

import { getParamsForm } from 'src/feature-app/get-params-form';

const { tc, today, url, device } = getParamsForm();
const urlParams = new URLSearchParams(window.location.search);

export const getCommonFormData = (isPresubmit, idPerson, idVehicle, dpsData, fields, data, surveyAnswer01) =>{

    const submitData = !isPresubmit ? {
        'headers[FORM_SOURCE_INFO]': 'Encuesta Renovación',
        'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,
        'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
    } : null;

    return {
        ...submitData,

        'headers[DPS_TOKEN]': isPresubmit ? 'VW_DDB_FORM_NORENUEVA_PREGRABACION': 'VW_DDB_FORM_NORENUEVA',
        'headers[FORM_DATE_INSERTION]': today,
        'headers[FORM_DETAILS]': isPresubmit ? 'NoRenovación Pregrabación' : 'NoRenovación Grabación',
        'headers[FORM_MKT_CHANNEL]': 'CRM',
        'headers[FORM_NAME]': fields.formName ? `vw-oneform-${fields.formName}` : `vw-oneform-${data.fields.formName}`,
        'headers[FORM_OBJECTIVE]': fields.formName ==='no_renovacion-no_tengo' && fields.quieroContacto === 'true' ? 'Más Información' : 'Encuesta',
        'headers[FORM_URL]': url,
        'headers[FORM_WEB_SECTION]': 'Genérica',
        'headers[ORIGIN_DEVICE_TYPE]': device,
        'headers[ORIGIN_TRACKING_CODE]': tc,
        'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,
        'headers[SF_CAMPAIGN_ORIGIN_ID]': 'PENDIENTE',
        'headers[FK_SYSTEM]': urlParams.get('assetid'),
        'headers[FORM_VERSION]': fields.formName ? `vw-oneform-${fields.formName}-${process.env.VERSION}` : `vw-oneform-${data.fields.formName}-${process.env.VERSION}`,

        'fields[DPS_BRAND]': 'Volkswagen',
        'fields[VEH_ACTUAL_FRAME]': idVehicle.Name,
        'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
        'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[PERSON_NAME]': idPerson.FirstName,
        'fields[PERSON_SURNAME_1]': idPerson.LastName,
        'fields[PERSON_EMAIL]': idPerson.PersonEmail,
        'fields[PERSON_PHONE_MOBILE]': idPerson.MobilePhone,
        'fields[SURVEY_ANSWER_01]': surveyAnswer01,

        'flags[PERSON_RGPD_ADAPTED]': true,
        'flags[FLAG_ALLOWS_DATA_ENRICHMENT]': false,
        'flags[FLAG_IS_MYSTERY_LEAD]': false,
        'flags[FLAG_RELACION_MARCA_SIN_IC]': false,
        'flags[FLAG_SF_INTEGRATION]': fields.quieroVolkswagen === 'true' && fields.quieroContacto === 'true' ? 'true' : 'false',
    }
};
