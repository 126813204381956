import React from 'react';
import { ScreenController, TriggerProps, useFaContent } from 'src/feature-app';
import { MGMController } from 'src/forms/MGM';

export function MGMTrigger(props: TriggerProps) {


  return (
    <div className="MGM-wrapper">
      <ScreenController
        selectedJourney={<MGMController />}
        closeLayerCallback={close}
        handleAreYouSureLayer={() => null}
        notALayer={true}
        formType = 'inPage'
      />
    </div>
  );
}
