import React, { useEffect } from 'react';
import { OneFormState, Steps } from 'src/types';
import { OneElementTemplate, RenderController } from 'src/feature-app/Screen';
import {
  CTA,
  Container,
  ContainerPadding,
  Text,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { TrackingForm, useTrackingManager } from 'src/feature-app';
import { useDispatch, useSelector } from 'react-redux';

export function ThankYouPlanIdeal() {
  const { formInfo } = useSelector((state: OneFormState) => state);
  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const getTrackingForm = (): TrackingForm => ({
    LeadID: undefined,
    FormFields: undefined,
  });

  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formInfo?.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: "ThankYou" } } });

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm()
    );
  }, []);

  const screen: Steps = {
    title: (
      <>
        <Text bold>¡Gracias por participar, {formInfo.formResponse.params.fields.DESC_NAME}!</Text>
      </>
    ),
    fields: (
      <OneElementTemplate element={
        <Container padding={{ top: ContainerPadding.dynamic0050 }}>
          <Container padding={{ top: ContainerPadding.dynamic0050 }}>
            <Text appearance={TokenTextAppearance.copy200}>El día <Text bold>7 de noviembre</Text> realizaremos el sorteo y comunicaremos el ganador/a, ¡te deseamos mucha suerte!</Text>
          </Container>

          <Container padding={{ top: ContainerPadding.dynamic0050 }}>
            <Text appearance={TokenTextAppearance.copy200}>Mientras tanto, puedes mantenerte al día de todas nuestras novedades sobre la movilidad eléctrica y resolver tus dudas <CTA tag="a" emphasis="tertiary" href="https://www.volkswagen.es/es/electricos-hibridos/electricos.html#simuladores"><Text bold appearance={TokenTextAppearance.copy200}>aquí</Text></CTA>.</Text>
          </Container>

          <Container padding={{ top: ContainerPadding.dynamic0050 }}>
            <Text appearance={TokenTextAppearance.copy200}>Además, nuestros eléctricos son los nuevos protagonistas de un nuevo evento muy especial, el ID.Street, ¿Quieres saber más? <CTA tag="a" emphasis="tertiary" href="https://idexperience.es/idstreet"><Text bold appearance={TokenTextAppearance.copy200}>Descúbrelo aquí</Text></CTA>.</Text>
          </Container>
        </Container>
      } />
    )
  };
  return (
    <RenderController
      screenType="full-screen"
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
      thankYouPage
    />
  );
}
