import axios, {AxiosResponse} from 'axios';
import {OneformBFFResponse} from 'src/types';
import {getOneformBFFUrl, getOneformBFFApiKey, reportErrorSentry} from 'src/bff';
import {useIsComerciales} from 'src/feature-app';

export const getEapiCarlines = async (): Promise<AxiosResponse<OneformBFFResponse>> => {
	try {
		const groupBrand = useIsComerciales() ? 'N' : 'V';
		const url = getOneformBFFUrl();
		const apiKey = getOneformBFFApiKey();
		const response = await axios.get(`${url}/eapi/carlines?group_brand=${groupBrand}`, {
			headers: {
				'X-Api-Key': apiKey,
			},
		});

		return response;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const getEapiAvailability = async (
	kvps: string,
	codInstalacion: string,
	timestampFrom: number,
	timestampTo: number,
): Promise<AxiosResponse<OneformBFFResponse>> => {
	try {
		const groupBrand = useIsComerciales() ? 'N' : 'V';
		const url = getOneformBFFUrl();
		const apiKey = getOneformBFFApiKey();
		const response = await axios.get(
			`${url}/eapi/availability?group_brand=${groupBrand}&kvps=${kvps}&cod_instalacion=${codInstalacion}&timestamp_from=${timestampFrom}&timestamp_to=${timestampTo}&check_dealer=true`,
			{
				headers: {
					'X-Api-Key': apiKey,
				},
			},
		);

		return response;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const createEapiAppointment = async (formData: FormData): Promise<AxiosResponse<OneformBFFResponse>> => {
	try {
		const url = getOneformBFFUrl();
		const apiKey = getOneformBFFApiKey();
		const response = await axios.post(`${url}/eapi/appointment/create`, formData, {
			headers: {
				'X-Api-Key': apiKey,
			},
		});

		return response;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const deleteEapiAppointment = async (formData: FormData): Promise<AxiosResponse<OneformBFFResponse>> => {
	try {
		const url = getOneformBFFUrl();
		const apiKey = getOneformBFFApiKey();
		const response = await axios.post(`${url}/eapi/appointment/delete`, formData, {
			headers: {
				'X-Api-Key': apiKey,
			},
		});

		return response;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const getEapiAppointmentIcs = async (pincode: string): Promise<Blob> => {
	try {
		const groupBrand = useIsComerciales() ? 'N' : 'V';
		const url = getOneformBFFUrl();
		const apiKey = getOneformBFFApiKey();
		const response = await axios.get(`${url}/eapi/appointment/get/ics?group_brand=${groupBrand}&pincode=${pincode}`, {
			headers: {
				'X-Api-Key': apiKey,
			},
		});

		return response.data;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};
