import { Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { dpsSend } from 'src/bff';
import { RatingStars, DetalleMotivo, MultipleCTASelection } from 'src/components';
import { FormController, useOneFormContext } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { formDataBuilder } from '../../format-fields';
import { useInitializeLEMForm } from '.';
import { ThankYouCEMOneShop, ConfirmacionCEMOneShop } from 'src/forms/Cem/CEM OneShop';
import { CEMOneShopFormProps } from './CEMOneShopController';
import { CTAsSiNo, ctaPruebaTCross } from 'src/feature-app/InputsValues/CTAsObjects';

export enum CEMOneShopSteps {
  Atencion,
  Planificacion,
  Organizacion,
  ExplicacionConfiguracion,
  ExplicacionPersonalizacion,
  VehiculoProbado,
  MasInformacion,
  Confirmacion,
}

export function CEMOneShopForm(props: CEMOneShopFormProps) {
  const { dpsData, idLead, idPerson, idOrder, handleError, trackFormLoad } = props;
  const [loadingPresubmit, setLoadingPresubmit] = useState(true);
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const dispatch = useDispatch();
  const stepsWithModel: Steps[] = [
    {
      title: (
        <>
          <Text>Tenemos curiosidad,  </Text>
          <Text bold>¿cómo fue la atención recibida en la entrega?</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <>
              <Text appearance={TokenTextAppearance.copy200} staticSize>
                Nos gustaría saber si desde la Concesión te ayudaron en todo lo que necesitabas.
              </Text>
              <RatingStars
                textUnderStars={true} // Will delete the text on the stars and add it under depending on the next prop.
                starsText={['Insatisfactoria', , 'Normal', , 'Satisfactoria']} // This prop needs an array according to the number of stars. No string means line.
                name="atencion"
                nextOnClick={true} />
            </>
          }
        />
      ),
      screenIndex: CEMOneShopSteps.Atencion,
      name: 'Atencion',
      outputs: ['atencion'],
      outputsText: ['atencion'],
      outputsIsStars: true,
    },
    {
      title: (
        <>
          <Text bold>¿Fue fácil planificar la entrega?</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <>
              <Text appearance={TokenTextAppearance.copy200} staticSize>
                Nos preguntamos si te ofrecieron opciones que se adaptaban a tus horarios.
              </Text>
              <RatingStars
                textUnderStars={true}
                starsText={['Difícil', , 'Normal', , 'Fácil']}
                name="planificacion"
                nextOnClick={true}
              />
            </>
          }
        />
      ),
      screenIndex: CEMOneShopSteps.Planificacion,
      name: 'Planificacion',
      outputs: ['planificacion'],
      outputsText: ['planificacion'],
      outputsIsStars: true,
    },
    {
      title: (
        <>
          <Text bold>¿Estaba todo organizado el día de la entrega?</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ paddingTop: '8px' }}>
              <Text appearance={TokenTextAppearance.copy200} staticSize>
                Nos gustaría saber si te recibieron con puntualidad y estaba todo listo para llevarte tu T-Cross Clic2Go.
              </Text>
              <MultipleCTASelection ctaValues={CTAsSiNo} name="organizacion" ctaWidth="81" nextOnClick />
            </div>
          }
        />
      ),
      screenIndex: CEMOneShopSteps.Organizacion,
      name: 'Organizacion',
      trueOrFalseQuestion: true,
      outputs: ['organizacion'],
      outputsText: ['organizacion'],
    },
    {
      title: (
        <>
          <Text>Una duda, </Text>
          <Text bold>¿te explicaron cómo funcionaban y cómo se configuraban los asistentes de tu coche?</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ paddingTop: '8px' }}>
              <Text appearance={TokenTextAppearance.copy200} staticSize>
                Nos preguntamos si saliste del punto de venta sabiendo sacar el máximo partido a tu Volkswagen.
              </Text>
              <MultipleCTASelection ctaValues={CTAsSiNo} name="explicacionConfiguracion" ctaWidth="81" nextOnClick />
            </div>
          }
        />
      ),
      screenIndex: CEMOneShopSteps.ExplicacionConfiguracion,
      name: 'ExplicacionConfiguracion',
      trueOrFalseQuestion: true,
      outputs: ['explicacionConfiguracion'],
      outputsText: ['explicacionConfiguracion'],
    },
    {
      title: (
        <>
          <Text bold>¿Probaste tu T-Cross Clic2Go antes de salir del punto de venta?</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ paddingTop: '8px' }}>
              <Text appearance={TokenTextAppearance.copy200} staticSize>
                Nos preguntamos si te ayudaron a poner en práctica todo lo explicado durante la entrega.
              </Text>
              <MultipleCTASelection ctaValues={ctaPruebaTCross} name="vehiculoProbado" nextOnClick />
            </div>
          }
        />
      ),
      screenIndex: CEMOneShopSteps.VehiculoProbado,
      name: 'VehiculoProbado',
      trueOrFalseQuestion: true,
      outputs: ['vehiculoProbado'],
      outputsText: ['vehiculoProbado'],
    },
    {
      title: (
        <>
          <Text>Antes de terminar, </Text>
          <Text bold>¿hay algo más que nos quieras contar?</Text>
          <span style={{ whiteSpace: 'nowrap' }}>
            <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: <OneElementTemplateFullScreen element={<DetalleMotivo label='Escríbelo aquí (Ejemplo: Me hubiera gustado...)' />} />,
      screenIndex: CEMOneShopSteps.MasInformacion,
      name: 'MasInformacion',
      outputs: ['masInformacion'],
      outputsText: ['masInformacion'],
    },
    {
      fields: <ConfirmacionCEMOneShop idPerson={idPerson} />,
      screenIndex: CEMOneShopSteps.Confirmacion,
      name: 'Confirmacion',
      outputs: ['confirmacion'],
      outputsText: ['confirmacion'],
    },
  ];

  const CEMOneShopData: Partial<FormDataGroup> = {
    headers: {},
    fields: {
      formName: 'cem-oneshop',
      formType: 'otherform',
      pageCategory: 'Encuesta',
    }, // Analítica
  };

  useEffect(() => {
    const sendPresubmit = async () => {
      const result = await sendForm(true);

      if (!result) {
        handleError();
      }
      setLoadingPresubmit(false);
    };

    sendPresubmit();

  }, []);

  const generateData = (data?: Partial<FormDataGroup>) => {
    const { formData, dealersInfo }: OneFormState = store.getState();
    const { fields, headers } = formData;
    const { tc, today, url, device } = getParamsForm();

    return {

      'headers[DESC_ID_CAMPAIGN]': '7011n000001FXvCAAW',
      'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,
      'headers[DESC_TOKEN]': 'VW_DDB_FRM_CEM_ONESHOP_CONTESTADO',
      'headers[TC]': tc,
      'headers[FECHA_INSERTION_DATE]': today,
      'headers[DESC_URLORIGIN]': url,
      'headers[DESC_ID_CLIMAPRO]': idPerson.ID_Person_DIM_IMP__c,
      'headers[DESC_FORM_DETAIL]': 'CEM OneShop Grabación',
      'headers[DESC_FORMOBJECTIVE]': 'Encuesta',
      'headers[DESC_DEVICETYPE]': device,
      'headers[DESC_CAMPAIGNBLOCK]': '21. VW OneShop',
      'headers[DESC_GDPR_ADAPTED]': true,
      'headers[DESC_BRAND]': 'VW',
      'headers[COD_FORM_ID]': fields.formName ? `vw-oneform-${fields.formName}` : `vw-oneform-${data.fields.formName}`,

      'fields[DESC_NUMBER_ORDER]': idOrder ? idOrder.IDClimapro__c : "",
      'fields[DESC_FORM_VERSION]': fields.formName
        ? `vw-oneform-${fields.formName}-${process.env.VERSION}`
        : `vw-oneform-${data.fields.formName}-${process.env.VERSION}`,
      'fields[DESC_NAME]': idPerson.FirstName,
      'fields[DESC_SURNAME_1]': idPerson.LastName,
      'fields[DESC_SURNAME_2]': idPerson.TXT_2_Surname__c,
      'fields[DESC_EMAIL_ADDRESS]': idPerson.TXT_Email__c,
      'fields[NUM_CELLPHONE]': idPerson.NUM_Phone_mobile__c,
      'fields[DESC_LOPD]': 'PERMITE',
      'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
      'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
      'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
      'fields[DESC_AUXFIELD1]': dpsData.rating,
      'fields[DESC_AUXFIELD2]': fields.atencion,
      'fields[DESC_AUXFIELD3]': fields.planificacion,
      'fields[DESC_AUXFIELD4]': fields.organizacion,
      'fields[DESC_AUXFIELD5]': fields.explicacionConfiguracion,
      'fields[DESC_AUXFIELD7]': fields.vehiculoProbado,
      'fields[DESC_AUXFIELD8]': fields.masInformacion,

    };
  };

  /**
   * Generamos la misma data que para el submit y eliminamos todos los campos que no son necesarios para el presubmit.
   */
  const generatePresubmitData = () => {
    const data = generateData(CEMOneShopData);

    delete data['headers[PINCODE]'];
    delete data['fields[DESC_AUXFIELD2]'];
    delete data['fields[DESC_AUXFIELD3]'];
    delete data['fields[DESC_AUXFIELD4]'];
    delete data['fields[DESC_AUXFIELD5]'];
    delete data['fields[DESC_AUXFIELD7]'];
    delete data['fields[DESC_AUXFIELD8]'];

    data['headers[DESC_FORM_DETAIL]'] = 'CEM OneShop Pregrabación';
    data['headers[DESC_TOKEN]'] = 'VW_DDB_FRM_CEM_ONESHOP_ENCUESTA';

    return data;
  };
  const generateSubmitData = () => generateData();

  const sendForm = (isPresubmit?: boolean): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = isPresubmit ? generatePresubmitData() : generateSubmitData();
      //resolve(true);
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData, isPresubmit);

      if (response && response.data && response.data.content && response.data.content.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { loading, steps, firstStep, lastStep } = useInitializeLEMForm(stepsWithModel, idLead);

  useEffect(() => {
    const CEMOneShopInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      step: firstStep,
      steps,
      stepsHistory: [0],
      thankYouComponent: <ThankYouCEMOneShop />,
    };
    if (!loading) {
      initializeForm(CEMOneShopInfo, CEMOneShopData, true);
      trackFormLoad();
    }
  }, [loading]);

  return loading || loadingPresubmit || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
