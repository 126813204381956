import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrackingForm, useTrackingManager } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps } from 'src/types';

export function ThankYouMGM({ friend }: { friend?: boolean }) {
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { formResponse } = formInfo;
  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const getTrackingForm = (): TrackingForm => ({
    LeadID: undefined,
    FormFields: undefined,
  });

  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formInfo?.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: "ThankYou" } } });

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm()
    );
  }, []);

  const screen: Steps = {
    title: friend ? (
      <>
        <Text bold>¡Muchas gracias! </Text> Esa persona tan especial ya está más cerca de estrenar su Volkswagen.
      </>
    ) : (
      <>
        <Text bold>¡Muchas gracias! </Text> Ya estás más cerca de estrenar tu Volkswagen.
      </>
    ),
    fields: <></>,
  };
  return (
    <RenderController
      screenType="full-screen"
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
      thankYouPage
    />
  );
}
