import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputError, Provincia, TextInputExtended } from 'src/components';
import { useOnlyLettersControl } from 'src/components/inputs/use-only-letters-control';
import {
  styled,
} from '@volkswagen-onehub/components-core';

interface ContentProps {
  notALayer?: boolean;
}

const ContentWrapper = styled.div<ContentProps>`
  margin-top: 4px;
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;
  }
  label {
    margin-top: 40px;
    @media all and (min-width: 560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid013)' : '303px')};
    }
    @media all and (min-width: 960px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '320px')};
    }
    @media all and (min-width: 1280px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '320px')};
    }
    @media all and (min-width: 1920px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '320px')};
    }
    @media all and (min-width: 2560px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '320px')};
    }
  }
`;

export const PantallaMunicipio = () => {
  const { register } = useFormContext();

  //Localidad
  const [localidadValue, setLocalidadValue] = useState('');
  const [pristineLocalidad, setPristineLocalidad] = useState(true);
  const { error, regExp } = useOnlyLettersControl(pristineLocalidad, localidadValue);

  const handleChangeLocalidad = (e: any) => {
    setLocalidadValue(e.target.value);
    setPristineLocalidad(false);
  };

  // PostalCode
  const [inputValuePostalCode, setInputValuePostalCode] = useState('');
  const [pristinePostalCode, setPristinePostalCode] = useState(true);
  const [errorPostalCode, setErrorPostalCode] = useState(false);
  const regExpPostalCode = /\d{5}/gi;

  const handleChangePostalCode = (e: any) => {
    setInputValuePostalCode(e.target.value);
    setPristinePostalCode(false);
  };

  useEffect(() => {
    if (!pristinePostalCode && inputValuePostalCode.match(regExpPostalCode) === null) {
      setErrorPostalCode(true);
    } else {
      setErrorPostalCode(false);
    }
  }, [inputValuePostalCode, pristinePostalCode]);

  //Provincia

  const [provinceValue, setProvinceValue] = useState('');
  const [pristineProvince, setPristineProvince] = useState(true);
  const { error: errorProvincia, regExp: regExpProvincia } = useOnlyLettersControl(pristineProvince, provinceValue);

  const handleChangeProvince = (e: any) => {
    setProvinceValue(e.target.value);
    setPristineProvince(false);
  };

  return (
    <ContentWrapper>
      <TextInputExtended
        name='location'
        label='Localidad'
        isFloating
        innerRef={register({
          required: {
            value: true,
            message: 'Requerido',
          },
          pattern: {
            value: regExp,
            message: 'Solo se aceptan letras',
          },
        })}
        onChange={(e) => handleChangeLocalidad(e)}
        required
      />
      {error && <InputError className='input-error'>Solo se aceptan letras</InputError>}

      <TextInputExtended
        name='postalCode'
        label='Código Postal'
        isFloating
        innerRef={register({
          required: {
            value: true,
            message: 'Requerido',
          },
          pattern: {
            value: regExpPostalCode,
            message: 'Solo se aceptan números',
          },
        })}
        onChange={(e) => handleChangePostalCode(e)}
        required
      />
      {errorPostalCode && <InputError className='input-error'>Solo se aceptan números</InputError>}

      <Provincia notALayer={true} noPaddingTop={true}/>

    </ContentWrapper>
  );
};
