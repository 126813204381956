import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerPadding,
  Layout,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, TrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { FormDataFields, FormInfo, OneFormState, Steps } from 'src/types';

interface FieldProps {
  copyBook?: string;
  cliente?: string;
}

interface NDPSField {
  name: string;
  value: string;
}

function Fields(props: FieldProps) {

  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
      }}
    >
      <div style={{ textAlign: 'left', paddingBottom: '4px', width: '100%' }}>
        <Container padding={{ top: ContainerPadding.dynamic0100 }}>
          <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
            {props.copyBook}
          </Text>
          {props.cliente === 'true' ? (
            <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
              ¡Mucha suerte!
            </Text>
          ) : null}
        </Container>
      </div>
    </Layout>
  );
}

interface ThankYouProps {
  formData: FormDataFields;
  formInfo: FormInfo;
}

function ThankYouNavidad(props: ThankYouProps) {
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { formResponse } = formInfo;
  const { cliente } = formData.fields;

  const clienteTitle = '¡Bien hecho! Este año los Reyes tienen muchas pistas';
  const clienteBody = 'La carta ya se ha enviado con tus deseos de Navidad. Muy pronto sabrás si el deseo de conducir un ID.4 durante un fin de semana se hace realidad...';
  const NoClienteTitle = '¡Qué ilusión! Seguro que los Reyes aciertan con tu regalo';
  const NoClienteBody = 'La carta ya se ha enviado con tus deseos de Navidad. Muchas gracias por crear tu lista de deseos en Volkswagen.';

  const [title, setTitle] = useState('');
  const [bodyCopy, setBodyCopy] = useState('');

  useEffect(() => {
    if (cliente === 'true') {
      setTitle(clienteTitle);
      setBodyCopy(clienteBody);
    } else {
      setTitle(NoClienteTitle);
      setBodyCopy(NoClienteBody);
    }
  }, [cliente]);

  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const getTrackingForm = (): TrackingForm => {
    let LeadID;
    if (formResponse && formResponse.content && formResponse.content.data && formResponse.content.data.fields) {
      const leadField: NDPSField = formResponse.content.data.fields.find(
        (field: NDPSField) => field.name === 'COD_LEAD_ID'
      );
      LeadID = leadField ? leadField.value : undefined;
    }
    return {
      LeadID,
      FormFields: undefined,
    };
  };

  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formInfo.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: "ThankYou" } } });

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm()
    );
  }, []);

  const renderTitle = () => {
    return (
      <>
        <BreakpointWrapper max={Breakpoints.b1600}>
          <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left} bold>
            {title}
          </Text>
        </BreakpointWrapper>
        <BreakpointWrapper min={Breakpoints.b1600}>
          <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
            {title}
          </Text>
        </BreakpointWrapper>
      </>
    );
  };

  const screen: Steps = {
    title: <>{renderTitle()}</>,
    fields: <Fields copyBook={bodyCopy} cliente={cliente} />,
  };
  return (
    <RenderController
      screenType="layer-screen"
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
    />
  );
}

const mapStateToProps = (state: OneFormState) => {
  return {
    formData: state.formData.fields,
    formInfo: state.formInfo,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ThankYouNavidad);

export { ConnectedComponent as ThankYouNavidad };
