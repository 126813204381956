import { Text } from '@volkswagen-onehub/components-core';
import Axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { PantallaNombreApellido, CarlineSelect, DetalleMotivo, MultipleCTASelection } from 'src/components';
import { FormController, useOneFormContext } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { FormDataGroup, FormInfo, IdLead, OneFormState, Steps } from 'src/types';
import { formDataBuilder } from '../format-fields';
import { ConfirmacionLEM, ThanKYouLEM } from 'src/forms/LEM';
import { LEMFormProps } from './LEMController';
import { useInitializeForm, checkIdLeadHasCarline } from './use-initialize-lem-form';
import { addRemovedStep, getIndexInSteps, removeStep, resetStepsScreenIndex } from '../helpers/add-remove-steps';
import { CTAsSiNo } from 'src/feature-app/InputsValues/CTAsObjects';

export enum LEMContactadoSteps {
  Modelo,
  PeriodoContacto,
  ProximoContacto,
  TipoVisita,
  Comentario,
  EnviarConcesion,
  DatosPersonales,
  Confirmacion
}

const getPeriodoContactoTitle = (modeloPrecargado: boolean) => {
  if (modeloPrecargado) {
    return (
      <>
        <Text bold>Y ¿cuándo</Text> te{' '}
        <span style={{ whiteSpace: 'nowrap' }}>
          respondieron?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
        </span>
      </>
    );
  } else {
    return (
      <>
        Nos encantaría saber más sobre tu experiencia.{' '}
        <Text bold>
          ¿Cuándo te{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            respondieron?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      </>
    );
  }
};

export function LEMContactadoForm(props: LEMFormProps) {
  const { dpsData, idLead, idPerson, installation, handleError, presubmit, trackFormLoad } = props;
  const [loadingPresubmit, setLoadingPresubmit] = useState(true);
  const { initializeForm, handleNextStep } = useOneFormContext();
  const store = useStore();
  const { enviarConcesion } = useSelector((state: OneFormState) => state.formData.fields);
  const { dealersInfo }: OneFormState = store.getState();
  const dispatch = useDispatch();

  const ctasPeriodoContacto = [
    {
      label: 'En las primeras 8 horas',
      value: 'En las primeras 8h'
    },
    {
      label: 'En las primeras 24 horas',
      value: 'En las primeras 24h'
    },
    {
      label: 'En más de 24 horas',
      value: 'En más de 24h'
    },
  ];

  const ctasProximoContacto = [
    {
      label: 'Sí, una visita al punto de venta',
      value: 'Si, una visita al punto de venta'
    },
    {
      label: 'Sí, una llamada',
      value: 'Si, una llamada'
    },
    {
      label: 'Sí, una videollamada',
      value: 'Si, una videollamada'
    },
    {
      label: 'No, no estoy interesado',
      value: 'No, no estoy interesado'
    },
    {
      label: 'No, pero me hubiera gustado',
      value: 'No, pero me hubiera gustado'
    },
  ];

  const stepsWithModel: Steps[] = [
    {
      title: (
        <>
          <Text bold>¿Por qué modelo </Text>contactaste con tu punto de venta?
        </>
      ),
      fields: <OneElementTemplateFullScreen element={<CarlineSelect useCarline={true} notALayer={true} />} />,
      screenIndex: LEMContactadoSteps.Modelo,
      name: 'Modelo',
    },
    {
      title: getPeriodoContactoTitle(checkIdLeadHasCarline(idLead)),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={ctasPeriodoContacto} name="periodoContacto" nextOnClick />}
        />
      ),
      screenIndex: LEMContactadoSteps.PeriodoContacto,
      name: 'PeriodoContacto',
    },
    {
      title: (
        <>
          ¿Habéis acordado un{' '}
          <Text bold>
            próximo{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              contacto o cita?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={ctasProximoContacto} name="proximoContacto" nextOnClick />}
        />
      ),
      screenIndex: LEMContactadoSteps.ProximoContacto,
      name: 'ProximoContacto',
      trueOrFalseQuestion: true,
    },
    {
      title: (
        <>
          <Text bold>¿Quieres compartir tus valoraciones</Text> con el punto de venta?*
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsSiNo} name="enviarConcesion" ctaWidth="81" nextOnClick />}
        />
      ),
      screenIndex: LEMContactadoSteps.EnviarConcesion,
      name: 'EnviarConcesion',
      trueOrFalseQuestion: true,
    },
    {
      title: (
        <>
          Por favor, <Text bold>introduce o comprueba tus datos:</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ paddingBottom: '2px' }}>
              <PantallaNombreApellido />
            </div>
          }
        />
      ),
      screenIndex: LEMContactadoSteps.DatosPersonales,
      name: 'DatosPersonales',
    },
    {
      title: (
        <>
          <Text bold>¿Quieres añadir </Text> algún comentario adicional?
        </>
      ),
      fields: <OneElementTemplateFullScreen element={<DetalleMotivo name="comentario" />} />,
      screenIndex: LEMContactadoSteps.Comentario,
      name: 'Comentario',
    },
    {
      fields: <ConfirmacionLEM />,
      screenIndex: LEMContactadoSteps.Confirmacion,
      name: 'Confirmacion',
    },
  ];

  const LEMContactadoData: Partial<FormDataGroup> = {
    headers: {},
    fields: {
      formName: 'lem-contactado',
      formType: 'otherform',
      pageCategory: 'Encuesta',
    }, // Analítica
  };

  useEffect(() => {
    if (enviarConcesion) {
      modifySteps(enviarConcesion == '1' || enviarConcesion == 'true', 'DatosPersonales');
    }
  }, [enviarConcesion]);

  const modifySteps = (add: boolean, screen: string) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
		let newSteps = [...actualSteps];
    if (add) {
      const stepIndex = getIndexInSteps(newSteps, screen);

			if (stepIndex === -1) {
				newSteps = addRemovedStep(steps, actualSteps, screen, screenIndex);
				setTimeout(() => {
					handleNextStep();
				}, 0);
			}
    }
    else {
      newSteps = removeStep(newSteps, screen);
    }

    const newStepsWithIndex = resetStepsScreenIndex(newSteps);

		dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
		dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
		dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
  };



  useEffect(() => {
    const sendPresubmit = async () => {
      const result = await sendForm(true);

      if (!result) {
        handleError();
      }
      setLoadingPresubmit(false);
    };

    if (presubmit) {
		sendPresubmit();
    } else {
		setLoadingPresubmit(false);
    }
  }, []);


  const generateData = (data?: Partial<FormDataGroup>) => {
    const { formData, dealersInfo }: OneFormState = store.getState();
    const { fields } = formData;
    const { tc, today, url, device } = getParamsForm();

    return {
      'headers[DPS_TOKEN]': 'VW_DDB_FRM_LEM_ENCUESTA',
      'headers[FK_SYSTEM]': idLead.Id,
      'headers[FORM_DATE_INSERTION]': today,
      'headers[FORM_DETAILS]': 'LEM Contactado',
      'headers[FORM_NAME]': fields.formName ? `vw-oneform-${fields.formName}` : `vw-oneform-${data.fields.formName}`,
      'headers[FORM_OBJECTIVE]': 'Encuesta',
      'headers[FORM_URL]': url,
      'headers[FORM_VERSION]': fields.formName ? `vw-oneform-${fields.formName}-${process.env.VERSION}`: `vw-oneform-${data.fields.formName}-${process.env.VERSION}`,
      'headers[ORIGIN_DEVICE_TYPE]': device,
      'headers[ORIGIN_TRACKING_CODE]': tc,
      'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,

      'flags[PERSON_RGPD_ADAPTED]': true,
      'flags[FLAG_RELACION_MARCA_SIN_IC]': false,

      'fields[DPS_BRAND]': 'Volkswagen',
      'fields[PERSON_EMAIL]': idLead.Email,
      'fields[PERSON_NAME]': fields.name ? fields.name : idLead.FirstName,
      'fields[PERSON_PHONE_MOBILE]': idLead.MobilePhone,
      'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
      'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
      'fields[PERSON_SURNAME_1]': fields.surname ? fields.surname : idLead.LastName,
      'fields[PERSON_SURNAME_2]': fields.secondSurname ? fields.secondSurname : idLead.MiddleName,
      'fields[SF_LEAD_INSTALLATION_ID]': idLead.Dealership__c,
      'fields[SF_LEAD_INSTALLATION_CODE]': installation ? installation.VGED_DealerCode__c : '',
      'fields[SF_LEAD_INSTALLATION_NAME]': installation ?installation.VGED_CommercialName__c : '',
      'fields[SURVEY_ANSWER_01]': dpsData.rating,
      'fields[SURVEY_ANSWER_03]': fields.periodoContacto,
      'fields[SURVEY_ANSWER_04]': fields.proximoContacto,
      'fields[SURVEY_ANSWER_10]': fields.enviarConcesion,
      'fields[SURVEY_ANSWER_11]': fields.comentario,
      'fields[VEH_INTEREST_NEW_OR_USED]': 'New Car',
      'fields[VEH_INTEREST_ID]': checkIdLeadHasCarline(idLead) && dealersInfo?.selectedCarline ? dealersInfo.selectedCarline.code : fields.carlineCode,
      'fields[VEH_INTEREST_MODEL]': checkIdLeadHasCarline(idLead) && dealersInfo?.selectedCarline ? dealersInfo.selectedCarline.title : fields.modelo

    };
  };

  const generatePresubmitData = () => {
    const data = generateData(LEMContactadoData);

    data['headers[FORM_DETAILS]'] = 'LEM Pregrabación';
    data['headers[DPS_TOKEN]'] = 'VW_DDB_FRM_LEM_ENCUESTA_PREGRABACION';

    return data;
  };

  const sendForm = (isPresubmit?: boolean): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {

      const data = isPresubmit ? generatePresubmitData() : generateData();
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData, isPresubmit);

      if (response?.data?.status?.code == 200) {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { loading, steps, firstStep, lastStep } = useInitializeForm(stepsWithModel, idLead);

  useEffect(() => {
    const LEMContactadoInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps,
      stepsHistory: [0],
      thankYouComponent: <ThanKYouLEM />,
    };
    if (!loading) {
      initializeForm(LEMContactadoInfo, LEMContactadoData, true);
      trackFormLoad();
    }
  }, [loading]);

  return loading || loadingPresubmit || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
