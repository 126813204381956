import Axios, { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useStore } from 'react-redux';
import { dpsSend } from 'src/bff';
import { formatPrefix, FormController, useOneFormContext } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { } from 'src/forms/MGM';
import { FormDataGroup, FormInfo, MolecularResponse, OneFormState, Steps } from 'src/types';
import { ThankYouMGM } from '.';
import { formatHeaders, formDataBuilder } from '../format-fields';
import { BonoRegaloFields } from './BonoRegaloFields';
import { LegalMGM } from './LegalMGM';
import { MGMFormProps } from './MGMController';
import { MGMLayout } from './MGMLayout';

const bonoRegaloCopies = {
  title: 'Solo te queda un Sí, para tener hasta 1.000€ de descuento.',
  copy: '¡Rellena el formulario con tus datos y envía!',
};

export function BonoRegaloForm(props: MGMFormProps) {
  const { dpsData, idLead, idPerson, trackFormLoad } = props;
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const steps: Steps[] = [
    {
      title: <></>, // El title se renderiza en los fields al ser un form de un solo step con un diseño que incluye un stage.
      fields: (
        <MGMLayout
          title={bonoRegaloCopies.title}
          fields={<BonoRegaloFields title={bonoRegaloCopies.title} copy={bonoRegaloCopies.copy} />}
        />
      ),
      screenIndex: 0,
      name: 'Form',
    },
  ];

  const BonoRegaloData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_GDPR_ADAPTED: 'true',
      DESC_CAMPAIGNBLOCK: '16. One shot',
      DESC_ID_CAMPAIGN: '7011n000001FY4sAAG',
      DESC_TOKEN: 'VW_DDB_FRM_MGM_REGALO_RENOVACION_STOCK_22',
      DESC_FORM_DETAIL: 'MGM Bono Regalo Renovación Stock 22',
    },
    fields: {
      formName: 'mgm-bono-regalo',
      formType: 'otherform',
      pageCategory: 'Cupon',
    }, // Analítica
  };

  const generateData = () => {
    const { formData }: OneFormState = store.getState();
    const { fields, headers } = formData;
    const { tc, today, url, device } = getParamsForm();
    const values = getValues();

    return {
      'headers[COD_FORM_ID]': `vw-oneform-${fields.formName}`,
      'headers[DESC_DEVICETYPE]': device,
      'headers[DESC_FORMOBJECTIVE]': fields.pageCategory, // Revisar
      'headers[DESC_URLORIGIN]': url,
      'headers[FECHA_INSERTION_DATE]': today,
      'headers[TC]': tc,
      'headers[PINCODE]': dpsData?.pincode?.pincodeResponse?.params?.pincode,
      ...formatHeaders(headers), // Headers comunes a todos los formularios de leads

      'fields[DESC_SURNAME_2]': values.secondSurname,
      'fields[DESC_SURNAME_1]': values.surname,
      'fields[DESC_NAME]': values.name,
      'fields[DESC_EMAIL_ADDRESS]': values.email,
      'fields[NUM_CELLPHONE]': `${formatPrefix(fields.prefix)}${values.cellphone}`,
      'fields[DESC_FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
      'fields[DESC_AUXFIELD20]': idPerson.Id,
      'fields[DESC_AUXFIELD1]': 'Bono Regalo',
      'fields[DESC_AUXFIELD2]': idPerson.FirstName,
      'fields[DESC_AUXFIELD3]': idPerson.LastName,
      'fields[DESC_AUXFIELD4]': idPerson.TXT_2_Surname__c,
      'fields[DESC_AUXFIELD5]': idPerson.TXT_Email__c,
      'fields[DESC_AUXFIELD6]': idPerson.NUM_Phone_mobile__c,
      'fields[DESC_LOPD]': 'PERMITE',
      'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
      'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
      'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
    };
  };

  const generateSubmitData = () => generateData();

  const sendForm = (): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateSubmitData();

      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData);

      if (response && response.data && response.data.content && response.data.content.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  // const { loading, steps, firstStep, lastStep } = useInitializeMGMForm(steps, idLead);

  useEffect(() => {
    const BonoRegaloInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: steps[0].fullScreen ? true : false,
      lastStep: steps[steps.length - 1],
      numberOfScreens: steps.length,
      screenIndex: steps[0].screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: steps[0],
      steps,
      hideLegal: true,
      stepsHistory: [0],
      customLegal: <LegalMGM />,
      thankYouComponent: <ThankYouMGM />,
    };
    initializeForm(BonoRegaloInfo, BonoRegaloData, true);
    trackFormLoad();
  }, []);

  return !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
