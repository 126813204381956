import React from 'react';
import {JourneyCitaPosventaDealerController} from './JourneyCitaPosventaDealerController';
import {MapGeneralController} from 'src/feature-app/NewMap/MapGeneralController';

/**
 * Este componente está creado únicamente para que JourneyCitaPosventaController tenga acceso a las variables de MapGeneralController.
 */
export const JourneyCitaPosventaDealerWrapper = () => {
	return (
		<MapGeneralController>
			<JourneyCitaPosventaDealerController />
		</MapGeneralController>
	);
};
