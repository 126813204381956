import React, { useEffect, useState } from 'react';
import { FormController, getParamsForm, useOneFormContext } from 'src/feature-app';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { Text, Container, ContainerPadding, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { CarlineSelect, MultipleCTASelection, DetalleMotivo, MultipleOptionCTAs, SelectMarcaModelo } from 'src/components';
import { ConfirmacionNoRenovacion, ThankYouNoRenovacion, MapaNoRenovacion, FakeTriggerCTA } from '.';
import { getCommonFormData } from './FormData';
import { NoRenovacionFormProps } from './NoRenovacionController';
import { formDataBuilder } from 'src/forms/format-fields';
import { oneformBFFNdpsSend } from 'src/bff';
import { useInitializeNoRenovacionForm } from './use-initialize-no-renovacion-form';
import { addRemovedStep, getIndexInSteps, removeStep, resetStepsScreenIndex } from '../helpers/add-remove-steps';
import { getDescInterestCarline } from '../Leads/helpers';
import {
  CTAsSiNo,
  CTAsNuevoOcasión,
  CTAsMotivoRenovador,
  CTAsMotivoRenovadorNoTengoExclusivo,
  CTAsMotivoRenovadorProblemas
} from 'src/feature-app/InputsValues/CTAsObjects';

const getModeloInteresTitle = (quieroVolkswagen: string) => {
  if (quieroVolkswagen === 'true') {
    return (
      <>
        <Text bold>¿Qué modelo </Text>te llama más la{' '}
        <span style={{ whiteSpace: 'nowrap' }}>
          atención?
          <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
        </span>
      </>
    );
  } else {
    return (
      <>
        <Text bold>¿Qué otra marca y modelo </Text>estás valorando principalmente?
      </>
    );
  }
};

const getModeloInteresFields = (quieroVolkswagen: string) => {
  if (quieroVolkswagen === 'true') {
    return (
      <OneElementTemplateFullScreen
        element={
          <div style={{ marginBottom: '2px' }}>
            <CarlineSelect useCarline notALayer alphabetical nextOnSelect/>
          </div>
        }
      />
    );
  } else {
    return (
      <OneElementTemplateFullScreen
          allowOverflowingContent
          element={
            <SelectMarcaModelo stepName="Modelo" brandName="brand" modelName="model" required={true} formToken="VW_DDB_FORM_NORENUEVA"/>
          }
        />
    );
  }
};

const getModeloInteresOutputs = (quieroVolkswagen: string) => {
  if (quieroVolkswagen === 'true') {
    return ['modelo'];
  } else {
    return ['brand', 'model'];
  }
};
const getModeloInteresOutputsText = (quieroVolkswagen: string) => {
  if (quieroVolkswagen === 'true') {
    return ['modelo'];
  } else {
    return ['brand', 'model'];
  }
};

const getDudasTitle = (quieroVolkswagen: string) => {
  if (quieroVolkswagen === 'true') {
    return (
      <>
        ¿Hay algo que te esté{' '}
        <Text bold>
          haciendo dudar en la compra del{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            Volkswagen?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
        <Container padding={{ top: ContainerPadding.dynamic0020 }}>
          <Text appearance={TokenTextAppearance.copy200} staticSize>
            Elige un máximo de 3 opciones
          </Text>
        </Container>

      </>
    );
  } else {
    return (
      <>
        <Text bold>¿Por qué motivos </Text>
        no estás valorando un{' '}
        <span style={{ whiteSpace: 'nowrap' }}>
          Volkswagen?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
        </span>
        <Container padding={{ top: ContainerPadding.dynamic0020 }}>
          <Text appearance={TokenTextAppearance.copy200} staticSize>
            Elige un máximo de 3 opciones
          </Text>
        </Container>
      </>
    );
  }
};
const getDudasAlternatTitle = (quieroVolkswagen: string) => {
  if (quieroVolkswagen === 'true') {
    return (
      <>
        ¿Hay algo que te esté{' '}
        <Text bold>
          haciendo dudar en la compra del{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            Volkswagen?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      </>
    );
  } else {
    return (
      <>
        <Text bold>¿Por qué motivo </Text>
        no estás valorando un{' '}
        <span style={{ whiteSpace: 'nowrap' }}>
          Volkswagen?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
        </span>
      </>
    );
  }
};

const getDudasFields = (quieroVolkswagen: string) => {
  if (quieroVolkswagen === 'true') {
    return (
      <OneElementTemplateFullScreen
        element={
          <MultipleOptionCTAs
            ctaValues={CTAsMotivoRenovador}
            name="selectMotivos"
            maxSelected={3}
            ignoreHandleClick={true}
            exclusiveCTA
            exclusiveCTAValues={CTAsMotivoRenovadorNoTengoExclusivo}
          />
        }
      />
    );
  } else {
    return (
      <OneElementTemplateFullScreen
        element={
          <MultipleOptionCTAs
            ctaValues={CTAsMotivoRenovador}
            name="selectMotivos"
            maxSelected={3}
            ignoreHandleClick={true}
          />
        }
      />
    );
  }
};

export enum NoRenovacionSigueBuscando {
  Introduccion,
  QuieroVolkswagen,
  Modelo,
  TipoCoche,
  OtraMarca,
  Motivo,
  MasInformacion,
  ProblemasVehiculo,
  // QuieroContacto,
  Mapa,
  Confirmacion,
}

export function NoRenovacionNoTengo({
  dpsData,
  idVehicle,
  idPerson,
  handleError,
  trackFormLoad,
  estado,
}: NoRenovacionFormProps) {
  const { initializeForm, handleNextStep } = useOneFormContext();
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { quieroVolkswagen, selectMotivos } = formData.fields;
  const { screenIndex } = formInfo;
  const dispatch = useDispatch();
  const [loadingPresubmit, setLoadingPresubmit] = useState(false);
  const store = useStore();

  useEffect(() => {
    const sendPresubmit = async () => {
      const result = await sendForm(true);

      if (!result) {
        handleError();
      }
      setLoadingPresubmit(false);
    };

    sendPresubmit();
  }, []);

  const updateModeloInteresStep = (quieroVolkswagen: string) => {
    const { steps: actualSteps } = store.getState().formInfo;
    const title = getModeloInteresTitle(quieroVolkswagen);
    const fields = getModeloInteresFields(quieroVolkswagen);
    const outputs = getModeloInteresOutputs(quieroVolkswagen);
    const outputsText = getModeloInteresOutputsText(quieroVolkswagen);

    let newSteps = [...steps];

    const modeloIndex = newSteps.findIndex((step: Steps) => step.name === 'Modelo');

    newSteps[modeloIndex].title = title;
    newSteps[modeloIndex].fields = fields;
    newSteps[modeloIndex].outputs = outputs;
    newSteps[modeloIndex].outputsText = outputsText;

    if (quieroVolkswagen === 'false') {
      newSteps = removeStep(newSteps, 'Mapa');
      dispatch({ type: 'UPDATE_FIELDS', payload: { quieroContacto: 'false' } });
    } else {
      const mapaIndex = getIndexInSteps(newSteps, 'Mapa');
      dispatch({ type: 'UPDATE_FIELDS', payload: { quieroContacto: 'true' } });

      if (mapaIndex === -1) {
        newSteps = addRemovedStep(steps, actualSteps, 'Mapa', screenIndex);
      }
    }

    newSteps = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newSteps });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newSteps[newSteps.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newSteps.length });
  };

  const handleStepsNoVolkswagen = (quieroVolkswagen: string) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
    let newSteps = [...steps];

    if (quieroVolkswagen === 'true') {
      newSteps = [...steps];
    } else {
      newSteps = removeStep(newSteps, 'tipoCoche');
      newSteps = removeStep(newSteps, 'otraMarca');
      newSteps = removeStep(newSteps, 'Mapa');
    }

    newSteps = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newSteps });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newSteps[newSteps.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newSteps.length });
  };

  const steps: Steps[] = [
    {
      title: (
        <>
          <Text bold>Nos encantaría seguir siendo tu compañero de viaje. </Text>
          Cuéntanos qué te hace dudar para poder ayudarte.
        </>
      ),
      fields: <OneElementTemplateFullScreen allowOverflowingContent element={<FakeTriggerCTA />} />,
      screenIndex: NoRenovacionSigueBuscando.Introduccion,
      name: 'introduccion',
    },
    {
      title: (
        <>
          <Text bold>
            ¿Estás valorando un{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              Volkswagen?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsSiNo} name="quieroVolkswagen" ctaWidth="81" nextOnClick />}
        />
      ),
      screenIndex: NoRenovacionSigueBuscando.QuieroVolkswagen,
      name: 'quieroVolkswagen',
      trueOrFalseQuestion: true,
    },
    {
      title: <></>, // Se actualiza en updateModeloInteresStep
      fields: <></>, // Se actualiza en updateModeloInteresStep
      screenIndex: NoRenovacionSigueBuscando.Modelo,
      name: 'Modelo',
      showOverflow: true,
    },
    {
      title: (
        <>
          <Text bold>¿Qué tipo de coche </Text>
          te{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            interesa?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsNuevoOcasión} name="tipoCoche" nextOnClick />}
        />
      ),
      screenIndex: NoRenovacionSigueBuscando.TipoCoche,
      name: 'tipoCoche',
    },
    {
      title: (
        <>
          ¿Estás valorando algún{' '}
          <Text bold>
            modelo de otra{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              marca?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          allowOverflowingContent
          element={
            <SelectMarcaModelo stepName="otraMarca" brandName="brand" modelName="model" required={true} formToken="VW_DDB_FORM_NORENUEVA" nextOnClick alternatCTA />
          }
        />
      ),
      screenIndex: NoRenovacionSigueBuscando.OtraMarca,
      name: 'otraMarca',
      showOverflow: true,
    },
    {
      title: getDudasTitle(quieroVolkswagen),
      alternateTitle: getDudasAlternatTitle(quieroVolkswagen),
      fields: getDudasFields(quieroVolkswagen),
      screenIndex: NoRenovacionSigueBuscando.Motivo,
      name: 'selectMotivos',
    },
    {
      title: (
        <>
          <Text bold>¿Por qué motivos </Text>
          tuviste problemas con tu {' '}
          <span style={{ whiteSpace: 'nowrap' }}>
          vehículo anterior?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
          <Container padding={{ top: ContainerPadding.dynamic0020 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
            Elige un máximo de 3 opciones
            </Text>
          </Container>

        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
        element={
          <MultipleOptionCTAs
            ignoreHandleClick={true}
            ctaValues={CTAsMotivoRenovadorProblemas}
            name="selectMotivosProblems"
            maxSelected={3}
          />
        }
        />
      ),
      screenIndex: NoRenovacionSigueBuscando.ProblemasVehiculo,
      name: 'selectMotivosProblems',
    },
    {
      title: (
        <>
          <Text bold>¿Qué otros motivos </Text>te hacen dudar?
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen element={<DetalleMotivo CTALabel="Eso es todo" label="Escríbelos aquí" />} />
      ),
      screenIndex: NoRenovacionSigueBuscando.MasInformacion,
      name: 'masInformacion',
    },
    {
      screenIndex: NoRenovacionSigueBuscando.Mapa,
      name: 'Mapa',
      multiStep: true,
      component: <MapaNoRenovacion />,
    },
    {
      fields: <ConfirmacionNoRenovacion idPerson={idPerson} />,
      screenIndex: NoRenovacionSigueBuscando.Confirmacion,
      name: 'Confirmacion',
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    fields: {
      formName: 'no_renovacion-no_tengo',
      formType: 'otherform',
      pageCategory: 'Encuesta',
    }, // Analítica
  };
  //mostrar u ocultar pantalla mas información según los ctas de dudas seleccionados

  const updateSteps = (stepName: string, conditional: boolean, addOnNextStepName?: string ) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
    let newSteps = [...actualSteps];
    let newScreenIndex = screenIndex;

    if (conditional) {

      const stepIndex = getIndexInSteps(newSteps, stepName);

      if (stepIndex === -1) {
        if (addOnNextStepName) {
          newScreenIndex = getIndexInSteps(newSteps, addOnNextStepName);
        }
        newSteps = addRemovedStep(steps, actualSteps, stepName, newScreenIndex);
      }
    } else {
      newSteps = removeStep(newSteps, stepName);
    }

    const newStepsWithIndex = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
  };


  const generateData = (isPresubmit: boolean, data?: Partial<FormDataGroup>) => {
    const { formData, dealersInfo }: OneFormState = store.getState();
    const { fields } = formData;
    const { selectedInstallation } = dealersInfo;

    let motivos = fields.selectMotivos;

    if(fields.selectMotivosProblems){
      motivos = motivos.replace("14", fields.selectMotivosProblems);
    }else{
      motivos = deleteProblema(motivos);
    }

    motivos = orderString(motivos);

    const installationData = selectedInstallation && fields.quieroContacto === 'true' ? {
      'fields[SF_LEAD_RECORDTYPE_ID]': '0121i000000D7NyAAK',
      'fields[SF_LEAD_RECORDTYPE_NAME]': 'Sales Assistant',
      'fields[SF_LEAD_SALES_INITIAL_ACTION]': 'Direct to Sales Assistant',
      'fields[SF_LEAD_INSTALLATION_ID]':  selectedInstallation.Id,
      'fields[SF_LEAD_INSTALLATION_CODE]': selectedInstallation.VGED_DealerCode__c,
      'fields[SF_LEAD_INSTALLATION_NAME]': selectedInstallation.VGED_CommercialName__c,
      'fields[SF_LEAD_INSTALLATION_ADDRESS]': selectedInstallation.ShippingStreet,
      'fields[SF_LEAD_INSTALLATION_PROVINCE_CODE]': selectedInstallation.ShippingStateCode.toString().padStart(2, '0'),
    } : null;

    const submitData = !isPresubmit ? {
      ...installationData,
      'fields[COMMENTS_BRAND]': fields.masInformacion
         ? 'Motivos de duda indicados por el cliente en la encuesta: ' + fields.masInformacion
         : motivos,
      'fields[SURVEY_ANSWER_11]': fields.quieroVolkswagen,
      'fields[SURVEY_ANSWER_13]': fields.tipoCoche == 'nuevo' ? '1' : '2',
      'fields[SURVEY_ANSWER_14]': fields.otraMarca == 'false' ? null : fields.otraMarca,
      'fields[SURVEY_ANSWER_16]': fields.quieroVolkswagen == 'true' ? motivos : null,
      'fields[SURVEY_ANSWER_19]': fields.brand,
      'fields[SURVEY_ANSWER_20]': fields.model,
      'fields[SURVEY_ANSWER_21]': fields.quieroVolkswagen == "true" ? null : motivos,   
      'fields[VEH_INTEREST_ID]': fields.carlineCode,
      'fields[VEH_INTEREST_MODEL]': getDescInterestCarline(dealersInfo, fields.modelo),   
      'fields[VEH_INTEREST_NEW_OR_USED]': fields.tipoCoche === 'nuevo' ? 'New Car' : 'Used Car',
    } : null;

    return {
      ...getCommonFormData(isPresubmit, idPerson, idVehicle, dpsData, fields, data, '3'),
      ...submitData,
    };
  };

  const orderString = (orderMotivos) => {

    if (orderMotivos == null) {
      return null;
    }

    const array = orderMotivos.split(",");
  
    const sortedArray = array.sort((a, b) => {
      return parseInt(a) - parseInt(b);
    });
  
    const orderString = sortedArray.join(",");
  
    return orderString;
  };

  const deleteProblema = (motivosProblema) => {
    
    if (motivosProblema != null) {
      const array = motivosProblema.split(",");
    
      if (array.length == 1 && (array[0] == "14" || array[0] == "nada")) {
        return null;
      }

      const motivos = array.filter(numero => numero != "14");
    
      return motivos.join(",");
    }
  };

  const sendForm = async (isPresubmit?: boolean): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateData(isPresubmit, NuevoFormData);
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData, isPresubmit);

      if (response.data?.status?.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { firstStep, lastStep, loading } = useInitializeNoRenovacionForm(steps);

  useEffect(() => {
    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps,
      stepsHistory: [0],
      thankYouComponent: <ThankYouNoRenovacion />,
    };

    if (!loading) {
      initializeForm(NuevoFormInfo, NuevoFormData, true);
      trackFormLoad();
    }
  }, [loading]);

  //activación de lógica para la gestion de steps

  useEffect(() => {
    if (quieroVolkswagen) {
      updateModeloInteresStep(quieroVolkswagen);
      handleStepsNoVolkswagen(quieroVolkswagen);
    }
  }, [quieroVolkswagen]);

  useEffect(() => {
    if (selectMotivos){
      if (!(selectMotivos.includes('14') && selectMotivos.includes('13'))) {
        if (selectMotivos.includes('14')) {
          updateSteps('masInformacion', false);
        }else if (selectMotivos.includes('13')) {
          updateSteps('selectMotivosProblems', false);
        }else{
          updateSteps('masInformacion', false);
          updateSteps('selectMotivosProblems', false);
        }
      }
    }
  }, [selectMotivos]);

  return loading || loadingPresubmit || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
