import { FormDataGroup } from 'src/types';

export const leadsCitaVentaData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Cita' },
  fields: {
    formFields: { COMMENTS_BRAND: 'Petición para reservar una cita en el punto de venta.' },
    formularioFlag: 'prospect',
    formName: 'lead-cita',
    formType: 'contact',
    pageCategory: 'Cita',
  },
};

export const leadsCitaVentaDealerData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Cita' },
  fields: {
    formFields: { COMMENTS_BRAND: 'Petición para reservar una cita en el punto de venta.' },
    formularioFlag: 'prospect',
    formName: 'lead-cita-dealer',
    formType: 'contact',
    pageCategory: 'Cita',
  },
};
