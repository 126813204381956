import Axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Text, styled, ThemeProvider } from '@volkswagen-onehub/components-core';
import { OneElementTemplate } from 'src/feature-app/Screen';
import { 
  Email, 
  PantallaNombreApellido, 
  Telefono, 
  MultipleCTASelection, 
  YaTienesVolkswagen, 
  NoCliente,
  ContactInfoWrapper 
} from 'src/components';
import {
  Mensaje,
  ConfirmacionDiaDeLaMadre,
  ThankYouDiaDeLaMadre
} from './componentsAndViews';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { dpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { FormController, removeWhiteSpace, useFeatureAppConfig, useOneFormContext,formatPrefix } from 'src/feature-app';
import { formDataBuilder } from '../../format-fields';

const CTAsTipoVehiculo = [
  { label: 'Un eléctrico', value: 'electrico' },
  { label: 'Un híbrido', value: 'hibrido' },
  { label: 'Uno de combustión', value: 'combustion' },
  { label: 'Ninguno, de momento', value: 'ninguno' },
];

const getContactoTitle = (cliente: string) => {
  if (cliente === 'true') {
    return (
      <>
        ¡Listo! <Text bold>¿Cómo podemos contactar contigo en caso de ganar el fin de semana?</Text>
      </>
    );
  } else {
    return (
      <>
        <Text bold>¿De parte de quién se lo enviamos?</Text>
      </>
    );
  }
};

export enum DiaDeLaMadreSteps {
  NombreApellido,
  Cliente,
  NoCliente,
  Mensaje,
  EmailTelefono,
  MotherEmail,
  TipoVehiculo,
  Confirmacion,
}

export function DiaDeLaMadreForm() {
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const config = useFeatureAppConfig();
  const dispatch = useDispatch();
  const { cliente } = useSelector((state: OneFormState) => state.formData.fields);
  const { formData } = useSelector((state: OneFormState) => state);

  const [notAClient, setNotAClient] = useState<boolean>(true);

  const handleNotAClient = (cliente: string) => {
    let newSteps = [...steps];

    if (cliente === 'false') {
      newSteps = [...steps];
    } else {
      newSteps.splice(DiaDeLaMadreSteps.NoCliente, 1);
      setNotAClient(false);
    }

    const newStepsWithIndex = newSteps.map((step, index) => {
      step.screenIndex = index;
      return step;
    });

    dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
  };

  const steps: Steps[] = [
    {
      title: (
        <>
          Para poder enviar tu mensaje, necesitamos saber <Text bold>quién eres</Text>
        </>
      ),
      fields: <OneElementTemplate element={<PantallaNombreApellido />} />,
      screenIndex: DiaDeLaMadreSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      title: (
        <>
          Ya tienes un <Text bold>Volkswagen, </Text>
          <span style={{ whiteSpace: 'nowrap' }}>
            <Text bold>¿verdad? </Text>
            <sup style={{ fontSize: '60%' }}>1</sup>
          </span>
        </>
      ),
      fields: <OneElementTemplate element={<YaTienesVolkswagen />} />,
      screenIndex: DiaDeLaMadreSteps.Cliente,
      name: 'Cliente',
      outputs: ['cliente'],
      outputsText: ['cliente'],
    },
    {
      title: (
        <>
          Este sorteo es <Text bold>solo para clientes Volkswagen </Text>
        </>
      ),
      fields: <OneElementTemplate element={<NoCliente name="noCliente" nextOnClick bodyCopy= 'Aunque darle amor a una madre está al alcance de cualquiera. ¿Te apetece seguir con el mensaje?'/>} />,
      screenIndex: DiaDeLaMadreSteps.NoCliente,
      name: 'NoCliente',
      outputs: ['noCliente'],
      outputsText: ['noCliente'],
    },
    {
      title: (
        <>
          Pues vayamos a lo que importa. ¿Qué te gustaría{' '}
          <Text bold>
            decirle a tu{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              madre?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: <OneElementTemplate element={<Mensaje />} />,
      screenIndex: DiaDeLaMadreSteps.Mensaje,
      name: 'Mensaje',
      outputs: ['messageText'],
      outputsText: ['messageText'],
    },
    {
      title: getContactoTitle(cliente),
      fields: (
        <OneElementTemplate
          element={
            <ContactInfoWrapper>
              <Telefono paginaConfirmacion={false} required={true} />
              <Email paginaConfirmacion={false} required={true} paddingTop='24px'/>
            </ContactInfoWrapper>
          }
        />
      ),
      screenIndex: DiaDeLaMadreSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      title: (
        <>
          Y, <Text bold>¿a qué email le hacemos llegar la sorpresa a tu madre?</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={<Email paginaConfirmacion={false} required={true} name="otherEmail" label="Email de tu madre" />}
        />
      ),
      screenIndex: DiaDeLaMadreSteps.MotherEmail,
      name: 'MotherEmail',
      outputs: ['otherEmail'],
      outputsText: ['otherEmail'],
    },
    {
      title: (
        <>
          Antes de terminar, tenemos curiosidad: ¿qué tipo de coche te ves{' '}
          <Text bold>
            conduciendo{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              próximamente?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={<MultipleCTASelection ctaValues={CTAsTipoVehiculo} name="tipoVehiculo" nextOnClick />}
        />
      ),
      screenIndex: DiaDeLaMadreSteps.TipoVehiculo,
      name: 'TipoVehiculo',
      outputs: ['tipoVehiculo'],
      outputsText: ['tipoVehiculo'],
    },
    {
      fields: <ConfirmacionDiaDeLaMadre />,
      screenIndex: DiaDeLaMadreSteps.Confirmacion,
      name: 'Confirmacion',
      isLastStep: true,
      hidePreviousStep: true,
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_VN_VO: 'VN',
      DESC_GDPR_ADAPTED: 'true',
    },
    fields: { formName: 'adhoc-2021-diamadre', formType: 'otherform', pageCategory: 'Concurso' }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData }: OneFormState = store.getState();

    const { fields, headers } = formData;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      const data = {
        'headers[COD_FORM_ID]': 'vw-oneform-adhoc_diamadre_2021',
        'headers[DESC_URLORIGIN]': url,
        'headers[DESC_BRAND]': 'VW',
        'headers[DESC_CAMPAIGNBLOCK]': 'B16 One Shot',
        'headers[DESC_DEVICETYPE]': device,
        'headers[DESC_FORMOBJECTIVE]': 'Participación Concurso',
        'headers[DESC_FORM_DETAIL]': 'Dia Madre 2021', //Este debería pasarse a variable, aunque actualmente siempre sea ID
        'headers[DESC_ID_CAMPAIGN]': '7011n000000Jo8rAAC',
        'headers[DESC_TOKEN]': 'VW_DDB_FRM_DIAMADRE',

        'headers[DESC_VN_VO]': 'VN',
        'headers[FECHA_INSERTION_DATE]': today,
        'headers[TC]': tc,
        'headers[DESC_GDPR_ADAPTED]': true,

        'fields[DESC_NAME]': fields.name,
        'fields[DESC_SURNAME_1]': fields.surname,
        'fields[DESC_SURNAME_2]': fields.secondSurname,
        'fields[DESC_EMAIL_ADDRESS]': removeWhiteSpace(fields.email),
        'fields[NUM_CELLPHONE]': `${formatPrefix(fields.prefix)}${fields.cellphone}`,
        'fields[DESC_AUXFIELD1]': fields.messageText,
        'fields[DESC_AUXFIELD2]': removeWhiteSpace(fields.otherEmail),
        'fields[DESC_AUXFIELD3]': fields.tipoVehiculo,
        'fields[DESC_AUXFIELD4]': fields.cliente,
        'fields[DESC_FORM_VERSION]': `vw-oneform-adhoc_diamadre_2021-${process.env.VERSION}`,
        'fields[DESC_LOPD]': 'PERMITE',
        'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
        'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
      };
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData);

      if (response && response.data && response.data.content && response.data.content.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      formTheme: 'inverted',
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      thankYouComponent: <ThankYouDiaDeLaMadre />,
    };

    initializeForm(NuevoFormInfo, NuevoFormData);
  }, []);

  useEffect(() => {
    if (cliente) handleNotAClient(cliente);
  }, [cliente]);

  return (
    <ThemeProvider theme="inverted">
      <FormController steps={steps} screenType="layer-screen" />
    </ThemeProvider>
  );
}
