import React from 'react';
import { styled } from '@volkswagen-onehub/components-core';
import { keyframes } from 'styled-components';

const ColorBarWrapper = styled.div`
  position: relative;
  margin-top: 16px;
  height: 5px;
  width: 100%;
  overflow: hidden;
`;

const greyAnimate = keyframes`
  0% { transform: translateX(0) }
  100% { transform: translateX(100%) }
`;

const GreyBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 0 100px 100px 0;
  background-color: #DFE4E8;
  &.start {
    animation: ${greyAnimate} 5s linear infinite normal;
  }
`;
const ColorBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 100px;
  background:  #00B0F0;
`;

export const ProgressBar = () => {
  return (
    <ColorBarWrapper>
      <ColorBar/>
      <GreyBar className='start' id='animated-bar'/>
    </ColorBarWrapper>

  );
};
