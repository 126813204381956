import { FormDataGroup } from 'src/types';

export const leadsMasInformacionPartialData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Más Información' },
  fields: {
    formFields: {},
    formularioFlag: 'masInformacion',
    formName: 'lead-mas_informacion',
    formType: 'contact',
    pageCategory: 'Más Información',
  },
};

export const leadsMasInformacionDealerPartialData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Más Información' },
  fields: {
    formFields: {},
    formularioFlag: 'masInformacion',
    formName: 'lead-mas_informacion-dealer',
    formType: 'contact',
    pageCategory: 'Más Información',
  },
};
