import { CarlineBase, CarlinesWithSalesgroups, FormularioFlagType, OneFormState, Salesgroup } from 'src/types';
import { formatCarlineSelectExtendedList } from '.';

export const formatAlphabetical = (carlines: CarlineBase[]) => {
  return carlines
    .map((carline) => formatCarlineSelectExtendedList(carline))
    .map((carline) => {
      if (carline.name.includes('Nuevo')) {
        // Pasamos de Nuevo T-Roc a T-Roc   ZNuevo para que el "Nuevo" no interfiera al ordenar y la parte de Nuevo no tenga preferencia sobre otras.
        carline.name = carline.name.replace('Nuevo ', '').concat('  ZNuevo');
        return carline;
      }
      return carline;
    })
    .filter((c) => c !== null)
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
    .map((carline) => {
      if (carline.name.includes('ZNuevo')) {
        // Hacemos la operación contraria para devolver el orden correcto.
        carline.name = `Nuevo ${carline.name.replace('  ZNuevo', '')}`;
        return carline;
      }
      return carline;
    });
};
