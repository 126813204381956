import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTreatment, fetchTreatmentLegacy } from 'src/bff';
import { SelectExtendedList } from './CarlineSelect';
import { SelectExtended } from './SelectExtended';
import { useIsComerciales } from 'src/feature-app/hooks';

export const Trato = () => {
    const [options, setOptions] = useState<SelectExtendedList[]>([]);
    const dispatch = useDispatch();
    const isComerciales = useIsComerciales();

    useEffect(() => {
        const getTreatment = async () => {
            const response = isComerciales ? await fetchTreatment('LCV_DDB_FRM_LEM_REC_LEM') : await fetchTreatment('VW_DDB_FRM_LEM_REC_LEM');
            const options: SelectExtendedList[] = response.content.map((att, indx) => ({ name: att.attributes[0].value, code: att.attributes[0].name, key: String(indx) }));
            setOptions(options);
            dispatch({ type: 'UPDATE_FIELDS', payload: { trato: options[0].name } });
        };
        getTreatment();
    }, []);

    const handleChange = (event: React.SyntheticEvent) => {
        dispatch({ type: 'UPDATE_FIELDS', payload: { trato: event.currentTarget.value } });
    };

    return (
        <SelectExtended options={options} name="trato" label="Trato" isRequired handleChange={handleChange} onClick={() => { }} />
    );
};
