import {
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Suggestion } from 'src';
import { Suggest, updateMapVariables, useLocateUser } from 'src/feature-app';
import LoadScriptInstance from 'src/feature-app/NewMap/Loadscript';

interface SuggestionScreenProps {
  setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocateWrapper = styled.div`
  overflow: visible;
  padding-top: 44px;
  padding-bottom: 15vh;
  
  @media all and (min-width: 1600px) {
    padding-bottom: 20vh;
  }
`;

export const SuggestionScreen = ({ setShowMap }: SuggestionScreenProps) => {
  const dispatch = useDispatch();
  const {
    value,
    setValue,
    suggestion,
    setSuggestion,
    icon,
    locationSuccess,
    setLocationSuccess,
    handleLocate,
    isLocating,
    loading,
    locationDisabled,
  } = useLocateUser();
  const [searchDisabled, setSearchDisabled] = useState(true);

  const onSuggestionSelectedFn = (suggestion: Suggestion) => {
    updateMapVariables(dispatch, suggestion).then(() => {
      setShowMap(true);
    });
  };

  useEffect(() => {
    if (suggestion) {
      updateMapVariables(dispatch, suggestion).then(() => {
        setSearchDisabled(false);
      });
    }
  }, [suggestion]);

  return (
    <LoadScriptInstance onMapLoad={() => {}}>
      <LocateWrapper className="important-full-screen">
        <Container padding={{ bottom: ContainerPadding.dynamic0150 }}>
          <Text appearance={TokenTextAppearance.headline300}>
            <Text bold>¿Qué taller</Text> quieres que se{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </Container>
        <Container wrap={'always'} gutter={ContainerGutter.static400}>
          <div style={{ position: 'relative', overflow: 'visible' }}>
            <Suggest
              onSuggestionSelectedFn={onSuggestionSelectedFn}
              setSuggestion={setSuggestion}
              value={value}
              setValue={setValue}
              isLocating={isLocating}
              setLocationSuccess={setLocationSuccess}
              formTheme="main"
            />
          </div>
          <Container wrap={'always'} gutter={ContainerGutter.static400}>
            <div>
              <CTA
                tag="button"
                emphasis={locationSuccess ? 'primary' : 'secondary'}
                icon={icon}
                onClick={async (e) => {
                  e.preventDefault();
                  if (!locationSuccess) handleLocate();
                }}
                disabled={loading ? true : false}
                size="small"
              >
                Localiza mi posición actual
              </CTA>
              {locationDisabled ? (
                <div style={{ paddingTop: '32px' }}>
                  <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100}>
                    Tu localización está deshabilitada
                  </Text>
                </div>
              ) : null}
            </div>
            <CTA
              tag="button"
              onClick={() => {
                setShowMap(true);
              }}
              disabled={loading || searchDisabled ? true : false}
            >
              Buscar
            </CTA>
          </Container>
        </Container>
      </LocateWrapper>
    </LoadScriptInstance>
  );
};
