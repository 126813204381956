import {useEffect, useReducer} from 'react';
import {useDispatch} from 'react-redux';
import {getDataUrl, getSalesgroups} from 'src/bff';
import {OneFormContent, useFaContent, useFeatureServices} from 'src/feature-app';
import {findCarlineFromSalesgroup, findCarline, isCarlineMode, isConfiguratorMode} from 'src/components';
import {CarlineBase, CarlinesWithSalesgroups, SalesGroupInfo, Steps} from 'src/types';
import {useIsComerciales} from 'src/feature-app/hooks';
import {removeStep, resetStepsScreenIndex} from '../helpers/add-remove-steps';
import {getMarketingCode} from 'src/bff';

const getLastItemOfSteps = (steps: Steps[]) => steps[steps.length - 1];
const carlineInFaContent = (faContent: OneFormContent) => faContent?.carlines && faContent.carlines.length === 1;
const salesgroupInFaContent = (faContent: OneFormContent) =>
	faContent?.salesgroups && faContent.salesgroups.length === 1;
const findSalesgroup = (carline: CarlinesWithSalesgroups, salesgroupId: string) =>
	carline?.salesGroups?.find((salesgroup) => salesgroup.code === Number(salesgroupId));

interface LeadsInitialConfig {
	loading: boolean;
	steps: Steps[];
	firstStep: Steps;
	lastStep: Steps;
}

const updateSteps = (steps: Steps[]) => {
	return {
		type: 'UPDATE_STEPS',
		payload: steps,
	};
};

const reducer = (state: LeadsInitialConfig, action: any): LeadsInitialConfig => {
	switch (action.type) {
		case 'UPDATE_STEPS':
			return {
				...state,
				steps: action.payload,
				firstStep: action.payload[0],
				lastStep: getLastItemOfSteps(action.payload),
				loading: false,
			};
		default:
			return state;
	}
};

const initialState: LeadsInitialConfig = {
	loading: true,
	steps: [],
	firstStep: null,
	lastStep: null,
};

export const createSalesgroup = (code: string | number, name: string) => {
	if (!code && !name) {
		return null;
	}
	return {salesGroupId: String(code), salesGroupName: String(name)};
};

export function useInitializeLeadForm(defaultSteps: Steps[], isEmbedded?: boolean, isInPage?: boolean) {
	const dispatch = useDispatch();
	const faContent = useFaContent();
	const {'car-configuration': carConfigurationService} = useFeatureServices();
	const [state, dispatchLead] = useReducer(reducer, initialState);
	const {loading, firstStep, lastStep, steps} = state;
	const isComerciales = useIsComerciales();

	/**
	 * Functions
	 */
	const requestCarlines = async () => {
		let carlines = await getSalesgroups();

		dispatch({type: 'SET_CARLINES', payload: carlines});

		return carlines;
	};

	const getCarlineInfo = async (carline: string) => {
		let dataRes: any;
		await fetch(`${getDataUrl()}/getCarlineSalesgroups/${carline}`)
			.then((res) => res.json())
			.then((data) => (dataRes = data));
		return dataRes;
	};

	const getModelFromUrl = () => {
		const path = window.location.pathname;
		let htmlIndex: number;
		let carlineName: string;
		let salesGroup: string;
		const splittedPath = path.split('/');
		splittedPath.filter((e, index) => e.includes('.html') && (htmlIndex = index));
		carlineName = splittedPath[htmlIndex + 1]?.replace('.app', '');

		const hasSalesGroup = () => splittedPath[htmlIndex + 2]?.includes('.app');
		hasSalesGroup() && (salesGroup = splittedPath[htmlIndex + 2]?.replace('.app', ''));
		return [carlineName, salesGroup];
	};

	const findAsset = (mediaAssets: any[], assetName: string) => mediaAssets.find((asset) => asset.mpsId === assetName);

	const getAsset = (mediaAssets: any[], assetName: string): string => {
		const asset = findAsset(mediaAssets, assetName);
		let assetUrl = asset.asset;

		if (assetUrl.includes('r-media') && assetUrl.includes('.jpg')) {
			assetUrl = assetUrl.replace('.jpg', '.png');
		}

		return assetUrl;
	};

	/**
	 * Set initial Product Data based on Form Context and returns Carline and Salesgroup.
	 */
	const setInitialProductData = (carlines: CarlineBase[]): {carline: CarlineBase; salesgroup: SalesGroupInfo} => {
		let carlineCode: string = carConfigurationService.get().carlineId;
		let salesgroupId = carConfigurationService.get().salesGroupId;

		let carline = undefined;
		let salesgroup = undefined;

		if ((isEmbedded && faContent?.oferta) || (isEmbedded && faContent?.match)) {
			// Ofertas or Match Form Embedded
			let offerOrPreconfiguration = faContent?.oferta ? faContent.oferta : faContent.match;
			carline = findCarline(carlines, offerOrPreconfiguration.carline.code);
			salesgroup = createSalesgroup(
				faContent.marketingCodeConfig?.SalesgroupId,
				faContent.marketingCodeConfig?.SalesgroupName,
			);

			//Initialize MarketingCode and store it
			getMarketingCode(offerOrPreconfiguration.marketingCode)
				.then((res) => {
					console.log(res);
					dispatch({
						type: 'UPDATE_FIELDS',
						payload: {marketingCodeResponse: res.response},
					});
				})
				.catch((err) => {});

			dispatch({type: 'SET_OFERTA', payload: offerOrPreconfiguration});
		} else if (isConfiguratorMode(faContent) && carlineCode && salesgroupId) {
			// Showroom Context
			carline = findCarline(carlines, Number(carlineCode), Number(salesgroupId));
			const salesgroupCarline = findSalesgroup(carline, salesgroupId);
			salesgroup = createSalesgroup(salesgroupCarline?.code, salesgroupCarline?.name);
		} else if (carlineInFaContent(faContent)) {
			carline = findCarline(carlines, faContent.carlines[0]);
		} else if (salesgroupInFaContent(faContent) && !isCarlineMode(faContent)) {
			// Salesgroup in faContent
			carline = findCarlineFromSalesgroup(carlines, Number(faContent.salesgroups[0]));
			const salesgroupCarline = findSalesgroup(carline, String(faContent.salesgroups[0]));
			salesgroup = createSalesgroup(salesgroupCarline?.code, salesgroupCarline?.name);
		}

		dispatch({
			type: 'SET_SELECTED_CARLINE',
			payload: carline,
		});

		if (carline) {
			dispatch({
				type: 'UPDATE_FIELDS',
				payload: {modelo: carline.title},
			});
		}

		dispatch({
			type: 'SET_SALESGROUP',
			payload: salesgroup,
		});

		return {carline, salesgroup};
	};

	const removeStepAndReset = (steps: Steps[], stepName: string) => {
		let updatedSteps = [...steps];
		updatedSteps = removeStep(steps, stepName);
		updatedSteps = resetStepsScreenIndex(steps);
		return updatedSteps;
	};

	/**
	 * Set initial Form with Marketing Code
	 */
	const useLeadsWithMarketingCode = (marketingCode: string) => {
		getMarketingCode(marketingCode)
			.then((res) => {
				const D6MOFASideview = getAsset(res.response.content?.consolidatedData?.meta.mediaAssets, 'D6MOFASideview');
				const D6Explore1 = getAsset(res.response.content?.consolidatedData?.meta.mediaAssets, 'D6Explore1');
				console.log(res);
				const modelName = res.marketingCodeConfig.ModelName;
				const marketingCodeData = {marketingCode, modelName, mediaAssets: {D6MOFASideview, D6Explore1}};

				let finalSteps: Steps[] = [...defaultSteps];

				finalSteps = removeStepAndReset(finalSteps, 'Modelo');

				if (!isComerciales) {
					finalSteps = removeStepAndReset(finalSteps, 'CodigoPostal');
				}

				dispatchLead(updateSteps(finalSteps));

				dispatch({type: 'SET_DELETE_CLOSE_AND_PREVIOUS', payload: true});

				dispatch({
					type: 'UPDATE_FIELDS',
					payload: {marketingCodeData, marketingCodeResponse: res.response},
				});
			})
			.catch((err) => {
				requestCarlines().then((carlines) => {
					const {carline, salesgroup} = setInitialProductData(carlines);

					let finalSteps: Steps[] = [...defaultSteps];

					if (!isComerciales) {
						finalSteps = removeStepAndReset(finalSteps, 'CodigoPostal');
					}
					if (carline) {
						finalSteps = removeStepAndReset(finalSteps, 'Modelo');
					}
					dispatchLead(updateSteps(finalSteps));
				});
				// console.log(err, 'error');
			});
	};

	/**
	 * Set initial form with Model
	 */
	const useLeadsWithModel = async (carline: string, salesGroup?: string) => {
		await getCarlineInfo(carline)
			.then((res) => {
				let colorCode = res.carline.colores.exterior.hexadecimal;
				let D6MOFASideview: string, D6Explore1: string, modelName: string, salesGroupData: any;
				const carlineCode: number = res.carline.code;

				const setDataNoSalesGroup = () => {
					D6MOFASideview = res.carline.mediaAssets.D6MOFASideview;
					D6Explore1 = res.carline.mediaAssets.D6MainStageExterior;
					modelName = res.carline.title;
				};

				if (salesGroup) {
					const salesGroupCoincidences = res.carline.salesgroups.filter((e) => e.name === salesGroup);
					salesGroupData = salesGroupCoincidences[0];
				}
				if (salesGroupData) {
					try {
						D6MOFASideview = salesGroupData.mediaAssets.D6MOFASideview;
						D6Explore1 = salesGroupData.mediaAssets.D6MainStageExterior;
						colorCode = salesGroupData.colores?.exterior?.hexadecimal;
						modelName = salesGroupData.title;
					} catch (error) {
						console.error('No data for salesgroup found');
						setDataNoSalesGroup();
					}
				} else {
					setDataNoSalesGroup();
				}

				const carlineData = {modelName, colorCode, mediaAssets: {D6MOFASideview, D6Explore1}};

				let finalSteps: Steps[] = [...defaultSteps];

				finalSteps = removeStepAndReset(finalSteps, 'Modelo');

				if (!isComerciales) {
					finalSteps = removeStepAndReset(finalSteps, 'CodigoPostal');
				}

				dispatchLead(updateSteps(finalSteps));

				dispatch({type: 'SET_DELETE_CLOSE_AND_PREVIOUS', payload: true});

				dispatch({
					type: 'UPDATE_FIELDS',
					payload: {carlineData, carlineResponse: res, carlineCode, modelo: modelName},
				});

				dispatch({type: 'SET_SELECTED_CARLINE', payload: res.carline});
				if (salesGroupData) {
					dispatch({
						type: 'SET_SALESGROUP',
						payload: {salesGroupId: salesGroupData.code, salesGroupName: salesGroupData.title},
					});
				}
			})
			.catch((err) => {
				requestCarlines().then((carlines) => {
					const {carline, salesgroup} = setInitialProductData(carlines);

					let finalSteps: Steps[] = [...defaultSteps];

					if (!isComerciales) {
						finalSteps = removeStepAndReset(finalSteps, 'CodigoPostal');
					}
					if (carline) {
						finalSteps = removeStepAndReset(finalSteps, 'Modelo');
					}
					dispatchLead(updateSteps(finalSteps));
				});
				// console.log(err, 'error');
			});
	};

	const getModelInfo = async (model: string) => {
		let dataRes: any;
		await fetch(`${getDataUrl()}/getCarlineSalesgroups/${model}`)
			.then((res) => res.json())
			.then((data) => (dataRes = data));
		return dataRes;
	};

	const ofertasUrl = () => {
		const path = window.location.pathname;
		return path.includes('ofertas');
	};

	useEffect(() => {
		// Si el formulario es inPage la inicialización del formulario dependerá de variables en la URL, sinó de faContent
		if (isInPage) {
			const urlParams = new URLSearchParams(window.location.search);
			const marketingCode = urlParams.get('marketingCode');
			if (marketingCode) {
				useLeadsWithMarketingCode(marketingCode);
			} else {
				const [carline, salesGroup] = getModelFromUrl();
				useLeadsWithModel(carline, salesGroup);
			}
		} else {
			requestCarlines().then((carlines) => {
				const {carline, salesgroup} = setInitialProductData(carlines);

				let finalSteps: Steps[] = [...defaultSteps];

				if (!isComerciales) {
					finalSteps = removeStepAndReset(finalSteps, 'CodigoPostal');
				}
				if (carline) {
					finalSteps = removeStepAndReset(finalSteps, 'Modelo');
				}
				dispatchLead(updateSteps(finalSteps));
			});
		}
	}, []);

	return {loading, steps, firstStep, lastStep};
}
