import React, { useEffect } from 'react';
import { OneFormState, Steps } from 'src/types';
import { RenderController } from 'src/feature-app/Screen';
import { Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { TrackingForm, useTrackingManager } from 'src/feature-app';
import { useDispatch, useSelector } from 'react-redux';

export function ThankYouConcursoRodaje() {
  const { formInfo } = useSelector((state: OneFormState) => state);
  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const getTrackingForm = (): TrackingForm => ({
    LeadID: undefined,
    FormFields: undefined,
  });

  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formInfo?.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: "ThankYou" } } });

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm()
    );
  }, []);

  const screen: Steps = {
    title: (
      <>
        <Text bold>¡Gracias, {formInfo.formResponse.params.fields.DESC_NAME}!</Text>
        <br />
        <Text appearance={TokenTextAppearance.copy100}>Te confirmamos que tus datos se han registrado correctamente</Text>
      </>
    ),
    fields: <></>,
  };
  return (
    <RenderController
      screenType="full-screen"
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
      thankYouPage
    />
  );
}
