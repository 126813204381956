import React, { useState } from 'react';
import { MobileCard } from '.';
import {
  styled,
  ThemeProvider,
} from '@volkswagen-onehub/components-core';
import { TabsNavigation } from '.';
import { useWindowHeight } from '@react-hook/window-size';
import { TriggerProps } from 'src/feature-app';
import { CarlineWithOffers } from 'src/types';

interface styleProps {
  height?: number;
}

const TabsWrapper = styled.div<styleProps>`
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;
const BackgroundImage = styled.img<styleProps>`
  height: auto;
  min-height: 100vh;
  min-width: 100vw;
  width: auto;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const ContentWrapper = styled.div<styleProps>`
  position: ${(props)=> props.height < 500 ? 'relative' : 'absolute'};
  top: 0;
  left: 0;
  min-height: 400px;
`;

const mobileBackgroundUrl = 'https://assets.volkswagen.com/is/image/volkswagenag/background-gradient_mobile-v2?Zml0PWNyb3AlMkMxJndpZD0zOTAmaGVpPTY5MyZmbXQ9anBlZyZxbHQ9NzkmYmZjPW9mZiZjYmIy';
interface TabsProps {
  carlinesWithOffers: CarlineWithOffers[];
}

export const Tabs = (props: TabsProps & TriggerProps) => {
  const { carlinesWithOffers, setSelectedJourney } = props;

  const [ activeTab, setActiveTab ] = useState<number>(0);
  const height= useWindowHeight();


  return (
    <ThemeProvider theme='image'>
      <TabsWrapper>
        <BackgroundImage src={mobileBackgroundUrl} className='background-image' height={height}/>
        <ContentWrapper className="outlet" height={height}>
          { 
            activeTab === 0 ? (
              <MobileCard setSelectedJourney={setSelectedJourney} carlineWithOffers={carlinesWithOffers[0]} content={carlinesWithOffers[0].ofertas[0]}/>
            ) : activeTab === 1 ? (
              <MobileCard setSelectedJourney={setSelectedJourney} carlineWithOffers={carlinesWithOffers[1]} content={carlinesWithOffers[1].ofertas[0]}/>
            ) : (
              <MobileCard setSelectedJourney={setSelectedJourney} carlineWithOffers={carlinesWithOffers[2]} content={carlinesWithOffers[2].ofertas[0]}/>
            ) 
          }
        </ContentWrapper>
        <TabsNavigation setActiveTab={setActiveTab} offers={carlinesWithOffers} activeTab={activeTab}  height={height}/>
      </TabsWrapper>
    </ThemeProvider>
  );
};
