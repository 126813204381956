import {getInstallations, reportErrorSentry} from 'src/bff';
import {DpsData} from 'src/feature-service/es-oneform-core-feature-service/types';
import {arrayToObject, transformDate} from 'src/helpers';
import {IdDealer} from 'src/types';

export const valueWrongMarca = 1;
export const valueWrongModelo = 2;
export const valueWrongConcesion = 3;
export const valueWrongPersona = 4;

export const stepNameToFieldName = (str: string) => {
	if (!str) return str;
	return str.charAt(0).toLowerCase() + str.slice(1);
};

export const sanitizeKvps = (kvps: string) => {
	if (kvps.substring(0, 3) == 'ESP') {
		kvps = kvps.substring(3);
	}

	if (kvps.substring(kvps.length - 1) == 'V') {
		kvps = kvps.substring(0, kvps.length - 1);
	}

	if (kvps.substring(kvps.length - 1) == 'N') {
		kvps = kvps.substring(0, kvps.length - 1);
	}

	return kvps;
};

export const formatSinRespuestaValue = (answer: string) => {
	if (!answer) return undefined;
	if (answer == 'Sin respuesta' || Number(answer) == -1) return '9';
	return answer;
};

interface Data {
	[key: string]: string;
}

class CEMDataError extends Error {
	constructor(message) {
		super(message);
		this.name = 'CEMDataError';
	}
}

export const checkUndefinedsInData = (data: Data, form: string) => {
	Object.entries(data).map((entry) => {
		const [field, data] = entry;
		if (data?.includes('undefined')) {
			const error = new CEMDataError(`Undefined en el formulario CEM ${form}. ${field} tiene el valor ${data}`);
			reportErrorSentry(error);
		}
	});
};

export const getCemDate = (date: string) => {
	const cemDate1 = transformDate(date, 'DD/MM/YYYY', 'YYYY-MM-DD');
	const cemDate2 = transformDate(date, 'DD/MM/YYYY', 'DD-MM-YYYY');
	const cemDate3 = transformDate(date, 'DD/MM/YYYY', 'DD/MM/YYYY');
	if (cemDate1) {
		return cemDate1 + ' 12:00:00';
	} else if (cemDate2) {
		return cemDate2 + ' 12:00:00';
	} else if (cemDate3) {
		return cemDate3 + ' 12:00:00';
	}
	return '';
};

export const getInstallation = async (dpsData: DpsData, idDealer: IdDealer) => {
	const installationsRaw = await getInstallations('VW_DDB_FRM_LEM_REC_LEM', 'VOLKSWAGEN_SF_INSTALLATION_ALL');
	if (!installationsRaw) {
		console.error('Installations not found');
		return null;
	}
	const installations = installationsRaw.map((instal) => arrayToObject(instal.attributes)) as IdDealer[];
	let installation = null;

	if (dpsData.dealer) {
		installation = installations.find((instal) => instal.VGED_DealerCode__c == dpsData.dealer) as IdDealer;
	} else if (dpsData.kvps) {
		installation = installations.find(
			(instal) => sanitizeKvps(instal.KVPSCode__c) == sanitizeKvps(dpsData.kvps),
		) as IdDealer;
	}

	if (!installation && idDealer.VGED_DealerCode__c) {
		installation = installations.find((instal) => instal.VGED_DealerCode__c == idDealer.VGED_DealerCode__c) as IdDealer;
	} else if (!installation && idDealer.KVPSCode__c) {
		installation = installations.find(
			(instal) => sanitizeKvps(instal.KVPSCode__c) == sanitizeKvps(idDealer.KVPSCode__c),
		) as IdDealer;
	}

	if (installation) {
		if (
			installation.VGED_DealerCode__c == '03905' ||
			sanitizeKvps(installation.KVPSCode__c) == '00494' ||
			installation.VGED_CommercialName__c == 'SAFA FUENGIROLA'
		) {
			installation.VGED_CommercialName__c = 'SAFAMOTOR';
		}

		return installation;
	}

	console.error('Installation not found into installations');
	return null;
};

export const getSfAddressRest = (addressSf: string, node?: string) => {
	const address = addressSf
		.replace(/^SfAddressRest\(|\)$/g, '')
		.split(', ')
		.reduce((obj, pair) => {
			const [key, value] = pair.split('=');
			obj[key] = value === 'null' ? null : value;
			return obj;
		}, {});

	if (node) {
		return address[node];
	} else {
		return address;
	}
};
