import React, { useEffect } from 'react';
import { TriggerProps, useFaContent, ScreenController } from 'src/feature-app';
import { BajaComunicacionesController } from 'src/forms/BajaComunicaciones';

export function BajaComunicacionesTrigger({ setSelectedJourney }: TriggerProps): JSX.Element {
  const faContent = useFaContent();

  return (
    <>
      <ScreenController
        selectedJourney={<BajaComunicacionesController />}
        closeLayerCallback={close}
        handleAreYouSureLayer={() => null}
        notALayer={true}
        formType = 'inPage'
      />
    </>
  );
}
