import { Breakpoints, Layout, styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Points } from 'src/types';

const InnerCardContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 21;
`;

const AnimatedCard = styled.div`
  height: 100%;
  width: 100%;
  &.alert-enter,
  &.alert-appear {
    transform: translateY(100%);
  }
  &.alert-enter-active,
  &.alert-appear-active {
    transform: translateY(0);
    transition: transform 400ms;
  }
  &.alert-exit {
    transform: translateY(0%);
  }
  &.alert-exit-active {
    transform: translateY(100%);
    transition: transform 400ms;
  }
`;

const CloseDiv = styled.button<translateYProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #001e50;
  height: 35px;
  border: none;
  cursor: pointer;
  left: 50%;
  outline: none;
  position: fixed;
  top: 0;
  transform: translate(-50%, 0) translateY(${(props) => props.translateY}px);
  z-index: 22;

  &.close-enter {
    top: 100%;
  }
  &.close-enter-active {
    top: 0%;
    transition: top 400ms;

    /* transform: translate(-50%, 0) translateY(${(props) => props.translateY}px);
    transition: transform 400ms; */
  }
  &.close-exit {
    transform: translateY(0%);
  }
  &.close-exit-active {
    transform: translateY(100%);
    transition: transform 400ms;
  }
`;

const Marker = styled.div`
  height: 4px;
  width: 44px;
  border-radius: 2px;
  background-color: #ffffff;
  margin-top: 1.5vh;
  opacity: 0;
  &.marker-enter {
    opacity: 0.1;
    transform: scale(1.5);
    display: block;
  }
  &.marker-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: transform 200ms, opacity 200ms ease-in;
  }
  &.marker-enter-done {
    opacity: 1;
  }
`;

const TopBar = styled.div`
  height: 32px;
  width: 100%;
  background-color: #001e50;
  position: sticky;
  top: 0;
  z-index: 3;
`;

interface translateYProps {
  translateY: number;
  showAllCardsMobile: boolean;
}
//max-height: 100vh;
//overflow-y: scroll;
const InnerCard = styled.div<translateYProps>`
  background-color: #001e50;
  border-radius: 12px 12px 0px;
  height: 100%;
  left: 50%;

  position: absolute;
  top: 53%;
  transform: translate(-50%, -50%) translateY(${(props) => props.translateY}px);
  width: 100%;
  z-index: 1000;
`;

interface CardProps {
  children: React.ReactNode;
  closeCard: () => void;
  showCard: boolean;
  selectedPoint: Points;
  showAllCardsMobile: boolean;
}

const fullLayout = [{ name: 'a', columns: 24 }];
const layoutWithMargins = [
  { name: '.', columns: 2 },
  { name: 'a', columns: 20 },
  { name: '.', columns: 2 },
];

export function Card(props: CardProps) {
  const { closeCard, children, showCard, selectedPoint, showAllCardsMobile } = props;
  const [isCardOpen, setIsCardOpen] = useState<boolean>(false);
  const [animateCarousell, setAnimateCarousell] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<number | null>(null);
  const [translateY, setTranslateY] = useState<number>(null);
  const [initialY, setInitialY] = useState<number>(null);
  const cardRef = useRef(null);

  useEffect(() => {
    if (selectedPoint && !showAllCardsMobile) {
      if (window.innerHeight > 400) {
        setTranslateY(window ? window.innerHeight - 420 : 344);
        setInitialY(window ? window.innerHeight - 420 : 344);
      } else {
        setTranslateY(0);
        setInitialY(0);
      }
    } else {
      setTranslateY(0);
      setInitialY(0);
    }
  }, [selectedPoint, showAllCardsMobile]);

  useEffect(() => {
    setIsCardOpen(true);
    // document.body.style.overflow = 'hidden';
    return () => {
      // document.body.style.overflow = 'visible';
    };
  }, []);

  useLayoutEffect(() => {
    if (isCardOpen) setTimeout(() => setAnimateCarousell(true), 0);
  }, [isCardOpen]);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.preventDefault();
    const x = e.nativeEvent.touches[0].clientY;
    setInitialValue(x);
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.preventDefault();
    const finalValue = e.nativeEvent.changedTouches[0].clientY;
    finalValue < (initialValue - initialY) * 6 ? more() : less();

    setInitialValue(null);
  };
  const handleMoveStartCard = (e: React.TouchEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.preventDefault();
    if (!initialValue) {
      return;
    }

    const y = e.nativeEvent.changedTouches[0].clientY;
    const result = y - initialValue;

    result >= 0 ? setTranslateY(result + initialY) : setTranslateY(initialY);
  };

  const more = () => {
    setTranslateY(initialY);
  };

  const less = () => {
    closeCard();
    setIsCardOpen(false);
    setAnimateCarousell(false);
  };

  const handleOnClickMap = (e: any) => {
    const innerCard = document.getElementById('inner-card-container');

    if (e.target != innerCard && !innerCard.contains(e.target) && !showAllCardsMobile) {
      closeCard();
      setIsCardOpen(false);
      setAnimateCarousell(false);
    }
  };

  return (
    <>
      <CSSTransition timeout={400} in={isCardOpen} classNames="close">
        <CloseDiv
          onTouchStart={(e) => {
            handleTouchStart(e);
          }}
          onTouchEnd={(e) => {
            handleTouchEnd(e);
          }}
          onTouchMove={(e) => {
            handleMoveStartCard(e);
          }}
          translateY={translateY}
          showAllCardsMobile={showAllCardsMobile}
        >
          <CSSTransition timeout={400} in={isCardOpen} classNames="marker">
            <Marker />
          </CSSTransition>
        </CloseDiv>
      </CSSTransition>
      <InnerCardContainer
        className="inner-container"
        onClick={(e) => {
          e.preventDefault;
          handleOnClickMap(e);
        }}
      >
        <CSSTransition timeout={400} in={isCardOpen} classNames="alert">
          <AnimatedCard>
            <InnerCard
              className="inner-card-scroll"
              id="inner-card-container"
              translateY={translateY}
              ref={cardRef}
              showAllCardsMobile={showAllCardsMobile}
            >
              <TopBar
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onTouchStart={(e) => {
                  handleTouchStart(e);
                }}
                onTouchEnd={(e) => {
                  handleTouchEnd(e);
                }}
                onTouchMove={(e) => {
                  handleMoveStartCard(e);
                }}
              />
              <Layout
                appearance={{
                  [Breakpoints.default]: fullLayout,
                }}
              >
                {children}
              </Layout>
            </InnerCard>
          </AnimatedCard>
        </CSSTransition>
      </InnerCardContainer>
    </>
  );
}
