import React, { useEffect, useState } from 'react';
import { oneformBFFNdpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormController, useOneFormContext } from 'src/feature-app';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { formDataBuilder, formatHeaders } from 'src/forms/format-fields';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { useDispatch, useStore } from 'react-redux';
import { NavidadGTI2024Page } from './NavidadGTI2024Page';

export function NavidadGTI2024Form() {
	const { initializeForm } = useOneFormContext();
	const trackingManager = useTrackingManager();
	const store = useStore();
	const dispatch = useDispatch();

	const steps: Steps[] = [
		{
			fields: <NavidadGTI2024Page />,
			screenIndex: 0,
			name: 'Page',
			outputs: ['page'],
			outputsText: ['page'],
		},
	];

	const initialData: Partial<FormDataGroup> = {
		headers: {
			DPS_TOKEN: 'VW_DDB_NAVIDAD_GTI_2024',
			FORM_OBJECTIVE: 'Concurso',
		},
		fields: {
			formName: 'vw-adhoc-2024-navidad_gti',
			formType: 'otherform',
			pageCategory: 'Concurso',
		},
	};

	const getFormInfo = (): {
		firstStep: Steps;
		lastStep: Steps;
		stepsHistory: number[];
		numberOfScreens: number;
		newSteps?: Steps[];
	} => {
		return {
			firstStep: steps[0],
			lastStep: steps[steps.length - 1],
			stepsHistory: [0],
			numberOfScreens: steps.length,
			newSteps: null,
		};
	};

	const generateData = () => {
		const { formData }: OneFormState = store.getState();
		const { fields } = formData;
		const { tc, today, url, device } = getParamsForm();

		return {
			...formatHeaders(initialData.headers),

			'headers[FORM_NAME]': 'vw-adhoc-2024-navidad_gti',
			'headers[SF_CAMPAIGN_NAME]': 'PENDIENTE',
			'headers[SF_CAMPAIGN_ID]': 'PENDIENTE',
			'headers[FORM_DETAILS]': 'Navidad GTI 2024',
			'headers[ORIGIN_DEVICE_TYPE]': device,
			'headers[FORM_URL]': url,
			'headers[FORM_DATE_INSERTION]': today,
			'headers[ORIGIN_TRACKING_CODE]': tc,

			'fields[DPS_BRAND]': 'Volkswagen',
			'fields[PERSON_NAME]': fields.name,
			'fields[PERSON_SURNAME_1]': fields.firstSurname,
			'fields[PERSON_SURNAME_2]': fields.secondSurname,
			'fields[PERSON_EMAIL]': fields.email,
			'fields[SURVEY_ANSWER_01]': fields.detalles,

			'fields[PERSON_RGPD_PERFILADO]': fields.rgpd ? 'PERMITE' : 'NO INFORMADO',
			'fields[PERSON_RGPD_COM_COMERCIALES]': 'NO INFORMADO',
			'flags[PERSON_RGPD_ADAPTED]': true,
		};
	};

	const sendForm = async (): Promise<boolean> => {
		return new Promise<any>(async (resolve, reject) => {
			const data = generateData();
			const dataStringified = JSON.stringify(data);
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			const response = await oneformBFFNdpsSend(formData);

			if (response?.data?.status?.code == 200) {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
				resolve(true);
			} else {
				dispatch({ type: 'UPDATE_FORM_ENDED', payload: true });
				resolve(false);
			}
		});
	};

	useEffect(() => {
		const { firstStep, lastStep, stepsHistory, numberOfScreens } = getFormInfo();

		const NuevoFormInfo: Partial<FormInfo> = {
			formStarted: true,
			sendFormCallback: sendForm,
			fullScreen: firstStep.fullScreen ? true : false,
			lastStep,
			numberOfScreens,
			screenIndex: firstStep.screenIndex,
			formTheme: 'main',
			notALayer: true,
			formType: 'inPage',
			step: firstStep,
			steps: steps,
			stepsHistory,
		};

		initializeForm(NuevoFormInfo, initialData, true);
		trackingManager.trackFormLoad();
	}, []);

	return <FormController steps={steps} screenType="full-screen" />;
}
