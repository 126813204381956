import React, { useEffect, useState } from 'react';
import { AcordionHorizontal, Tabs } from './Suvperheroes';
import { getOfertasSUV } from 'src/bff';
import { CarlineWithOffers } from 'src/types';
import { useWindowWidth } from '@react-hook/window-size';
import { TriggerProps } from 'src/feature-app';


export function SuvperheroesTrigger({ setSelectedJourney }: TriggerProps) {
  const [carlinesWithOffers, setCarlinesWithOffers] = useState<CarlineWithOffers[]>(null);
  const width = useWindowWidth();

  useEffect(() => {
    getOfertasSUV()
      .then((data) => {
        setCarlinesWithOffers(data);
      });
  }, []);

  return (
    <>
      {
        carlinesWithOffers ? (
          <div className="suvperheroes-wrapper">
            {
              width < 560 ?
                <Tabs setSelectedJourney={setSelectedJourney} carlinesWithOffers={carlinesWithOffers} /> :
                <AcordionHorizontal setSelectedJourney={setSelectedJourney} carlinesWithOffers={carlinesWithOffers} />
            }
          </div>
        ) : null
      }
    </>
  );
}
