import { TrackingForm } from 'src/feature-app';

export interface NDPSField {
  name: string;
  value: string;
}

export const getTrackingForm = (formResponse): TrackingForm => {
  let LeadID;
  if (formResponse && formResponse.content && formResponse.content.data && formResponse.content.data.fields) {
    //LeadID Molecular
    const leadField: NDPSField = formResponse.content.data.fields.find(
      (field: NDPSField) => field.name === 'COD_LEAD_ID'
    );
    LeadID = leadField ? leadField.value : undefined;
  }else if (formResponse && formResponse.content && formResponse.content.headers){
    //LeadID OneFormBFF
    const leadField: NDPSField = formResponse.content.headers.find(
      (header: NDPSField) => header.name === 'FK_SYSTEM'
    );
    LeadID = leadField ? leadField.value : undefined;
  }
  return {
    LeadID,
    FormFields: undefined,
  };
};
