import { styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { OneFormState } from 'src/types';
import { InputError, TextInputExtended, CarlineSelect, PaddingWrapper } from 'src/components';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { CitaFormDataFields } from 'src/forms/CitaPosventa/types';
import { SelectExtended } from '.';

const arrCountries = [
  {
    name: 'ES',
    code: 'ES',
    regex: [/\d{4}[b-df-hj-npr-tv-zB-DF-HJ-NPR-TV-Z]{3}/, /[A-Za-z]{1,2}\d{4}[A-Za-z]{1,2}/],
    errorMessage: 'Ejemplo: 0000BCD o AA0000AA',
  },
  {
    name: 'DE',
    code: 'DE',
    regex: [/[a-zA-Z]{2,5}\d{1,4}/],
    errorMessage: 'Ejemplo: AAABB0000',
  },
  {
    name: 'FR',
    code: 'FR',
    regex: [
      /[a-hj-np-tv-zA-HJ-NP-TV-Z]{2}\d{3}[a-hj-np-tv-zA-HJ-NP-TV-Z]{2}/,
      /\d{2,4}[a-zA-Z]{2,3}\d{2,3}/,
      /\d{2,4}[a-zA-Z]{2,3}\d{1}(a|b|A|B)/,
    ],
    errorMessage: 'Ejemplo: AA000AA o 00000AA00',
  },
  {
    name: 'GB',
    code: 'GB',
    regex: [/[a-zA-Z]{1,2}\d{2}[a-zA-Z]{3}/, /[a-zA-Z]{1}\d{3}[a-zA-Z]{3}/],
    errorMessage: 'Ejemplo: AA00AAA',
  },
  {
    name: 'IT',
    code: 'IT',
    regex: [/[a-zA-Z]{2}\d{3}[a-zA-Z]{2}/],
    errorMessage: 'Ejemplo: AA000AA',
  },
  {
    name: 'PT',
    code: 'PT',
    regex: [/[a-zA-Z]{2}\d{2}[a-zA-Z]{2}/, /\d{2}[a-zA-Z]{2}\d{2}/],
    errorMessage: 'Ejemplo: AA00AA',
  },
  {
    name: 'Otros',
    code: 'Otros',
    regex: [/[a-zA-Z\d\s]{3,}/],
    errorMessage: 'Solo números, letras y espacios',
  },
];

interface paginaConfirmacionProps {
  paginaConfirmacion: boolean;
}

const ContentWrapper = styled.div<paginaConfirmacionProps>`

  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: ${(props) => (props.paginaConfirmacion ? 'flex-start' : 'space-between')};
    flex-wrap: wrap;
    padding-top: 0;
  }
  /* Eliminar flechas del input type number porque descuadra el layout un poco */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const MatriculaAndCountriesWrapper = styled.div<paginaConfirmacionProps>`
  width: 100%;
  display: flex;
  height: max-content;
  @media all and (min-width: 560px) {
    width: 303px;
  }
  @media all and (min-width: 960px) {
    width: 320px;
    margin-right:${(props) => (props.paginaConfirmacion ? '88px' : '0px')};
  }
`;
const MatriculaWrapper = styled.div`
  width: 100%;
  label {
    width: 100%;
    @media screen and (min-width: 960px) {
      width: 170px;
    }
  }
`;
const CoutryWrapper = styled.div`
  margin-right: 24px;
  label {
    width: 109px;
    @media screen and (min-width: 560px) {
      width: 116px;
    }
    @media screen and (min-width: 960px) {
      width: 120px;
    }
  }
`;
const InputErrorWrapper = styled.div`
  &.matricula-error-wrapper {
    p {
      max-width: 179px;
      @media all and (min-width: 560px) {
        max-width: 163px;
      }
      @media all and (min-width: 960px) {
        max-width: 176px;
      }
    }
  }
`;
const AnoMatriculacionWrapper = styled.div<{ paginaConfirmacion: boolean }>`
  margin-top: ${(props) => (props.paginaConfirmacion ? '40px' : null)};
  @media all and (min-width: 960px) {
    margin-top: 0;
  }
`;

interface inputWidthWrapperProps {
  noPaddingTop?: boolean;
}

const InputWidthWrapper = styled.div<inputWidthWrapperProps>`
  width: 100%;
  padding-top: ${(props) => (props.noPaddingTop ? null : '40px')};

  @media all and (max-height: 624px) {
    padding-top: ${(props) => (props.noPaddingTop ? null : '24px')};
  }
  @media all and (min-width: 560px) {
    width: 303px;
  }
  @media all and (min-width: 960px) {
    padding-top: 0;
    width: 320px;
  }
`;

interface MatriculaProps {
  formData: CitaFormDataFields;
  paginaConfirmacion: boolean;
  noPaddingTop?: boolean;
}

const MatriculaYModelo = (props: MatriculaProps) => {
  const { register, errors } = useFormContext();
  const { error, setError } = useOneFormContext();
  const [inputValueAno, setInputValueAno] = useState('');
  const [pristineMatricula, setPristineMatricula] = useState(true);
  const [pristineAno, setPristineAno] = useState(true);
  const [inputValueMatricula, setInputValueMatricula] = useState('');
  const [errorMatricula, setErrorMatricula] = useState(false);
  const [errorAno, setErrorAno] = useState(false);
  const [errorCountry, setErrorCountry] = useState(false);
  const [country, setCountry] = useState<string>('ES');
  const [countryObject, setCountryObject] = useState(null);
  const regExpAno = /^(19|20)\d{2}$/;
  const [regExCounty, setRegExCounty] = useState<RegExp[]>(null);
  const { formData, paginaConfirmacion, noPaddingTop } = props;

  useEffect(() => {
    if (formData.countryPlate) {
      setCountry(formData.countryPlate);
      const countryObject = arrCountries.find((c) => c.name === formData.countryPlate);
      setCountryObject(countryObject);
      setRegExCounty(countryObject.regex);
    } else {
      setCountry('ES');
      setCountryObject(arrCountries[0]);
      setRegExCounty(arrCountries[0].regex);
    }
    if (formData.matricula) {
      setInputValueMatricula(formData.matricula);
      setPristineMatricula(false);
    }
    if (formData.anoMatriculacion) {
      setInputValueAno(formData.anoMatriculacion);
      setPristineAno(false);
    }
  }, []);

  const validateMatricula = (input: string) => {
    const isValid = regExCounty.some((regEx) => regEx.test(input));

    if (isValid) {
      setErrorMatricula(false);
      return true;
    } else {
      setErrorMatricula(true);
      return false;
    }
  };

  const handleChangeCountry = (event: any) => {
    setCountry(event.currentTarget.value);
    const countryObject = arrCountries.find((c) => c.name === event.currentTarget.value);
    setCountryObject(countryObject);
    setRegExCounty(countryObject.regex);
    if (countryObject.name !== '') setErrorCountry(false);
  };

  const handleChangeAno = (e: any) => {
    setInputValueAno(e.target.value);
    setPristineAno(false);
  };

  const handleChangeMatricula = (e: any) => {
    setInputValueMatricula(e.target.value);
    setPristineMatricula(false);
  };

  useEffect(() => {
    if (inputValueAno.length < 4) {
      return;
    } else {
      if (!pristineAno && inputValueAno.match(regExpAno) === null) {
        setErrorAno(true);
      } else if (inputValueAno === '') {
        setPristineAno(true);
        setErrorAno(false);
      } else {
        setErrorAno(false);
      }
    }
  }, [inputValueAno, pristineAno]);

  useEffect(() => {
    if (error) {
      errorAno || pristineAno ? setErrorAno(true) : setErrorAno(false);
      errorMatricula || pristineMatricula ? setErrorMatricula(true) : setErrorMatricula(false);
    }
  }, [error]);

  useEffect(() => {
    if (!errorMatricula && !errorMatricula) setError(false);
  }, [errorMatricula, errorMatricula]);

  return (
    <PaddingWrapper paginaConfirmacion={paginaConfirmacion}>
      <ContentWrapper className="content-matricula-wrapper" paginaConfirmacion={paginaConfirmacion}>
        <MatriculaAndCountriesWrapper className="matricula-wrapper" paginaConfirmacion={paginaConfirmacion}>
          <CoutryWrapper className="select-country-wrapper">
            <SelectExtended
              name="countryPlate"
              label="País"
              value={country}
              required
              options={arrCountries}
              handleChange={handleChangeCountry}
              onClick={() => null}
              updateFields
              setOption={setCountry}
              isRequired={true}
            />
          </CoutryWrapper>
          <MatriculaWrapper>
            <TextInputExtended
              name="matricula"
              label="Matrícula"
              type="text"
              innerRef={register({
                validate: (input) => validateMatricula(input),
                required: {
                  value: true,
                  message: 'Requerido',
                },
                maxLength: 100,
              })}
              isFloating
              onChange={(e) => handleChangeMatricula(e)}
              required
            />
            {errorMatricula && (
              <InputErrorWrapper className="matricula-error-wrapper">
                <InputError className="input-error">{countryObject.errorMessage}</InputError>
              </InputErrorWrapper>
            )}
          </MatriculaWrapper>
        </MatriculaAndCountriesWrapper>
        <AnoMatriculacionWrapper paginaConfirmacion={paginaConfirmacion}>
          <InputWidthWrapper noPaddingTop={noPaddingTop} className='ano-matricula'>
            <TextInputExtended
              name="anoMatriculacion"
              label="Año de matriculación"
              type="number"
              innerRef={register({
                required: {
                  value: true,
                  message: 'Requerido',
                },
                pattern: {
                  value: regExpAno,
                  message: 'Ejemplo: 2010',
                },
              })}
              isFloating
              onChange={(e) => handleChangeAno(e)}
              required
            />
            {errorAno && <InputError className="input-error">Ejemplo: 2010</InputError>}
          </InputWidthWrapper>
        </AnoMatriculacionWrapper>
        {!paginaConfirmacion ? (
          <InputWidthWrapper noPaddingTop>
            <CarlineSelect />
          </InputWidthWrapper>
        ) : null}
      </ContentWrapper>
    </PaddingWrapper>
  );
};

const mapStateToProps = (state: OneFormState) => {
  return {
    formInfo: state.formInfo,
    formData: state.formData.fields,
  };
};

const ConnectedComponent = connect(mapStateToProps)(MatriculaYModelo);

export { ConnectedComponent as MatriculaYModelo };
