import React from 'react';
import { ScreenController, TriggerProps, useFaContent } from 'src/feature-app';
import { TestIDController } from 'src/forms/FormsAdhoc/TestID2023/TestIDController';

export function TestIDTrigger(props: TriggerProps) {


  return (
      <ScreenController
        selectedJourney={<TestIDController />}
        closeLayerCallback={close}
        handleAreYouSureLayer={() => null}
        notALayer={true}
        formType = 'inPage'
      />
  );
}
