import {
  Container,
  CTA,
  styled,
  ThemeProvider,
} from '@volkswagen-onehub/components-core';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Filtros, NewMap, useMapGeneralController } from 'src/feature-app';
import { ArrowLeft } from 'src/icons-core-imports';
import { CTAMapa, CTAServices, ViewWidgetMapaProps } from 'src/widgets';

const MapAndTabsWrapper = styled.div<{ showFilters: boolean }>`
  display: flex;

  .wrapper {
    width: 50%;
    height: 100vh;
    &--tabs {
      max-width: 441px;
      overflow-y: hidden;
    }
    &--map {
      position: relative;
      width: 100%;
      z-index: 4;
      height: 100%;
      background-color: grey;
    }
  }
  .opacity-layer {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: ${(props) => (props.showFilters ? 6 : -1)};
    opacity: ${(props) => (props.showFilters ? 1 : 0)};
    transition: opacity 0.4s;
  }
`;

const ListWrapper = styled.div`
  overflow-y: auto;
  padding-right: 40px;
  // 36px es la medida de los ctas de coche de sustitución y servicio de recogida
  height: calc(85vh - 36px);

  @media all and (min-width: 560px) {
    height: calc(82vh - 36px);
  }
  @media all and (min-width: 768px) {
    height: calc(85vh - 36px);
  }
  @media all and (min-width: 960px) {
    height: calc(60vh - 36px);
  }
  @media all and (min-width: 1600px) {
    height: calc(70vh - 36px);
}
`;

const FilterButton = styled.div`
  position: absolute;
  width: 100%;
  top: 24px;
  /* right: 24px; */
  button {
    background: #ffffff;
  }
`;

const FiltrosWrapper = styled.div`
  min-height: 100vh;
  width: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  transform: translate3d(100%, 0, 0);
  display: none;
  .back-btn {
    position: absolute;
    z-index: 4;
    top: 20px;
    left: 24px;
    @media screen and (min-width: 1600px) {
      top: 24px;
    }
    /* style={{ paddingTop: '12px', paddingLeft: '24px' }} */
  }
  .filtros-component {
    padding-bottom: 0;
    min-height: calc(100vh - 44px);
  }
  &.filtros-enter {
    display: block;
    transform: translate3d(100%, 0, 0);
  }
  &.filtros-enter-active {
    display: block;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
  }
  &.filtros-enter-done {
    display: block;
    transform: translate3d(0, 0, 0);
  }
  &.filtros-exit {
    display: block;
    transform: translate3d(0, 0, 0);
  }
  &.filtros-exit-active {
    display: block;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
  }
  &.filtros-exit-done {
    display: none;
    transform: translate3d(100%, 0, 0);
  }
`;

export const ViewDesktopWidgetMapa = (props: ViewWidgetMapaProps) => {
  const { renderPoints, pointsToList, CTAServicesProps, CTAMapaProps } = props;
  const { carPickupSelected, replacementCarSelected } = CTAServicesProps;
  const [showFiltros, setShowFiltros] = useState<boolean>(false);

  const { searchIsDealer } = useMapGeneralController();

  const handleShowFiltros = () => {
    setShowFiltros(!showFiltros);
  };

  return (
    <>
      <MapAndTabsWrapper className="map-tabs-wrapper" showFilters={showFiltros}>
        <div className="wrapper wrapper--tabs">
          {/* <CloseComponent closeInMap handleAreYouSureLayer={handleAreYouSureLayer} /> */}
          <CTAServices {...CTAServicesProps} />
          <Container horizontalAlign={'center'} stretchContent={true}>
            <div style={{ width: '100%' }}>
              {pointsToList ? (
                <ListWrapper className="wrapper--list">
                  <div style={{ paddingBottom: '32px' }}>
                    {renderPoints(pointsToList, carPickupSelected, replacementCarSelected, false)}
                  </div>
                </ListWrapper>
              ) : null}
            </div>
          </Container>
        </div>
        <div className="wrapper wrapper--map">
          <NewMap
            searchIsDealer={searchIsDealer}
            isWidget
            carPickupSelected={carPickupSelected}
            replacementCarSelected={replacementCarSelected}
          />
          {/* Problemas con el scroll */}
          <FilterButton className="filtros-navbar">
            <ThemeProvider theme="main">
              <CTAMapa {...CTAMapaProps} />
            </ThemeProvider>
          </FilterButton>
        </div>
        <div className="opacity-layer" onClick={() => setShowFiltros(false)} />
      </MapAndTabsWrapper>
      {/* {showFiltros ? ( */}

      <CSSTransition in={showFiltros} timeout={300} classNames="filtros">
        {/* provar primer així i després amb render condicional .
          si es passa a condicional reisar front, probablement s'hauran de treure les css asignades a la clase
          filtros-component del component filtros-wrapper.
          */}
        <FiltrosWrapper className="view-filtros-wrapper">
          <ThemeProvider theme="main">
            <div className="back-btn">
              <CTA
                tag="button"
                emphasis="tertiary"
                size="small"
                onClick={(e: any) => {
                  e.preventDefault();
                  handleShowFiltros();
                }}
                ariaLabel="aria label"
                icon={<ArrowLeft />}
              />
            </div>
            <Filtros
              closeFiltros={() => handleShowFiltros()}
              showFiltros={showFiltros}
              setShowFiltros={setShowFiltros}
              onlySuggest={true}
              concesionario={true}
            />
          </ThemeProvider>
        </FiltrosWrapper>
      </CSSTransition>
      {/* ) : null}*/}
    </>
  );
};
