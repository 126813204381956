import React from 'react';
import { JourneyEmpresasController } from '.';
import { MapGeneralController } from 'src/feature-app';

/**
 * Este componente está creado únicamente para que JourneyEmpresasController tenga acceso a las variables de MapGeneralController.
 */
export const JourneyEmpresasWrapper = () => {
  return (
    <MapGeneralController>
      <JourneyEmpresasController />
    </MapGeneralController>
  );
};
