import React, { useEffect, useRef, useState } from 'react';
import { createPincodeEntity } from '../dps-utils';
import { dpsSend } from 'src/bff';
import { EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { formDataBuilder, formatHeaders } from '../format-fields';
import { GenericErrorLayer, LoadingEncuestas } from 'src/components';
import { getParamsForm, useFeatureServices, removeWhiteSpace, useNavigate, useTrackingManager, getTrackingForm } from 'src/feature-app';
import { FormDataGroup, IdPerson, OneFormState } from 'src/types';
import { useDispatch, useStore } from 'react-redux';
import { TestIDForm } from '../FormsAdhoc/TestID2023/TestIDForm';
import { PlanIdealForm } from '../FormsAdhoc/2023PlanIdeal/PlanIdealForm';

interface AutosubmitControllerProps {
    action: string;
}


export function AutosubmitController({ action }: AutosubmitControllerProps): JSX.Element | null {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [renderTestID, setRenderTestID] = useState(false);
    const [renderPlanIdeal, setRenderPlanIdeal] = useState(false);

    const dpsDataRef = useRef(null);
    const idPersonRef = useRef(null);
    const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

    const trackingManager = useTrackingManager();
    const dispatch = useDispatch();
    const { navigateToThankyouNewsletter } = useNavigate();
    const store = useStore();
    const { formData }: OneFormState = store.getState();

    const initialData: Partial<FormDataGroup> = {
        headers: {
            DESC_BRAND: 'VW',
            DESC_GDPR_ADAPTED: 'true',
            DESC_TOKEN: 'VW_DDB_FRM_NEWSLETTER_ELECTRICOS',
            DESC_FORMOBJECTIVE: 'Suscripción Newsletter',
            COD_FORM_ID: 'vw-oneform-'+action+'-autosubmit',
        },
        fields: {
            formName: action+'-autosubmit',
            formType: 'newsletter',
            pageCategory: 'Suscripción Newsletter',
        }, // Analítica
    };
    dispatch({ type: 'UPDATE_FORM', payload: { ...initialData } });

    const sendForm = (): Promise<boolean> => {
        const idPerson: IdPerson = idPersonRef.current;

        const { fields } = formData;
        return new Promise<any>(async (resolve, reject) => {
            const { tc, today, url, device } = getParamsForm();

            const data = {
                ...formatHeaders(initialData.headers),
                'headers[DESC_URLORIGIN]': url,
                'headers[DESC_CAMPAIGNBLOCK]': '4. VW Newsletter',
                'headers[DESC_DEVICETYPE]': device,
                'headers[DESC_ID_CAMPAIGN]': '7011n000000SLXMAA4',
                'headers[FECHA_INSERTION_DATE]': today,
                'headers[TC]': tc,
                'headers[DESC_VN_VO]': 'VN',

                'fields[DESC_NAME]': idPerson?.FirstName ? idPerson.FirstName : null,
                'fields[DESC_SURNAME_1]': idPerson?.LastName ? idPerson.LastName : null,

                'fields[DESC_FORM_VERSION]': `vw-oneform-${initialData.fields.formName}-${process.env.VERSION}`,
                'fields[DESC_EMAIL_ADDRESS]': idPerson && idPerson.TXT_Email__c ? idPerson.TXT_Email__c : removeWhiteSpace(fields.email),
                'fields[DESC_LOPD]': 'NO INFORMADO',
                'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
                'fields[DESC_LOPD_GDPR_PERFILADO]': 'NO INFORMADO',
                'fields[DESC_LSSI]': 'NO INFORMADO',
            };
            const dataStringified = JSON.stringify(data);
            const dataParsed = JSON.parse(dataStringified);

            const formData = formDataBuilder(dataParsed);
            const response = await dpsSend(formData);

            if (response.data && response.data.content && response.data.content.code === '200') {
                dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
                trackingManager.trackFormSubmissionSuccessMessageLoad(
                    {
                        contentId: undefined,
                    },
                    getTrackingForm(response));
                switch (action){
                  case 'newsletter-electricos':
                    navigateToThankyouNewsletter(action); // Navegación a Thank You Page
                    break;
                  case 'newsletter-electricos-adhoc-2023-test_id': // Render de form Test ID como continuación
                    setRenderTestID(true);
                    break;
                  case 'newsletter-electricos-adhoc-2023-plan_ideal': // Render de Plan Ideal como continuación
                    setRenderPlanIdeal(true);
                    break;
                }
            } else {
                handleError();
            }

        });
    };

    const handleComplete = () => {
        const dpsData = oneFormService.getDpsData();
        const idPerson = createPincodeEntity(dpsData, 'PERSON');
        trackingManager.trackFormLayerLoad();
        dpsDataRef.current = dpsData;
        idPersonRef.current = idPerson;

        if (action === 'newsletter-electricos') {
            if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
                handleError();
            } else if (dpsData.pincode.pincodeUsed) {
                handleError();
            } else {
                sendForm();
            }
        } else {
            sendForm();
            setLoading(false);
        };
    };

    const handleError = () => {
        setError(true);
        setLoading(false);
    };

    useEffect(() => {
        const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

        oneFormCoreDpsIsLoaded.subscribe({
            next(value) { },
            error(err) {
                console.error(err);
                handleError();
            },
            complete() {
                handleComplete();
            },
        });
    }, []);

    if (loading) {
        return <LoadingEncuestas />;
    } else if (error) {
        return (
            <>
                <GenericErrorLayer
                    title="Uy, algo ha pasado"
                    bodycopy="Por alguna razón no hemos podido realizar la petición."
                    notShowCTA={true}
                    icon={false}
                    notALayer={true}
                />
            </>
        );
    } else if (renderTestID) {
        const idPerson: IdPerson = idPersonRef.current;

        return (
            <TestIDForm
                dpsData={dpsDataRef.current}
                idPerson={idPerson}
            />
        );
    } else if (renderPlanIdeal) {
      const idPerson: IdPerson = idPersonRef.current;

      return (
          <PlanIdealForm
              dpsData={dpsDataRef.current}
              idPerson={idPerson}
          />
      );
  }
    return null;
}
