import React from 'react';

//Cita posventa

export const kilometrosValues = [
  '0km - 30.000km',
  '30.000km - 60.000km',
  '60.000km - 90.000km',
  '90.000km - 120.000km',
  '120.000km - 150.000km',
  '150.000km - 180.000km',
  'Más de 180.000km',
  'No estoy seguro',
];

export const kilometrosComercialesValues = [
  '0km - 50.000km',
  '50.000km - 100.000km',
  '100.000km - 150.000km',
  '150.000km - 200.000km',
  '200.000km - 250.000km',
  '250.000km - 300.000km',
  '300.000km - 350.000km',
  'Más de 400.000km',
  'No estoy seguro',
];

export const millageValues = [
  [0, 30000],
  [30000, 60000],
  [60000, 90000],
  [90000, 120000],
  [120000, 150000],
  [150000, 180000],
  [180000, 210000],
];

export const millageComercialesValues = [
  [0, 50000],
  [50000, 100000],
  [100000, 150000],
  [150000, 200000],
  [200000, 250000],
  [250000, 300000],
  [300000, 350000],
  [350000, 400000],
];

export const getKilometrosInfo = (isComerciales: boolean) => {
  if (isComerciales) {
    return { kilometrosValues: kilometrosComercialesValues, millageValues: millageComercialesValues };
  }
  return { kilometrosValues, millageValues };
};

export const franjaHoraria = [
  { code: 'MANANA', name: ' por la mañana' },
  { code: 'MEDIODIA', name: ' al mediodía' },
  { code: 'TARDE', name: ' por la tarde' },
];

export const meses = [
  {
    name: 'Enero',
    number: '01',
  },
  {
    name: 'Febrero',
    number: '02',
  },
  {
    name: 'Marzo',
    number: '03',
  },
  {
    name: 'Abril',
    number: '04',
  },
  {
    name: 'Mayo',
    number: '05',
  },
  {
    name: 'Junio',
    number: '06',
  },
  {
    name: 'Julio',
    number: '07',
  },
  {
    name: 'Agosto',
    number: '08',
  },
  {
    name: 'Septiembre',
    number: '09',
  },
  {
    name: 'Octubre',
    number: '10',
  },
  {
    name: 'Noviembre',
    number: '11',
  },
  {
    name: 'Diciembre',
    number: '12',
  }
];
