import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, getTrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps, IdPerson } from 'src/types';
import { ThankYouTitle, ThankYouFields } from 'src/components';

interface ThankYouCemVentaProductoProps {
	idPerson: IdPerson;
}

export function ThankYouCEMVentaProducto(props: ThankYouCemVentaProductoProps) {
	const { idPerson } = props;
	const { formResponse } = useSelector((state: OneFormState) => state.formInfo);
	const trackingManager = useTrackingManager();
	const dispatch = useDispatch();

	useEffect(() => {
		// Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
		const screenIndexThankyou = formResponse.numberOfScreens;

		dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
		dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: "ThankYou" } } });

		trackingManager.trackFormSubmissionSuccessMessageLoad(
			{
				contentId: undefined,
			},
			getTrackingForm(formResponse)
		);
	}, []);

	const title = '¡Muchas gracias por compartir tu experiencia' + (idPerson.FirstName ? ', ' + idPerson.FirstName : '') + '!';

	const screen: Steps = {
		title: <ThankYouTitle notALayer={true} boldCopyFirst={title} />,
		fields: <ThankYouFields copyBook='Conocer tu opinión es la forma que tenemos de seguir mejorando.' notALayer={true} />,
	};
	return (
		<RenderController
			screenType="full-screen"
			title={screen.title}
			fields={screen.fields}
			key={555}
			avoidTrackFormStepLoad
			thankYouPage
		/>
	);
}
