import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, getTrackingForm } from 'src/feature-app';
import { useGetBrand } from 'src/feature-app/hooks';
import { RenderController } from 'src/feature-app/Screen';
import { DealersInfo, FormDataFields, FormInfo, OneFormState, Steps } from 'src/types';
import { ThankYouTitle, ThankYouFields } from 'src/components';
interface ThankYouProps {
  formData: FormDataFields;
  formInfo: FormInfo;
  dealersInfo: DealersInfo;
  inPage?: boolean;
}

const copysThankYou = [
  {
    name: 'lead-presupuesto',
    titleCopyRegular: 'Pronto recibirás',
    titleCopyBold: 'el presupuesto para tu ',
    bodyCopy: ['Un asesor contactará contigo para hacerte llegar la información que necesitas.'],
  },
  {
    name: 'lead-oferta',
    titleCopyRegular: 'Estás muy cerca de conducir',
    titleCopyBold: 'tu ',
    bodyCopy: ['Un asesor contactará contigo muy pronto para darte todos los detalles sobre esta oferta.'],
  },
  {
    name: 'lead-match',
    titleCopyRegular: 'Estás muy cerca de conducir',
    titleCopyBold: 'tu ',
    bodyCopy: ['Un asesor contactará muy pronto contigo para hacerte una oferta a medida.'],
  },
  {
    name: 'lead-mas_informacion',
    titleCopyRegular: 'Pronto recibirás',
    titleCopyBold: 'más información sobre el ',
    bodyCopy: ['Un asesor contactará contigo para responder a todas tus preguntas.'],
  },
  {
    name: 'lead-stock',
    titleCopyRegular: 'Pronto ',
    titleCopyBold: 'responderemos a tus preguntas',
    bodyCopy: ['Un asesor contactará contigo para explicártelo todo sobre nuestros modelos con disponibilidad inmediata.'],
  },
  {
    name: 'lead-cita',
    titleCopyRegular: 'Te esperamos en',
    titleCopyBold: '',
    bodyCopy: [
      'Ya hemos recibido tu solicitud, ',
      '. Muy pronto, un asesor contactará contigo para terminar de cerrar tu cita.',
    ],
  },
  {
    name: 'lead-test_drive',
    titleCopyRegular: 'Te esperamos en',
    titleCopyBold: '',
    bodyCopy: [
      'Un asesor te llamará pronto para terminar de confirmar contigo los detalles de la prueba de conducción.',
    ],
  },
  {
    name: 'lead-call_center',
    titleCopyRegular: 'Lead enviado a',
    titleCopyBold: '',
    bodyCopy: [
      'Un asesor contactará con el lead para responder a sus preguntas.',
    ],
  }
];

function ThankYouLeads(props: ThankYouProps) {
  const { dealersInfo, inPage } = props;
  const { formInfo, formData } = useSelector((state: OneFormState) => state);
  const { formResponse } = formInfo;
  const { modelo, formName, name: nombre } = formData.fields;
  const { oferta } = dealersInfo;
  const { match } = dealersInfo;

  const dispatch = useDispatch();
  const brand = useGetBrand();
  const trackingManager = useTrackingManager();

  const [completeInfo, setCompletInfo] = useState<any>(null);
  const [completarBold, setCompletarBold] = useState(null);
  const [bodyCopy, setbodyCopy] = useState<string>('');
  const [ctaUrl, setCtaUrl] = useState(null);


  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formInfo.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: 'ThankYou' } } });

    const aux = copysThankYou.filter((a: any) => {
      return formName.includes(a.name);
    });
    setCompletInfo(aux);

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm(formResponse)
    );
  }, []);

  useEffect(() => {
    if (completeInfo && completeInfo.length > 0) {

      if (completeInfo[0].bodyCopy.length === 1) {
        setbodyCopy(completeInfo[0].bodyCopy[0]);
      } else {
        const aux = completeInfo[0].bodyCopy[0] + nombre + completeInfo[0].bodyCopy[1];
        setbodyCopy(aux);
      }

      if (formName === 'lead-oferta') {
        setCompletarBold(oferta.carline.title);
      } else if (formName === 'lead-match') {
        setCompletarBold(match.carline.title);
      } else if (formName === 'lead-stock') {
        setCompletarBold('');
      } else {
        if (formName.includes('lead-presupuesto') || formName.includes('lead-mas_informacion')) {
          setCompletarBold(modelo);
        } else {
          setCompletarBold(dealersInfo.selectedInstallation.VGED_CommercialName__c);
        }
      }
    }
  }, [completeInfo]);

  useEffect(() => {
    if (formName === 'lead-oferta') {
      if (brand === 'V') {
        setCtaUrl(`https://www.volkswagen.es/es/modelos/${oferta.carline.name}.html`);
      } else if (brand === 'N') {
        setCtaUrl(`https://www.volkswagen-comerciales.es/es/modelos/${oferta.carline.name}.html`);
      }
    } else if (formName === 'lead-match') {
      setCtaUrl(`https://www.volkswagen.es/es/modelos/${match.carline.name}.html`);
    }
  }, [dealersInfo]);

  const ofertasComercialesFields = 'En breve te llamaremos desde un número terminado en 904.';

  const screen: Steps = {
    title: (
      <>
        {completeInfo && completeInfo.length > 0 ? (
          inPage ?
            <ThankYouTitle notALayer regularCopyFirst={completeInfo[0]?.titleCopyRegular} boldCopyFirst={completeInfo[0]?.titleCopyBold} boldCopySecond={completarBold} />
            : (
              formName === 'lead-oferta' && brand === 'N' ?
                <ThankYouTitle boldCopyFirst='¡Muchas gracias por tu interés!' />
                :
                <ThankYouTitle regularCopyFirst={completeInfo[0]?.titleCopyRegular} boldCopyFirst={completeInfo[0]?.titleCopyBold} boldCopySecond={completarBold} />
            )

        ) : null
        }
      </>
    ),
    fields: (
      <>
        {
          inPage ?
            <ThankYouFields notALayer copyBook={bodyCopy} ctaUrl={ctaUrl} />
            :
            <ThankYouFields copyBook={brand === 'N' && formName === 'lead-oferta' ? ofertasComercialesFields : bodyCopy} ctaUrl={ctaUrl} />
        }
      </>
    ),
  };
  return (
    <RenderController
      screenType={inPage ? 'full-screen' : 'layer-screen'}
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
    />
  );
}

const mapStateToProps = (state: OneFormState) => {
  return {
    formData: state.formData.fields,
    formInfo: state.formInfo,
    dealersInfo: state.dealersInfo,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ThankYouLeads);

export { ConnectedComponent as ThankYouLeads };
