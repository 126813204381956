import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/es';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
/**
 * viernes, 13-12:00
 * @param ms
 */
export const weekDay_Day_Hour = (ms?: number) => {
  if (ms) {
    return dayjs.tz(ms, 'Europe/Madrid').locale('es').format('dddd, DD-HH:mm');
  } else {
    return dayjs.tz(new Date(), 'Europe/Madrid').locale('es').format('dddd, DD-HH:mm');
  }
};

/**
 * 12:00
 * @param ms
 */
export const hourAndMinute = (ms?: number) => {
  if (ms) {
    return dayjs.tz(ms, 'Europe/Madrid').locale('es').format('HH:mm');
  } else {
    return dayjs.tz(new Date(), 'Europe/Madrid').locale('es').format('HH:mm');
  }
};

/**
 * lunes 16,noviembre-10:00
 * @param ms
 */
export const weekDay_DayNumber_Month_Hour = (ms?: number) => {
  if (ms) {
    return dayjs.tz(ms, 'Europe/Madrid').locale('es').format('dddd DD,MMMM-HH:mm');
  } else {
    return dayjs.tz(new Date(), 'Europe/Madrid').locale('es').format('dddd DD,MMMM-HH:mm');
  }
};

/**
 * martes 10,noviembre
 * @param ms
*/
export const weekDay_DayNumber_Month = (ms?: number) => {
  if (ms) {
    return dayjs(ms).locale('es').utc().format('dddd DD,MMMM');
  } else {
    return dayjs().locale('es').utc().format('dddd DD,MMMM');
  }
};
/**
 * martes 10 de Noviembre
 * @param ms
*/
export const weekDay_DayNumber_De_Month = (ms?: number) => {
  if (ms) {
    return dayjs(ms).locale('es').utc().format('dddd DD [de] MMMM').toLowerCase();
  } else {
    return dayjs().locale('es').utc().format('dddd DD [de] MMMM').toLowerCase();
  }
};
/**
 * martes 10,noviembre

 * @param ms
 */
export const year_MonthNumber_DayNumber = (ms?: number) => {
  if (ms) {
    return dayjs(ms).locale('es').utc().format('YYYY-MM-DD');
  } else {
    return dayjs().locale('es').utc().format('YYYY-MM-DD');
  }
};

/**
 * 2020-2-2 -> 2020-02-02
 * @param ms
 */
export const getYYYYMMDD = (d: string) => dayjs(d, 'YYYY-M-D').format('YYYY-MM-DD');
export const getYYYYMMDDx = (ms: number) => dayjs(ms).locale('es').utc().format('YYYY-MM-DD');

export const getActualDay = () => dayjs().locale('es').format('D');
export const getActualMonth = () => dayjs().locale('es').format('MMMM');
export const getNextActualMonth = () => dayjs().locale('es').add(1, 'month').format('MMMM');
export const getPreviousActualMonth = () => dayjs().locale('es').subtract(1, 'month').format('MMMM');
export const getActualMonthNumber = () => dayjs().locale('es').format('M');
export const getActualYearNumber = () => dayjs().locale('es').format('YYYY');
export const getToday = () => dayjs(new Date());
