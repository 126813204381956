import {
  styled
} from '@volkswagen-onehub/components-core';

interface PaddingWrapperProps {
  paginaConfirmacion?: boolean;
}

export const PaddingWrapper = styled.div<PaddingWrapperProps>`
  padding-top: ${(props) => (props.paginaConfirmacion ? '0px' : '44px')};
  @media all and (max-height: 624px) {
    padding-top: ${(props) => (props.paginaConfirmacion ? '0px' : '24px')};
  }
`;
