import { ServiceConfigurationV1 } from '@volkswagen-onehub/service-config-provider';
import { getDataServiceConfig } from 'src/feature-app';

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving Volkswagen Data service config provider.');
  return null;
};

export const getTenant = (dataService: ServiceConfigurationV1) => {
  return isPrivate(dataService)
    ? dataService.tenantPrivate
    : isCommercial(dataService)
      ? dataService.tenantCommercial
      : null;
};

export const isCommercial = (dataService: ServiceConfigurationV1) => dataService.customConfig?.brand === 'N';
export const isPrivate = (dataService: ServiceConfigurationV1) => dataService.customConfig?.brand === 'V';

export const checkTenant = (dataService: ServiceConfigurationV1) => {
  const tenant = getTenant(dataService);

  return tenant ? `/${tenant}` : '';
};

export const getDataUrl = (): string | null => {
  const data = getDataServiceConfig();

  if (!data) {
    return handleErrorInRetrievingExternalConfig();
  }

  return `${data.urlOrigin}${data.urlPath}${checkTenant(data)}`;
};

export const getDataUrlWithoutTenant = (): string | null => {
  const data = getDataServiceConfig();

  if (!data) {
    return handleErrorInRetrievingExternalConfig();
  }

  return `${data.urlOrigin}${data.urlPath}`;
};
