import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Layout,
  styled,
  Text,
  TextAlignment,
  ThemeProvider,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { TriggerProps, useFaContent, useTransformToHtml, ScreenController } from 'src/feature-app';
import { SACForm } from '../forms/SAC';
import { SACOneShopController } from '../forms/SAC/SACOneShop';
import { useDispatch } from 'react-redux';


const Content = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle at bottom, #00437a 0%, #001e50 100%);

  & > div {
    width: 100%;
  }
`;

const CenterCTA = styled.div`
  text-align: center;
`;

export function SACTrigger(props: TriggerProps) {
  const { setSelectedJourney, setCTALabel } = props;
  const dispatch = useDispatch();

  const faContent = useFaContent();

  const getCtaLabel = () => (faContent?.cta ? faContent.cta : 'Contáctanos');

  const alternativeHeadline = '<b>¿Quieres hablar con nosotros?</b>';
  const getTriggerHeadline = () =>
    faContent?.headline ? useTransformToHtml(faContent.headline) : useTransformToHtml(alternativeHeadline);

  const alternativeBodyCopy = 'Explícanos cómo podemos ayudarte y te responderemos muy pronto.';
  const getTriggerbodycopy = () =>
    faContent?.bodycopy ? useTransformToHtml(faContent.bodycopy) : useTransformToHtml(alternativeBodyCopy);

  return (
    
      faContent.triggerCase === 'Generico'?
      <ThemeProvider theme="inverted">

      <Content>
        <Layout
          appearance={{
            [Breakpoints.default]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 20 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b960]: [
              { name: '.', columns: 3 },
              { name: 'a', columns: 18 },
              { name: '.', columns: 3 },
            ],
            [Breakpoints.b1280]: [
              { name: '.', columns: 4 },
              { name: 'a', columns: 16 },
              { name: '.', columns: 4 },
            ],
            [Breakpoints.b1600]: [
              { name: '.', columns: 1 },
              { name: 'a', columns: 22 },
              { name: '.', columns: 1 },
            ],
          }}
        >
          <Container wrap={'always'} padding={{ top: ContainerPadding.dynamic0250 }}>
            <Container wrap={'always'} gutter={ContainerGutter.dynamic0100}>
              <Text textAlign={TextAlignment.center} appearance={TokenTextAppearance.headline300}>
                <div dangerouslySetInnerHTML={getTriggerHeadline()} />
              </Text>

              <Text textAlign={TextAlignment.center} appearance={TokenTextAppearance.copy200}>
                <div dangerouslySetInnerHTML={getTriggerbodycopy()} />
              </Text>
            </Container>
            <Container
              wrap={'always'}
              gutter={ContainerGutter.static400}
              padding={{ bottom: ContainerPadding.dynamic0250, top: ContainerPadding.dynamic0150 }}
            >
              <CenterCTA>
                <BreakpointWrapper max={Breakpoints.b560}>
                  <CTA
                    tag="button"
                    onClick={() => {
                      dispatch({ type: 'RESET_STATE' });
                      setCTALabel(getCtaLabel());
                      setSelectedJourney(<SACForm />);
                    }}
                    stretchContent
                  >
                    {getCtaLabel()}
                  </CTA>
                </BreakpointWrapper>

                <BreakpointWrapper min={Breakpoints.b560}>
                  <CTA
                    tag="button"
                    onClick={() => {
                      dispatch({ type: 'RESET_STATE' });
                      setCTALabel(getCtaLabel());
                      setSelectedJourney(<SACForm />);
                    }}
                  >
                    {getCtaLabel()}
                  </CTA>
                </BreakpointWrapper>
              </CenterCTA>
            </Container>
          </Container>
        </Layout>
      </Content>
    </ThemeProvider>
    :
    <>
      <ScreenController
        selectedJourney={<SACOneShopController />}
        closeLayerCallback={close}
        handleAreYouSureLayer={() => null}
        notALayer={true}
        formType = 'inPage'
      />
    </>
  
    );
}
