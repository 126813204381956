import { Text } from '@volkswagen-onehub/components-core';
import React, { useState } from 'react';
import { Points } from 'src/types';
import { Img, TextWrapper, Wrapper } from 'src/feature-app/NewMap/components/CommonStyledComponents';
import { useMapGeneralController } from 'src/feature-app/NewMap/MapGeneralController';

interface ClusterMarkerProps {
  lat: number;
  lng: number;
  pointCount: number;
  onClick: any;
  isHover: boolean;
}

export const sameClusterId = (selectedPoint: Points, point: Points) => {
  if (selectedPoint?.properties?.parent_cluster_id && point.id) {
    return selectedPoint.properties.parent_cluster_id === point.id;
  }
  return false;
};

export const ClusterMarker = React.memo(({ onClick, pointCount, isHover }: ClusterMarkerProps) => {
  const { markerDimensions } = useMapGeneralController();
  const [mouseEntered, setMouseEntered] = useState(false);

  return (
    <Wrapper
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      onMouseEnter={(e) => {
        e.preventDefault();
        setMouseEntered(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        setMouseEntered(false);
      }}
    >
      <Img
        markerDimensions={markerDimensions}
        src={require(isHover || mouseEntered ? '../assets/selected_marker.png' : '../assets/marker.png')}
      />
      <TextWrapper>
        <Text staticSize>{pointCount}</Text>
      </TextWrapper>
    </Wrapper>
  );
});
