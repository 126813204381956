import { CarlineBase } from 'src/types';
/**
 * En el caso de CEM Venta tenemos que eliminar el "Nuevo" del título, ordenar alfabéticamente los carlines y eliminar los duplicados en caso de que haya
 * dos generaciones de carlines en el selector conviviendo como "Nuevo Arteon" y "Arteon".
 * @param carlines
 * @returns
 */
 export const formatCEMVenta = (carlines: CarlineBase[]) => {
    return carlines
      .map((carline) => {
        return {
          code: String(carline.code),
          name: carline.title,
          key: carline.title,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
      .filter((carline, index, self) => index === self.findIndex((t) => t.name === carline.name));
  };
