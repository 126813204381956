import React, { useEffect, useState } from 'react';
import {
  Breakpoints,
  BreakpointWrapper,
  styled,
  Text,
  ThemeProvider,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { MensajesCards } from '.';
import { keyframes } from 'styled-components';
import { useFaContent } from 'src/feature-app';

const spinnerKeyframe = keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin-left: var(--size-grid003);
  margin-right: var(--size-grid003);
  width: var(--size-grid018);
  display: flex;
  justify-content: space-between;
  .cards {
    width: var(--size-grid009);
    @media screen and (max-width: 990px) {
      width: var(--size-grid011);
    }
    @media screen and (max-width: 959px) {
      margin-left: auto;
      width: 56%;
    }
    @media screen and (max-width: 910px) {
      margin-left: auto;
      width: 60%;
    }
    @media screen and (max-width: 840px) {
      margin-left: auto;
      width: 63%;
    }
    @media screen and (max-width: 780px) {
      margin-left: auto;
      width: 70%;
    }
    @media screen and (max-width: 712px) {
      margin-left: auto;
      width: 85%;
    }
    @media screen and (max-width: 654px) {
      width: 100%;
    }
  }
  .info {
    width: var(--size-grid007);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 959px) {
      width: 80%;
    }
    @media screen and (max-width: 560px) {
      margin-bottom: 10px;
      width: 90%;
    }
    #number-messages {
      display: inline-block;
      min-width: 100px;
    }
  }
  @media screen and (max-width: 990px) {
    margin-left: var(--size-grid002);
    margin-right: var(--size-grid002);
    width: var(--size-grid020);
  }
  @media screen and (max-width: 959px) {
    flex-wrap: wrap-reverse;
  }
`;

const Spinner = styled.div`
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-bottom: 17px;
  border-radius: 100%;
  position: relative;
  .dashed-circumference {
    position: absolute;
    top: 5.5%;
    left: 4.5%;
    z-index: -1;
    width: 90%;
    height: 90%;
    border-radius: 100%;
    border: 1px dotted #001E50;
  }
  .percentage-bar {
    .circle {
      stroke: #071e50;
      fill: none;
      stroke-width: 0.75;
      animation: ${spinnerKeyframe} 3.5s ease-out forwards;
    }
  }
  .percentage-text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate3d(50%, -50%, 0);
    #percentage-messages {
      width: 28px;
      display: inline-block;
      text-align: right;
      @media screen and (min-width: 960px) {
        width: 40px;
      }
      @media screen and (min-width: 1600px) {
        width: 56px;
      }
    }
  }
  @media screen and (max-width: 959px) {
    width: 150px;
    height: 150px;
    position: absolute;
    margin: 0;
    bottom: 0;
    left: 0;
  }
  @media screen and (max-width: 559px) {
    width: 123px;
    height: 123px;
  }
`;

function animateNumber(id: string, start: number, end: number, duration: number) {
  const range = end - start;
  let current = start;
  const increment = id === 'number-messages' ? 2 : 1;
  const stepTime = Math.abs(Math.floor(duration / range));
  let obj = document.getElementById(id);
  const timer = setInterval(function() {
    current += increment;
    if (id === 'percentage-messages' && current < 10) {
      obj.innerHTML = `${current}`;
    } else {
      obj.innerHTML = current.toString();
    }
    if (current === end) {
      clearInterval(timer);
    }
  }, stepTime);
}

export function SectionMensajes() {
  const [ showMessages, setShowMessages ] = useState(false);
  const [ madres, setMadres ] = useState<number>(1000);
  const [ porcentaje, setPorcentaje ] = useState<number>(25);
  const faContent = useFaContent();

  useEffect(() => {
    if (faContent.madres) setMadres(parseInt(faContent.madres));
    if (faContent.porcentaje) setPorcentaje(parseInt(faContent.porcentaje));
  }, [faContent]);

  useEffect(() => {
    if (showMessages) {
      animateNumber('number-messages', 10, madres, 4000);
      animateNumber('percentage-messages', 0, porcentaje, 3000);
    }
  }, [showMessages]);

  useEffect(() => {
    const options = { threshold: 0.8 };
    const element = document.querySelector('#mensajes');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0 && !showMessages) {
          setShowMessages(true);
        }
      });
    }, options);
    observer.observe(element);
  }, []);

  return (
    <Wrapper id="mensajes">
      <div className="cards">
        <MensajesCards showMessages={showMessages} />
      </div>
      <div className="info">
        <ThemeProvider theme='main'>
          <Text appearance={TokenTextAppearance.headline400}>
            Más de <span id="number-messages">10</span> madres han recibido
            {' '}
            <Text bold>su carga de amor</Text>
          </Text>
          <BreakpointWrapper min={Breakpoints.b960}>
            {showMessages ? (
              <Spinner>
                <div className="dashed-circumference" />
                <svg viewBox="0 0 36 36" className="percentage-bar">
                  <path className="circle"
                    strokeDasharray={`${porcentaje}, 100`}
                    d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                </svg>
                <div className="percentage-text">
                  <Text bold appearance={TokenTextAppearance.headline300}><span id="percentage-messages">0</span>%</Text>
                </div>
              </Spinner>
            ) : null}
          </BreakpointWrapper>
        </ThemeProvider>
      </div>
      <BreakpointWrapper max={Breakpoints.b960}>
        {showMessages ? (
          <Spinner>
            <div className="dashed-circumference" />
            <svg viewBox="0 0 36 36" className="percentage-bar">
              <path className="circle"
                strokeDasharray={`${porcentaje}, 100`}
                d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            </svg>
            <div className="percentage-text">
              <Text bold appearance={TokenTextAppearance.headline300}><span id="percentage-messages">0</span>%</Text>
            </div>
          </Spinner>
        ) : null}
      </BreakpointWrapper>
    </Wrapper>
  );
}
