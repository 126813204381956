import {
	Text,
	Container,
	ContainerGutter,
	TokenTextAppearance,
	TextAlignment,
	styled,
} from '@volkswagen-onehub/components-core';
import { RatingStarWrapper, renderBolds } from 'src/components/styleComponents';
import { StarFilled, StarOutline } from 'src/icons-core-imports';
import React, { useEffect, useState } from 'react';
import { TriggerProps, useFaContent } from 'src/feature-app';
import { FeedbackForm } from 'src/forms/Feedback/FeedbackForm';
import { useDispatch, useStore } from 'react-redux';
import { OneFormState } from 'src/types';

const FeedbackWrapper = styled.div`
  padding-top: 24px;
`;
const RatingStarsWrapper = styled.div`
  padding-top: 24px;
  padding-left: 20px;
`;

export const FeedbackTrigger = (props?: TriggerProps) => {
	const { setSelectedJourney } = props;
	const store = useStore();
	const faContent = useFaContent();
	const { formData }: OneFormState = store.getState();
	const { fields } = formData;
	const [formSent, setFormSent] = useState(false);
	const [rateValue, setRateValue] = useState<number>(0);

	const showStars = () => {
		if (faContent.idkit == 'true' && fields.identitykitId?.length > 0) {
			return true;
		}
		if (faContent.climapro == 'true' && fields.climaproUserStatus == 'OK') {
			return true;
		}
		return false;
	};

	const hideStars = () => {
		setFormSent(true);
	};

	const renderForm = (rating: number) => {
		setSelectedJourney(<FeedbackForm rating={rating} hideStars={hideStars} />);
	};

	const renderStars = () => {
		const stars = [];
		for (let i = 1; i <= 5; i++) {
			stars.push(
				<RatingStarWrapper
					className={rateValue >= i ? 'filled' : 'outline'}
					key={i}
					onClick={(e: any) => {
						e.preventDefault();
						renderForm(i);
						setRateValue(i);
					}}
					isPrevStep={true}
					textUnderStars={false}
					pointer={true}
				>
					{rateValue >= i ? <StarFilled /> : <StarOutline />}
				</RatingStarWrapper>
			);
		}

		return stars;
	};

	return (
		<>
			{showStars() && !formSent ? (
				<FeedbackWrapper>
					<Text appearance={TokenTextAppearance.headline200} textAlign={TextAlignment.center}>
						{faContent.headline ? renderBolds(faContent.headline) : '¿Te gusta lo que te has encontrado' + (faContent.feedback ? ' en ' + faContent.feedback.toLowerCase() : '') + '?'}
					</Text>
					<RatingStarsWrapper>
						<Container horizontalAlign={'center'}>{renderStars()}</Container>
					</RatingStarsWrapper>
				</FeedbackWrapper>
			) : null}
		</>
	);
};
