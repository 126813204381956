import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, getTrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps } from 'src/types';
import { ThankYouTitle, ThankYouFields } from 'src/components';

export const ThankYouBaja = () => {
  const {formResponse} = useSelector((state: OneFormState) => state.formInfo);
  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();

  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formResponse?.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: "ThankYou" } } });

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm(formResponse)
    );
  }, []);

  const screen: Steps = {
    title: <ThankYouTitle notALayer={true} boldCopyFirst='Muchas gracias por todo este tiempo con nosotros' />,
    fields: (
      <ThankYouFields copyBook='Tu petición de baja se ha enviado correctamente. Recuerda que el cambio se hará efectivo en los próximos días y que te esperamos con los brazos abiertos si decides volver a suscribirte.' notALayer/>
    ),
  };
  return (
    <RenderController
      screenType="full-screen"
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
      thankYouPage
    />
  );
};
