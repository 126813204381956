import React from 'react';
import { TriggerProps, useFaContent } from 'src/feature-app';
import { WidgetMapa } from 'src/widgets';

export function WidgetTrigger({ setSelectedJourney }: TriggerProps) {
    const faContent = useFaContent();

    if (faContent.widget === 'Mapa') {
        return <WidgetMapa />;
    }
    return null;
  }
