import React, { useEffect, useState } from 'react';
import { CTA } from '@volkswagen-onehub/components-core';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps, IdPerson, IdVehicle, IdDealer, IdOrder } from 'src/types';
import { ThankYouTitle, ThankYouFields } from 'src/components';
import { formDataBuilder } from 'src/forms/format-fields';
import { oneformBFFNdpsSend } from 'src/bff';
import { getCommonFormData } from 'src/forms/Cem/CEMSegundaEntrega';

export interface NoInteresaCEMSegundaEntregaFormProps {
	handleError: () => void,
	idPerson: IdPerson;
	idVehicle: IdVehicle;
	idDealer: IdDealer;
	idOrder: IdOrder;
}

export function CEMSegundaEntregaNoInteres(props: NoInteresaCEMSegundaEntregaFormProps) {
	const {
		handleError,
		idPerson,
		idVehicle,
		idDealer,
		idOrder
	} = props;
	const dispatch = useDispatch();
	const { formData } = useSelector((state: OneFormState) => state);
	const { fields } = formData;
	const [send, setSend] = useState(false);
	const [sent, setSent] = useState(false);


	const sendForm = (): Promise<boolean> => {
		setSend(true);
		return new Promise<any>(async (resolve, reject) => {

			const data = getCommonFormData(idPerson, idVehicle, idDealer, idOrder);

			data['fields[SURVEY_ANSWER_01]'] = 'false';
			data['fields[PERSON_RGPD_PERFILADO]'] = fields.tmk ? fields.tmk : 'NO INFORMADO';
			data['fields[PERSON_RGPD_COM_COMERCIALES]'] = fields.lssi ? fields.lssi : 'NO INFORMADO';

			const dataStringified = JSON.stringify(data);
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			const response = await oneformBFFNdpsSend(formData);

			if (response && response.data?.status?.code == 200) {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				setSent(true);
			} else {
				handleError();
			}
		});
	};

	useEffect(() => {
		if (idDealer && !send) sendForm();
	}, [idDealer]);

	const copyBook = <>
		Seguro que terminas conociendo tu Volkswagen como la palma de tu mano. Y siempre puedes <CTA tag="a" emphasis="tertiary" href="https://www.volkswagen.es/es/volkswagen-espana/atencion-cliente.html">contactarnos</CTA> si tienes alguna duda.
	</>;

	const screen: Steps = {
		title: <ThankYouTitle notALayer boldCopyFirst='¡Entendido!' />,
		fields: <ThankYouFields notALayer copyBook={copyBook} />,
	};

	return (
		sent ? <RenderController
			screenType="full-screen"
			title={screen.title}
			fields={screen.fields}
			key={555}
			avoidTrackFormStepLoad
			thankYouPage={true}
		/> : null
	);
}
