import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { styled, TextInput } from '@volkswagen-onehub/components-core';
import { InputError } from '../NavidadGTI2024Page';

interface InputProps {
	name: string;
	label?: string;
	empty?: boolean;
}

export function EmailField(props: InputProps) {
	const { name, label, empty } = props;
	const dispatch = useDispatch();
	const { register } = useFormContext();
	const [error, setError] = useState(false);
	const regExp =
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	const handleChange = (e: any) => {
		const value = e.target.value;

		setError(false);

		if (!regExp.test(value)) {
			setError(true);
		} else {
			dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: value } });
		}
	};

	return (
		<>
			<TextInput
				name={name}
				label={label}
				appearance={'default'}
				required={true}
				onChange={(e) => handleChange(e)}
				type={'text'}
				isFloating={true}
			/>
			{error && <InputError className="input-error">Ejemplo: nombre@email.com</InputError>}
			{empty && <InputError className="input-error">Campo obligatorio</InputError>}
		</>
	);
}
