import Axios from 'axios';
import { getMolecularCdnUrl, getOneformBFFCdnApiKey, getOneformBFFCdnUrl, reportErrorSentry } from 'src/bff';
import { DomainResponse } from 'src/types';

export const fetchProvince = async (token?: string): Promise<DomainResponse> => {
  const requestToken = token ? token : 'VW_DDB_FRM_LEM_REC_LEM';
  const oneformCdn = getOneformBFFCdnUrl();
  const oneformBFFApiKey = getOneformBFFCdnApiKey();

  try {
    const response = await Axios.get(`${oneformCdn}/ndps/domain?token=${requestToken}&domain=PROVINCE_CODE`,
      {
        timeout: 10000,
        headers: {
          'X-Api-Key': oneformBFFApiKey
        }
      }
    );

    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};

export const fetchDealers = async (code: string, token?: string): Promise<DomainResponse> => {
  const requestToken = !token ? 'VW_DDB_FRM_LEM_REC_LEM' : token;
  let domain = '';
  switch (requestToken) {
    case "VW_DDB_FRM_LEM_REC_LEM":
      domain = 'VOLKSWAGEN_SF_LEAD_INSTALLATION';
      break;
    case "LCV_DDB_FRM_LEM_REC_LEM":
      domain = 'LCV_SF_LEAD_INSTALLATION'
      break;
    default:
      domain = 'VOLKSWAGEN_SF_LEAD_INSTALLATION';
  }
  const oneformCdn = getOneformBFFCdnUrl();
  const oneformBFFApiKey = getOneformBFFCdnApiKey();

  try {
    const response = await Axios.get(
      `${oneformCdn}/ndps/domain?token=${requestToken}&domain=${domain}&relatedDomain=FRML_TXT_codVentaProvincia__c&relatedValue=${code}`,
      {
        timeout: 10000,
        headers: {
          'X-Api-Key': oneformBFFApiKey
        }
      }
    );
    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};

export const fetchTreatment = async (token?: string): Promise<DomainResponse> => {
  const requestToken = token ? token : 'VW_DDB_FRM_LEM_REC_LEM';
  const oneformCdn = getOneformBFFCdnUrl();
  const oneformBFFApiKey = getOneformBFFCdnApiKey();

  try {
    const response = await Axios.get(`${oneformCdn}/ndps/domain?token=${requestToken}&domain=Treatment`,
      {
        timeout: 10000,
        headers: {
          'X-Api-Key': oneformBFFApiKey
        }
      });
    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};

export const fetchProvinceLegacy = async (token?: string): Promise<DomainResponse> => {
  const requestToken = token ? token : 'VW_DDB_FRM_LEM_REC_LEM';
  const molecularCdn = getMolecularCdnUrl();

  try {
    const response = await Axios.get(`${molecularCdn}/request-dps-domain.php?token=${requestToken}&domain=Province`,
      {
        timeout: 10000
      });
    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};

export const fetchDealersLegacy = async (code: string, token?: string, addPosventa?: boolean): Promise<DomainResponse> => {
  const requestToken = token ? token : 'VW_DDB_FRM_LEM_REC_LEM';
  const domain = addPosventa ? 'Installations_VW_All' : 'Installation';
  const molecularCdn = getMolecularCdnUrl();

  try {
    const response = await Axios.get(`${molecularCdn}/request-dps-domain.php?token=${requestToken}&domain=${domain}&relatedDomain=FRML_TXT_codVentaProvincia__c&relatedValue=${code}`,
      {
        timeout: 10000
      }
    );
    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};

export const fetchTreatmentLegacy = async (token?: string): Promise<DomainResponse> => {
  const requestToken = token ? token : 'VW_DDB_FRM_LEM_REC_LEM';
  const molecularCdn = getMolecularCdnUrl();

  try {
    const response = await Axios.get(`${molecularCdn}/request-dps-domain.php?token=${requestToken}&domain=Treatment`,
    {
      timeout: 10000,
    });
    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};
