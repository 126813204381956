import React from 'react';
import {
  Breakpoints,
  Container,
  ContainerPadding,
  Layout,
  Text,
  TextAlignment,
  ContainerGutter,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { CTAThankYouPage, CTAThankYou, CTAThankYouPageAction, CTAAction, LeadsCTAs } from '.';
import { OneFormState } from 'src/types';
import { useSelector } from 'react-redux';


interface FieldProps {
  copyBook?: any;
  copyBookSecond?: any;
  notALayer?: boolean;
  CTAFields?: boolean;
  firstCTA?: CTAThankYou;
  secondCTA?: boolean;
  secondCTACopies?: CTAThankYou;
  CTAAction?: CTAAction;
  ctaFunction?: any;
  ctaUrl?: string;
}

export const ThankYouFields = (props: FieldProps) => {
  const{ 
    notALayer , 
    copyBook, 
    CTAFields, 
    copyBookSecond, 
    firstCTA, 
    secondCTA, 
    secondCTACopies, 
    CTAAction,
    ctaFunction,
    ctaUrl
  } = props;
  const { formData } = useSelector((state: OneFormState) => state);
  const { formName } = formData.fields;

  return (
    <>
    {
      notALayer ? (
        <Layout
          appearance={{
            [Breakpoints.default]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 20 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b960]: [
              { name: '.', columns: 3 },
              { name: 'a', columns: 18 },
              { name: '.', columns: 3 },
            ],
            [Breakpoints.b1280]: [
              { name: '.', columns: 5 },
              { name: 'a', columns: 14 },
              { name: '.', columns: 5 },
            ],
            [Breakpoints.b2560]: [
              { name: '.', columns: 7 },
              { name: 'a', columns: 10 },
              { name: '.', columns: 7 },
            ],
          }}
        >
          <div style={{ textAlign: 'left', paddingBottom: '4px', width: '100%' }}>
            <Container padding={{ top: ContainerPadding.dynamic0100, bottom: CTAFields ? ContainerPadding.dynamic0100 : ContainerPadding.static0 }} gutter={ContainerGutter.static200} wrap={'always'}>
              <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
               {copyBook}
              </Text>
              {
                copyBookSecond ? (
                  <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
                    {copyBookSecond}
                  </Text>
                ) : null
              }
            </Container>
            {
              CTAFields ? <CTAThankYouPage firstCTA={firstCTA} secondCTA={secondCTA} secondCTACopies={secondCTACopies} /> : null
            }
            {
              CTAAction? <CTAThankYouPageAction ctaInfo={CTAAction} ctaFunction={ctaFunction} /> : null
            }
            {
              formName && formName === 'lead-oferta' || formName === 'lead-match' ? ( 
                <LeadsCTAs 
                  url={ctaUrl}
                /> 
              ) : null
            }
          </div>
        </Layout>

      ) : (
        <Layout
          appearance={{
            [Breakpoints.default]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 20 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b960]: [
              { name: '.', columns: 3 },
              { name: 'a', columns: 18 },
              { name: '.', columns: 3 },
            ],
          }}
        >
          <div style={{ textAlign: 'left', paddingBottom: '4px', width: '100%' }}>
            <Container padding={{ top: ContainerPadding.dynamic0100, bottom: CTAFields ? ContainerPadding.dynamic0100 : ContainerPadding.static0 }} gutter={ContainerGutter.static200} wrap={'always'}>
              <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
                {copyBook}
              </Text>
              {
                copyBookSecond ? (
                  <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
                    {copyBookSecond}
                  </Text>
                ) : null
              }
            </Container>
            {
              CTAFields ? <CTAThankYouPage firstCTA={firstCTA} secondCTA={secondCTA} secondCTACopies={secondCTACopies} /> : null
            }
            {
              CTAAction? <CTAThankYouPageAction ctaInfo={CTAAction} ctaFunction={ctaFunction} /> : null
            }
            {
              formName && formName === 'lead-oferta' || formName === 'lead-match' ? ( 
                <LeadsCTAs 
                  url={ctaUrl}
                /> 
              ) : null
            }
          </div>
        </Layout>
      )}
    </>
  );
};
