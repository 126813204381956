import { CarlineBase, CarlinesWithSalesgroups, FormularioFlagType } from 'src/types';
/**
 * Devuelve únicamente los carlines con el flag del formulario correspondiente.
 * @param carlines
 * @param formularioFlag
 * @returns
 */
 export const filterCarlineFlag = (carlines: CarlineBase[] | CarlinesWithSalesgroups[], formularioFlag: FormularioFlagType) => {
  if (!formularioFlag) {
    return carlines;
  }

  return carlines
    .map((carline: CarlineBase | CarlinesWithSalesgroups) => {
      if (carline.formularios[formularioFlag] === 1) {
        return carline;
      }
    })
    .filter((carline) => carline !== undefined);
};
