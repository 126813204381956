import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MultipleCTASelection } from '.';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useSelector } from 'react-redux';
import { OneFormState } from 'src/types';
import { contactoOptionsTurismos, contactoOptionsComerciales } from 'src/feature-app/InputsValues/CTAsObjects';
import { useIsComerciales } from 'src/feature-app/hooks';

export function CanalDeContacto() {
  const { register, errors } = useFormContext();
  const [errorContacto, setErrorContacto] = useState(false);
  const [contacto, setContacto] = useState('Arteon');
  const { fields } = useSelector((state: OneFormState) => state.formData);
  const { error, setError } = useOneFormContext();
  const isComerciales = useIsComerciales();

  useEffect(() => {
    if (fields.contacto) {
      setContacto(fields.contacto);
    }
  }, []);

  useEffect(() => {
    if (error) {
      !contacto ? setErrorContacto(true) : setErrorContacto(false);
    }
  }, [error]);

  useEffect(() => {
    if (errors.contacto) {
      setErrorContacto(true);
    } else {
      setErrorContacto(false);
    }
  }, [errors]);

  function handleChange(event: any) {
    const newContacto = event.currentTarget.value;
    setContacto(newContacto);
    if (newContacto !== '') setErrorContacto(false);
  }

  return <MultipleCTASelection ctaValues={isComerciales ? contactoOptionsComerciales : contactoOptionsTurismos} name="contacto" nextOnClick />;
}
