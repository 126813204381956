import fetch from 'cross-fetch';
import * as settings from 'src';
import { getOneformBFFCdnUrl, getOneformBFFCdnApiKey, reportErrorSentry } from 'src/bff';

export const fetchMarketingCode = async (marketingCode: string) => {
  const oneformBFFCdnUrl = getOneformBFFCdnUrl();
  const oneformBFFCdnApiKey = getOneformBFFCdnApiKey();

  return fetch(oneformBFFCdnUrl + '/okapi/marketing-code?marketingCode=' + marketingCode, {
    headers: {
      "X-Api-Key": oneformBFFCdnApiKey,
    }
  })
    .then((response) => response.json())
    .catch((err) => {
      reportErrorSentry(err);
    });
};
