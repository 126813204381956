import React, { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { getGoogleApiKey } from 'src/bff';

const libraries = ['places'];

export default function LoadScriptInstance(props: any) {
  const [isLoaded, setIsLoaded] = useState(false);

  const loader = new Loader({
    apiKey: getGoogleApiKey(),
    libraries: ['places'],
    language: 'es',
  });

  useEffect(() => {
    const useLoader = async () => {
      const load = await loader.load();
      setIsLoaded(true);
      props.onMapLoad();
    };
    useLoader();
  }, []);

  return isLoaded ? props.children : null;
}
