import {
	Breakpoints,
	Container,
	ContainerPadding,
	styled,
	Text,
	Layout,
	Gallery,
	BreakpointWrapper,
	ContainerGutter,
	CTA,
	TokenTextAppearance,
	GallerySlide,
	Image,
	DesignTokenSizeEnum,
	ThemeProvider,
	TextAlignment,
	Switch,
	ArrowButtons,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useOneFormContext } from 'src/feature-app';
import { OneFormState } from 'src/types';
import { TextInputField } from './components/TextInputField';
import { EmailField } from './components/EmailField';
import { Arrow } from './components/Arrow';
import { TextAreaField } from './components/TextAreaField';

const BlackSection = styled.section`
	background-color: black;
	min-height: 100vh;
	width: 100%;
`;

const HeaderContainer = styled.div`
	position: relative;
	height: 600px;
	width: 100%;

	background-image: url(https://assets.volkswagen.com/is/image/volkswagenag/stage_img_golf_gti_carbon?Zml0PWNyb3AlMkMxJndpZD0xNjMwJmhlaT04MTUmZm10PWpwZWcmcWx0PTc5JmJmYz1vZmYmOTVkZQ==);
	background-size: cover;
	background-position: center right;

	display: flex;

	& > div {
		width: 100%;
		height: 100%;
	}
`;

const FooterContainer = styled.div`
	height: 600px;
	width: 100%;

	background-image: url(https://assets.volkswagen.com/is/image/volkswagenag/background_form?Zml0PWNyb3AlMkMxJndpZD0xNTQyJmhlaT03NzEmZm10PWpwZWcmcWx0PTc5JmJmYz1vZmYmNDliZg==);
	background-size: cover;
	background-position: bottom center;
`;

const TitleText = styled.span`
	position: absolute;
	bottom: 40%;
`;

const RedText = styled.span`
	* {
		color: #ff030b;
	}
`;

const CopyContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-left: 1px solid white;
	padding-left: 10px;

	& > span {
		border: 1px solid red;
		font-style: italic;
	}
`;

const GreyContainer = styled.div`
	margin-top: 80px;
	background-color: #111721;
	padding: 40px;
	& > div > div > div {
		flex: 2;
	}
	& > div > div > div:last-child {
		flex: 3;
	}
`;

const TextAreaContainer = styled.div`
	margin: 42px 0;
	& span {
		background-color: transparent !important;
		font-size: 16px;
	}
`;

const SwitchContainer = styled.div`
	margin-bottom: 40px;
	& > div {
		margin: 20px 0;
	}
	& > label {
		display: inline-block;
	}
	& > label > span {
		background-color: transparent;
	}
`;

const images = [
	'https://assets.volkswagen.com/is/image/volkswagenag/gallery_01_1920x1372?Zml0PWNyb3AlMkMxJndpZD0xODI5JmhlaT0xMzcyJmZtdD1qcGVnJnFsdD03OSZiZmM9b2ZmJjdiMWQ=',
	'https://assets.volkswagen.com/is/image/volkswagenag/gallery_02_1920x1372?Zml0PWNyb3AlMkMxJndpZD0xODI5JmhlaT0xMzcyJmZtdD1qcGVnJnFsdD03OSZiZmM9b2ZmJjdiMWQ=',
	'https://assets.volkswagen.com/is/image/volkswagenag/gallery_03_1920x1372?Zml0PWNyb3AlMkMxJndpZD0xODI5JmhlaT0xMzcyJmZtdD1qcGVnJnFsdD03OSZiZmM9b2ZmJjdiMWQ=',
	'https://assets.volkswagen.com/is/image/volkswagenag/gallery_04_1920x1372?Zml0PWNyb3AlMkMxJndpZD0xODI5JmhlaT0xMzcyJmZtdD1qcGVnJnFsdD03OSZiZmM9b2ZmJjdiMWQ=',
	'https://assets.volkswagen.com/is/image/volkswagenag/gallery_05_1920x1372?Zml0PWNyb3AlMkMxJndpZD0xODI5JmhlaT0xMzcyJmZtdD1qcGVnJnFsdD03OSZiZmM9b2ZmJjdiMWQ=',
	'https://assets.volkswagen.com/is/image/volkswagenag/gallery_06_1920x1372?Zml0PWNyb3AlMkMxJndpZD0xODI5JmhlaT0xMzcyJmZtdD1qcGVnJnFsdD03OSZiZmM9b2ZmJjdiMWQ=',
];

export const InputError = styled.p`
	position: relative;
	margin-top: 6px;
	background-color: #e4002c;
	color: #ffffff;
	/* width: max-content; */
	width: 100%;
	max-width: '280px';
	padding: 4px 8px;
	min-height: 24px;
	border-radius: 4px;
	font-size: 12px;
	text-align: left;
	@media all and (min-width: 560px) {
		max-width: '303px';
	}
	@media all and (min-width: 960px) {
		max-width: '320px';
	}
`;

export const NavidadGTI2024Page = () => {
	const { handleSubmit } = useOneFormContext();
	const store = useStore();
	const dispatch = useDispatch();
	const { formInfo }: OneFormState = store.getState();

	const [nameEmpty, isNameEmpty] = useState<boolean>();
	const [firstSurnameEmpty, isFirstSurnameEmpty] = useState<boolean>();
	const [secondSurnameEmpty, isSecondSurnameEmpty] = useState<boolean>();
	const [emailEmpty, isEmailEmpty] = useState<boolean>();
	const [detallesEmpty, isDetallesEmpty] = useState<boolean>();

	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

	const handleRgpd = (e) => {
		dispatch({ type: 'UPDATE_FIELDS', payload: { ['rgpd']: e.target.checked } });
	};

	const handleSendForm = (e) => {
		const { formData }: OneFormState = store.getState();

		isNameEmpty(false);
		isFirstSurnameEmpty(false);
		isSecondSurnameEmpty(false);
		isEmailEmpty(false);
		isDetallesEmpty(false);

		if (!formData.fields.name || formData.fields.name.trim() == '') {
			isNameEmpty(true);
		} else if (!formData.fields.firstSurname || formData.fields.firstSurname.trim() == '') {
			isFirstSurnameEmpty(true);
		} else if (!formData.fields.secondSurname || formData.fields.secondSurname.trim() == '') {
			isSecondSurnameEmpty(true);
		} else if (!formData.fields.email || formData.fields.email.trim() == '') {
			isEmailEmpty(true);
		} else if (!formData.fields.detalles || formData.fields.detalles.trim() == '') {
			isDetallesEmpty(true);
		} else {
			setDisableSubmit(true);
			handleSubmit(e);
		}
	};

	const retryForm = () => {
		setDisableSubmit(false);
		dispatch({ type: 'UPDATE_FORM_ENDED', payload: false });
	};

	// Añadir type=button al slider para evitar submit del form
	useEffect(() => {
		const slider = document.getElementById('slider');
		if (slider) {
			const buttons = slider.querySelectorAll('button');
			buttons.forEach((button) => {
				button.setAttribute('type', 'button');
			});
		}
	}, []);

	return (
		<BlackSection>
			<ThemeProvider theme={'inverted'}>
				<HeaderContainer>
					<Container padding={{ left: ContainerPadding.grid002 }}>
						<TitleText>
							<RedText>
								<Text appearance={TokenTextAppearance.headline500} bold>
									Golf GTI
								</Text>
							</RedText>
							<Text bold appearance={TokenTextAppearance.headline400}>
								Estas Navidades desearás carbón
							</Text>
						</TitleText>
					</Container>
				</HeaderContainer>

				<Arrow />

				<Layout
					appearance={{
						[Breakpoints.default]: [
							{ name: '.', columns: 2 },
							{ name: 'a', columns: 20 },
							{ name: '.', columns: 2 },
						],
						[Breakpoints.b1280]: [
							{ name: '.', columns: 4 },
							{ name: 'a', columns: 16 },
							{ name: '.', columns: 4 },
						],
					}}
				>
					<div style={{ paddingBottom: '40px', textAlign: 'center' }}>
						<RedText>
							<Text bold appearance={TokenTextAppearance.headline300} wordBreak>
								Hay quienes no están hechos para portarse bien
							</Text>
						</RedText>
					</div>

					<div style={{ paddingBottom: '70px', textAlign: 'center' }}>
						<Text appearance={TokenTextAppearance.bigcopy100} wordBreak>
							Velocidad, adrenalina, potencia. Si todo esto te define, es hora de demostrar que llevas carbón en tu ADN.
						</Text>
						<Text appearance={TokenTextAppearance.bigcopy100} wordBreak>
							Participa y gana <Text bold>1 de las 5 Driving Experience GTI que sorteamos en Montmeló y Jarama</Text>.
						</Text>
						<Text appearance={TokenTextAppearance.bigcopy100} wordBreak>
							Pista: puedes ser todo lo ingenioso/a que quieras 😉
						</Text>
					</div>
				</Layout>

				<Gallery
					id="slider"
					bottomElements={
						<BreakpointWrapper min={Breakpoints.b560}>
							<ArrowButtons buttonPreviousAriaLabel="<" buttonNextAriaLabel=">" />
						</BreakpointWrapper>
					}
					ariaLabel=""
					slidesPerPage={{ [Breakpoints.default]: 3.5, [Breakpoints.b1280]: 4.5 }}
					gutter={DesignTokenSizeEnum.static400}
				>
					{images.map((url, i) => (
						<GallerySlide key={url} ariaLabelledby={`slide${i}-heading`}>
							<Container padding={ContainerPadding.static300} wrap={'never'}>
								<Image alt="" src={url} />
							</Container>
						</GallerySlide>
					))}
				</Gallery>

				<Layout
					appearance={{
						[Breakpoints.default]: [
							{ name: '.', columns: 2 },
							{ name: 'a', columns: 20 },
							{ name: '.', columns: 2 },
						],
						[Breakpoints.b1280]: [
							{ name: '.', columns: 4 },
							{ name: 'a', columns: 16 },
							{ name: '.', columns: 4 },
						],
					}}
				>
					<Container padding={{ top: ContainerPadding.dynamic0200 }} verticalAlign={'center'} stretchContent>
						<Text bold appearance={TokenTextAppearance.bigcopy100} textAlign={TextAlignment.center} wordBreak>
							Algunos ya lo han hecho...
						</Text>
					</Container>
					<Container
						wrap={{ [Breakpoints.default]: 'always', [Breakpoints.b1280]: 'never' }}
						gutter={ContainerGutter.dynamic0150}
						padding={{ top: ContainerPadding.dynamic0200 }}
						shrinkContent
					>
						<CopyContainer>
							<div style={{ paddingBottom: '20px', fontStyle: 'italic' }}>
								<Text appearance={TokenTextAppearance.copy200} wordBreak>
									He aparcado ocupando dos plazas para que mi Volkswagen tuviera su espacio.
								</Text>
							</div>
							<Text appearance={TokenTextAppearance.copy200} bold>
								Juan
							</Text>
						</CopyContainer>
						<CopyContainer>
							<div style={{ paddingBottom: '20px', fontStyle: 'italic' }}>
								<Text appearance={TokenTextAppearance.copy200} wordBreak>
									He llevado la música a tope con las ventanas bajadas, no quería ser egoísta.
								</Text>
							</div>
							<Text appearance={TokenTextAppearance.copy200} bold>
								Carla
							</Text>
						</CopyContainer>
						<CopyContainer>
							<div style={{ paddingBottom: '20px', fontStyle: 'italic' }}>
								<Text appearance={TokenTextAppearance.copy200} wordBreak>
									Le he dado un toque de color al asiento de copiloto de mi amigo con un poco de kétchup.
								</Text>
							</div>
							<Text appearance={TokenTextAppearance.copy200} bold>
								Marc
							</Text>
						</CopyContainer>
					</Container>
				</Layout>

				<Layout
					appearance={{
						[Breakpoints.default]: [
							{ name: '.', columns: 1 },
							{ name: 'a', columns: 22 },
							{ name: '.', columns: 1 },
						],
						[Breakpoints.b1280]: [
							{ name: '.', columns: 2 },
							{ name: 'a', columns: 20 },
							{ name: '.', columns: 2 },
						],
					}}
				>
					{!formInfo.formSubmitted && !formInfo.formEnded && (
						<GreyContainer>
							<Container
								wrap={{ [Breakpoints.default]: 'always', [Breakpoints.b1280]: 'never' }}
								gutter={ContainerGutter.dynamic0150}
								shrinkContent
							>
								<TextInputField name="name" label="Nombre" empty={nameEmpty} />
								<TextInputField name="firstSurname" label="Primer apellido" empty={firstSurnameEmpty} />
								<TextInputField name="secondSurname" label="Segundo apellido" empty={secondSurnameEmpty} />
								<EmailField name="email" label="Email" empty={emailEmpty} />
							</Container>
							<TextAreaContainer>
								<TextAreaField name="detalles" label="Cuéntanos por qué te mereces carbón:" />
								{detallesEmpty && <InputError className="input-error">Campo obligatorio</InputError>}
							</TextAreaContainer>

							<SwitchContainer>
								<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left} staticSize>
									¿Te gustaría recibir descuentos y novedades de Volkswagen?
								</Text>

								<Switch onChange={(e) => handleRgpd(e)} defaultChecked={false}>
									Aceptar
								</Switch>
							</SwitchContainer>

							<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left} staticSize>
								Enviando este formulario aceptas las{' '}
								<CTA
									tag="a"
									href="https://www.volkswagen.es/content/dam/onehub_pkw/importers/es/quiero-un/golf-negro-carbon/BASES-LEGALES-NAVIDAD-2024.pdf"
									target="_blank"
									emphasis="tertiary"
								>
									bases legales del sorteo
								</CTA>
								.
							</Text>
							<Container stretchContent>
								<div style={{ width: '200px', float: 'right', paddingTop: '20px' }}>
									<CTA
										tag="button"
										type="submit"
										emphasis="primary"
										onClick={(e) => {
											e.preventDefault();
											handleSendForm(e);
										}}
										stretchContent
										disabled={disableSubmit}
									>
										Enviar
									</CTA>
								</div>
							</Container>
						</GreyContainer>
					)}
					{formInfo.formSubmitted && (
						<GreyContainer>
							<div style={{ margin: '100px 0' }}>
								<Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.center}>
									¡Muchas gracias por participar!
								</Text>
							</div>
						</GreyContainer>
					)}
					{formInfo.formEnded && (
						<GreyContainer>
							<div style={{ margin: '100px 0', textAlign: 'center' }}>
								<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.center}>
									Se ha producido un error.
								</Text>
								<div style={{ display: 'inline-block', width: '200px', paddingTop: '20px' }}>
									<CTA
										tag="button"
										type="submit"
										emphasis="primary"
										onClick={(e) => {
											e.preventDefault();
											retryForm();
										}}
										stretchContent
									>
										Volver a intentar
									</CTA>
								</div>
							</div>
						</GreyContainer>
					)}
				</Layout>

				<FooterContainer />
			</ThemeProvider>
		</BlackSection>
	);
};
