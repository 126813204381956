import React from 'react';
import {
  Text,
  Container,
  ContainerPadding,
  CTA,
  ContainerGutter,
  Layout,
  Breakpoints,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { useOneFormContext } from 'src/feature-app';

interface IntroduccioStarsProps {
  bodyCopy?: string;
  label?: string;
}

export const PantallaIntroduccionStars = (props:IntroduccioStarsProps) => {
  const { bodyCopy, label } = props;
  const { nextStep } = useOneFormContext();
  const trackingManager = useTrackingManager();

  return (
    <Layout
      allowOverflowingContent
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
        [Breakpoints.b1280]: [
          { name: '.', columns: 5 },
          { name: 'a', columns: 14 },
          { name: '.', columns: 5 },
        ],
        [Breakpoints.b1920]: [
          { name: '.', columns: 6 },
          { name: 'a', columns: 12 },
          { name: '.', columns: 6 },
        ],
        [Breakpoints.b2560]: [
          { name: '.', columns: 7 },
          { name: 'a', columns: 10 },
          { name: '.', columns: 7 },
        ],
      }}
    >
      <Container
        wrap={'always'}
        padding={{ top: ContainerPadding.dynamic0050 }}
        gutter={ContainerGutter.static400}
      >
        <Text appearance={TokenTextAppearance.copy200} staticSize>
          {bodyCopy ? bodyCopy : 'Valora del 1 al 5 las siguientes experiencias con nosotros.'}
        </Text>
        <CTA
          tag="button"
          emphasis="primary"
          onClick={(e) => {
            trackingManager.trackFormStepButtonClick({
              contentId: 'Siguiente',
            });
            e.preventDefault();
            nextStep();
          }}
          ariaLabel="aria label"
        >
          {label ? label : 'Empezar'}
        </CTA>
      </Container>
    </Layout>
  );
};
