import React from 'react';
import { ScreenController, useFaContent } from 'src/feature-app';
import { CemController, LEMController, NoCompraController, NoRenovacionController } from 'src/forms';
import { CEMOneShopController } from "src/forms/Cem/CEM OneShop";
import { CEMVentaProductoController } from "src/forms/Cem/CEMVentaProducto/CEMVentaProductoController";
import { CEMSegundaEntregaController, CEMSegundaEntregaValoracionController } from "src/forms/Cem/CEMSegundaEntrega";

export function EncuestasTrigger() {
  const faContent = useFaContent();

  const renderTrigger = (Controller: JSX.Element) => {
    return (
      <>
        <ScreenController
          selectedJourney={Controller}
          closeLayerCallback={close}
          handleAreYouSureLayer={() => null}
          formType = 'inPage'
        />
      </>
    );
  };

  switch (faContent?.encuesta) {
    case 'CEM - POSVENTA':
    case 'CEM - VENTA':
      return renderTrigger(<CemController />);
	case 'CEM - VENTA PRODUCTO':
		return renderTrigger(<CEMVentaProductoController />);
    case 'CEM - ONESHOP':
      return renderTrigger(<CEMOneShopController />);
	case 'CEM - SEGUNDA ENTREGA - INVITACION':
		return renderTrigger(<CEMSegundaEntregaController />);
	case 'CEM - SEGUNDA ENTREGA - VALORACION':
		return renderTrigger(<CEMSegundaEntregaValoracionController />);
    case 'LEM':
      return renderTrigger(<LEMController />);
    case 'NO COMPRA':
      return renderTrigger(<NoCompraController />);
    case 'NO RENOVACIÓN':
      return renderTrigger(<NoRenovacionController />);

    default:
      throw new Error('No property encuesta in FA Content.');
  }
}
