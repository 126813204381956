import { CarlinesWithSalesgroups } from 'src/types';
import { getSalesgroupsFromCarlines, formatAllSalesgroups } from '.';
/**
 * Se agrupan todas las operaciones que se realizan a los carlines con salesgroups (filtrar por flag, obtener solo los salesgroups y formatearlos) para no hacerlas dos veces.
 * @param carlines
 * @param formularioFlag
 * @returns
 */
export const getFormattedSalesgroups = (carlines: CarlinesWithSalesgroups[]) => {
  const salesgroups = getSalesgroupsFromCarlines(carlines);
  return formatAllSalesgroups(salesgroups);
};
