import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useFeatureServices } from 'src/feature-app';
import { EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { IdPerson } from 'src/types';
import { SACOneShopForm } from '.';
import { createPincodeEntity } from '../../dps-utils';
import { LoadingEncuestas } from 'src/components';
import { HeaderImage } from "../../../components/HeaderImage";

export interface SACOneShopProps {
  idPerson: IdPerson;
  handleError: () => void;

}

export function SACOneShopController() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const dpsDataRef = useRef(null);
  const idPersonRef = useRef(null);
  const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

  const imagesUrl = {
    imageBigUrl: 'https://assets.volkswagen.com/is/image/volkswagenag/es-oneform-sac-oneshop-2560x760?Zml0PWNyb3AlMkMxJndpZD0yNTYwJmhlaT03NjAmZm10PXdlYnAtYWxwaGEmcWx0PTc5JmJmYz1vZmYmYTYzZA==',
    imageSmallUrl: 'https://assets.volkswagen.com/is/image/volkswagenag/es-oneform-sac-oneshop-1080x520?Zml0PWNyb3AlMkMxJndpZD0xMDgwJmhlaT01MjAmZm10PXdlYnAtYWxwaGEmcWx0PTc5JmJmYz1vZmYmMjAxZA==',
    imageLogoUrl: 'https://assets.volkswagen.com/is/image/volkswagenag/es-oneform-sac-oneshop-logo-v2?Zml0PWNyb3AlMkMxJndpZD0zNjImaGVpPTc0JmZtdD1wbmctYWxwaGEmYmZjPW9mZiZjZmYy'
  }

  const handleNext = (value: any) => { };
  const handleComplete = () => {
    const dpsData = oneFormService.getDpsData();

    if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
      handleError();
    } else if (dpsData.pincode.pincodeUsed) {
      setLoading(false);
    } else {
      const idPerson = createPincodeEntity(dpsData, 'PERSON');

      dpsDataRef.current = dpsData;
      idPersonRef.current = idPerson;

      setLoading(false);
    }
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  useEffect(() => {
    const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

    oneFormCoreDpsIsLoaded.subscribe({
      next(value) {
        handleNext(value);
      },
      error(err) {
        console.error(err);
        handleError();
      },
      complete() {
        handleComplete();
      },
    });
  }, []);

  if (loading) {
    return <LoadingEncuestas />;
  } else if (error) {
    return (
      <>
        <HeaderImage
          imageSmall={imagesUrl.imageSmallUrl}
          imageBig={imagesUrl.imageBigUrl}
          imageLogo={imagesUrl.imageLogoUrl}
        />
        <SACOneShopForm />
      </>
    );
  } else {
    return (
      <>
        <HeaderImage
          imageSmall={imagesUrl.imageSmallUrl}
          imageBig={imagesUrl.imageBigUrl}
          imageLogo={imagesUrl.imageLogoUrl}
        />
        <SACOneShopForm
          dpsData={dpsDataRef.current}
          idPerson={idPersonRef.current}
        />
      </>
    );
  }
}
