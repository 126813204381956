import { FeatureAppEnvironment } from '@feature-hub/core';
import React from 'react';
import { FeatureAppConfig, Dependencies } from 'src/feature-app';

export type OneFormFeatureAppEnvironment = FeatureAppEnvironment<Dependencies, FeatureAppConfig>;

export const FeatureAppEnvironmentContext = React.createContext<OneFormFeatureAppEnvironment | undefined>(undefined);

export function useFeatureAppEnvironment(): OneFormFeatureAppEnvironment {
  const featureAppEnvironment = React.useContext(FeatureAppEnvironmentContext);

  if (!featureAppEnvironment) {
    throw new Error('No featureAppEnvironment context value was provided.');
  }

  return featureAppEnvironment;
}
