import {
	Breakpoints,
	BreakpointWrapper,
	Container,
	ContainerPadding,
	Layout,
	styled,
	Text,
	TextAlignment,
	CTA,
	TokenTextAppearance,
	TokenTextColor,
} from '@volkswagen-onehub/components-core';
import {isNull, useOneFormContext} from 'src/feature-app';
import {ArrowLeft} from 'src/icons-core-imports';

import React, {createRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {DealerCard} from 'src/components';
import {SeleccionDealerTabs} from 'src/feature-app';
import {CloseComponent} from 'src/feature-app/Screen';
import {Availability, DealersData, OneFormState, Points, Center} from 'src/types';
import {getFirstAndLastDayOfMonth} from 'src/helpers';

interface SeleccionDealerProps {
	pointsByDistance: Points[];
	pointsByTime: Points[];
	// setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
	setShowCards: React.Dispatch<React.SetStateAction<boolean>>;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedPoint: React.Dispatch<React.SetStateAction<Points>>;
	getAvailability: (dealer: DealersData, start: number, end: number) => Promise<Availability>;
	selectedPoint: Points;

	handleShowSlots: (point: Points) => string | null;
	showAllCardsMobile: boolean;
	center: Center;
	handleReservaCitaLayer: (p: Points) => void;
	hideCards?: () => void;
}

interface BoxProps {
	selected: boolean;
	oneCard: boolean;
}

const GoBackWrapper = styled.div`
	padding-top: 24px;

	& button {
		color: #ffffff;
	}
`;

const Box = styled.div<BoxProps>`
	width: 100%;
	border: ${(props) => (props.selected ? '2px solid #FFFFFF' : '1px solid rgba(255,255,255,0.44)')};
	padding: 24px;
	margin-top: ${(props) => (props.oneCard ? '0' : '44px')};

	:hover {
		border: ${(props) => (props.selected ? '2px solid #FFFFFF' : '1px solid #4cc7f4')};
	}
`;
const ScrollWrapper = styled.div`
	height: calc(100vh - 80px);
	overflow-y: auto;
	margin: auto;
`;

const TabsWrapper = styled.div`
	width: 83vw;
	padding-top: 24px;
	height: 100%;

	@media all and (min-width: 960px) {
		width: 380px;
		overflow-y: auto;
		margin: auto;
	}
	ul {
		padding-left: 5px;
	}

	& > div {
		padding-bottom: 24px;
	}
`;

const ArrowLeftWrapper = styled.div`
	padding: 24px 0px;

	& svg {
		fill: #ffffff;
	}
`;

interface Ref {
	ref: React.RefObject<any>;
	kvps: string;
}

export function SeleccionDealer(props: SeleccionDealerProps) {
	const {
		pointsByDistance,
		pointsByTime,
		selectedPoint,
		setSelectedPoint,
		getAvailability,
		setLoading,
		handleShowSlots,
		handleReservaCitaLayer,
		center,
		hideCards,
		setShowCards,
	} = props;

	const {horario} = useSelector((state: OneFormState) => state.formData.fields);
	const {handleAreYouSureLayer} = useOneFormContext();

	const [defaultIndex, setDefaultIndex] = useState(0);
	const [selectedPoints, setSelectedPoints] = useState(null);

	const createRefs = (points: Points[]) => {
		const refs = [];
		const length = points.length;
		for (let ix = 0; ix < length; ix++) {
			let refObject: Ref = {ref: createRef(), kvps: points[ix].properties.dealer.kvps};
			refs.push(refObject);
			refObject = null;
		}
		return refs;
	};
	const [isTimeSelected, setIsTimeSelected] = useState(false);
	const [timeRefs, setTimeRefs] = useState<Ref[]>(null);
	const [distanceRefs, setDistanceRefs] = useState<Ref[]>(null);

	useEffect(() => {
		if (horario === 'before') {
			setIsTimeSelected(true);
			setDefaultIndex(1);
		}
	}, []);

	useEffect(() => {
		// setSelectedPoint(null);
		setTimeRefs(null);
		setDistanceRefs(null);

		const timeRefs = createRefs(pointsByTime);
		const distanceRefs = createRefs(pointsByDistance);
		setTimeRefs(timeRefs);
		setDistanceRefs(distanceRefs);
	}, [pointsByDistance]);

	useEffect(() => {
		setSelectedPoints(pointsByDistance); // Actualizar con el que está seleccionado.
	}, [selectedPoints]);

	const handleShowCalendarOnClick = async (point: Points) => {
		setSelectedPoint(point);
		setLoading(true);
		const {firstDayOfMonth, lastDayOfMonth} = getFirstAndLastDayOfMonth();
		const availability = await getAvailability(point.properties.dealer, firstDayOfMonth, lastDayOfMonth); // Devuelve el availability.
		setLoading(false);
	};

	const handleSeleccionarOnClick = (point: any) => {
		setSelectedPoint(point);
		handleReservaCitaLayer(point);
	};

	const renderPoints = (points: any, isTime?: boolean): JSX.Element => {
		return points.map((point: Points, i: number) => {
			const {kvps} = point.properties.dealer;
			const {cluster} = point.properties;
			const selected = selectedPoint && selectedPoint.properties.dealer.kvps === kvps ? true : false;

			if (selected && !cluster) {
				if (
					isTimeSelected &&
					timeRefs[i] &&
					timeRefs[i].ref &&
					timeRefs[i].ref.current &&
					timeRefs[i].kvps === selectedPoint.properties.dealer.kvps
				) {
					timeRefs[i].ref.current.scrollIntoView({block: 'center', behavior: 'smooth'});
				} else if (
					!isTimeSelected &&
					distanceRefs[i] &&
					distanceRefs[i].ref &&
					distanceRefs[i].ref.current &&
					distanceRefs[i].kvps === selectedPoint.properties.dealer.kvps
				) {
					distanceRefs[i].ref.current.scrollIntoView({block: 'center', behavior: 'smooth'});
				}
			}
			return (
				<>
					<Box
						selected={selected}
						ref={(ref) => {
							if (!cluster && !isNull(timeRefs) && timeRefs.length > 0 && !isNull(ref) && isTime) {
								if (timeRefs[i] && timeRefs[i].ref) {
									timeRefs[i].ref.current = ref;
								}
							} else if (!cluster && !isNull(distanceRefs) && distanceRefs.length > 0 && !isNull(ref) && !isTime) {
								if (distanceRefs[i] && distanceRefs[i].ref) {
									distanceRefs[i].ref.current = ref;
								}
							}
						}}
						className="Box"
					>
						<DealerCard
							point={point}
							center={center}
							handleShowCalendarOnClick={handleShowCalendarOnClick}
							handleSeleccionarOnClick={handleSeleccionarOnClick}
							handleShowSlots={handleShowSlots}
						/>
					</Box>
				</>
			);
		});
	};

	return selectedPoints ? (
		<>
			<BreakpointWrapper max={Breakpoints.b960}>
				{props.showAllCardsMobile ? (
					<Layout
						allowOverflowingContent
						appearance={{
							[Breakpoints.default]: [
								{name: '.', columns: 2},
								{name: 'a', columns: 20},
								{name: '.', columns: 2},
							],
						}}
					>
						<Container padding={{bottom: ContainerPadding.static500, top: ContainerPadding.static250}}>
							<GoBackWrapper className="goback-wrapper">
								<CTA
									tag="button"
									emphasis="tertiary"
									ariaLabel="aria label"
									icon={<ArrowLeft variant="small" />}
									onClick={(e) => {
										e.preventDefault();
										hideCards();
									}}
								>
									<Text bold color={TokenTextColor.inherit}>
										Ir al mapa
									</Text>
								</CTA>
							</GoBackWrapper>
							<Text appearance={TokenTextAppearance.headline200} bold textAlign={TextAlignment.left}>
								Ordenar por:
							</Text>
							<SeleccionDealerTabs
								pointsByDistance={pointsByDistance}
								pointsByTime={pointsByTime}
								defaultIndex={defaultIndex}
								setDefaultIndex={setDefaultIndex}
								isTimeSelected={isTimeSelected}
								setIsTimeSelected={setIsTimeSelected}
								renderPoints={renderPoints}
							/>
							{/* <TabsWrapper className="tabs-dealers-wrapper">
                <Text appearance={TokenTextAppearance.headline200} bold textAlign={TextAlignment.left}>
                  Ordenar por:
                </Text>
                <Tabs
                  idPrefix="Tabs"
                  onChange={(index) => {
                    setDefaultIndex(index);
                    setIsTimeSelected(!isTimeSelected);
                  }}
                  defaultIndex={defaultIndex}
                >
                  {{
                    title: 'Ubicación',
                    content: renderPoints(pointsByDistance, false),
                    key: 'ubicacion',
                  }}
                  {{ title: 'Horario', content: renderPoints(pointsByTime, true), key: 'horario' }}
                </Tabs>
              </TabsWrapper> */}
						</Container>
					</Layout>
				) : null}
			</BreakpointWrapper>

			<BreakpointWrapper min={Breakpoints.b960}>
				<CloseComponent closeInMap handleAreYouSureLayer={handleAreYouSureLayer} />
				<ScrollWrapper className="desktop-scroll-controller">
					<Layout
						appearance={{
							[Breakpoints.default]: [
								{name: '.', columns: 2},
								{name: 'a', columns: 20},
								{name: '.', columns: 2},
							],
						}}
					>
						<Container
							padding={{
								bottom: ContainerPadding.static350,
								top: ContainerPadding.static250,
								left: ContainerPadding.static200,
							}}
						>
							<Text appearance={TokenTextAppearance.headline200} bold textAlign={TextAlignment.left}>
								Ordenar por:
							</Text>
						</Container>
						<Container padding={{bottom: ContainerPadding.static500}} horizontalAlign={'center'}>
							<SeleccionDealerTabs
								pointsByDistance={pointsByDistance}
								pointsByTime={pointsByTime}
								defaultIndex={defaultIndex}
								setDefaultIndex={setDefaultIndex}
								isTimeSelected={isTimeSelected}
								setIsTimeSelected={setIsTimeSelected}
								renderPoints={renderPoints}
							/>
						</Container>
					</Layout>
				</ScrollWrapper>
			</BreakpointWrapper>
		</>
	) : null;
}
