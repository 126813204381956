import React, { useEffect, useState } from 'react';
import {
  Text,
  Container,
  ContainerPadding,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import {
  Telefono,
  MultipleCTASelection,
  DetalleMotivo,
  MultipleOptionCTAs,
  Email,
  PantallaNombreApellido,
  ContactInfoWrapper
} from 'src/components';
import { Portada, Experiencia } from './ComponentsAndViews';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { dpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps, IdPerson, IdOrder } from 'src/types';
import {
  FormController,
  removeWhiteSpace,
  useFeatureAppConfig,
  useFeatureServices,
  useGetWaidLitera,
  useOneFormContext,
  useTrackingManager,
} from 'src/feature-app';
import { ThankYouEurocopa, ConfirmacionEurocopa } from './';
import { formDataBuilder } from 'src/forms/format-fields';
import { EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { createPincodeEntity } from 'src/forms/dps-utils';
import { GenericErrorLayer } from 'src/components/layers';

export const CTAsSiNoNsNc = [
  { label: 'Sí, sin dudarlo', value: 'true' },
  { label: 'No lo recomiendo', value: 'false' },
  { label: 'Aún no lo sé, es pronto', value: 'NS/NC' },
];
export const CTASCarga = [
  { label: 'En casa', value: 'casa' },
  { label: 'En el trabajo', value: 'trabajo' },
  { label: 'En ruta', value: 'ruta' },
  { label: 'Otro', value: 'otro' },
];
export const CTAsConduccion = [
  { label: 'Sí, siempre utilizo mi vehículo eléctrico', value: 'electrico' },
  { label: 'Lo combino con otro vehículo no eléctrico', value: 'combinado' },
];
export const CTAsGusta = [
  { label: 'La innovación', value: 'innovacion' },
  { label: 'La velocidad de la carga', value: 'velocidad carga' },
  { label: 'La practicidad para el día a día', value: 'practicidad' },
  { label: 'Que se adapta a mis escapadas/planes', value: 'adaptacion' },
  { label: 'Lo chulísimo que es', value: 'chulisimo' },
  { label: 'El ahorro económico', value: 'ahorro' },
  { label: 'Otro', value: 'otro' },
];

const getExperienciaTitle = (entregado: string) => {
  if (entregado === 'true') {
    return (
      <>
        ¿Podrías compartir tu experiencia{' '}
        <Text bold>
          desde que fichaste a tu{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            eléctrico?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
        <Container padding={{ top: ContainerPadding.dynamic0050 }}>
          <Text appearance={TokenTextAppearance.copy200} staticSize>
            Un <Text bold>discurso motivador</Text> antes de un partido importante{' '}
            <Text bold>puede marcar la diferencia</Text>, del mismo modo que tu respuesta<sup>1</sup> aquí es clave para
            que otros se sumen a la movilidad eléctrica. ¡Nos gustaría compartirla!
          </Text>
        </Container>
      </>
    );
  } else {
    return (
      <>
        ¿Qué fue lo que más te motivó{' '}
        <Text bold>
          para fichar a tu nuevo{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            eléctrico?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
        <Container padding={{ top: ContainerPadding.dynamic0050 }}>
          <Text appearance={TokenTextAppearance.copy200} staticSize>
            Un <Text bold>discurso motivador</Text> antes de un partido importante{' '}
            <Text bold>puede marcar la diferencia</Text>, del mismo modo que tu respuesta<sup>1</sup> aquí es clave para
            que otros se sumen a la movilidad eléctrica. ¡Nos gustaría compartirla!
          </Text>
        </Container>
      </>
    );
  }
};
const getExperienciaAlternateTitle = (entregado: string) => {
  if (entregado === 'true') {
    return (
      <>
        ¿Podrías compartir tu experiencia{' '}
        <Text bold>
          desde que fichaste a tu{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            eléctrico?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      </>
    );
  } else {
    return (
      <>
        ¿Qué fue lo que más te motivó{' '}
        <Text bold>
          para fichar a tu nuevo{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            eléctrico?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      </>
    );
  }
};

export enum EurocopaSteps {
  Trigger,
  Carga,
  MasInformacion,
  Coduccion,
  MultipleChoice,
  MultipleChoiceDetalle,
  Recomendacion,
  Experiencia,
  NombreApellido,
  EmailTelefono,
  Confirmacion,
}

const getIndexInSteps = (steps: Steps[], stepName: string) => steps.findIndex((step) => step.name === stepName);

export function EurocopaForm() {
  const { initializeForm, handleNextStep } = useOneFormContext();
  const store = useStore();
  const config = useFeatureAppConfig();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState(false);
  const [formCaducado, setFormCaducado] = useState(false);
  const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;
  const { formData } = useSelector((state: OneFormState) => state);
  const [idOrder, setIdOrder] = useState(false);
  const { carga, multipleChoice } = formData.fields;
  const trackingManager = useTrackingManager();
  const waid = useGetWaidLitera();

  const updateExperienciaStep = (conCoche: string, auxSteps?: Steps[]) => {
    const title = getExperienciaTitle(conCoche);
    const alternateTitle = getExperienciaAlternateTitle(conCoche);

    let newSteps = auxSteps ? auxSteps : [...steps];

    const experienciIndex = newSteps.findIndex((step: Steps) => step.name === 'Experiencia');

    newSteps[experienciIndex].title = title;
    newSteps[experienciIndex].alternateTitle = alternateTitle;

    dispatch({ type: 'UPDATE_STEPS', payload: newSteps });
  };

  const handleDetalle = (field: string, fieldName: string) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
    let newSteps = [...actualSteps];

    if (field === 'otro' || field.includes('otro')) {
      const detalleIndex = getIndexInSteps(newSteps, fieldName);

      if (detalleIndex === -1) {
        // En caso de haber eliminado el step hay que volver a añadirlo de nuevo y llamar a la función handleNextStep ya que
        // ésta se llama antes de volver a añadir el mapa y realiza el cálculo con los steps sin actualizar.
        const detalleStepIndex = getIndexInSteps(steps, fieldName);
        newSteps.splice(screenIndex + 1, 0, steps[detalleStepIndex]);

        setTimeout(() => {
          handleNextStep();
        }, 0);
      }
    } else {
      const detalleIndex = getIndexInSteps(newSteps, fieldName);
      newSteps.splice(detalleIndex, 1);
    }
    updateExperienciaStep('true', newSteps);

    const newStepsWithIndex = newSteps.map((step, index) => {
      step.screenIndex = index;
      return step;
    });

    updateSteps(newStepsWithIndex);
  };

  const updateSteps = (steps: Steps[]) => {
    dispatch({ type: 'UPDATE_STEPS', payload: steps });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: steps[steps.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: steps.length });
  };

  const steps: Steps[] = [
    {
      fields: <Portada />,
      screenIndex: EurocopaSteps.Trigger,
      name: 'Trigger',
      outputs: ['trigger'],
      outputsText: ['trigger'],
    },
    {
      title: (
        <>
          ¿Dónde sueles <Text bold>cargar las pilas</Text> de tu{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            ID.?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTASCarga} name="carga" nextOnClick />}
        />
      ),
      screenIndex: EurocopaSteps.Carga,
      name: 'Carga',
      outputs: ['carga'],
      outputsText: ['carga'],
    },
    {
      title: (
        <>
          <Text bold>¿Cuál?</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <DetalleMotivo
              CTALabel="Eso es todo"
              label="Escríbelo aquí"
              limitedCharacters={true}
              numberOfMaxLength={80}
            />
          }
        />
      ),
      screenIndex: EurocopaSteps.MasInformacion,
      name: 'masInformacion',
      outputs: ['masInformacion'],
      outputsText: ['masInformacion'],
    },
    {
      title: (
        <>
          ¿Es tu ID. tu{' '}
          <Text bold>
            vehículo{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              titular?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsConduccion} name="conduccion" nextOnClick />}
        />
      ),
      screenIndex: EurocopaSteps.Coduccion,
      name: 'Conduccion',
      outputs: ['conduccion'],
      outputsText: ['conduccion'],
    },
    {
      title: (
        <>
          ¿Qué dirías que supone <Text bold>un golazo</Text> por parte de tu{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            ID.?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
          <Container padding={{ top: ContainerPadding.dynamic0100 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Puedes elegir más de una respuesta.
            </Text>
          </Container>
        </>
      ),
      alternateTitle: (
        <>
          ¿Qué dirías que supone <Text bold>un golazo</Text> por parte de tu{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            ID.?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen element={<MultipleOptionCTAs ctaValues={CTAsGusta} name="multipleChoice" />} />
      ),
      screenIndex: EurocopaSteps.MultipleChoice,
      name: 'MultipleChoice',
      outputs: ['multipleChoice'],
      outputsText: ['multipleChoice'],
    },
    {
      title: (
        <>
          <Text bold>Cuéntanos más</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <DetalleMotivo
              CTALabel="Eso es todo"
              label="Escríbelo aquí"
              name="multipleChoiceDetalle"
              limitedCharacters={true}
              numberOfMaxLength={80}
            />
          }
        />
      ),
      screenIndex: EurocopaSteps.MultipleChoiceDetalle,
      name: 'MultipleChoiceDetalle',
      outputs: ['multipleChoiceDetalle'],
      outputsText: ['multipleChoiceDetalle'],
    },
    {
      title: (
        <>
          ¿Recomendarías a tus amigos o conocidos{' '}
          <Text bold>
            que se pasen al equipo{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              eléctrico?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsSiNoNsNc} name="recomendacion" nextOnClick />}
        />
      ),
      screenIndex: EurocopaSteps.Recomendacion,
      name: 'Recomendacion',
      outputs: ['recomendacion'],
      outputsText: ['recomendacion'],
    },
    {
      title: <></>, //Se actualiza en updateExperienciaStep
      alternateTitle: <></>, //Se actualiza en updateExperienciaStep
      fields: <OneElementTemplateFullScreen element={<Experiencia />} />,
      screenIndex: EurocopaSteps.Experiencia,
      name: 'Experiencia',
      outputs: ['experiencia'],
      outputsText: ['experiencia'],
    },
    {
      title: (
        <>
          Para poder entrar en el sorteo, necesitamos confirmar <Text bold>quién eres</Text>
        </>
      ),
      fields: <OneElementTemplateFullScreen element={<PantallaNombreApellido notALayer={true} />} />,
      screenIndex: EurocopaSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      title: (
        <>
          Y confirmar los datos para <Text bold>poder contactar contigo</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <ContactInfoWrapper>
              <Telefono paginaConfirmacion={false} required={true} notALayer={true} />
              <Email paginaConfirmacion={false} required={true} notALayer={true} paddingTop='24px'/>
            </ContactInfoWrapper>
          }
        />
      ),
      screenIndex: EurocopaSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      fields: <ConfirmacionEurocopa />,
      screenIndex: EurocopaSteps.Confirmacion,
      name: 'Confirmacion',
      isLastStep: true,
      hidePreviousStep: true,
    },
  ];

  const getFormInfo = (
    idOrder: IdOrder
  ): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    let newSteps = [...steps];

    if (idOrder?.SLI_State__c && Number(idOrder.SLI_State__c) < 70) {
      updateExperienciaStep('false');
    } else if (!idOrder?.SLI_State__c || (idOrder?.SLI_State__c && Number(idOrder.SLI_State__c) < 70)) {
      updateExperienciaStep('true');
    }

    if (idOrder?.SLI_State__c && Number(idOrder.SLI_State__c) < 70) {
      const indexFinalCorte = newSteps.findIndex((step) => step.name === 'Recomendacion');
      newSteps.splice(1, indexFinalCorte);
      setIdOrder(true);
      newSteps = newSteps.map((step, index) => {
        step.screenIndex = index;
        return step;
      });
    }

    return {
      firstStep: newSteps[0],
      lastStep: newSteps[newSteps.length - 1],
      stepsHistory: [0],
      numberOfScreens: newSteps.length,
      newSteps,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();

    const { fields, headers } = formData;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      const data = {
        'headers[PINCODE]': fields.pincode,
        'headers[DESC_ID_CLIMAPRO]': fields.idClimapro,

        'headers[COD_FORM_ID]': 'vw-oneform-adhoc_2021_eurocopa',
        'headers[DESC_WAID_LITERA]': 'Sorteo Eurocopa 2021',
        'headers[DESC_URLORIGIN]': url,
        'headers[DESC_BRAND]': 'VW',
        'headers[DESC_CAMPAIGNBLOCK]': '2. VW Sponsorship',
        'headers[DESC_DEVICETYPE]': device,
        'headers[DESC_FORMOBJECTIVE]': 'Concurso',
        'headers[DESC_FORM_DETAIL]': 'Sorteo Eurocopa 2021',
        'headers[DESC_ID_CAMPAIGN]': '7011n000000JooQAAS',
        'headers[DESC_TOKEN]': 'VW_DDB_FRM_CONCURSO_EUROCOPA', //CONSULTAR
        'headers[FECHA_INSERTION_DATE]': today,
        'headers[DESC_VN_VO]': 'VN',
        'headers[TC]': tc,
        'headers[DESC_GDPR_ADAPTED]': 'true',

        'fields[DESC_NAME]': fields.name,
        'fields[DESC_SURNAME_1]': fields.surname,
        'fields[DESC_SURNAME_2]': fields.secondSurname,
        'fields[DESC_EMAIL_ADDRESS]': removeWhiteSpace(fields.email),
        'fields[NUM_CELLPHONE]': `${fields.prefix}${fields.cellphone}`,
        'fields[DESC_AUXFIELD1]': fields.experiencia,
        'fields[DESC_AUXFIELD2]': fields.enCurso,
        'fields[DESC_AUXFIELD3]': fields.carga,
        'fields[DESC_AUXFIELD4]': fields.masInformacion,
        'fields[DESC_AUXFIELD5]': fields.conduccion,
        'fields[DESC_AUXFIELD6]': fields.multipleChoice,
        'fields[DESC_AUXFIELD7]': fields.multipleChoiceDetalle,
        'fields[DESC_AUXFIELD8]': fields.recomendacion,
        'fields[DESC_FORM_VERSION]': `vw-oneform-adhoc_2021_eurocopa-${process.env.VERSION}`,
        'fields[DESC_LOPD]': 'PERMITE',
        'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
        'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
      };
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData);

      if (response && response.data && response.data.content && response.data.content.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const handleComplete = () => {
    const dpsData = oneFormService.getDpsData();
    const idPerson = createPincodeEntity(dpsData, 'PERSON') as IdPerson;
    const idOrder = createPincodeEntity(dpsData, 'ORDER') as IdOrder;

    const enCursoAux = idOrder && parseFloat(idOrder.SLI_State__c) < 70 ? 'true' : 'false';

    if (!idPerson) {
      if (dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
        console.log(dpsData);
        setFormError(true);
      } else if (dpsData.pincode.pincodeUsed) {
        setFormCaducado(true);
      }
    }

    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo(idOrder);

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      formTheme: 'main',
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      thankYouComponent: <ThankYouEurocopa />,
    };

    const NuevoFormData: Partial<FormDataGroup> = {
      headers: {
        DESC_BRAND: 'VW',
        DESC_VN_VO: 'VN',
        DESC_GDPR_ADAPTED: 'true',
        COD_FORM_ID: 'vw-oneform-adhoc_2021_eurocopa',
        DESC_WAID_LITERA: waid ? waid : 'Sorteo Eurocopa 2021',
        DESC_CAMPAIGNBLOCK: '2. VW Sponsorship',
        DESC_FORMOBJECTIVE: 'Concurso',
        DESC_FORM_DETAIL: 'Sorteo Eurocopa 2021',
        DESC_ID_CAMPAIGN: '7011n000000JooQAAS',
        DESC_TOKEN: 'VW_DDB_FRM_CONCURSO_EUROCOPA', //CONSULTAR
      },

      fields: {
        name: idPerson?.FirstName,
        surname: idPerson?.LastName,
        secondSurname: idPerson?.TXT_2_Surname__c,
        email: idPerson?.TXT_Email__c,
        cellphone: idPerson?.NUM_Phone_mobile__c,
        pincode: dpsData.pincode?.pincodeResponse?.params?.pincode,
        enCurso: enCursoAux,
        idClimapro: idPerson?.ID_Person_DIM_IMP__c,
        formName: 'adhoc-2021-eurocopa',
        formType: 'otherform',
        pageCategory: 'Concurso',
      }, // Analítica
    };

    initializeForm(NuevoFormInfo, NuevoFormData, true);
    trackingManager.trackFormLoad();

    setLoading(false);
  };

  useEffect(() => {
    const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

    oneFormCoreDpsIsLoaded.subscribe({
      next(value) {},
      error(err) {
        console.error(err);
      },
      complete() {
        handleComplete();
      },
    });
  }, []);

  useEffect(() => {
    if (!idOrder) {
      if (carga) {
        handleDetalle(carga, 'masInformacion');
      }
    }
  }, [carga]);
  useEffect(() => {
    if (!idOrder) {
      if (multipleChoice) {
        handleDetalle(multipleChoice, 'MultipleChoiceDetalle');
      }
    }
  }, [multipleChoice]);

  return (
    <>
      {loading ? null : (
        <>
          {formError ? (
            <GenericErrorLayer
              title="Uy, algo ha pasado"
              bodycopy="Por alguna razón no hemos podido cargar este cuestionario."
              ctaLayer="Reintentar"
              notShowCTA={true}
              icon={false}
              notALayer={true}
            />
          ) : formCaducado ? (
            <GenericErrorLayer
              title="¡Ups!"
              bodycopy="Lo sentimos, pero ya has participado en el sorteo de las entradas exclusivas. Muchas gracias."
              ctaLayer="Reintentar"
              notShowCTA={true}
              icon={false}
              notALayer={true}
            />
          ) : (
            <FormController steps={steps} screenType="full-screen" />
          )}
        </>
      )}
    </>
  );
}
