import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import {
  CanalDeContacto,
  CarlineSelect,
  ContactInfoWrapper,
  PantallaNombreApellido,
  Email,
  Telefono,
  CodigoPostal,
  ConsentQuestion
} from 'src/components';
import { OneElementTemplate, OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { MapaLeads, ConfirmacionLeadsNoQuestions } from 'src/forms';
import { FormInfo, Steps } from 'src/types';
import { useIsComerciales } from 'src/feature-app/hooks';
import { LeadGenericoController } from '../LeadGenericoController';
import { LeadsFormsProps } from '../LeadGenericoForm';
import { useInitializeLeadForm } from '../use-initialize-lead-form';
import { leadsPresupuestoDealerPartialData, leadsPresupuestoPartialData } from '.';
import { MapaLeadsInPage, PresupuestoPartialDataConfigurador } from 'src/forms/LeadsInPage';

const getMapaFields = (inPage: boolean) => {
  if (inPage === true) {
    return <MapaLeadsInPage formName="presupuesto" />;
  } else {
    return <MapaLeads formName="presupuesto" />;
  }
};

export enum PresupuestoSteps {
  Modelo,
  Mapa,
  NombreApellido,
  CodigoPostal,
  Contacto,
  EmailTelefono,
  Confirmacion,
}

export function PresupuestoForm({ updatedFormData, isOfertasOrMatch, inPage, isConfigurador, isLeadDealer }: LeadsFormsProps) {
  const isComerciales = useIsComerciales();

  const [partialData, setPartialData] = useState(null);

  useEffect(() => {
    if (updatedFormData) {
      setPartialData(updatedFormData);
    } else if(isLeadDealer){
      setPartialData(leadsPresupuestoDealerPartialData);
    }
    else {
      setPartialData(leadsPresupuestoPartialData);
    }
  }, []);

  const defaultSteps: Steps[] = [
    {
      title: (
        <>
          <Text bold>¿Qué modelo </Text> te interesa?
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<div style={{ marginBottom: '2px' }}><CarlineSelect useCarline={true} /></div>} />
            :
            <OneElementTemplate element={<div style={{ marginBottom: '2px' }}><CarlineSelect useCarline={true} /></div>} />
          }
        </>
      ),
      screenIndex: PresupuestoSteps.Modelo,
      name: 'Modelo',
      outputs: ['modelo'],
      outputsText: ['modelo'],
    },
    isLeadDealer ? null :
      {
        screenIndex: PresupuestoSteps.Mapa,
        name: 'Mapa',
        multiStep: true,
        component: getMapaFields(inPage),
      },
    {
      title: (
        <>
          Para poder contactar contigo, necesitamos saber <Text bold>quién eres</Text>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen
              element={
                <div style={{ marginBottom: '2px' }}>
                  <PantallaNombreApellido isComerciales={isComerciales} />
                </div>
              }
            />
            :
            <OneElementTemplate
              element={
                <div style={{ marginBottom: '2px' }}>
                  <PantallaNombreApellido isComerciales={isComerciales} />
                </div>
              }
            />
          }
        </>
      ),
      screenIndex: PresupuestoSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      title: (
        <>
          También nos gustaría conocer <Text bold>tu código postal</Text>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<div style={{ marginBottom: '2px' }}><CodigoPostal /></div>} />
            :
            <OneElementTemplate element={<div style={{ marginBottom: '2px' }}><CodigoPostal /></div>} />
          }
        </>
      ),
      screenIndex: PresupuestoSteps.CodigoPostal,
      name: 'CodigoPostal',
      outputs: ['codigoPostal'],
      outputsText: ['codigoPostal'],
    },
    {
      title: (
        <>
          <Text bold>¿Cómo prefieres </Text>que contactemos contigo?
          <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<CanalDeContacto />} />
            :
            <OneElementTemplate element={<CanalDeContacto />} />
          }
        </>
      ),
      screenIndex: PresupuestoSteps.Contacto,
      name: 'Contacto',
      outputs: ['contacto'],
      outputsText: ['contacto'],
    },
    {
      title: (
        <>
          Para ello necesitaremos <Text bold>tus datos de contacto</Text>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen
              element={
                <ContactInfoWrapper>
                  <Telefono paginaConfirmacion={false} required={true} />
                  <Email paginaConfirmacion={false} required={true} paddingTop='24px' />
                </ContactInfoWrapper>
              }
            />
            :
            <OneElementTemplate
              element={
                <ContactInfoWrapper>
                  <Telefono paginaConfirmacion={false} required={true} />
                  <Email paginaConfirmacion={false} required={true} paddingTop='24px' />
                </ContactInfoWrapper>
              }
            />
          }
        </>
      ),
      screenIndex: PresupuestoSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      fields: <ConsentQuestion questionType='lssi' />,
      name: 'lssi',
      outputs: ['lssi'],
    },
    {
      fields: <ConsentQuestion questionType='tmk' />,
      name: 'tmk',
      outputs: ['tmk'],
    },
    {
      fields: <ConfirmacionLeadsNoQuestions />,
      isLastStep: true,
      hidePreviousStep: true,
      name: 'Confirmacion',
    },
  ];

  const filteredSteps = defaultSteps.filter((step) => step !== null);

  const { loading, steps, firstStep, lastStep } = useInitializeLeadForm(filteredSteps, false, inPage);

  const citaVentaInfo: Partial<FormInfo> = {
    numberOfScreens: steps.length,
  };

  return loading ? null : (
    <LeadGenericoController
      steps={steps}
      leadData={isConfigurador ? PresupuestoPartialDataConfigurador : partialData}
      leadInfo={citaVentaInfo}
      firstStep={firstStep}
      lastStep={lastStep}
      inPage={inPage}
      isConfigurador={isConfigurador}
    />
  );
}
