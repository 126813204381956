import { Breakpoints, BreakpointWrapper, CTA, styled } from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerHandleV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { OneFormState } from 'src/types';
import {
  LayerManagerWrapper,
  useFeatureAppEnvironment,
  useFeatureServices,
  useOneFormContext,
  useTrackingManager,
} from 'src/feature-app';
import { KoScreen, LoadingScreen, IntermitenciaDMSKo, InvalidForm } from 'src/components';

const PositionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface LastNavigationProps {
  compartir?: string;
  setCompartirError?: React.Dispatch<React.SetStateAction<boolean>>;
  setPrivacidadError?: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  KoTitle?: string;
  KoBody?: string;
  loadingCopy?: string;
  ContentID?: string;
  checkNotSavedFields?: () => string;
  ActivarCheckNotSavedFields?: boolean;
}


export const LastNavigation = (props: LastNavigationProps) => {
  const {
    label,
    KoTitle,
    KoBody,
    loadingCopy,
    setCompartirError,
    ContentID,
    checkNotSavedFields,
    ActivarCheckNotSavedFields,
  } = props;

  const { handleShowFinalScreen, handleSubmit } = useOneFormContext();
  const { triggerValidation, errors } = useFormContext();
  const [layerVisible, setLayerVisible] = useState<boolean>(false);
  const [notSavedField, setNotSavedField] = useState<string>(null);
  const [koLayer, setKoLayer] = useState<LayerHandleV2<any>>(null);
  const [koScreen, setKoScreen] = useState<boolean>(false);
  const [intermitenciaLayerLayer, setIntermitenciaLayerLayer] = useState<LayerHandleV2<any>>(null);
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const dispatch = useDispatch();
  const trackingManager = useTrackingManager();
  const { formData } = useSelector((state: OneFormState) => state);

  //Loading and Ko layers

  const handleKoLayer = () => {
    const layer = layerManager.openFocusLayer(renderKoLayer, {}, { size: FocusLayerSizeV2.A });
    setKoLayer(layer);
  };

  const renderKoLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <KoScreen retrySubmit={retrySubmit} closeLayerCallback={close} title={KoTitle} bodyCopy={KoBody} />
      </LayerManagerWrapper>
    );
  };

  const handleLoadingScreenLayer = () => {
    const layer = layerManager.openFocusLayer(renderLoadingScreen, {}, { size: FocusLayerSizeV2.A });

    return layer;
  };

  const renderLoadingScreen = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingScreen isleads copy={loadingCopy} />
      </LayerManagerWrapper>
    );
  };

  const handleIntermitenciaDMSKoLayer = () => {
    const layer = layerManager.openFocusLayer(renderIntermitenciaDMSKoLayer, {}, { size: FocusLayerSizeV2.A });
    setIntermitenciaLayerLayer(layer);
    // return layer;
  };

  const renderIntermitenciaDMSKoLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <IntermitenciaDMSKo retrySubmit={retrySubmit} closeLayerCallback={close} />
      </LayerManagerWrapper>
    );
  };

  const handleInvalidFormLayer = () => {
    const layer = layerManager.openFocusLayer(renderInvalidFormLayer, {}, { size: FocusLayerSizeV2.A });
    // setConfirmacionLayer(layer);

    // return layer;
  };
  const renderInvalidFormLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <InvalidForm notSavedField={notSavedField} closeLayerCallback={close} />
      </LayerManagerWrapper>
    );
  };

  // 1. check de lssi y tmk
  // 2. submit de la cita y activación la pantalla de feedback correspondiente
  // no se hace ninguna validación en este último paso porque esta ya se ha hecho en el edit-guardar de cada campo previamente
  async function handleConfirmar(e: any) {
    if (formData.fields.formName === 'no_compra-no_tengo' && formData.fields.quieroContacto === 'true' ||
      formData.fields.formName === 'no_renovacion-no_tengo' && formData.fields.quieroContacto === 'true') {
      if (!formData.fields.compartir) {
        setCompartirError(true);
      } else {
        trackingManager.trackFormCTAClick({
          contentId: ContentID ? ContentID : 'Enviar',
        });

        const loadingLayer = handleLoadingScreenLayer();
        setLayerVisible(true);

        const value = await handleSubmit(e);
        loadingLayer.close();

        if (value) {
          handleShowFinalScreen();
        } else {
          handleKoLayer();
        }
      }
    } else {
      const isValid = await triggerValidation();
      if (setCompartirError && !isValid) {
        setCompartirError(true);
      }
      
      if (!isValid) return;

      trackingManager.trackFormCTAClick({
        contentId: ContentID ? ContentID : 'Enviar',
      });

      if (ActivarCheckNotSavedFields) {
        const someNotSavedField = checkNotSavedFields();
        setNotSavedField(someNotSavedField);

        if (someNotSavedField === null) {
          const loadingLayer = handleLoadingScreenLayer();
          setLayerVisible(true);

          const value = await handleSubmit(e);
          loadingLayer.close();

          if (value) {
            handleShowFinalScreen();
          } else {
            const { sendDmsFailed } = store.getState().formInfo;
            if (sendDmsFailed) {
              handleIntermitenciaDMSKoLayer();
            } else {
              handleKoLayer();
            }
          }
        } else {
          handleInvalidFormLayer();
        }
      } else {
        const loadingLayer = handleLoadingScreenLayer();
        setLayerVisible(true);

        const value = await handleSubmit(e);
        loadingLayer.close();

        if (value) {
          handleShowFinalScreen();
        } else {
          handleKoLayer();
        }
      }
    }
  }
  async function retrySubmit(e: any) {
    if (koLayer) {
      koLayer.close();
    }
    setKoScreen(false);
    const loadingLayer = handleLoadingScreenLayer();
    const value = await handleSubmit(e);
    loadingLayer.close();
    if (value) {
      handleShowFinalScreen();
    } else {
      handleKoLayer();
      setKoScreen(true);
    }
  }

  useEffect(() => {
    if (!layerVisible && koScreen) setKoScreen(false);
  }, [layerVisible]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FORM_ENDED', payload: true });
  }, []);

  return (
    <PositionWrapper className="last-navigation-position">
      <BreakpointWrapper max={Breakpoints.b560}>
        <CTA
          tag="button"
          type="submit"
          emphasis="primary"
          onClick={(e) => {
            e.preventDefault();
            handleConfirmar(e);
          }}
          stretchContent
        >
          {label ? label : 'Enviar'}
        </CTA>
      </BreakpointWrapper>
      <BreakpointWrapper min={Breakpoints.b560}>
        <CTA
          tag="button"
          type="submit"
          emphasis="primary"
          onClick={(e) => {
            e.preventDefault();
            handleConfirmar(e);
          }}
        >
          {label ? label : 'Enviar'}
        </CTA>
      </BreakpointWrapper>
    </PositionWrapper>
  );
};
