import { Text } from "@volkswagen-onehub/components-core";
import React from "react";

export const questions = [
    {
        questionName: 'Test ID 01',
        title: <>1. Estás en Madrid y te apetece hacer una escapada en tu ID.7.{' '}<Text bold>¿Hasta dónde podrías llegar aproximadamente?</Text></>,
        answers: [
            'Hasta Barcelona, podría llegar a hacer 700 km.',
            'Hasta Zaragoza y no más que esos 300 km.',
            'Hasta Toledo. Hombre, ni que pudiera recorrer más de 70km.',
        ],
        correctAnswer: 'Hasta Barcelona, podría llegar a hacer 700 km.',
        imageSrc: 'https://assets.volkswagen.com/is/image/volkswagenag/DB2023AU00207_medium%201?Zml0PWNyb3AlMkMxJndpZD00NjQmaGVpPTQ2NCZmbXQ9cG5nLWFscGhhJmJmYz1vZmYmNjA2Mg=='
    },
    {
        questionName: 'Test ID 02',
        title: <>​2. Estás organizando el viaje de verano con tu ID.5 y quieres saber dónde podrás cargarlo.{' '}<Text bold>¿Cuántos puntos habilitados de carga hay en España?</Text></>,
        answers: [
            '4.444, como las cuatro ruedas de un coche.',
            'Más de 18.000, es casi imposible no toparse con uno.',
            '93, como días tiene el verano.',
        ],
        correctAnswer: 'Más de 18.000, es casi imposible no toparse con uno.',
        imageSrc: 'https://assets.volkswagen.com/is/image/volkswagenag/DB2020AL01387_medium%201?Zml0PWNyb3AlMkMxJndpZD00NjImaGVpPTQ2MiZmbXQ9cG5nLWFscGhhJmJmYz1vZmYmZTJiZQ=='
    },
    {
        questionName: 'Test ID 03',
        title: <>3. Oh no...está empezando a sonar la canción de tu ex en tu ID.7 y quieres cambiarla.{' '}<Text bold> ¿Cómo lo haces?</Text></>,
        answers: [
            'Hago un gesto con la mano de izquierda a derecha, como si fuera un director de orquesta.',
            'Se lo pido a mi copiloto, que es muy majo.',
            'Me desespero y toco todos los botones del coche a la vez.',
        ],
        correctAnswer: 'Hago un gesto con la mano de izquierda a derecha, como si fuera un director de orquesta.',
        imageSrc: 'https://assets.volkswagen.com/is/image/volkswagenag/DB2023AU00281_medium%201?Zml0PWNyb3AlMkMxJndpZD00NjQmaGVpPTQ2NCZmbXQ9cG5nLWFscGhhJmJmYz1vZmYmNjA2Mg=='
    },
    {
        questionName: 'Test ID 04',
        title: <>4. Estás subiendo los pirineos con un ID.4 y quieres cambiar de marcha para que el coche tenga más potencia.{' '}<Text bold>¿Cómo lo haces?</Text></>,
        answers: [
            '¡Muy fácil! Con el cambio de marchas de toda la vida.',
            '¿Qué marcha? Los coches eléctricos lo dan todo sin marchas.',
            'Le pongo la banda sonora de UP para que siga subiendo.',
        ],
        correctAnswer: '¿Qué marcha? Los coches eléctricos lo dan todo sin marchas.',
        imageSrc: 'https://assets.volkswagen.com/is/image/volkswagenag/DB2022AU00678_medium%201?Zml0PWNyb3AlMkMxJndpZD00NjQmaGVpPTQ2NCZmbXQ9cG5nLWFscGhhJmJmYz1vZmYmNjA2Mg=='
    },
    {
        questionName: 'Test ID 05',
        title: <>5. Quieres parar a tomarte un descanso y de paso aprovechar para cargar tu ID.3 en un punto de carga rápida.{' '}<Text bold>¿Cuántos minutos necesitarás para cargarlo al 80%?</Text></>,
        answers: [
            '30 minutos, lo que tardo en tomarme un descansito y un café.',
            '80 minutos, lo mismo que un capítulo de Juego de Tronos.',
            '60 minutos, lo que dura una clase de Cycling en el gimnasio.',
        ],
        correctAnswer: '30 minutos, lo que tardo en tomarme un descansito y un café.',
        imageSrc: 'https://assets.volkswagen.com/is/image/volkswagenag/DB2020NR00454_medium%201?Zml0PWNyb3AlMkMxJndpZD00NjQmaGVpPTQ2NCZmbXQ9cG5nLWFscGhhJmJmYz1vZmYmNjA2Mg=='
    },
];

