import Axios, { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import {
  Text,
  Container,
  ContainerPadding,
  ContainerGutter,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { TwoElementsTemplate } from 'src/feature-app/Screen';
import { ConfirmacionCallMeOferta } from './ConfirmacionCallMeOferta';
import { Nombre, Telefono, GeneralInputWidthWrapper } from 'src/components';
import { useDispatch, useStore } from 'react-redux';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps, MolecularResponse } from 'src/types';
import { FormController, useFaContent, useFeatureAppConfig, useOneFormContext } from 'src/feature-app';
import { ThankYouCallMeOferta } from '.';
import { useGetBrand } from 'src/feature-app/hooks';

export enum CallMeOfertaSteps {
  NombreTelefono,
  Confirmacion,
}

export function CallMeOfertaForm() {
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const dispatch = useDispatch();
  const faContent = useFaContent();
  const brand = useGetBrand();

  const steps: Steps[] = [
    {
      title: (
        <Container wrap={'always'}>
          <Text>
            Para llamarte, necesitamos <Text bold>tus datos de contacto</Text>
          </Text>
          <Text appearance={TokenTextAppearance.copy200}>
            Nos pondremos en contacto contigo de lunes a viernes de 9:00 h a 19:30 h (excepto
            festivos nacionales) para darte más información.
          </Text>
        </Container>
      ),
      fields: (
        <Container
          gutter={ContainerGutter.static400}
          padding={{ top: ContainerPadding.static500 }}
          wrap={'always'}
          horizontalAlign={'flex-start'}
        >
          <TwoElementsTemplate
            firstElement={
              <GeneralInputWidthWrapper>
                <Nombre />
              </GeneralInputWidthWrapper>
            }
            secondElement={<Telefono hidePrefijo={brand === 'N'} paginaConfirmacion={false} required={true} />}
          />
        </Container>
      ),
      screenIndex: CallMeOfertaSteps.NombreTelefono,
      name: 'NombreTelefono',
      outputs: ['name', 'cellphone'],
      outputsText: ['name', 'cellphone'],
    },
    {
      fields: <ConfirmacionCallMeOferta />,
      screenIndex: CallMeOfertaSteps.Confirmacion,
      name: 'Confirmacion',
      isLastStep: true,
      hidePreviousStep: true,
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_VN_VO: 'VN',
      DESC_GDPR_ADAPTED: 'true',
    },
    fields: { formName: 'callme-oferta', formType: 'contact', pageCategory: 'Call Me Oferta' }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData }: OneFormState = store.getState();
    const { fields } = formData;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      //Set de data para Producción
      const dataPro = {
        name: fields.name,
        // phone: `${fields.prefix}${fields.cellphone}`,
        phone: `${fields.cellphone}`,

        DESC_LSSI: fields.lssi === 'PERMITIDO' ? 'S' : 'N',
        DESC_LOPD_GDPR_PERFILADO: fields.tmk === 'PERMITIDO' ? 'S' : 'N',

        ref: url,

        oferta: faContent.oferta.modalidad + ' ' + faContent.oferta.cuotaText + ' ' + faContent.oferta.financiacionText,
        modelo: faContent.oferta.carline.title + ' ' + faContent.oferta.motorizacion,
        modelo_code: faContent.oferta.carline.code + '_' + faContent.marketingCodeConfig.SalesgroupId,
      };

      //Set de data para Preproducción
      const dataPre = {
        PRE: 1,
        name: fields.name,
        // phone: `${fields.prefix}${fields.cellphone}`,
        phone: `${fields.cellphone}`,

        DESC_LSSI: fields.lssi === 'PERMITIDO' ? 'S' : 'N',
        DESC_LOPD_GDPR_PERFILADO: fields.tmk === 'PERMITIDO' ? 'S' : 'N',

        ref: url,

        oferta: faContent.oferta.modalidad + ' ' + faContent.oferta.cuotaText + ' ' + faContent.oferta.financiacionText,
        modelo: faContent.oferta.carline.title + ' ' + faContent.oferta.motorizacion,
        modelo_code: faContent.oferta.carline.code + '_' + faContent.marketingCodeConfig.SalesgroupId,
      };

      const response: AxiosResponse<MolecularResponse> = await Axios.post(
        'https://www.volkswagencomerciales.es/call-me-now/email',
        window.location.hostname === 'www.volkswagen-comerciales.es' ? dataPro : dataPre
      ).catch((err) => {
        return null;
      });

      if (response && response.data && response.data.success && response.status == 200) {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      formTheme: 'inverted',
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      notALayer: false,
      formType: 'layer',
      stepsHistory,
      thankYouComponent: <ThankYouCallMeOferta />,
    };

    initializeForm(NuevoFormInfo, NuevoFormData);
  }, []);

  return <FormController steps={steps} screenType="layer-screen" />;
}
