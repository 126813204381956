import {
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { useOneFormContext } from 'src/feature-app';

interface CTAFieldsProps {
  url: string;
}

export const LeadsCTAs = (props: CTAFieldsProps) => {
  const { url } = props;
  const { closeLayer } = useOneFormContext();

  
  return (
    <Container
      padding={{ top: ContainerPadding.static300 }}
      wrap={'always'}
      gutter={ContainerGutter.static300}
    >
      <CTA
        tag="a"
        href={url}
        emphasis='primary'
      >
        Conócelo
      </CTA>
      <CTA
        tag="button"
        emphasis="secondary"
        onClick={(e) => {
          e.preventDefault();
          closeLayer();
        }}
      >
        Seguir navegando
      </CTA>
    </Container>
  );
};
