import { styled } from '@volkswagen-onehub/components-core';
import {
  CloseHandleV2,
  FocusLayerSizeV2,
  InteractionLayerSizeV2,
  LayerHandleV2,
  LayerManagerV2,
} from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { AreYouSureLayer, GenericErrorLayer } from 'src/components';
import {
  getTrigger,
  LayerManagerWrapper,
  useFeatureAppConfig,
  useFeatureAppEnvironment,
  useFeatureServices,
  useTrackingManager,
  useIsDevStage,
} from 'src/feature-app';
import { ScreenController } from 'src/feature-app/Screen';
import { getSentryHub, setSentryTag } from 'src/feature-app/sentry';
import { OneFormState } from 'src/types';

const basis = 24;

function assertCountGridColumn(count: number): void {
  if (process.env.NODE_ENV !== 'production') {
    if (Math.floor(count) !== count) {
      throw new Error('count has to be an integer.');
    }

    if (count < 0 || count > basis) {
      throw new Error(`count has to be between 0 and ${basis}.`);
    }
  }
}

export function getGridColumn(count: number): string {
  assertCountGridColumn(count);

  // Round down to two decimal places
  // two decimal places because Edge has issues with too many decimal places
  // round down, so (positive) rounding errors do not add up
  return `${Math.floor((100 / basis) * count * 100) / 100}vw`;
}

export function getColumnsWidthForBreakpoint(columns: number, breakpoint: number): string {
  assertCountGridColumn(columns);

  // Round down to two decimal places
  // two decimal places because Edge has issues with too many decimal places
  // round down, so (positive) rounding errors do not add up
  return `${Math.floor((breakpoint / basis) * columns * 100) / 100}px`;
}

// Background determina el color del background, y la altura mínima que debe ocupar este color.
// Por los errores del layer también determina el width.

export const BackgroundOneForm = styled.div`
  background-color: ${(props) => props.theme.colors.background.primary};
  min-height: 100vh;
  width: 100vw;
  @media all and (min-width: 960px) {
    width: 960px;
  }
`;

const getConfigFormattedName = (name: string) =>
  name
    .replace('_', '-')
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export function OneFormTrigger() {
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const { formTheme, genericError } = useSelector(
    (state: OneFormState) => state.formInfo,
    (prev, next) => prev.formTheme === next.formTheme && prev.genericError === next.genericError
  );
  const formName = useSelector((state: OneFormState) => state.formData.fields.formName);
  const oneFormLayer = useRef<LayerHandleV2<any>>(null);
  const oneFormLayerFocus = useRef<LayerHandleV2<any>>(null);
  const genericErrorLayer = useRef<LayerHandleV2<any>>(null);
  const [selectedJourneyElement, setSelectedJourneyElement] = useState<JSX.Element>(null);
  const [selectedFocusJourney, setSelectedFocusJourney] = useState<JSX.Element>(null);
  const [CTALabel, setCTALabel] = useState<string>(null);
  const [skipTrackButtonClick, setSkipTrackButtonClick] = useState(false);
  const trackingManager = useTrackingManager();
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const config = useFeatureAppConfig();
  const triggerLoaded = useRef<string>(null);
  const isDevStage = useIsDevStage();
  const sentryHub = getSentryHub();

  useEffect(() => {
    if (selectedJourneyElement) {
      handleOneFormLayer();
      // sentryHub.setTag('Form', 'Test');
    }

    if (selectedFocusJourney) {
      handleOneFormFocusLayer();
    }
  }, [selectedJourneyElement, selectedFocusJourney]);

  useEffect(() => {
    if (genericError) {
      handleGenericErrorLayer();
    }
  }, [genericError]);

  useEffect(() => {
    if (triggerLoaded.current && isDevStage) {
      setSentryTag('oneform.trigger', triggerLoaded.current);
      const name = getConfigFormattedName(triggerLoaded.current);
      console.log('%cOneform Loading Trigger:', 'font-weight: 700', name);
    }
  }, [triggerLoaded]);

  useEffect(() => {
    if (formName) {
      setSentryTag('oneform.form', formName);
    }
  }, [formName]);

  const setSelectedJourney = (selectedJourney: JSX.Element, skipTrackButtonClick?: boolean) => {
    if (skipTrackButtonClick) {
      setSkipTrackButtonClick(skipTrackButtonClick);
    }
    setSelectedJourneyElement(selectedJourney);
  };

  const renderOneForm = (state: any, close: CloseHandleV2<any, any>): JSX.Element => {
    return (
      <LayerManagerWrapper store={store} env={env} theme='inverted' close={close}>
        <BackgroundOneForm className="general-background">
          <ScreenController
            selectedJourney={selectedJourneyElement}
            closeLayerCallback={close}
            handleAreYouSureLayer={handleAreYouSureLayer}
            notALayer={false}
          />
        </BackgroundOneForm>
      </LayerManagerWrapper>
    );
  };

  const renderOneFormFocus = (state: any, close: CloseHandleV2<any, any>): JSX.Element => {
    return (
      <LayerManagerWrapper store={store} env={env} theme="main">
        <ScreenController
          selectedJourney={selectedFocusJourney}
          closeLayerCallback={close}
          handleAreYouSureLayer={handleAreYouSureLayer}
          notALayer={true}
        />
      </LayerManagerWrapper>
    );
  };

  const renderGenericErrorLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <GenericErrorLayer closeLayerCallback={close} closeOneForm={oneFormLayer.current.close} />
      </LayerManagerWrapper>
    );
  };

  const renderAreYouSureLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <AreYouSureLayer
          layerName="isCloseLayer"
          closeLayerCallback={close}
          closeOneForm={oneFormLayer.current.close}
          onClickProp={() => {}}
        />
      </LayerManagerWrapper>
    );
  };

  const handleOneFormLayer = () => {
    if (!skipTrackButtonClick) {
      trackingManager.trackButtonClick({ contentId: CTALabel });
    }
    const layer = layerManager.openInteractionLayer(renderOneForm, {}, { size: InteractionLayerSizeV2.D });
    oneFormLayer.current = layer;
  };

  const handleOneFormFocusLayer = () => {
    if (!skipTrackButtonClick) {
      trackingManager.trackButtonClick({ contentId: CTALabel });
    }

    const layer = layerManager.openFocusLayer(
      renderOneFormFocus,
      {},
      { size: FocusLayerSizeV2.A, onClose: () => setSelectedFocusJourney(null) }
    );
    oneFormLayerFocus.current = layer;
  };

  const handleGenericErrorLayer = () => {
    const layer = layerManager.openFocusLayer(renderGenericErrorLayer, {}, { size: FocusLayerSizeV2.A });
    genericErrorLayer.current = layer;
  };

  const handleAreYouSureLayer = () => {
    const layer = layerManager.openFocusLayer(renderAreYouSureLayer, {}, { size: FocusLayerSizeV2.A });
    return layer;
  };

  return React.useMemo(
    () =>
      getTrigger(
        config,
        setSelectedJourney,
        setCTALabel,
        selectedJourneyElement,
        setSelectedFocusJourney,
        selectedFocusJourney,
        triggerLoaded
      ),
    []
  );
}

// export const OneFormTrigger = React.memo(OneFormTriggerComp);
