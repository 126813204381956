import { FeatureAppEnvironment } from '@feature-hub/core';
import { Dependencies } from 'src';

let featureServicesMirror: Dependencies = null;
let featureEnvironmentMirror: FeatureAppEnvironment<any, any> = null;

export const saveFeatureServices = (featureServices: Dependencies) => {
  featureServicesMirror = featureServices;
};
export const saveEnvironment = (environment: FeatureAppEnvironment<any, any>) => {
  featureEnvironmentMirror = environment;
};
export const getFeatureServices = () => featureServicesMirror;
export const getEnvironment = () => featureEnvironmentMirror;
export const getOneHubConfig = () => featureServicesMirror['service-config-provider'].configs['es-onehub-vw'];
export const getDataServiceConfig = () => featureServicesMirror['service-config-provider'].configs['es-vwdata'];
export const getMolecularCdnServiceConfig = () =>
  featureServicesMirror['service-config-provider'].configs['es-molecular-cdn'];
export const getMolecularServiceConfig = () => featureServicesMirror['service-config-provider'].configs['es-molecular'];
export const getVicciServiceConfig = () => featureServicesMirror['service-config-provider'].configs['oneapi-vicci'];
export const getVicciApiKey = () => featureServicesMirror['api-key-provider'].apiKey;
export const getGoogleApiKeyServiceConfig = () =>
  featureServicesMirror['service-config-provider'].configs['es-google-apikey'];
export const getOneformBFFCdnServiceConfig = () =>
  featureServicesMirror['service-config-provider'].configs['es-oneform-bff-cdn'];
export const getOneformBFFServiceConfig = () => featureServicesMirror['service-config-provider'].configs['es-oneform-bff'];
export const getVwEventosConfig = () => featureServicesMirror['service-config-provider'].configs['es-vweventos'];
