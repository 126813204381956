
import React, { useEffect, useState } from 'react';
import { Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { MultipleCTASelection } from 'src/components';
import { useOneFormContext } from 'src/feature-app';
import { RenderController, OneElementTemplate } from 'src/feature-app/Screen';
import LoadScriptInstance from 'src/feature-app/NewMap/Loadscript';
import { LocateUser } from 'src/forms/CitaPosventa/NuevaCitaPosventa';
import { TIME_FOR_TRANSFORM } from 'src/globals';
import { DealersData, OneFormState, Steps } from 'src/types';
import { JourneyLeadsWrapper } from './JourneyLeadsWrapper';
import { CTAsHorario } from 'src/feature-app/InputsValues';

enum MapaLeadsSteps {
  Dealer,
  Horario,
  SeleccionCita,
  SeleccionFecha,
}

interface MapaLeadsProps {
  showHorario?: boolean;
  formName: string;
}

const renderTitle = (formName: string) => {
  return (
    <>
      {formName === 'presupuesto' || formName === 'masInformacion' || formName === 'oferta' ? (
        <Text>
          {' '}
          <Text bold>¿Qué experto</Text> quieres que se{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      ) : (
        <>
          {formName === 'testDrive' ? (
            <Text>
              {' '}
              <Text bold>¿Dónde</Text> prefieres{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                provarlo?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
              </span>
            </Text>
          ) : (
            <Text>
              {' '}
              <Text bold>¿A dónde</Text> quieres{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                ir?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
              </span>
            </Text>
          )}
        </>
      )}
    </>
  );
};

const isLastScreen = (steps: Steps[], multiStepScreenIndex: number) => steps.length - 1 === multiStepScreenIndex;
const isFirstScreen = (multiStepScreenIndex: number) => multiStepScreenIndex === 0;

export function MapaLeads(props: MapaLeadsProps) {
  const { formName, showHorario } = props;
  const dispatch = useDispatch();
  const store = useStore();
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { dealer }: { dealer?: DealersData } = formData.fields;
  const { firstMapLoad, multiStepScreenIndex, navigationMovingForward } = formInfo;

  const {
    handleScreenChange,
    // setWaitForNextStep,
    setNextMultiStep,
    setNextMultiStepScreenIndex,
    setPreviousMultiStep,
    setWaitForPreviousStep,
    waitForPreviousStep,
    // waitForNextStep,
    setIsFullScreen,
    setShowPreviousStep,
  } = useOneFormContext();

  let stepsHorario: Steps[] = [
    {
      title: <>{renderTitle(formName)}</>,
      fields: <LocateUser />,
      screenIndex: MapaLeadsSteps.Dealer,
      name: 'MapSearch',
      outputs: ['location'],
      outputsText: ['location'],
      showOverflow: true,
    },
    {
      screenIndex: MapaLeadsSteps.Horario,
      name: 'MapSlotPref',
      title: (
        <>
          <Text bold>¿Cúando</Text> prefieres pasar por el{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            punto de venta?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplate element={<MultipleCTASelection ctaValues={CTAsHorario} name="horario" nextOnClick />} />
      ),
      outputs: ['horario'],
      outputsText: ['horario'],
    },
    {
      screenIndex: MapaLeadsSteps.SeleccionCita,
      name: 'MapSelect',
      alternateTitle: (
        <>
          <Text>
            <Text bold>¿Qué experto</Text> quieres que se{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
          {formName === 'presupuesto' ?
            (
              <div>
                <Text appearance={TokenTextAppearance.copy200}>Los encargados de elaborar un presupuesto son los asesores de la red de Concesionarios Oficial Volkswagen. Elige uno y le enviaremos tu petición para que te haga una propuesta a medida.{' '}</Text>
              </div>
            )
            : null
          }
        </>
      ),
      title: <></>,
      fields: <JourneyLeadsWrapper />,
      outputs: ['installation'],
      outputsText: ['installation'],
      fullScreen: true,
      multiStepLastScreen: true,
      hidePreviousStep: true,
    },
  ];

  let stepsWithoutHorario: Steps[] = [
    {
      title: (
        <>
          <Text>
            <Text bold>¿Qué experto</Text> quieres que se{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
          {formName === 'presupuesto' ?
            (
              <div>
                <Text appearance={TokenTextAppearance.copy200}>Los encargados de elaborar un presupuesto son los asesores de la red de Concesionarios Oficial Volkswagen. Elige uno y le enviaremos tu petición para que te haga una propuesta a medida.{' '}</Text>
              </div>
            )
            : null
          }
        </>
      ),
      fields: <LocateUser />,
      screenIndex: MapaLeadsSteps.Dealer,
      name: 'MapSearch',
      outputs: ['location'],
      outputsText: ['location'],
      showOverflow: true,
    },
    {
      screenIndex: 1,
      name: 'MapSelect',
      alternateTitle: (
        <Text>
          <Text bold>¿Qué experto</Text> quieres que se{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      ),
      title: <></>,
      fields: <JourneyLeadsWrapper />,
      outputs: ['installation'],
      outputsText: ['installation'],
      fullScreen: true,
      multiStepLastScreen: true,
      hidePreviousStep: true,
    },
  ];

  const steps = showHorario ? stepsHorario : stepsWithoutHorario;
  const [selectedStep, setSelectedStep] = useState<Steps>(null);

  /**
   * Hay que esperar a que se cree el mapa de google para tener acceso a window.google
   */
  const onMapLoad = () => {
    handleFirstLoad();
  };

  const handleFirstLoad = async () => {
    if (firstMapLoad) {
      // Inicialización
      initializeMultiStep();
    } else {
      if (navigationMovingForward || navigationMovingForward === null || navigationMovingForward === undefined) {
        // Inicialización cuando se va hacia adelante una vez inicializado el mapa o después de cerrar el layer y abrir en el mapa.
        initializeMultiStep();
      } else {
        // En caso de que estemos en la última pantalla, desactivamos el wait for next step.
        const newWaitForNextStep = !isLastScreen(steps, multiStepScreenIndex);
        // Lo mismo para previous, solo le desactivamos si estamos en la primera.
        const newWaitForPreviousStep = !isFirstScreen(multiStepScreenIndex);

        updateWaitForNextAndPreviousStep(newWaitForNextStep, newWaitForPreviousStep);
        updateScreenChangeVariables(multiStepScreenIndex);

        setTimeout(() => {
          setShowPreviousStep(false);
        }, TIME_FOR_TRANSFORM);
      }
    }
    setNextMultiStep(() => nextMapaLeads);
    setPreviousMultiStep(() => previousMapaLeads);
  };

  const initializeMultiStep = () => {
    updateWaitForNextAndPreviousStep(true, false);
    updateScreenChangeVariables(0);
  };

  const updateWaitForNextAndPreviousStep = (waitForNextStep: boolean, waitForPreviousStep: boolean) => {
    if (waitForNextStep !== null) {
      dispatch({ type: 'UPDATE_WAIT_FOR_NEXT_STEP', payload: waitForNextStep });
    }
    if (waitForPreviousStep !== null) {
      setWaitForPreviousStep(waitForPreviousStep);
    }
  };

  const nextMapaLeads = () => {
    const { multiStepScreenIndex } = store.getState().formInfo;

    handleScreenChange();

    // Una vez avanzamos dentro de los multisteps se activa el wait
    // previous step para volver hacia atrás dentro del propio multistep.
    if (!waitForPreviousStep || multiStepScreenIndex === 0) {
      updateWaitForNextAndPreviousStep(null, true);
    }

    setTimeout(() => {

      updateScreenChangeVariables(multiStepScreenIndex + 1);
      // Si estamos en el último paso del multistep se desactiva el
      // wait next step para poder salir del multistep y avanzar hacia la siguiente pantalla.
      if (steps[multiStepScreenIndex + 1].multiStepLastScreen) {
        updateWaitForNextAndPreviousStep(false, null);
        if (multiStepScreenIndex === 0) {
          setShowPreviousStep(false);
        } else {
          setShowPreviousStep(true);
        }
      }
    }, TIME_FOR_TRANSFORM);
  };

  const previousMapaLeads = () => {
    const { multiStepScreenIndex, waitForNextStep } = store.getState().formInfo;

    handleScreenChange();

    // Se desactiva el wait previous step en la segunda pantalla para que
    // en el caso de volver hacia atrás en la primera salte de step y no busque dentro de los multisteps.
    if (multiStepScreenIndex === 1) {
      updateWaitForNextAndPreviousStep(null, false);
    }

    if (!waitForNextStep) {
      updateWaitForNextAndPreviousStep(true, null);
    }

    // Se fija en la pantalla previa a la primera para que cuando se cambie
    // a la primera pantalla el CTA de anterior pase del multistep al step anterior.
    setTimeout(() => {
      updateScreenChangeVariables(multiStepScreenIndex - 1);
    }, TIME_FOR_TRANSFORM);
  };

  const updateScreenChangeVariables = (newMultiStepScreenIndex: number) => {
    setNextMultiStepScreenIndex(newMultiStepScreenIndex);
    dispatch({ type: 'UPDATE_MULTISTEP_SCREENINDEX', payload: newMultiStepScreenIndex });
    setSelectedStep(steps[newMultiStepScreenIndex]);
    setIsFullScreen(steps[newMultiStepScreenIndex].fullScreen);
    setShowPreviousStep(!steps[newMultiStepScreenIndex].hidePreviousStep);
  };

  useEffect(() => {
    dispatch({ type: 'UPDATE_MULTISTEPS', payload: steps });

    handleFirstLoad();

    return () => {
      dispatch({ type: 'UPDATE_FIRST_MAP_LOAD', payload: false });
      setShowPreviousStep(true);
      updateWaitForNextAndPreviousStep(false, false);
    };
  }, []);

  return selectedStep ? (
    <LoadScriptInstance onMapLoad={onMapLoad}>
      <RenderController
        screenType="layer-screen"
        title={selectedStep.title}
        fields={selectedStep.fields}
        key={selectedStep.screenIndex}
      />
    </LoadScriptInstance>
  ) : null;
}
