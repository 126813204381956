import { styled } from '@volkswagen-onehub/components-core';
import React, { ReactElement, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { NewDealerCard } from 'src/feature-app';
import { Points } from 'src/types';
import { useMapGeneralController } from 'src/feature-app/NewMap/MapGeneralController';
import { getDistanceFromLatLngCenter } from 'src/feature-app/NewMap/utils';

const CSSDiv = styled.div`
  /* Slider */
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  /* Slider */
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`;

const BackgroundCard = styled.div`
  background-color: #001e50;
  margin: 0 4px;
`;

interface CarouselCardsProps {
  handleShowCalendarOnClick?: any;
  handleSeleccionarOnClick: any;
  handleShowSlots?: (point: Points) => string | null;
  pointsToList: Points[];
  isCitaPosventa?: boolean;
  replacementCarSelected?: boolean;
  carPickupSelected?: boolean;
}

export const Carousel = (props: CarouselCardsProps) => {
  const {
    clusters,
    handlePointClickAndCarouselMove,
    centerToCalculateDistance,
    handleSelectedPoint,
    handleClusterClick,
    selectedPoint,
  } = useMapGeneralController();
  const { 
    handleSeleccionarOnClick, 
    handleShowCalendarOnClick, 
    handleShowSlots, 
    pointsToList, 
    isCitaPosventa,
    carPickupSelected,
    replacementCarSelected 
  } = props;
  const sliderRef = useRef<Slider>(null);
  const childrenRef = useRef<ReactElement[]>(null);

  useEffect(() => {
    if (pointsToList.length) {
      const firstPoint = selectedPoint ? selectedPoint : pointsToList[0];
      // handleCarouselMovement(firstPoint);
      handleSelectedPoint(firstPoint);
      // handleSelectedCard(firstPoint);
    }
  }, [pointsToList]);

  useEffect(() => {
    // Movimiento del carousel en caso de que se haga click en un Dealer Marker.
    if (selectedPoint) {
      // handleSelectedCard(selectedPoint);
      handleCarouselMovement(selectedPoint);
    }
  }, [selectedPoint, pointsToList]);

  const handleSelectedCard = (point: Points) => {
    if (!point.properties.cluster) {
      const { kvps } = point.properties.dealer;

      const index = pointsToList.findIndex((dealer) => {
        if (dealer.properties.dealer.kvps === kvps) {
          return true;
        }
      });
      sliderRef.current.slickGoTo(index);
    }
  };

  const handleCarouselMovement = (point: Points) => {
    if (point.properties.parent_cluster_id) {
      const parentCluster = clusters.find(
        (cluster) => cluster.properties.cluster && cluster.id === point.properties.parent_cluster_id
      );
      if (parentCluster) {
        // En caso de que el dealer esté dentro de un cluster dentro de otro fijamos el valor de isCluster como true para que llegue hasta el final
        // y no rompa únicamente el primer cluster.
        // handleClusterClick(point, true);
        handleSelectedPoint(point);
      } else {
        handlePointClickAndCarouselMove(point, false);
        handleSelectedCard(point);
      }
    } else {
      handlePointClickAndCarouselMove(point, false);
      handleSelectedCard(point);
    }
  };

  const settings = {
    className: 'center',
    infinite: false,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 1,
    swipe: true,
    speed: 500,
    arrows: false,
    beforeChange: (current: number, next: number) => {
      const point: Points = pointsToList.find((point) => {
        if (!point.properties.cluster) {
          if (point.properties.dealer.kvps === childrenRef.current[next].key) {
            return point;
          }
        }
      });

      handleCarouselMovement(point);
    },
  };
  return (
    <CSSDiv>
      <Slider
        {...settings}
        ref={(el) => {
          sliderRef.current = el;
          if (el && el.props && el.props.children) {
            childrenRef.current = el.props.children.filter((c:any) => c !== null);
          }
        }}
      >
        {pointsToList
          ? pointsToList.map((point: Points, i) => {
            const distanceFromCenter = getDistanceFromLatLngCenter(centerToCalculateDistance.current, point);

            return !point.properties.cluster ? (
              <div key={`${point.properties.dealer.kvps}`} className={`${i}-${point.properties.dealer.name}`}>
                {/* este div es necesario para el funcionamiento de la libreria */}
                <BackgroundCard>
                  <NewDealerCard
                    handleShowSlots={handleShowSlots}
                    point={point}
                    distanceFromCenter={distanceFromCenter}
                    handleShowCalendarOnClick={handleShowCalendarOnClick}
                    handleSeleccionarOnClick={handleSeleccionarOnClick}
                    isCarousel={true}
                    dealerInfo={point.properties.dealer}
                    slots={point.properties.dealer.slots}
                    isCitaPosventa={isCitaPosventa}
                    replacementCarSelected={replacementCarSelected}
                    carPickupSelected={carPickupSelected}
                  />
                </BackgroundCard>
              </div>
            ) : null;
          })
          : null}
      </Slider>
    </CSSDiv>
  );
};
