import React from 'react';
import { 
  Layout,
  Breakpoints,
  CTA,
  Text, 
  Container, 
  ContainerPadding,
  ThemeProvider,
  styled, 
  WhiteSpace,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { useOneFormContext } from 'src/feature-app';

const videoURL = 'https://cdn.volkswagen.es/repository/app/vw-oneform/assets/videos/video_eurocopa.mp4';
const Video = styled.video`
  background: linear-gradient(321.56deg, #00437A 0%, #00376C 52.11%, #001E50 100%);
  width: auto;
  height: 98vh;

  @media all and (min-width: 960px) {
    width: 100vw;
    height: auto;
  }
`;
const HeaderContentPosition = styled.div`
  position: absolute;
  z-index: 1;
  top : 5%;
  @media all and (min-width: 960px) {
    top : 10%;
  }
  @media all and (min-width: 1280px) {
    top : 15%;
  }
`;

export const Portada = () => {
  const trackingManager = useTrackingManager();
  const { nextStep } = useOneFormContext();
  return (
    <div style={{position: 'absolute', top: '0'}}>
      <div style={{overflow: 'hidden', width: '100vw'}}>
        <Video autoPlay muted loop src={videoURL}/>
      </div>
      <HeaderContentPosition className='header-content-position'>
        <ThemeProvider theme='inverted'>
          <Layout
            allowOverflowingContent
            appearance={{
              [Breakpoints.default]: [
                { name: '.', columns: 2 },
                { name: 'a', columns: 20 },
                { name: '.', columns: 2 },
              ],
              [Breakpoints.b960]: [
                { name: '.', columns: 3 },
                { name: 'a', columns: 18 },
                { name: '.', columns: 3 },
              ],
              [Breakpoints.b1280]: [
                { name: '.', columns: 5 },
                { name: 'a', columns: 14 },
                { name: '.', columns: 5 },
              ],
              [Breakpoints.b1920]: [
                { name: '.', columns: 6 },
                { name: 'a', columns: 12 },
                { name: '.', columns: 6 },
              ],
              [Breakpoints.b2560]: [
                { name: '.', columns: 7 },
                { name: 'a', columns: 10 },
                { name: '.', columns: 7 },
              ],
            }}
          >
            <Text appearance={TokenTextAppearance.headline400}>
              Hay tantos tipos de jugadores{' '}<Text bold>como de conductores.</Text>{' '}<Text bold whiteSpace={WhiteSpace.nowrap} >¿Cuál eres tú?</Text> 
            </Text>
            <Container padding={{top: ContainerPadding.dynamic0100}}>
              <Text appearance={TokenTextAppearance.copy200} >
                Nos encantaría que participaras en este sorteo exclusivo para clientes ID. Compártenos tu experiencia.
              </Text>
            </Container>
            <Container 
              wrap={'always'} 
              padding={{ top: ContainerPadding.static500}}
            >
              <CTA
                tag="button"
                emphasis="primary"
                onClick={(e) => {
                  trackingManager.trackFormStepButtonClick({
                    contentId: 'Siguiente',
                  });
                  e.preventDefault();
                  nextStep();
                }}
                ariaLabel="aria label"
              >
                Comenzar
              </CTA>
            </Container>
          </Layout>
        </ThemeProvider>
      </HeaderContentPosition>
    </div>
  );
};
