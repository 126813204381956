import { BBox } from 'geojson';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { DealersData, Geometry, Installation, isDealerData, OneFormState, SelectedTab } from 'src/types';
import { Points } from 'src/types';
import { isGooglePlace, Suggestion } from 'src/feature-app/NewMap';
import { getGeometry, getPoints, getUserGeolocation, getUserSuggestion } from '../redux/selectors';
import { generateClusters } from 'src/feature-app/NewMap/generate-clusters';
import { getLocationOfDealerWithoutLoadInstance } from 'src/feature-app/NewMap/utils';

export const MapGeneralControllerContext = React.createContext<MapGeneralControllerMethods>(null);
export const useMapGeneralController = (): MapGeneralControllerMethods =>
  React.useContext(MapGeneralControllerContext) as MapGeneralControllerMethods;

interface MapGeneralControllerMethods {
  cardsDistanceRef: React.MutableRefObject<HTMLElement[]>;
  cardsTimeRef: React.MutableRefObject<HTMLElement[]>;
  centerInGeoLocatedCenter: () => void;
  centerMap: (newCenter: google.maps.LatLng) => void;
  centerToCalculateDistance: React.MutableRefObject<google.maps.LatLng>;
  clusters: Points[];
  geoLocatedCenterRef: React.MutableRefObject<google.maps.LatLng>;
  getNewClusters: () => void;
  handleApiLoaded: (map: google.maps.Map, maps: any) => void;
  handleChangeInMapZoom: (zoomIn: boolean) => void;
  handleClusterClick: (point: Points, isCluster?: boolean, recalculateClusters?: boolean) => void;
  handleHoverPoint: (p: Points) => void;
  handleMapMovement: () => void;
  handlePointClickAndCarouselMove: (p: Points, s?: boolean, preSelectedOrderValue?: SelectedTab) => void;
  handleSelectedPoint: (p: Points) => void;
  hoverPoint: Points;
  resetMap: () => void;
  mapHasMoved: boolean;
  mapMaxZoom: number;
  markerDimensions: MarkerDimensions;
  searchIsDealer: boolean;
  searchMoreDealers: () => void;
  moreDealersSearched: boolean;
  setMoreDealersSearched: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPoint: Points;
  setSearchIsDealer: React.Dispatch<React.SetStateAction<boolean>>;
  superClusterRef: any;
  userIsGeoLocated: boolean;
  centerRef: React.MutableRefObject<google.maps.LatLng>;
}
export interface MarkerDimensions {
  width: number;
  height: number;
}

const markerDimensions: MarkerDimensions = { width: 32, height: 44 };
const mapMaxZoom = 16;

const MapGeneralController = React.memo(
  (props: {
    children: JSX.Element;
    points: Points[];
    geometry: Geometry;
    userSuggestion: Suggestion;
    userGeolocation: Suggestion;
    dealer?: DealersData;
    skipMapMovementOnClick?: boolean;
  }) => {
    const { points, geometry, children, userSuggestion, userGeolocation, dealer, skipMapMovementOnClick } = props;
    // const { debug } = useOneFormContext(); // Causa de los rerenders
    const boundsRef = useRef<BBox>(null);
    const zoomRef = useRef(null);
    const centerRef = useRef<google.maps.LatLng>(null);
    const geoLocatedCenterRef = useRef<google.maps.LatLng>(null);
    const centerToCalculateDistance = useRef<google.maps.LatLng>(null);
    const mapRef = useRef<google.maps.Map>(null);
    const mapsRef = useRef(null);
    const [selectedPoint, setSelectedPoint] = useState<Points>(null);
    const [hoverPoint, setHoverPoint] = useState<Points>(null);
    const [clusters, setClusters] = useState<Points[]>([]);
    const superClusterRef = useRef(null);
    const cardsTimeRef = useRef<HTMLElement[]>([]);
    const cardsDistanceRef = useRef<HTMLElement[]>([]);
    const [mapHasMoved, setMapHasMoved] = useState(false);
    const [searchIsDealer, setSearchIsDealer] = useState<boolean>(null);
    const [userIsGeoLocated, setUserIsGeoLocated] = useState<boolean>(null);
    const zoomListenerRef = useRef(null);
    const [moreDealersSearched, setMoreDealersSearched] = useState<boolean>(null);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      if (!userSuggestion) return;
      setMapHasMoved(false);
      if (isGooglePlace(userSuggestion)) {
        setSearchIsDealer(false);
      } else {
        setSearchIsDealer(true);

        // Hay que preseleccionar el dealer elegido
        if (points) {
          const selectedCluster = points.find((cluster) => {            
            if (cluster.properties.dealer) {
              return (
                (!isGooglePlace(userSuggestion) && isDealerData(userSuggestion) &&
                  cluster.properties.dealer.kvps === userSuggestion.kvps)
                ||
                (!isGooglePlace(userSuggestion) && !isDealerData(userSuggestion) &&
                  cluster.properties.dealer.kvps === (userSuggestion as Installation).KVPSCode__c)
              );
            } else {
              return false;
            }
          });
          selectedCluster && handleSelectedPoint(selectedCluster);
        }

      }
      if (mapRef.current && mapsRef.current) {
        if (isGooglePlace(userSuggestion)) {
          initializeMapWithBounds(geometry.viewport);
        } else {
          if (geometry) {
            initalizeMapWithCenter(geometry.lat, geometry.lng);
          }
        }
      }
    }, [userSuggestion, points]);

    useEffect(() => {
      setMounted(true);
      return () => {
        mapRef.current = null;
        mapsRef.current = null;
        setMounted(false);
      };
    }, []);

    useEffect(() => {
      if (userGeolocation) {
        setUserIsGeoLocated(true);
      } else {
        setUserIsGeoLocated(false);
        geoLocatedCenterRef.current = null;
      }
    }, [userGeolocation]);

    const resetMap = () => {
      mapRef.current = null;
      mapsRef.current = null;
    };

    const handleApiLoaded = (map: google.maps.Map, maps: any) => {
      // En el caso de que se haya interactuado con el mapa y se oculte por alguna razón (calendario, filtros, etc),
      // se vuelve a crear una nueva instancia del mismo, perdiendo cualquier interacción creandose de nuevo el mapa
      // con la información inicial.
      if (!mapRef.current && !mapsRef.current) {
        mapRef.current = map;
        mapsRef.current = maps;

        window.map = map;
        window.maps = maps;
        setMapHasMoved(false);
        if (geometry && geometry.bounds) {
          // Caso de geolocalización o búsqueda de google
          initializeMapWithBounds(geometry.bounds);
        } else if (geometry && geometry.viewport) {
          // Caso de geolocalización o búsqueda de google
          initializeMapWithBounds(geometry.viewport);
        } else if (geometry && geometry.lat && geometry.lng) {
          // Caso dealer
          initalizeMapWithCenter(geometry.lat, geometry.lng);
        } else {
          // Caso Cita posventa Dealer. En este caso al no haber precargado el mapa anteriormente en el autosuggest no están las variables
          // de maps disponibles, por lo que se tienen que crear con el mapa que sea acaba de crear y sin usar LoadInstance.
          const { lat, lng } = getLocationOfDealerWithoutLoadInstance(dealer, maps);
          initalizeMapWithCenter(lat, lng);
        }
        setZoomListener(map, maps);
      } else {
        removeZoomListener(mapsRef.current);
        // Lógica para recrear el mapa antes de su ocultación.
        map.setCenter(centerRef.current);
        map.fitBounds(transformClusterBoundsToGoogleBounds(boundsRef.current));
        map.setZoom(zoomRef.current);

        mapRef.current = map;
        mapsRef.current = maps;
        setZoomListener(map, maps);
      }
    };

    const initializeMapWithBounds = (bounds: google.maps.LatLngBounds) => {
      handleSelectedPoint(null);
      // No debería ser necesario crear las siguientes variables, ya que con center y bounds funciona la primera carga.
      // Por alguna razón, después de seleccionar una cita y volver atrás da este error:
      // InvalidValueError: setCenter: not a LatLng or LatLngLiteral with finite coordinates: in property lat: not a number.
      // a pesar de que bounds es el mismo que en la primera carga y lat si que es un número.
      // Al calcular cada variable por separado no se produce este error.

      const { north, east, south, west } = getCardinalPoints(bounds);
      const sw = createLatLng(south, west);
      const ne = createLatLng(north, east);
      const newBounds = new mapsRef.current.LatLngBounds(sw, ne);
      const center = newBounds.getCenter();
      const lat = center.lat();
      const lng = center.lng();
      const latlng = createLatLng(lat, lng);

      mapRef.current.setCenter(latlng);
      mapRef.current.fitBounds(newBounds, 0);
      const clusterBounds: BBox = transformGoogleBoundsToClusterBounds(newBounds);
      if (isGooglePlace(userSuggestion) && userSuggestion.isGeoLocated) {
        geoLocatedCenterRef.current = center;
      }
      if (!geoLocatedCenterRef.current) {
        centerToCalculateDistance.current = center;
      } else {
        centerToCalculateDistance.current = geoLocatedCenterRef.current;
      }
      setTimeout(() => {
        mapRef.current.panTo(center);
      }, 0);
      setMapClusters(points, mapRef.current.getZoom(), clusterBounds, center);
    };

    const initalizeMapWithCenter = (lat: number, lng: number) => {
      const zoom = 16;
      const latlng = createLatLng(lat, lng);
      mapRef.current.setCenter(latlng);
      mapRef.current.setZoom(zoom);
      const bounds = getBounds();
      const clusterBounds = transformGoogleBoundsToClusterBounds(bounds);
      centerToCalculateDistance.current = latlng;
      setMapClusters(points, zoom, clusterBounds, latlng);
    };

    const setZoomListener = (map: google.maps.Map, maps: any) => {
      if (maps) {
        const listener = maps.event.addListener(map, 'zoom_changed', () => {
          const zoom = map.getZoom();
          // setMapClusters(points, zoom, boundsRef.current, centerRef.current);
          zoomRef.current = zoom;
        });
        zoomListenerRef.current = listener;
      }
    };

    const removeZoomListener = (maps: any) => {
      maps.event.removeListener(zoomListenerRef.current);
    };

    const setMapClusters = (
      points: Points[],
      zoom: number,
      bounds: BBox,
      center: google.maps.LatLng,
      param?: boolean
    ) => {
      // Solución al bug Uncaught RangeError: Maximum call stack size exceeded.

      if (zoom < 0) {
        return;
      }
      const { clusters, supercluster }: { clusters: Points[]; supercluster: any } = generateClusters(
        points,
        zoom,
        bounds
      );
      superClusterRef.current = supercluster;

      // En caso de que no encuentre dealers en la ubicación seleccionada en una búsqueda de google se hace zoom out
      // hasta que encuentre al menos 2. En caso de que sea una búsqueda de dealer, buscamos hasta encontrar 1.
      // Usamos isGooglePlace y no el flag the searchIsDealer porque en la primera carga no está disponible.
      let pointCount = getPointCount(clusters);

      if (pointCount > 1 && !isGooglePlace(userSuggestion)) {
      }

      if ((pointCount < 2 && isGooglePlace(userSuggestion)) || (pointCount === 0 && !isGooglePlace(userSuggestion))) {
        const { center, clusterBounds } = zoomOutMapForSearch(zoom);
        setMapClusters(points, zoom - 1, clusterBounds, center);
        return;
      }

      changeMapVariables(bounds, zoom, center);
      drawRectangle();

      if (userSuggestion && (!('isGooglePlace' in userSuggestion) || !userSuggestion.isGooglePlace) && searchIsDealer && !param) {
        const point = clusters.find((point) => {
          if (isDealerData(userSuggestion)) {
            return point.properties.dealer.kvps === (userSuggestion as DealersData).kvps;
          } else {
            return point.properties.dealer.kvps === (userSuggestion as Installation).KVPSCode__c;
          }
        });
        setClusters([point]);
      } else {
        setClusters(clusters);
      }
    };

    const getNewClusters = () => {
      resetCardReferences();
      const bounds = getBounds();
      const center = centerToCalculateDistance.current;
      const zoom = mapRef.current.getZoom();
      const clusterBounds = transformGoogleBoundsToClusterBounds(bounds);
      setSearchIsDealer(false);
      setMapClusters(points, zoom, clusterBounds, center, true);
      setMoreDealersSearched(true);
      setMapHasMoved(false);
    };

    /**
     * Función que hace zoom out, útil para realizar búsquedas de más talleres en caso de que los resultados no sean los deseados.
     * @param zoom
     */
    const zoomOutMapForSearch = (zoom: number) => {
      mapRef.current.setZoom(zoom);
      const bounds = mapRef.current.getBounds();
      const center = bounds.getCenter();
      const clusterBounds = transformGoogleBoundsToClusterBounds(bounds);
      return { bounds, center, clusterBounds };
    };

    /**
     * Devuelve el número de puntos que se encuentran dentro de los clusters.
     * @param clusters
     */
    const getPointCount = (clusters: Points[]): number => {
      return clusters.reduce((acc, cluster) => {
        if (cluster.properties.cluster) {
          return acc + cluster.properties.point_count;
        } else {
          return acc + 1;
        }
      }, 0);
    };

    /**
     * CTA Ver más talleres en la zona. Se hace un zoom out hasta que se encuentra mínimo un taller más
     */
    const searchMoreDealers = () => {
      setSearchIsDealer(false);
      setMapHasMoved(true);
      const { center, clusterBounds } = zoomOutMapForSearch(zoomRef.current);

      const { clusters } = generateClusters(points, zoomRef.current, clusterBounds);
      setMapClusters(points, zoomRef.current, clusterBounds, center, true);
      setMoreDealersSearched(true);
      let pointCount = getPointCount(clusters);

      if (pointCount === 1) {
        searchMoreDealers();
      }
    };

    /**
     * Las bounds de google maps van en referencia a SW y NE. No obstante las bounds necesarias para obtener los clusters van en referencia
     * NW y SE por lo que hay que obenter la equivalencia para que funcione correctamente.
     * @param googleBounds
     */
    const transformGoogleBoundsToClusterBounds = (googleBounds: google.maps.LatLngBounds): BBox => {
      const { north, east, south, west } = getCardinalPoints(googleBounds);

      return [west, south, east, north];
    };

    const transformClusterBoundsToGoogleBounds = (bounds: BBox): google.maps.LatLngBounds => {
      const sw = createLatLng(bounds[1], bounds[0]);
      const ne = createLatLng(bounds[3], bounds[2]);
      return new mapsRef.current.LatLngBounds(sw, ne);
      // const { north, east, south, west } = getCardinalPoints(bounds);

      // return [west, south, east, north];
    };

    const getCardinalPoints = (bounds: google.maps.LatLngBounds) => {
      const north = bounds.getNorthEast().lat();
      const east = bounds.getNorthEast().lng();
      const south = bounds.getSouthWest().lat();
      const west = bounds.getSouthWest().lng();

      return { north, east, south, west };
    };

    const getBounds = (): google.maps.LatLngBounds => mapRef.current.getBounds();

    /**
     * Transforma las coordenadas del point en coordenadas LatLng de Google Maps.
     * @param point
     */
    const getLatLngFromPoint = (point: Points): google.maps.LatLng =>
      createLatLng(point.geometry.coordinates[1], point.geometry.coordinates[0]);

    const createLatLng = (lat: number, lng: number): google.maps.LatLng => new mapsRef.current.LatLng(lat, lng);

    const changeMapVariables = (newBounds: BBox, zoom: number, center: google.maps.LatLng) => {
      // setBounds(newBounds);
      // mapRef.current.setZoom(zoom);
      boundsRef.current = newBounds;
      zoomRef.current = zoom;
      centerRef.current = center;
      if (isGooglePlace(userSuggestion) && !userGeolocation) {
        centerToCalculateDistance.current = center;
      }
    };

    const handleChangeInMapZoom = (zoomIn: boolean) => {
      const zoom = mapRef.current.getZoom();
      const newZoom = zoomIn ? zoom + 1 : zoom - 1;
      mapRef.current.setZoom(newZoom);

      handleMapMovement();
    };

    const handlePointClickAndCarouselMove = useCallback(
      (point: Points, scroll?: boolean, preSelectedOrderValue?: SelectedTab) => {
        handleSelectedPoint(point);

        if (scroll) {
          const cards = preSelectedOrderValue === 'horario' ? cardsTimeRef : cardsDistanceRef;
          const card = cards.current.find((el) => el.className.includes(point.properties.dealer.kvps));

          if (card) {
            // Añadir restriccion a solo desktop
            card.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }
        }
        if (!skipMapMovementOnClick && checkIfPointIsInsideBounds(point)) {
          moveMapIfIsSlightlyOutBounds(point);
        } else {
          mapRef.current.panTo(getLatLngFromPoint(point));
        }
      },
      []
    );

    const handleSelectedPoint = useCallback((point: Points | null) => {
      setSelectedPoint(point);
    }, []);

    const handleHoverPoint = useCallback((point: Points | null) => {
      setHoverPoint(point);
    }, []);

    /**
     * Al clickar sobre un cluster se han de separar los dealers que contenga y hacer un zoom sobre la zona
     * sin perder los pines de la búsqueda anterior.
     * @param point
     * @param isCluster Este parámetro solo se usa cuando se clicka sobre una card para hacer el máximo zoom posible, ya
     * que si el pin se encuentra dentro de dos clusters no llega a mostrarlo correctamente con el expansion zoom.
     * @param recalculateClusters En mobile no funciona correctamente el recalculo de clusters ya que el mapa no está montado
     * y no se dispara el evento de zoom_changed por lo que necesitamos recalcular los clusters a mano para que al volver al mapa
     * estén los clusters correspondientes al nuevo zoom.
     */
    const handleClusterClick = (point: Points, isCluster?: boolean, recalculateClusters?: boolean) => {
      // breakCluster(clusterId);
      const parentCluster = clusters.find(
        (cluster) =>
          cluster.properties.cluster && (cluster.id === point.properties.parent_cluster_id || cluster.id === point.id)
      );
      if (parentCluster) {
        handleSelectedPoint(point);

        // Cálculo del zoom mínimo de exapansión + 1 para que se vean correctamente los nuevos pines en el mapa.
        const expansionZoom =
          isCluster && parentCluster.properties.point_count > 2
            ? mapMaxZoom - 3
            : Math.min(superClusterRef.current.getClusterExpansionZoom(parentCluster.id), mapMaxZoom);
        const center = createLatLng(point.geometry.coordinates[1], point.geometry.coordinates[0]);

        mapRef.current.setCenter(center);
        mapRef.current.setZoom(expansionZoom);
        zoomRef.current = expansionZoom;

        if (recalculateClusters || recalculateClusters === undefined) {
          setMapClusters(points, expansionZoom, boundsRef.current, center);
        }
      } else {
        handlePointClickAndCarouselMove(point, false);
      }
    };

    const resetCardReferences = () => {
      cardsDistanceRef.current = [];
      cardsTimeRef.current = [];
    };

    /**
     * Comprueba si el point se encuentra dentro o fuera de los límites para centrar el mapa en caso de que esté fuera.
     * @param point
     */
    const checkIfPointIsInsideBounds = (point: Points): boolean => {
      const bounds = getBounds();
      if (bounds.contains(getLatLngFromPoint(point))) {
        return true;
      } else {
        return false;
      }
    };

    /**
     * Si el pin se encuentra visualmente fuera de los límites del mapa hay que moverlo para que aparezca entero.
     */
    const moveMapIfIsSlightlyOutBounds = (point: Points) => {
      const lat = point.geometry.coordinates[1];
      const lng = point.geometry.coordinates[0];
      const { north, east, south, west } = getCardinalPoints(getBounds());
      const pointLatLng = getLatLngFromPoint(point);
      const northPoint = createLatLng(north, lng);
      const eastPoint = createLatLng(lat, east);
      const southPoint = createLatLng(south, lng);
      const westPoint = createLatLng(lat, west);

      const northDistance = distanceInPx(pointLatLng, northPoint);
      const eastDistance = distanceInPx(pointLatLng, eastPoint);
      const southDistance = distanceInPx(pointLatLng, southPoint);
      const westDistance = distanceInPx(pointLatLng, westPoint);

      let y = calculateY(northDistance, southDistance);
      let x = null;

      if (eastDistance < markerDimensions.width) {
        x = calculateEastDistance(eastDistance, southDistance);
      }
      if (westDistance < markerDimensions.width) {
        x = -markerDimensions.width;
      }
      if (x || y) {
        mapRef.current.panBy(x, y);
      }
    };

    const calculateY = (northDistance: number, southDistance: number) => {
      const south = calculateSouthDistance(southDistance);
      const north = -calculateNorthDistance(northDistance);

      if (south) {
        return south;
      }
      return north;
    };

    /**
     * Este cálculo se realiza por separado ya que en mobile está el carousel por encima y requiere más lógica.
     * @param southDistance
     */
    const calculateSouthDistance = (southDistance: number): number | null => {
      if (window.innerWidth < 960) {
        if (southDistance < markerDimensions.height + 220) {
          return markerDimensions.height + 220;
        }
      } else {
        if (southDistance < markerDimensions.height) {
          return markerDimensions.height;
        }
      }
      return 0;
    };

    const calculateNorthDistance = (northDistance: number): number | null => {
      if (window.innerWidth < 960) {
        if (northDistance < markerDimensions.height + 100) {
          return markerDimensions.height + 100;
        }
      } else {
        if (northDistance < markerDimensions.height + 56) {
          return markerDimensions.height + 56;
        } else {
          return 0;
        }
      }
      return 0;
    };

    const calculateEastDistance = (eastDistance: number, southDistance: number): number | null => {
      if (window.innerWidth < 959) {
        if (eastDistance < markerDimensions.width + 69 && southDistance < markerDimensions.height + 197) {
          return markerDimensions.width + 69;
        } else {
          return markerDimensions.height;
        }
      } else {
        return markerDimensions.width;
      }
    };
    /**
     * Devuelve la distancia en píxels entre dos puntos del mapa.
     *
     * @param position1
     * @param position2
     */
    const distanceInPx = (position1: google.maps.LatLng, position2: google.maps.LatLng) => {
      var p1 = mapRef.current.getProjection().fromLatLngToPoint(position1);
      var p2 = mapRef.current.getProjection().fromLatLngToPoint(position2);

      var pixelSize = Math.pow(2, -mapRef.current.getZoom());

      var d = Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / pixelSize;

      return d;
    };

    /**
     * Dibuja las bounds en el mapa.
     * @param bounds
     */
    const drawRectangle = () => {
      new mapsRef.current.Rectangle({
        strokeColor: '#FF0000',
        strokeOpacity: 0, //debug ? 0.8 : 0,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0, //debug ? 0.35 : 0,
        map: mapRef.current,
        bounds: getBounds(),
      });
    };

    const centerInGeoLocatedCenter = useCallback(() => {
      mapRef.current.setCenter(geoLocatedCenterRef.current);
    }, []);

    const centerMap = useCallback((newCenter: google.maps.LatLng) => {
      mapRef.current.setCenter(newCenter);
    }, []);

    const handleMapMovement = () => {
      if (!mapHasMoved) {
        setMapHasMoved(true);
      }
    };

    return (
      <MapGeneralControllerContext.Provider
        value={{
          cardsDistanceRef,
          cardsTimeRef,
          centerInGeoLocatedCenter,
          centerMap,
          centerToCalculateDistance,
          centerRef,
          clusters,
          geoLocatedCenterRef,
          getNewClusters,
          handleApiLoaded,
          handleChangeInMapZoom,
          handleClusterClick,
          handleHoverPoint,
          handleMapMovement,
          handlePointClickAndCarouselMove,
          handleSelectedPoint,
          hoverPoint,
          resetMap,
          mapHasMoved,
          mapMaxZoom,
          markerDimensions,
          searchIsDealer,
          searchMoreDealers,
          setMoreDealersSearched,
          moreDealersSearched,
          selectedPoint,
          setSearchIsDealer,
          superClusterRef,
          userIsGeoLocated,
        }}
      >
        {children}
      </MapGeneralControllerContext.Provider>
    );
  }
);

const mapStateToProps = (state: OneFormState) => {
  return {
    points: getPoints(state),
    geometry: getGeometry(state),
    userSuggestion: getUserSuggestion(state),
    userGeolocation: getUserGeolocation(state),
  };
};

const ConnectedComponent = connect(mapStateToProps)(MapGeneralController);

export { ConnectedComponent as MapGeneralController };
