import React, { useEffect } from 'react';
import { getDealerKvps } from 'src/bff';
import { TriggerProps, useFaContent } from 'src/feature-app';
import { ProspectsPosventaForm, transformMillage } from 'src/forms';
import { FormDataFields } from 'src/types';

export function ProspectPosventaEmbedded({ setSelectedJourney }: TriggerProps) {
  const faContent = useFaContent();
  if (faContent.featureApp === 'es-calculadora') {
    return <ProspectPosventaCalculadora setSelectedJourney={setSelectedJourney} />;
  }
  return null;
}

export function ProspectPosventaCalculadora({ setSelectedJourney }: TriggerProps) {
  const faContent = useFaContent();
  const renderForm = (fields: FormDataFields) => {
    setSelectedJourney(
      <ProspectsPosventaForm
        updatedFormData={{
          fields,
        }}
      />,
      true
    );
  };
  useEffect(() => {
    const fields = {
      motivo: 'MANTENIMIENTO',
      formName: 'prospect-posventa-calculadora',
      matricula: faContent?.vehicle[0]?.license,
      kilometros: transformMillage(faContent?.vehicle[0]?.localKM),
      anoMatriculacion: faContent?.vehicle[0]?.licenseDate,
      modelo: faContent?.vehicle[0]?.familyDesc,
      budget: { pvp: faContent?.quotePvp, operations: [...faContent?.quoteOperations] },
      deepLink: faContent?.deepLink,
    };
    const kvps = faContent?.dealer;

    getDealerKvps(kvps)
      .then((dealer) => {
        renderForm({ ...fields, dealer });
      })
      .catch((err) => {
        console.error(err);
        renderForm({ ...fields, dealer: {} }); // Hay que ver que hacer en estos casos.
      });
  }, []);

  return <></>;
}
