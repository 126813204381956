import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import {
  FormController,
  useOneFormContext,
  formatPrefix,
  removeWhiteSpace
} from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { getCommonFormData } from '../FormData';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { ThankYouOneShop, SACOneShopFields } from '.';
import { formDataBuilder, formatHeaders } from '../../format-fields';
import { IdPerson } from 'src/types';
import { DpsData } from 'src/feature-service/es-oneform-core-feature-service/types';



let NuevoFormData: Partial<FormDataGroup> = {
  headers: {
    DESC_BRAND: 'VW',
    DESC_GDPR_ADAPTED: 'true',
    DESC_TOKEN: 'VW_DDB_FRM_SAC',
    DESC_FORMOBJECTIVE: 'Atención al cliente',
    DESC_ID_CAMPAIGN: '7011n000000AglrAAC',
    COD_FORM_ID: 'vw-oneform-sac-oneshop',
    DESC_FORM_DETAIL: 'SAC OneShop',
    DESC_CAMPAIGNBLOCK: '18 - VW Customer Care',
  },
  fields: {
    formName: 'sac-oneshop',
    formType: 'otherform',
    pageCategory: 'SAC OneShop',
  }, // Analítica
};

interface SACOneShopProps {
  idPerson?: IdPerson;
  dpsData?: DpsData;
}

export const SACOneShopForm = (props: SACOneShopProps) => {
  const { initializeForm } = useOneFormContext();
  const { idPerson } = props;
  const store = useStore();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);


  const steps: Steps[] = [
    {
      title: <></>, // El title se renderiza en los fields al ser un form de un solo step con un diseño que incluye un stage.
      fields: <OneElementTemplateFullScreen element={<SACOneShopFields idPerson={idPerson} />} />,
      screenIndex: 0,
      name: 'Form',
    },
  ];

  useEffect(() => {
    if (idPerson) {
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'cellphone': idPerson.NUM_Phone_mobile__c } });
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'name': idPerson.FirstName } });
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'surname': idPerson.LastName } });
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'email': idPerson.TXT_Email__c } });
    }
  }, [idPerson]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const info = urlParams.get('q');
    if (info) {
      const decodedParms = atob(info);
      const splittedParams = decodedParms.split(';');
      const [DESC_ID_CLIMAPRO] = splittedParams;

      NuevoFormData = {
        headers: { ...NuevoFormData.headers, DESC_ID_CLIMAPRO },
        fields: { ...NuevoFormData.fields },
      };
    }
    setLoading(false);
  }, []);

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();

    const { fields, headers } = formData;

    return new Promise<any>(async (resolve, reject) => {

      const data = {
        'headers[FORM_DETAILS]': 'SAC OneShop',
        ...getCommonFormData(fields),
      };

      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData);

      if (response && response.data && response.data.content && response.data?.status?.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      formTheme: 'main',
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      notALayer: true,
      formType: 'inPage',
      alingItemsCenter: true,
      thankYouComponent: <ThankYouOneShop />,
    };
    initializeForm(NuevoFormInfo, NuevoFormData);
  }, [loading]);

  return !steps ? null : <FormController steps={steps} screenType="full-screen" />;
};
