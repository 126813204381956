import React from 'react';
import { JourneyLeadsController } from './';
import { MapGeneralController } from 'src/feature-app';

/**
 * Este componente está creado únicamente para que JourneyLeadsController tenga acceso a las variables de MapGeneralController.
 */
export const JourneyLeadsWrapper = () => {
  return (
    <MapGeneralController>
      <JourneyLeadsController />
    </MapGeneralController>
  );
};
