import {
    Breakpoints,
    Container,
    ContainerGutter,
    CTA,
    Layout,
    LayoutRowGap,
    styled,
    Text,
    TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { TriggerProps } from 'src/feature-app';
import { ProspectsVentaForm } from '../forms/Prospects/ProspectsVentaForm';
import { useDispatch } from 'react-redux';

const taigoImage = 'https://cdn.volkswagen.es/repository/app/vw-oneform/assets/images/taigo.png';

const ImageWrapper = styled.div`
  img {
    width: 100%;
  }
`;

export function ProspectsTrigger(props: TriggerProps) {
  const { setSelectedJourney } = props;

  const dispatch = useDispatch();

    return (
        
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
            { name: '.', columns: 2 },
            { name: 'b', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b560]: [
            { name: '.', columns: 6 },
            { name: 'a', columns: 12 },
            { name: '.', columns: 6 },
            { name: '.', columns: 6 },
            { name: 'b', columns: 12 },
            { name: '.', columns: 6 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 6 },
            { name: '.', columns: 2 },
            { name: 'b', columns: 12 },
            { name: '.', columns: 2 },
          ]
        }}
        rowGap={LayoutRowGap.dynamic0250}
      >
        <ImageWrapper>
          <img src={taigoImage} alt='Nuevo Taigo'/>
        </ImageWrapper>
        <Container wrap={'always'} gutter={ContainerGutter.static300}>
          <Text appearance={TokenTextAppearance.copy200} bold>
            Nuevo Taigo
          </Text>
          <Text appearance={TokenTextAppearance.headline300}>
            <Text bold>Carácter{' '}</Text>todos los días
          </Text>
          <Text appearance={TokenTextAppearance.copy200}>
            El Nuevo Taigo es para aquellos que marcan sus propias tendencias. Que no solo tienen estilo, sino también
            carácter. Y que cada día encuentran lo extraordinario en lo cotidiano. ¿Será también para ti?
          </Text>
          <CTA
            tag="button"
            onClick={(e) => {
              dispatch({ type: 'RESET_STATE' });
              e.preventDefault();
              setSelectedJourney(<ProspectsVentaForm />);
            }}
            emphasis='primary'
          >
            Pide más información
          </CTA>
        </Container>
      </Layout>
    );
}

