import { Text } from '@volkswagen-onehub/components-core';
import Axios, { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { getMolecularUrl } from 'src/bff';
import { Email, PantallaNombreApellido } from 'src/components';
import { FormController, removeWhiteSpace, useFaContent, useOneFormContext } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { OneElementTemplate } from 'src/feature-app/Screen';
import { isCalculadoraForm } from 'src/forms';
import { FormDataGroup, FormInfo, MolecularResponse, OneFormState, Steps } from 'src/types';
import { formatFields, formatHeaders, formDataBuilder } from '../format-fields';
import { getCalculadoraData, getCodInterestCarline, getDescInterestCarline, getMatchData } from '../Leads';
import { ThankYouProspects } from './';
import { ConfirmacionProspects } from './ConfirmacionProspects';

export enum ProspectsPosventaSteps {
  Email,
  NombreApellido,
  Confirmacion,
}

interface ProspectsFormProps {
  customSteps?: Steps[];
  updatedFormData?: Partial<FormDataGroup>;
  updatedFormInfo?: Partial<FormInfo>;
}

export function ProspectsPosventaForm({ updatedFormData }: ProspectsFormProps) {
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const dispatch = useDispatch();
  const faContent = useFaContent();

  const steps: Steps[] = [
    {
      title: (
        <>
          <Text bold>¿A qué email </Text>te enviamos este presupuesto de mantenimiento?
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <div style={{ marginBottom: '2px' }}>
              <Email paginaConfirmacion={false} required={true} />
            </div>
          }
        />
      ),
      screenIndex: ProspectsPosventaSteps.Email,
      name: 'Email',
      outputs: ['email'],
      outputsText: ['email'],
    },
    {
      title: (
        <>
          Genial. Aunque antes de mandártelo, nos gustaría saber <Text bold>quién eres</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <div style={{ marginBottom: '2px' }}>
              <PantallaNombreApellido />
            </div>
          }
        />
      ),
      screenIndex: ProspectsPosventaSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      fields: <ConfirmacionProspects />,
      screenIndex: ProspectsPosventaSteps.Confirmacion,
      name: 'Confirmacion',
      isLastStep: true,
      hidePreviousStep: true,
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_GDPR_ADAPTED: 'true',
      DESC_TOKEN: 'VW_DDB_FRM_PROSPECT_POSVENTA',
      DESC_CAMPAIGNBLOCK: '13. VW Posventa Inbound',
      DESC_FORMOBJECTIVE: 'Presupuesto Posventa',
      DESC_ID_CAMPAIGN: '7011n000000JwcWAAS',
      ...updatedFormData.headers,
    },
    fields: {
      formName: 'prospect-posventa',
      formType: 'otherform',
      pageCategory: 'Prospect',
      formFields: {
        DESC_LOPD: 'PERMITE',
        DESC_TRANF_DATA_THIRD: 'NO INFORMADO',
      },
      ...updatedFormData?.fields,
    }, // Analítica
  };

  const getFormInfo = (steps: Steps[]): Partial<FormInfo> => {
    const firstStep = steps[0];
    const lastStep = steps[steps.length - 1];
    const stepsHistory = [0];
    const numberOfScreens = steps.length;

    return {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      formTheme: 'inverted',
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps,
      stepsHistory,
      thankYouComponent: <ThankYouProspects />,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();

    const { fields, headers } = formData;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, url, device } = getParamsForm();

      const data = {
        ...formatHeaders(headers),
        'headers[DESC_VN_VO]': 'VN',
        'headers[DESC_DEVICETYPE]': device,
        'headers[DESC_URLORIGIN]': url,
        'headers[FECHA_INSERTION_DATE]': today,
        'headers[TC]': tc,
        'headers[COD_FORM_ID]': `vw-oneform-${fields.formName}`,

        'fields[DESC_NAME]': fields.name,
        'fields[DESC_SURNAME_1]': fields.surname,
        'fields[DESC_SURNAME_2]': fields.secondSurname,
        'fields[DESC_EMAIL_ADDRESS]': removeWhiteSpace(fields.email),
        'fields[DESC_FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
        'fields[COD_INTEREST_CARLINE]': getCodInterestCarline(dealersInfo, fields.carlineCode),
        'fields[DESC_INTEREST_CARLINE]': getDescInterestCarline(dealersInfo, fields.modelo),
        ...formatFields(fields.formFields),

        'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
        ...getCalculadoraData(formData),
      };

      if (isCalculadoraForm(fields.formName)) {
        delete data['fields[COD_INTEREST_CARLINE]'];
        delete data['fields[DESC_INTEREST_CARLINE]'];
        delete data['headers[DESC_VN_VO]'];
      }

      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formDataInfo = formDataBuilder(dataParsed);

      const MOLECULAR_URL = getMolecularUrl(); // Legacy

      const response: AxiosResponse<MolecularResponse> = await Axios.post(
        `${MOLECULAR_URL}/request-dps-send.php`,
        formDataInfo
      );

      if (response && response.data && response.data.content && response.data.content.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const formInfo = getFormInfo(steps);

    initializeForm(formInfo, NuevoFormData);
  }, []);

  return <FormController steps={steps} screenType="layer-screen" />;
}
