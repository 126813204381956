import React from 'react';
import { EmbeddedFeatureApp, TriggerProps, useFaContent } from 'src/feature-app';
import { LeadBonoTrigger } from './LeadBonoTrigger';
import { LeadMatchTrigger } from './LeadMatchTrigger';
import { LeadOfertasTrigger } from './LeadOfertasTrigger';
import { LeadStockTrigger } from './LeadStockTrigger';

const renderTrigger = (featureApp: EmbeddedFeatureApp, props: TriggerProps) => {
  if (featureApp === 'es-ofertas') {
    return <LeadOfertasTrigger {...props} />;
  } else if (featureApp === 'es-stock-teaser') {
    return <LeadStockTrigger {...props} />;
  } else if (featureApp === 'es-match') {
    return <LeadMatchTrigger {...props} />;
  }else if (featureApp === 'es-bono') {
    return <LeadBonoTrigger {...props} />;
  }

  return null;
};

export function LeadEmbededTrigger(props: TriggerProps) {
  const faContent = useFaContent();

  return renderTrigger(faContent.featureApp, props);
}
