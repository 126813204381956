import {
  BreakpointWrapper,
  Breakpoints,
  styled,
  Text,
  TextAlignment,
  ThemeProvider,
  Container,
  ContainerPadding,
  CTA,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerHandleV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useStore, useSelector } from 'react-redux';
import {
  LayerManagerWrapper,
  useFeatureAppEnvironment,
  useFeatureServices,
  useOneFormContext,
  useTrackingManager,
} from 'src/feature-app';
import { KoScreen, LoadingScreen } from '../../CitaPosventa/NuevaCitaPosventa/ConfirmarCitaLayer';
import { OneFormState } from 'src/types';

interface lastNavigationProps {
  refProps?: any;
  formMin?: any;
  setShowError?: any;
}

const NavigationWrapper = styled.div<lastNavigationProps>`
  display: block;
  padding-top: 44px;
`;

export const LastNavigationCEMOneShop = (props: lastNavigationProps) => {
  const { setShowError } = props;
  // const dispatch = useDispatch();
  const { handleShowFinalScreen, handleSubmit } = useOneFormContext();
  const { reset, getValues, triggerValidation } = useFormContext();
  const { fields } = useSelector((state: OneFormState) => state.formData);
  const [layerVisible, setLayerVisible] = useState<boolean>(false);
  const [koLayer, setKoLayer] = useState<LayerHandleV2<any>>(null);
  const [koScreen, setKoScreen] = useState<boolean>(false);

  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const dispatch = useDispatch();
  const trackingManager = useTrackingManager();
  const [isValid, setIsValid] = useState<boolean>(false);

  //Loading and Ko layers

  const handleKoLayer = () => {
    const layer = layerManager.openFocusLayer(renderKoLayer, {}, { size: FocusLayerSizeV2.A });
    setKoLayer(layer);
  };

  const renderKoLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <KoScreen
          retrySubmit={retrySubmit}
          closeLayerCallback={close}
          bodyCopy="Por alguna razón no hemos podido guardar tu respuesta. ¿Lo intentamos de nuevo?"
          isLEM={true}
        />
      </LayerManagerWrapper>
    );
  };

  const handleLoadingScreenLayer = () => {
    const layer = layerManager.openFocusLayer(renderLoadingScreen, {}, { size: FocusLayerSizeV2.A });

    return layer;
  };

  const renderLoadingScreen = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingScreen copy="Enviando tu respuesta" />
      </LayerManagerWrapper>
    );
  };

  const handleConfirmarEnvio = async (e: any) => {
    const values = getValues();
    const isValid = await triggerValidation();
    setShowError(false);

    if (isValid) {
      setShowError(false);

      dispatch({ type: 'UPDATE_FIELDS', payload: values });

      trackingManager.trackFormCTAClick({
        contentId: 'Enviar',
      });

      const loadingLayer = handleLoadingScreenLayer();
      setLayerVisible(true);

      const value = await handleSubmit(e);

      loadingLayer.close();

      if (value) {
        handleShowFinalScreen();
      } else {
        handleKoLayer();
      }
    } else {
      setShowError(true);
    }
  };

  async function retrySubmit(e: any) {
    if (koLayer) {
      koLayer.close();
    }
    setKoScreen(false);
    const loadingLayer = handleLoadingScreenLayer();
    const value = await handleSubmit(e);
    loadingLayer.close();
    if (value) {
      handleShowFinalScreen();
    } else {
      handleKoLayer();
      setKoScreen(true);
    }
  }

  useEffect(() => {
    if (!layerVisible && koScreen) setKoScreen(false);
  }, [layerVisible]);

  useEffect(() => {
    if (fields.enviarInformacion) setIsValid(true);
  }, [fields]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FORM_ENDED', payload: true });
  }, []);

  return (
    <NavigationWrapper ref={props.refProps} formMin={props.formMin}>
      <Container
        padding={{ bottom: ContainerPadding.static500 }}
        horizontalAlign={'flex-start'}
      >
        <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.left} color={TokenTextColor.secondary}>
          Enviando este formulario declaras estar informado de la{' '}
          <CTA
            tag="a"
            href="https://www.volkswagen.es/es/volkswagen-espana/datos-privacidad.html"
            target="_blank"
            emphasis="tertiary"
          >
            <Text bold>política de privacidad.</Text>
          </CTA>
        </Text>
      </Container>

      <ThemeProvider>
        <BreakpointWrapper max={Breakpoints.b560}>
          <Container horizontalAlign={'flex-start'}>
            <CTA
              tag="button"
              type="submit"
              emphasis="primary"
              onClick={(e) => {
                e.preventDefault();
                handleConfirmarEnvio(e);
              }}
              stretchContent
            >
              Enviar
            </CTA>
          </Container>
        </BreakpointWrapper>
        <BreakpointWrapper min={Breakpoints.b560}>
          <Container horizontalAlign={'center'}>
            <CTA
              tag="button"
              type="submit"
              emphasis="primary"
              onClick={(e) => {
                e.preventDefault();
                handleConfirmarEnvio(e);
              }}
            >
              Enviar
            </CTA>
          </Container>
        </BreakpointWrapper>
      </ThemeProvider>
    </NavigationWrapper>
  );
};
