import React, { useEffect, useState } from 'react';
import { ThankYouTestID } from '../TestID2023';
import { dpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import {
  FormController,
  formatPrefix,
  removeWhiteSpace,
  useGetIdPerson,
  useOneFormContext,
} from 'src/feature-app';
import {
  FormDataGroup,
  FormInfo,
  OneFormState,
  Steps,
} from 'src/types';
import { formDataBuilder, formatHeaders } from 'src/forms/format-fields';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { useDispatch, useStore } from 'react-redux';
import { ConcursoRodajeConfirmacion } from './ConcursoRodajeConfirmacion';
import { ThankYouConcursoRodaje } from './ThankYouConcursoRodaje';

interface ConcursoRodajeFieldsProps {
  dpsData?: any;
  idPerson?: any;
  handleError?: any;
  email?: string;
  name?: string;
  surname?: string;
}

export function ConcursoRodajeForm(props: ConcursoRodajeFieldsProps) {
  const { initializeForm } = useOneFormContext();
  const trackingManager = useTrackingManager();
  const store = useStore();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const idPerson = useGetIdPerson();

  useEffect(() => {
    const {
      FirstName: name,
      LastName: surname,
      TXT_Email__c: email,
      TXT_Postal_Code__c: postalCode,
      NUM_Phone_mobile__c: telefono,
    } = idPerson || {};

    dispatch({ type: 'UPDATE_FIELDS', payload: { email, name, surname, postalCode, telefono } });
    setLoading(false);
  }, []);

  const steps: Steps[] = [
    {
      fields: <ConcursoRodajeConfirmacion idPerson={idPerson} />,
      screenIndex: 0,
      name: 'Confirmacion',
      outputs: ['confirmacion'],
      outputsText: ['confirmacion'],
    },
  ];

  const initialData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_GDPR_ADAPTED: 'true',
      DESC_TOKEN: 'VW_DDB_FRM_CONCURSO_RODAJE_2023',
      DESC_FORMOBJECTIVE: 'Concurso',
    },
    fields: {
      formName: 'adhoc-2023-concurso_rodaje',
      formType: 'otherform',
      pageCategory: 'Concurso',
    }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const generateData = (data?: Partial<FormDataGroup>) => {
    const { formData }: OneFormState = store.getState();
    const { fields } = formData;
    const { tc, today, url, device } = getParamsForm();

    return {
      ...formatHeaders(initialData.headers),
      'headers[DESC_URLORIGIN]': url,
      'headers[COD_FORM_ID]': `vw-oneform-${fields.formName}`,
      'headers[DESC_CAMPAIGNBLOCK]': '7. VW Driving Experience',
      'headers[DESC_DEVICETYPE]': device,
      'headers[DESC_ID_CAMPAIGN]': '7017Q000000DUlUQAW',
      'headers[DESC_FORM_DETAIL]': 'Concurso Rodaje 2023',
      'headers[DESC_GDPR_ADAPTED]': 'true',
      'headers[FECHA_INSERTION_DATE]': today,
      'headers[TC]': tc,
      'headers[DESC_VN_VO]': 'VN',

      'fields[DESC_POSTAL_CODE]': fields.postalCode ? fields.postalCode : idPerson.TXT_Postal_Code__c,
      'fields[DESC_NAME]': fields.name ? fields.name : idPerson.FirstName,
      'fields[DESC_SURNAME_1]': fields.surname ? fields.surname : idPerson.LastName,
      'fields[DESC_EMAIL_ADDRESS]': fields.email ? fields.email : idPerson ? idPerson.TXT_Email__c : null,

      'fields[DESC_FORM_VERSION]': `vw-oneform-${initialData.fields.formName}-${process.env.VERSION}`,

      'fields[DESC_LOPD]': 'PERMITE',
      'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
      'fields[DESC_LOPD_GDPR_PERFILADO]': 'NO INFORMADO',
      'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
      'fields[NUM_CELLPHONE]': fields.telefono ? `${formatPrefix(fields.prefix)}${fields.telefono}` : idPerson.NUM_Phone_mobile__c,

    };
  };

  const generateSubmitData = () => generateData();

  const sendForm = async (): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateSubmitData();
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData);

      if (response.data?.content?.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      thankYouComponent: <ThankYouConcursoRodaje />,
    };

    if (!loading) {
      initializeForm(NuevoFormInfo, initialData, true);
      trackingManager.trackFormLoad();
    }
  }, [loading]);

  return loading || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
