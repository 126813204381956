import { TrackingConfiguration, TrackingOwnership, TrackingPersonalization, TrackingUser } from './tracking-types';

export const emptyPersonalization: TrackingPersonalization = {
  status: false,
  moduleId: undefined,
  placeholderId: undefined,
  contentId: undefined,
  activityId: undefined,
  personalizable: undefined,
  activityName: undefined,
  experienceId: undefined,
  experienceName: undefined,
  offerId: undefined,
  offerName: undefined,
};

export const emptyPriceConfiguration: TrackingConfiguration = {
  PriceType: 'price',
  PriceTypeParameter: undefined,
  PriceRateCurrency: undefined,
  PriceRateBasic: undefined,
  PriceRateAdditional: undefined,
  PriceRateTotal: undefined,
  PriceRateType: undefined,
  PriceTotalCurrency: undefined,
  PriceTotalBasic: undefined,
  PriceTotalAdditional: undefined,
  PriceTotalTotal: undefined,
};

export const emptyCarlineConfig: TrackingConfiguration = {
  SalesgroupId: undefined,
  SalesgroupName: undefined,
  EquipmentlineName: undefined,
  CarlineId: undefined,
  CarlineName: undefined,
};

export const emptyLatLongZip: TrackingUser = {
  locationLatitude: undefined,
  locationLongitude: undefined,
  locationZIP: undefined,
};

export const emptyConfig = { ...emptyPriceConfiguration, ...emptyCarlineConfig };

export const emptyOwnership: TrackingOwnership = {
  vehicle: undefined,
};

export const emptyConfigurationFromVicci = {
  CarlineIsRecommendation: undefined,
  SalesgroupIsRecommendation: undefined,
  BodyTypeName: undefined,
  PriceTotalTotal: undefined,
  ModelId: undefined,
  ModelTrimFeatures: undefined,
  ModelModelVersion: undefined,
  ModelIsRecommendation: undefined,
  ModelName: undefined,
  ModelDriveType: undefined,
  ModelGearType: undefined,
  ModelYear: undefined,
  EnginePowerHPKW: undefined,
  EngineFuelTypePrimary: undefined,
  EngineFuelTypeNonPrimary: undefined,
  ConsumptionCombinedPrimary: undefined,
  ConsumptionCombinedNonPrimary: undefined,
  EmissionConsumptionEfficiencyClass: undefined,
  EmissionConsumptionTestingMethod: undefined,
  ExteriorColorCode: undefined,
  ExteriorColorName: undefined,
  ExteriorColorIsRecommendation: undefined,
  InteriorColorCode: undefined,
  InteriorColorName: undefined,
  InteriorColorIsRecommendation: undefined,
  WheelsBasicId: undefined,
  WheelsBasicName: undefined,
  WheelsBasicIsRecommendation: undefined,
  PriceCurrency: undefined,
  PriceBasis: undefined,
  PriceExterior: undefined,
  PriceOptions: undefined,
  PriceTotalConfiguration: undefined,
  PriceCampaignID: undefined,
  PriceCampaignDescription: undefined,
  PriceCampaignValue: undefined,
  PriceCustomerType: undefined,
  OptionsNumber: undefined,
  OptionsList: undefined,
};
