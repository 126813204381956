import React, { useEffect, useRef, useState } from 'react';
import { isHighRating, isLowRating, isNoRating, allHighRatings, allLowRatings, allNoRatings, allHighOrNoRatings } from 'src/feature-app';


export function LastQuestionFlow(ratings: string[], lastQuestion: string) {

	const goodRating = allHighRatings(ratings);
	const badRating = allLowRatings(ratings);
	const emptyRating = allNoRatings(ratings);
	const goodOrEmptyRating = allHighOrNoRatings(ratings);

	/* 
	Qfinal=4,5 AND NOT [Q1=3,2,1 AND Q2=3,2,1 AND Qn=3,2,1]
	OR
	Qfinal=3,2,1 AND Q1=5,4,9 AND Q2=5,4,9 AND Qn=5,4,9
	*/
	const mostrarQlast1 =
		(isHighRating(lastQuestion) && !badRating)
		||
		(isLowRating(lastQuestion) && goodOrEmptyRating);

	/* 
	Qfinal=3,2,1 
	AND
	NOT [Q1=5,4,9 AND Q2=5,4,9 AND Qn=5,4,9]
	AND
	NOT [Q1=3,2,1 AND Q2=3,2,1 AND Qn=3,2,1]
	*/
	const mostrarQlast2 = isLowRating(lastQuestion) && !goodOrEmptyRating && !badRating;

	const urlParams = new URLSearchParams(window.location.search);
	const dev = urlParams.get('dev') !== null;

	if(dev) {
		console.log('LAST RATING:', lastQuestion, 'isHigh:', isHighRating(lastQuestion), 'isLow:', isLowRating(lastQuestion));
		console.log('RATINGS:', ratings, 'allgood:', goodRating, 'allbad:', badRating, 'allempty:', emptyRating, 'allgoodroempty:', goodOrEmptyRating);
		console.log('show1:', mostrarQlast1, 'show2:', mostrarQlast2);
	}

	return {
		mostrarQlast1: mostrarQlast1,
		mostrarQlast2: mostrarQlast2
	};

}
