/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState, useEffect } from 'react';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';

export const useGetBoundingClientRect = (identifier: string) => {

  const [boundingObject, setBoundingObject] = useState(null);
  const [bottom, setBottom] = useState(null);
  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const [right, setRight] = useState(null);
  const [y, setY] = useState(null);

  //boundingObject contiene varias keys, bottom hace referencia a la distancia entre el bottom del elemento y el top del window

  const getBoundingObject = () => {
    const element = document.querySelector(identifier);
    if (element) {
      const { top, right, bottom, left, width, height, x, y } = element.getBoundingClientRect();
      //la librería de autosuggest no permite poner id, por eso el querySelector
      setBoundingObject({ top, right, bottom, left, width, height, x, y });
      setBottom(bottom);
      setTop(top);
      setLeft(left);
      setRight(right);
      setY(y);
    }
  };

  const getAndUpdateState = () => {
    getBoundingObject();
  };

  return { boundingObject, bottom, top, left, right, y, getAndUpdateState };
};
