import { DealersInfo } from 'src/types';

export const getCodInterestCarline = (dealersInfo: DealersInfo, code: string) => {
  const { selectedCarline } = dealersInfo;

  if (!code && !selectedCarline) return '';
  if (code && !selectedCarline) {
    return code;
  }

  return String(selectedCarline.code);
};
