import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { getCarlines } from 'src/bff';
import { useFaContent, useFeatureServices } from 'src/feature-app';
import { CarlineBase, IdLead, Steps } from 'src/types';

const getLastItemOfSteps = (steps: Steps[]) => steps[steps.length - 1];

const propertyHasValue = (property: string) => property !== 'null';

export const checkIdLeadHasCarline = (idLead: IdLead) =>
  idLead &&
  idLead.NUM_COD_INTEREST_CARLINE__c &&
  idLead.DESC_Model__c &&
  propertyHasValue(idLead.NUM_COD_INTEREST_CARLINE__c) &&
  propertyHasValue(idLead.DESC_Model__c);

export function useInitializeFidelizacionForm(defaultSteps: Steps[], idLead: IdLead) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [steps, setSteps] = useState<Steps[]>(defaultSteps);
  const [firstStep, setFirstStep] = useState<Steps>(defaultSteps[0]);
  const [lastStep, setLastStep] = useState<Steps>(getLastItemOfSteps(defaultSteps));

  const removeModelFromSteps = async () => {
    return new Promise((resolve) => {
      let updatedSteps = [...steps];
      const indexOfModelo = updatedSteps.findIndex((step) => step.name === 'Modelo');
      updatedSteps.splice(indexOfModelo, 1); // Eliminado el step de Modelo.
      updatedSteps[0].hidePreviousStep = true;
      const updatedStepsWithIndex = updatedSteps.map((step, index) => {
        step.screenIndex = index;
        return step;
      });

      setSteps(updatedStepsWithIndex);
      setFirstStep(updatedStepsWithIndex[0]);
      setLastStep(getLastItemOfSteps(updatedStepsWithIndex));

      resolve(true);
    });
  };

  useEffect(() => {
    const remove = async () => {
      await removeModelFromSteps();
      setLoading(false);
    };

    const requestCarlines = async () => {
      const carlines = await getCarlines();

      dispatch({ type: 'SET_CARLINES', payload: carlines });
      setSteps(defaultSteps);
      setLoading(false);
    };

    if (checkIdLeadHasCarline(idLead)) {
      remove();
    } else {
      requestCarlines();
    }
  }, []);

  return { loading, steps, firstStep, lastStep };
}
