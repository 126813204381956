import {
  Breakpoints,
  BreakpointWrapper,
  CTA,
  styled,
  Layout,
} from '@volkswagen-onehub/components-core';
import { LegalesFormatoLayer, LssiTkmQuestions, PoliticaPrivacidad } from 'src/components';
import { CloseHandleV2, FocusLayerSizeV2, LayerHandleV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch, useStore } from 'react-redux';
import {
  LayerManagerWrapper,
  useFeatureAppEnvironment,
  useFeatureServices,
  useOneFormContext,
  useTrackingManager,
} from 'src/feature-app';
import { OneFormState } from 'src/types';
import { KoScreen, LoadingScreen } from './ConfirmarCallMeLayer';

const GeneralconfirmacionWrapper = styled.div`
  padding: 72px 0 44px;
  @media all and (min-width: 960px) {
    padding: 86px 0 0;
  }
`;

function ConfirmacionCallMeOferta() {
  const dispatch = useDispatch();

  const [lssi, setLssi] = useState<string>(null);
  const [tmk, setTmk] = useState<string>(null);
  const [lssiError, setLssiError] = useState<boolean>(false);
  const [tmkError, setTmkError] = useState<boolean>(false);

  useEffect(() => {
    if (lssi) {
      const values = { lssi: lssi };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setLssiError(false);
    }
    if (tmk) {
      const values = { tmk: tmk };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setTmkError(false);
    }
  }, [lssi, tmk]);

  return (
    <GeneralconfirmacionWrapper>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
        }}
        allowOverflowingContent
      >
        <LssiTkmQuestions showTitle={true}/>
        <PoliticaPrivacidad />

        <div className="last-navigation-position" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <LastNavigation />
        </div>
        <LegalesFormatoLayer noPadding={false} showTitle={true} />
      </Layout>
    </GeneralconfirmacionWrapper>
  );
}

interface LastNavigationProps {
  tmk?: string;
  lssi?: string;
  setTmkError?: React.Dispatch<React.SetStateAction<boolean>>;
  setLssiError?: React.Dispatch<React.SetStateAction<boolean>>;
}

function LastNavigation(props: LastNavigationProps) {
  const { tmk, lssi, setTmkError, setLssiError } = props;
  // const dispatch = useDispatch();
  const { handleShowFinalScreen, handleSubmit } = useOneFormContext();
  const { triggerValidation } = useFormContext();
  const [layerVisible, setLayerVisible] = useState<boolean>(false);
  const [koLayer, setKoLayer] = useState<LayerHandleV2<any>>(null);
  const [koScreen, setKoScreen] = useState<boolean>(false);
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const dispatch = useDispatch();
  const trackingManager = useTrackingManager();

  //Loading and Ko layers

  const handleKoLayer = () => {
    const layer = layerManager.openFocusLayer(renderKoLayer, {}, { size: FocusLayerSizeV2.A });
    setKoLayer(layer);
  };

  const renderKoLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <KoScreen retrySubmit={retrySubmit} closeLayerCallback={close} />
      </LayerManagerWrapper>
    );
  };

  const handleLoadingScreenLayer = () => {
    const layer = layerManager.openFocusLayer(renderLoadingScreen, {}, { size: FocusLayerSizeV2.A });

    return layer;
  };

  const renderLoadingScreen = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingScreen isleads />
      </LayerManagerWrapper>
    );
  };

  // 1. check de lssi y tmk
  // 2. submit de la cita y activación la pantalla de feedback correspondiente
  // no se hace ninguna validación en este último paso porque esta ya se ha hecho en el edit-guardar de cada campo previamente
  async function handleConfirmarCallMe(e: any) {
    if (tmk === null && tmk === null) {
      setTmkError(true);
      setLssiError(true);
    } else if (tmk === null) {
      setTmkError(true);
    } else if (lssi === null) {
      setLssiError(true);
    } else {

      const isValid = await triggerValidation();

      if (!isValid) return;

      trackingManager.trackFormCTAClick({
        contentId: 'Enviar',
      });

      const loadingLayer = handleLoadingScreenLayer();
      setLayerVisible(true);

      const value = await handleSubmit(e);
      loadingLayer.close();

      if (value) {
        handleShowFinalScreen();
      } else {
        handleKoLayer();
      }
    }
  }
  async function retrySubmit(e: any) {
    if (koLayer) {
      koLayer.close();
    }
    setKoScreen(false);
    const loadingLayer = handleLoadingScreenLayer();
    const value = await handleSubmit(e);
    loadingLayer.close();
    if (value) {
      handleShowFinalScreen();
    } else {
      handleKoLayer();
      setKoScreen(true);
    }
  }

  useEffect(() => {
    if (!layerVisible && koScreen) setKoScreen(false);
  }, [layerVisible]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FORM_ENDED', payload: true });
  }, []);

  return (
    <>
      <BreakpointWrapper max={Breakpoints.b560}>
        <CTA
          tag="button"
          type="submit"
          emphasis="primary"
          onClick={(e) => {
            e.preventDefault();
            handleConfirmarCallMe(e);
          }}
          stretchContent
        >
          Enviar
        </CTA>
      </BreakpointWrapper>
      <BreakpointWrapper min={Breakpoints.b560}>
        <CTA
          tag="button"
          type="submit"
          emphasis="primary"
          onClick={(e) => {
            e.preventDefault();
            handleConfirmarCallMe(e);
          }}
        >
          Enviar
        </CTA>
      </BreakpointWrapper>
    </>
  );
}

const mapStateToProps = (state: OneFormState) => {
  return {
    formInfo: state.formInfo,
    formData: state.formData,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ConfirmacionCallMeOferta);

export { ConnectedComponent as ConfirmacionCallMeOferta };
