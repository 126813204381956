import {
  Breakpoints,
  BreakpointWrapper,
  Image,
  Layout,
  styled,
  Text,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { useFeatureServices,  useFaContent } from 'src/feature-app';

const TitleTextWrapper = styled.div`
  position: relative;
`;
const TextWrapper = styled.div`
  position: absolute;
  bottom: 5%;
  color: #ffffff;
  padding: 0px var(--size-grid002);
`;
interface MGMTitleProps {
  title: string;
  copy: string;
}
interface MGMLayoutProps {
  title: string;
  fields: JSX.Element;
}

export function MGMLayout({ title, fields }: MGMLayoutProps) {
  const imageService = useFeatureServices()['image-service'];
  const mgmImgSrc = useFaContent()?.mgmImgSrc;

  // const test = imageService.getImageURL({ contentFile: '/content/dam/evhub-sample/images/16-9/16_9.jpg', scene7File: '/content/dam/evhub-sample/images/16-9/16_9.jpg', });
  // console.log(test, 'test');
  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          { name: 'a', columns: 24 },
          { name: '.', columns: 2 },
          { name: 'b', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b560]: [
          { name: 'a', columns: 10 },
          { name: '.', columns: 2 },
          { name: 'b', columns: 10 },
          { name: '.', columns: 2 },
        ],
      }}
    >
      <TitleTextWrapper>
        <Image src={mgmImgSrc} alt="Volkswagen" />
        <TextWrapper>
          <BreakpointWrapper max={Breakpoints.b560}>
            <Text color={TokenTextColor.inherit} appearance={TokenTextAppearance.headline300} bold>
              {title}
            </Text>
          </BreakpointWrapper>
        </TextWrapper>
      </TitleTextWrapper>
      <>{fields}</>
    </Layout>
  );
}

export const MGMTitle = ({ title, copy }: MGMTitleProps) => {
  return (
    <>
      <BreakpointWrapper min={Breakpoints.b560}>
        <Text appearance={TokenTextAppearance.headline300} bold>
          {title}
        </Text>
      </BreakpointWrapper>
      <br />
      <Text appearance={TokenTextAppearance.copy200} staticSize>
        {copy}
      </Text>
    </>
  );
};
