import React, { useEffect, useState } from 'react';
import { ConfirmationPlanIdeal, ThankYouPlanIdeal } from './';
import { QuestionWithImage } from './QuestionWithImage';
import { dpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import {
  formatPrefix,
  FormController,
  useOneFormContext,
} from 'src/feature-app';
import {
  FormDataGroup,
  FormInfo,
  OneFormState,
  Steps,
} from 'src/types';
import { formDataBuilder } from 'src/forms/format-fields';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { OneElementTemplate } from 'src/feature-app/Screen';
import {
  DetalleMotivo,
} from 'src/components';
import {
  Text,
} from '@volkswagen-onehub/components-core';
import { addRemovedStep, getIndexInSteps, removeStep, resetStepsScreenIndex } from '../../helpers/add-remove-steps';

export enum PlanIdealSteps {
  PlanIDeal1,
  PlanIDeal2,
  PlanIDeal3,
  PlanIDeal3Otros,
  Confirmacion
}

const questions = [
  {
    questionName: 'PlanIDeal1',
    screenIndex: PlanIdealSteps.PlanIDeal1,
    intro: <>En tus escapadas...</>,
    title: <>¿Cuánto te gusta conducir?</>,
    answers: [
        'Mucho, mi coche es mi segundo hogar',
        'Ni fu ni na, de copiloto se va mejor',
        'Poco, solo si es necesario'
    ],
    videoSrc: 'https://assets.volkswagen.com/is/content/volkswagenag/video01-0x720-3000k',
    imageSrc: 'https://assets.volkswagen.com/is/image/volkswagenag/DB2023AU00207_medium%201?Zml0PWNyb3AlMkMxJndpZD00NjQmaGVpPTQ2NCZmbXQ9cG5nLWFscGhhJmJmYz1vZmYmNjA2Mg==',
    skippable: false
  },
  {
    questionName: 'PlanIDeal2',
    screenIndex: PlanIdealSteps.PlanIDeal2,
    intro: <>En tus escapadas...</>,
    title: <>¿Quién suele acompañarte?</>,
    answers: [
        'Voy solo y sin que nadie me toque la música',
        'Mi familia, nada nos separa',
        'Mi pareja es mi mejor copiloto',
        'Mis amigxs, con ellxs a todos lados'
    ],
    videoSrc: 'https://assets.volkswagen.com/is/content/volkswagenag/video02-0x720-3000k',
    imageSrc: 'https://assets.volkswagen.com/is/image/volkswagenag/DB2023AU00207_medium%201?Zml0PWNyb3AlMkMxJndpZD00NjQmaGVpPTQ2NCZmbXQ9cG5nLWFscGhhJmJmYz1vZmYmNjA2Mg==',
    skippable: false
  },
  {
    questionName: 'PlanIDeal3',
    screenIndex: PlanIdealSteps.PlanIDeal3,
    intro: <>Ahora es tu turno.</>,
    title: <>¿Hay algo que quieras saber de nuestros eléctricos?</>,
    answers: [
        'La autonomía que tienen',
        'El tiempo y lugar de carga',
        'Lo que me ahorro',
        'Otros'
    ],
    videoSrc: 'https://assets.volkswagen.com/is/content/volkswagenag/video03-0x720-3000k',
    imageSrc: 'https://assets.volkswagen.com/is/image/volkswagenag/DB2023AU00207_medium%201?Zml0PWNyb3AlMkMxJndpZD00NjQmaGVpPTQ2NCZmbXQ9cG5nLWFscGhhJmJmYz1vZmYmNjA2Mg==',
    skippable: true
  }
];

interface PlanIdealFormProps {
  dpsData?: any;
  idPerson?: any;
  handleError?: any;
  email?: string;
  name?: string;
  surname?: string;
}

export function PlanIdealForm(props: PlanIdealFormProps) {
  const { idPerson } = props;
  const { initializeForm, handleNextStep } = useOneFormContext();
  const trackingManager = useTrackingManager();
  const store = useStore();
  const dispatch = useDispatch();
  const { formData } = useSelector((state: OneFormState) => state);
  const [loading, setLoading] = useState(true);

  const name: string = props.name ? props.name : props.idPerson?.FirstName;
  const surname: string = props.surname ? props.surname : props.idPerson?.LastName;
  const email: string = props.email ? props.email : props.idPerson?.TXT_Email__c;

  useEffect(() => {
    dispatch({ type: 'UPDATE_FIELDS', payload: { email, name, surname } });
    setLoading(false);
  }, []);

  //Logica eliminar o añadir PlanIdeal3Otros
  const { PlanIDeal3 } = formData.fields;
  const handlePlanIDeal3Otros = (PlanIDeal3: String) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
    let newSteps = [...actualSteps];

    if (PlanIDeal3 === "Otros") {
      const stepIndex = getIndexInSteps(newSteps, 'PlanIDeal3Otros');

      if (stepIndex === -1) {
        newSteps = addRemovedStep(steps, actualSteps, 'PlanIDeal3Otros', screenIndex);
        setTimeout(() => {
          handleNextStep();
        }, 0);
      }
    } else {
      newSteps = removeStep(newSteps, 'PlanIDeal3Otros');
    }

    const newStepsWithIndex = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
  };

  const steps: Steps[] = [
    ...questions.map((question, index) => {
      return {
        fields: <QuestionWithImage {...question} index={index} />,
        screenIndex: question.screenIndex,
        name: question.questionName,
        showOverflow: true,
      }
    }),
    {
      title: <>¿Nos das <Text bold>más detalles</Text>?</>,
      fields: <OneElementTemplate element={<DetalleMotivo name='PlanIDeal3Otros'/>} />,
      screenIndex: PlanIdealSteps.PlanIDeal3Otros,
      name: 'PlanIDeal3Otros'
    },
    {
      fields: <ConfirmationPlanIdeal idPerson={idPerson} />,
      screenIndex: PlanIdealSteps.Confirmacion,
      name: 'Confirmacion',
      outputs: ['confirmacion'],
      outputsText: ['confirmacion'],
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    fields: {
      formName: 'adhoc-2023-plan_ideal',
      formType: 'otherform',
      pageCategory: 'Concurso'
    }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const generateData = (data?: Partial<FormDataGroup>) => {
    const { formData }: OneFormState = store.getState();
    const { fields } = formData;
    const { tc, today, url, device } = getParamsForm();

    return {
      'headers[COD_FORM_ID]': fields.formName ? `vw-oneform-${fields.formName}` : `vw-oneform-${data.fields.formName}`,
      'headers[DESC_BRAND]': 'VW',
      'headers[DESC_CAMPAIGNBLOCK]': '16. OneShots',
      'headers[DESC_DEVICETYPE]': device,
      'headers[DESC_FORM_DETAIL]': 'Plan IDeal 2023',
      'headers[DESC_FORMOBJECTIVE]': 'Concurso',
      'headers[DESC_GDPR_ADAPTED]': 'true',
      'headers[DESC_ID_CAMPAIGN]': '7017Q000000DYShQAO',
      'headers[DESC_TOKEN]': 'VW_DDB_FRM_PLAN_IDEAL_2023',
      'headers[DESC_URLORIGIN]': url,
      'headers[DESC_VN_VO]': 'VN',
      'headers[FECHA_INSERTION_DATE]': today,
      'headers[TC]': tc,
      'headers[DESC_ID_CLIMAPRO]': idPerson ? idPerson.ID_Person_DIM_IMP__c : "",

      'fields[DESC_AUXFIELD1]': fields.PlanIDeal1, // Primera Respuesta
      'fields[DESC_AUXFIELD2]': fields.PlanIDeal2, // Segunda Respuesta
      'fields[DESC_AUXFIELD3]': fields.PlanIDeal3, // Tercera Respuesta
      'fields[M_OBSERVATION]': fields.PlanIDeal3 === 'Otros' ? fields.PlanIDeal3Otros : '', // Mensaje en caso otros en Tercera Respuesta

      'fields[DESC_EMAIL_ADDRESS]': fields.email ? fields.email : idPerson? idPerson.TXT_Email__c : null,
      'fields[DESC_FORM_VERSION]': fields.formName
        ? `vw-oneform-${fields.formName}-${process.env.VERSION}`
        : `vw-oneform-${data.fields.formName}-${process.env.VERSION}`,
      'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
      'fields[DESC_LOPD]': 'PERMITE',
      'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
      'fields[DESC_NAME]': fields.name ? fields.name : idPerson.FirstName,
      'fields[DESC_SURNAME_1]': fields.surname ? fields.surname : idPerson.LastName,
      'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
      'fields[NUM_CELLPHONE]': fields.telefono ? `${formatPrefix(fields.prefix)}${fields.telefono}` : idPerson.NUM_Phone_mobile__c,

    };
  };

  const generateSubmitData = () => generateData();

  const sendForm = async (): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateSubmitData();
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData);

      if (response.data?.content?.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      thankYouComponent: <ThankYouPlanIdeal />,
    };

    if (!loading) {
      initializeForm(NuevoFormInfo, NuevoFormData, true);
      trackingManager.trackFormLoad();
    }
  }, [loading]);

  useEffect(() => {
    if (PlanIDeal3) handlePlanIDeal3Otros(PlanIDeal3);
  }, [PlanIDeal3]);

  return loading || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
