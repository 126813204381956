import { FormDataGroup } from 'src/types';

 export const PresupuestoPartialDataConfigurador: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Presupuesto' },
  fields: {
    formFields: {},
    formularioFlag: 'presupuesto',
    formName: 'lead-presupuesto-configure',
    formType: 'quote',
    pageCategory: 'Presupuesto',
  },
};
