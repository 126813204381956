import { CTA } from '@volkswagen-onehub/components-core';
import { ArrowUp } from 'src/icons-core-imports';
import React from 'react';
import { useOneFormContext } from 'src/feature-app';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';


export const AnteriorCTA = () => {
 
  const { handlePreviousStep  } = useOneFormContext();
  const trackingManager = useTrackingManager();

  return (
  
    <CTA
      onClick={(e) => {
        trackingManager.trackFormStepButtonClick({
          contentId: 'Anterior',
        });
        handlePreviousStep(e);
      }}
      tag="button"
      emphasis="secondary" 
      size="small"
      icon={<ArrowUp variant="small" />}
    >
      Anterior
    </CTA>

  );
};
