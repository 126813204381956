import { OneFormState } from 'src/types';

import { createSelector } from 'reselect';

const getPoint = (state: OneFormState) => {
  return state.dealersInfo.points;
};

export const getPoints = createSelector(getPoint, (getPoint) => getPoint);

const getGeometryProperty = (state: OneFormState) => {
  return state.formInfo.geometry;
};

export const getGeometry = createSelector(getGeometryProperty, (getGeometryProperty) => getGeometryProperty);

const getUserSuggestionProperty = (state: OneFormState) => {
  return state.formInfo.userSuggestion;
};

export const getUserSuggestion = createSelector(
  getUserSuggestionProperty,
  (getUserSuggestionProperty) => getUserSuggestionProperty
);

const getUserGeolocationProperty = (state: OneFormState) => {
  return state.formInfo.userGeoLocation;
};

export const getUserGeolocation = createSelector(
  getUserGeolocationProperty,
  (getUserGeolocationProperty) => getUserGeolocationProperty
);
