import { Breakpoints, Layout, styled } from '@volkswagen-onehub/components-core';
import React from 'react';
import { CitaPreviaTile } from './';

const Grid = styled.div`
  --grid-gap-value: 32px;
  -gap: var(--grid-gap-value);
  display: grid;
  grid-auto-rows: calc((100vw / (9 / 5)) + var(--grid-gap-value) / 2);
  grid-template-columns: 1fr;
  grid-auto-flow: row dense;

  @media screen and (min-width: 560px) {
    --grid-gap-value: 56px;
    grid-auto-rows: calc(48.6111vw);
  }
  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: calc(((21 / 24) * 100vw - (2 - 1) * var(--grid-gap-value)) / 2 / (9 / 5));
  }
  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: calc(((21 / 24) * 100vw - (3 - 1) * var(--grid-gap-value)) / 3 / (9 / 5));
  }
  @media screen and (min-width: 1920px) {
    --grid-gap-value: 72px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: calc(((21 / 24) * 100vw - (4 - 1) * var(--grid-gap-value)) / 4 / (9 / 5));
  }
`;

export const CitaPreviaTileGrid = (props: {
  setSelectedJourney: React.Dispatch<React.SetStateAction<JSX.Element>>;
}) => {
  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [{ name: 'a', columns: 24 }],
        [Breakpoints.b560]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
      }}
    >
      <Grid>
        <CitaPreviaTile {...props} />
      </Grid>
    </Layout>
  );
};
