import { getParamsForm } from 'src/feature-app/get-params-form';
import { removeWhiteSpace, formatPrefix } from 'src/feature-app';

const { tc, today, url, device } = getParamsForm();

export const getCommonFormData = (fields) =>{
    return {

        'headers[DPS_TOKEN]': 'VW_DDB_FRM_SAC',
        'headers[FORM_DATE_INSERTION]': today,
        'headers[FORM_NAME]': `vw-oneform-${fields.formName}`,
        'headers[FORM_OBJECTIVE]': 'Atención al cliente',
        'headers[FORM_URL]': url,
        'headers[FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
        'headers[ORIGIN_DEVICE_TYPE]': device,
        'headers[ORIGIN_TRACKING_CODE]': tc,
        'headers[SF_CAMPAIGN_ORIGIN_ID]': 'PENDIENTE',

        'flags[FLAG_RELACION_MARCA_SIN_IC]': false,
        'flags[PERSON_RGPD_ADAPTED]': true,

        'fields[DPS_BRAND]': 'Volkswagen',
        'fields[SURVEY_ANSWER_01]': fields.motivoSac ? fields.motivoSac : 'ONESHOP',
        'fields[PERSON_PREFERRED_CHANNEL]': fields.contactoSAC ? fields.contactoSAC : 'TELEFONO',
        'fields[PERSON_NAME]': fields.name,
        'fields[PERSON_SURNAME_1]': fields.surname,
        'fields[PERSON_SURNAME_2]': fields.secondSurname,
        'fields[PERSON_EMAIL]': removeWhiteSpace(fields.email),
        'fields[PERSON_PHONE_PREFIX]': fields.prefix,
        'fields[PERSON_PHONE_MOBILE]': fields.cellphone,
        'fields[PERSON_PHONE_MOBILE_FULL]': `${formatPrefix(fields.prefix)}${fields.cellphone}`,
        'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
    }
};
