import { fetchMarketingCode, reportErrorSentry } from 'src/bff';
import { TrackingConfiguration } from 'src/feature-app';

/*
export interface MarketingCodeFields {
  'fields[COD_VEH_SALESGROUP]': string;
  'fields[DESC_VEH_SALESGROUP]': string;
  'fields[DESC_TRIM_LINE]': string;
  'fields[COD_VEH_TMAIMG]': string;
  'fields[DESC_VEH_TMAIMG]': string;
  'fields[DESC_VEH_MARKETING_CODE]': string;
  'fields[COD_VEH_COLOR]': string;
  'fields[DESC_VEH_COLOR]': string;
  'fields[COD_VEH_COLOR_INT]': string;
  'fields[DESC_VEH_COLOR_INT]': string;
  'fields[COD_VEH_WHEELS]': string;
  'fields[DESC_VEH_WHEELS]': string;
}
*/

export interface MarketingCodeHistory {
  marketingCode: string;
  marketingCodeConfig: TrackingConfiguration;
  response: any;
}
let marketingCodeHistory: MarketingCodeHistory[] = [];

export const getMarketingCode = async (
  marketingCode: string,
): Promise<{
  marketingCodeConfig: TrackingConfiguration;
  response: any;
}> => {
  // await delayRequest();
  const offerInHistory = marketingCodeHistory.find((offer) => offer.marketingCode === marketingCode);

  if (offerInHistory) {
    return {
      marketingCodeConfig: offerInHistory.marketingCodeConfig,
      response: offerInHistory.response,
    };
  } else {
    const { marketingCodeConfig, response } = await receiveMarketingCode(marketingCode);
    const history: MarketingCodeHistory = { marketingCode, marketingCodeConfig, response };
    marketingCodeHistory.push(history);
    return { marketingCodeConfig, response };
  }
};

export const getFeatures = (features: { code: string }[]) => {
  if (!features) return [];
  return features.map((feature) => feature.code);
};

export const getWheelsParams = (features: { code: string; name: string }[]) => {
  if (!features) return { COD_VEH_WHEELS: '', DESC_VEH_WHEELS: '' };
  const wheel = features.find((feature) => feature.code.startsWith('MRAD'));

  const COD_VEH_WHEELS = wheel?.code ? wheel.code : undefined;
  const DESC_VEH_WHEELS = wheel?.name ? wheel.name : undefined;
  return { COD_VEH_WHEELS, DESC_VEH_WHEELS };
};

export const getWheels = (features: { code: string; name: string }[]) => {
  const { COD_VEH_WHEELS, DESC_VEH_WHEELS } = getWheelsParams(features);

  return { 'fields[COD_VEH_WHEELS]': COD_VEH_WHEELS, 'fields[DESC_VEH_WHEELS]': DESC_VEH_WHEELS.slice(0, 65) };
};

export const getOption = (category: string, options: { category: string, code: string, description: string }[]) => {
  const option = options.find((option) => option.category==category);
  return { option };
};

export const getModelFilter = (type: string, modelFilters: { id: string, text: string, type: string }[]) => {
  const modelFilter = modelFilters.find((modelFilter) => modelFilter.type==type);
  return { modelFilter };
};

class ErrorLoadStorageDataset extends Error {
  constructor(message: any) {
    super(message);
    this.name = 'ErrorLoadStorageDataset';
  }
}

export const receiveMarketingCode = async (
  marketingCode: string,
): Promise<{ marketingCodeConfig: TrackingConfiguration; response?: any }> => {

  try {
    const response = await fetchMarketingCode(marketingCode);

    const salesgroup = getOption("SALESGROUP",response.content.consolidatedData.options);
    const modelyear = getOption("MODELYEAR",response.content.consolidatedData.options);
    const type = getOption("TYPE",response.content.consolidatedData.options);
    const baseType = getOption("BASETYPE",response.content.consolidatedData.options);

    const equipmentLine = getModelFilter("EquipmentLine",response.content.consolidatedData.meta.modelFilter);

    const marketingCodeConfig: TrackingConfiguration = {
      CarlineId: response.content.consolidatedData.model_code.split("_")[1],
      CarlineName: response.content.consolidatedData.model_description,
      SalesgroupId: salesgroup.option.code.replace('SALESGROUP:',''),
      SalesgroupName: salesgroup.option.description,
      EquipmentlineName: equipmentLine.modelFilter.text,
      ModelId: baseType.option.code.replace('BASETYPE:',''),
      ModelName: type.option.description,
      ModelYear: modelyear.option.code.replace('MODELYEAR:',''),
    };

    return { marketingCodeConfig, response};
  } catch (error) {
    const errorMkc = new ErrorLoadStorageDataset(error);
    reportErrorSentry(errorMkc);
    const marketingCodeConfig: TrackingConfiguration = null;
    return { marketingCodeConfig };
  }
};

/**
 * Necesitamos retrasar la request ya que se llama desde muchos sitios al mismo tiempo, por lo que se producen llamadas
 * innecesarias. Esta función simplemente espera a que de tiempo a resolver el marketing code y se la siguiente llamada
 * ya encuentre el resultado de la request anterior en el objeto de historia.
 */
export const delayRequest = async () =>
  new Promise((resolve) => {
    setTimeout(resolve, 1000);
  });
