import { formData } from './';
import { FormDataGroup } from 'src/types';
import { OneFormActionTypes, RESET_STATE } from './types';
import { FormDataActionTypes, UPDATE_FIELDS, UPDATE_FORM } from './form-data-types';

export function formDataReducer(state: FormDataGroup = null, action: OneFormActionTypes | FormDataActionTypes): any {
  switch (action.type) {
    case RESET_STATE:
      return { ...formData };
    case UPDATE_FORM:
      return {
        fields: { ...state.fields, ...action.payload.fields },
        headers: { ...state.headers, ...action.payload.headers },
        flags: { ...state.flags, ...action.payload.flags },
      };
    case UPDATE_FIELDS:
      return {
        ...state,
        fields: { ...state.fields, ...action.payload },
      };
    default:
      return state;
  }
}
