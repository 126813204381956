import { styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TextInputExtended, SelectExtended, PaddingWrapper, InputWidthWrapper } from 'src/components';
import { OneFormState } from 'src/types';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { InputError } from './InputError';
import { callingCountriesOwn } from './';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
  Metadata,
  AsYouType,
} from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';

interface TelefonoProps {
  paginaConfirmacion?: boolean;
  ignoreValidation?: boolean;
  required?: boolean;
  notALayer?: boolean;
  onFocusHandler?: () => void;
  onChangeHandler?: () => void;
  hidePrefijo?: boolean;
  name?: string;
}

const DisplayWrapper = styled.div`
  display: flex;
`;

const PrefWrapper = styled.div<TelefonoProps>`
  label {
    width: 109px;
    @media screen and (min-width: 560px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '116px')};
    }
    @media screen and (min-width: 960px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid003)' : '120px')};
    }
    @media all and (min-width: 1280px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid002)' : '120px')};
    }
    @media screen and (min-width: 1600px) {
      /* padding-top: 0px; */
    }
    @media all and (min-width: 1920px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid002)' : '120px')};
    }
    @media all and (min-width: 2560px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid001)' : '120px')};
    }
  }
`;
const TelfWrapper = styled.div<TelefonoProps>`
  width: 100%;
  padding-left: ${(props) => (props.notALayer ? '20px' : '24px')};
  @media screen and (min-width: 560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '192px')};
    padding-left: ${(props) => (props.hidePrefijo ? '0px' : props.notALayer ? '24px' : '20px')};
  }
  @media screen and (min-width: 960px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '212px')};
  }
  @media all and (min-width: 1280px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '212px')};
  }
  @media all and (min-width: 1600px) {
    padding-left: ${(props) => (props.hidePrefijo ? '0px' : props.notALayer ? '28px' : '20px')};
  }
  @media all and (min-width: 1920px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid003)' : '212px')};
  }
  @media all and (min-width: 2560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid003)' : '212px')};
  }
`;

const TelefonoFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const regExpNonDigit = /\D/;
export const cellphoneIsInvalid = (value: string) =>
  regExpNonDigit.test(value) || !/[6|7|8|9][0-9]{8}/gi.test(value) || (value.length > 1 && value.length < 8);
// Uso la regexp porque por alguna razón si la saco del componente y la exporto para otros componentes da error y no lo reconoce correctamente.
export const TelefonoInput = ({ ignoreValidation, required, onFocusHandler, onChangeHandler, name }: TelefonoProps) => {
  const { cellphone, prefix, raceCellphone, formName } = useSelector((state: OneFormState) => state.formData.fields);
  const [telefonoError, setTelefonoError] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [telephone, setTelephone] = useState<string>(null);
  const { error, setError } = useOneFormContext();
  const [minLength, setMinLength] = useState(9);
  const [maxLength, setMaxLength] = useState(9);
  const { register, errors, setError: setErrorForm, clearError } = useFormContext();
  const regExp = /[6|7|8|9][0-9]{8}/gi;
  const dispatch = useDispatch();

  const [inputName, setInputName ] = useState('cellphone');

  useEffect(()=> {
    if(name) {
      setInputName(name);
    }
  }, [name]);

  const handleChange = (e: any) => {
    setTelephone(e.target.value);
    dispatch({ type: 'UPDATE_FIELDS', payload: { [inputName]: e.target.value } });

    if (isValidPhoneNumber(`${prefix}${e.target.value}`)) {
      setTelefonoError(false);
    } else {
      setTelefonoError(true);
    }
    setPristine(false);
  };

  useEffect(() => {
    if ((error && !pristine) || (error && !telephone)) {
      setTelefonoError(true);
    } else {
      setTelefonoError(false);
    }
  }, [error]);

  useEffect(() => {
    const metadata = new Metadata();
    const asYouType = new AsYouType();
    asYouType.reset();
    asYouType.input(prefix ? prefix : '+34');
    const possibleLengths = metadata.selectNumberingPlan(asYouType.country).possibleLengths();
    const maxLength = Math.max(...possibleLengths);
    const minLength = Math.min(...possibleLengths);
    setMaxLength(maxLength);
    setMinLength(minLength);
    handleChange({ target: { value: telephone } });
  }, [prefix]);

  useEffect(() => {
    if(formName === 'race' && raceCellphone) {
      setTelephone(raceCellphone);
    } else {
      if (cellphone) {
      setTelephone(cellphone);
    }
    }
    
    if (!prefix) {
      // En comerciales da error si no hay prefijo, por lo que lo seteamos directamente como +34 para que se pueda validar correctamente.
      dispatch({ type: 'UPDATE_FIELDS', payload: { prefix: '+34' } });
    }
  }, []);

  return (
    <TelefonoFlex>
      <TextInputExtended
        name={name ? name : 'cellphone'}
        label="Teléfono"
        isFloating
        maxLength={maxLength}
        innerRef={register({
          required: {
            value: ignoreValidation ? false : true,
            message: 'Requerido',
          },
          maxLength: { value: maxLength, message: `Máximo ${maxLength} dígitos` },
          minLength: { value: minLength, message: 'Faltan números' },
          validate: (value) => isValidPhoneNumber(`${prefix}${value}`),
          // pattern: {
          //   value: regExp,
          //   message: 'Solo se aceptan números',
          // },
        })}
        onChange={(e) => {
          handleChange(e);
          if (onChangeHandler) {
            onChangeHandler();
          }
        }}
        onFocus={() => {
          if (onFocusHandler) {
            onFocusHandler();
          }
        }}
        required={required}
      />

      {telephone && telephone.length > 1 && telefonoError && !isValidPhoneNumber(`${prefix}${telephone}`) ? (
        <InputError className="input-error">
          {validatePhoneNumberLength(`${prefix}${telephone}`) === 'TOO_LONG'
            ? `Máximo ${maxLength} dígitos`
            : validatePhoneNumberLength(`${prefix}${telephone}`) === 'TOO_SHORT'
            ? 'Faltan números'
            : validatePhoneNumberLength(`${prefix}${telephone}`) === 'NOT_A_NUMBER'
            ? 'Solo se aceptan números'
            : isValidPhoneNumber(`${prefix}${telephone}`)
            ? null
            : 'Número no válido'}
        </InputError>
      ) : null}
    </TelefonoFlex>
  );
};

export const PrefijoInput = ({ required, name }: TelefonoProps) => {
  const { prefix : prefixFromData, racePrefix: racePrefixFromData, formName} = useSelector((state: OneFormState) => state.formData.fields);
  const [prefix, setPrefix] = useState<string>(null);
  const [prefixData, setPrefixData] = useState<any[]>([]);
  const dispatch = useDispatch();

  const [inputName, setInputName ] = useState('prefix');

  useEffect(()=> {
    if(name) {
      setInputName(name);
    }
  }, [name]);

  useEffect(() => {
    if (prefix) {
      dispatch({ type: 'UPDATE_FIELDS', payload: { prefix } });
    }
  }, [prefix]);

  useEffect(() => {
    if(formName === 'race' && racePrefixFromData) {
      setPrefix(racePrefixFromData);
    } else if (prefixFromData) {
      setPrefix(prefixFromData);
    } else {
      setPrefix('+34');
      dispatch({ type: 'UPDATE_FIELDS', payload: { [inputName]: '+34' } });
    }

    const merged = [].concat(...callingCountriesOwn);
    const prefixData = merged
      .map((code) => code.replace(/ |\+/g, ''))
      .filter((elem, index, self) => index === self.indexOf(elem))
      .sort((a: any, b: any) => a - b)
      .map((code) => `+${code}`);

    setPrefixData(prefixData);
  }, []);

  function handleChangePrefix(event: any) {
    const newPrefix = event.currentTarget.value;
    setPrefix(newPrefix);
  }

  return (
    <SelectExtended
      name={name ? name  : 'prefijo'}
      label="Prefijo"
      value={prefix}
      required
      options={prefixData}
      handleChange={handleChangePrefix}
      onClick={() => null}
      updateFields
      setOption={setPrefix}
      isRequired={required}
    />
  );
};

export function TelefonoPrefijo(props: TelefonoProps) {
  return (
    <>
      {props.hidePrefijo ? null : (
        <PrefWrapper className="pref-wrapper" notALayer={props.notALayer}>
          <PrefijoInput {...props} />
        </PrefWrapper>
      )}
      <TelfWrapper hidePrefijo={props.hidePrefijo} notALayer={props.notALayer}>
        <TelefonoInput {...props} />
      </TelfWrapper>
    </>
  );
}

export function Telefono(props: TelefonoProps) {
  return (
    <PaddingWrapper paginaConfirmacion={props.paginaConfirmacion}>
      <InputWidthWrapper className="telf-wrapper" notALayer={props.notALayer}>
        <DisplayWrapper>
          <TelefonoPrefijo {...props} />
        </DisplayWrapper>
      </InputWidthWrapper>
    </PaddingWrapper>
  );
}
