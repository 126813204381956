/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import { styled } from '@volkswagen-onehub/components-core';

const SectionWrapper = styled.div`
  position: relative;
  height: 100%;

  @media all and (min-width: 960px) {
    height: max-content;
    min-height: calc(100vh - 164px);
    width: var(--size-grid012);
    display: flex;
    align-items: center;
    margin-top: 0;
  }
  @media all and (min-width: 1600px) {
    min-height: calc(100vh - 180px);
  }
`;

interface ImageWrapperProps {
  noMargins?: boolean;
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  img {
    width: var(--size-grid024);
    margin: auto;
    margin-top: ${(props) => props.noMargins ? '-32px' : '0'};
    height: auto;
    @media all and (min-width: 560px) {
      width: var(--size-grid024);
    }
    @media all and (min-width: 960px) {
      margin: auto;
      width: var(--size-grid012);
    }
    @media all and (min-width: 1920px) {
      width: var(--size-grid011);
    }
  }
`;

interface ImageProps {
  image: string;
  modelName: string;
  noMargins?: boolean;
}
export const ImageCarNoColorBG = (props: ImageProps) => {
  const { image, modelName } = props;

  return (
    <SectionWrapper className="section-right-wrapper" >
      <ImageWrapper noMargins>
        <img src={image} alt={modelName} />
      </ImageWrapper>
    </SectionWrapper>
  );
};
