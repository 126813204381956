import React, { useEffect, useState } from 'react';
import {
  Text,
  styled,
  ThemeProvider,
  BreakpointWrapper,
  Breakpoints,
  Container,
  ContainerPadding,
} from '@volkswagen-onehub/components-core';
import { CajaCTATrigger } from '.';
import { CarouselComponent } from 'src/components';
import { TriggerProps } from 'src/feature-app';
import imageOne from 'src/forms/DiaDeLaMadre/assets/turismos_campana_dia-de-la-madre_USP-1_1280x853px.jpg';
import imageTwo from 'src/forms/DiaDeLaMadre/assets/turismos_campana_dia-de-la-madre_USP-2_1280x768px.jpg';
import imageThree from 'src/forms/DiaDeLaMadre/assets/turismos_campana_dia-de-la-madre_USP-3_1280x768px.jpg';

const BackgroundBlue = styled.div`
  margin-right: var(--size-grid002);
  margin-top: calc(var(--size-grid002) * -1);
  background-color: #001e50;
  width: var(--size-grid022);
  position: absolute;
  height: 100%;
  z-index: -1;

  @media all and (min-width: 560px) {
    width: var(--size-grid022);
    margin-left: var(--size-grid002);
    margin-right: 0;
    margin-top: 0;
  }
  @media all and (min-width: 960px) {
    margin-left: var(--size-grid003);
    margin-right: var(--size-grid001);
    width: var(--size-grid020);
    height: calc(100% - 24px);
    margin-top: 24px;
  }
  @media all and (min-width: 1280px) {
    margin-left: var(--size-grid004);
    margin-right: var(--size-grid002);
    width: var(--size-grid018);
  }
  @media all and (min-width: 1600px) {
    margin-top: 28px;
    height: calc(100% - 28px);
  }
  @media all and (min-width: 1920px) {
    margin-left: var(--size-grid004);
    margin-right: var(--size-grid009);
    width: var(--size-grid011);
    margin-top: 72px;
    margin-bottom: 72px;
    height: calc(100% - 72px);
  }
`;

const ImageOneAndTextWrapper = styled.div`
  display: flex;
  margin-left: var(--size-grid001);

  @media all and (min-width: 960px) {
    margin-left: var(--size-grid002);
  }
  @media all and (min-width: 1280px) {
    margin-left: var(--size-grid003);
  }
  @media all and (min-width: 1920px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .image-one {
    width: var(--size-grid013);
    margin-right: var(--size-grid001);
    height: fit-content;
    @media all and (min-width: 960px) {
      width: var(--size-grid012);
      margin-bottom: var(--size-grid002);
    }
    @media all and (min-width: 1280px) {
      width: var(--size-grid011);
    }
    @media all and (min-width: 1920px) {
      width: var(--size-grid010);
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

const ImageTwoWrapper = styled.div`
  margin-top: calc(var(--size-grid002) * -1);
  margin-left: var(--size-grid001);

  @media all and (min-width: 960px) {
    margin-left: var(--size-grid002);
    margin-top: calc((var(--size-grid001) + 20px) * -1);
  }
  @media all and (min-width: 1280px) {
    margin-left: var(--size-grid003);
    margin-top: calc(var(--size-grid001) * -1);
  }
  @media all and (min-width: 1920px) {
    margin-left: var(--size-grid001);
    margin-top: 0;
  }

  .image-two {
    width: var(--size-grid010);
    margin-right: var(--size-grid002);
    @media all and (min-width: 960px) {
      width: var(--size-grid009);
    }
    @media all and (min-width: 1280px) {
      width: var(--size-grid008);
    }
    @media all and (min-width: 1920px) {
      width: var(--size-grid007);
      margin-right: 0;
    }
  }
`;

const ImageThreeWrapper = styled.div`
  margin-top: calc(var(--size-grid002) * -1);

  @media all and (min-width: 960px) {
    margin-top: calc((var(--size-grid001) + 20px) * -1);
  }
  @media all and (min-width: 1280px) {
    margin-top: calc(var(--size-grid001) * -1);
  }
  @media all and (min-width: 1920px) {
    margin-top: calc((var(--size-grid003) + 20px) * -1);
    margin-left: var(--size-grid012);
  }

  .image-three {
    width: var(--size-grid010);
    @media all and (min-width: 960px) {
      width: var(--size-grid009);
    }
    @media all and (min-width: 1280px) {
      width: var(--size-grid008);
    }
    @media all and (min-width: 1920px) {
      width: var(--size-grid007);
    }
  }
`;

const BottomImagesWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const RelativeWrapper = styled.div`
  position: relative;

  @media all and (min-width: 1920px) {
    display: flex;
  }
`;

const imageArray = [
  {
    src: 'imageOne',
    name: 'turismos_campana_dia-de-la-madre',
    className: 'image-one',
  },
  {
    src: 'imageTwo',
    name: 'turismos_campana_dia-de-la-madre',
    className: 'image-two',
  },
  {
    src: 'imageThree',
    name: 'turismos_campana_dia-de-la-madre',
    className: 'image-three',
  },
];

const CarouselImageWrapper = styled.div`
  padding: 0 2.5%;
  width: 83.2vw;
  height: 83.2vw;
  overflow: hidden;

  .wrapper-image-one {
    padding-left: 6.32%;
  }

  .image-one {
    transform: scale(0.6) translate(-82%, -54%);
    @media all and (min-width: 450px) {
      transform: scale(0.6) translate(-71%, -47%);
    }
  }
  .image-two {
    transform: scale(0.5) translate(-65%, -63%);
    @media all and (min-width: 450px) {
      transform: scale(0.6) translate(-50%, -37%);
    }
  }
  .image-three {
    transform: scale(0.5) translate(-62%, -63%);
    @media all and (min-width: 450px) {
      transform: scale(0.6) translate(-50%, -37%);
    }
  }
`;

const MovileImageWrapper = styled.div`
  margin: 0 auto;
  width: var(--size-grid020);
  height: var(--size-grid020);
  overflow: hidden;
  margin-left: var(--size-grid002);
  .wrapper-image-one {
  }

  .image-one {
    transform: scale(0.6) translate(-82%, -54%);
    @media all and (min-width: 450px) {
      transform: scale(0.6) translate(-71%, -47%);
    }
  }
`;

export const DisplayImagenesYCTATrigger = (props: TriggerProps) => {
  const { setSelectedJourney, setCTALabel } = props;

  const renderList = (imageArray: Array<any>) => {
    return imageArray.map((image, ind) => {
      return (
        <CarouselImageWrapper className={`wrapper-${image.className}`} key={ind}>
          <div style={{ overflow: 'hidden' }}>
            <img src={image.src} alt={image.name} className={image.className} />
          </div>
        </CarouselImageWrapper>
      );
    });
  };

  return (
    <div id="cta-trigger-dia-madre">
      <BreakpointWrapper max={Breakpoints.b560}>
        <MovileImageWrapper>
          <div style={{ overflow: 'hidden' }}>
            <img
              src="https://cdn.volkswagen.es/repository/app/vw-oneform/assets/images/turismos_campana_dia-de-la-madre_USP-1_1280x853px-min.jpg"
              alt="turismos_campana_dia-de-la-madre"
              className="image-one"
            />
          </div>
        </MovileImageWrapper>
        <RelativeWrapper>
          <BackgroundBlue />
          <CajaCTATrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />
        </RelativeWrapper>
      </BreakpointWrapper>
      {/* maquetación breakpoints a partir de 560*/}
      <BreakpointWrapper min={Breakpoints.b560}>
        <RelativeWrapper>
          <BackgroundBlue />
          <ImageOneAndTextWrapper className="imageOne-text-wrapper">
            <img
              src="https://cdn.volkswagen.es/repository/app/vw-oneform/assets/images/turismos_campana_dia-de-la-madre_USP-1_1280x853px-min.jpg"
              alt="turismos_campana_dia-de-la-madre"
              className="image-one"
            />
            <CajaCTATrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel} />
          </ImageOneAndTextWrapper>
          <BreakpointWrapper min={Breakpoints.b1920}>
            <ImageTwoWrapper>
              <img
                src="https://cdn.volkswagen.es/repository/app/vw-oneform/assets/images/turismos_campana_dia-de-la-madre_USP-2_1280x768px-min.jpg"
                alt="turismos_campana_dia-de-la-madre"
                className="image-two"
              />
            </ImageTwoWrapper>
          </BreakpointWrapper>
        </RelativeWrapper>
        <BreakpointWrapper min={Breakpoints.b560} max={Breakpoints.b1920}>
          <BottomImagesWrapper>
            <ImageTwoWrapper>
              <img
                src="https://cdn.volkswagen.es/repository/app/vw-oneform/assets/images/turismos_campana_dia-de-la-madre_USP-2_1280x768px-min.jpg"
                alt="turismos_campana_dia-de-la-madre"
                className="image-two"
              />
            </ImageTwoWrapper>
            <ImageThreeWrapper>
              <img
                src="https://cdn.volkswagen.es/repository/app/vw-oneform/assets/images/turismos_campana_dia-de-la-madre_USP-3_1280x768px-min.jpg"
                alt="turismos_campana_dia-de-la-madre"
                className="image-three"
              />
            </ImageThreeWrapper>
          </BottomImagesWrapper>
        </BreakpointWrapper>
        <BreakpointWrapper min={Breakpoints.b1920}>
          <ImageThreeWrapper>
            <img
              src="https://cdn.volkswagen.es/repository/app/vw-oneform/assets/images/turismos_campana_dia-de-la-madre_USP-3_1280x768px-min.jpg"
              alt="turismos_campana_dia-de-la-madre"
              className="image-three"
            />
          </ImageThreeWrapper>
        </BreakpointWrapper>
      </BreakpointWrapper>
    </div>
  );
};
