import {
  Text,
  styled,
  Container,
  ContainerPadding,
  Breakpoints,
  BreakpointWrapper,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { RatingStarWrapper } from '../styleComponents/RatingStarWrapper';
import React, { useEffect, useState } from 'react';
import { StarFilled, StarOutline } from 'src/icons-core-imports';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { OneFormState } from 'src/types';
import { InputButton } from 'src/components';

const StarText = styled.div`
  display: flex;
  justify-content: center;
  div{
    position: absolute;
  }
`;

const TextMini = styled.div`
  color: #000000;
  font-size: 3vw;
  margin-top: 2px;
  @media all and (min-width: 400px){
    font-size: 12px;
    margin-top: 0;
  }
`;

interface RatingStarsProps {
  name?: string;
  nextOnClick?: boolean;
  preSelectedRate?: number;
  stars?: number;
  noRequired?: boolean;
  isPrevStep?: boolean;
  textBad?: string;
  textGood?: string;
  textUnderStars?: boolean;
  starsText?: string[];
  CTASinRespuesta?: boolean;
}

export const valuesSinRespuesta = () => {
  return {
    valueSinRespuesta: -1,
    labelSinRespuesta: 'Sin respuesta'
  };
};

export const RatingStars = (props: RatingStarsProps) => {
  const {
    name,
    nextOnClick,
    stars,
    noRequired,
    isPrevStep,
    preSelectedRate,
    textBad,
    textGood,
    textUnderStars,
    starsText,
    CTASinRespuesta
  } = props;
  const { handleNextStep } = useOneFormContext();
  const { register, setValue } = useFormContext();
  const [ rateValue, setRateValue ] = useState<number>(preSelectedRate ? preSelectedRate : null);
  const dispatch = useDispatch();
  const { formData } = useSelector((state: OneFormState) => state);
  
  const numberOfStars = stars ? stars : 5;
  const { valueSinRespuesta, labelSinRespuesta } = valuesSinRespuesta();

  const handleField = () => {
    // Hack para solucionar el problema que se da en el caso de elegir Sin respuesta y volver hacia atrás.
    if (formData.fields[name]) {
      if (formData.fields[name] == valueSinRespuesta) {
        setTimeout(() => {
          setValue(name, labelSinRespuesta);
        }, 100);
        setRateValue(valueSinRespuesta);
      } else {
        setRateValue(Number(formData.fields[name]));
      }
    }
  };

  useEffect(() => {
    handleField();
  }, []);

  const handleClick = (rateValue: number, e: any) => {
    setRateValue(rateValue);
    dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: rateValue } });

    if (nextOnClick) {
      setTimeout(() => {
        handleNextStep(e);
      }, 0);
    }
  };

  const renderStarsText = (starsText, index: number) => {
    if (starsText[index] === undefined) {
      return <hr />
    }
    else return (
      <StarText>
        <BreakpointWrapper min={Breakpoints.b560}>
          <Text appearance={TokenTextAppearance.copy100} staticSize>{starsText[index]}</Text>
        </BreakpointWrapper>
        <BreakpointWrapper max={Breakpoints.b560}>
            <TextMini>{starsText[index]}</TextMini>
        </BreakpointWrapper>
      </StarText>
    );
  };

  const renderStars = (rateValue: number) => {
    const stars = [];
    for (let i = 0; i < numberOfStars; i++) {
        stars.push(
          <RatingStarWrapper
            className={ rateValue > i ? 'filled' : 'outline' }
            key={i}
            onClick={(e: any) => {
              e.preventDefault();
              if (isPrevStep) {
                return;
              } else {
                handleClick(i + 1, e);
              }
            }}
            isPrevStep={isPrevStep}
            textUnderStars={textUnderStars}
          >
            { rateValue > i ? <StarFilled /> : <StarOutline variant="large" /> }
            {textUnderStars &&
              (
                renderStarsText(starsText, i)
              )
            }
          </RatingStarWrapper>
        );
    }

    return stars;
  };

  return (
    <div onMouseMove={handleField} onMouseOut={handleField} onMouseEnter={handleField}>
      {isPrevStep || textUnderStars ? null : (
        <Container padding={{ bottom: ContainerPadding.dynamic0100, top: ContainerPadding.dynamic0050 }}>
          <Text appearance={TokenTextAppearance.copy200} staticSize>
            Valora del 1 al 5, siendo 1 {textBad} y 5 totalmente{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              {textGood}
              <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>.
            </span>
          </Text>
        </Container>
      )}
      <div style={{ display: 'flex' }}>
        { renderStars(rateValue) }
      </div>
      {isPrevStep ? null : (
        <input
          type="hidden"
          name={name}
          value={typeof rateValue != 'undefined' ? rateValue : null}
          ref={register({ required: !noRequired })}
        />
      )}
      {CTASinRespuesta ? (
        <div style={{ paddingTop: '24px' }}>
          <InputButton
            selectedValue={formData.fields[name] == valueSinRespuesta ? labelSinRespuesta : null}
            tag="button"
            name={name}
            label={labelSinRespuesta}
            key={10}
            onClick={(e) => {
              if (isPrevStep) {
                return;
              } else {
                handleClick(valueSinRespuesta, e);
              }
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
