import React from 'react';
import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerPadding,
  styled,
  Text,
  Image,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { Checks } from './Checks';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';

const LayoutWrapper = styled.div`
  @media all and (min-width: 960px) {
    padding-left: var(--size-grid002);
    display: flex;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  padding-left: var(--size-grid002);
  padding-right: var(--size-grid002);
  @media all and (min-width: 960px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: var(--size-grid012);
    padding-left: var(--size-grid002);
    padding-right: 0;
    padding-top: 0;
  }
  @media all and (min-width: 1280px) {
    padding-left: var(--size-grid003);
  }
  @media all and (min-width: 1600px) {
    padding-left: var(--size-grid002);
  }
`;

interface QuestionWithImageProps {
  answers: string[];
  correctAnswer?: string;
  imageSrc: string;
  index: number;
  questionName: string;
  title: JSX.Element | string;
}

export const QuestionWithImage = (props: QuestionWithImageProps) => {
  const {
    answers,
    correctAnswer,
    imageSrc,
    index,
    questionName,
    title,
  } = props;
  const height = useWindowHeight();
  const width = useWindowWidth();

  return (
    <LayoutWrapper className='layout-wrapper' >
      {
        height <= 600 ? null : (
          <BreakpointWrapper max={Breakpoints.b960}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Image style={{ maxWidth: "464px", paddingBottom: "30px" }} objectFit={width <= 464 ? 'contain' : 'none'} src={imageSrc} alt="" />
            </div>
          </BreakpointWrapper>
        )
      }
      <BreakpointWrapper min={Breakpoints.b960}>
        <Image style={{ maxWidth: "464px" }} src={imageSrc} alt="imagen de volkswagen" />
      </BreakpointWrapper>
      <InfoWrapper className='info-wrapper'>
        <Container padding={{ bottom: ContainerPadding.static350 }} wrap={'always'}>
          {index === 0 && <Text appearance={TokenTextAppearance.copy200} bold>Y... ¡que empiece el test!</Text>}
          <Text appearance={TokenTextAppearance.headline400} staticSize>
            <>
              {title}
            </>
          </Text>
          <Checks questionName={questionName} answers={answers} correctAnswer={correctAnswer} />
        </Container>
      </InfoWrapper>
    </LayoutWrapper>
  )
};
