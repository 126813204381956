import axios, {AxiosResponse} from 'axios';
import {
	getMolecularCdnUrl,
	getOneformBFFCdnApiKey,
	getOneformBFFCdnUrl,
	getVwEventosEntregaIdealUrl,
	reportErrorSentry,
} from 'src/bff';
import {useIsComerciales} from 'src/feature-app';
import {
	DomainAttributesValues,
	InstallationResponse,
	InstallationEntregaIdealResponse,
	EntregaIdealHorarioResponse,
} from 'src/types';

export const getInstallations = async (
	token?: string,
	domain?: string,
): Promise<{attributes: DomainAttributesValues[]}[]> => {
	const isComerciales = useIsComerciales();

	const requestToken =
		!token || testToken() ? (isComerciales ? 'LCV_DDB_FRM_LEM_REC_LEM' : 'VW_DDB_FRM_LEM_REC_LEM') : token;
	const requestDomain = !domain
		? isComerciales
			? 'LCV_SF_LEAD_INSTALLATION'
			: 'VOLKSWAGEN_SF_LEAD_INSTALLATION'
		: domain;
	const oneformCdn = getOneformBFFCdnUrl();
	const oneformBFFApiKey = getOneformBFFCdnApiKey();

	try {
		const installations: AxiosResponse<InstallationResponse> = await axios.get(
			`${oneformCdn}/ndps/domain?token=${requestToken}&domain=${requestDomain}`,
			{
				timeout: 10000,
				headers: {
					'X-Api-Key': oneformBFFApiKey,
				},
			},
		);
		return installations.data.content;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const getInstallationsLegacy = async (
	token?: string,
	addPosventa?: boolean,
): Promise<{attributes: DomainAttributesValues[]}[]> => {
	const requestToken = !token || testToken() ? 'VW_DDB_FRM_LEM_REC_LEM' : token;
	const domain = addPosventa ? 'Installations_VW_All' : 'Installation';
	const molecularCdnUrl = getMolecularCdnUrl();

	try {
		const installations: AxiosResponse<InstallationResponse> = await axios.get(
			`${molecularCdnUrl}/request-dps-domain.php?token=${requestToken}&domain=${domain}`,
		);
		return installations.data.content;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

const testToken = () => {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get('test') && urlParams.get('test').includes('TOKEN');
};

export const getInstallationsEntregaIdeal = async (): Promise<InstallationEntregaIdealResponse[]> => {
	const url = getVwEventosEntregaIdealUrl();

	try {
		const response: AxiosResponse<InstallationEntregaIdealResponse> = await axios.get(`${url}/instalaciones`);
		return response.data.instalaciones;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const getHorariosInstallation = async (
	kvps: string,
	year: string | number,
	month: string | number,
): Promise<EntregaIdealHorarioResponse[]> => {
	const url = getVwEventosEntregaIdealUrl();

	try {
		const response: AxiosResponse<[]> = await axios.get(`${url}/horario/${kvps}/${year}/${month}`);
		return response.data;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};
