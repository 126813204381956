
import { ServiceConfigProviderV1 } from '@volkswagen-onehub/service-config-provider';
import { useFeatureServices } from './use-feature-services';
import { AuthServiceProviderV2, IAuthServiceV2 } from '@volkswagen-onehub/authservice';

export const getAuthService = (serviceConfig: ServiceConfigProviderV1, authServiceProvider:AuthServiceProviderV2 ) => {
    const endpoint = serviceConfig.configs['customer-profile']?.urlOrigin;    
    const authService: IAuthServiceV2 = authServiceProvider?.register('market');

    
    return authService;
};

export const useGetAuthService = () => {
    const serviceConfig = useFeatureServices()['service-config-provider'];
    const authService = useFeatureServices()['vw:authService'] as AuthServiceProviderV2;

    return getAuthService(serviceConfig, authService);
};
