import { Steps } from 'src/types';

export const getIndexInSteps = (steps: Steps[], stepName: string) => {
  if (steps?.length === 0 || !stepName) {
    return;
  }
  return steps.findIndex((step) => step.name === stepName);
};

/**
 * En caso de haber eliminado un step hay que volver a añadirlo de nuevo con los steps por defecto y llamar a la función handleNextStep ya que 
 * ésta se llama antes de tiempo y realiza el cálculo con los steps sin actualizar.
 * @param steps 
 * @param stepName 
 */
export const addRemovedStep = (initialSteps: Steps[], actualSteps: Steps[], stepName: string, screenIndex: number) => {
  const addedStep = getIndexInSteps(initialSteps, stepName);
  actualSteps.splice(screenIndex + 1, 0, initialSteps[addedStep]);
  return actualSteps;
};


export const removeStep = (steps: Steps[], stepName: string): Steps[] => {
  const indexToSplice = getIndexInSteps(steps, stepName);
  
  if (indexToSplice !== -1) {
    steps.splice(indexToSplice, 1);
  }
  return steps;
};

export const removeMultipleSteps = (steps: Steps[], stepNames: string[]): Steps[] => {
  const indexesToSplice = stepNames.map(stepName => getIndexInSteps(steps, stepName));

  const newSteps = steps.filter((step, index) => {
    return indexesToSplice.indexOf(index) === -1;
  });

  return newSteps;
};

export const removeMultipleStepsAndResetScreenIndex = (steps: Steps[], stepNames: string[]): Steps[] => {
  const newSteps = removeMultipleSteps(steps, stepNames);

  return resetStepsScreenIndex(newSteps);
};

export const resetStepsScreenIndex = (steps: Steps[]) => {
  return steps.map((step, index) => {
    step.screenIndex = index;
    return step;
  });
};

/**
 * Utilizamos esta función cuando queremos eliminar muchos steps seguidos, cuando por ejemplo hay 
 * una pregunta que en caso negativo elimina una serie de preguntas condicionales. 
 * Hay que pasar el firstStep como el step que desencadena la pregunta y el lastStep como el primer step que tendremos que mostrar
 * después de eliminar.
 * @param steps 
 * @param firstStep Este step no se eliminará, se eliminará el siguiente.
 * @param lastStep Este step no se eliminará, se eliminará el anterior.
 */
export const getArrayOfStepsNames = (steps: Steps[], firstStep: string, lastStep: string) => {
  const indexOfFirstStep = getIndexInSteps(steps, firstStep);
  const indexOfLastStep = getIndexInSteps(steps, lastStep);

  if (indexOfFirstStep !== -1 && indexOfLastStep !== -1) {
    const newSteps = [...steps];
    const stepsToRemove = newSteps.slice(indexOfFirstStep + 1, indexOfLastStep);
    const arrayOfStepsNames = stepsToRemove.map(step => step.name);
    return arrayOfStepsNames;
  }
  return [];
};
