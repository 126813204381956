import { getModelFilter, getOption } from 'src/bff/get-marketing-code';
import { FormDataGroup } from 'src/types';
import { isOfertasForm } from '../LeadGenericoForm';

export const getConfiguradorData = async (formData: FormDataGroup) => {
  const { fields } = formData;
  const { marketingCodeData, marketingCodeResponse } = fields;

  if (!marketingCodeResponse) return null;
  //const price = await getPriceDataWithMarketingCode(marketingCodeResponse);

  const type = getOption("TYPE",marketingCodeResponse?.content.consolidatedData.options);
  const salesgroup = getOption("SALESGROUP",marketingCodeResponse?.content.consolidatedData.options);
  const trimline = getModelFilter("EquipmentLine",marketingCodeResponse?.content.consolidatedData.meta.modelFilter);

  let configuradorData = {
    'fields[VEH_INTEREST_ID]': marketingCodeResponse?.content.consolidatedData.model_code.split("_")[1],
    'fields[VEH_INTEREST_MODEL]': marketingCodeResponse?.content.consolidatedData.model_description,
    'fields[VEH_INTEREST_SALESGROUP]': salesgroup.option.description,
    'fields[VEH_INTEREST_SALESGROUP_ID]': salesgroup.option.code.split(":")[1],
    'fields[VEH_INTEREST_TRIM_LINE]': trimline.modelFilter.text,
    'fields[VEH_INTEREST_DETAILS]': type.option.description,
    // 'fields[VEH_INTEREST_MORE_INFO]': "Color pintura exterior: " + marketingCodeResponse?.consolidatedData?.exterior?.name, // Pendiente recuperar color exterior
    'fields[VEH_INTEREST_CONFIGURATION_CODE]': marketingCodeData?.marketingCode,
    /*
    'fields[VEH_INTEREST_OFFER_PRICE]': price?.body?.amount
      ? `Precio final de la configuración: ${price?.body?.amount}Eur`
      : undefined,
    */
  };

  //Si es un form de ofertas no es relevante el precio de la configuración ni su Marketing Code
  if(isOfertasForm(fields.formName)){
    delete configuradorData['fields[VEH_INTEREST_CONFIGURATION_CODE]'];
    // delete configuradorData['fields[VEH_INTEREST_OFFER_PRICE]'];
  }

  return configuradorData;
};

export const getConfiguradorComercialesData = async (formData: FormDataGroup) => {
  const { fields } = formData;
  const { marketingCodeData, marketingCodeResponse } = fields;

  if (!marketingCodeResponse) return null;
  //const price = await getPriceDataWithMarketingCode(marketingCodeResponse);
  console.log(marketingCodeResponse);

  const type = getOption("TYPE",marketingCodeResponse?.content.consolidatedData.options);
  const salesgroup = getOption("SALESGROUP",marketingCodeResponse?.content.consolidatedData.options);
  const trimline = getModelFilter("EquipmentLine",marketingCodeResponse?.content.consolidatedData.meta.modelFilter);


  let configuradorData = {
    'fields[VEH_INTEREST_ID]': `${marketingCodeResponse?.content.consolidatedData.model_code.split("_")[1]}_${salesgroup?.option.code.replace('SALESGROUP:','')}`,
    'fields[VEH_INTEREST_MODEL]': marketingCodeResponse?.content.consolidatedData.model_description,
    'fields[VEH_INTEREST_SALESGROUP]': salesgroup.option.description,
    'fields[VEH_INTEREST_SALESGROUP_ID]': salesgroup.option.code.split(":")[1],
    'fields[VEH_INTEREST_TRIM_LINE]': trimline.modelFilter.text,
    'fields[VEH_INTEREST_DETAILS]': type.option.description,
    // 'fields[VEH_INTEREST_MORE_INFO]': "Color pintura exterior: " + marketingCodeResponse?.consolidatedData?.exterior?.name, // Pendiente recuperar color exterior
    'fields[VEH_INTEREST_CONFIGURATION_CODE]': marketingCodeData?.marketingCode,
    /*
    'fields[VEH_INTEREST_OFFER_PRICE]': price?.body?.amount
      ? `Precio final de la configuración: ${price?.body?.amount}Eur`
      : undefined,
    */
  };

  //Si es un form de ofertas no es relevante el precio de la configuración ni su Marketing Code
  if(isOfertasForm(fields.formName)){
    delete configuradorData['fields[VEH_INTEREST_CONFIGURATION_CODE]'];
    // delete configuradorData['fields[VEH_INTEREST_OFFER_PRICE]'];
  }

  return configuradorData;
};
