export const getLeadAttributes = (date?: string) => {
	return [
		{
			name: 'ID',
			value: 'LEAD',
		},
		{
			name: 'attributes',
			value:
				'es.vged.dps.salesforce.rest.entities.SfAttributesRest@133a815a[type=Leads_Prospects__c,url=/services/data/v51.0/sobjects/Leads_Prospects__c/a0d1n00000gvFgzAAE]',
		},
		{
			name: 'Id',
			value: 'a0d1n00000gvFgzAAE',
		},
		{
			name: 'OwnerId',
			value: '0050Y000001UE3gQAG',
		},
		{
			name: 'IsDeleted',
			value: 'false',
		},
		{
			name: 'Name',
			value: '8401294-VW_DDB_FRM_LEM_REC_LEM-36V',
		},
		{
			name: 'CreatedDate',
			value: date ? date : '2023-11-14 18:44:24',
		},
		{
			name: 'CreatedById',
			value: '0050Y000001UE3gQAG',
		},
		{
			name: 'LastModifiedDate',
			value: '2021-05-05T05:55:28.000+0000',
		},
		{
			name: 'LastModifiedById',
			value: '0050Y000001UE3gQAG',
		},
		{
			name: 'SystemModstamp',
			value: '2021-05-05T05:55:28.000+0000',
		},
		{
			name: 'LastViewedDate',
			value: 'null',
		},
		{
			name: 'LastReferencedDate',
			value: 'null',
		},
		{
			name: 'Brand__c',
			value: 'Volkswagen',
		},
		{
			name: 'DESC_Dealer_Code__c',
			value: 'MERKAMOTOR TORTOSA, S.A.',
		},
		{
			name: 'DESC_Model__c',
			value: 'T-Roc',
		},
		{
			name: 'ID_Person_DIM_IMP__c',
			value: '8401294',
		},
		{
			name: 'IS_Prospect__c',
			value: 'false',
		},
		{
			name: 'Is_AfterSales__c',
			value: 'false',
		},
		{
			name: 'Is_Company__c',
			value: 'false',
		},
		{
			name: 'Is_lead__c',
			value: 'true',
		},
		{
			name: 'LSSI__c',
			value: 'NO PERMITE',
		},
		{
			name: 'LastCreatedDate__c',
			value: '2021-05-02T23:50:00.000+0000',
		},
		{
			name: 'TXT_Email__c',
			value: 'test@test.com',
		},
		{
			name: 'TXT_Postal_Code__c',
			value: '00000',
		},
		{
			name: 'TXT_Province__c',
			value: 'Barcelona',
		},
		{
			name: 'Token__c',
			value: 'VW_DDB_FRM_LEM_REC_LEM',
		},
		{
			name: 'Total__c',
			value: 'null',
		},
		{
			name: 'Total_last_1_year__c',
			value: 'null',
		},
		{
			name: 'Total_last_2_year__c',
			value: 'null',
		},
		{
			name: 'Total_last_3_year__c',
			value: 'null',
		},
		{
			name: 'Total_last_4_year__c',
			value: 'null',
		},
		{
			name: 'VN_VO__c',
			value: 'VN',
		},
		{
			name: 'LKP_PersonAccount__c',
			value: '0011n00002e8sUnAAI',
		},
		{
			name: 'External_Id_Climapro__c',
			value: 'DPS-000000000000001052940',
		},
		{
			name: 'NUM_COD_INTEREST_CARLINE__c',
			value: '30365',
		},
		{
			name: 'TXT_DESC_DEALER_CODE__c',
			value: '03278',
		},
		{
			name: 'IsQualified__c',
			value: 'false',
		},
	];
};
