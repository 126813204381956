import { CarlineBase } from 'src/types';
import { getSalesgroupsFromCarlines } from '.';
/**
 * Devuelve únicamente los carlines preseleccionados desde el cms en una array con carlineCodes tipo [30155, 30205].
 * @param carlines
 * @param faContentCarlines
 * @returns
 */
export const filterPreselectedFaContentSalesgroups = (carlines: CarlineBase[], faContentSalesgroups: number[]) => {
  const salesgroups = getSalesgroupsFromCarlines(carlines);
  return faContentSalesgroups
    .map((salesgroupCode) => salesgroups.find((salesgroup) => salesgroup.code == salesgroupCode))
    .filter((c) => c !== undefined);
};
