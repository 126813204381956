import { Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { LocateUserFullScreen, MultipleCTASelection } from 'src/components';
import {
  LayerManagerWrapper,
  useFeatureAppEnvironment,
  useFeatureServices,
  useOneFormContext,
} from 'src/feature-app';
import { RenderController, OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import LoadScriptInstance from 'src/feature-app/NewMap/Loadscript';
import { TIME_FOR_TRANSFORM } from 'src/globals';
import { OneFormState, Steps } from 'src/types';
import { LocateUserLeadsInPage, renderTitle } from 'src/forms/LeadsInPage';
import { JourneyLeadsInPageWrapper } from 'src/forms/LeadsInPage';
import { InteractionLayerSizeV2, ShimBackgroundTypeV2 } from '@volkswagen-onehub/layer-manager';
import { CTAsHorario } from 'src/feature-app/InputsValues';

const getTitle = (data) => {
  if (data) {
    return;
  } else {
    return (
      <Text appearance={TokenTextAppearance.headline300}>
          {' '}
        <Text bold>¿Qué experto</Text> quieres que se{' '}
        <span style={{ whiteSpace: 'nowrap' }}>
          encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
        </span>
      </Text>
    );
  }
};

const getLocatePage = (data) => {
  if (data) {
    return <LocateUserLeadsInPage />;
  } else {
    return <OneElementTemplateFullScreen allowOverflowingContent element={<LocateUserFullScreen autoWidth />} />;
  }
};

enum MapaLeadsInPageSteps {
  Dealer,
  Horario,
  SeleccionCita,
  SeleccionFecha,
}

interface MapaLeadsInPageProps {
  showHorario?: boolean;
  formName: string;
}

const isLastScreen = (steps: Steps[], multiStepScreenIndex: number) => steps.length - 1 === multiStepScreenIndex;
const isFirstScreen = (multiStepScreenIndex: number) => multiStepScreenIndex === 0;

export function MapaLeadsInPage(props: MapaLeadsInPageProps) {
  const { formName, showHorario } = props;
  const dispatch = useDispatch();
  const store = useStore();
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { marketingCodeData, carlineData } = formData.fields;
  const { firstMapLoad, multiStepScreenIndex, navigationMovingForward } = formInfo;
  const layerManager = useFeatureServices()['layer-manager'];
  const env = useFeatureAppEnvironment();
  const {
    handleScreenChange,
    // setWaitForNextStep,
    setNextMultiStep,
    setNextMultiStepScreenIndex,
    setPreviousMultiStep,
    setWaitForPreviousStep,
    waitForPreviousStep,
    // waitForNextStep,
    setIsFullScreen,
    setShowPreviousStep,
    nextStepEventEmitter,
  } = useOneFormContext();

  const mapStep = showHorario ? 2 : 1;

  let stepsHorario: Steps[] = [
    {
      title: getTitle(marketingCodeData ? marketingCodeData : carlineData),
      fields: getLocatePage(marketingCodeData ? marketingCodeData : carlineData),
      screenIndex: MapaLeadsInPageSteps.Dealer,
      name: 'MapSearch',
      outputs: ['location'],
      outputsText: ['location'],
      showOverflow: true,
    },
    {
      screenIndex: MapaLeadsInPageSteps.Horario,
      name: 'MapSlotPref',
      title: (
        <>
          <Text bold>¿Cúando</Text> prefieres pasar por el{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            punto de venta?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsHorario} name="horario" nextOnClick />}
        />
      ),
      outputs: ['horario'],
      outputsText: ['horario'],
    },
    {
      screenIndex: MapaLeadsInPageSteps.SeleccionCita,
      name: 'MapSelect',
      alternateTitle: (
        <Text>
          <Text bold>¿Qué experto</Text> quieres que se{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      ),
      title: <></>,
      fields: <JourneyLeadsInPageWrapper />,
      outputs: ['installation'],
      outputsText: ['installation'],
      fullScreen: true,
      multiStepLastScreen: true,
      hidePreviousStep: true,
    },
  ];

  let stepsWithoutHorario: Steps[] = [
    {
      title: getTitle(marketingCodeData ? marketingCodeData : carlineData),
      fields: getLocatePage(marketingCodeData ? marketingCodeData : carlineData),
      screenIndex: MapaLeadsInPageSteps.Dealer,
      name: 'MapSearch',
      outputs: ['location'],
      outputsText: ['location'],
      showOverflow: true,
    },
    {
      screenIndex: 1,
      name: 'MapSelect',
      alternateTitle: (
        <Text>
          <Text bold>¿Qué experto</Text> quieres que se{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      ),
      title: <></>,
      fields: <JourneyLeadsInPageWrapper />,
      outputs: ['installation'],
      outputsText: ['installation'],
      fullScreen: true,
      multiStepLastScreen: true,
      hidePreviousStep: true,
    },
  ];
  const steps = showHorario ? stepsHorario : stepsWithoutHorario;

  const [selectedStep, setSelectedStep] = useState<Steps>(null);
  const layerRef = useRef(null);
  /**
   * Hay que esperar a que se cree el mapa de google para tener acceso a window.google
   */
  const onMapLoad = () => {
    if (multiStepScreenIndex === 0) {
      // handleFirstLoad();
    }
  };

  const handleFirstLoad = async () => {
    if (firstMapLoad) {
      // Inicialización
      initializeMultiStep();
    } else {
      if (navigationMovingForward || navigationMovingForward === null || navigationMovingForward === undefined) {
        // Inicialización cuando se va hacia adelante una vez inicializado el mapa o después de cerrar el layer y abrir en el mapa.
        initializeMultiStep();
      } else {
        // En caso de que estemos en la última pantalla, desactivamos el wait for next step.
        const newWaitForNextStep = !isLastScreen(steps, multiStepScreenIndex);
        // Lo mismo para previous, solo le desactivamos si estamos en la primera.
        const newWaitForPreviousStep = !isFirstScreen(multiStepScreenIndex);

        updateWaitForNextAndPreviousStep(newWaitForNextStep, newWaitForPreviousStep);
        updateScreenChangeVariables(multiStepScreenIndex);
      }
    }
    setNextMultiStep(() => nextMapaNoCompra);
    setPreviousMultiStep(() => previousMapaNoCompra);
  };

  const initializeMultiStep = () => {
    updateWaitForNextAndPreviousStep(true, false);
    updateScreenChangeVariables(0);
  };

  const updateWaitForNextAndPreviousStep = (waitForNextStep: boolean, waitForPreviousStep: boolean) => {
    if (waitForNextStep !== null) {
      dispatch({ type: 'UPDATE_WAIT_FOR_NEXT_STEP', payload: waitForNextStep });
    }
    if (waitForPreviousStep !== null) {
      setWaitForPreviousStep(waitForPreviousStep);
    }
  };

  const nextMapaNoCompra = () => {
    if (!showHorario) {
      renderLayer();
      return;
    }
    const { multiStepScreenIndex } = store.getState().formInfo;

    if (multiStepScreenIndex === 1) {
      renderLayer();
      updateScreenChangeVariables(mapStep);
      return;
    }

    handleScreenChange();

    // Una vez avanzamos dentro de los multisteps se activa el wait
    // previous step para volver hacia atrás dentro del propio multistep.
    if (!waitForPreviousStep || multiStepScreenIndex === 0) {
      updateWaitForNextAndPreviousStep(null, true);
    }

    setTimeout(() => {
      updateScreenChangeVariables(multiStepScreenIndex + 1);
      // Si estamos en el último paso del multistep se desactiva el
      // wait next step para poder salir del multistep y avanzar hacia la siguiente pantalla.
      if (steps[multiStepScreenIndex + 1].multiStepLastScreen) {
        updateWaitForNextAndPreviousStep(false, null);
        if (multiStepScreenIndex === 0) {
          setShowPreviousStep(false);
        } else {
          setShowPreviousStep(true);
        }
      }
    }, TIME_FOR_TRANSFORM);
  };

  const previousMapaNoCompra = () => {
    const { multiStepScreenIndex, waitForNextStep } = store.getState().formInfo;

    handleScreenChange();

    // Se desactiva el wait previous step en la segunda pantalla para que
    // en el caso de volver hacia atrás en la primera salte de step y no busque dentro de los multisteps.
    if (multiStepScreenIndex === 1) {
      updateWaitForNextAndPreviousStep(null, false);
    }

    if (!waitForNextStep) {
      updateWaitForNextAndPreviousStep(true, null);
    }

    // Se fija en la pantalla previa a la primera para que cuando se cambie
    // a la primera pantalla el CTA de anterior pase del multistep al step anterior.
    setTimeout(() => {
      updateScreenChangeVariables(multiStepScreenIndex - 1);
    }, TIME_FOR_TRANSFORM);
  };

  const updateScreenChangeVariables = (newMultiStepScreenIndex: number) => {
    setNextMultiStepScreenIndex(newMultiStepScreenIndex);
    dispatch({ type: 'UPDATE_MULTISTEP_SCREENINDEX', payload: newMultiStepScreenIndex });
    setSelectedStep(steps[newMultiStepScreenIndex]);
    setIsFullScreen(steps[newMultiStepScreenIndex].fullScreen);
    setShowPreviousStep(!steps[newMultiStepScreenIndex].hidePreviousStep);
  };

  useEffect(() => {
    dispatch({ type: 'UPDATE_MULTISTEPS', payload: steps });

    handleFirstLoad();

    return () => {
      dispatch({ type: 'UPDATE_FIRST_MAP_LOAD', payload: false });
      setShowPreviousStep(true);
      updateWaitForNextAndPreviousStep(false, false);
    };
  }, []);

  const renderElement = (state?: any) => {
    if (state) {
      updateWaitForNextAndPreviousStep(false, null);
    }

    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadScriptInstance onMapLoad={onMapLoad}>
          <RenderController
            screenType="layer-screen"
            title={state ? state.step.title : selectedStep.title}
            fields={state ? state.step.fields : selectedStep.fields}
            key={state ? state.step.screenIndex : selectedStep.screenIndex}
          />
        </LoadScriptInstance>
      </LayerManagerWrapper>
    );
  };

  /**
   * Necesario para en el caso de que se cierre el mapa se pueda mostrar la pantalla de preferencia horaria
   */
  const onCloseMap = () => {
    updateScreenChangeVariables(mapStep - 1);
    updateWaitForNextAndPreviousStep(true, false);
  };

  const renderLayer = () => {
    const layer = layerManager.openInteractionLayer(
      renderElement,
      { step: steps[mapStep], nextStepEventEmitter },
      {
        size: InteractionLayerSizeV2.D,
        id: 'mapaLeadInPage',
        shimBackgroundType: ShimBackgroundTypeV2.OPAQUE,
        onClose: onCloseMap,
      }
    );
    layerRef.current = layer;
    return null;
  };

  return selectedStep ? (
    selectedStep.screenIndex === mapStep ? null : (
      <LoadScriptInstance onMapLoad={onMapLoad}>
        <RenderController
          screenType="full-screen"
          title={selectedStep.title}
          fields={selectedStep.fields}
          key={selectedStep.screenIndex}
        />
      </LoadScriptInstance>
    )
  ) : null;
}
