import React, { useEffect } from 'react';
import {
  styled,
  Text,
} from '@volkswagen-onehub/components-core';
import { ProgressBar } from './ProgressBar';
import { CarlineWithOffers } from 'src/types';

interface styleProps {
  height?: number;
}
const Nav = styled.div<styleProps>`
  padding:16px;
  margin: 0 var(--size-grid001);
  position: ${(props) => props.height <500 ? 'relative' : 'absolute'};
  margin-top: ${(props) => props.height < 500 ? '20px' : null};
  bottom: 24px;
  height: 85px;
  border-radius: 8px;
  background-color: #FFFFFF;
  width: var(--size-grid022);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5);
`;

const UlWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
`;
interface LiButtonProps {
  activeTab: boolean;
}
const LiButton = styled.li<LiButtonProps>`
  z-index: 1;
  width: 72px;
  height: 32px;
  border: 1px solid  #00B0F0;
  border-radius: 16px;
  list-style: none;
  cursor: pointer;
  transition: all 0.1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.activeTab ? ' #00B0F0' : '#FFFFFF'};
`;

const TextStyles = styled.span<LiButtonProps>`
  color: ${(props) => !props.activeTab ? ' #00B0F0' : '#FFFFFF'}
`;
interface TabsNavigationProps {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  offers: CarlineWithOffers[];
  activeTab: number;
  height: number;
}

export const TabsNavigation = (props: TabsNavigationProps) => {
  const { setActiveTab, offers, activeTab, height } = props;


  const resetProgressBar = (select: number) => {
    setActiveTab(select);
    const aux = document.getElementById('animated-bar');
    if (aux) {
      aux.classList.remove('start');
      aux.offsetWidth;
      aux.classList.add('start');
    }
  };

  useEffect(() => {
    const aux = activeTab === 2 ? 0 : activeTab + 1;

    const timer = setTimeout(() => {
      setActiveTab(aux);
      resetProgressBar(aux);
    }, 5000);

    return () => clearTimeout(timer);

  }, [activeTab]);

  return (
    <Nav height={height}>
      <UlWrapper className="nav">
        {offers.map((offer, index) => (
          <LiButton key={index} onClick={(e) => resetProgressBar(index)} activeTab={activeTab === index}>
            <Text bold={activeTab === index}>
              <TextStyles activeTab={activeTab === index}> {offer.title} </TextStyles>
            </Text>
          </LiButton>
        ))}
      </UlWrapper>
      <ProgressBar />
    </Nav>
  );
};
