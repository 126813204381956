import { styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps } from 'src/types';
import { useOneFormContext } from '.';
import { useShowOverflow } from 'src/feature-app/hooks';

interface formControllerWrapperProps {
  screenType: 'layer-screen' | 'full-screen';
  showOverflow?: boolean;
}
const FormControllerWrapper = styled.div<formControllerWrapperProps>`
  overflow: ${(props)=> props.showOverflow ? 'visible' : 'hidden'};
  width: 100%;
  

  @media all and (min-width: 960px) {
    height: 100%;
  }
`;
interface Aprops {
  steps?: Steps[];
  screenType: 'layer-screen' | 'full-screen';
  screenIndex?: number;
  showPreviousStep?: boolean;
}



export function FormController(props: Aprops) {
  const { screenType } = props;
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { step, steps, screenIndex } = formInfo;
  const [selectedStep, setSelectedStep] = useState<Steps>(null);
  const store = useStore();
  const { showPreviousStep } = useOneFormContext();
  

  useEffect(() => {
    if (steps && step) {
      const { steps, step }: { steps: Steps[]; step: Steps } = store.getState().formInfo;
      const newStep = steps.find((newstep) => newstep.screenIndex === step.screenIndex);
      if (newStep && (!selectedStep || newStep.screenIndex !== selectedStep.screenIndex)) {
        setSelectedStep(newStep);
      }
    }
  }, [steps, step]);

  //Si se necesita que el overflow sea visible. 

  const { showOverflow } = useShowOverflow(formInfo.notALayer, selectedStep);

  return selectedStep ? (
    <FormControllerWrapper
      className="form-controller-wrapper"
      showOverflow = {showOverflow}
      screenType={screenType}
    >
      {selectedStep.multiStep && selectedStep.component ? (
        selectedStep.component
      ) : (
        <RenderController
          screenType={screenType}
          title={selectedStep.title}
          fields={selectedStep.fields}
          key={selectedStep.screenIndex}
        />
      )}
    </FormControllerWrapper>
  ) : null;
}
