import React from 'react';
import { ScreenController, TriggerProps, useFaContent } from 'src/feature-app';
import { FidelizacionController } from 'src/forms/FormsAdhoc/Fidelizacion';

export function FidelizacionTrigger(props: TriggerProps) {
  const { setSelectedJourney, setCTALabel } = props;
  const faContent = useFaContent();

  const getCtaLabel = () => (faContent?.cta ? faContent.cta : 'Pide esta oferta');

  return (
    <div className="fidelizacion-wrapper">
      <ScreenController
        selectedJourney={<FidelizacionController />}
        closeLayerCallback={close}
        handleAreYouSureLayer={() => null}
        notALayer={true}
      />
    </div>
  );
}
