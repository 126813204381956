import React from 'react';
import { JourneyNoRenovacionController } from '.';
import { MapGeneralController } from 'src/feature-app';

/**
 * Este componente está creado únicamente para que JourneyNoRenovacionController tenga acceso a las variables de MapGeneralController.
 */
export const JourneyNoRenovacionWrapper = () => {
  
  return (
    <MapGeneralController>
      <JourneyNoRenovacionController />
    </MapGeneralController>
  );
};
