import {
  Container,
  ContainerGutter,
  CTA,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { Alert } from 'src/icons-core-imports';

import { CloseHandleV2 } from '@volkswagen-onehub/layer-manager';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface contentProps {
  notALayer?: boolean;
  formHeight?: string;
}

const Content = styled.div<contentProps>`
  background-color: #ffffff;
  padding: ${(props) => (props.notALayer ? '0 var(--size-grid002)' : '44px 31px')};
  flex: auto;
  text-align: ${(props) => (props.notALayer ? 'left' : 'center')};
  width: 100%;
  display: ${(props) => (props.notALayer ? 'flex' : null)};
  align-items: ${(props) => (props.notALayer ? 'center' : null)};
  height: ${(props) => (props.notALayer ? 'calc(100vh - 84px)' : '100%')};
  @media all and (min-width: 560px) {
    padding: ${(props) => (props.notALayer ? '0 var(--size-grid002)' : '52px 46px')};
    height: ${(props) => (props.notALayer ? 'calc(100vh - 92px)' : '100%')};
  }
  @media all and (min-width: 960px) {
    padding: ${(props) => (props.notALayer ? '0 var(--size-grid003)' : 'var(--size-grid001)')};
    height: ${(props) => (props.notALayer ? 'calc(100vh - 100px)' : '100%')};
  }
  @media all and (min-width: 1280px) {
    padding: ${(props) => (props.notALayer ? '0 var(--size-grid005)' : 'var(--size-grid001)')};
  }
  @media all and (min-width: 1920px) {
    padding: ${(props) => (props.notALayer ? '0 var(--size-grid006)' : 'var(--size-grid001)')};
    height: ${(props) => (props.notALayer ? 'calc(100vh - 116px)' : '100%')};
  }
  @media all and (min-width: 2560px) {
    padding: ${(props) => (props.notALayer ? '0 var(--size-grid007)' : 'var(--size-grid001)')};
  }
`;

interface LayerComponentProps {
  closeLayerCallback?: CloseHandleV2<any, any>;
  closeOneForm?: CloseHandleV2<any, any>;
  bodycopy?: string;
  title?: string;
  icon?: boolean;
  onClick?: any;
  ctaLayer?: string;
  notALayer?: boolean;
  notShowCTA?: boolean;
  ctaType?: 'a' | 'button';
  urlHref?: string;
  ctaTextAlign?: 'left' | 'center';
  secondCta?: JSX.Element;
}

//admite formato, title, bodycopy, si debe mostrar o no el icono de alert
//así como la siguiente info del CTA: si mostarlo o no, el texto del layer y la funciona de onClick

export function GenericErrorLayer(props: LayerComponentProps) {
  const {
    closeLayerCallback,
    closeOneForm,
    bodycopy,
    title,
    icon,
    onClick,
    ctaLayer,
    notALayer,
    notShowCTA,
    ctaType,
    urlHref,
    ctaTextAlign,
    secondCta,
  } = props;
  const dispatch = useDispatch();

  const handleCloseLayers = () => {
    closeLayerCallback({}, {}, {});
    closeOneForm({}, {}, {});
    dispatch({ type: 'UPDATE_GENERIC_ERROR', payload: false });
  };

  //calc height stuff
  const content = useRef(null);
  const viewportHeight = window.innerHeight;
  const [formHeight, setFormHeight] = useState<string>('100%');

  useEffect(() => {
    if (!notALayer) {
      return;
    }

    if (content.current) {
      const minHeight = viewportHeight - 252;
      const contentHeight = content.current.clientHeight;
      if (minHeight > contentHeight) {
        setFormHeight(`${minHeight}px`);
      } else {
        setFormHeight(`${contentHeight}px`);
      }
    }
  }, []);

  return (
    <Content className="generic-error-layer-wrapper" notALayer={notALayer} ref={content} formHeight={formHeight}>
      <Container
        gutter={ContainerGutter.dynamic0100}
        wrap={'always'}
        horizontalAlign={'flex-start'}
      >
        {icon ? (
          <div style={{ textAlign: 'left' }}>
            <Alert variant="large" />
          </div>
        ) : null}
        <>
          <Text
            appearance={notALayer ? TokenTextAppearance.headline300 : TokenTextAppearance.headline200}
            bold
            textAlign={TextAlignment.left}
          >
            {title ? title : 'Uy, algo ha pasado'}
          </Text>
        </>
        <Container
          gutter={ContainerGutter.static500}
          wrap={'always'}
          horizontalAlign={'flex-start'}
        >
          <Text textAlign={TextAlignment.left}>
            {bodycopy
              ? bodycopy
              : 'Parece que no podemos seguir con el proceso de cita en este momento. ¿Lo intentamos de nuevo más tarde?'}
          </Text>
          <Container
            wrap={'always'}
            horizontalAlign={'flex-start'}
            gutter={ContainerGutter.static350}
          >
            {notShowCTA ? null : (
              <>
                {ctaType === 'a' ? (
                  <div style={{ textAlign: ctaTextAlign ? ctaTextAlign : 'center' }}>
                    <CTA tag="a" emphasis="primary" href={urlHref}>
                      {ctaLayer ? <>{ctaLayer}</> : <>Cerrar</>}
                    </CTA>
                  </div>
                ) : (
                  <div style={{ textAlign: ctaTextAlign ? ctaTextAlign : 'center' }}>
                    <CTA
                      tag="button"
                      emphasis="primary"
                      onClick={(e: any) => {
                        if (onClick) {
                          e.preventDefault();
                          onClick();
                        } else {
                          e.preventDefault();
                          handleCloseLayers();
                        }
                      }}
                    >
                      {ctaLayer ? <>{ctaLayer}</> : <>Cerrar</>}
                    </CTA>
                  </div>
                )}
              </>
            )}
            {secondCta ? <div style={{ textAlign: ctaTextAlign ? ctaTextAlign : 'center' }}>{secondCta}</div> : null}
          </Container>
        </Container>
      </Container>
    </Content>
  );
}
