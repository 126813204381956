import React from 'react';
import { Text } from '@volkswagen-onehub/components-core';
import { Steps } from 'src/types';
import { RatingStars } from 'src/components';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';

const resetStepsScreenIndex = (steps: Steps[], offset: number) => {
  return steps.map((step, index) => {
    step.screenIndex = index + offset;
    return step;
  });
};

export function RatingSteps(formName: string, startIndex: number) {
	
	const steps: Steps[] = [

		{
			title: <Text bold>El precio del modelo</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={<RatingStars name="precio" nextOnClick={true} textGood="satisfactorio" textBad="insatisfactorio" />}
				/>
			),
			screenIndex: 0,
			name: 'Precio',
			outputsIsStars: true,
		},
		{
			title: <Text bold>Características del vehículo, equipamiento y asistentes adicionales</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<RatingStars name="equipamiento" nextOnClick={true} textGood="satisfactorios" textBad="insatisfactorios" />
					}
				/>
			),
			screenIndex: 0,
			name: 'Equipamiento',
			outputsIsStars: true,
		},
		{
			title: <Text bold>El consumo del modelo</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={<RatingStars name="consumo" nextOnClick={true} textGood="satisfactorio" textBad="insatisfactorio" />}
				/>
			),
			screenIndex: 0,
			name: 'Consumo',
			outputsIsStars: true,
		},
		{
			title: <Text bold>La garantía del modelo</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<RatingStars name="garantia" nextOnClick={true} textGood="satisfactoria" textBad="insatisfactoria" />
					}
				/>
			),
			screenIndex: 0,
			name: 'Garantia',
			outputsIsStars: true,
		},
		{
			title: <Text bold>Las condiciones de financiación</Text>,
			fields: (
				<OneElementTemplateFullScreen
					element={
						<RatingStars name="financiacion" nextOnClick={true} textGood="satisfactorias" textBad="insatisfactorias" />
					}
				/>
			),
			screenIndex: 0,
			name: 'Financiacion',
			outputsIsStars: true,
		},
	];

	if (formName == 'ya_tengo') {
		const prevSteps: Steps[] = [
			{
				title: <Text bold>Los plazos de entrega</Text>,
				alternateTitle: <Text bold>Los plazos de entrega</Text>,
				fields: (
					<OneElementTemplateFullScreen
						element={
							<RatingStars name="plazos" nextOnClick={true} textGood="satisfactorios" textBad="insatisfactorios" />
						}
					/>
				),
				screenIndex: 0,
				name: 'Plazos',
				outputsIsStars: true,
			},
			{
				title: <Text bold>Tu visita al punto de venta</Text>,
				fields: (
					<OneElementTemplateFullScreen
						element={<RatingStars name="visita" nextOnClick={true} textGood="satisfactoria" textBad="insatisfactoria" />}
					/>
				),
				screenIndex: 0,
				name: 'Visita',
				outputsIsStars: true,
			}
		];

		return resetStepsScreenIndex(prevSteps.concat(steps), startIndex + 1);
	}
	else if(formName == 'no_tengo') {
		const prevSteps: Steps[] = [
			{
				title: <Text bold>Gestión del punto de venta</Text>,
				alternateTitle: <Text bold>Gestión del punto de venta</Text>,
				fields: (
					<OneElementTemplateFullScreen
						element={
							<RatingStars name="gestion" nextOnClick={true} textGood="satisfactoria" textBad="insatisfactoria" />
						}
					/>
				),
				screenIndex: 0,
				name: 'Gestion',
				outputsIsStars: true,
			}
		];

		return resetStepsScreenIndex(prevSteps.concat(steps), startIndex + 1);
	}
	else {
		return resetStepsScreenIndex(steps, startIndex + 1);
	}
}