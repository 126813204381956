import { vehiclesInfo } from '.';
import { VehiclesInfo } from 'src/types';
import { OneFormActionTypes, RESET_STATE } from './types';
import { VehiclesInfoActionTypes, UPDATE_VEHICLES } from './vehicles-types';

export function vehiclesReducer(state: VehiclesInfo = null, action: OneFormActionTypes | VehiclesInfoActionTypes): any {
	switch (action.type) {
		case RESET_STATE:
			return { ...vehiclesInfo };
		case UPDATE_VEHICLES:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
