import React from 'react';
import {
  CTA,
} from '@volkswagen-onehub/components-core';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { useOneFormContext } from 'src/feature-app';

export const FakeTriggerCTA = () => {
  const { nextStep } = useOneFormContext();
  const trackingManager = useTrackingManager();

  return (
    <div style={{ paddingTop: '44px'}}>
      <CTA
        tag="button"
        emphasis="primary"
        onClick={(e) => {
          trackingManager.trackFormStepButtonClick({
            contentId: 'Siguiente',
          });
          e.preventDefault();
          nextStep();
        }}
        ariaLabel="aria label"
      >
        Empezar
      </CTA>
    </div>
  );
};
