import React from 'react';
import { useStore } from 'react-redux';
import { Store } from 'redux';
import { useFeatureAppConfig, useGetBrand } from 'src/feature-app';
import {
  createPartner,
  createPartnerWithSearch,
  createTrackingLink,
  createTrackingProcess,
  getBaseEventInfo,
  getCarlineConfiguration,
  getCompleteConfigurationFromVicci,
  getFormAppointments,
  getMarketingConsent,
} from 'src/feature-app/tracking-helpers';
import {
  emptyCarlineConfig,
  emptyConfig,
  emptyLatLongZip,
  emptyOwnership,
  emptyPersonalization,
} from 'src/feature-app/tracking-objects';
import {
  ErrorFormFields,
  OffersTrackingEvent,
  OneFormTrackingManager,
  TrackingError,
  TrackingEvent,
  TrackingEventInfo,
  TrackingFilter,
  TrackingForm,
  TrackingPartner,
  TrackingProcess,
} from 'src/feature-app/tracking-types';
import { DealersData, OneFormState } from 'src/types';
import { useFeatureServices } from './use-feature-services';

const defaultTrackingEvent: OffersTrackingEvent = {
  personalization: emptyPersonalization,
};

export function useTrackingManager(): OneFormTrackingManager {
  const { tracking: trackingManager } = useFeatureServices();
  const store: Store<OneFormState> = useStore();
  const config = useFeatureAppConfig();
  const brand = useGetBrand();

  return React.useMemo((): OneFormTrackingManager => {
    function track(action: string, event: OffersTrackingEvent): void {
      const { trackingIsDisabled } = store.getState().formInfo;
      if (trackingManager && !trackingIsDisabled) {
        trackingManager.track(action, event as TrackingEvent);
      }
    }

    return {
      trackFormButtonClick(eventInfo: TrackingEventInfo, dealer?: DealersData) {
        const { formName, formType } = store.getState().formData.fields;

        track('VWBasic_FormButton_Click', {
          ...defaultTrackingEvent,
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            PrefContactChannels: undefined,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            NewsletterType: undefined,
            Questionnaire: undefined,
            ...getMarketingConsent(store),
          },
          eventInfo: { ...getBaseEventInfo(store, brand), ...eventInfo },
          configuration: {
            ...emptyConfig,
            PriceType: 'price',
            PriceTypeParameter: undefined,
            ...getCarlineConfiguration(store),
          },
          partner: createPartner(store, dealer),
          ownership: emptyOwnership,
          link: createTrackingLink(eventInfo.contentId),
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
      trackFormStepButtonClick(eventInfo: TrackingEventInfo) {
        const { formName, formType, pageCategory } = store.getState().formData.fields;

        const emptyConfigStep = { ...emptyConfig };
        delete emptyConfigStep.PriceTotalTotal;

        track('VWBasic_FormStepButton_Click', {
          ...defaultTrackingEvent,
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            PrefContactChannels: undefined,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            NewsletterType: undefined,
            Questionnaire: undefined,
            ...getMarketingConsent(store),
          },
          eventInfo: { ...getBaseEventInfo(store, brand), ...eventInfo },
          configuration: {
            ...emptyConfigStep,
            WheelsAdditionalExternalRollingNoise: undefined,
            ...getCarlineConfiguration(store),
          },
          process: {
            Name: pageCategory,
            ...createTrackingProcess(store),
          },
          partner: createPartner(store),
          ownership: emptyOwnership,
          link: createTrackingLink(eventInfo.contentId),
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
      trackFormStepLoad(eventInfo: TrackingEventInfo, formInfo?: TrackingForm, processInfo?: TrackingProcess) {
        const { formName, formType, pageCategory } = store.getState().formData.fields;

        const baseEventInfoForLoad = { ...getBaseEventInfo(store, brand) };
        delete baseEventInfoForLoad.sectionName;

        const emptyConfigLoad = { ...emptyConfig };
        delete emptyConfigLoad.PriceTotalTotal;

        track('VWBasic_FormStep_Load', {
          ...defaultTrackingEvent,
          form: {
            ...formInfo,
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
          },
          eventInfo: { ...baseEventInfoForLoad, ...eventInfo, contentLabels: undefined },
          configuration: {
            ...emptyConfigLoad,
            WheelsAdditionalExternalRollingNoise: undefined,
            ...getCarlineConfiguration(store),
          },
          user: { ...emptyLatLongZip },
          process: {
            Name: pageCategory,
            ...createTrackingProcess(store, processInfo),
          },
          partner: createPartner(store),
          ownership: emptyOwnership,
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
      trackButtonClick(eventInfo: TrackingEventInfo, dealer?: DealersData) {
        const processInfo = createTrackingProcess(store);
        track('VWBasic_Button_Click', {
          ...defaultTrackingEvent,
          eventInfo: { ...getBaseEventInfo(store, brand), ...eventInfo },
          configuration: {
            ...emptyCarlineConfig,
            ...getCarlineConfiguration(store),
          },
          partner: createPartner(store, dealer),
          ownership: emptyOwnership,
          process: processInfo,
          link: createTrackingLink(eventInfo.contentId),
          environment: { featureAppIdAppVersion: process.env.VERSION },
          questionnaire: { QuestionAnswerSet: undefined },
          SC: {
            ResultsRecommendationPosition: undefined,
            ResultsRecommendationFirst: undefined,
            ResultsRecommendationSecond: undefined,
          },
        });
      },
      trackFormCTAClick(eventInfo: TrackingEventInfo, dealer?: DealersData) {
        const { formName, formType } = store.getState().formData.fields;

        track('VWBasic_FormCTA_Click', {
          ...defaultTrackingEvent,
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            PrefContactChannels: undefined,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            NewsletterType: undefined,
            Questionnaire: undefined,
            ...getMarketingConsent(store),
          },
          eventInfo: { ...getBaseEventInfo(store, brand), ...eventInfo },
          configuration: {
            ...emptyConfig,
            PriceType: undefined,
            PriceTypeParameter: undefined,
            ...getCarlineConfiguration(store),
          },
          partner: createPartner(store, dealer),
          ownership: emptyOwnership,
          link: createTrackingLink(eventInfo.contentId),
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
      trackFormSubmissionSuccessMessageLoad(eventInfo: TrackingEventInfo, formInfo?: TrackingForm) {
        const { formName, formType, vicciData, pageCategory } = store.getState().formData.fields;
        const processInfo = createTrackingProcess(store);

        track('VWBasic_FormSubmissionSuccessMessage_Load', {
          ...defaultTrackingEvent,
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            PrefContactChannels: undefined,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            NewsletterType: undefined,
            ErrorFormFields: undefined,
            Questionnaire: undefined,
            SourceID: undefined,
            OpportunityUID: undefined,
            CycleID: undefined,
            FormStart: false,
            LeadID: undefined,
            ...getFormAppointments(store),
            ...getMarketingConsent(store),
            ...formInfo,
          },
          eventInfo: { ...getBaseEventInfo(store, brand), ...eventInfo },
          user: { ...emptyLatLongZip },
          configuration: {
            ...emptyConfig,
            ...getCompleteConfigurationFromVicci(vicciData),
            ...getCarlineConfiguration(store),
          },
          process: { ...processInfo, Name: pageCategory },
          partner: createPartnerWithSearch(store),
          ownership: emptyOwnership,
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
      trackFormLayerLoad() {
        const { formName, formType, pageCategory } = store.getState().formData.fields;

        const emptyConfigLoad = { ...emptyConfig };
        delete emptyConfigLoad.PriceTypeParameter;
        delete emptyConfigLoad.PriceType;

        track('VWBasic_FormLayer_Load', {
          ...defaultTrackingEvent,
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            NewsletterType: undefined,
            CycleID: undefined,
            FormStart: true,
          },
          eventInfo: {
            ...getBaseEventInfo(store, brand),
            contentId: config.trigger ? config.trigger : undefined,
            contentLabels: undefined,
          },
          configuration: { ...emptyConfigLoad, ...getCarlineConfiguration(store) },
          process: {
            Name: pageCategory,
            ...createTrackingProcess(store),
          },
          partner: createPartner(store),
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
      trackFormLoad() {
        const { formName, formType, pageCategory } = store.getState().formData.fields;
        const emptyConfigLoad = { ...emptyConfig };

        track('VWBasic_Form_Pageload', {
          ...defaultTrackingEvent,
          environment: { featureAppIdAppVersion: process.env.VERSION },
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            FormStart: true,
          },
          eventInfo: {
            ...getBaseEventInfo(store, brand),
            contentId: config.trigger ? config.trigger : undefined,
            contentLabels: undefined,
          },
          user: { ...emptyLatLongZip },
          configuration: { ...emptyConfigLoad, ...getCarlineConfiguration(store) },
          process: {
            Name: pageCategory,
            ...createTrackingProcess(store),
          },
          partner: createPartner(store),
        });
      },
      trackFormLoadRace() {
        const { formName, formType, pageCategory } = store.getState().formData.fields;
        const emptyConfigLoad = { ...emptyConfig };

        track('VWBasic_Form_Load', {
          ...defaultTrackingEvent,
          environment: { featureAppIdAppVersion: process.env.VERSION },
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            FormStart: true,
          },
          eventInfo: {
            ...getBaseEventInfo(store, brand),
            contentId: config.trigger ? config.trigger : undefined,
            contentLabels: undefined,
          },
          user: { ...emptyLatLongZip },
          configuration: { ...emptyConfigLoad, ...getCarlineConfiguration(store) },
          process: {
            Name: pageCategory,
            ...createTrackingProcess(store),
          },
          partner: createPartner(store),
        });
      },

      trackFormErrorMessageLoad(errorInfo?: TrackingError, errorFields?: ErrorFormFields[]) {
        const { formName, formType } = store.getState().formData.fields;

        track('VWBasic_FormErrorMessage_Load', {
          ...defaultTrackingEvent,
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            ErrorFormFields: { FieldNameandID: [...errorFields] },
            ErrorAdressValidationFields: undefined,
          },
          eventInfo: { ...getBaseEventInfo(store, brand) },
          error: { message: errorInfo.message, code: undefined, referringUrl: undefined },
          partner: createPartner(store),
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
      trackFormFieldClick(eventInfo: TrackingEventInfo, form: TrackingForm) {
        const { formName, formType } = store.getState().formData.fields;

        track('VWBasic_FormField_Click', {
          ...defaultTrackingEvent,
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            ...form,
          },
          eventInfo: { ...getBaseEventInfo(store, brand), ...eventInfo },
          configuration: {
            ...emptyCarlineConfig,
            ...getCarlineConfiguration(store),
          },
          process: createTrackingProcess(store),
          partner: createPartner(store),
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
      trackFormFilterSelect(eventInfo: TrackingEventInfo, filter: TrackingFilter, form: TrackingForm) {
        const { formName, formType } = store.getState().formData.fields;

        track('VWBasic_FormFilterSelect_Click', {
          ...defaultTrackingEvent,
          form: {
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            ...form,
          },
          eventInfo: { ...getBaseEventInfo(store, brand), ...eventInfo },
          configuration: {
            ...emptyCarlineConfig,
            ...getCarlineConfiguration(store),
          },
          filter,
          process: createTrackingProcess(store),
          partner: createPartner(store),
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
      trackFormCloseClick(eventInfo: TrackingEventInfo, formInfo?: TrackingForm) {
        const baseEventInfoCloseClick = { ...getBaseEventInfo(store, brand) };
        delete baseEventInfoCloseClick.templateType;

        const partner: TrackingPartner = createPartnerWithSearch(store);
        delete partner.informationDepartment;

        const processInfo = createTrackingProcess(store);
        // delete processInfo.StepNumber;
        const { formName, formType, vicciData, pageCategory } = store.getState().formData.fields;

        track('VWBasic_FormClose_Click', {
          ...defaultTrackingEvent,
          form: {
            ...formInfo,
            FormType: formType,
            FormName: `vw-oneform-${formName}`,
            PrefContactChannels: undefined,
            SourceID: undefined,
            ErrorFormFields: undefined,
            OpportunityUID: undefined,
            FormVersion: `vw-oneform-${formName}-${process.env.VERSION}`,
            CycleID: undefined,
            FormFields: undefined,
            ...getFormAppointments(store),
          },
          eventInfo: { ...baseEventInfoCloseClick, ...eventInfo },
          configuration: {
            ...emptyCarlineConfig,
            ...getCompleteConfigurationFromVicci(vicciData),
            ...getCarlineConfiguration(store),
          },
          process: {...processInfo, Name: pageCategory},
          partner,
          link: createTrackingLink(eventInfo.contentId),
          environment: { featureAppIdAppVersion: process.env.VERSION },
        });
      },
    };
  }, [trackingManager]);
}
