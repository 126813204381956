import { Text, CTA, styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { LocateUserFullScreen } from 'src/components';
import {
  LayerManagerWrapper,
  useFeatureAppEnvironment,
  useFeatureServices,
  useOneFormContext,
} from 'src/feature-app';
import { RenderController, OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import LoadScriptInstance from 'src/feature-app/NewMap/Loadscript';
import { TIME_FOR_TRANSFORM } from 'src/globals';
import { DealersData, OneFormState, Steps } from 'src/types';
import { JourneyNoCompraWrapper } from './JourneyNoCompraWrapper';
import { InteractionLayerSizeV2, ShimBackgroundTypeV2 } from '@volkswagen-onehub/layer-manager';

enum MapaNoCompraSteps {
  Dealer,
  Horario,
  SeleccionCita,
  SeleccionFecha,
}

interface MapaNoCompraProps {
  showHorario?: boolean;
  formName: string;
}

const isLastScreen = (steps: Steps[], multiStepScreenIndex: number) => steps.length - 1 === multiStepScreenIndex;
const isFirstScreen = (multiStepScreenIndex: number) => multiStepScreenIndex === 0;

export function MapaNoCompra(props: MapaNoCompraProps) {
  const dispatch = useDispatch();
  const store = useStore();
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { firstMapLoad, multiStepScreenIndex, navigationMovingForward } = formInfo;
  const layerManager = useFeatureServices()['layer-manager'];
  const env = useFeatureAppEnvironment();
  const {
    handleScreenChange,
    // setWaitForNextStep,
    setNextMultiStep,
    setNextMultiStepScreenIndex,
    setPreviousMultiStep,
    setWaitForPreviousStep,
    waitForPreviousStep,
    // waitForNextStep,
    setIsFullScreen,
    setShowPreviousStep,
    nextStepEventEmitter,
    nextStep,
    handleNextStep
  } = useOneFormContext();

  const steps: Steps[] = [
    {
      title: (
        <>
          <Text bold>Si tienes dudas, puedes pedir a un punto de venta que te contacte.</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen allowOverflowingContent
          element={<LocateUserFullScreen noTengoDudasButton />}
        />
      ),
      screenIndex: MapaNoCompraSteps.Dealer,
      name: 'MapSearch',
      showOverflow: true,
    },
    {
      screenIndex: 1,
      name: 'MapSelect',
      alternateTitle: (
        <Text>
          <Text bold>¿Qué experto</Text> quieres que se{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      ),
      title: <></>,
      fields: <JourneyNoCompraWrapper />,
      fullScreen: true,
      multiStepLastScreen: true,
      hidePreviousStep: true,
    },
  ];

  const [selectedStep, setSelectedStep] = useState<Steps>(null);
  const layerRef = useRef(null);
  /**
   * Hay que esperar a que se cree el mapa de google para tener acceso a window.google
   */
  const onMapLoad = () => {
    if (multiStepScreenIndex === 0) {
      handleFirstLoad();
    }
  };

  const handleFirstLoad = async () => {
    if (firstMapLoad) {
      // Inicialización
      initializeMultiStep();
    } else {
      if (navigationMovingForward || navigationMovingForward === null || navigationMovingForward === undefined) {
        // Inicialización cuando se va hacia adelante una vez inicializado el mapa o después de cerrar el layer y abrir en el mapa.
        initializeMultiStep();
      } else {
        // En caso de que estemos en la última pantalla, desactivamos el wait for next step.
        const newWaitForNextStep = !isLastScreen(steps, multiStepScreenIndex);
        // Lo mismo para previous, solo le desactivamos si estamos en la primera.
        const newWaitForPreviousStep = !isFirstScreen(multiStepScreenIndex);

        updateWaitForNextAndPreviousStep(newWaitForNextStep, newWaitForPreviousStep);
        updateScreenChangeVariables(multiStepScreenIndex);


      }
    }
    setNextMultiStep(() => nextMapaNoCompra);
    setPreviousMultiStep(() => previousMapaNoCompra);
  };

  const initializeMultiStep = () => {
    updateWaitForNextAndPreviousStep(true, false);
    updateScreenChangeVariables(0);
  };

  const updateWaitForNextAndPreviousStep = (waitForNextStep: boolean, waitForPreviousStep: boolean) => {
    if (waitForNextStep !== null) {
      dispatch({ type: 'UPDATE_WAIT_FOR_NEXT_STEP', payload: waitForNextStep });
    }
    if (waitForPreviousStep !== null) {
      setWaitForPreviousStep(waitForPreviousStep);
    }
  };

  const nextMapaNoCompra = () => {
    // const { multiStepScreenIndex } = store.getState().formInfo;

    // handleScreenChange();

    // // Una vez avanzamos dentro de los multisteps se activa el wait
    // // previous step para volver hacia atrás dentro del propio multistep.
    // if (!waitForPreviousStep || multiStepScreenIndex === 0) {
    //   updateWaitForNextAndPreviousStep(null, true);
    // }

    // setTimeout(() => {
    //   updateScreenChangeVariables(multiStepScreenIndex + 1);
    //   // Si estamos en el último paso del multistep se desactiva el
    //   // wait next step para poder salir del multistep y avanzar hacia la siguiente pantalla.
    //   if (steps[multiStepScreenIndex + 1].multiStepLastScreen) {
    //     updateWaitForNextAndPreviousStep(false, null);
    //     setShowPreviousStep(true);
    //   }
    // }, TIME_FOR_TRANSFORM);
    renderLayer();
  };

  const previousMapaNoCompra = () => {
    const { multiStepScreenIndex, waitForNextStep } = store.getState().formInfo;

    handleScreenChange();

    // Se desactiva el wait previous step en la segunda pantalla para que
    // en el caso de volver hacia atrás en la primera salte de step y no busque dentro de los multisteps.
    if (multiStepScreenIndex === 1) {
      updateWaitForNextAndPreviousStep(null, false);
    }

    if (!waitForNextStep) {
      updateWaitForNextAndPreviousStep(true, null);
    }

    // Se fija en la pantalla previa a la primera para que cuando se cambie
    // a la primera pantalla el CTA de anterior pase del multistep al step anterior.
    setTimeout(() => {
      updateScreenChangeVariables(multiStepScreenIndex - 1);
    }, TIME_FOR_TRANSFORM);
  };

  const updateScreenChangeVariables = (newMultiStepScreenIndex: number) => {
    setNextMultiStepScreenIndex(newMultiStepScreenIndex);
    dispatch({ type: 'UPDATE_MULTISTEP_SCREENINDEX', payload: newMultiStepScreenIndex });
    setSelectedStep(steps[newMultiStepScreenIndex]);
    setIsFullScreen(steps[newMultiStepScreenIndex].fullScreen);
    setShowPreviousStep(!steps[newMultiStepScreenIndex].hidePreviousStep);
  };

  useEffect(() => {
    dispatch({ type: 'UPDATE_MULTISTEPS', payload: steps });

    handleFirstLoad();

    return () => {
      dispatch({ type: 'UPDATE_FIRST_MAP_LOAD', payload: false });
      setShowPreviousStep(true);
      updateWaitForNextAndPreviousStep(false, false);
    };
  }, []);

  const renderElement = (state?: any) => {
    if (state) {
      updateWaitForNextAndPreviousStep(false, null);
    }

    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadScriptInstance onMapLoad={onMapLoad}>
          <RenderController
            screenType="layer-screen"
            title={state ? state.step.title : selectedStep.title}
            fields={state ? state.step.fields : selectedStep.fields}
            key={state ? state.step.screenIndex : selectedStep.screenIndex}
          />
        </LoadScriptInstance>
      </LayerManagerWrapper>
    );
  };

  const renderLayer = () => {
    const layer = layerManager.openInteractionLayer(
      renderElement,
      { step: steps[1], nextStepEventEmitter },
      { size: InteractionLayerSizeV2.D, id: 'mapaNoCompra', shimBackgroundType: ShimBackgroundTypeV2.OPAQUE }
    );
    layerRef.current = layer;
    return null;
  };

  return selectedStep ? (
    selectedStep.screenIndex === 1 ? null : (
      <LoadScriptInstance onMapLoad={onMapLoad}>
        <RenderController
          screenType="full-screen"
          title={selectedStep.title}
          fields={selectedStep.fields}
          key={selectedStep.screenIndex}
        />
      </LoadScriptInstance>
    )
  ) : null;
}
