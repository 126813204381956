import { styled } from '@volkswagen-onehub/components-core';

export const Background = styled.div<{ calendarIsStep: boolean }>`
  background-color: #ffffff;
  width: 100%;
  min-height: ${(props)=>(props.calendarIsStep ? '100vh' : 'calc(100vh - 52px)')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -2px;
`;
export const AllContentWrapper = styled.div`
  display: flex;
  align-items: center; 
`;
export const DealerInfoWrapper = styled.div`
  width: 83vw;
  @media screen and (min-width: 960px) {
    width: 660px;
  }
`;
export const GoBackWrapper = styled.div`
  padding-left: 20px;
  padding-top: 24px;
  padding-bottom: 3px;
  background-color: #ffffff;
`;
export const GreyText = styled.span`
  color: '#6A767D';
`;
export const CalendarHandlerWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 44px 4% 32px;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 560px) {
    padding: 44px 11% 32px;
  }
  @media screen and (min-width: 960px) {
    padding: 44px 11% 32px;
  }
`;
export const AllDaysWrapper = styled.div`
  width: 100%;
  margin: auto;
  align-self: center;
  justify-self: center;
  margin-bottom: 44px;
  @media screen and (min-width: 560px) {
    width: 89%;
  }
  @media screen and (min-width: 960px) {
    width: 96%;
    margin-left: 32px;
    margin-rigth: 0;
  }
`;
export const TabsWitdh = styled.div`
  width: 83vw;
  @media all and (min-width: 960px) {
    width: 660px;
  }
`;
export const DividerC = styled.div`
  height: 1px;
  width: 100%;
  background-color: #dfe4e8;
`;
export const DayPositionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
interface DayWrapperProps {
  active?: any;
  presentDay?: any;
  disabled?: boolean;
}
export const DayWrapper = styled.div<DayWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active ? '#FFFFFF' : props.disabled ? '#dfe4e8' : '#001E50')};
  font-size: ${(props) => (props.presentDay ? '700' : null)};
  background-color: ${(props) => (props.active ? '#001E50' : null)};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  @media screen and (min-width: 960px) {
    width: 44px;
    height: 44px;
  }
`;

interface CapitalizeWrapperProps {
  hasTitle?: boolean;
  isDisabled: boolean;
}
export const Capitalize = styled.span<CapitalizeWrapperProps>`
  text-transform: capitalize;
  color: ${(props) => (props.isDisabled ? '#E8DFE4' : '#001e50')};
  padding: ${(props) => (props.hasTitle ? null : '0 32px')};
  padding-top: ${(props) => (props.hasTitle ? null : '2px')};
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
`;
