import { CarlineBase } from 'src/types';
import { formatAlphabetical, formatCarlineSelectExtendedList } from '.';

/**
 * Devuelve los carlines con el formato necesario
 * @param carlines
 * @returns
 */
  export const formatAllCarlines = (carlines: CarlineBase[], alphabetical?: boolean) => {
  if (alphabetical) {
    return formatAlphabetical(carlines);
  } else {
    return carlines.map((carline) => formatCarlineSelectExtendedList(carline)).filter((c) => c !== null);
  }
};
