import React, { useState, useEffect } from 'react';
import {
  styled,
  Text,
  ThemeProvider,
  Container,
  ContainerPadding,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { CTAOfertaSuv } from './CTAOfertaSuv';
import { TriggerProps } from 'src/feature-app';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import { CarlineWithOffers } from 'src/types';


const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 400px;
  padding: 94px var(--size-grid001) 32px;
  text-align: center;
`;

interface CarImageProps {
  name: string;
  height: number;
  width: number;
}

const CarImage = styled.img<CarImageProps>`
  width: ${(props) => props.height < 600 ? '90%' : '100%'};
  height: auto;
  position: ${(props) => props.height < 500 ? 'relative' : 'absolute'};
  margin: 0 auto;
  left:  ${(props) => props.height < 500 ? null : props.name === 'Taigo' ? null : 'var(--size-grid005)'};
  right: ${(props) => props.height < 500 ? null : props.name === 'Taigo' ? '0' : null};
  bottom:  ${(props) => props.height < 500 ? null : '20%'};
  margin-top: ${(props) => props.height < 500 ? '24px' : null};
  transform: ${(props) => props.height < 600 ? null : 'scale(1.1)'};
  transform-origin: ${(props) => props.name === 'Taigo' ? 'right bottom' : 'left bottom'};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column ;
  justify-content: flex-start;
  align-items: flex-start;
`;

interface MobileCardProps {
  content: any;
  carlineWithOffers: CarlineWithOffers;
}

export const formatNumber = (num: any) => {

  let transformedNumber = '';

  let decimals = num.toString().split('.');
  let numerals = decimals.shift().split('');
  let aux = [];

  while (numerals.length > 0) {
    aux.unshift(numerals.splice(-3, 3).join(''));
  }
  if (decimals.length <= 0) {
    transformedNumber = aux.join('.') + ',00';
  } else if (decimals[0].length === 1) {
    transformedNumber = aux.join('.') + ',' + decimals.toString() + '0';
  } else {
    transformedNumber = aux.join('.') + ',' + decimals.toString();
  }

  return transformedNumber;
};

export const MobileCard = (props: TriggerProps & MobileCardProps) => {
  const { content, setSelectedJourney, carlineWithOffers } = props;
  const [imageUrl, setImageUrl] = useState<string>(null);
  const height = useWindowHeight();
  const width = useWindowWidth();

  useEffect(() => {
    if (content.carline.title === 'Taigo') {
      setImageUrl('https://assets.volkswagen.com/is/image/volkswagenag/taigo_suvperheroes_v2?Zml0PWNyb3AlMkMxJndpZD0xMDM5JmhlaT01MjAmZm10PXBuZy1hbHBoYSZiZmM9b2ZmJmIzZmE=');
    } else if (content.carline.title === 'T-Cross') {
      setImageUrl('https://assets.volkswagen.com/is/image/volkswagenag/t-cross_suvperheroes_v3?Zml0PWNyb3AlMkMxJndpZD0xMDQyJmhlaT01ODYmZm10PXBuZy1hbHBoYSZiZmM9b2ZmJjVlZWU=');
    } else {
      setImageUrl('https://assets.volkswagen.com/is/image/volkswagenag/t-roc_suvperheroes_v3?Zml0PWNyb3AlMkMxJndpZD0xMDI4JmhlaT01NzgmZm10PXBuZy1hbHBoYSZiZmM9b2ZmJmIwNTU=');
    }
  }, [content]);

  return (
    <Wrapper>
      <TextWrapper>
        <Text appearance={TokenTextAppearance.headline400} bold>
          {content.carline.title}{' '}
          <Text appearance={TokenTextAppearance.label200} bold={false}>por</Text>
        </Text>
        <Text appearance={TokenTextAppearance.headline400} bold>
          {' '}{content.cuota}€
          <Text appearance={TokenTextAppearance.label200} bold={false}>/mes<sup>*</sup></Text>
        </Text>
        <Container padding={content.myWay.activo === 1 ? null : { bottom: ContainerPadding.static250, }}>
          <Text appearance={TokenTextAppearance.headline400} bold>
            <Text appearance={TokenTextAppearance.label200} bold={false}>con{' '}</Text>
            <Text>{content.financiacionText.replace('con', '')}</Text>
          </Text>
        </Container>
        {content.myWay.activo === 1 ?
          <Container padding={{ bottom: ContainerPadding.static250, }}>
            <Text appearance={TokenTextAppearance.label150}>Cuota final: {formatNumber(content.cuotaFinal)}€</Text>
          </Container>
          : null
        }
        <ThemeProvider theme='main'>
          <CTAOfertaSuv setSelectedJourney={setSelectedJourney} carlineWithOffers={carlineWithOffers} />
        </ThemeProvider>
      </TextWrapper>

      {
        imageUrl && <CarImage height={height} width={width} name={content.carline.title} src={imageUrl} alt={content.carline.title} />
      }
    </Wrapper>
  );
};
