import { styled } from '@volkswagen-onehub/components-core';
import React, { useState } from 'react';
import { Points } from 'src/types';
import { Img, Wrapper } from 'src/feature-app/NewMap/components/CommonStyledComponents';
import { useMapGeneralController } from 'src/feature-app/NewMap/MapGeneralController';

const Dot = styled.div<{ greyCard: boolean }>`
  height: 4px;
  width: 4px;
  background-color: ${(props) => (props.greyCard ? '#6A767D' : '#0040c5')};
  border-radius: 100%;
  display: inline;
  position: absolute;
  bottom: 0;
  left: 44%;
`;

interface DealerMarkerProps {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  lat: number;
  lng: number;
  isGrey: boolean;
  isActive: boolean;
  isHover: boolean;
  isFavouriteDealer: boolean;
}

export const isDealerGrey = (carPickupSelected: boolean, replacementCarSelected: boolean, point: Points) =>
  pickupSelectedAndDealerHasIt(carPickupSelected, point) ||
  replacementCarSelectedAndDealerHasIt(replacementCarSelected, point);

export const isDealerActive = (selectedPoint: Points, point: Points) =>
  selectedPoint?.properties?.dealer?.kvps === point?.properties?.dealer?.kvps;

export const isDealerFavourite = (favouriteDealer: Points, point: Points) =>
favouriteDealer?.properties?.dealer?.kvps === point?.properties?.dealer?.kvps;

export const isDealerHovered = (hoverPoint: Points, point: Points) =>
hoverPoint?.properties?.dealer?.kvps === point?.properties?.dealer?.kvps;

const pickupSelectedAndDealerHasIt = (carPickupSelected: boolean, point: Points) =>
  carPickupSelected && !point?.properties?.dealer?.dmsInfo?.pickupCarServiceAvailabe;
const replacementCarSelectedAndDealerHasIt = (replacementCarSelected: boolean, point: Points) =>
  replacementCarSelected && !point?.properties?.dealer?.dmsInfo?.replacementCarServiceAvailable;

export const DealerMarker = React.memo(
  ({
    onClick,
    isGrey,
    isActive,
    isHover,
    isFavouriteDealer
  }: DealerMarkerProps) => {
    const markerDimensions = useMapGeneralController().markerDimensions;
    const [mouseEntered, setMouseEntered] = useState(false);

    return (
      <Wrapper
        onClick={onClick}
        onMouseEnter={(e) => {
          e.preventDefault();
          setMouseEntered(true);
        }}
        onMouseLeave={(e) => {
          e.preventDefault();
          setMouseEntered(false);
        }}
        onTop={isActive || isHover ||mouseEntered ? true : false}
      >
        <Img
          markerDimensions={markerDimensions}
          src={require(isGrey
            ? '../assets/dealer_marker_grey.png'
            : isFavouriteDealer
            ? '../assets/favourite_dealer.png'
            : isActive || isHover ||mouseEntered
            ? '../assets/dealer_marker_selected.png'
            : '../assets/dealer_marker.png')}
          className="dealer-marker"
        />
        {isActive ? <Dot greyCard={isGrey} /> : null}
      </Wrapper>
    );
  }
);
