import {
  Breakpoints,
  Container,
  ContainerPadding,
  ContainerGutter,
  CTA,
  Layout,
  Text,
  ThemeProvider,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { ArrowLeft, Close, Locate, Magnifier, CarPickupService, FleetServicePrivate } from 'src/icons-core-imports';

import React, { useEffect, useState } from 'react';
import { useFeatureAppConfig } from 'src/feature-app';
import { SelectedTab } from 'src/types';
import { Carousel, Filtros, NewMap, useMapGeneralController } from 'src/feature-app/NewMap';
import { Points } from 'src/types';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useRef } from 'react';
import { 
  CloseButtonWrapper, 
  ControllerBackground, 
  CTAsNavigationWrapper, 
  CTASwrapper, 
  FilterMobileNavbar, 
  SmallerSVG, 
  SoloCardWrapper, 
  VerMasTalleresCTA, 
  VerMasYCarouselPosition,
  handleClickCarPickup,
  handleClickReplacementCar,
  MoblieListWrapper 
} from '.';
import { useFeatureServices } from 'src/feature-app/hooks';

const listIcon = require('../../../feature-app/NewMap/assets/filter-ic_list.svg');

interface ViewCitaPosventaProps {
  handleSeleccionarOnClick: any;
  handleShowSlots?: (point: Points) => string | null;
  handleShowCalendarOnClick?: (point: Points) => Promise<void>;
  renderPoints(points: any, carPickupSelected?: boolean, replacementCarSelected?: boolean, isTime?: boolean, isCarouselCard?: boolean ): JSX.Element[];
  renderCalendar?(): JSX.Element;
  showCalendar?: boolean;
  preSelectedOrderValue?: SelectedTab;
  setPreSelectedOrderValue?: React.Dispatch<React.SetStateAction<SelectedTab>>;
  pointsToList: Points[];
  isPosventa?: boolean;
  services?: boolean;
  concesionario?: boolean;
  onlySuggest?: boolean; 
  paddingTopList?: string;
}

export const LayerMobileView = (props: ViewCitaPosventaProps) => {
  const {
    handleSeleccionarOnClick,
    handleShowSlots,
    renderPoints,
    handleShowCalendarOnClick,
    showCalendar,
    renderCalendar,
    preSelectedOrderValue,
    setPreSelectedOrderValue,
    pointsToList, isPosventa, services,
    concesionario, onlySuggest, paddingTopList
  } = props;
  const { handlePreviousStep } = useOneFormContext();

  const {
    centerInGeoLocatedCenter,
    userIsGeoLocated,
    searchIsDealer,
  } = useMapGeneralController();

  //controladores de vista

  const [showFiltros, setShowFiltros] = useState<boolean>(false);
  const [showTalleres, setShowTalleres] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(true);
  const [translateCarousel, setTranselateCarousel] = useState<boolean>(false);
  const [ preselectedDealerForm, setPreselectedDealerForm ] = useState(false);
  const config = useFeatureAppConfig();
  const sustitucionMapRef = useRef(null);
  const recogidaMapRef = useRef(null);
  const sustitucionRef = useRef(null);
  const recogidaRef = useRef(null);
  const layerManager = useFeatureServices()['layer-manager'];
  useEffect(()=>{
    if(config.trigger === 'cita-posventa-dealer') {
      setPreselectedDealerForm(true);
    } else {
      setPreselectedDealerForm(false);
    }
  }, []);

  //otros
  const [gelocalizationActive, setGelocalizationActive] = useState<boolean>(false);

  useEffect(() => {
    if (userIsGeoLocated) {
      setGelocalizationActive(true);
    }
  }, [userIsGeoLocated]);

  const handleShowFiltros = () => {
    setShowFiltros(!showFiltros);
    setShowTalleres(false);
  };
  const handleShowTalleres = () => {
    setShowTalleres(!showTalleres);
    setShowFiltros(false);
  };

  useEffect(() => {
    if (showFiltros === false && showTalleres === false) {
      setShowMap(true);
    } else {
      setShowMap(false);
    }
  }, [showFiltros, showTalleres]);

  //seleccionar los que tiene coche de sustitucion o servicio de recogida

  const [ carPickupSelected, setCarPickupSelected] = useState<boolean>(false);
  const [ replacementCarSelected, setReplacementCarSelected ] = useState<boolean>(false);

  return showCalendar ? (
    renderCalendar()
  ) : (
    <div className="mobile-map-wrapper">
      {showMap ? (
        <>
          <ThemeProvider theme="main">
            <FilterMobileNavbar className="filtros-navbar" >
              <CTAsNavigationWrapper services={services}>
                <CTA
                  tag="button"
                  emphasis="tertiary"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    const layers = layerManager.getLayers();
                    const layer = layers.find(
                      (layer) => layer.layer.options.id === 'mapaLeadInPage'
                    );               
                    if (layer) {
                      layer.layer?.close();
                    }
                    handlePreviousStep(e);
                  }}
                  ariaLabel="aria label"
                  icon={<ArrowLeft />}
                />
                {
                  preselectedDealerForm ? null : (
                    <CTA
                      tag="button"
                      emphasis="secondary"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShowFiltros();
                      }}
                      ariaLabel="aria label"
                      icon={<Magnifier variant="small" />}
                    >
                      Buscar de nuevo
                    </CTA>
                  )
                }
              </CTAsNavigationWrapper>
              {
                services ? (
                  <Container 
                    horizontalAlign={'flex-start'}
                    gutter={ContainerGutter.static200}
                  >
                    <SmallerSVG>
                      <CTA 
                        ref={sustitucionMapRef}
                        tag='button' 
                        emphasis={replacementCarSelected ? 'primary':'secondary' }
                        size='small' 
                        onClick={(e) => {
                          e.preventDefault();
                          handleClickReplacementCar(replacementCarSelected, setReplacementCarSelected);
                          sustitucionMapRef.current.blur();
                        }} 
                        ariaLabel='aria label'
                        icon={<FleetServicePrivate/>}
                      >
                        Coche de sustitución
                      </CTA>
                    </SmallerSVG>  
                    <SmallerSVG>
                      <CTA 
                        tag='button' 
                        ref={recogidaMapRef}
                        emphasis={carPickupSelected ? 'primary':'secondary' }
                        size='small' 
                        onClick={(e) => {
                          e.preventDefault();
                          handleClickCarPickup(replacementCarSelected, setReplacementCarSelected);
                          recogidaMapRef.current.blur();
                        }} 
                        ariaLabel='aria label'
                        icon={<CarPickupService/>}
                      >
                        Servicio de recogida
                      </CTA>
                    </SmallerSVG>
                  </Container>
                ) : null
              }
            </FilterMobileNavbar>
          </ThemeProvider>
          <div
            style={{
              height: '100vh',
              backgroundColor: 'grey',
              position: 'absolute',
              top: '0',
              width: '100%',
            }}
          >
            <NewMap
              searchIsDealer={searchIsDealer}
              setTranslateCarousel={setTranselateCarousel}
              translateCarousel={translateCarousel}
              preSelectedOrderValue={preSelectedOrderValue}
              preselectedDealerForm={preselectedDealerForm}
              carPickupSelected={carPickupSelected}
              replacementCarSelected={replacementCarSelected}
              isPosventa={isPosventa}
            />
          </div>

          {pointsToList ? (
            searchIsDealer ? (
              <SoloCardWrapper>
                {renderPoints(pointsToList, carPickupSelected, replacementCarSelected)}
              </SoloCardWrapper>
            ) : (
              <VerMasYCarouselPosition
                className="ver-mas-carousel-position"
                translateCarousel={translateCarousel}
                userIsGeoLocated={userIsGeoLocated}
              >
                <ThemeProvider theme="main">
                  <CTASwrapper>
                    {gelocalizationActive ? (
                      <ControllerBackground
                        onTouchStart={(e) => {
                          e.preventDefault();
                          centerInGeoLocatedCenter();
                          e.stopPropagation();
                        }}
                      >
                        <CTA
                          tag="button"
                          emphasis="secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            centerInGeoLocatedCenter();
                            e.stopPropagation();
                          }}
                          ariaLabel="aria label"
                          icon={<Locate variant="small" />}
                        />
                      </ControllerBackground>
                    ) : null}
                    <VerMasTalleresCTA
                      onTouchStart={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleShowTalleres();
                      }}
                    >
                      <img src={listIcon} style={{ marginRight: '4px' }} />
                      <Text appearance={TokenTextAppearance.label150} staticSize>
                        Ver lista
                      </Text>
                    </VerMasTalleresCTA>
                  </CTASwrapper>
                </ThemeProvider>

                {/* Aunque en principio no debería devolver nunca sólo un resultado, dejo el siguiente
                render condicional por is acaso */}
                {pointsToList.length === 1 ? (
                  <SoloCardWrapper>
                    {renderPoints(pointsToList, carPickupSelected, replacementCarSelected)}
                  </SoloCardWrapper>
                ) : (
                  <Carousel
                    handleShowCalendarOnClick={handleShowCalendarOnClick}
                    handleSeleccionarOnClick={handleSeleccionarOnClick}
                    handleShowSlots={handleShowSlots}
                    pointsToList={pointsToList}
                    isCitaPosventa={isPosventa}
                    carPickupSelected={carPickupSelected}
                    replacementCarSelected={replacementCarSelected}
                  />
                )}
              </VerMasYCarouselPosition>
            )
          ) : null}
        </>
      ) : (
        <>
          {showTalleres ? (
            <ThemeProvider theme='inverted'>
              <div
                style={{
                  height: '100%',
                  minHeight: '100vh',
                  width: '100%',
                  backgroundColor: '#001E50',
                }}
              >
                <CloseButtonWrapper isTabsClose={true}>
                  <CTA
                    tag="button"
                    emphasis="tertiary"
                    size="small"
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleShowTalleres();
                    }}
                    ariaLabel="aria label"
                    icon={<Close />}
                  />
                </CloseButtonWrapper>
                {
                  services ? (
                    <Container 
                      horizontalAlign={'flex-start'}
                      gutter={ContainerGutter.static200}
                      padding={{ bottom: ContainerPadding.static200, top: ContainerPadding.static520, left:ContainerPadding.static200 }}
                    >
                      <SmallerSVG>
                        <CTA 
                          ref={sustitucionRef}
                          tag='button' 
                          emphasis={replacementCarSelected ? 'primary':'secondary' }
                          size='small' 
                          onClick={(e) => {
                            e.preventDefault();
                            handleClickReplacementCar(replacementCarSelected, setReplacementCarSelected);
                            sustitucionRef.current.blur();
                          }} 
                          ariaLabel='aria label'
                          icon={<FleetServicePrivate/>}
                        >
                          Coche de sustitución
                        </CTA>
                      </SmallerSVG>  
                      <SmallerSVG>
                        <CTA 
                          ref={recogidaRef}
                          tag='button' 
                          emphasis={carPickupSelected ? 'primary':'secondary' }
                          size='small' 
                          onClick={(e) => {
                            e.preventDefault();
                            handleClickCarPickup(carPickupSelected, setCarPickupSelected);
                            recogidaRef.current.blur();
                          }} 
                          ariaLabel='aria label'
                          icon={<CarPickupService/>}
                        >
                          Servicio de recogida
                        </CTA>
                      </SmallerSVG>
                    </Container>
                  ) : null
                }
                
                <Layout
                  appearance={{
                    [Breakpoints.default]: [
                      { name: '.', columns: 2 },
                      { name: 'a', columns: 20 },
                      { name: '.', columns: 2 },
                    ],
                  }}
                >
                  <Container
                    padding={{ bottom: ContainerPadding.static500 }}
                    horizontalAlign={'center'}
                    stretchContent
                    wrap={'always'}
                  >
                    <MoblieListWrapper paddingTop={paddingTopList}>
                      {renderPoints(pointsToList, carPickupSelected, replacementCarSelected)}
                    </MoblieListWrapper>
                  </Container>
                </Layout>
              </div>
            </ThemeProvider>
          ) : showFiltros ? (
            <ThemeProvider theme="main">
              <div
                style={{ minHeight: '100vh', height: '100%', width: '100%', backgroundColor: ' #ffffff' }}
                className="filtros-wrapper" 
              >
                <CloseButtonWrapper isFiltrosClose>
                  <CTA
                    tag="button"
                    emphasis="tertiary"
                    size="small"
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleShowFiltros();
                    }}
                    ariaLabel="aria label"
                    icon={<Close />}
                  />
                </CloseButtonWrapper>
                <Filtros
                  closeFiltros={() => handleShowFiltros()}
                  preSelectedOrderValue={preSelectedOrderValue}
                  setPreSelectedOrderValue={setPreSelectedOrderValue}
                  showFiltros={showFiltros}
                  setShowFiltros={setShowFiltros}
                  citaPosventa = {isPosventa}
                  onlySuggest={onlySuggest}
                  concesionario={concesionario}
                />
              </div>
            </ThemeProvider>
          ) : null}
        </>
      )}
    </div>
  );
};
