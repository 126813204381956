import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { getInstallations, getInstallationsLegacy } from 'src/bff';
import { DealersData, DomainAttributesValues, Installation, InstallationResponse, Points } from 'src/types';
import { useIsComerciales } from './use-is-comerciales';
import { provinces } from '../NewMap';

export function useGetInstalaciones(token?: string, domain?: string): string {
  const dispatch = useDispatch();
  const [dealerName, setDealerName] = useState(null);
  const store = useStore()

  useEffect(() => {
    const requestInstallations = async () => {
		
		const installationsResponse = await getInstallations(token, domain);

      // La respuesta viene como una array de arrays de attributes. Eliminamos este nivel para simplificar.
      const installationsDomainAtt: DomainAttributesValues[][] = installationsResponse.map((inst) => inst.attributes);
      const isComerciales = useIsComerciales();
      const selectedCarlineCode = store.getState().dealersInfo?.selectedCarline?.code;
      const excludedInstallationCodes = ['03812', '03394', 'EX1Q2', '0311X', '0321C', 'EX2C3', 'EX2H4', '30559', '53V50', '0310W', '30724', '03917', '30083', 'EX2Y1', 'EX1P0', '03910']
      const isIdBuzz = () => selectedCarlineCode === 72005 || selectedCarlineCode === 72006;

      const getProvince = (provinceCode: string): string | null => {
        const province = provinces.find(p => p.attributes.some(attr => attr.value === provinceCode));
        return province ? province.attributes.find(attr => attr.name === 'NAME')?.value?.toUpperCase() ?? null : null;
      };

      let points: Points[] = [];
      let installations: Installation[] = [];

      installationsDomainAtt.forEach((installation) => {
        const newInstallation: Installation = {
          Id: '',
          VGED_CommercialName__c: '',
          ShippingStreet: '',
          ShippingPostalCode: '',
          ShippingCity: '',
          ShippingLatitude: '',
          ShippingLongitude: '',
          VGED_DealerCode__c: '',
          ShippingStateCode: '',
          KVPSCode__c: '',
          Province: '',
        };

        installation.forEach((property) => {
          const { name, value } = property;
          if (name === 'ShippingStateCode') {
            newInstallation[name] = value;
            const provinceName = getProvince(value);
            newInstallation['Province'] = provinceName ?? '';
          }
          else {
            (newInstallation.hasOwnProperty(name))
            newInstallation[name] = value;
          }
        });

        if (
          !newInstallation.ShippingLatitude ||
          !newInstallation.ShippingLongitude ||
          newInstallation.ShippingLatitude === '' ||
          newInstallation.ShippingLongitude === ''
        ) {
          return;
        }

        // Necesario para el correcto funcionamiento del mapa.
        const dealer: Partial<DealersData> = {
          kvps: newInstallation.KVPSCode__c,
          name: newInstallation.VGED_CommercialName__c,
          markerInfo: {
            dealerInfo: {
              name: newInstallation.VGED_CommercialName__c,
              address: newInstallation.ShippingStreet,
              zipCode: newInstallation.ShippingPostalCode,
              city: newInstallation.ShippingCity,
              province: newInstallation.Province,
            },
          },
        };

        const point = {
          type: 'Feature',
          properties: { cluster: false, installation: newInstallation, dealer }, //concesionId: dealer.id, dealer: dealer
          geometry: {
            type: 'Point',
            coordinates: [newInstallation.ShippingLongitude, newInstallation.ShippingLatitude],
          },
        };

        const isExcludedInstallation = (): boolean => {
          return excludedInstallationCodes.includes(newInstallation.VGED_DealerCode__c)
        }
        if (isComerciales && !isIdBuzz() && isExcludedInstallation()) {
          return
        }
        else {
          points.push(point);
          installations.push(newInstallation);
        }
      });
      dispatch({ type: 'SET_INSTALLATIONS', payload: installations });
      dispatch({ type: 'SET_POINTS', payload: points });
      dispatch({ type: 'DEALERS_OR_INSTALLATIONS_MODE', payload: 'installations' });
    };

    requestInstallations();
  }, []);
  return dealerName;
}
