import { FeatureAppEnvironment } from '@feature-hub/core';
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { AnyAction, Store } from 'redux';
import { LayerManagerWrapper } from 'src/feature-app';

import {
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';

export const AvisoIslasCanarias = ({ close }: { close: CloseHandleV2<any, any> }) => {
  const [href, setHref] = useState(null);
  const [copy, setCopy] = useState(null);

  const store = useStore();

  const { formData } = store.getState();

  useEffect(() => {
      if (formData.fields.formName === 'cita-posventa') {
          setHref('https://www.vwcanarias.com/es/cita-previa.html');
          setCopy('Para pedir cita en un Servicio Oficial de las islas, visita la web de Volkswagen Canarias y haz la reserva desde ahí.');
      } else {
          setHref('https://cita.vwcanarias.com/');
          setCopy('Para pedir cita en un punto de venta de las islas, visita la web de Volkswagen Canarias y haz la reserva desde ahí.');
      }
  }, []);

  return (
    <Container
      stretchContent
      padding={{ top: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001 }, bottom: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001 }, left: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001 }, right: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001 } }}
    >
      <Container
        wrap={'always'}
        stretchContent
        gutter={ContainerGutter.static200}
        padding={{ bottom: ContainerPadding.static350 }}
      >
        <Text bold appearance={TokenTextAppearance.headline300}>
          ¿Estás en las Islas Canarias?
        </Text>
        <Text appearance={TokenTextAppearance.copy200}>
          {copy}
        </Text>
      </Container>
      <Container
        wrap={{ 0: 'always', 960: 'never' }}
        gutter={{ 0: ContainerGutter.static300, }}
      >
        <CTA tag="a" href={href} emphasis="primary">
          Ir a Volkswagen Canarias
        </CTA>
        <CTA
          tag="button"
          emphasis="secondary"
          onClick={(e) => {
              e.preventDefault();
              close({}, {}, {});
          }}
        >
          Seguir en esta página
        </CTA>
      </Container>
    </Container>
  );
};

export const renderAvisoIslasCanariasLayer = (
  store: Store<any, AnyAction>,
  env: FeatureAppEnvironment<any, any>,
  layerManager: LayerManagerV2
) => {

  const render = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
          <AvisoIslasCanarias close={close} />
      </LayerManagerWrapper>
  );
  };

  layerManager.openFocusLayer(
    render,
    {},
    { size: FocusLayerSizeV2.B, userCloseable: true }
  );
};
