import React, { useEffect, useState } from 'react';
import { ConfirmationTestID, ThankYouTestID } from './';
import { QuestionWithImage } from './QuestionWithImage';
import { dpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import {
  formatPrefix,
  FormController,
  removeWhiteSpace,
  useOneFormContext,
} from 'src/feature-app';
import {
  FormDataGroup,
  FormInfo,
  OneFormState,
  Steps,
} from 'src/types';
import { formDataBuilder } from 'src/forms/format-fields';
import { questions } from './';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { useDispatch, useStore, useSelector } from 'react-redux';

interface TestIDFormProps {
  dpsData?: any;
  idPerson?: any;
  handleError?: any;
  email?: string;
  name?: string;
  surname?: string;
}

export function TestIDForm(props: TestIDFormProps) {
  const { idPerson } = props;
  const { initializeForm } = useOneFormContext();
  const trackingManager = useTrackingManager();
  const store = useStore();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const name: string = props.name ? props.name : props.idPerson?.FirstName;
  const surname: string = props.surname ? props.surname : props.idPerson?.LastName;
  const email: string = props.email ? props.email : props.idPerson?.TXT_Email__c;

  useEffect(() => {
    dispatch({ type: 'UPDATE_FIELDS', payload: { email, name, surname } });
    setLoading(false);
  }, []);

  const steps: Steps[] = [
    ...questions.map((question, index) => {
      return {
        fields: <QuestionWithImage {...question} index={index} />,
        screenIndex: index,
        name: question.questionName,
        showOverflow: true,
      }
    }),
    {
      fields: <ConfirmationTestID idPerson={idPerson} />,
      screenIndex: questions.length,
      name: 'Confirmacion',
      outputs: ['confirmacion'],
      outputsText: ['confirmacion'],
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    fields: {
      formName: 'adhoc-2023-test_id',
      formType: 'otherform',
      pageCategory: 'Concurso'
    }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const generateData = (data?: Partial<FormDataGroup>) => {
    const { formData }: OneFormState = store.getState();
    const { fields } = formData;
    const { tc, today, url, device } = getParamsForm();

    return {
      'headers[COD_FORM_ID]': fields.formName ? `vw-oneform-${fields.formName}` : `vw-oneform-${data.fields.formName}`,
      'headers[DESC_BRAND]': 'VW',
      'headers[DESC_CAMPAIGNBLOCK]': '4. VW Newsletter',
      'headers[DESC_DEVICETYPE]': device,
      'headers[DESC_FORM_DETAIL]': 'Test ID 2023',
      'headers[DESC_FORMOBJECTIVE]': 'Concurso',
      'headers[DESC_GDPR_ADAPTED]': 'true',
      'headers[DESC_ID_CAMPAIGN]': '7017Q000000DUOnQAO',
      'headers[DESC_TOKEN]': 'VW_DDB_FRM_TEST_ID_2023',
      'headers[DESC_URLORIGIN]': url,
      'headers[DESC_VN_VO]': 'VN',
      'headers[FECHA_INSERTION_DATE]': today,
      'headers[TC]': tc,

      'fields[DESC_AUXFIELD1]': fields.answers, // respuestas correctas
      'fields[DESC_AUXFIELD2]': fields.location,
      'fields[DESC_EMAIL_ADDRESS]': fields.email ? fields.email : idPerson? idPerson.TXT_Email__c : null,
      'fields[DESC_FORM_VERSION]': fields.formName
        ? `vw-oneform-${fields.formName}-${process.env.VERSION}`
        : `vw-oneform-${data.fields.formName}-${process.env.VERSION}`,
      'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
      'fields[DESC_LOPD]': 'PERMITE',
      'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
      'fields[DESC_NAME]': fields.name ? fields.name : idPerson.FirstName,
      'fields[DESC_PREFERRED_CONTACT_CHANNEL]': fields.contact,
      'fields[DESC_SURNAME_1]': fields.surname ? fields.surname : idPerson.LastName,
      'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
      'fields[NUM_CELLPHONE]': fields.telefono ? `${formatPrefix(fields.prefix)}${fields.telefono}` : idPerson.NUM_Phone_mobile__c,

    };
  };

  const generateSubmitData = () => generateData();

  const sendForm = async (): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateSubmitData();
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData);

      if (response.data?.content?.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      thankYouComponent: <ThankYouTestID />,
    };

    if (!loading) {
      initializeForm(NuevoFormInfo, NuevoFormData, true);
      trackingManager.trackFormLoad();
    }
  }, [loading]);

  return loading || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
