import React from 'react';
import { useGetBrand } from 'src/feature-app/hooks';
import { LeadGenericoComercialesForm } from './LeadGenericoComercialesForm';
import { LeadGenericoForm, LeadGenericoFormProps } from './LeadGenericoForm';

export const LeadGenericoController = (props: LeadGenericoFormProps) => {
    const brand = useGetBrand();

    return brand === 'V' ? <LeadGenericoForm {...props} /> : brand === 'N' ? <LeadGenericoComercialesForm {...props} /> : null;
};
