import { Breakpoints, Layout } from '@volkswagen-onehub/components-core';
import React from 'react';
import { 
  LegalesFormatoLayer, 
  LssiTkmQuestions, 
  PoliticaPrivacidad, 
  LastNavigation, 
  GeneralConfirmacionLayerWrapper 
} from 'src/components';

export const ConfirmacionSAC = () => {

  return (
    <GeneralConfirmacionLayerWrapper>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
        }}
      >
        <LssiTkmQuestions showTitle={true}/>
        <PoliticaPrivacidad />

        <LastNavigation
          KoTitle="Uy, algo ha pasado"
          KoBody="Por alguna razón no hemos podido guardar tu petición. ¿Lo intentamos de nuevo?"
          loadingCopy="Enviando tu petición"
        />

        <LegalesFormatoLayer showTitle={true} />
      </Layout>
    </GeneralConfirmacionLayerWrapper>
  );
};
