import { FormDataGroup } from 'src/types';

export const leadsStockPartialData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Stock' },
  fields: {
    formFields: { COMMENTS_BRAND: 'Usuario interesado en vehículos de stock sin haber encontrado disponibilidad en el rango de KM seleccionados' },
    formularioFlag: 'masInformacion',
    formName: 'lead-stock',
    formType: 'contact',
    pageCategory: 'Más Información',
  },
};

export const leadsStockInPagePartialData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Stock' },
  fields: {
    ...leadsStockPartialData.fields,
    formFields: undefined
  },
};
