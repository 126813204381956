import { getFeatureServices, getGoogleApiKeyServiceConfig, getMolecularServiceConfig } from 'src/feature-app';

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving Google API Key service config provider.');
  return null;
};

export const getGoogleApiKey = (): string | null => {
  const googleApiKeyService = getGoogleApiKeyServiceConfig();

  if (!googleApiKeyService) {
    return handleErrorInRetrievingExternalConfig();
  }

  return googleApiKeyService.customConfig?.apiKey;
};
