import axios, { AxiosResponse } from 'axios';
import { getDataUrl, getOneformBFFCdnApiKey, getOneformBFFCdnUrl, reportErrorSentry } from 'src/bff';
import { modelCar, DomainResponse } from 'src/types';

export const getVehiculosModelo = async (): Promise<modelCar[]> => {
  try {
    const dataUrl = getDataUrl();
    const response: AxiosResponse = await axios.get(`${dataUrl}/getVehiculosModelos`);
    return response.data.vehiculos;
  } catch (error) {
    reportErrorSentry(error);
    return null;
  }
};

export const fetchMarcas = async (token?: string): Promise<DomainResponse> => {
  const requestToken = token ? token : 'VW_DDB_FRM_LEM_REC_LEM';
  const oneformCdn = getOneformBFFCdnUrl();
  const oneformBFFApiKey = getOneformBFFCdnApiKey();

  try {
    const response = await axios.get(`${oneformCdn}/ndps/domain?token=${requestToken}&domain=BRANDEUROTAX`,
      {
        timeout: 10000,
        headers: {
          'X-Api-Key': oneformBFFApiKey
        }
      }
    );

    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};

export const fetchModelos = async (code: string, token?: string): Promise<DomainResponse> => {
  const requestToken = token ? token : 'VW_DDB_FRM_LEM_REC_LEM';
  const oneformCdn = getOneformBFFCdnUrl();
  const oneformBFFApiKey = getOneformBFFCdnApiKey();

  try {
    const response = await axios.get(`${oneformCdn}/ndps/domain?token=${requestToken}&domain=MODELOS_EUROTAX&relatedDomain=BRAND&relatedValue=${code}`,
      {
        timeout: 10000,
        headers: {
          'X-Api-Key': oneformBFFApiKey
        }
      }
    );

    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};