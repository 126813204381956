import React from 'react';
import {
  Text,
  ThemeProvider,
  CTA,
  styled,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { TriggerProps, useFaContent } from 'src/feature-app';
import { DiaDeLaMadreForm } from 'src/forms/FormsAdhoc/DiaDeLaMadre';

const MarginWrapper = styled.div`
  width: var(--size-grid020);
  padding: var(--size-grid002) var(--size-grid001) var(--size-grid006) var(--size-grid003);
  display: flex;
  flex-direction: column;

  @media all and (min-width: 560px) {
    margin-top: 40px;
    width: var(--size-grid008);
    margin-bottom: var(--size-grid004);
    padding: 0;
  }
  @media all and (min-width: 960px) {
    margin-top: 64px;
    width: var(--size-grid007);
    margin-bottom: calc(var(--size-grid002) + 40px);
  }
  @media all and (min-width: 1280px) {
    width: var(--size-grid006);
    margin-bottom: 0;
  }
  @media all and (min-width: 1600px) {
    margin-top: 80px;
  }
  @media all and (min-width: 1920px) {
    margin: 52px 0 72px var(--size-grid002);
    width: var(--size-grid005);
  }
`;

export const CajaCTATrigger = (props: TriggerProps) => {
  const { setSelectedJourney, setCTALabel } = props;

  const faContent = useFaContent();

  const getCtaLabel = () => (faContent?.cta ? faContent.cta : 'Dale amor');

  return (
    <ThemeProvider theme="inverted">
      <MarginWrapper>
        <div style={{ paddingBottom: '32px' }}>
          <Text appearance={TokenTextAppearance.headline300}>
            Solo falta <Text bold>la tuya</Text>
          </Text>
        </div>
        <div style={{ paddingBottom: '32px' }}>
          <Text appearance={TokenTextAppearance.copy200}>
            A veces un {'"'}te quiero{'"'} no puede esperar. Díselo ahora para entrar en el{' '}
            <Text bold>sorteo<sup>*</sup> de un finde para dos personas en tu parador favorito al volante del nuevo ID.4</Text>
            , haciendo lo que más desea tu madre:{' '}<Text bold>pasar tiempo contigo.</Text>
          </Text>
        </div>
        <div style={{ paddingBottom: '16px' }}>
          <CTA
            tag="button"
            onClick={() => {
              setCTALabel(getCtaLabel());
              setSelectedJourney(<DiaDeLaMadreForm />);
            }}
          >
            {getCtaLabel()}
          </CTA>
        </div>
        <div >
          <Text appearance={TokenTextAppearance.copy100}>
            <sup>*</sup>Sorteo exclusivo para clientes de Volkswagen.
          </Text>
        </div>
      </MarginWrapper>
    </ThemeProvider>
  );
};
