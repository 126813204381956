import React, { useEffect } from 'react';
import { Text, Container, ContainerPadding, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { OneElementTemplate } from 'src/feature-app/Screen';
import { ConfirmacionNavidad } from './ConfirmacionNavidad';
import {
  Email,
  PantallaNombreApellido,
  Telefono,
  YaTienesVolkswagen,
  NoCliente,
  MultipleOptionCTAs,
  DetalleMotivo,
  ContactInfoWrapper
} from 'src/components';
import { FriendsInfo } from './ComponentsAndViews';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { dpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps, MolecularResponse } from 'src/types';
import {
  FormController,
  removeWhiteSpace,
  useFeatureAppConfig,
  useOneFormContext,
  formatPrefix,
} from 'src/feature-app';
import { ThankYouNavidad } from './ThankYouNavidad';
import { formatHeaders, formDataBuilder } from 'src/forms/format-fields';
import { addRemovedStep, getIndexInSteps, removeStep, resetStepsScreenIndex } from 'src/forms/helpers/add-remove-steps';
import { CTAsDisfrutarID4 } from 'src/feature-app/InputsValues';

export enum NavidadSteps {
  Cliente,
  NoCliente,
  NombreApellido,
  EnviarA,
  NoClienteContacto,
  EmailTelefono,
  MultipleChoice,
  MasInformacion,
  ImaginarExperiencia,
  Confirmacion,
}

interface NavidadFormProps {
  updatedFormData?: Partial<FormDataGroup>;
}

export function NavidadForm({ updatedFormData }: NavidadFormProps) {
  const { initializeForm, handleNextStep } = useOneFormContext();
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { screenIndex } = formInfo;
  const { cliente, multipleChoice } = formData.fields;
  const store = useStore();
  const config = useFeatureAppConfig();
  const dispatch = useDispatch();

  const handleStepsCliente = (cliente: string) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
    let newSteps = [...steps];

    if (cliente === 'true') {
      newSteps = removeStep(newSteps, 'NoCliente');
      newSteps = removeStep(newSteps, 'NoClienteContacto');
    } else {
      newSteps = removeStep(newSteps, 'NombreApellido');
      newSteps = removeStep(newSteps, 'EmailTelefono');
      newSteps = removeStep(newSteps, 'MultipleChoice');
      newSteps = removeStep(newSteps, 'MasInformacion');
      newSteps = removeStep(newSteps, 'ImaginarExperiencia');
    }

    newSteps = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newSteps });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newSteps[newSteps.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newSteps.length });
  };

  const handleMasInformacion = (multipleChoice: string[]) => {
    const { steps: actualSteps } = store.getState().formInfo;

    let newSteps = [...actualSteps];

    if (multipleChoice.includes('Otros')) {
      const stepIndex = getIndexInSteps(newSteps, 'MasInformacion');

      if (stepIndex === -1) {
        newSteps = addRemovedStep(steps, actualSteps, 'MasInformacion', screenIndex);
        setTimeout(() => {
          handleNextStep();
        }, 0);
      }
    } else {
      newSteps = removeStep(newSteps, 'MasInformacion');
    }

    const newStepsWithIndex = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
  };

  const steps: Steps[] = [
    {
      title: (
        <>
          Ya tienes un <Text bold>Volkswagen, </Text>
          <span style={{ whiteSpace: 'nowrap' }}>
            <Text bold>¿verdad? </Text>
            <sup style={{ fontSize: '60%' }}>1</sup>
          </span>
        </>
      ),
      fields: <OneElementTemplate element={<YaTienesVolkswagen />} />,
      screenIndex: NavidadSteps.Cliente,
      name: 'Cliente',
      outputs: ['cliente'],
      outputsText: ['cliente'],
      trueOrFalseQuestion: true,
    },
    {
      title: (
        <>
          Este sorteo es <Text bold>solo para clientes Volkswagen </Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <NoCliente
              name="noCliente"
              nextOnClick
              bodyCopy="Pero nosotros te ayudamos a compartir tus deseos con los Reyes Magos. ¿Quieres enviar tu lista de Navidad?"
            />
          }
        />
      ),
      screenIndex: NavidadSteps.NoCliente,
      name: 'NoCliente',
      outputs: ['noCliente'],
      outputsText: ['noCliente'],
      trueOrFalseQuestion: true,
    },
    {
      title: (
        <>
          Para que los Reyes sepan lo que deseas, necesitamos saber <Text bold>quién eres</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <div style={{ marginBottom: '2px' }}>
              <PantallaNombreApellido />
            </div>
          }
        />
      ),
      screenIndex: NavidadSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      title: (
        <>
          Y, <Text bold>¿a qué Reyes quieres que le enviemos tu lista de los deseos?</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <FriendsInfo
              nameNombre="friendName"
              nameMail="friendMail"
              labelNombre="Nombre de tu familiar o amigo"
              labelMail="Email de tu familiar o amigo"
            />
          }
        />
      ),
      screenIndex: NavidadSteps.EnviarA,
      name: 'EnviarA',
      outputs: ['friendName', 'friendMail'],
      outputsText: ['friendName','/', 'friendMail'],
    },
    {
      title: (
        <>
          <Text bold>¿De parte de quién </Text>se lo hacemos llegar?
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <FriendsInfo nameNombre="noClienteName" nameMail="noClienteMail" labelNombre="Nombre" labelMail="Email" />
          }
        />
      ),
      screenIndex: NavidadSteps.NoClienteContacto,
      name: 'NoClienteContacto',
      outputs: ['noClienteName', 'noClienteMail'],
      outputsText: ['noClienteName','/', 'noClienteMail'],
    },
    {
      title: (
        <>
          ¡Listo! <Text bold>¿Cómo podemos contactar contigo en caso de ganar el sorteo?</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <ContactInfoWrapper>
              <Telefono paginaConfirmacion={false} required={true} />
              <Email paginaConfirmacion={false} required={true} />
            </ContactInfoWrapper>
          }
        />
      ),
      screenIndex: NavidadSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      title: (
        <>
          Tenemos curiosidad, <Text bold>¿qué te atrae de disfrutar un ID.4?</Text>
          <Container padding={{ top: ContainerPadding.dynamic0100 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Puedes elegir dos opciones
            </Text>
          </Container>
        </>
      ),
      alternateTitle: (
        <>
          Tenemos curiosidad, <Text bold>¿qué te atrae de disfrutar un ID.4?</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <MultipleOptionCTAs
              ctaValues={CTAsDisfrutarID4}
              name="multipleChoice"
              minSelected={1}
              maxSelected={2}
              ignoreHandleClick={true}
            />
          }
        />
      ),
      screenIndex: NavidadSteps.MultipleChoice,
      name: 'MultipleChoice',
      outputs: ['multipleChoice'],
      outputsText: ['multipleChoice'],
    },
    {
      title: (
        <>
          <Text bold>¿Nos puedes detallar cuáles?</Text>
        </>
      ),
      fields: <OneElementTemplate element={<DetalleMotivo />} />,
      screenIndex: NavidadSteps.MasInformacion,
      name: 'MasInformacion',
      outputs: ['masInformacion'],
      outputsText: ['masInformacion'],
    },
    {
      title: (
        <>
          <Text bold>¿Cómo te imaginas la experiencia de conducir un ID.4?</Text>
          <Container padding={{ top: ContainerPadding.dynamic0100 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Estoy impaciente por ver cómo es la conducción<sup>1</sup> del nuevo ID.4, además de ....
            </Text>
          </Container>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <DetalleMotivo
              name="imaginarExperiencia"
              hideCTA={true}
              showRecomendation={
                <>
                  <sup>1</sup> Tus inquietudes son muy interesantes para personalizar tu experiencia, además de
                  compartirlas en nuestra web para ayudar a otros usuarios.
                </>
              }
            />
          }
        />
      ),
      screenIndex: NavidadSteps.ImaginarExperiencia,
      name: 'ImaginarExperiencia',
      outputs: ['imaginarExperiencia'],
      outputsText: ['imaginarExperiencia'],
    },
    {
      fields: <ConfirmacionNavidad />,
      screenIndex: NavidadSteps.Confirmacion,
      name: 'Confirmacion',
      isLastStep: true,
      hidePreviousStep: true,
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_VN_VO: 'VN',
      DESC_GDPR_ADAPTED: 'true',
      DESC_TOKEN: 'VW_DDB_FRM_NAVIDAD',
      DESC_ID_CAMPAIGN: '7011n000000JvfaAAC',
      DESC_FORM_DETAIL: 'Navidad 2021',
      DESC_CAMPAIGNBLOCK: 'B3 VW Emotional CRM',
      DESC_FORMOBJECTIVE: 'Concurso',
    },
    fields: {
      formName: 'adhoc-2021-navidad',
      formType: 'otherform',
      pageCategory: 'Concurso',
      ...updatedFormData?.fields,
    }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();

    const { fields, headers } = formData;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      const data = {
        'headers[COD_FORM_ID]': `vw-oneform-${fields.formName}`,
        'headers[DESC_URLORIGIN]': url,
        'headers[DESC_DEVICETYPE]': device,
        ...formatHeaders(headers),

        'headers[FECHA_INSERTION_DATE]': today,
        'headers[TC]': tc,

        'fields[DESC_NAME]': fields.noClienteName ? fields.noClienteName : fields.name,
        'fields[DESC_SURNAME_1]': fields.surname,
        'fields[DESC_SURNAME_2]': fields.secondSurname,
        'fields[DESC_EMAIL_ADDRESS]': removeWhiteSpace(fields.email),
        'fields[NUM_CELLPHONE]': `${formatPrefix(fields.prefix)}${fields.cellphone}`,
        'fields[DESC_AUXFIELD1]': fields.wishlist,
        'fields[DESC_AUXFIELD2]': fields.cliente,
        'fields[DESC_AUXFIELD3]': fields.friendName,
        'fields[DESC_AUXFIELD4]': fields.friendMail,
        'fields[DESC_AUXFIELD5]': fields.multipleChoice,
        'fields[DESC_AUXFIELD6]': fields.masInformacion,
        'fields[DESC_AUXFIELD7]': fields.imaginarExperiencia,
        'fields[DESC_FORM_VERSION]': `vw-oneform-adhoc_2021_navidad-${process.env.VERSION}`,
        'fields[DESC_LOPD]': 'PERMITE',
        'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
        'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
      };
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData);

      if (response && response.data && response.data.content && response.data.content.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      formTheme: 'inverted',
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      thankYouComponent: <ThankYouNavidad />,
    };

    initializeForm(NuevoFormInfo, NuevoFormData);
  }, []);

  //activacion logica steps

  useEffect(() => {
    if (cliente) handleStepsCliente(cliente);
  }, [cliente]);

  useEffect(() => {
    if (multipleChoice) {
      handleMasInformacion(multipleChoice);
    }
  }, [multipleChoice]);

  return <FormController steps={steps} screenType="layer-screen" />;
}
