import { CTA } from '@volkswagen-onehub/components-core';
import { CTAButtonProps } from '@volkswagen-onehub/components-core/dist/call-to-action';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { OneFormState } from 'src/types';

declare type Emphasis = 'primary' | 'secondary' | 'tertiary';
declare type Size = 'small' | 'large';

interface InputButtonProps extends CTAButtonProps {
  innerRef?: any;
  name: string;
  key: string | number;
  label: string;
  selectedValue?: string;
  size?: Size;
  ignoreHandleClick?: boolean;
  disabled?: boolean;
}

export function InputButton(props: InputButtonProps) {
  const trackingManager = useTrackingManager();

  const { innerRef, title, key, selectedValue, label, name, onClick, size, ignoreHandleClick, disabled } = props;
  const { formData } = useSelector((state: OneFormState) => state);

  const { control, register, setValue } = useFormContext();
  const [apparence, setApparence] = useState<Emphasis>('secondary');

  const handleClick = () => {
    apparence === 'secondary' ? setApparence('primary') : setApparence('secondary');
    setValue(name, label);
  };

  useEffect(() => {
    selectedValue === label ? setApparence('primary') : setApparence('secondary');
    const value = formData.fields[name];
    if (value && value === label) {
      setApparence('primary');
    }
  }, [selectedValue]);

  return (
    <Controller
      as={
        <CTA
          {...props}
          tag="button"
          ref={innerRef ? innerRef : register}
          emphasis={apparence}
          onClick={(e) => {
            e.preventDefault();
            trackingManager.trackFormButtonClick({
              contentId: label,
            });
            // if (onClick) onClick(e);
            // else handleClick();
            if (!ignoreHandleClick) {
              handleClick();
            }
            if (onClick) {
              onClick(e);
            }
          }}
          size={size ? size : 'large'}
          disabled={disabled}
        >
          {label}
        </CTA>
      }
      key={key}
      name={name}
      control={control}
    />
  );
}
