import React, { useEffect } from 'react';
import { TriggerProps } from 'src/feature-app';
import { OfertaForm } from 'src/forms';

export function LeadOfertasTrigger({ setSelectedJourney }: TriggerProps): JSX.Element {

  useEffect(() => {
    setSelectedJourney(<OfertaForm />, true);
  }, []);

  return null;
}
