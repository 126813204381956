import { styled } from '@volkswagen-onehub/components-core';
import { css } from 'styled-components';

interface styledWrapperProps {  
  notALayer?: boolean;
  isFiltrosSuggest: boolean;
  isLocating?: boolean;
  inputFocus?: boolean;
  formTheme?: string;
  isModeloSuggest?: boolean;
  isFullScreen?: boolean;
  goUp?: boolean;
  autoWidth?: boolean;
}


export const MagnifierLabel = styled.div<styledWrapperProps>`
  color: ${(props) => (props.isFiltrosSuggest || props.formTheme === 'inverted' ? '#001e50' : '#ffffff')};
  padding-right: 8px;
  display: flex;
  align-items: center;

  & svg {
    fill: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#6A767D' :'#dfe4e8'
      : props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#ffffff' : '#001e50'
    };
  }
`;
export const CloseButton = styled.button<styledWrapperProps>`
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  padding-left: 8px;
  display: flex;
  align-items: center;

  & svg {
    fill: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#6A767D' : '#dfe4e8'
      : props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#ffffff' : '#001e50'
    };
  }
`;

export const StyledWrapper = styled.div<styledWrapperProps>`
  display: flex;
  justify-content: flex-start;
  margin-top: ${(props) => (props.isFiltrosSuggest ? '24px' : null)};
  padding: 9px 16px;
  border-radius: 8px;
  box-shadow: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '0 0 0 1px #6A767D' : '0 0 0 1px #dfe4e8'
      : props.inputFocus
        ? '0 0 0 2px #00B0F0'
        : props.isFiltrosSuggest || props.formTheme === 'inverted'
          ? '0 0 0 1px #ffffff' : '0 0 0 1px #001E50'
    };

  & .react-autosuggest__container {
    width: 100%;
  }

  & .react-autosuggest__input {
    width: 100%;
    padding: 8px 0;
    border: none;
    background: transparent;
    color: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#6A767D' : '#dfe4e8'
      : props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#ffffff' : '#001e50'
    };
    /* Añadir puntos suspensivos para el placeholder en mobile */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* WebKit, Edge */
    ::-webkit-input-placeholder {
    color: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#6A767D' : '#dfe4e8' 
      : props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#ffffff' : '#001e50'
    };
    }
    /* Firefox 4-18 */
    :-moz-placeholder {
    color: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#6A767D' : '#dfe4e8'
      : props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#ffffff' : '#001e50'
    };
    opacity: 1;
    }
    /* Firefox 19+ */
    ::-moz-placeholder {
    color: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#6A767D' : '#dfe4e8'
      : props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#ffffff' : '#001e50'
    };
    opacity: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    }
    /* IE 10-11 */
    :-ms-input-placeholder {
    color: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#6A767D' : '#dfe4e8'
      : props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#ffffff' : '#001e50'
    };
    }
    /* Edge */
    ::-ms-input-placeholder {
    color: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#6A767D' : '#dfe4e8'
      : props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#ffffff' : '#001e50'
    };
    }
    /* MODERN BROWSER */
    ::placeholder {
    color: ${(props) =>
    props.isLocating
      ? props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#6A767D' : '#dfe4e8'
      : props.isFiltrosSuggest || props.formTheme === 'inverted'
        ? '#ffffff' : '#001e50'
    };
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    }
  }

  & .react-autosuggest__input--focused {
    outline: none;
  }

  & .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .react-autosuggest__suggestions-container {
    display: none;
    width: 100%;
  }

  & .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    height: max-content;
    border: 1px solid #cccccc;
	border-radius: 4px;
    background: #ffffff; 
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1060;
    overflow: hidden;
    border: none;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.12);

    left: 0;
    top: ${(props)=>props.goUp ? '0' : '53px'};
    transform: ${(props)=>props.goUp ? 'translateY(-100%)' : null};
    
    ${(props) =>
    props.isFiltrosSuggest
      ? css`
        width: calc( 100% + 68x); 
        @media screen and (min-width: 960px) {
          width: 400px; 
        }
      `
      : props.isModeloSuggest ?
        css`
          width: var(--size-grid020);
          @media all and (min-width: 560px) {
            width: ${(props) => (props.notALayer ? 'var(--size-grid013)' : '303px')};
          }
          @media all and (min-width: 960px) {
            width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '320px')};
          }
          @media all and (min-width: 1280px) {
            width: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '320px')};
          }
          @media all and (min-width: 1920px) {
            width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '320px')};
          }
          @media all and (min-width: 2560px) {
            width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '320px')};
          }       
        `
        : props.isFullScreen  ? 
          css`
            width: var(--size-grid020);
            @media all and (min-width: 960px) {
              width: ${(props) => (props.autoWidth ? '100%' : 'var(--size-grid013)')};
            }
            @media all and (min-width: 1280px) {
              width:${(props) => (props.autoWidth ? '100%' : 'var(--size-grid014)')};
            }
            @media all and (min-width: 1920px) {
              width: ${(props) => (props.autoWidth ? '100%' : 'var(--size-grid012)')};
            }
            @media all and (min-width: 2560px) {
              width: ${(props) => (props.autoWidth ? '100%' : 'var(--size-grid010)')};
            }
          `
          :css`
            width: calc( 100% + 68x); 
            @media screen and (min-width: 960px) {
              width: 720px;
            }
          `}
  }

  & .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: hidden scroll;
    height: max-content;
    max-height: 170px;
    @media all and (min-height: 500px) {
      max-height: 230px;
    }
    @media all and (min-height: 800px) {
      max-height: 300px;
    }
    @media all and (min-height: 800px) {
      max-height: 350px;
    }
  }

  & .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    left: 0;
    :hover {
      * {
        color: #0040c5;
      }
      svg {
        fill: #0040c5;
      }
    }
  }
`;

export const SuggestionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: #001e50;
`;
