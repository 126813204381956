import {
  Container,
  ContainerGutter,
  CTA,
  Text,
  styled,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOneFormContext } from 'src/feature-app';
import { useIsComerciales } from 'src/feature-app/hooks';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { CustomSlider, PaddingWrapper } from '.';

const KilContentWrapper = styled.div`
  padding-left: 32px;
  padding-right: 8px;
  width: 100vw;

  @media all and (min-width: 560px) {
    padding-left: 46px;
    padding-right: 32px;
  }
  @media all and (min-width: 960px) {
    padding-left: 120px;
    padding-right: 81px;
    width: 960px;
  }
`;

export function Kilometros() {
  const isCommerciales = useIsComerciales();
  const jumpRange = isCommerciales ? 50000 : 30000;
  const maxValue = isCommerciales ? 400000 : 180000;
  const numberOfSteps = isCommerciales ? 9 : 7;
  const [value, setValue] = useState(1);
  const [millageValue, setMillageValue] = useState<number | number[]>([0, jumpRange]);
  const [rangoKilometros, setRangoKilometros] = useState<string>(`0km - ${formatNumber(jumpRange)}km`);
  const dispatch = useDispatch();
  const { handleNextStep } = useOneFormContext();
  const trackingManager = useTrackingManager();

  // convertir int a string con '.' de separacion para los miles. ej: 30000 --> 30.000
  function formatNumber(num: number | number[]) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
  const handleInputChange = (event: any) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    const aux = jumpRange;
    const lowValue = aux * value - jumpRange;
    const highValue = aux * value;
    let stringRange;

    if (highValue > maxValue) {
      stringRange = isCommerciales ? 'Más de 400.000km' : 'Más de 180.000km';
      setMillageValue([lowValue, highValue]);
    } else {
      stringRange = `${formatNumber(lowValue)}km - ${formatNumber(highValue)}km`;
      setMillageValue([lowValue, highValue]);
    }
    setRangoKilometros(stringRange);

    trackingManager.trackFormFilterSelect(
      {
        contentId: 'Kilometros',
      },
      {
        type: 'range',
        name: 'Kilometros',
        lowerbound: String(lowValue),
        upperbound: String(highValue),
        values: undefined,
      },
      { FormFieldName: 'kilometros' }
    );
  }, [value]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FIELDS', payload: { kilometros: millageValue } });
  }, [millageValue]);

  return (
    <KilContentWrapper>
      <PaddingWrapper >
        <Container
          horizontalAlign={'center'}
          wrap={'always'}
          stretchContent
          gutter={ContainerGutter.static500}
        >
          <Text appearance={TokenTextAppearance.headline200} textAlign={TextAlignment.center}>
            {rangoKilometros}
          </Text>
          <CustomSlider
            value={value}
            handleInputChange={handleInputChange}
            formatNumber={formatNumber}
            maxValue={maxValue}
            numberOfSteps={numberOfSteps}
          />
        </Container>
      </PaddingWrapper>
      <PaddingWrapper >
        <Container horizontalAlign={'flex-start'}>
          <CTA
            tag="button"
            emphasis="secondary"
            onClick={(e) => {
              e.preventDefault();
              trackingManager.trackFormButtonClick({
                contentId: 'No estoy seguro',
              });
              setMillageValue(0);
              handleNextStep(e);
            }}
            ariaLabel="aria label"
          >
            No estoy seguro
          </CTA>
        </Container>
      </PaddingWrapper>
    </KilContentWrapper>
  );
}
