import React, { useState, useEffect } from 'react';
import { SelectExtended } from '.';
import { getActualYearNumber } from 'src/feature-app';
import { styled } from '@volkswagen-onehub/components-core';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { OneFormState } from 'src/types';
import { meses } from 'src/feature-app/InputsValues/OtherInputTypesObjects';
interface FechaCompraProps {
  notALayer: boolean;
}

const FechaCompraWrapper = styled.div<FechaCompraProps>`
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  width: var(--size-grid020);
  @media all and (min-width: 560px) {
    width: var(--size-grid013);
  }
  @media screen and (min-width: 960px) {
    margin-top: 0; 
    width: var(--size-grid008);
  }
  @media all and (min-width: 1280px) {
    width: var(--size-grid006);
  }
  @media all and (min-width: 1920px) {
    width: var(--size-grid005);
  }
  @media all and (min-width: 2560px) {
    width: var(--size-grid004);
  }
  label {
    margin-top: 40px;
    width: var(--size-grid009);

    @media all and (max-height: 624px) {
      margin-top: 20px;
    }

    @media all and (min-width: 560px) {
      width: var(--size-grid006);
    }
    @media screen and (min-width: 960px) {
      width: calc( var(--size-grid003) + (var(--size-grid001)/2) );
    }
    @media all and (min-width: 1280px) {
      width: calc( var(--size-grid002) + (var(--size-grid001)/2) );
    }
    @media all and (min-width: 1920px) {
      width: var(--size-grid002);
    }
    @media all and (min-width: 2560px) {
      width: calc( var(--size-grid001) + (var(--size-grid001)/2) );
    }
  }
`;
const MarginDiv = styled.div`
  width: var(--size-grid002);
  @media all and (min-width: 560px) {
      width: var(--size-grid001);
    }
`;

export const FechaCompra = (props: FechaCompraProps ) => {

  const { notALayer } = props;
  const dispatch = useDispatch();
  const { formData } = useSelector((state: OneFormState) => state);
  const { fecha } = formData.fields;

  //month
  const [ month, setMonth ] = useState<string>('Enero');

  const handleChangeMonth = (e:any) => {
    e.preventDefault();
    setMonth(e.target.value);
  };

  //year

  const actualYear = getActualYearNumber();
  const [ yearsArray, setYearsArray ] = useState(null);

  useEffect(()=>{
    const previousYear = dayjs(actualYear).subtract(1, 'y').locale('es').format('YYYY');

    setYearsArray([previousYear, actualYear]);
  }, [actualYear]);

  const [ year, setYear ] = useState<string>(actualYear);

  const handleChangeYear = (e:any) => {
    e.preventDefault();
    setYear(e.target.value);
  };

  //previous value

  useEffect(()=>{
    if(fecha) {
      const aux = fecha.split('/');
      const auxMonth = meses.find((m)=> m.number === aux[1]); 
      setMonth(auxMonth.name);
      setYear(aux[2]);
    }
  }, []);

  //send value

  const [ finalDate, setFinalDate ] = useState(null);

  useEffect(()=>{
    const aux = meses.find((m)=> m.name === month);

    setFinalDate(`01/${aux.number}/${year}`);
  }, [month, year]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FIELDS', payload: { fecha: finalDate } });
    if(finalDate) {
      const aux = finalDate.split('/');
      const mes = meses.find((m) => m.number === aux[1]);
      dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { fechaCompra : mes.name + ' / ' + aux[2] } });
    }
  }, [finalDate]);

  

  

  return (
    <FechaCompraWrapper notALayer={notALayer}>
      <SelectExtended
        label='Mes'
        options={meses.map((m, i)=> m.name)}
        name='month'
        isRequired={true}
        handleChange={handleChangeMonth}
        value={month}
        onClick={() => null}
        setOption={setMonth}
      />
      <MarginDiv className='just-for-margin'/>
      { yearsArray ? (
        <SelectExtended
          label='Año'
          options={yearsArray}
          name='year'
          isRequired={true}
          handleChange={handleChangeYear}
          value={year}
          onClick={() => null}
          setOption={setYear}
        />
      ) : null}
    </FechaCompraWrapper>
  );
};
