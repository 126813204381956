import * as React from 'react';
import * as Sentry from '@sentry/react';
import { CloseHandleV2 } from '@volkswagen-onehub/layer-manager';

interface Props {
  hub: Sentry.Hub;
  close?: CloseHandleV2<any, any>;
  children: React.ReactNode;
}
class ErrorBoundary extends React.Component<Props> {
  constructor(props: Props) {
      super(props);
      this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    /** 
     * We make use of the Sentry hub passed in props to report the application
     * error to the intended recipient Sentry project.
     * Note: if Sentry.init was used, it looks like they should get a copy of
     * that error in their Sentry project too (though this behaviour might only
     * be applicable in development, to be confirmed.)
     */
    // Sentry.makeMain(this.props.hub);
    this.props.hub.run(currentHub => {
      currentHub.withScope((scope) => {
          scope.setExtras(errorInfo);
          const eventId = currentHub.captureException(error);
          this.setState({eventId});
      });

    });
    if (this.props.close) {
      this.props.close({},{},{});
    }

  }

  render() {
      if (this.state.hasError) {
          //render fallback UI
          return null;
      }

      //when there's not an error, render children untouched
      return this.props.children;
  }
}

export default ErrorBoundary;
