import { isNull } from 'src/feature-app';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDealerKvps } from 'src/bff/api/get-dealer-kvps';
import { DealersData } from 'src/types';
import { getDealers } from 'src/bff';

export function useGetDealers(kvps?: string) {
  const dispatch = useDispatch();
  const [dealerName, setDealerName] = useState(null);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dealers, setDealers] = useState<DealersData[]>(null);

  useEffect(() => {
    const handleDealer = async (kvps: string) => {
      const selectedDealer = await getDealerKvps(kvps);

      if (selectedDealer) {
        setDealerName(selectedDealer.name);
        setSelectedDealer(selectedDealer);
        dispatch({ type: 'SET_SELECTED_DEALER', payload: selectedDealer });
        dispatch({ type: 'SET_URL_DEALER', payload: selectedDealer });
        dispatch({ type: 'UPDATE_SUGGESTION', payload: selectedDealer });
      } else {
        setDealerName(null);
        setSelectedDealer(null);
      }
    };

    if (window && window.location) {
      const urlParams = new URLSearchParams(window.location.search);
      const dealer = urlParams.get('dealer');

      if (dealer) {
        handleDealer(dealer);
      }
    }
    if (kvps) {
      handleDealer(kvps);
    }

    const requestDealers = async () => {
      const dealers = await getDealers();

      const points = dealers
        .map((dealer: DealersData) => {
          if (isNull(dealer.markerInfo.svcLocation)) return {};
          const { longitude, latitude } = dealer.markerInfo.svcLocation;
          return {
            type: 'Feature',
            properties: { cluster: false, concesionId: dealer.id, dealer: dealer },
            geometry: {
              type: 'Point',
              coordinates: [longitude, latitude],
            },
          };
        })
        .filter((point) => Object.keys(point).length > 0);

      dispatch({ type: 'SET_DEALERS', payload: dealers });
      dispatch({ type: 'SET_POINTS', payload: points });
      dispatch({ type: 'DEALERS_OR_INSTALLATIONS_MODE', payload: 'dealers' });
      setDealers(dealers);
      setLoading(false);
    };

    requestDealers();
  }, []);
  return { dealers, dealerName, selectedDealer, loading };
}
