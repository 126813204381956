import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCarlines } from 'src/bff';
import { CarlineSelect, Email, findCarline, PantallaNombreApellido } from 'src/components';
import { useFaContent } from 'src/feature-app';
import { OneElementTemplate } from 'src/feature-app/Screen';
import { createSalesgroup } from '../Leads';
import { ConfirmacionProspects } from './ConfirmacionProspects';
import { ProspectsVentaForm } from './ProspectsVentaForm';

enum ProspectsSteps {
  Email,
  NombreApellido,
  Confirmacion,
}

export function MatchProspectForm() {
  const faContent = useFaContent();
  const dispatch = useDispatch();

  const steps = [
    {
      title: (
        <>
          <Text bold>¿A qué email</Text>{' '}quieres que te enviemos esta configuración?
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <div style={{ marginBottom: '2px' }}>
              <Email paginaConfirmacion={false} required={true} />
            </div>
          }
        />
      ),
      screenIndex: ProspectsSteps.Email,
      name: 'Email',
      outputs: ['email'],
      outputsText: ['email'],
    },
    {
      title: (
        <>
          Para hacerlo, nos gustaría saber{' '}<Text bold>quién eres</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <div style={{ marginBottom: '2px' }}>
              <PantallaNombreApellido />
            </div>
          }
        />
      ),
      screenIndex: ProspectsSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    
    {
      fields: <ConfirmacionProspects />,
      screenIndex: ProspectsSteps.Confirmacion,
      name: 'Confirmacion',
      isLastStep: true,
      hidePreviousStep: true,
    },
  ];

  return (
    <ProspectsVentaForm
      customSteps={steps}
      updatedFormData={{
        fields: { formName: 'prospect-match' },
      }}
    />
  );
}
