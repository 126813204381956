import {} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { FeatureAppConfig } from 'src';
import { Dependencies } from 'src/feature-app';

const getOptions = (stage: 'dev' | string): Sentry.BrowserOptions => {
  return {
    dsn: 'https://c72951f5b7c54e769470906bceb05c92@o192159.ingest.sentry.io/6118409',
    integrations: [
      new Sentry.Integrations.InboundFilters(),
      new Sentry.Integrations.FunctionToString(),
      new Sentry.Integrations.TryCatch(),
      new Sentry.Integrations.Breadcrumbs({ console: true, dom: true, fetch: true, history: true, xhr: true }),
      new Sentry.Integrations.GlobalHandlers(),
      new Sentry.Integrations.LinkedErrors(),
      new Sentry.Integrations.Dedupe(),
      new Sentry.Integrations.UserAgent(),
    ],
    tracesSampleRate: 1,
    environment: stage === 'dev' ? 'Stage' : 'Prod',
    release: process.env.VERSION,

    beforeSend: (event: Sentry.Event) => {
      // Solo reportamos los errores en producción
      // if (stage === 'dev') {
      //   return null;
      // }
      event.exception.values = event.exception.values.map(value => {
        value.mechanism.handled = false;
        return value;
      });
      return event;
    },
  };
};

let sentryHub: Sentry.Hub = null;

export const initSentry = (featureServices: Dependencies, config: FeatureAppConfig) => {
  const stageCustomConfig = featureServices['service-config-provider'].configs?.stage?.customConfig;
  const faContent = featureServices['fa-content-service'].content;

  const client = new Sentry.BrowserClient(getOptions(stageCustomConfig?.stage));
  const hub = new Sentry.Hub(client);
  // Sentry.makeMain(hub); // Desactivado ya que provoca que se reporten errores de otros proyectos.
  hub.setContext('Feature App Config', { ...config });
  hub.setContext('Feature App Content', { ...faContent });
  setSentryHub(hub);
  return { hub };
};

export const getSentryHub = () => sentryHub;

export const setSentryTag = (tag:string, value: string) => {
  sentryHub.setTag(tag, value);
};

const setSentryHub = (newHub: Sentry.Hub) => {
  sentryHub = newHub;
};
