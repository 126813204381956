import {
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';

import React, { useEffect } from 'react';
import { Horario } from 'src/types';

interface FranjaHorariaProps {
  selectedFranjaHoraria: string;
  handleFranjaHoraria: (s: string) => void;
  horario: Horario;
}

export function FranjaHoraria(props: FranjaHorariaProps) {
  const { handleFranjaHoraria, selectedFranjaHoraria, horario } = props;

  useEffect(() => {
    if (horario === 'afternon') {
      handleFranjaHoraria('Mediodía');
    } else if (horario === 'evening') {
      handleFranjaHoraria('Tarde');
    } else {
      handleFranjaHoraria('Mañana');
    }
  }, []);

  return (
    <Container
      padding={{ top: ContainerPadding.static500 }}
      gutter={ContainerGutter.static400}
      horizontalAlign={'flex-start'}
      wrap={'always'}
    >
      <Text appearance={TokenTextAppearance.headline200} bold textAlign={TextAlignment.left}>
        Selecciona una franja horaria
      </Text>
      <Container gutter={ContainerGutter.static300} horizontalAlign={'flex-start'}>
        <CTA
          tag="button"
          emphasis={selectedFranjaHoraria === 'Mañana' ? 'primary' : 'secondary'}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            handleFranjaHoraria('Mañana');
          }}
        >
          Mañana
        </CTA>
        <CTA
          tag="button"
          emphasis={selectedFranjaHoraria === 'Mediodía' ? 'primary' : 'secondary'}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            handleFranjaHoraria('Mediodía');
          }}
        >
          Mediodía
        </CTA>
        <CTA
          tag="button"
          emphasis={selectedFranjaHoraria === 'Tarde' ? 'primary' : 'secondary'}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            handleFranjaHoraria('Tarde');
          }}
        >
          Tarde
        </CTA>
      </Container>
    </Container>
  );
}
