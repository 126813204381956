import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerPadding,
  styled,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LocateUserFullScreen } from 'src/components';
import { OneFormState } from 'src/types';
import { getColorInfo, ImageCar } from '.';

const LayoutWrapper = styled.div`
  @media all and (min-width: 960px) {
    display: flex;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  padding-left: var(--size-grid002);
  padding-right: var(--size-grid002);
  padding-top: 44px;
  @media all and (min-width: 5600px) {
    padding-top: 68px;
  }
  @media all and (min-width: 960px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: var(--size-grid012);
    padding-left: var(--size-grid002);
    padding-right: 0;
    padding-top: 0;
  }
  @media all and (min-width: 1280px) {
    padding-left: var(--size-grid003);
  }
  @media all and (min-width: 1600px) {
    padding-left: var(--size-grid002);
  }
`;

export const renderTitle = (formName: string) => {
  return (
    <>
      {formName === 'presupuesto' || formName === 'masInformacion' ? (
        <Text appearance={TokenTextAppearance.headline300}>
          {' '}
          <Text bold>¿Qué experto</Text> quieres que se{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      ) : (
        <>
          {formName === 'testDrive' ? (
            <Text appearance={TokenTextAppearance.headline300}>
              {' '}
              <Text bold>¿Dónde</Text> prefieres{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                provarlo?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
              </span>
            </Text>
          ) : (
            <Text appearance={TokenTextAppearance.headline300}>
              {' '}
              <Text bold>¿A dónde</Text> quieres{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                ir?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
              </span>
            </Text>
          )}
        </>
      )}
    </>
  );
};

export const LocateUserLeadsInPage = () => {
  const { formData } = useSelector((state: OneFormState) => state);
  const { marketingCodeData, carlineData, formName } = formData.fields;
  const [imageSrc, setImageSrc] = useState(null);
  const [name, setName] = useState(null);
  const [colorInfo, setColorInfo] = useState(null);

  useEffect(() => {
    if (!marketingCodeData) return;
    const { modelName, colorCode, mediaAssets } = marketingCodeData;
    setName(modelName);


    if (colorCode) {
      getColorInfo(colorCode).then((color) => {
        setColorInfo(color);
      });
    } else {
      setColorInfo('#484A52');
    }

    if (mediaAssets?.D6MOFASideview) {
      setImageSrc(mediaAssets?.D6MOFASideview);
    } else {
      setImageSrc(mediaAssets?.D6Explore1);
    }
  }, [marketingCodeData]);

  useEffect(() => {
    if (!carlineData) return;
    const { modelName, colorCode, mediaAssets } = carlineData;
    setName(modelName);

    if (colorCode) {
      getColorInfo(colorCode).then((color) => {
        setColorInfo(color);
      });
    } else {
      setColorInfo('#484A52');
    }

    if (mediaAssets?.D6MOFASideview) {
      setImageSrc(mediaAssets?.D6MOFASideview);
    } else {
      setImageSrc(mediaAssets?.D6Explore1);
    }
  }, [carlineData]);

  return (
    <LayoutWrapper className="layout-wrapper">
      <BreakpointWrapper max={Breakpoints.b960}>
        <ImageCar color={colorInfo?.hex} image={imageSrc} modelName={name} />
      </BreakpointWrapper>
      <InfoWrapper className="info-wrapper">
          <Text appearance={TokenTextAppearance.copy200} staticSize bold>
            {name}
          </Text>
        <Container padding={{ bottom: ContainerPadding.static350 }}>
          <Text appearance={TokenTextAppearance.headline300}>
            Genial,{' '}
            {formName.includes('mas_informacion') ? (
              <Text bold>te mandamos toda la información</Text>
            ) : formName.includes('test_drive') ? (
              <Text bold>te esperamos para una prueba de conducción</Text>
            ) : formName.includes('presupuesto') ? (
              <Text bold>te enviamos tu presupuesto personalizado</Text>
            ) : formName.includes('cita') ? (
              <Text bold>te esperamos para contártelo todo</Text>
            ) : (
              <Text bold>te mandamos toda la información</Text>
            )}
          </Text>
        </Container>
        <Text appearance={TokenTextAppearance.headline300}>
          {' '}
          <Text bold>¿Qué experto</Text> quieres que se{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            encargue?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
        <LocateUserFullScreen paddingTop="32px" paddingTopLocation="12px" autoWidth />
      </InfoWrapper>
      <BreakpointWrapper min={Breakpoints.b960}>
        <ImageCar color={colorInfo?.hex} image={imageSrc} modelName={name} />
      </BreakpointWrapper>
    </LayoutWrapper>
  );
};
