import React, { useState, useEffect} from 'react';
import { CTA, Text, TextAlignment, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackingManager } from 'src/feature-app/hooks';
import { OneElementTemplateFullScreen } from 'src/feature-app';
import { OneFormState } from 'src/types';
import {LastNavigationCEMOneShop} from '.';

const CTAWrapper = styled.div`
  text-align: left;
  margin: -10px;
  margin-top: 32px;
  & > button {
    margin: 12px;
    width: 81px;
  }
`;

const ErrorMessage = styled.div`
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #e4002c;
  color: #ffffff;
`;

function Fields() {
  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const [ selectedValue, setSelectedValue] = useState<string>('');
  const { control, register, setValue } = useFormContext();
  const { fields } = useSelector((state: OneFormState) => state.formData);
  const [showError, setShowError] = useState<boolean>(false);

  const CTAValues = ['Sí', 'No'];

  useEffect(() => {
    if (fields.enviarInformacion) setSelectedValue(fields.enviarInformacion);
  }, []);

  return (
    <>
      <CTAWrapper>
        {CTAValues.map((el, i) => {
          return (
            <Controller
              as={
                <CTA
                  tag="button"
                  emphasis={selectedValue === el ? 'primary' : 'secondary'}
                  size="small"
                  ref={register({
                    required: true,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    trackingManager.trackFormButtonClick({
                      contentId: el,
                    });
                    dispatch({ type: 'UPDATE_FIELDS', payload: { enviarInformacion: el } });
                    setValue('enviarInformacion', el);
                    setSelectedValue(el);
                  }}
                >
                  {el}
                </CTA>
              }
              key={i}
              name='enviarInformacion'
              control={control}
            />
          );
        })}
      </CTAWrapper>
      {
        showError ? (
          <ErrorMessage>
            <Text appearance={TokenTextAppearance.label150} color={TokenTextColor.inherit} textAlign={TextAlignment.left}>
              Necesitamos esta información para seguir
            </Text>
          </ErrorMessage>
        ) : null
      }
      <LastNavigationCEMOneShop setShowError={setShowError}/>
    </>
  );
}

export function CEMOneShopLastScreenFields() {
  return (
    <OneElementTemplateFullScreen element={<Fields />} />
  );
}
