import React from 'react';
import {
  CTA,
  styled
} from '@volkswagen-onehub/components-core';

export type CTAThankYou = {
  emphasis?: string;
  label: string;
  url?: string;
}

interface CTAThankYouPageProps {
  firstCTA: CTAThankYou;
  secondCTA?: boolean;
  secondCTACopies?: CTAThankYou;
}

const CtasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  @media all and (min-width: 560px) {
    flex-direction: row;
    gap: 44px;
  }
  button {
    align-self: flex-start;
  }
`;

export const CTAThankYouPage = (props: CTAThankYouPageProps) => {
  const { firstCTA, secondCTA, secondCTACopies } = props;

  return (
    <CtasWrapper>
      <CTA
        tag="a"
        href={firstCTA.url}
        emphasis={firstCTA.emphasis}
      >
        {firstCTA.label}
      </CTA>
      {
        secondCTA ? (
          <CTA
            tag="a"
            href={secondCTACopies.url}
            emphasis={secondCTACopies.emphasis}
          >
            {secondCTACopies.label}
          </CTA>
        ) : null
      }
      
    </CtasWrapper>
  );
};
