import {
  Container,
  CTA,
  Text,
  TextAlignment,
  ContainerGutter,
  styled,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  isGooglePlace,
  Suggest,
  Suggestion,
  useOneFormContext
} from 'src/feature-app';
import { useLocateUser } from 'src/feature-app/hooks';
import { Installation, isDealerData, OneFormState } from 'src/types';

const LocateWrapper = styled.div<{ paddingTop?: string }>`
  overflow: visible;
  padding-top: ${(props) => props.paddingTop ? props.paddingTop : '44px'};
`;
const LocationDisabledWrapper = styled.div<{ paddingTopLocation?: string }>`
  padding-top: ${(props) => props.paddingTopLocation ? props.paddingTopLocation : '44px'};
`;
interface LocateUserProps {
  isFiltrosSuggest?: boolean;
  onSuggestionSelectedFnAlternative?: (suggestion: Suggestion) => void;
  theme?: 'main' | 'inverted';
  paddingTop?: string;
  paddingTopLocation?: string;
  autoWidth?: boolean;
  noTengoDudasButton?: boolean;
}

export function LocateUserFullScreen(props: LocateUserProps) {
  const { isFiltrosSuggest, onSuggestionSelectedFnAlternative, theme, paddingTop, paddingTopLocation, autoWidth } = props;
  const { register } = useFormContext();
  const { nextStep } = useOneFormContext();
  const {
    value,
    setValue,
    suggestion,
    setSuggestion,
    icon,
    locationSuccess,
    setLocationSuccess,
    handleLocate,
    loading,
    locationDisabled,
    isLocating,
    onSuggestionSelectedFn,
  } = useLocateUser();
  const dispatch = useDispatch();
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { formTheme } = formInfo;

  const getSuggestionValue = (suggestion: Suggestion) => {
    if (!isGooglePlace(suggestion) && !isDealerData(suggestion)) {
      return (suggestion as Installation).VGED_CommercialName__c;
    } else {
      return suggestion.name;
    }
  };

  return (
    <LocateWrapper className="important-full-screen" paddingTop={paddingTop}>
      <Container wrap={'always'} gutter={ContainerGutter.static400}>
        <div style={{ position: 'relative', overflow: 'visible' }}>
          <Suggest
            onSuggestionSelectedFn={
              onSuggestionSelectedFnAlternative ? onSuggestionSelectedFnAlternative : onSuggestionSelectedFn
            }
            setSuggestion={setSuggestion}
            value={value}
            setValue={setValue}
            isFiltrosSuggest={isFiltrosSuggest ? isFiltrosSuggest : false}
            isLocating={isLocating}
            setLocationSuccess={setLocationSuccess}
            formTheme={theme ? theme : formTheme}
            autoWidth={autoWidth}
          />
        </div>
        <Container wrap={'always'}>
          <CTA
            tag="button"
            emphasis={locationSuccess ? 'primary' : 'secondary'}
            icon={icon}
            onClick={async (e) => {
              e.preventDefault();
              if (!locationSuccess) handleLocate();
            }}
            disabled={loading ? true : false}
            size="small"
          >
            Localiza mi posición actual
          </CTA>
          {locationDisabled ? (
            <LocationDisabledWrapper paddingTopLocation={paddingTopLocation}>
              <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100}>
                Tu localización está deshabilitada
              </Text>
            </LocationDisabledWrapper>
          ) : null}
        </Container>
        {props.noTengoDudasButton ? (
          <CTA
            tag="button"
            onClick={async (e) => {
              e.preventDefault();
              dispatch({ type: 'UPDATE_FIELDS', payload: { quieroContacto: 'false' } });
              nextStep();
            }}
            size="small"
          >
            No tengo dudas
          </CTA>
        ) : null}
      </Container>
      <input
        type="hidden"
        name="suggestion"
        value={suggestion ? getSuggestionValue(suggestion) : null}
        ref={register({ required: true })}
      />
    </LocateWrapper>
  );
}
