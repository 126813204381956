import React, { useEffect, useState } from "react";
import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerPadding,
  styled,
  Text,
} from '@volkswagen-onehub/components-core';
import { Checks } from './Checks';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';

const LayoutWrapper = styled.div`
  @media all and (min-width: 960px) {
    padding-left: var(--size-grid002);
    display: flex;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  padding-left: var(--size-grid002);
  padding-right: var(--size-grid002);
  @media all and (min-width: 960px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: var(--size-grid012);
    padding-left: var(--size-grid002);
    padding-right: 0;
    padding-top: 0;
  }
  @media all and (min-width: 1280px) {
    padding-left: var(--size-grid003);
  }
  @media all and (min-width: 1600px) {
    padding-left: var(--size-grid002);
  }
`;

interface VideoProps {
  isMediumScreen: boolean;
};

const Video = styled.video<VideoProps>`
  display: block;
  max-height:  ${(props) => props.isMediumScreen ? "auto" : "25%"};
  width: ${(props) => props.isMediumScreen ? "35%" : "100%"};
  margin: ${(props) => props.isMediumScreen ? "auto" : "0 auto"};
  padding-bottom: var(--size-grid001);
`;

interface QuestionWithImageProps {
  answers: string[];
  imageSrc: string;
  videoSrc: string;
  skippable: boolean;

  index: number;
  questionName: string;
  intro: JSX.Element | string;
  title: JSX.Element | string;
}

export const QuestionWithImage = (props: QuestionWithImageProps) => {
  const {
    answers,
    imageSrc,
    videoSrc,
    index,
    questionName,
    intro,
    title,
    skippable
  } = props;
  const height = useWindowHeight();
  const windowWidth = useWindowWidth();
  const [isMediumScreen, setIsMediumScreen] = useState<boolean>(false);

  useEffect(() => {
    console.log("setIsMediumScreen", windowWidth >= 960);
    setIsMediumScreen(windowWidth >= 960);
}, [windowWidth]);

  return (
    <LayoutWrapper className='layout-wrapper' >
      {
        height <= 600 ? null : (
          <BreakpointWrapper max={Breakpoints.b960}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Video isMediumScreen={isMediumScreen} preload="auto" autoPlay loop muted playsInline>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </Video>
            </div>
          </BreakpointWrapper>
        )
      }
      <BreakpointWrapper min={Breakpoints.b960}>
        <Video isMediumScreen={isMediumScreen} preload="auto" autoPlay loop muted playsInline>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>
      </BreakpointWrapper>
      <InfoWrapper className='info-wrapper'>
        <Container padding={{ bottom: ContainerPadding.static350 }} wrap={'always'}>
          <Text appearance={TokenTextAppearance.copy200}>
            <>
              {intro}
            </>
          </Text>
          <Text appearance={TokenTextAppearance.headline400} bold staticSize>
            <>
              {title}
            </>
          </Text>
          <Checks skippable={skippable} questionName={questionName} answers={answers} />
        </Container>
      </InfoWrapper>
    </LayoutWrapper>
  )
};
