import { styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStore, useSelector, useDispatch } from 'react-redux';
import { InputButton } from '.';
import { OneFormState } from 'src/types';
import { useOneFormContext } from 'src/feature-app/OneForm';

type CTAWrapperProps = {
  ctaWidth?: string;
  color?: string;
  marginTop?: string;
  wrapperWidth?: string;
  marginRight?: string;
};

const CTAWrapper = styled.div<CTAWrapperProps>`
  text-align: left;
  margin: -10px;
  margin-top: ${(props) => props.marginTop ? props.marginTop : '32px'};
  max-width: ${(props) => props.wrapperWidth ? props.wrapperWidth : null};

  @media all and (max-height: 624px) {
    margin-top: ${(props) => props.marginTop ? props.marginTop : '24px'};
  }
  
  & > button {
    margin: 12px;
    ${(props) => props.marginRight ?
    `&:first-of-type {margin-right:  ${props.marginRight}px}` : null};
    width: ${(props) => props.ctaWidth ? `${props.ctaWidth}px` : 'auto'};
    color: ${(props) => props.color ? props.color : null};
    border-color: ${(props) => props.color ? props.color : null};
  }
`;

export interface CTAValues {
  value?: string | boolean;
  label: string;
}

interface MultipleCTASelectionProps {
  ctaValues: CTAValues[];
  name: string;
  reset?: boolean;
  nextOnClick?: boolean;
  ctaWidth?: string;
  preSelectedValue?: string;
  color?: string;
  marginTop?: string;
  wrapperWidth?: string;
  marginRight?: string;
  exclusiveCTA?: boolean;
  exclusiveCTAValues?: CTAValues[];
}

export function MultipleCTASelection(props: MultipleCTASelectionProps) {
  const store = useStore();
  const dispatch = useDispatch();
  const {
    ctaValues,
    name,
    reset,
    nextOnClick,
    ctaWidth,
    preSelectedValue,
    color,
    marginTop,
    wrapperWidth,
    marginRight,
    exclusiveCTA,
    exclusiveCTAValues
  } = props;
  const { formData } = useSelector((state: OneFormState) => state);

  const { register, errors, control, setValue } = useFormContext();
  const { handleNextStep } = useOneFormContext();
  const [selectedValue, setSelectedValue] = useState<string>(null);

  useEffect(() => {
    const value = preSelectedValue || formData.fields[name];
    if(reset) {
		setSelectedValue(null);
		setValue(name, null);
		dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [name]: '' } });
      dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: null } });
    }
    else if (value) {
      const selectedCtaValue = ctaValues.find((cta) => cta.value === value);
      if (selectedCtaValue) {
        setSelectedValue(selectedCtaValue.label);
        setValue(name, selectedCtaValue.value);
      } else {
        setSelectedValue(value);
        setValue(name, value);
      }
    }
  }, [preSelectedValue, reset]);

  return (
    <CTAWrapper
      ctaWidth={ctaWidth}
      className="muliple-cta-wrapper"
      color={color}
      marginTop={marginTop}
      wrapperWidth={wrapperWidth}
      marginRight={marginRight}
    >
      {ctaValues.map((cta: CTAValues, index: number) => (
        <InputButton
          tag="button"
          name={name}
          label={cta.label}
          innerRef={register({
            required: true,
          })}
          selectedValue={selectedValue}
          size={'small'}
          key={index}
          onClick={(e) => {
            if (cta.label !== selectedValue) {
              setSelectedValue(cta.label);
              setValue(name, cta.value !== null ? cta.value : cta.label);
              dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [name]: cta.label } });
              dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: cta.value !== null ? cta.value : cta.label } });

              if (nextOnClick) {
                handleNextStep(e);
              }
            } else {
              setSelectedValue(null);
              setValue(name, null);
              dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [name]: '' } });
              dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: null } });
            }
          }}
        />
      ))}
      {
        exclusiveCTA ? (
          <>
            {
              exclusiveCTAValues.map((cta: CTAValues, index: number) => (
                <InputButton
                  tag="button"
                  name={name}
                  label={cta.label}
                  innerRef={register({
                    required: true,
                  })}
                  selectedValue={selectedValue}
                  size={'small'}
                  key={index}
                  onClick={(e) => {
                    if (cta.label !== selectedValue) {
                      setSelectedValue(cta.label);
                      setValue(name, cta.value !== null ? cta.value : cta.label);
                      dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [name]: cta.label } });
                      dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: cta.value !== null ? cta.value : cta.label } });
        
                      if (nextOnClick) {
                        handleNextStep(e);
                      }
                    } else {
                      setSelectedValue(null);
                      setValue(name, null);
                      dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [name]: '' } });
                      dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: null } });
                    }
                  }}
                />
              ))
            } 
          </>
        ) : null
      }
      <input type="hidden" name={name} value={selectedValue} />
    </CTAWrapper>
  );
}
