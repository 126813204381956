import React from 'react';
import {
  Breakpoints,
  BreakpointWrapper,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';

interface ThankYouTitleProps {
  notALayer?: boolean;
  regularCopyFirst?: string;
  boldCopyFirst?: string;
  regularCopySecond?: string;
  boldCopySecond?: string;
}

export const ThankYouTitle = (props: ThankYouTitleProps) => {
  const { regularCopyFirst, boldCopyFirst, notALayer, regularCopySecond, boldCopySecond } = props;
  return (
    <>
      { notALayer ? (
        <>
          {' '}
          {regularCopyFirst ? <>{regularCopyFirst}{' '}</> : null}
          {
            boldCopyFirst ? <Text bold>{boldCopyFirst}{' '}</Text> : null
          }
          {regularCopySecond ? <>{regularCopySecond}{' '}</> : null}
          {
            boldCopySecond ? <Text bold>{boldCopySecond}</Text> : null
          }
        </>
      ) : (
        <>
          <BreakpointWrapper max={Breakpoints.b1600}>
            <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
              {regularCopyFirst ? <>{regularCopyFirst}{' '}</> : null}
              {
                boldCopyFirst ? <Text bold>{boldCopyFirst}</Text> : null
              }
              {regularCopySecond ? <>{regularCopySecond}{' '}</> : null}
              {
                boldCopySecond ? <Text bold>{boldCopySecond}</Text> : null
              }
            </Text>
          </BreakpointWrapper>
          <BreakpointWrapper min={Breakpoints.b1600}>
            <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
              {regularCopyFirst ? <>{regularCopyFirst}{' '}</> : null}
              {
                boldCopyFirst ? <Text bold>{boldCopyFirst}</Text> : null
              }
              {regularCopySecond ? <>{regularCopySecond}{' '}</> : null}
              {
                boldCopySecond ? <Text bold>{boldCopySecond}</Text> : null
              }
            </Text>
          </BreakpointWrapper>
        </>
      )}
    </>
  );
};
