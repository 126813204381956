import React from 'react';
import { EmbeddedFeatureApp, TriggerProps, useFaContent } from 'src/feature-app';
import { CallMeOfertasTrigger } from './CallMeOfertasTrigger';

const renderTrigger = (featureApp: EmbeddedFeatureApp, props: TriggerProps) => {
  if (featureApp === 'es-ofertas') {
    return <CallMeOfertasTrigger {...props} />;
  }

  return null;
};

export function CallMeEmbeddedTrigger(props: TriggerProps) {
  const faContent = useFaContent();

  return renderTrigger(faContent.featureApp, props);
}
