import React, { useState, useEffect } from 'react';
import { styled, ThemeProvider } from '@volkswagen-onehub/components-core';
import { LiSection } from '.';
import { CarlineWithOffers } from 'src/types';
import { TriggerProps } from 'src/feature-app';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

interface WrapperProps {
  isVertical: boolean;
}

const UlWrapper = styled.ul<WrapperProps>`
  display: flex;
  min-height: ${(props)=> props.isVertical ? '50vh':'670px'};
  height: ${(props)=> props.isVertical ? '50vh':'100vh'};
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
  @media all and (min-width: 1280px){
    min-height: ${(props)=> props.isVertical ? '50vh':'720px'};
  }
  @media all and (min-width: 1600px){
    min-height: ${(props)=> props.isVertical ? '50vh':'1000px'};
  }

`;

interface BackgroundImagProps {
  liSection?: boolean;
  idLi?: number;
  isVertical: boolean;
}
export const BackgroundImage = styled.img<BackgroundImagProps>`
  position: absolute;
  top: ${(props) => props.liSection ? null : '0'};
  bottom: ${(props) => props.liSection ? '0' : null};
  left: ${(props) => props.idLi === 2 ? null : '0'};
  right:${(props) => props.idLi === 2 ? '0' : null};
  width: auto;
  height: ${(props)=> props.isVertical ? '50vh':'100vh'};
  transform-origin: ${(props) => props.liSection && props.idLi === 2 ? 'right bottom' : props.liSection  ? 'left bottom' : null};
  transform: ${(props) => props.idLi === 0 ? 'scale(1.2) translateX(-20%)' : props.idLi === 1 ? 'scale(1.2) translateX(-35%)' : props.idLi === 2 ? 'scale(1.2)' : null};

  min-width: 100vw;
  min-height: ${(props)=> props.isVertical ? '50vh':'670px'};
  @media all and (min-width: 1280px){
    min-height: ${(props)=> props.isVertical ? '50vh':'720px'};
  }
  @media all and (min-width: 1600px){
    min-height: ${(props)=> props.isVertical ? '50vh':'1000px'};
  }
`;

export const backgroundUrl = 'https://assets.volkswagen.com/is/image/volkswagenag/background-gradient-v2?Zml0PWNyb3AlMkMxJndpZD0xMjgwJmhlaT03MjAmZm10PWpwZWcmcWx0PTc5JmJmYz1vZmYmMmI5ZQ==';

export const AcordionHorizontal = (props: TriggerProps & { carlinesWithOffers: CarlineWithOffers[] }) => {
  const { carlinesWithOffers, setSelectedJourney } = props;
  const height = useWindowHeight();
  const width = useWindowWidth();
  const [active, setActive] = useState(0);
  const [isVertical, setVertical] = useState<boolean>(false);

  useEffect(()=>{
    if(width - height <= 100 || height > width) {
      setVertical(true);
    } else {
      setVertical(false);
    }
  },[width]);

  const handleClick = (e, index) => {
    e.preventDefault();
    if (active === index) {
      return;
    } else {
      setActive(index);
    }
  };

  return (
    <ThemeProvider theme={'image'}>
      <Wrapper >
        <BackgroundImage src={backgroundUrl} isVertical={isVertical}/>
        <UlWrapper isVertical={isVertical}>
          {
            carlinesWithOffers.map((carlineWithOffers, i) => {
              return <LiSection setSelectedJourney={setSelectedJourney} carlineWithOffers={carlineWithOffers} active={active} handleClick={handleClick} id={i} content={carlineWithOffers.ofertas[0]} />;
            })
          }
        </UlWrapper>
      </Wrapper>
    </ThemeProvider>
  );
};
