import React, {useEffect} from 'react';
import {getDealers} from 'src/bff';
import {TriggerProps, useFaContent, useGetDealers, VehiclePortal} from 'src/feature-app';
import {FormDataFields} from 'src/types';
import {AppointmentVehicle} from 'src/forms';
import {NuevaCitaPosventaFormController} from 'src/forms/CitaPosventa/NuevaCitaPosventa';

const getAppointmentVehicle = (vehicle: VehiclePortal): AppointmentVehicle => {
	return {
		brand: 'V',
		frame: vehicle.frame,
		modelVersion: vehicle.commercialDenominationSli,
		modelYear: 0,
		plate: vehicle.license.toUpperCase(),
		plateDate: transformTimestampToDate(vehicle.licenseDate, false),
		tmaimg: vehicle.tmaimg,
		model: {
			code: vehicle.familyModelCode,
			key: vehicle.familyModelCode,
			name: vehicle.familyModelDesc,
		},
	};
};

const transformTimestampToDate = (date: number, isOnlyYear: boolean) => {
	const timestamp = date;
	let formattedDate: string;
	if (isOnlyYear) {
		formattedDate = new Date(timestamp).toLocaleDateString('es-ES', {
			year: 'numeric',
		});
	} else {
		formattedDate = new Date(timestamp).toISOString().replace(/T/, ' ').replace(/\..+/, '');
	}
	return formattedDate;
};

export function CitaPosventaBenfitsForms({setSelectedJourney}: TriggerProps) {
	const faContent = useFaContent();
	const {loading} = useGetDealers(faContent?.dealer);
	const vehicle = faContent.vehicle[0];

	const renderForm = (fields: FormDataFields) => {
		setSelectedJourney(
			<NuevaCitaPosventaFormController
				isCitaPosventaEmbeddedMaintenance
				updatedFormData={{
					fields,
				}}
			/>,
			true,
		);
	};
	useEffect(() => {
		if (loading) return;

		const fields = {
			motivo: 'MANTENIMIENTO',
			formName: 'cita-posventa-my-benefits',
			matricula: vehicle.license,
			vehicleForMaintenance: getAppointmentVehicle(vehicle),
			anoMatriculacion: transformTimestampToDate(vehicle.licenseDate, true),
			modelo: vehicle.commercialDenominationSli,
			//budget: { pvp: faContent?.quotePvp, operations: [...faContent?.quoteOperations] },
		};

		getDealers()
			.then(() => {
				renderForm({...fields});
			})
			.catch((err) => {
				renderForm({...fields}); // Hay que ver que hacer en estos casos.
			});
	}, [loading]);

	return <></>;
}
