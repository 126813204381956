import { getOneformBFFCdnServiceConfig } from 'src/feature-app';

export interface OneformBFFCustomConfig {
  'X-Api-Key'?: string
}

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving OneForm BFF service config provider.');
  return null;
};

export const getOneformBFFApiKey = () => {
  const oneformBFF = getOneformBFFCdnServiceConfig();
  const customConfig:OneformBFFCustomConfig = oneformBFF.customConfig;

  if (!oneformBFF) {
    return handleErrorInRetrievingExternalConfig();
  }

  return customConfig['X-Api-Key'];
};
