
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useStore } from 'react-redux';
import { dpsSend } from 'src/bff';
import { FormController, useOneFormContext, formatPrefix } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import {} from 'src/forms/MGM';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { ThankYouMGM } from '.';
import { formDataBuilder, formatHeaders } from '../format-fields';
import { EnvioRegaloFields } from './EnvioRegaloFields';
import { LegalMGM } from './LegalMGM';
import { MGMFormProps } from './MGMController';
import { MGMLayout } from './MGMLayout';

const envioRegaloCopies = {
  title: 'Di Sí a sorprender a esa persona tan especial.',
  copy: '¡Rellena el formulario con sus datos!',
};

export function EnvioRegaloForm(props: MGMFormProps) {
  const { dpsData, idLead, idPerson, trackFormLoad } = props;
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const dispatch = useDispatch();
  const { getValues } = useFormContext();

  const steps: Steps[] = [
    {
      title: <></>, // El title se renderiza en los fields al ser un form de un solo step con un diseño que incluye un stage.
      fields: (
        <MGMLayout
          title={envioRegaloCopies.title}
          fields={<EnvioRegaloFields title={envioRegaloCopies.title} copy={envioRegaloCopies.copy} />}
        />
      ),
      screenIndex: 0,
      name: 'Form',
    },
  ];

  const EnvioRegaloData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_GDPR_ADAPTED: 'true',
      DESC_CAMPAIGNBLOCK: '16. One shot',
      DESC_ID_CAMPAIGN: '7011n000001FY4sAAG',
      DESC_TOKEN: 'VW_DDB_FRM_MGM_CLIENTE_RENOVACION_STOCK_22',
      DESC_FORM_DETAIL: 'MGM Envío Regalo Renovación Stock 22',
    },
    fields: {
      formName: 'mgm-envio-regalo',
      formType: 'otherform',
      pageCategory: 'Regalar Cupon',
    }, // Analítica
  };

  const generateData = () => {
    const { formData }: OneFormState = store.getState();
    const { fields, headers } = formData;
    const { tc, today, url, device } = getParamsForm();
    const values = getValues();

    return {
      'headers[COD_FORM_ID]': `vw-oneform-${fields.formName}`,
      'headers[DESC_DEVICETYPE]': device,
      'headers[DESC_URLORIGIN]': url,
      'headers[FECHA_INSERTION_DATE]': today,
      'headers[DESC_FORMOBJECTIVE]': fields.pageCategory, // Revisar
      'headers[TC]': tc,
      'headers[PINCODE]': dpsData?.pincode?.pincodeResponse?.params?.pincode,
      ...formatHeaders(headers), // Headers comunes a todos los formularios de leads

      'fields[DESC_SURNAME_2]': idPerson.TXT_2_Surname__c,
      'fields[DESC_SURNAME_1]': idPerson.LastName,
      'fields[DESC_NAME]': idPerson.FirstName,
      'fields[DESC_EMAIL_ADDRESS]': idPerson.TXT_Email__c,
      'fields[NUM_CELLPHONE]': idPerson.NUM_Phone_mobile__c,
      'fields[DESC_FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
      'fields[DESC_AUXFIELD20]': idPerson.Id,
      'fields[DESC_AUXFIELD1]': 'Envío Regalo',
      'fields[DESC_AUXFIELD2]': values.name,
      'fields[DESC_AUXFIELD3]': values.surname,
      'fields[DESC_AUXFIELD4]': values.secondSurname,
      'fields[DESC_AUXFIELD5]': values.email,
      'fields[DESC_AUXFIELD6]': `${formatPrefix(fields.prefix)}${values.cellphone}`,
      'fields[DESC_LOPD]': 'PERMITE',
      'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
      'fields[DESC_LSSI]': idPerson.LSSI__c,
      'fields[DESC_LOPD_GDPR_PERFILADO]': idPerson.Robinson__c,

      'flags[FLAG_ISLEAD]': 'false',
      'flags[FLAG_ISPROSPECT]': 'false',
      'flags[FLAG_VOUCHER]': 'false',
    };
  };

  const generateSubmitData = () => generateData();

  const sendForm = (): Promise<boolean> => {
    return new Promise<any>(async (resolve) => {
      const data = generateSubmitData();

      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData);

      if (response && response.data && response.data.content && response.data.content.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  // const { loading, steps, firstStep, lastStep } = useInitializeMGMForm(steps, idLead);

  useEffect(() => {
    const EnvioRegaloInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: steps[0].fullScreen ? true : false,
      lastStep: steps[steps.length - 1],
      numberOfScreens: steps.length,
      screenIndex: steps[0].screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      hideLegal: true,
      step: steps[0],
      steps,
      stepsHistory: [0],
      customLegal: <LegalMGM isFriendForm />,
      thankYouComponent: <ThankYouMGM friend />,
    };
    initializeForm(EnvioRegaloInfo, EnvioRegaloData, true);
    trackFormLoad();
  }, []);

  return !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
