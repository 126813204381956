import { getParamsForm } from 'src/feature-app/get-params-form';

const { tc, today, url, device } = getParamsForm();

export const getCommonFormData = (isPresubmit, idLead, dpsData, fields, data) => {

	const formName = fields.formName ? fields.formName : data.fields.formName;

	return {
    'headers[DPS_TOKEN]': isPresubmit ? 'VW_DDB_FRM_ENCUESTA_NO_COMPRA_PREGRABACION' : 'VW_DDB_FRM_ENCUESTA_NO_COMPRA',
    'headers[FORM_DATE_INSERTION]': today,
    'headers[FORM_URL]': url,
    'headers[FORM_NAME]': `vw-oneform-${formName}`,
    'headers[FORM_DETAILS]': isPresubmit ? 'NoCompra Pregrabación' : 'NoCompra Grabación',
    'headers[FORM_OBJECTIVE]': fields.quieroContacto == 'true' ? 'Más Información' : 'Encuesta',
    'headers[FORM_VERSION]': `vw-oneform-${formName}-${process.env.VERSION}`,
    'headers[ORIGIN_DEVICE_TYPE]': device,
    'headers[ORIGIN_TRACKING_CODE]': tc,
    'headers[FK_SYSTEM]': idLead.Id,
    'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,

    //'headers[SF_CAMPAIGN_ORIGIN_ID]': 'PENDIENTE',

    'flags[PERSON_RGPD_ADAPTED]': true,

    'fields[DPS_BRAND]': 'Volkswagen',
		'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
		'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',

		'fields[SURVEY_ANSWER_01]': formName == 'no_compra-no_quiero' ? '3' : formName == 'no_compra-no_tengo' ? '2' : '1',

		//'fields[DESC_LOPD]': isPresubmit ? '' : 'PERMITE',
		//'fields[DESC_TRANF_DATA_THIRD]': isPresubmit ? '' : 'NO INFORMADO',

		// Para no_tengo y ya_tengo: todo false
		//'flags[FLAG_ISLEAD]': fields.quieroVolkswagen == 'false' ? 'false' : fields.quieroContacto,
		//'flags[FLAG_ISPROSPECT]': fields.quieroVolkswagen == 'true' && fields.quieroContacto == 'false' ? 'true' : 'false',
		//'flags[FLAG_GENERATELEM]': fields.quieroVolkswagen == 'false' ? 'false' : fields.quieroContacto,
	}
};
