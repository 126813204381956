import { DealersInfo, FormDataGroup, Offer } from 'src/types';
import { formatHeaders } from '../../format-fields';
import { isOfertasForm } from '../LeadGenericoForm';

export const getOfertasData = async (formData: FormDataGroup, dealersInfo: DealersInfo) => {
  const { fields } = formData;
  const { oferta } = dealersInfo;

  if (!isOfertasForm(fields.formName)) {
    return null;
  } else {
    const headers = {
      FORM_NAME: 'vw-oneform-lead-oferta',
    };

    const ofertaFields = {
      'fields[VEH_INTEREST_ID]': oferta.carline.code,
      'fields[VEH_INTEREST_MODEL]': `${oferta.carline.nuevo ? 'Nuevo ' : ''}${oferta.carline.title}`,
      'fields[VEH_INTEREST_FINANCIAL_PRODUCT]': oferta.financiacionText?.slice(4),
      'fields[VEH_INTEREST_OFFER_DETAIL]': createOfferPrice(oferta),
      'fields[COMMENTS_BRAND]': getObservationBrand(oferta),
    };

    return { ...formatHeaders(headers), ...ofertaFields };
  }
};

export const getOfertasComercialesData = async (formData: FormDataGroup, dealersInfo: DealersInfo) => {
  const { fields } = formData;
  const { oferta } = dealersInfo;

  if (!isOfertasForm(fields.formName)) {
    return null;
  } else {
    const headers = {
      FORM_NAME: 'vw-oneform-lead-oferta',
    };

    const ofertaFields = {
      'fields[VEH_INTEREST_ID]': oferta.carline.code+"_"+oferta.carline.salesgroup.code,
      'fields[VEH_INTEREST_MODEL]': `${oferta.carline.nuevo ? 'Nuevo ' : ''}${oferta.carline.salesgroup.name}`,
      'fields[VEH_INTEREST_FINANCIAL_PRODUCT]': oferta.financiacionText?.slice(4),
      'fields[VEH_INTEREST_OFFER_DETAIL]': createOfferPrice(oferta),
      'fields[COMMENTS_BRAND]': getObservationBrand(oferta),
    };

    return { ...formatHeaders(headers), ...ofertaFields };
  }
};

const createOfferPrice = (offer: Offer) => {
  return `Cuota: ${offer.cuota} Eur / \r\nPeriodicidad Cuota: ${offer.cuotaTipo} / \r\nNum Cuotas: ${offer.plazos
    } / \r\nEntrada: ${offer.cuotaEntrada} Eur / \r\nCuota final: ${offer.cuotaFinal} Eur ${offer.tin || offer.tae ? '/' : ''
    }\r\n${offer.tin ? `TIN: ${offer.tin}% ${offer.tae ? '/ \r\n' : '\r\n'}` : ''}${offer.tae ? `TAE: ${offer.tae}%\r\n` : ''
    }`;
};

const getObservationBrand = (oferta: Offer) => {
  if (!oferta.promociones || oferta.promociones.length === 0) {
    return null;
  }

  return oferta.promociones.map((promo) => promo.name);
};
