import { Breakpoints, Layout } from '@volkswagen-onehub/components-core';
import { 
  LegalesFormatoLayer, 
  LssiTkmQuestions, 
  PoliticaPrivacidad, 
  LastNavigation, 
  GeneralConfirmacionLayerWrapper 
} from 'src/components';
import React from 'react';

export const ConfirmacionEmpresas = () => {

  return (
    <GeneralConfirmacionLayerWrapper>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
        }}
      >
        <LssiTkmQuestions showTitle={true}/>
        <PoliticaPrivacidad />

        <LastNavigation loadingCopy="Enviando tu petición" />

        <LegalesFormatoLayer noPadding={false} showTitle={true} />
      </Layout>
    </GeneralConfirmacionLayerWrapper>
  );
};
