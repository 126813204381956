import {
  Breakpoints,
  Container,
  ContainerGutter,
  ContainerPadding,
  Layout,
  Text,
  TextAlignment,
  TokenTextAppearance,
  styled,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { LastNavigation, PoliticaPrivacidad, GeneralConfirmacionLayerWrapper, LegalesFormatoLayer } from 'src/components';
import icon from './icon.svg'
import { useSelector, useStore } from 'react-redux';
import { OneFormState } from 'src/types';
import { removePTags } from './helpers';

const PoliticaWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ConfirmacionLeadsNoQuestions = () => {
  const { formInfo } = useSelector((state: OneFormState) => state);
  const isLayer = formInfo?.formType === 'layer' || !formInfo?.notALayer;
  const store = useStore();
  const { dealersInfo }: OneFormState = store.getState();
  const { oferta } = dealersInfo;

  return (
    <GeneralConfirmacionLayerWrapper>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
        }}
      >
        <Container
          wrap={'always'}
          horizontalAlign={'center'}
          gutter={ContainerGutter.static300}

        >
          <div style={{ textAlign: 'center', width: '100%' }}>
            <img src={icon} alt="icon" />
          </div>
          <Text textAlign={TextAlignment.center} appearance={TokenTextAppearance.headline300}>
            <Text bold>¿Listo </Text>para enviar?
          </Text>
          <PoliticaWrapper>
            <PoliticaPrivacidad />
          </PoliticaWrapper>
        </Container>
        <LastNavigation loadingCopy="Enviando tu petición" />
        {isLayer &&
          <Container
            padding={{ top: ContainerPadding.static550, bottom: ContainerPadding.static515 }}
            gutter={ContainerGutter.static300}
            wrap={'always'
}
          >
            <Text bold staticSize appearance={TokenTextAppearance.copy200}>
              Texto legal de Volkswagen
            </Text>
            {oferta ? (
              <Text staticSize appearance={TokenTextAppearance.copy100}>
                {removePTags(oferta.legal)}
              </Text>
            ) : null}
            <LegalesFormatoLayer noPadding={true} />
          </Container>
        }
      </Layout>
    </GeneralConfirmacionLayerWrapper>
  );
};
