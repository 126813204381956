import React, { useEffect } from 'react';
import { CTA } from '@volkswagen-onehub/components-core';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, getTrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps } from 'src/types';
import { ThankYouTitle, ThankYouFields } from 'src/components';

export const ThankYouCEMSegundaEntregaValoracion = () => {
	const { formInfo } = useSelector((state: OneFormState) => state);
	const { formResponse } = formInfo;

	const trackingManager = useTrackingManager();
	const dispatch = useDispatch();

	useEffect(() => {
		// Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
		const screenIndexThankyou = formInfo.numberOfScreens;

		dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
		dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: 'ThankYou' } } });

		trackingManager.trackFormSubmissionSuccessMessageLoad(
			{
				contentId: undefined,
			},
			getTrackingForm(formResponse)
		);
	}, []);

	const copyBook = <>
		Nos encantaría devolverte el favor recordándote <CTA tag="a" emphasis="tertiary" href="https://www.volkswagen.es/es/clientes/beneficios-volkswagen.html">todas las ventajas</CTA> de ser de Volkswagen.
	</>;

	const screen: Steps = {
		title: <ThankYouTitle notALayer boldCopyFirst='¡Gracias!' />,
		fields: <ThankYouFields notALayer copyBook={copyBook} />,
	};
	return (
		<RenderController
			screenType="full-screen"
			title={screen.title}
			fields={screen.fields}
			key={555}
			avoidTrackFormStepLoad
			thankYouPage={true}
		/>
	);
};
