import {
  Assessors,
  Slot,
} from 'src/types';
import dayjs from 'dayjs';


export const createNoDmsSlotAndAssessor = (franjaHoraria: string, day: string): { slot: Slot; assessor: Assessors } => {
  const from = Number(dayjs.utc(`${day} 01:00`, 'YYYY-MM-DD HH:mm').format('x'));
  const to = Number(dayjs.utc(`${day} 23:59`, 'YYYY-MM-DD HH:mm').format('x'));
  const code = franjaHoraria === 'Mañana' ? 'MANANA' : franjaHoraria === 'Mediodía' ? 'MEDIODIA' : 'TARDE';
  const assessor: Assessors = { code: '0', name: 'Todos' };
  const slot: Slot = {
    from,
    to,
    replacementCarServiceAvailable: false,
    dayMoment: {
      code,
      name: franjaHoraria,
    },
    assessorCode: '0',
  };

  return { slot, assessor };
};
