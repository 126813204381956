import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SelectExtended } from '.';
import { styled, CTA } from '@volkswagen-onehub/components-core';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { fetchMarcas, fetchModelos } from 'src/bff';
import { OneFormState } from 'src/types';
import { useFormContext } from 'react-hook-form';

interface BrandModelProps {
	stepName: string;
	brandName?: string;
	modelName?: string;
	marginTop?: string;
	notALayer?: boolean;
	required: boolean;
	formToken?: string;
	nextOnClick?: boolean;
	alternatCTA?: boolean;
}

const SelectsWrapper = styled.div<BrandModelProps>`
	margin-top: 4px;
	display: flex;
	flex-wrap: wrap;
	width: var(--size-grid020);
	@media all and (min-width: 560px) {
		width: var(--size-grid013);
	}
	@media screen and (min-width: 960px) {
		margin-top: 0; 
		width: var(--size-grid008);
	}
	@media all and (min-width: 1280px) {
		width: var(--size-grid006);
	}
	@media all and (min-width: 1920px) {
		width: var(--size-grid005);
	}
	@media all and (min-width: 2560px) {
		width: var(--size-grid004);
	}
	label {
		margin-top: 40px;
		width: var(--size-grid009);

		@media all and (max-height: 624px) {
			margin-top: 20px;
		}

		@media all and (min-width: 560px) {
			width: var(--size-grid006);
		}
		@media screen and (min-width: 960px) {
			width: calc( var(--size-grid003) + (var(--size-grid001)/2) );
		}
		@media all and (min-width: 1280px) {
			width: calc( var(--size-grid002) + (var(--size-grid001)/2) );
		}
		@media all and (min-width: 1920px) {
			width: var(--size-grid002);
		}
		@media all and (min-width: 2560px) {
			width: calc( var(--size-grid001) + (var(--size-grid001)/2) );
		}
	}
`;
const MarginDiv = styled.div`
	width: var(--size-grid002);
	@media all and (min-width: 560px) {
			width: var(--size-grid001);
		}
`;

const ButtonWidth = styled.div`
  margin-top: 58px;
  margin-left: 10px;

 button {
    width: 81px;
  }
`;

const ModeloInteresWrapper = styled.div<BrandModelProps>`
  display: ${(props)=> props.alternatCTA ? 'flex' : null};
  flex-wrap: ${(props)=> props.alternatCTA ? 'wrap' : null};

  @media all and (min-width: 560px) {
    gap: ${(props)=> props.alternatCTA ? '58px' : null};
  }
`;

export const SelectMarcaModelo = (props: BrandModelProps) => {
	const { stepName, brandName = 'brand', modelName = 'model', notALayer, marginTop, required, formToken, nextOnClick, alternatCTA } = props;
	const { handleNextStep } = useOneFormContext();
	const { formData, vehiclesInfo } = useSelector((state: OneFormState) => state);
	const dispatch = useDispatch();
	const { register } = useFormContext();
	const [isAnswered, setIsAnswered] = useState(false);
	const [brand, setBrand] = useState(null);
	const [model, setModel] = useState(null);
	const [brands, setBrands] = useState(null);
	const [models, setModels] = useState(null);

	const getBrandByName = (name) => {
		return brands ? brands.find(obj => obj.name == name) : null;
	}

	const getBrandByCode = (code) => {
		return brands ? brands.find(obj => obj.code == code) : null;
	}

	const getModelByName = (name) => {
		return models ? models.find(obj => obj.name == name) : null;
	}

	const getModelByCode = (code) => {
		return models ? models.find(obj => obj.code == code) : null;
	}

	const convertToOption = (arr: any) => {
		const name = arr['attributes'].find(obj => obj.name == 'NAME');
		const code = arr['attributes'].find(obj => obj.name == 'CODE');
		return {
			code: code ? code.value : null,
			name: name ? name.value : null,
		}
	}

	const fillBrandsCombo = () => {
		if (!brands) {
			setModels(null);
			if (vehiclesInfo.brands) {
				setBrands(vehiclesInfo.brands);
			}
			else {
				const brands = [];
				fetchMarcas(formToken).then((result) => {
					if (result?.content) {
						result.content = namesSort(result.content);
						result.content.forEach((arr) => {
							brands.push(convertToOption(arr));
						});
					}
				});
				setBrands(brands);
				dispatch({ type: 'UPDATE_VEHICLES', payload: { brands: brands } });
			}
		}
	}

	const fillModelsCombo = (brandCode: string) => {
		setModels(null);
		if (brandCode == vehiclesInfo.brandSelected) {
			setModels(vehiclesInfo.models);
		}
		else {
			const models = [];
			fetchModelos(brandCode, formToken).then((result) => {
				if (result?.content) {
					result.content = namesSort(result.content);
					result.content.forEach((arr) => {
						models.push(convertToOption(arr));
					});
				}
			});
			setModels(models);
			dispatch({ type: 'UPDATE_VEHICLES', payload: { models: models } });
		}
	}

	useEffect(() => {
		fillBrandsCombo();
		
		if (vehiclesInfo.brandSelected) {
			setBrand(vehiclesInfo.brandSelected);
			fillModelsCombo(vehiclesInfo.brandSelected);
			if (vehiclesInfo.modelSelected) {
				setModel(vehiclesInfo.modelSelected);
			}
		}
	}, []);

	const handleChangeBrand = (e: any) => {
		e.preventDefault();
		if (e.target.value) {
			const brand = getBrandByName(e.target.value);
			setBrand(brand);
			setModel(null);
			dispatch({ type: 'UPDATE_VEHICLES', payload: { brandSelected: brand, modelSelected: null } });
            dispatch({ type: 'UPDATE_FIELDS', payload: { [brandName]: brand.code } });
			fillModelsCombo(brand.code);
		}
	};

	const handleChangeModel = (e: any) => {
		e.preventDefault();
		if (e.target.value) {
			const model = getModelByName(e.target.value);
			setModel(model);
			dispatch({ type: 'UPDATE_VEHICLES', payload: { modelSelected: model } });
			dispatch({ type: 'UPDATE_FIELDS', payload: { [modelName]: model.code } });
			dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [stepName]: brand.name + ' ' + model.name } });
		}
	};

	const namesSort = (data) => {
		data.sort((a, b) => {
			const nameA = a.attributes.find(attr => attr.name === 'NAME').value;
			const nameB = b.attributes.find(attr => attr.name === 'NAME').value;
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});
		return data;
	}

	const handleNoModel = () => {
		setBrand(null);
		setModel(null);
		dispatch({ type: 'UPDATE_VEHICLES', payload: { brandSelected: null, modelSelected: null} });
		dispatch({ type: 'UPDATE_FIELDS', payload: { [brandName]: null } });
		dispatch({ type: 'UPDATE_FIELDS', payload: { [modelName]: null } });
		dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [stepName]: 'No' } });
		setIsAnswered(true);
	}

	return (
		<ModeloInteresWrapper alternatCTA notALayer={notALayer} marginTop={marginTop} required>
			{brands ? (
				<SelectsWrapper notALayer={notALayer} marginTop={marginTop} required  >
					<SelectExtended
						label='Marca'
						options={brands && brands.length > 0 ? brands : []}
						name={brandName}
						isRequired={required}
						required
						handleChange={handleChangeBrand}
						value={brand ? brand.name : ""}
						defaultValue=""
						onClick={() => null}
						setOption={setBrand}
						isFloating={true}
					/>
					<MarginDiv className='just-for-margin' />
					<SelectExtended
						disabled={!brand || !models}
						label='Model'
						options={models && models.length > 0 ? models : []}
						name={modelName}
						isRequired={required}
						required
						handleChange={handleChangeModel}
						value={model ? model.name : ""}
						defaultValue=""
						onClick={() => null}
						setOption={setModel}
						isFloating={true}
					/>
				</SelectsWrapper >) : null}
			<input
				type='hidden'
				name='Modelo'
				value={isAnswered ? 'true' : (model ? model.code : null)}
				ref={register({ required: required})}
			/>
			{ alternatCTA ? (
				<ButtonWidth>
					<CTA
						emphasis={'secondary'}
						tag="button"
						size={'small'}
						onClick={(e) => {
							e.preventDefault();
							handleNoModel();
							if(nextOnClick) {
								setTimeout(() => {
									handleNextStep();
								}, 0);
							}
						}}
					>No</CTA>
				</ButtonWidth>
			) : null}
		</ModeloInteresWrapper>
	);
};