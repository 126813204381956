export const isLowRating = (rating: string) => Number(rating) == 1 || Number(rating) == 2 || Number(rating) == 3;
export const isHighRating = (rating: string) => Number(rating) == 4 || Number(rating) == 5;
export const isNoRating = (rating: string) => isNaN(Number(rating)) || Number(rating) < 1 || Number(rating) > 5; // is text or lower than 1 or higher than 5

export const allHighRatings = (ratings: [...any]) => {
	for (const rating of ratings) {
		if (!isHighRating(rating)) {
			return false;
		}
	}
	return true;
};

export const allLowRatings = (ratings: [...any]) => {
	for (const rating of ratings) {
		if (!isLowRating(rating)) {
			return false;
		}
	}
	return true;
};

export const allNoRatings = (ratings: [...any]) => {
	for (const rating of ratings) {
		if (!isNoRating(rating)) {
			return false;
		}
	}
	return true;
};

export const allHighOrNoRatings = (ratings: [...any]) => {
	for (const rating of ratings) {
		if (isLowRating(rating)) {
			return false;
		}
	}
	return true;
};
