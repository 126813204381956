import { styled, Text, TextAlignment, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';
// // import store from './store';
import { CloseHandleV2 } from '@volkswagen-onehub/layer-manager';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { deviceType } from 'react-device-detect';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { setObjectValues, useFeatureServices, formInfoInitialState } from 'src/feature-app';
import { TIME_FOR_TRANSFORM } from 'src/globals';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { ErrorFormFields } from './tracking-types';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { BehaviorSubject } from 'rxjs';
import { useFeatureAppConfig, useFeatureAppEnvironment } from './hooks';
import { GenericErrorLayer } from 'src/components';
import { LayerManagerWrapper } from './LayerManagerWrapper';

const Form = styled.form``;

interface errorMessageProps {
  notALayer: boolean;
}
const ErrorMessage = styled.div<errorMessageProps>`
  position: absolute;
  bottom: ${(props) => (props.notALayer ? '88px' : '122px')};
  right: 20px;
  z-index: 1000;
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #e4002c;
  color: #ffffff;
  @media all and (min-width: 560px) {
    right: ${(props) => (props.notALayer ? 'var(--size-grid002)' : '40px')};
  }
  @media all and (min-width: 960px) {
    right: ${(props) => (props.notALayer ? 'var(--size-grid003)' : '52px')};
    bottom: ${(props) => (props.notALayer ? '88px' : '136px')};
  }
  @media all and (min-width: 1280px) {
    right: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '52px')};
  }
  @media all and (min-width: 1920px) {
    right: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '52px')};
  }
  @media all and (min-width: 2560px) {
    right: ${(props) => (props.notALayer ? 'var(--size-grid007)' : '52px')};
  }
`;

interface UseFormMethods {
  closeLayer: () => void;
  handlePreviousStep: (e: any) => void;
  handleScreenChange: (newNumber?: number) => void;
  handleSubmit: (e: SyntheticEvent<any>) => Promise<boolean>;
  nextStep: () => void;
  previousStep: () => void;
  sendForm: () => void;
  handleShowFinalScreen: () => void;
  /**
   * Flag que activa algunas funcionalidades para facilitar el desarrollo.
   */
  debug: boolean;
  /**
   *  Flag que controla si el formulario está dentro de un multistep y se tiene que esperar para realizar un next step del formulario genérico.
   */
  // waitForNextStep: boolean;
  /**
   *  Flag que controla si el formulario está dentro de un multistep y se tiene que esperar para realizar un previous step del formulario genérico.
   */
  waitForPreviousStep: boolean;
  // setWaitForNextStep: React.Dispatch<React.SetStateAction<boolean>>;
  setWaitForPreviousStep: React.Dispatch<React.SetStateAction<boolean>>;
  initializeForm: (
    initialFormInfo: Partial<FormInfo>,
    initialFormData: Partial<FormDataGroup>,
    skipLayerInit?: boolean
  ) => void;
  /**
   *  La propiedad nextMultiStep contiene la función que (en caso de que haya un multiStep) controlará el CTA de siguiente pantalla para navegar por las subsiguientes pantallas del multiStep sin cambiar de pantalla en el formulario principal.
   */
  nextMultiStep?: () => void;
  /**
   * Al guardar una función dentro del nextMultiStep necesitamos envolverla en otra función para que React no crea que es una inicialización -> https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
   */
  setNextMultiStep?: React.Dispatch<React.SetStateAction<() => void>>;
  /**
   *  La propiedad previousMultiStep contiene la función que (en caso de que haya un multiStep) controlará el CTA de siguiente pantalla para navegar por las anteriores pantallas del multiStep sin cambiar de pantalla en el formulario principal.
   */
  previousMultiStep?: () => void;
  /**
   * Al guardar una función dentro del previousMultiStep necesitamos envolverla en otra función para que React no crea que es una inicialización -> https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
   */
  setPreviousMultiStep?: React.Dispatch<React.SetStateAction<() => void>>;

  handleNextStep: (e?: any) => void;
  /**
   * Flag que controla si se muestra o no el previousStep (ej: en CitaPrevia no se muestra en el multiStep 2 de mapa)
   */
  showPreviousStep: boolean;
  setShowPreviousStep: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreen: boolean;
  /**
   * Flag para validacion
   */
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Flags para gestionar transiciones entre pantallas
   */
  nextScreenIndex: number;
  setNextScreenIndex: React.Dispatch<React.SetStateAction<number>>;
  nextMultiStepScreenIndex: number;
  setNextMultiStepScreenIndex: React.Dispatch<React.SetStateAction<number>>;
  distanceToMove: Array<number>;
  setDistanceToMove: React.Dispatch<React.SetStateAction<Array<number>>>;
  moveForward: boolean;
  setMoveForward: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * En alguna casuística el intro no debería pasar de página, causando el cierre de la feature app. Este flag lo desactiva.
   */
  setDisableIntroNextStep: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Trigger para algunos casos en que el handleClick de ScreenController no funciona bien y el next step no se activa
   * al seleccionar una opción de las disponibles en la pantalla porque no se produce ningún click.
   * EJEMPLO: Pantalla locateUser citaPosventa en la casuística que el usuario elije geolacalización
   */
  isValueSelected: boolean;
  setIsValueSelected: React.Dispatch<React.SetStateAction<boolean>>;
  handleAreYouSureLayer: any;
  nextStepEventEmitter: BehaviorSubject<boolean>;
}
export const OneFormContext = React.createContext<UseFormMethods | null>(null);

export const useOneFormContext = (): UseFormMethods => React.useContext(OneFormContext) as UseFormMethods;

interface OneFormProviderProps {
  children: JSX.Element;
  closeLayerCallback: CloseHandleV2<any, any>;
  handleAreYouSureLayer: any;
}

interface OneFormDebug {
  debug: boolean;
  toggleDebugMode: () => void;
}

declare global {
  interface Window {
    OneForm: OneFormDebug;
  }
}

export const OneFormProvider = (props: OneFormProviderProps): any => {
  const { closeLayerCallback, handleAreYouSureLayer } = props;
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const {
    sendFormCallback,
    screenIndex,
    formEnded,
    steps,
    stepsHistory,
    nextStepCallback,
    numberOfScreens,
    lastStep,
    step,
    formSubmitted,
    notALayer,
    suggestionIsInCanaryIslands,
  } = formInfo;
  const methods = useForm();
  // const { getValues, triggerValidation } = useFormContext();

  const dispatch = useDispatch();
  const store = useStore();
  const [waitForPreviousStep, setWaitForPreviousStep] = useState(false);
  const [nextMultiStep, setNextMultiStep] = useState<() => void>(() => () => {});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [previousMultiStep, setPreviousMultiStep] = useState<() => void>(() => () => {});
  const [showPreviousStep, setShowPreviousStep] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [nextScreenIndex, setNextScreenIndex] = useState<number>(null);
  const [nextMultiStepScreenIndex, setNextMultiStepScreenIndex] = useState<number>(null);
  const [distanceToMove, setDistanceToMove] = useState<Array<number>>([]);
  const [moveForward, setMoveForward] = useState<boolean>(true);
  const oneFormService = useFeatureServices()['es:oneform-core'];
  const trackingManager = useTrackingManager();
  const [debug, setDebug] = useState(false);
  const [disableIntroNextStep, setDisableIntroNextStep] = useState(false);
  const [isValueSelected, setIsValueSelected] = useState<boolean>(false);
  const [closed, setClosed] = useState(false);
  const nextStepEventEmitter: BehaviorSubject<any> = new BehaviorSubject(false);
  const layerManager = useFeatureServices()['layer-manager'];
  const history = useFeatureServices()['s2:history'];
  const config = useFeatureAppConfig();
  const env = useFeatureAppEnvironment();

  useEffect(() => {
    window.OneForm = { debug, toggleDebugMode };
    setTimeout(() => {
      const { person } = oneFormService.get();
      if (person) {
        dispatch({ type: 'UPDATE_FIELDS', payload: { ...person } });
        setObjectValues(person, methods.setValue);
      }
    }, 1000);
  }, []);

  const toggleDebugMode = () => {
    setDebug(!debug);
    window.OneForm.debug = !debug;
  };

  const initializeForm = (
    initialFormInfo: Partial<FormInfo>,
    initialFormData: Partial<FormDataGroup>,
    skipLayerInit?: boolean
  ) => {
    // En algunos casos al finalizar el formulario y cerrar el layer se vuelve a disparar el evento;
    if (formSubmitted || closed) {
      return;
    }

    dispatch({ type: 'UPDATE_FORM', payload: initialFormData });
    dispatch({ type: 'SET_FORM_INFO', payload: { ...formInfoInitialState, ...initialFormInfo } });
    dispatch({ type: 'SET_INITIAL_STEPS', payload: initialFormInfo.steps });
    setTimeout(() => {
      // Solucion al bug que se produce cuando hay un error genérico. Al resetear el estado
      // se añade el último screenIndex que había antes de producirse el error, por lo que
      // la navegación se ve alterada.
      dispatch({ type: 'SET_STEPS_HISTORY', payload: initialFormInfo.stepsHistory });
      if (!skipLayerInit) {
        trackingManager.trackFormLayerLoad();
      }
    }, 0);
  };

  useEffect(() => {
    if (screenIndex !== 0 && screenIndex !== null) {
      dispatch({ type: 'UPDATE_STEPS_HISTORY', payload: screenIndex });
    }
    if (screenIndex !== 2 && nextMultiStepScreenIndex !== null) setNextMultiStepScreenIndex(null);
    setTimeout(() => {
      setObjectValues(formData.fields, methods.setValue);
    }, 500);
  }, [screenIndex]);

  /**
   * En el journey de no compra al llegar al mapa se abre un layer. Se necesita que interactue con el next step, por lo que lo tenemos que cerrar desde aquí
   */
  const handleMapFullScreenLayer = (isNext: boolean) => {
    const layers = layerManager.getLayers();
    const layer = layers.find(
      (layer) => layer.layer.options.id === 'mapaNoCompra' || layer.layer.options.id === 'mapaNoRenovacion' || layer.layer.options.id === 'mapaLeadInPage'
    );

    if (layer) {
      layer.layer?.close();
      isNext ? nextStep() : previousStep();
      dispatch({ type: 'UPDATE_WAIT_FOR_NEXT_STEP', payload: false });
      setWaitForPreviousStep(false);
    }
  };

  const handleNextStep = async (e?: any) => {
    if (e) e.preventDefault();

    const { step } = store.getState().formInfo;

    if (step && step.isLastStep) {
      return;
    }
    const values = methods.getValues();

    const isValid = await methods.triggerValidation();
    const { waitForNextStep } = store.getState().formInfo;

    // if (isValid && suggestionIsInCanaryIslands) {
    //   renderAvisoIslasCanariasLayer(store, env, layerManager);

    //   return;
    // }

    if (isValid) {
      if (!moveForward) setMoveForward(true);
      setError(false);
      dispatch({ type: 'UPDATE_FIELDS', payload: values });

      //console.log(formData);

      handleMapFullScreenLayer(true); // Revisar para optimizar esta llamada y que solo debería ejecutarse si el form es inPage y el step en un layer

      if (waitForNextStep) {
        nextMultiStep();
      } else {
        nextStep();
      }
    } else {
      setError(true);
      const keys = Object.keys(methods.errors);

      const errorFields: ErrorFormFields[] = keys.map((key) => {
        return { 'form field id': 'id', 'form field name': key };
      });

      keys.map((err) => trackingManager.trackFormErrorMessageLoad({ message: err }, errorFields));
    }
  };

  const nextStep = () => {
    let nextStepNumber: number;
    let nextStep: Steps;

    // En algunos casos según como se inicializa el form ni el screenIndex ni los steps están disponibles, por lo que hay que
    // utilizar el getState para obtener el estado actual.
    const { screenIndex, steps } = store.getState().formInfo;

    if (formEnded && lastStep) {
      nextStepNumber = lastStep.screenIndex;
      nextStep = lastStep;
    } else {
      nextStepNumber = nextStepCallback ? nextStepCallback() : screenIndex + 1;
      nextStep = steps[nextStepNumber];
    }

    handleStepChange(nextStep, nextStepNumber, true);
  };

  const handlePreviousStep = (e: any) => {
    e.preventDefault();
    if (moveForward) {
      setMoveForward(false);
    }

    if (formData.fields.formName.includes('no_compra') || formData.fields.formName.includes('no_renovacion') || formData.fields.formName.includes('lead_inpage')) {
      handleMapFullScreenLayer(false);
    }
    const values = methods.getValues();
    // Comprobar si es correcto antes de actualizar?
    // dispatch({ type: 'UPDATE_FIELDS', payload: values });
    waitForPreviousStep ? previousMultiStep() : previousStep();
    if (formEnded) dispatch({ type: 'UPDATE_FORM_ENDED', payload: false });
  };

  const previousStep = () => {
    // Es necesario poder sobreescribir previousStep?
    const { screenIndex, steps, stepsHistory } = store.getState().formInfo;

    const indexActualScreen = stepsHistory.indexOf(screenIndex);
    const newScreenIndex = stepsHistory[indexActualScreen - 1];
    const indexInSteps = steps.findIndex((step: any) => step.screenIndex === newScreenIndex);
    const previousStep = steps[indexInSteps];

    // Falta eliminar el último elemento de stepsHistory
    handleStepChange(previousStep, newScreenIndex, false);
  };

  const handleStepChange = (step: Steps, screenIndex: number, isMovingForward: boolean) => {
    handleScreenChange(screenIndex);
    dispatch({ type: 'SET_NAVIGATION_MOVING_FORWARD', payload: isMovingForward });
    setTimeout(() => {
      dispatch({ type: 'UPDATE_STEP', payload: step });
      // dispatch({ type: 'UPDATE_STEPS_HISTORY', payload: screenIndex });
      dispatch({ type: 'UPDATE_FULLSCREEN', payload: step.fullScreen });
      setIsFullScreen(step.fullScreen);
    }, TIME_FOR_TRANSFORM);
  };

  const handleScreenChange = (newNumber?: number) => {
    if (typeof newNumber === 'number') setNextScreenIndex(newNumber);
    dispatch({ type: 'TRIGGER_EXIT_ANIMATION', payload: true });
    setTimeout(() => {
      dispatch({ type: 'TRIGGER_EXIT_ANIMATION', payload: false });
      if (typeof newNumber === 'number') {
        dispatch({ type: 'UPDATE_SCREEN', payload: newNumber });
      }
    }, TIME_FOR_TRANSFORM);
  };

  const handleShowFinalScreen = () => {
    dispatch({ type: 'SHOW_FINAL_SCREEN', payload: true });
  };

  const closeLayer = () => {
    dispatch({ type: 'RESET_STATE' });
    setClosed(true);
    closeLayerCallback({}, {}, {});

    if (env.done) {
      env.done();
    }
    return;
  };

  const sendForm = async (): Promise<boolean> => {
    return new Promise((resolve) => {
      sendFormCallback
        ? resolve(sendFormCallback())
        : setTimeout(() => {
            resolve(true);
          }, 2000);
    });
  };

  const handleSubmit = async (e: SyntheticEvent<any>) => {
    e.preventDefault();
    const responseIsValid = await sendForm();
    return responseIsValid;
  };

  const renderAvisoCanarias = () => {
    let redirect: string;

    if (formData.fields.formName === 'cita-posventa') {
      redirect = 'https://www.vwcanarias.com/es/cita-previa.html';
    } else {
      redirect = 'https://cita.vwcanarias.com/';
    }

    return (
      <LayerManagerWrapper store={store} env={env}>
        <GenericErrorLayer
          title="Uy, algo ha pasado"
          bodycopy="Por alguna razón no hemos podido cargar este cuestionario."
          ctaLayer="Redirigir"
          icon={false}
          notALayer={false}
          urlHref={redirect}
          ctaType="a"
        />
      </LayerManagerWrapper>
    );
  };

  return (
    <FormContext {...methods}>
      <OneFormContext.Provider
        value={{
          closeLayer,
          distanceToMove,
          setDistanceToMove,
          error,
          setError,
          isValueSelected,
          setIsValueSelected,
          handleScreenChange,
          handleSubmit,
          handleShowFinalScreen,
          initializeForm,
          moveForward,
          setMoveForward,
          nextMultiStep,
          nextStep,
          previousStep,
          nextScreenIndex,
          setNextScreenIndex,
          nextMultiStepScreenIndex,
          setNextMultiStepScreenIndex,
          waitForPreviousStep,
          sendForm,
          setWaitForPreviousStep,
          setNextMultiStep,
          previousMultiStep,
          setPreviousMultiStep,
          handleNextStep,
          handlePreviousStep,
          showPreviousStep,
          setShowPreviousStep,
          setIsFullScreen,
          isFullScreen,
          debug,
          setDisableIntroNextStep,
          handleAreYouSureLayer,
          nextStepEventEmitter,
        }}
      >
        <Form
          onSubmit={handleSubmit}
          tabIndex={-1} // https://stackoverflow.com/questions/43503964/onkeydown-event-not-working-on-divs-in-react
          onKeyDown={(e) => {
            const values = methods.getValues();

            if (e.keyCode === 13 && !disableIntroNextStep && Object.keys(values).length > 0) {
              handleNextStep(e);
            } else if (e.keyCode === 13 && disableIntroNextStep) {
              e.preventDefault();
            }
          }}
          className="form-provider"
        >
          {props.children}
        </Form>
        {error && screenIndex !== numberOfScreens - 1 ? (
          <ErrorMessage notALayer={notALayer}>
            <Text appearance={TokenTextAppearance.label150} color={TokenTextColor.inherit} textAlign={TextAlignment.left}>
              Necesitamos esta información para seguir
            </Text>
          </ErrorMessage>
        ) : null}
      </OneFormContext.Provider>
    </FormContext>
  );
};

export const getDeviceType = (): string => (deviceType === 'browser' ? 'desktop' : deviceType.toLowerCase());

export const getTrackingCode = (): string => ((typeof s !== 'undefined' && typeof s.persCmp !== 'undefined') ? s.persCmp : 'undefined');
