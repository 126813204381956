import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { MagnifierLabel, CloseButton, SuggestionWrapper, StyledWrapper } from 'src/feature-app/NewMap';
import { Close, Magnifier } from 'src/icons-core-imports';
import { Text, ThemeProvider, TokenTextAppearance, TokenTextColor, styled } from '@volkswagen-onehub/components-core';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { getVehiculosModelo } from 'src/bff';
import { modelCar, OneFormState } from 'src/types';
import { useFormContext } from 'react-hook-form';
import { useDropdowPosition } from 'src/feature-app/hooks';

interface SuggestModeloProps {
  notALayer: boolean;
  marginTop?: string;
  fieldsName: string;
  required: boolean;
}

const FechaCompraWrapper = styled.div<SuggestModeloProps>`
  margin-top: ${(props) => props.marginTop ? props.marginTop : '44px'};
  width: var(--size-grid020);
  position:relative;
  overflow: visible ;
  @media all and (max-height: 624px) {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '24px')};
  }

  @media all and (min-width: 560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid013)' : '303px')};
  }
  @media all and (min-width: 960px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '320px')};
  }
  @media all and (min-width: 1280px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '320px')};
  }
  @media all and (min-width: 1920px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '320px')};
  }
  @media all and (min-width: 2560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '320px')};
  }
`;

const escapeRegexCharacters = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value: string, list: modelCar[]) => {
  if (value.length < 2) return [];
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(escapedValue, 'i');

  return list.filter((car: modelCar) => regex.test(car.title));
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion: modelCar) => suggestion.title;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion: modelCar) => (
  <SuggestionWrapper>
    <Text appearance={TokenTextAppearance.copy200} color={TokenTextColor.inherit}>
      {suggestion.title}
    </Text>
  </SuggestionWrapper>
);

export const SuggestMarca = (props: SuggestModeloProps) => {
  const { formData } = useSelector((state: OneFormState) => state);
  const { notALayer, marginTop, fieldsName, required } = props;
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [list, setList] = useState(null);
  const { register, clearError } = useFormContext();
  const [value, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(false); // Controla que una vez seleccionado un valor en el input, no siga buscando en google maps.
  const suggestRef = useRef(null);
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { formTheme } = formInfo;
  const [suggestion, setSuggestion] = useState<modelCar>(null);
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const { setDisableIntroNextStep, setError } = useOneFormContext();

  useEffect(() => {
    getVehiculosModelo().then((modelos) => {
      setList(modelos);
      if (formData.fields[fieldsName]) {
        const modeloSuggestion = modelos.find((model) => model.title === formData.fields[fieldsName]);
        setValue(formData.fields[fieldsName]);
        setSuggestion(modeloSuggestion);
      }
    });
  }, []);

  const onChange = (e: any, { newValue }: { newValue: string }) => {
    if (setSelectedValue) {
      setSelectedValue(false);
    }
    if (newValue === '') {
      setSuggestion(null);
      setSelectedValue(true);
    }
    setError(false);
    setValue(newValue);
  };
  
  //desplegar el dropdown para arriba o para abajo según la posicinón del input en el window
  const { goUp, getAndUpdateState } = useDropdowPosition('.react-autosuggest__container');

  const onClick = () => {
    getAndUpdateState();
  };

  const onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
    const suggestions = getSuggestions(value, list);
    setSuggestions(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputPlaceholder = 'Escribe aquí la Marca';
  const inputProps = {
    placeholder: inputPlaceholder,
    value,
    onChange,
    onClick,
    onBlur: () => { setDisableIntroNextStep(false); },
    disabled: false,
  };

  
  

  return (
    <FechaCompraWrapper notALayer={notALayer} marginTop={marginTop} fieldsName={fieldsName} required>
      <StyledWrapper
        formTheme={formTheme}
        inputFocus={inputFocus}
        ref={suggestRef}
        onFocus={() => {
          {/*setTimeout(() => {
            suggestRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }, 400);*/}
          setInputFocus(true);
        }}
        onBlur={() => setInputFocus(false)}
        isLocating={false}
        isFiltrosSuggest={false}
        isModeloSuggest={true}
        goUp={goUp}
      >
        <MagnifierLabel className="magnifier-label" formTheme={formTheme} isLocating={false} isFiltrosSuggest={false}>
          <Magnifier />
        </MagnifierLabel>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={(event, { suggestion, suggestionValue }) => {
            setSuggestion(suggestion);
            dispatch({ type: 'UPDATE_FIELDS', payload: { [fieldsName]: suggestion.name } });
            setSelectedValue(true);
          }}

          inputProps={inputProps}
        />
        {value !== '' && (
          <ThemeProvider theme="main">
            <CloseButton
              isLocating={false}
              isFiltrosSuggest={true}
              onClick={(e) => {
                e.preventDefault(); 
                setValue('');
                // setFormValue(fieldsName, '')
                setSuggestion(null);
              }}
            >
              <Close />
            </CloseButton>
          </ThemeProvider>
        )}
      </StyledWrapper>
      <input
        type='hidden'
        name={fieldsName ? fieldsName : 'modelo'}
        value={suggestion ? getSuggestionValue(suggestion) : null}
        ref={ required ? register({ required: true }) : register({ required: false }) }
      />
    </FechaCompraWrapper>
  );
};
