import axios, { AxiosResponse } from 'axios';
import { getDataUrl, reportErrorSentry } from 'src/bff';
import { CarlinesWithSalesgroups } from 'src/types';

export const getSalesgroups = async (): Promise<CarlinesWithSalesgroups[]> => {
  try {
    const dataUrl = getDataUrl();

    const response: AxiosResponse<{ carlines: CarlinesWithSalesgroups[] }> = await axios.get(
      `${dataUrl}/getSalesgroups`
    );

    return response.data.carlines;
  } catch (error) {
    reportErrorSentry(error);
    return null;
  }
};
