import { Spinner } from '@volkswagen-onehub/components-core';
import { Checkmark, Locate } from 'src/icons-core-imports';

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useStore } from 'react-redux';
import {
  GooglePlace,
  isNull,
  Suggestion,
  useOneFormContext,
  updateMapVariables,
  useFeatureServices,
  useFeatureAppEnvironment,
} from 'src/feature-app';
import { handleLocation } from 'src/feature-app/NewMap/handle-location';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { OneFormState } from 'src';
import { renderAvisoIslasCanariasLayer } from 'src/components';
import { Store } from 'redux';

// está hecho a saco para extraer la lógica del componente LocateUser y no tenerla por duplicado
// por temas de front y comportamiento de la caja del suggest no se puede usar LocateUser en filtros

export function useLocateUser() {
  const [value, setValue] = useState<string>('');
  const [suggestion, setSuggestion] = useState<Suggestion>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [icon, setIcon] = useState<JSX.Element>(<Locate variant="small" />);
  const [isLocating, setIsLocating] = useState(false);
  const [locationDisabled, setLocationDisabled] = useState<boolean>(false);
  // locationSuccess lo suyo seria subirlo a un context para que no se resetee entre LocateUser y filtros
  const [locationSuccess, setLocationSuccess] = useState<boolean>(false);
  const { triggerValidation } = useFormContext();
  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const { setIsValueSelected, handleNextStep } = useOneFormContext();
  const store: Store<OneFormState> = useStore();
  const env = useFeatureAppEnvironment();
  const layerManager = useFeatureServices()['layer-manager'];

  const onSuggestionSelectedFn = (suggestion: Suggestion) => {
    setSuggestion(suggestion);
    updateMapVariables(dispatch, suggestion).then(() => {
      const { suggestionIsInCanaryIslands } = store.getState().formInfo;

      if (suggestionIsInCanaryIslands) {
        renderAvisoIslasCanariasLayer(store, env, layerManager);
        return;
      }
      // Avanza a la siguiente pantalla
      handleNextStep();
    });
  };

  const handleLocate = async () => {
    trackingManager.trackFormButtonClick({
      contentId: 'Localiza mi posición actual',
    });
    setIsLocating(true);
    setLoading(true);
    setIcon(<Spinner />);
    const { geometry, name } = await handleLocation();
    setIcon(<Locate variant="small" />);
    setIsLocating(false);
    setLoading(false);
    if (isNull(geometry)) {
      setLocationDisabled(true);
      setLocationSuccess(false);
    } else {
      setLocationDisabled(false);
      const suggestion: GooglePlace = { isGooglePlace: true, name, skipSearch: true, isGeoLocated: true };
      // dispatch({ type: 'UPDATE_USER_GEOLOCATION', payload: suggestion });
      // handleMapVariables(dispatch, { lat: geometry.lat, lng: geometry.lng }, 10, geometry, false, suggestion);

      setSuggestion(suggestion);
      setLocationSuccess(true);
      triggerValidation();
      setValue(name);
      setIsValueSelected(true);
      updateMapVariables(dispatch, suggestion, geometry);
    }
  };

  const handleLocateFilters = async () => {
    trackingManager.trackFormButtonClick({
      contentId: 'Localiza mi posición actual',
    });
    setIsLocating(true);
    setLoading(true);
    setIcon(<Spinner />);
    const { geometry, name } = await handleLocation();
    setIcon(<Locate variant="small" />);
    setIsLocating(false);
    setLoading(false);

    if (isNull(geometry)) {
      setLocationDisabled(true);
      setLocationSuccess(false);
    } else {
      setLocationDisabled(false);
      const suggestion: GooglePlace = { isGooglePlace: true, name, skipSearch: true, isGeoLocated: true };
      // dispatch({ type: 'UPDATE_USER_GEOLOCATION', payload: suggestion });
      // handleMapVariables(dispatch, { lat: geometry.lat, lng: geometry.lng }, 10, geometry, false, suggestion);

      triggerValidation();
      setValue(name);
      setLocationSuccess(true);
      return { suggestion, geometry };
      // setSuggestion(suggestion);
    }
  };

  useEffect(() => {
    locationSuccess ? setIcon(<Checkmark variant="small" />) : setIcon(<Locate variant="small" />);
  }, [locationSuccess]);

  return {
    suggestion,
    setSuggestion,
    value,
    setValue,
    icon,
    handleLocate,
    loading,
    locationDisabled,
    locationSuccess,
    setLocationSuccess,
    isLocating,
    handleLocateFilters,
    onSuggestionSelectedFn,
  };
}
