import {
  Breakpoints,
  Layout,
  styled,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { useStore } from 'react-redux';
import { LastNavigation, LssiTkmQuestions, PoliticaPrivacidad } from 'src/components';
import { OneFormState } from 'src/types';

const GeneralconfirmacionWrapper = styled.div`
  
`;

export const ConfirmacionLeadInPage = () => {
  const store = useStore();

  return (
    <GeneralconfirmacionWrapper>
      <Layout
        allowOverflowingContent
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
          [Breakpoints.b1280]: [
            { name: '.', columns: 5 },
            { name: 'a', columns: 14 },
            { name: '.', columns: 5 },
          ],
          [Breakpoints.b1920]: [
            { name: '.', columns: 6 },
            { name: 'a', columns: 12 },
            { name: '.', columns: 6 },
          ],
          [Breakpoints.b2560]: [
            { name: '.', columns: 7 },
            { name: 'a', columns: 10 },
            { name: '.', columns: 7 },
          ],
        }}
      >
        <LssiTkmQuestions showTitle={true}themeColor="main"/>
        <PoliticaPrivacidad />
        <LastNavigation loadingCopy="Enviando tu petición" />
      </Layout>
    </GeneralconfirmacionWrapper>
  );
};
