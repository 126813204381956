import { FormDataGroup } from 'src/types';

export const PartialDataTestDriveConfigurador: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Test Drive' },
  fields: {
    formFields: {},
    formularioFlag: 'testDrive',
    formName: 'lead-test_drive-configure',
    formType: 'testdrive',
    pageCategory: 'Test Drive',
  },
};
