import { FormDataGroup } from 'src/types';

export const leadsTestDrivePartialData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Test Drive' },
  fields: {
    formFields: {},
    formularioFlag: 'testDrive',
    formName: 'lead-test_drive',
    formType: 'testdrive',
    pageCategory: 'Test Drive',
  },
};

export const leadsTestDriveDealerPartialData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Test Drive' },
  fields: {
    formFields: {},
    formularioFlag: 'testDrive',
    formName: 'lead-test_drive-dealer',
    formType: 'testdrive',
    pageCategory: 'Test Drive',
  },
};
