import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { InputError } from 'src/components';
import { TextInputExtended } from 'src/components';
import { GeneralInputWidthWrapper } from 'src/components';
import { useOneFormContext } from 'src/feature-app/OneForm';

interface emailProps {
  paginaConfirmacion?: boolean;
  ignoreValidation?: boolean;
  required?: boolean;
  notALayer?: boolean;
  name?: string;
  label?: string;
  disabled?: boolean;
  paddingTop?: string;
  setEmail?: (email: string) => void;
  setShowError?: (showError: boolean) => void;
  setEmailField?: boolean;
}

export function Email(props: emailProps) {
  const { paginaConfirmacion, ignoreValidation, required, notALayer, name, label, disabled, paddingTop, setEmail, setShowError, setEmailField } = props;

  const { register, errors } = useFormContext();
  const [inputValue, setInputValue] = useState('');
  const [pristine, setPristine] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const regExp =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const { error, setError } = useOneFormContext();
  const dispatch = useDispatch();

  const checkEmailError = (value: string) => !pristine && !regExp.test(value) && value?.length > 2;
  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    setPristine(false);
  };

  useEffect(() => {
    if (checkEmailError(inputValue)) {
      setEmailError(true);
      if(setShowError) setShowError(true);
    } else {
      if (setEmail && !error){ 
        setEmail(inputValue)
        setShowError(false);
      };
      if (setEmailField && !error) {
        dispatch({ type: 'UPDATE_FIELDS', payload: { email: inputValue } });
      }
      setEmailError(false);
      setError(false);
    }
  }, [inputValue, pristine]);

  useEffect(() => {
    if ((!inputValue && error) || (error && checkEmailError(inputValue))) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [error]);

  return (
    <GeneralInputWidthWrapper className="email-input-wrapper" paginaConfirmacion={paginaConfirmacion} notALayer={notALayer} paddingTop={paddingTop}>
      <TextInputExtended
        name={name ? name : 'email'}
        label={label ? label : 'Email'}
        isFloating
        innerRef={register({
          required: {
            value: ignoreValidation ? false : true,
            message: 'Requerido',
          },
          pattern: {
            value: regExp,
            message: 'Solo se aceptan letras',
          },
        })}
        onChange={(e) => handleChange(e)}
        required={required}
        disabled={disabled}
      />
      {emailError && <InputError className="input-error">Ejemplo: nombre@email.com</InputError>}
    </GeneralInputWidthWrapper>
  );
}
