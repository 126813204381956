import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { DealersData, Suggestion } from 'src';
import {
  useGetDealers,
  useFeatureAppEnvironment,
  FullScreen,
  ScreenController,
  updateMapVariables,
  LayerManagerWrapper,
  useFeatureServices,
} from 'src/feature-app';
import { LoadingLayer } from 'src/forms';
import { MapGeneralController } from 'src/feature-app/NewMap';
import { MapaScreen } from './MapaScreen';
import { SuggestionScreen } from './SuggestionScreen';
import { FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';

export function WidgetMapa() {
  const { selectedDealer, dealers, loading } = useGetDealers();
  const [showMap, setShowMap] = useState(false);
  const store = useStore();
  const dispatch = useDispatch();
  const env = useFeatureAppEnvironment();
  const onSuggestionSelectedFn = (suggestion: Suggestion) => {
    updateMapVariables(dispatch, suggestion).then(() => {
      setShowMap(true);
      closeLoadingLayer();
    });
  };
  const loadingLayer = useRef(null);
  const calculadoraFeatureService = useFeatureServices()['calculator-feature-service'];
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;

  const renderLoadingLayer = () => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingLayer copy="Cargando" />
      </LayerManagerWrapper>
    );
  };

  const openLoadingLayer = () => {
    if (!loadingLayer.current) {
      const layer = layerManager.openFocusLayer(renderLoadingLayer, {}, { size: FocusLayerSizeV2.A });
      loadingLayer.current = layer;
    }
  };

  const closeLoadingLayer = () => {
    if (loadingLayer.current) {
      loadingLayer.current.close();
      loadingLayer.current = null;
    }
  };

  useEffect(() => {
    const kvps = calculadoraFeatureService?.getCalculatorData()?.installation?.code;
    dispatch({ type: 'DISABLE_TRACKING' });
    if (!kvps) {
      return;
    }
    openLoadingLayer();

    if (dealers) {
      const dealer: DealersData = dealers?.find((dealer) => dealer.kvps === kvps);
      if (dealer) {
        onSuggestionSelectedFn(dealer);
      }
    }
  }, [dealers, loading]);

  return (
    <MapGeneralController>
      <ScreenController
        formType= 'inPage'
        notALayer
        handleAreYouSureLayer={() => null}
        selectedJourney={
          showMap ? <MapaScreen setShowMap={setShowMap} /> : <SuggestionScreen setShowMap={setShowMap} />
        }
      />
    </MapGeneralController>
  );
}
