import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMarketingCode } from 'src/bff';
import { useFaContent } from 'src/feature-app';

/**
 * Hook necesario para disparar ambos formularios de match. El objetivo es enviar las variables de match y matchMarketingCodeData para
 * que desde la parte de analítica se puedan obtener todas las variables que se están utilizando tambien para la analítica de match.
 * @param setSelectedJourney
 * @param Form
 */
export function useInitiateMatchTriggers(setSelectedJourney: any, Form: JSX.Element) {
  const faContent = useFaContent();
  const dispatch = useDispatch();

  useEffect(() => {
    getMarketingCode(faContent?.match?.marketingCode)
      .then((res) => {
        dispatch({ type: 'SET_MATCH', payload: faContent.match });
        dispatch({
          type: 'UPDATE_FIELDS',
          payload: { matchMarketingCodeData: res.response, argumentatives: faContent?.argumentatives },
        });

        setSelectedJourney(Form, true);
      })
      .catch((err) => {
        dispatch({ type: 'SET_MATCH', payload: faContent.match });
        dispatch({ type: 'UPDATE_FIELDS', payload: { argumentatives: faContent?.argumentatives } });
        setSelectedJourney(Form, true);
      });
  }, []);
}
