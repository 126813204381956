import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerPadding,
  Layout,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFeatureAppConfig, useTrackingManager, TrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { FormDataFields, FormInfo, OneFormState, Steps } from 'src/types';

const copiesThankYou = [
  {
    name: 'prospect',
    titleCopyRegular: '',
    titleCopyBold: '¡Listo!',
    bodyCopy: [
      'Muy pronto, recibirás toda la información sobre el Nuevo Taigo.',
    ],
  },
  {
    name: 'prospect-match',
    titleCopyRegular: 'Ya te hemos enviado ',
    titleCopyBold: 'los detalles de tu configuración',
    bodyCopy: [
      'Muy pronto recibirás un email con las características del Volkswagen que más encaja contigo. ',
    ],
  },
  {
    name: 'prospect-posventa-calculadora',
    titleCopyRegular: 'Ya tienes ',
    titleCopyBold: 'tu presupuesto de mantenimiento',
    bodyCopy: [
      'Te hemos enviado un email con todos los detalles sobre tu mantenimiento para que puedas consultarlo cuando quieras.',
    ],
  }
];
interface FieldProps {
  copyBook?: string;
}

interface NDPSField {
  name: string;
  value: string;
}

function Fields(props: FieldProps) {
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const config = useFeatureAppConfig();

  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
      }}
    >
      <div style={{ textAlign: 'left', paddingBottom: '4px', width: '100%' }}>
        <Container padding={{ top: ContainerPadding.dynamic0100 }}>
          <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
            {props.copyBook}
          </Text>
        </Container>
      </div>
    </Layout>
  );
}

interface ThankYouProps {
  formData: FormDataFields;
  formInfo: FormInfo;
}

function ThankYouProspects(props: ThankYouProps) {
  const { formData, formInfo } = props;
  const { formResponse } = formInfo;
  const { formName } = formData;


  const [completeInfo, setCompletInfo] = useState<any>(null);
  const [titleBold, setTitleBold] = useState('');
  const [titleRegular, setTitleRegular] = useState('');
  const [copybook, setCoopybook] = useState('');


  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const getTrackingForm = (): TrackingForm => {
    let LeadID;
    if (formResponse && formResponse.content && formResponse.content.data && formResponse.content.data.fields) {
      const leadField: NDPSField = formResponse.content.data.fields.find(
        (field: NDPSField) => field.name === 'COD_LEAD_ID'
      );
      LeadID = leadField ? leadField.value : undefined;
    }
    return {
      LeadID,
      FormFields: undefined,
    };
  };

  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formInfo.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: "ThankYou" } } });

    const aux = copiesThankYou.filter((a: any) => {
      return a.name === formName;
    });
    setCompletInfo(aux);

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm()
    );
  }, []);

  const renderTitle = () => {
    return (
      <>
        <BreakpointWrapper max={Breakpoints.b1600}>
          <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
            {completeInfo ? completeInfo[0].titleCopyRegular : null}
            <Text bold>{completeInfo ? completeInfo[0].titleCopyBold : null}</Text>
          </Text>
        </BreakpointWrapper>
        <BreakpointWrapper min={Breakpoints.b1600}>
          <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
            {completeInfo ? completeInfo[0].titleCopyRegular : null}
            <Text bold>{completeInfo ? completeInfo[0].titleCopyBold : null}</Text>
          </Text>
        </BreakpointWrapper>
      </>
    );
  };

  const screen: Steps = {
    title: <>{renderTitle()}</>,
    fields: <Fields copyBook={completeInfo ? completeInfo[0].bodyCopy : null} />,
  };
  return (
    <RenderController
      screenType="layer-screen"
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
    />
  );
}

const mapStateToProps = (state: OneFormState) => {
  return {
    formData: state.formData.fields,
    formInfo: state.formInfo,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ThankYouProspects);

export { ConnectedComponent as ThankYouProspects };
