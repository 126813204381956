import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureServices, useFaContent, useTrackingManager } from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { CEMSegundaEntregaValoracionForm } from 'src/forms/Cem/CEMSegundaEntrega';
import { GenericErrorLayer } from 'src/components/layers';
import { createPincodeEntity } from 'src/forms/dps-utils';
import { getInstallations } from 'src/bff';
import { IdDealer, IdOrder, IdPerson, IdVehicle } from 'src';
import { LoadingEncuestas } from 'src/components';
import { arrayToObject } from 'src/helpers';


export function CEMSegundaEntregaValoracionController() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [usado, setUsado] = useState(false);

	const [idPerson, setIdPerson] = useState(null);
	const [idVehicle, setIdVehicle] = useState(null);
	const [idDealer, setIdDealer] = useState(null);
	const [idOrder, setIdOrder] = useState(null);

	const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

	const handleNext = (value: any) => { };
	const handleComplete = async () => {
		const dpsData = oneFormService.getDpsData() as DpsData;

		if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
			handleError();
		} else if (dpsData.pincode.pincodeUsed) {
			setUsado(true);
			setLoading(false);
		} else {
			setIdPerson(createPincodeEntity(dpsData, 'PERSON') as IdPerson);
			setIdVehicle(createPincodeEntity(dpsData, 'VEHICLE') as IdVehicle);
			setIdDealer(createPincodeEntity(dpsData, 'DEALER') as IdDealer);
			setIdOrder(createPincodeEntity(dpsData, 'ORDER') as IdOrder);

			if (dpsData.dealer) {
				const installationsRaw = await getInstallations('VW_DDB_FRM_CEM_SEGUNDA_ENTREGA_VALORACION', 'VOLKSWAGEN_SF_LEAD_INSTALLATION'); // Installations
				if (installationsRaw.length > 0) {
					let installations = installationsRaw.map((instal) => arrayToObject(instal.attributes)) as IdDealer[];
					installations = installations.filter((instal) => instal.VGED_DealerCode__c == dpsData.dealer);
					if (installations.length > 0) {
						setIdDealer(installations[0]);
					}
				}
				else {
					handleError('Installations not found');
				}
			}

			setLoading(false);
		};
	};

	const handleError = (message?: string) => {
		setError(true);
		setLoading(false);
		if (message) {
			console.error(message);
		}
	};

	useEffect(() => {
		const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

		oneFormCoreDpsIsLoaded.subscribe({
			next(value) {
				handleNext(value);
			},
			error(err) {
				console.error(err);
				handleError();
			},
			complete() {
				handleComplete();
			},
		});
	}, []);

	if (loading) {
		return <LoadingEncuestas />;
	} else if (error) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, algo ha pasado"
					bodycopy="Por alguna razón no hemos podido cargar el cuestionario."
					ctaLayer="Reintentar"
					notShowCTA={true}
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (usado) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, ya has respondido a este cuestionario"
					bodycopy="Parece que ya nos has contado cómo fue tu experiencia. ¿Quieres añadir algo más?"
					ctaLayer="Contacta con nosotros"
					ctaType="a"
					urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} 
	else {
		return (
			<CEMSegundaEntregaValoracionForm
				handleError={handleError}
				idPerson={idPerson}
				idVehicle={idVehicle}
				idDealer={idDealer}
				idOrder={idOrder}
			/>
		);
	}
}
