import { Spinner, styled, Text } from '@volkswagen-onehub/components-core';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BonoClienteForm } from './';
// import { MGMContactadoForm } from './MGMContactadoForm';
// import { MGMNoContactadoForm } from './MGMNoContactadoForm';
import { GenericErrorLayer } from 'src/components/layers';
import { useFeatureServices, useTrackingManager, useFaContent} from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { CarlineBase, DealersData, IdLead, IdPerson } from 'src/types';
// import { checkIdLeadHasCarline } from './use-initialize-MGM-form';
import { createPincodeEntity } from '../dps-utils';
import { EnvioRegaloForm } from './EnvioRegaloForm';
import { BonoRegaloForm } from './BonoRegaloForm';

export interface MGMFormProps {
  idLead: IdLead;
  idPerson: IdPerson;
  dpsData: DpsData;
  handleError: () => void;
  trackFormLoad: () => void;
}

export interface MGMFieldsProps {
  title: string;
  copy: string;
}

export function MGMController() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [usado, setUsado] = useState(false);
  const [caducado, setCaducado] = useState(false);
  const [bonoCliente, setBonoCliente] = useState(false);
  const [envioRegalo, setEnvioRegalo] = useState(false);
  const [bonoRegalo, setBonoRegalo] = useState(false);
  const trackingManager = useTrackingManager();
  const dpsDataRef = useRef(null);
  const idLeadRef = useRef(null);
  const idPersonRef = useRef(null);
  const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;
  const faContent = useFaContent();

  const handleNext = (value: any) => {};
  const handleComplete = () => {
    const dpsData = oneFormService.getDpsData();

    if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
      handleError();
    } else if (dpsData.pincode.pincodeUsed) {
      setUsado(true);
      setLoading(false);
    } else {
      const idLead = createPincodeEntity(dpsData, 'LEAD');
      const idPerson = createPincodeEntity(dpsData, 'PERSON');

      dpsDataRef.current = dpsData;
      idLeadRef.current = idLead;
      idPersonRef.current = idPerson;

      if (faContent.mgm === 'Bono Cliente') {
        setBonoCliente(true);
      } else if (faContent.mgm === 'Envío Regalo') {
        setEnvioRegalo(true);
      } else if (faContent.mgm === 'Bono Regalo') {
        setBonoRegalo(true);
      }
      setLoading(false);
    }
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  const trackFormLoad = () => {
    trackingManager.trackFormLoad();
  };

  useEffect(() => {
    const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

    oneFormCoreDpsIsLoaded.subscribe({
      next(value) {
        handleNext(value);
      },
      error(err) {
        console.error(err);
        handleError();
      },
      complete() {
        handleComplete();
      },
    });
  }, []);

  if (loading) {
    return <Loading />;
  } else if (error) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, algo ha pasado"
          bodycopy="Por alguna razón no hemos podido cargar el cuestionario."
          ctaLayer="Reintentar"
          notShowCTA={true}
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else if (caducado) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, este formulario ha caducado"
          bodycopy="Parece que el cuestionario ya no está disponible. ¿Quieres contarnos cómo ha sido tu experiencia?"
          ctaLayer="Contacta con nosotros"
          ctaType="a"
          urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else if (usado) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, ya has respondido a este cuestionario"
          bodycopy="Parece que ya nos has contado cómo fue tu experiencia. ¿Quieres añadir algo más?"
          ctaLayer="Contacta con nosotros"
          ctaType="a"
          urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else {
    return (
      <>
        {bonoCliente ? (
          <BonoClienteForm
            dpsData={dpsDataRef.current}
            idLead={idLeadRef.current}
            idPerson={idPersonRef.current}
            handleError={handleError}
            trackFormLoad={trackFormLoad}
          />
        ) : envioRegalo ? (
          <EnvioRegaloForm
            dpsData={dpsDataRef.current}
            idLead={idLeadRef.current}
            idPerson={idPersonRef.current}
            handleError={handleError}
            trackFormLoad={trackFormLoad}
          />
        ) : bonoRegalo ? (
          <BonoRegaloForm
            dpsData={dpsDataRef.current}
            idLead={idLeadRef.current}
            idPerson={idPersonRef.current}
            handleError={handleError}
            trackFormLoad={trackFormLoad}
          />
        ) : null}
      </>
    );
  }
}
interface CenterContentProps {
  height: string;
}
const CenterContent = styled.div<CenterContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? props.height : '100%')};
`;

const Loading = () => {
  const [height, setHeight] = useState('100%');

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    let aux;

    if (viewportWidth < 560) {
      aux = viewportHeight - 152;
    } else if (viewportWidth < 1600) {
      aux = viewportHeight - 252;
    } else {
      aux = viewportHeight - 380;
    }
    setHeight(`${aux}px`);
  }, []);

  return (
    <CenterContent height={height}>
      <Spinner variant="large" />
      <Text>Cargando tus datos</Text>
    </CenterContent>
  );
};
