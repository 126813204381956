import React from 'react';
import { ScreenController, useFaContent } from 'src/feature-app';
import { TestDriveForm, CitaVentaForm, MasInformacionForm, PresupuestoForm, leadsStockInPagePartialData  } from 'src/forms/Leads';
import { CallCenterForm } from 'src/forms/Leads/CallCenter';

//marketing code para development marketingCode=V57K29A5

export function LeadInPageTrigger() {
  const faContent = useFaContent();

  const renderTrigger = (Controller: JSX.Element) => {
    return (
      <>
        <ScreenController
          selectedJourney={Controller}
          closeLayerCallback={close}
          handleAreYouSureLayer={() => null}
          notALayer
          formType = 'inPage'
        />
      </>
    );
  };

  const isConfigurador = faContent?.featureApp == 'Configure' ? true : false;

  switch (faContent?.lead_type) {
    case 'test-drive':
      return renderTrigger(<TestDriveForm inPage isConfigurador={isConfigurador} />);
    case 'presupuesto':
      return renderTrigger(<PresupuestoForm inPage isConfigurador={isConfigurador}/>);
    case 'mas-informacion':
      return renderTrigger(<MasInformacionForm inPage isConfigurador={isConfigurador}/>);
    case 'cita':
      return renderTrigger(<CitaVentaForm inPage isConfigurador={isConfigurador}/>);
    case 'stock':
      return renderTrigger(<MasInformacionForm inPage updatedFormData={leadsStockInPagePartialData} isConfigurador={isConfigurador} />);
	case 'call-center':
		return renderTrigger(<CallCenterForm inPage isConfigurador={isConfigurador}/>);

    default:
      throw new Error('No property lead_type in FA Content.');
  }
}
