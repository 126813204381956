import Axios, { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { Text } from '@volkswagen-onehub/components-core';
import { OneElementTemplate } from 'src/feature-app/Screen';
import { ConfirmacionNewsletter } from './ConfirmacionNewsletter';
import { Email, PantallaNombreApellido } from 'src/components';
import { useDispatch, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps, MolecularResponse } from 'src/types';
import { FormController, removeWhiteSpace, useFeatureAppConfig, useOneFormContext } from 'src/feature-app';
import { ThankYouNewsletter } from './';
import { formDataBuilder } from '../format-fields';

export enum NewsletterSteps {
  NombreApellido,
  Email,
  Confirmacion,
}

export function NewsletterForm() {
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const config = useFeatureAppConfig();
  const dispatch = useDispatch();
  const steps: Steps[] = [
    {
      title: (
        <>
          Queremos contártelo todo sobre la familia ID. Pero antes, nos gustaría saber <Text bold>quién eres</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <div style={{ marginBottom: '2px' }}>
              <PantallaNombreApellido />
            </div>
          }
        />
      ),
      screenIndex: NewsletterSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      title: (
        <>
          <Text bold>¿A qué email </Text>te enviamos nuestras novedades?
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <div style={{ marginBottom: '2px' }}>
              <Email paginaConfirmacion={false} required={true} />
            </div>
          }
        />
      ),
      screenIndex: NewsletterSteps.Email,
      name: 'Email',
      outputs: ['email'],
      outputsText: ['email'],
    },
    {
      fields: <ConfirmacionNewsletter />,
      screenIndex: NewsletterSteps.Confirmacion,
      name: 'Confirmacion',
      isLastStep: true,
      hidePreviousStep: true,
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_VN_VO: 'VN',
      DESC_GDPR_ADAPTED: 'true',
    },
    fields: { formName: 'newsletter', formType: 'newsletter', pageCategory: 'Suscripción Newsletter' }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();

    const { fields, headers } = formData;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      const data = {
        'headers[DPS_TOKEN]': 'VW_DDB_FRM_ID_NEWSLETTER',
        'headers[FORM_DATE_INSERTION]': today,
        'headers[FORM_DETAILS]': 'ID', //Este debería pasarse a variable, aunque actualmente siempre sea ID
        'headers[FORM_NAME]': 'vw-oneform-newsletter',
        'headers[FORM_OBJECTIVE]': 'Suscripción Newsletter',
        'headers[FORM_URL]': url,
        'headers[FORM_VERSION]': `vw-oneform-newsletter-${process.env.VERSION}`,
        'headers[ORIGIN_DEVICE_TYPE]': device,
        'headers[ORIGIN_TRACKING_CODE]': tc,
        'headers[SF_CAMPAIGN_ORIGIN_ID]': 'PENDIENTE',

        'flags[FLAG_RELACION_MARCA_SIN_IC]': false,
        'flags[PERSON_RGPD_ADAPTED]': true,

        'fields[DPS_BRAND]': 'Volkswagen',
        'fields[SF_LEAD_SALES_INITIAL_ACTION]': 'Prospect',
        'fields[PERSON_NAME]': fields.name,
        'fields[PERSON_SURNAME_1]': fields.surname,
        'fields[PERSON_SURNAME_2]': fields.secondSurname,
        'fields[PERSON_EMAIL]': removeWhiteSpace(fields.email),
        'fields[PERSON_PHONE_MOBILE]': fields.cellphone,
        'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
      };
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData);

      if (response && response.data && response.data.content && response.data?.status?.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      formTheme: 'inverted',
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      notALayer: false,
      formType: 'layer',
      thankYouComponent: <ThankYouNewsletter />,
    };

    initializeForm(NuevoFormInfo, NuevoFormData);
  }, []);

  return <FormController steps={steps} screenType="layer-screen" />;
}
