import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFaContent } from 'src/feature-app';

/**
 * Hook necesario para disparar formularios desde bonos. El objetivo es enviar como observaciones la descripción del bono.
 * @param setSelectedJourney
 * @param Form
 */
export function useInitiateBonosTriggers(setSelectedJourney: any, Form: JSX.Element) {
  const faContent = useFaContent();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELDS',              // Pendiente propagar el valor del campo observaciones desde faContent
      payload: {  },
    });

    setSelectedJourney(Form, true);
  }, []);
}
