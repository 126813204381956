export const getTestDealer = [
	{
		name: 'ID',
		value: 'DEALER',
	},
	{
		name: 'Id',
		value: '0011i00001dahNuAAI',
	},
	{
		name: 'VGED_CommercialName__c',
		value: 'CARHAUS',
	},
	{
		name: 'VGED_DealerCode__c',
		value: '0322H',
	},
	{
		name: 'KVPSCode__c',
		value: 'ESP08034V',
	},
	{
		name: 'VGED_ConcessionDealerCode__c',
		value: '0322H',
	},
	{
		name: 'ShippingStreet',
		value: 'CTRA. DE SANT BOI 11-13',
	},
	{
		name: 'ShippingPostalCode',
		value: '08940',
	},
	{
		name: 'ShippingCity',
		value: 'CORNELLA DE LLOBREGAT',
	},
	{
		name: 'ShippingLatitude',
		value: '41.355749',
	},
	{
		name: 'ShippingLongitude',
		value: '2.065784',
	},
	{
		name: 'ShippingStateCode',
		value: '8',
	},

	///////////// sin uso

	{
		name: 'RCY_DealerType__c',
		value: '1',
	},
	{
		name: 'BillingStreet',
		value: 'CTRA. DE SANT BOI 11-13',
	},
	{
		name: 'BillingPostalCode',
		value: '08940',
	},
	{
		name: 'BillingCity',
		value: 'CORNELLA DE LLOBREGAT',
	},
	{
		name: 'BillingStateCode',
		value: '8',
	},
	{
		name: 'BillingLatitude',
		value: '41.355749',
	},
	{
		name: 'BillingLongitude',
		value: '2.065784',
	},
];
