import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { CanalDeContacto, Email, ContactInfoWrapper, PantallaNombreApellido, Telefono, CodigoPostal, ConsentQuestion } from 'src/components';
import { OneElementTemplate } from 'src/feature-app/Screen/Templates';
import { MapaLeads, ConfirmacionLeadsNoQuestions } from 'src/forms';
import { FormDataGroup, FormInfo, Steps } from 'src/types';
import { ConfirmacionLeads } from '../ConfirmacionLeads';
import { useInitializeLeadForm } from '../use-initialize-lead-form';
import { LeadGenericoController } from '../LeadGenericoController';
import { useIsComerciales } from 'src/feature-app/hooks';

export enum OfertaSteps {
  Contacto,
  EmailTelefono,
  Mapa,
  NombreApellido,
  CodigoPostal,
}

export function OfertaForm() {
  const isComerciales = useIsComerciales();

  const formSteps: Steps[] = [
    {
      title: (
        <>
          <Text bold>¿Cómo prefieres </Text>que contactemos contigo?
          <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
        </>
      ),
      fields: <OneElementTemplate element={<CanalDeContacto />} />,
      screenIndex: OfertaSteps.Contacto,
      name: 'Contacto',
      outputs: ['contacto'],
      outputsText: ['contacto'],
    },
    {
      title: (
        <>
          Para ello necesitaremos <Text bold>tus datos de contacto</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <ContactInfoWrapper>
              <Telefono hidePrefijo={isComerciales} paginaConfirmacion={false} required={true} />
              <Email paginaConfirmacion={false} required={true} paddingTop='24px' />
            </ContactInfoWrapper>
          }
        />
      ),
      screenIndex: OfertaSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      screenIndex: OfertaSteps.Mapa,
      name: 'Mapa',
      multiStep: true,
      component: <MapaLeads formName="oferta" />,
    },
    {
      title: (
        <>
          Para poder contactar contigo, necesitamos saber <Text bold>quién eres</Text>
        </>
      ),
      fields: <OneElementTemplate element={<div style={{ marginBottom: '2px' }}><PantallaNombreApellido isComerciales={isComerciales} /></div>} />,
      screenIndex: OfertaSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      title: (
        <>
          También nos gustaría conocer <Text bold>tu código postal</Text>
        </>
      ),
      fields: <OneElementTemplate element={<div style={{ width: '100%', paddingBottom: '2px' }} className='codigo-postal-empresa'><CodigoPostal /></div>} />,
      screenIndex: OfertaSteps.CodigoPostal,
      name: 'CodigoPostal',
      outputs: ['codigoPostal'],
      outputsText: ['codigoPostal'],
    },
  ];

  const confirmSteps: Steps[] =
      [
        {
          fields: <ConsentQuestion questionType='lssi' />,
          name: 'lssi',
          outputs: ['lssi'],
          screenIndex: formSteps.length,
        },
        {
          fields: <ConsentQuestion questionType='tmk' />,
          name: 'tmk',
          outputs: ['tmk'],
          screenIndex: formSteps.length + 1,
        },
        {
          fields: <ConfirmacionLeadsNoQuestions />,
          name: 'Confirmacion',
          isLastStep: true,
          hidePreviousStep: true,
          screenIndex: formSteps.length + 2,
        },
      ]
    ;

  const { loading, steps, firstStep, lastStep } = useInitializeLeadForm([...formSteps, ...confirmSteps], true);

  const citaVentaData: Partial<FormDataGroup> = {
    headers: isComerciales ? { FORM_OBJECTIVE : 'SF201903221335'} : {FORM_OBJECTIVE : 'Presupuesto' },
    fields: {
      formFields: {},
      formularioFlag: 'Presupuesto',
      formName: 'lead-oferta',
      formType: 'quote',
      pageCategory: 'Oferta',
    },
  };

  const citaVentaInfo: Partial<FormInfo> = {
    numberOfScreens: steps.length,
  };

  return loading ? null : (
    <LeadGenericoController
      steps={steps}
      leadData={citaVentaData}
      leadInfo={citaVentaInfo}
      firstStep={firstStep}
      lastStep={lastStep}
    />
  );
}
