export const provinces = [
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "2"
            },
            {
                "name": "NAME",
                "value": "Albacete"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "3"
            },
            {
                "name": "NAME",
                "value": "Alicante/Alacant"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "4"
            },
            {
                "name": "NAME",
                "value": "Almería"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "1"
            },
            {
                "name": "NAME",
                "value": "Araba/Álava"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "33"
            },
            {
                "name": "NAME",
                "value": "Asturias"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "5"
            },
            {
                "name": "NAME",
                "value": "Ávila"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "6"
            },
            {
                "name": "NAME",
                "value": "Badajoz"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "7"
            },
            {
                "name": "NAME",
                "value": "Balears, Illes"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "8"
            },
            {
                "name": "NAME",
                "value": "Barcelona"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "48"
            },
            {
                "name": "NAME",
                "value": "Bizkaia"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "9"
            },
            {
                "name": "NAME",
                "value": "Burgos"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "10"
            },
            {
                "name": "NAME",
                "value": "Cáceres"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "11"
            },
            {
                "name": "NAME",
                "value": "Cádiz"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "39"
            },
            {
                "name": "NAME",
                "value": "Cantabria"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "12"
            },
            {
                "name": "NAME",
                "value": "Castellón/Castelló"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "13"
            },
            {
                "name": "NAME",
                "value": "Ciudad Real"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "14"
            },
            {
                "name": "NAME",
                "value": "Córdoba"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "15"
            },
            {
                "name": "NAME",
                "value": "Coruña, A"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "16"
            },
            {
                "name": "NAME",
                "value": "Cuenca"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "20"
            },
            {
                "name": "NAME",
                "value": "Gipuzkoa"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "17"
            },
            {
                "name": "NAME",
                "value": "Girona"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "18"
            },
            {
                "name": "NAME",
                "value": "Granada"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "19"
            },
            {
                "name": "NAME",
                "value": "Guadalajara"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "21"
            },
            {
                "name": "NAME",
                "value": "Huelva"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "22"
            },
            {
                "name": "NAME",
                "value": "Huesca"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "23"
            },
            {
                "name": "NAME",
                "value": "Jaén"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "24"
            },
            {
                "name": "NAME",
                "value": "León"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "25"
            },
            {
                "name": "NAME",
                "value": "Lleida"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "27"
            },
            {
                "name": "NAME",
                "value": "Lugo"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "28"
            },
            {
                "name": "NAME",
                "value": "Madrid"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "29"
            },
            {
                "name": "NAME",
                "value": "Málaga"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "30"
            },
            {
                "name": "NAME",
                "value": "Murcia"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "31"
            },
            {
                "name": "NAME",
                "value": "Navarra"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "32"
            },
            {
                "name": "NAME",
                "value": "Ourense"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "34"
            },
            {
                "name": "NAME",
                "value": "Palencia"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "35"
            },
            {
                "name": "NAME",
                "value": "Palmas, Las"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "36"
            },
            {
                "name": "NAME",
                "value": "Pontevedra"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "26"
            },
            {
                "name": "NAME",
                "value": "Rioja, La"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "37"
            },
            {
                "name": "NAME",
                "value": "Salamanca"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "38"
            },
            {
                "name": "NAME",
                "value": "Santa Cruz de Tenerife"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "40"
            },
            {
                "name": "NAME",
                "value": "Segovia"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "41"
            },
            {
                "name": "NAME",
                "value": "Sevilla"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "42"
            },
            {
                "name": "NAME",
                "value": "Soria"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "43"
            },
            {
                "name": "NAME",
                "value": "Tarragona"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "44"
            },
            {
                "name": "NAME",
                "value": "Teruel"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "45"
            },
            {
                "name": "NAME",
                "value": "Toledo"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "46"
            },
            {
                "name": "NAME",
                "value": "Valencia/València"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "47"
            },
            {
                "name": "NAME",
                "value": "Valladolid"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "49"
            },
            {
                "name": "NAME",
                "value": "Zamora"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "50"
            },
            {
                "name": "NAME",
                "value": "Zaragoza"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "51"
            },
            {
                "name": "NAME",
                "value": "Ceuta"
            }
        ]
    },
    {
        "attributes": [
            {
                "name": "CODE",
                "value": "52"
            },
            {
                "name": "NAME",
                "value": "Melilla"
            }
        ]
    }
]