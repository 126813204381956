import {
	Breakpoints,
	BreakpointWrapper,
} from '@volkswagen-onehub/components-core';
import React from 'react';

export const CTAsSiNo = [
	{
		label: 'Sí',
		value: 'true'
	},
	{
		label: 'No',
		value: 'false'
	},
];

export const contactoOptionsTurismos = [
	{
		label: 'Whatsapp',
		value: 'Whatsapp',
	},
	{
		label: 'Teléfono',
		value: 'Telefono',
	},
	{
		label: 'Email',
		value: 'Email',
	},
];

export const contactoOptionsComerciales = [
	{
		label: 'Teléfono',
		value: 'Telefono',
	},
	{
		label: 'Email',
		value: 'Email',
	},
];

export const CTAsHorario = [
	{
		label: 'Por la mañana',
		value: 'morning'
	},
	{
		label: 'Al mediodía',
		value: 'afternon'
	},
	{
		label: 'Por la tarde',
		value: 'evening'
	},
];

//Cita posventa

export interface CTAsMotivoDef {
	label: string;
	value: string;
	detalles?: string | JSX.Element;
	detallesPrevStep?: string;
}

export const CTAsMotivo: CTAsMotivoDef[] = [
	{
		label: 'Mantenimiento',
		value: 'MANTENIMIENTO',
		detalles: 'la revisión de mantenimiento',
		detallesPrevStep: 'Revisión de mantenimiento',
	},
	{
		label: 'Neumáticos',
		value: 'NEUMATICOS',
		detalles: 'el cambio de neumáticos',
		detallesPrevStep: 'Cambio de neumáticos',
	},
	{
		label: 'Avería',
		value: 'REPARACION',
		detalles: 'la avería',
		detallesPrevStep: 'Reparación de avería'
	},
	{
		label: 'Revisión pre-ITV',
		value: 'PREITV',
		detalles: (
			<>
				la revisión{' '}
				<BreakpointWrapper min={Breakpoints.b960}>
					<br />
				</BreakpointWrapper>
				pre-ITV
			</>
		),
		detallesPrevStep: 'Revisión pre-ITV',
	},
	{
		label: 'Chapa y pintura',
		value: 'CARROCERIA',
		detalles: 'la reparación de chapa y pintura',
		detallesPrevStep: 'Reparación de chapa y pintura',
	},
	{
		label: 'Accesorios',
		value: 'ACCESORIOS',
		detalles: 'la instalación de los accesorios',
		detallesPrevStep: 'Instalación de accesorios',
	},
	/* {
		label: 'Chequeo',
		value: 'CHEQUEO',
		detalles: 'el chequeo de tu coche',
		detallesPrevStep: 'Chequeo'
	}, */
	{
		label: 'Actualización Software ID',
		value: 'OTROS',
		detalles: 'Actualización Software ID',
		detallesPrevStep: 'Actualización Software ID'
	},
	{
		label: 'Otros',
		value: 'OTROS',
		detalles: 'el motivo de tu visita',
		detallesPrevStep: 'Visita'
	},
];

export const CTAsDudas = [
	{
		label: 'Los plazos de entrega',
		value: 'plazos'
	},
	{
		label: 'Tu visita al punto de venta',
		value: 'experiencia'
	},
	{
		label: 'El precio del modelo',
		value: 'precio'
	},
	{
		label: 'El equipamiento y los asistentes adicionales',
		value: 'equipamiento'
	},
	{
		label: 'El consumo del modelo',
		value: 'consumo'
	},
	{
		label: 'La garantía del modelo',
		value: 'garantia'
	},
	{
		label: 'Las condiciones de financiación',
		value: 'financiación'
	},
	{
		label: 'La situación actual',
		value: 'situacion'
	},
	{
		label: 'Otros motivos',
		value: 'otros'
	},
];

//SAC

export const contactoOptionsSAC = [
	{
		label: 'Email',
		value: 'EMAIL',
	},
	{
		label: 'Teléfono',
		value: 'TELEFONO',
	},
	/* {
		label: 'Correo postal',
		value: 'POSTAL',
	 }, */
	{
		label: 'Whatsapp',
		value: 'WHATSAPP',
	},
];

//CEM postenta y CEM venta

export const ctaTestDrive = [
	{
		label: 'Sí, y realicé la prueba',
		value: '1'
	},
	{
		label: 'Sí, pero no realicé la prueba',
		value: '2'
	},
	{
		label: 'No, pero estaba interesado/a ',
		value: '3'
	},
	{
		label: 'No, y no estaba interesado/a',
		value: '4'
	},
	{
		label: 'Sin respuesta',
		value: '9'
	},
];

export const ctaAlternativaTransporte = [
	{
		label: 'Sí, un vehículo de sustitución',
		value: '1'
	},
	{
		label: 'Sí, otra alternativa (transporte público, taxi, shuttle, …)',
		value: '2'
	},
	{
		label: 'No, pero la hubiera necesitado',
		value: '3'
	},
	{
		label: 'No, y tampoco la necesitaba',
		value: '4'
	},
	{
		label: 'Sin respuesta',
		value: '9'
	},
];
export const ctaContactoSatisfaccion = [
	{
		label: 'Sí',
		value: '1'
	},
	{
		label: 'No',
		value: '2'
	},
	{
		label: 'Sin respuesta',
		value: '9'
	},
];

export const ctaTrabajosAdicionales = [
	{
		label: 'Sí',
		value: '1'
	},
	{
		label: 'No',
		value: '2'
	},
	{
		label: 'Sin respuesta',
		value: '9'
	},
];

export const ctaMotivoVisita = [

	{
		label: 'Revisión pre-ITV',
		value: '2'
	},
	{
		label: 'Avería circulando con el coche (fue necesario remolcarlo)',
		value: '4'
	},
	{
		label: 'Medida de servicio (excluida la medida de servicio diésel)',
		value: '10'
	},
	{
		label: 'Chapa y pintura',
		value: '6'
	},
	{
		label: 'Cambio de neumáticos',
		value: '3'
	},
	{
		label: 'Actualización de software',
		value: '11'
	},
	{
		label: 'Instalación de accesorios',
		value: '7'
	},
	{
		label: 'Servicio rutinario / de mantenimiento',
		value: '1'
	},
	{
		label: 'Otra visita inesperada (el coche pudo seguir circulando y elegiste tu Sercicio Oficial favorito)',
		value: '5'
	},
	{
		label: 'Otras reparaciones  o reclamaciones (por ejemplo, problemas electrónicos)',
		value: '8'
	},
	{
		label: 'Sin respuesta',
		value: '99'
	},
];


export const ctaEnvioCEM = [
	{
		label: 'Sí, mis respuestas pueden ser publicadas sin mi nombre en la Web Oficial Volkswagen y en la de [Installation]',
		value: '1',
	},
	{
		label: 'No, mis respuestas no pueden ser publicadas en la Web Oficial Volkswagen y en la de [Installation]',
		value: '2',
	},
];

// CEM ONESHOP

export const ctaPruebaTCross = [
	{
		label: 'Sí',
		value: 'true'
	},
	{
		label: 'No',
		value: 'false'
	},
	{
		label: 'No, pero me lo ofrecieron',
		value: 'No, pero me lo ofrecieron'
	},
];

// No renovador

export const CTAsMotor = [
	{
		label: 'Gasolina',
		value: 'gasolina',
	},
	{
		label: 'Diesel',
		value: 'diesel',
	},
	{
		label: 'Híbrido',
		value: 'híbrido',
	},
	{
		label: 'Eléctrico',
		value: 'electrico',
	},
];

export const CTAsMotivoRenovador = [
	{
		label: 'Los plazos de entrega',
		value: '1',
	},
	{
		label: 'El precio y las opciones de financiación',
		value: '2',
	},
	{
		label: 'El diseño del coche',
		value: '3',
	},
	{
		label: 'El equipamiento del coche',
		value: '4',
	},
	{
		label: 'Relación calidad/precio',
		value: '5',
	},
	{
		label: 'La poca variedad de modelos y tecnologías disponibles',
		value: '6',
	},
	{
		label: 'Mi experiencia con el concesionario de venta',
		value: '7',
	},
	{
		label: 'Por problemas con mi vehículo anterior',
		value: '14',
	},
	{
		label: 'Otros motivos',
		value: '13',
	},
];

export const CTAsMotivoRenovadorProblemas = [
	{
		label: 'El consumo de combustible del vehículo anterior',
		value: '8',
	},
	{
		label: 'Los costes de mantenimiento del vehículo anterior',
		value: '9',
	},
	{
		label: 'Las averías sufridas con el vehículo anterior',
		value: '10',
	},
	{
		label: 'Mi experiencia previa con el servicio de posventa',
		value: '11',
	},
	{
		label: 'Otras experiencias con la marca',
		value: '12',
	},
];

export const CTAsMotivoRenovadorNoTengoExclusivo = [
	{
		label: 'Nada',
		value: 'nada',
	},
];

export const CTAsUsadoNuevo = [
	{
		label: 'He comprado un vehículo nuevo',
		value: '1',
	},
	{
		label: 'He comprado un vehículo de ocasión',
		value: '2',
	},
];

export const CTAsLugarCompra = [
	{
		label: 'Concesionario oficial',
		value: '1',
	},
	{
		label: 'Concesionario no oficial',
		value: '2',
	},
	{
		label: 'Empresa de Renting/Leasing',
		value: '3',
	},
	{
		label: 'Compra-venta privada',
		value: '4',
	},
];

export const CTAsNuevoOcasión = [
	{
		label: 'Uno nuevo',
		value: 'nuevo',
	},
	{
		label: 'Uno de ocasión',
		value: 'ocasión',
	},
];

export const CTAsFormasMovilidad = [
	{
		label: 'Coche compartido (car sharing)',
		value: '1',
	},
	{
		label: 'Moto compartida (moto sharing)',
		value: '2',
	},
	{
		label: 'Transporte público',
		value: '3',
	},
	{
		label: 'Patinete',
		value: '4',
	},
	{
		label: 'Moto',
		value: '5',
	},
	{
		label: 'Bicicleta',
		value: '6',
	},
	{
		label: 'Otros',
		value: '8',
	},
];

export const CTAsFormasMovilidadExclusive = [
	{
		label: 'No tengo necesidad de utilizar ningún medio de transporte para desplazarme',
		value: '7',
	},
];

export const CTAsRazones = [
	{
		label: 'Económicas',
		value: '1',
	},
	{
		label: 'Medioambientales',
		value: '2',
	},
	{
		label: 'Ahorro de tiempo',
		value: '3',
	},
	{
		label: 'Otras razones',
		value: '4',
	},
];

