import React, { Ref, useEffect, useState } from 'react';
import { TextArea, TextAreaProps } from '@volkswagen-onehub/components-core';
import { useFormContext } from 'react-hook-form';
import { useFeatureServices } from 'src/feature-app';
import { FieldType } from 'src/feature-service/es-oneform-core-feature-service/types';
import { useSelector } from 'react-redux';
import { OneFormState } from 'src/types';
import { useTrackingManager } from 'src/feature-app/hooks';
import { useOneFormContext } from 'src/feature-app';

export interface TextAreaExtendedProps extends TextAreaProps {
  name: FieldType;
  innerRef?: Ref<HTMLInputElement>;
  /**
   * En caso de que el input esté junto con un CTA, se debe de pasar el label del CTA para en caso de que se seleccione el CTA no se refleje en el input.
   */
  alternateCTAText?: string;
  rows?: number;
  required?: boolean;
  maxLength?: number;
}

export function TextAreaExtended(props: TextAreaExtendedProps) {
  const { innerRef, name, onBlur, alternateCTAText, onChange, label, rows, required, maxLength } = props;
  const { setValue } = useFormContext();
  const { error, setError } = useOneFormContext();
  const trackingManager = useTrackingManager();
  const oneFormFeatureService = useFeatureServices()['es:oneform-core'];
  const { formData } = useSelector((state: OneFormState) => state);
  const [currentValue, setCurrentValue] = useState('');

  const addMaxLength = () => {
    const aux = document.getElementsByTagName('textarea');
    aux[0].setAttribute('maxlength', maxLength.toString());
  };

  useEffect(() => {
    if (maxLength) addMaxLength();
    const value = formData.fields[name];

    if (value && value !== alternateCTAText) {
      setCurrentValue(value);
    }

  }, []);

  return (
    <>
      <TextArea
        {...props}
        onChange={(e: any) => {
          setCurrentValue(e.currentTarget.value);
          if(e.currentTarget.value.length > 3){
            setError(false);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e: any) => {
          if (onBlur) onBlur(e);
          setValue(name, e.currentTarget.value);
          oneFormFeatureService.setField(name, e.currentTarget.value);
        }}
        onFocus={() => {
          trackingManager.trackFormFieldClick(
            {
              contentId: label,
            },
            {
              FormFieldName: name,
            }
          );
        }}
        value={currentValue}
        rows={rows ? rows : 3}
        required={required ? required : false}
        maxLength={maxLength}
      />
      <input type="hidden" name={name} value={currentValue} ref={innerRef} />
    </>
  );
}
