import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputError, TextInputExtended, Email, GeneralInputWidthWrapper } from 'src/components';
import { useOnlyLettersControl } from 'src/components';
import { styled } from '@volkswagen-onehub/components-core';

const ContentWrapper = styled.div`
  padding-bottom: 2px;
  margin-top: 4px;
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;
  }
`;

interface FriendsInfoProps {
  nameNombre: string;
  labelNombre: string;
  nameMail: string;
  labelMail: string;
}

export const FriendsInfo = (props: FriendsInfoProps) => {
  const { nameNombre, labelMail, labelNombre, nameMail } = props;
  const { register } = useFormContext();
  const [inputValue, setInputValue] = useState('');
  const [pristine, setPristine] = useState(true);
  const { error, regExp } = useOnlyLettersControl(pristine, inputValue);

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    setPristine(false);
  };

  return (
    <ContentWrapper className='content-wrapper'>
      <GeneralInputWidthWrapper notALayer={false}>
        <TextInputExtended
          name= {nameNombre}
          label={labelNombre}
          isFloating
          innerRef={register({
            required: {
              value: true,
              message: 'Requerido',
            },
            pattern: {
              value: regExp,
              message: 'Solo se aceptan letras',
            },
          })}
          onChange={(e) => handleChange(e)}
          required
        />
        {error && <InputError className='input-error'>Solo se aceptan letras</InputError>} 
      </GeneralInputWidthWrapper >
      <Email paginaConfirmacion={false} required={true} name={nameMail} label={labelMail}/>
    </ContentWrapper>
  );
};
