import { CarlineBase, CarlinesWithSalesgroups, OneFormState } from 'src/types';
import { findCarlineFromSalesgroup } from '.';
import { useFaContent } from 'src/feature-app';


export const findCarline = (
  carlines: CarlineBase[] | CarlinesWithSalesgroups[],
  code: number | string,
  salesgroupId?: number
) => {
  if (salesgroupId) {
    let carline = findCarlineFromSalesgroup(carlines, salesgroupId);
    return carline;
    } else if (carlines.find((carline: CarlineBase) => carline.code === Number(code))) {
    let carline = carlines.find((carline: CarlineBase) => carline.code === Number(code));
    return carline;
  } else {
    let carline = carlines.find((carline: CarlineBase) => carline.name === (code));
    return carline;
  }
 
  return null;
};
