import { BBox } from 'geojson';
import Supercluster from 'supercluster';

export const generateClusters = (points: any[], zoom: number, bounds: BBox): { clusters: any; supercluster: any } => {
  const options = { radius: 40, maxZoom: 20 };

  //   const superclusterRef = useRef<Supercluster<any>>();
  let supercluster;

  //   const pointsRef = useRef<Array<Supercluster.PointFeature<any>>>();
  const zoomInt = Math.round(zoom);

  if (!supercluster) {
    supercluster = new Supercluster(options);
    supercluster.load(points);
  }

  if (bounds) {
    const clusters = supercluster.getClusters(bounds, zoomInt);
    return { clusters, supercluster };
  }
  return { clusters: null, supercluster: null };
};
