import dayjs from 'dayjs';
import { DomainAttributesValues } from 'src';

export const formatToTwoChars = (str: string) => {
	if (str === null || str === undefined || str === '') {
		return '';
	}

	str = String(str);

	return str.length === 1 ? '0' + str : str;
};

export const arrayToObject = (arr: DomainAttributesValues[]) => {
	if (!arr) return null;
	const obj = {};
	for (const i in arr) {
		obj[arr[i].name] = arr[i].value;
	}
	return obj;
};

export const strTitlelize = (str: string) => {
	if (!str) return '';
	return str
		.toLowerCase()
		.split(' ') // separar palabras para capitalizar
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
		.split('.') // separar sociedades para capitalizar (S.A., S.L.U.)
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join('.');
};

export const transformDate = (date: string, outputFormat: string, inputFormat?: string) => {
	if (inputFormat) {
		if (dayjs(date, inputFormat, true).isValid()) {
			return dayjs(date, inputFormat).format(outputFormat);
		}
	} else if (dayjs(date, 'YYYY-MM-DD', true).isValid()) {
		return dayjs(date, 'YYYY-MM-DD').format(outputFormat);
	}
	return null;
};

export const getFirstAndLastDayOfMonth = (
	monthNumber?: string,
	yearNumber?: string,
): { firstDayOfMonth: number; lastDayOfMonth: number } => {
	let firstDayOfMonth;
	let lastDayOfMonth;

	if (yearNumber && monthNumber) {
		firstDayOfMonth = Number(
			dayjs()
				.locale('es')
				.utc()
				.month(Number(monthNumber) - 1) // Months are zero indexed, so January is month 0.
				.year(Number(yearNumber))
				.startOf('month')
				.format('x'),
		);
		lastDayOfMonth = Number(
			dayjs()
				.locale('es')
				.utc()
				// .add(1, 'month')
				.month(Number(monthNumber) - 1)
				.year(Number(yearNumber))
				.endOf('month')
				.format('x'),
		);
	} else {
		firstDayOfMonth = Number(dayjs().add(1, 'day').locale('es').utc().format('x'));
		lastDayOfMonth = Number(
			dayjs()
				.locale('es')
				.utc()
				// .add(1, 'month')
				.endOf('month')
				.format('x'),
		);
	}

	return { firstDayOfMonth, lastDayOfMonth };
};
