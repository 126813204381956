import React, { useEffect, useRef, useState } from 'react';
import { useFeatureServices } from 'src/feature-app';
import { EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { IdPerson } from 'src/types';
import { BajaComunicacionesForm } from '.';
import { createPincodeEntity } from '../dps-utils';
import { LoadingEncuestas } from 'src/components';

export interface BajaComunicacionesProps {
  idPerson: IdPerson;
  handleError: () => void;
}

export function BajaComunicacionesController() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const dpsDataRef = useRef(null);
  const idPersonRef = useRef(null);
  const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

  const handleNext = (value: any) => { };

  const handleComplete = () => {
    const dpsData = oneFormService.getDpsData();
    dpsDataRef.current = dpsData;

    if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
      setLoading(false);
    } else if (dpsData.pincode.pincodeUsed) {
      setLoading(false);
    } else {
      const idPerson = createPincodeEntity(dpsData, 'PERSON');
      idPersonRef.current = idPerson;
      setLoading(false);
    }
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  useEffect(() => {
    const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

    oneFormCoreDpsIsLoaded.subscribe({
      next(value) {
        handleNext(value);
      },
      error(err) {
        console.error(err);
        handleError();
      },
      complete() {
        handleComplete();
      },
    });
  }, []);

  if (loading) {
    return <LoadingEncuestas/>;
  } else if (error) {
		return null;
	} else {
    return (
      <BajaComunicacionesForm
        idPerson={idPersonRef.current}
        dpsData={dpsDataRef.current}
      />
    );
  }
}
