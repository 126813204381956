import {
    styled
} from '@volkswagen-onehub/components-core';
import { keyframes } from 'styled-components';
import { MarkerDimensions } from 'src/feature-app/NewMap/MapGeneralController';


const beatKeyframe = keyframes`
  from {
    opacity: 1;
    transform: translate3d(50%, 50%, 0) scale(0);
  }
  to {
    opacity: 0;
    transform: translate3d(50%, 50%, 0) scale(1);
  }
`;

export const Wrapper = styled.div<{ onTop?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -100%, 0);
  z-index: ${(props) => (props.onTop ? '3' : '2')};
  &.geoloc {
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
  }
  .circle-beat {
    animation: ${beatKeyframe} 2s ease 0s infinite normal none running;
  }
`;

export const TextWrapper = styled(Wrapper as any)`
  /* https://github.com/microsoft/TypeScript/issues/37597#issuecomment-628089901 */
  top: 68%;
  color: #ffffff;
  & div {
    color: #ffffff;
  }
`;

export const Img = styled.img<{ markerDimensions: MarkerDimensions }>`
  width: ${(props) => `${props.markerDimensions.width}px`};
  height: ${(props) => `${props.markerDimensions.height}px`};
  &.dealer-marker {
    margin-bottom: 4px;
  }
`;
