import {
  Container,
  CTA,
  styled,
  ContainerPadding,
} from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerHandleV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { MGMFieldsProps } from 'src/forms';
import { Apellido, Email, Nombre, Telefono, LssiTkmQuestions, GeneralInputWidthWrapper } from 'src/components';
import {
  LayerManagerWrapper,
  useFeatureAppEnvironment,
  useFeatureServices,
  useOneFormContext,
  useTrackingManager,
} from 'src/feature-app';
import { KoScreen, LoadingScreen } from 'src/forms/CitaPosventa/NuevaCitaPosventa/ConfirmarCitaLayer';
import { OneFormState } from 'src/types';
import { MGMTitle } from './MGMLayout';

const GeneralconfirmacionWrapper = styled.div``;

export function BonoRegaloFields({ title, copy }: MGMFieldsProps) {
  const [lssi, setLssi] = useState<string>(null);
  const [tmk, setTmk] = useState<string>(null);
  const [lssiError, setLssiError] = useState<boolean>(false);
  const [tmkError, setTmkError] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (lssi) {
      const values = { lssi: lssi };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setLssiError(false);
    }
    if (tmk) {
      const values = { tmk: tmk };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setTmkError(false);
    }
  }, [lssi, tmk]);

  return (
    <GeneralconfirmacionWrapper>
      <MGMTitle title={title} copy={copy} />
      <Container wrap={'always'} padding={{ bottom: ContainerPadding.static250 }}>
        <GeneralInputWidthWrapper notALayer>
          <Nombre />
        </GeneralInputWidthWrapper>
        <GeneralInputWidthWrapper notALayer>
          <Apellido name="surname" required label="Primer apellido" />
        </GeneralInputWidthWrapper>
        <Email required notALayer />
        <Telefono required notALayer />
      </Container>
      <div className="last-navigation-position" style={{ width: '100%', display: 'flex' }}>
        <LastNavigation
          tmk={tmk}
          lssi={lssi}
          lssiError={lssiError}
          tmkError={tmkError}
          setTmkError={setTmkError}
          setLssiError={setLssiError}
          setLssi={setLssi}
          setTmk={setTmk}
        />
      </div>
    </GeneralconfirmacionWrapper>
  );
}

interface LastNavigationProps {
  tmk: string;
  lssi: string;
  tmkError: boolean;
  lssiError: boolean;
  setTmkError: React.Dispatch<React.SetStateAction<boolean>>;
  setLssiError: React.Dispatch<React.SetStateAction<boolean>>;
  setTmk: React.Dispatch<React.SetStateAction<string>>;
  setLssi: React.Dispatch<React.SetStateAction<string>>;
}

function LastNavigation(props: LastNavigationProps) {
  const { tmk, lssi, setTmkError, setLssiError, tmkError, lssiError, setLssi, setTmk } = props;
  // const dispatch = useDispatch();
  const { handleShowFinalScreen, handleSubmit } = useOneFormContext();
  const { getValues, triggerValidation } = useFormContext();
  const [layerVisible, setLayerVisible] = useState<boolean>(false);
  const [koLayer, setKoLayer] = useState<LayerHandleV2<any>>(null);
  const [koScreen, setKoScreen] = useState<boolean>(false);
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const dispatch = useDispatch();
  const trackingManager = useTrackingManager();
  const { formData } = useSelector((state: OneFormState) => state);

  //Loading and Ko layers

  const handleKoLayer = () => {
    const layer = layerManager.openFocusLayer(renderKoLayer, {}, { size: FocusLayerSizeV2.A });
    setKoLayer(layer);
  };

  const renderKoLayer = (state: any, close: CloseHandleV2<any, any>) => {
    const NoCompraTitle = 'Uy, algo ha pasado';
    const NoCompraBody = 'Por alguna razón no hemos podido guardar tu respuesta. ¿Lo intentamos de nuevo? ';

    return (
      <LayerManagerWrapper store={store} env={env}>
        <KoScreen
          retrySubmit={retrySubmit}
          closeLayerCallback={close}
          title={NoCompraTitle}
          bodyCopy={NoCompraBody}
          isLEM={true}
        />
      </LayerManagerWrapper>
    );
  };

  const handleLoadingScreenLayer = () => {
    const layer = layerManager.openFocusLayer(renderLoadingScreen, {}, { size: FocusLayerSizeV2.A });

    return layer;
  };

  const renderLoadingScreen = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingScreen copy="Enviando tu respuesta" />
      </LayerManagerWrapper>
    );
  };

  async function handleConfirmarCita(e: any) {
    trackingManager.trackFormCTAClick({
      contentId: 'Quiero mi bono',
    });

    const isValid = await triggerValidation();

    if (isValid && !lssiError && !tmkError) {
      const loadingLayer = handleLoadingScreenLayer();
      setLayerVisible(true);

      const value = await handleSubmit(e);
      loadingLayer.close();

      if (value) {
        handleShowFinalScreen();
      } else {
        handleKoLayer();
      }
    }
  }

  async function retrySubmit(e: any) {
    if (koLayer) {
      koLayer.close();
    }
    setKoScreen(false);
    const loadingLayer = handleLoadingScreenLayer();
    const value = await handleSubmit(e);
    loadingLayer.close();
    if (value) {
      handleShowFinalScreen();
    } else {
      handleKoLayer();
      setKoScreen(true);
    }
  }

  useEffect(() => {
    if (!layerVisible && koScreen) setKoScreen(false);
  }, [layerVisible]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FORM_ENDED', payload: true });
  }, []);

  return (
    <div>
      <LssiTkmQuestions showTitle={false} />
      <CTA
        tag="button"
        type="submit"
        emphasis="primary"
        onClick={(e) => {
          e.preventDefault();
          handleConfirmarCita(e);
        }}
        stretchContent={window.innerWidth <= 560}
      >
        Quiero mi bono
      </CTA>
    </div>
  );
}
