import React, { useState} from 'react';
import { useDispatch } from 'react-redux';
import { styled, Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useFormContext } from 'react-hook-form';
import { InputButton } from 'src/components/inputs/InputButton';

const CTAWrapper = styled.div`
  text-align: left;
  margin: -10px;
  margin-top: 32px;
  @media all and (max-height: 624px) {
    margin-top: 24px;
  }
  & > button {
    margin: 12px;
  }
`;

interface CTAValues {
  value?: string | boolean;
  label: string;
}

interface NoClienteProps {
  name: string;
  nextOnClick?: boolean;
  bodyCopy: string;
}

export  const NoCliente = (props: NoClienteProps) => {
  const dispatch = useDispatch();
  const { name, nextOnClick, bodyCopy } = props;

  const { register, setValue } = useFormContext();
  const { handleNextStep, closeLayer } = useOneFormContext();
  const [selectedValue, setSelectedValue] = useState<string>(null);

  return (
    <>
      <Text appearance={TokenTextAppearance.copy200}>
        {bodyCopy} 
      </Text>
      <CTAWrapper>  
        <InputButton
          tag="button"
          name={name}
          label={'Claro'}
          innerRef={register({
            required: true,
          })}
          selectedValue={selectedValue}
          size={'small'}
          key={'001-true'}
          onClick={(e) => {
            if ('Claro' !== selectedValue) {
              setSelectedValue('Claro');
              setValue(name, true);

              if (nextOnClick) {
                handleNextStep(e);
              }
            } else {
              setSelectedValue(null);
              setValue(name, null);
              dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: null } });
            }
          }}
        />
        <InputButton
          tag="button"
          name={name}
          label={'Salir del formulario'}
          innerRef={register({
            required: true,
          })}
          selectedValue={selectedValue}
          size={'small'}
          key={'002-false'}
          onClick={(e) => {

            if ('Salir del formulario' !== selectedValue) {
              setSelectedValue('Salir del formulario');
              setValue(name,false);
              dispatch({ type: 'RESET_STATE' });
              closeLayer();

            } else {
              setSelectedValue(null);
              setValue(name, null);
              dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: null } });
            }
          }}
        />
      </CTAWrapper>
    </>
  );
};
