import React from 'react';
import { Text } from '@volkswagen-onehub/components-core';

export const renderBolds = (htmlString: string) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, 'text/html');
	const elements = Array.from(doc.body.childNodes);

	return elements.map((node) => {
		if (node.nodeType == Node.ELEMENT_NODE && (node.nodeName == 'B' || node.nodeName == 'STRONG')) {
			return <Text bold>{node.textContent}</Text>;
		}
		else {
			return node.textContent;
		}
	});
};
