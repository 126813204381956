import { CarlineBase } from 'src/types';
import { SelectExtendedList } from 'src/components/CarlineSelect';

/**
 * Devuelve el carline con el formato necesario para usar el select.
 * @param carline
 * @returns
 */
 export const formatCarlineSelectExtendedList = (carline: CarlineBase): SelectExtendedList => {
    if (!carline) {
      return null;
    }
    return {
      code: String(carline.code),
      name: `${carline.nuevo ? 'Nuevo ' : ''}${carline.title}`,
      key: carline.title,
    };
  };
