import {
  CarlineBase,
  DealersData,
  DealersOrInstallationsMode,
  Installation,
  Offer,
  Points,
  RankedPreconfiguration,
  SalesGroupInfo,
} from 'src/types';

export const SET_DEALERS = 'SET_DEALERS';
export const SET_POINTS = 'SET_POINTS';
export const SET_SELECTED_DEALER = 'SET_SELECTED_DEALER';
export const SET_FAVOURITE_DEALER = 'SET_FAVOURITE_DEALER';
export const SET_SELECTED_INSTALLATION = 'SET_SELECTED_INSTALLATION';
export const SET_INSTALLATIONS = 'SET_INSTALLATIONS';
export const DEALERS_OR_INSTALLATIONS_MODE = 'DEALERS_OR_INSTALLATIONS_MODE';
export const SET_CARLINES = 'SET_CARLINES';
export const SET_SELECTED_CARLINE = 'SET_SELECTED_CARLINE';
export const SET_OFERTA = 'SET_OFERTA';
export const SET_SALESGROUP = 'SET_SALESGROUP';
export const SET_URL_DEALER = 'SET_URL_DEALER';
export const SET_MATCH = 'SET_MATCH';
export const SET_CITA_CARLINES = 'SET_CITA_CARLINES';

interface SetDealersAction {
  type: typeof SET_DEALERS;
  payload: DealersData[];
}
interface SetPointsAction {
  type: typeof SET_POINTS;
  payload: Points[];
}

interface SetSelectedDealerAction {
  type: typeof SET_SELECTED_DEALER;
  payload: DealersData;
}
interface SetUrlDealerAction {
  type: typeof SET_URL_DEALER;
  payload: DealersData;
}
interface SetFavouriteDealerAction {
  type: typeof SET_FAVOURITE_DEALER;
  payload: DealersData;
}
interface SetSelectedInstallationAction {
  type: typeof SET_SELECTED_INSTALLATION;
  payload: Installation;
}
interface SetInstallationsAction {
  type: typeof SET_INSTALLATIONS;
  payload: Installation[];
}
interface SetDealersOrInstallationModeAction {
  type: typeof DEALERS_OR_INSTALLATIONS_MODE;
  payload: DealersOrInstallationsMode;
}
interface SetCarlinesAction {
  type: typeof SET_CARLINES;
  payload: CarlineBase[];
}
interface SetCitaCarlinesAction {
  type: typeof SET_CITA_CARLINES;
  payload: CarlineBase[];
}
interface SetSelectedCarlineAction {
  type: typeof SET_SELECTED_CARLINE;
  payload: CarlineBase;
}
interface SetMatchAction {
  type: typeof SET_MATCH;
  payload: RankedPreconfiguration;
}

interface SetOfertaAction {
  type: typeof SET_OFERTA;
  payload: Offer;
}

interface SetSalesGroupAction {
  type: typeof SET_SALESGROUP;
  payload: SalesGroupInfo;
}

export type DealersActionTypes =
  | SetFavouriteDealerAction
  | SetUrlDealerAction
  | SetCarlinesAction
  | SetDealersAction
  | SetDealersOrInstallationModeAction
  | SetInstallationsAction
  | SetMatchAction
  | SetOfertaAction
  | SetPointsAction
  | SetSalesGroupAction
  | SetSelectedCarlineAction
  | SetCitaCarlinesAction
  | SetSelectedDealerAction
  | SetSelectedInstallationAction;
