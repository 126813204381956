import {Text} from '@volkswagen-onehub/components-core';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOneFormContext} from 'src/feature-app';
import {RenderController} from 'src/feature-app/Screen';
import {TIME_FOR_TRANSFORM} from 'src/globals';
import {DealersData, OneFormState, Steps} from 'src/types';
import {updateMapVariables} from 'src/feature-app/';
import {MapaCitaSteps} from 'src/forms/CitaPosventa/MapaCita/MapaCita';
import {JourneyCitaPosventaDealerWrapper} from 'src/forms/CitaPosventa/MapaCita/JourneyCitaPosventaDealerWrapper';

interface MapaCitaProps {
	showHorario?: boolean;
	formName?: string;
}

export function MapaCitaDealer(props: MapaCitaProps) {
	const dispatch = useDispatch();
	const {formData, dealersInfo} = useSelector((state: OneFormState) => state);
	const {selectedDealer, urlDealer} = dealersInfo;

	const {dealer}: {dealer?: DealersData} = formData.fields;

	const {
		handleScreenChange,
		setNextMultiStep,
		setNextMultiStepScreenIndex,
		setPreviousMultiStep,
		setWaitForPreviousStep,
		setIsFullScreen,
		setShowPreviousStep,
	} = useOneFormContext();

	const steps: Steps[] = [
		{
			screenIndex: 0,
			name: 'MapSelect',
			alternateTitle: (
				<Text>
					<Text bold>Esta es la cita</Text> que has elegido
				</Text>
			),
			title: <></>,
			fields: <JourneyCitaPosventaDealerWrapper />,
			outputs: ['slot.from', 'dealer.name'],
			outputsText: ['slot.from', 'en el taller', 'dealer.name'],
			fullScreen: true,
			multiStepLastScreen: true,
			hidePreviousStep: true,
		},
	];

	const [selectedStep, setSelectedStep] = useState<Steps>(steps[0]);

	const handleFirstLoad = async () => {
		if (urlDealer) {
			initializeMultiStep(urlDealer);
			return;
		} else if (selectedDealer) {
			initializeMultiStep(selectedDealer);
			return;
		} else {
			initializeMultiStep();
		}

		setNextMultiStep(() => navigateMultiStep);
		setPreviousMultiStep(() => navigateMultiStep);
	};

	const initializeMultiStep = async (dealer?: DealersData) => {
		if (dealer) {
			dispatch({type: 'UPDATE_MULTISTEP_SCREENINDEX', payload: 0});
			await updateMapVariables(dispatch, dealer);
			updateScreenChangeVariables(0);
			updateWaitForNextAndPreviousStep(false, false);
			timeoutPreviousStep();
		}
	};

	const updateWaitForNextAndPreviousStep = (waitForNextStep: boolean, waitForPreviousStep: boolean) => {
		if (waitForNextStep !== null) {
			dispatch({type: 'UPDATE_WAIT_FOR_NEXT_STEP', payload: waitForNextStep});
		}
		if (waitForPreviousStep !== null) {
			setWaitForPreviousStep(waitForPreviousStep);
		}
	};

	const navigateMultiStep = () => {
		handleScreenChange();
	};

	/**
	 * En algunas ocasiones no funciona correctamente el setShowPreviousStep(false) por lo que conviene aplicar un timeout.
	 */
	const timeoutPreviousStep = (ms?: number) => {
		setTimeout(
			() => {
				setShowPreviousStep(false);
			},
			ms ? ms : TIME_FOR_TRANSFORM,
		);
	};

	const updateScreenChangeVariables = (newMultiStepScreenIndex: number) => {
		setIsFullScreen(true);
		dispatch({type: 'UPDATE_FULLSCREEN', payload: true});
		setNextMultiStepScreenIndex(newMultiStepScreenIndex);
		dispatch({type: 'UPDATE_MULTISTEP_SCREENINDEX', payload: newMultiStepScreenIndex});
		setSelectedStep(steps[newMultiStepScreenIndex]);
		setShowPreviousStep(false);
	};

	useEffect(() => {
		dispatch({type: 'UPDATE_MULTISTEPS', payload: steps});
		handleFirstLoad();

		return () => {
			dispatch({type: 'UPDATE_FIRST_MAP_LOAD', payload: false});
			setShowPreviousStep(true);
			updateWaitForNextAndPreviousStep(false, false);
		};
	}, []);

	return (
		<RenderController
			screenType="layer-screen"
			title={selectedStep.title}
			fields={selectedStep.fields}
			key={selectedStep.screenIndex}
		/>
	);
}
