import { getReverseGeocode } from 'src/feature-app';
import { Geometry } from 'src/types';

function getCurrentPosition(options = { timeout: 10000 }): Promise<any> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

export const handleLocation = async (): Promise<{ geometry: Geometry; name: string }> => {
  try {
    const { coords } = await getCurrentPosition();
    const { latitude, longitude } = coords;
    const { geometry, name } = await getReverseGeocode({ lat: latitude, lng: longitude });

    return { geometry, name };
    // Handle coordinates
  } catch (error) {
    // Handle error
    console.error(error);
    return { geometry: null, name: '' };
  }
};
