import {
  styled
} from '@volkswagen-onehub/components-core';

interface IputWidthProps {
  notALayer?: boolean;
}

export const InputWidthWrapper = styled.div<IputWidthProps>`
  width: 100%;
  @media all and (min-width: 560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid013)' : '303px')};
    }
    @media all and (min-width: 960px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '320px')};
    }
    @media all and (min-width: 1280px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '320px')};
    }
    @media all and (min-width: 1920px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '320px')};
    }
    @media all and (min-width: 2560px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '320px')};
    }
`;

export const LabelInputWidthWrapper = styled.div<IputWidthProps>`
  label {
    width: 100%;
    @media all and (min-width: 560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid013)' : '303px')};
    }
    @media all and (min-width: 960px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '320px')};
    }
    @media all and (min-width: 1280px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '320px')};
    }
    @media all and (min-width: 1920px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '320px')};
    }
    @media all and (min-width: 2560px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '320px')};
    }
  }
`;
