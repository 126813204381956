import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { CanalDeContacto, CarlineSelect, PantallaNombreApellido, Email, Telefono, CodigoPostal, ContactInfoWrapper, MultipleCTASelection } from 'src/components';
import { OneElementTemplate, OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { MapaLeads, ConfirmacionLeadsNoQuestions } from 'src/forms';
import {
  DetalleMotivo,
  ConsentQuestion
} from 'src/components';
import { FormInfo, Steps } from 'src/types';
import { LeadsFormsProps } from '../LeadGenericoForm';
import { useInitializeLeadForm } from '../use-initialize-lead-form';
import { LeadGenericoController } from '../LeadGenericoController';
import { useIsComerciales } from 'src/feature-app/hooks';
import { leadsCallCenterPartialData } from '.';
import { MapaLeadsInPage, PartialDataMasInformacionConfigurador } from 'src/forms/LeadsInPage';

const getMapaFields = (inPage: boolean) => {
  if (inPage === true) {
    return <MapaLeadsInPage formName="masInformacion" />;
  } else {
    return <MapaLeads formName="masInformacion" />;
  }
};

enum CallCenterSteps {
	CallCenterCampaign,
	FormObjective,
	Modelo,
	Mapa,
	NombreApellido,
	CodigoPostal,
	Contacto,
	EmailTelefono,
	Comentarios,
	Confirmacion,
}


export function CallCenterForm({ updatedFormData, inPage, isConfigurador, isLeadDealer }: LeadsFormsProps) {
	const isComerciales = useIsComerciales();
	const [partialData, setPartialData] = useState(null);
	const ctaFormObjective = [
		{
			label: 'Test Drive',
			value: 'Test Drive'
		},
		{
			label: 'Más Información',
			value: 'Más Información'
		},
		{
			label: 'Presupuesto',
			value: 'Presupuesto'
		},
		{
			label: 'Cita Previa',
			value: 'Cita Previa'
		}
	];
	const ctaCallCenterCampaign = [
		{
			label: 'Servicio habitual',
			value: 'Servicio habitual'
		},
		{
			label: 'ID.4 Renting Online',
			value: 'ID.4 Renting Online'
		},
		{
			label: 'No enviado',
			value: 'No enviado'
		}
	];
	

  useEffect(() => {
    if (updatedFormData) {
		// console.log('updatedFormData', updatedFormData);
		setPartialData(updatedFormData);
    } else {
		// console.log('leadsCallCenterPartialData', leadsCallCenterPartialData);
		setPartialData(leadsCallCenterPartialData);
    }
  }, []);

  const defaultSteps: Steps[] = [
    {
		title: (
			<>
			<Text bold>Origen</Text> del lead?
			</>
		),
		fields: (
			<>
			{inPage ?
				<OneElementTemplateFullScreen
					element={<MultipleCTASelection ctaValues={ctaCallCenterCampaign} name="callCenterCampaign" nextOnClick />}
				/>
				:
				<OneElementTemplate
					element={<MultipleCTASelection ctaValues={ctaCallCenterCampaign} name="callCenterCampaign" nextOnClick />}
				/>
			}
			</>
		),
		screenIndex: CallCenterSteps.CallCenterCampaign,
		name: 'CallCenterCampaign',
		outputs: ['callCenterCampaign'],
		outputsText: ['callCenterCampaign'],
		ctas: ctaCallCenterCampaign,
	},
	{
		title: (
			<>
			Que <Text bold>tipo</Text> de leads es?
			</>
		),
		fields: (
			<>
			{inPage ?
				<OneElementTemplateFullScreen
					element={<MultipleCTASelection ctaValues={ctaFormObjective} name="formObjective" nextOnClick />}
				/>
				:
				<OneElementTemplate
					element={<MultipleCTASelection ctaValues={ctaFormObjective} name="formObjective" nextOnClick />}
				/>
			}
			</>
		),
		screenIndex: CallCenterSteps.FormObjective,
		name: 'FormObjective',
		outputs: ['formObjective'],
		outputsText: ['formObjective'],
		ctas: ctaFormObjective,
	},
	{
      title: (
        <>
          <Text bold>¿Qué modelo </Text> interesa al cliente?
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<div style={{ marginBottom: '2px' }}><CarlineSelect useCarline={true} /></div>} />
            :
            <OneElementTemplate element={<div style={{ marginBottom: '2px' }}><CarlineSelect useCarline={true} /></div>} />
          }
        </>
      ),
      screenIndex: CallCenterSteps.Modelo,
      name: 'Modelo',
      outputs: ['modelo'],
      outputsText: ['modelo'],
    },
    isLeadDealer ? null :
      {
        screenIndex: CallCenterSteps.Mapa,
        name: 'Mapa',
        multiStep: true,
        component: getMapaFields(inPage),
      },
    {
      title: (
        <>
          Para poder preparar su visita, necesitamos saber <Text bold>quién es</Text>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<div style={{ marginBottom: '2px' }}><PantallaNombreApellido isComerciales={isComerciales} /></div>} />
            :
            <OneElementTemplate element={<div style={{ marginBottom: '2px' }}><PantallaNombreApellido isComerciales={isComerciales} /></div>} />
          }
        </>
      ),
      screenIndex: CallCenterSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      title: (
        <>
          También nos gustaría conocer <Text bold>su código postal</Text>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<div style={{ marginBottom: '2px' }}><CodigoPostal /></div>} />
            :
            <OneElementTemplate element={<div style={{ marginBottom: '2px' }}><CodigoPostal /></div>} />
          }
        </>
      ),
      screenIndex: CallCenterSteps.CodigoPostal,
      name: 'CodigoPostal',
      outputs: ['codigoPostal'],
      outputsText: ['codigoPostal'],
    },
    {
      title: (
        <>
          <Text bold>¿Cómo prefiere </Text>que contactemos con él?
          <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<CanalDeContacto />} />
            :
            <OneElementTemplate element={<CanalDeContacto />} />
          }
        </>
      ),
      screenIndex: CallCenterSteps.Contacto,
      name: 'Contacto',
      outputs: ['contacto'],
      outputsText: ['contacto'],
    },
    {
      title: (
        <>
          Para ello necesitaremos <Text bold>sus datos de contacto</Text>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen
              element={
                <ContactInfoWrapper>
                  <Telefono paginaConfirmacion={false} required={true} />
                  <Email paginaConfirmacion={false} required={true} paddingTop='24px' />
                </ContactInfoWrapper>
              }
            />
            :
            <OneElementTemplate
              element={
                <ContactInfoWrapper>
                  <Telefono paginaConfirmacion={false} required={true} />
                  <Email paginaConfirmacion={false} required={true} paddingTop='24px' />
                </ContactInfoWrapper>
              }
            />
          }
        </>
      ),
      screenIndex: CallCenterSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      title: (
        <>
          ¿Quiere saber <Text bold>algo en concreto</Text>?
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<DetalleMotivo name="commentsCC"/>} />
            :
            <OneElementTemplate element={<DetalleMotivo name="commentsCC"/>} />
          }
        </>
      ),
      screenIndex: CallCenterSteps.Comentarios,
      name: 'Comentarios',
      outputs: ['commentsCC'],
      outputsText: ['commentsCC'],
    },
    {
      fields: <ConsentQuestion questionType='lssi' />,
      name: 'lssi',
      outputs: ['lssi'],
    },
    {
      fields: <ConsentQuestion questionType='tmk' />,
      name: 'tmk',
      outputs: ['tmk'],
    },
    {
      fields: <ConfirmacionLeadsNoQuestions />,
      isLastStep: true,
      hidePreviousStep: true,
      name: 'Confirmacion',
    },
  ];
  const filteredSteps = defaultSteps.filter((step) => step !== null);

  const { loading, steps, firstStep, lastStep } = useInitializeLeadForm(filteredSteps, false, inPage);

  const citaVentaInfo: Partial<FormInfo> = {
    numberOfScreens: steps.length,
  };

  return loading ? null : (
    <LeadGenericoController
      steps={steps}
      leadData={isConfigurador ? PartialDataMasInformacionConfigurador : partialData}
      leadInfo={citaVentaInfo}
      firstStep={firstStep}
      lastStep={lastStep}
      inPage={inPage}
      isConfigurador={isConfigurador}
    />
  );
}
