import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React from 'react';
import { useStore } from 'react-redux';
import { LayerManagerWrapper, useFeatureAppEnvironment, useFeatureServices } from 'src/feature-app';
import { useGetIdPerson } from 'src/feature-app/hooks';
import { LssiTkmQuestionsProps, LegalItem } from '.';

const infoLayerCopies = {
  lssi: {
    title: 'Estarás mejor informado.',
    body: 'Si nos das tu consentimiento desde Volkswagen Group España Distribución, S.A. como responsable de la marca Volkswagen podremos enviarte por cualquier medio, ya sea por teléfono, correo electrónico, sms o aplicaciones de mensajería instantánea información comercial lo que puede incluir, a título enunciativo, pero no limitativo, descuentos, novedades, próximos lanzamientos, invitaciones a eventos o a concursos, entre otras comunicaciones comerciales.',
  },
  tmk: {
    title: '¡Adiós mensajes genéricos!',
    body: 'Activando esta opción podremos enviarte información y ofertas personalizadas de productos y servicios adecuadas a tu perfil. Para ello nos basaremos en tu comportamiento, preferencias personales y cómo usas nuestros productos y servicios.',
  }
};

const legalItemTitles = {
  lssi: '¿Te gustaría recibir descuentos y novedades de Volkswagen?',
  tmk: '¿Te gustaría que elaboremos un perfil tuyo para ofrecerte información comercial sobre productos y servicios de nuestras marcas que creamos que te puedan interesar más según tus preferencias?',
};

export const LssiTkmQuestionsTurismos = ({ showTitle, themeColor }: LssiTkmQuestionsProps) => {
  const store = useStore();
  const idPerson = useGetIdPerson();
  const lssiPermite: boolean = idPerson?.LSSI__c === 'PERMITE';

  const { formName, quieroContacto } = store.getState().formData.fields;
  const isLead: boolean = quieroContacto === 'true' || formName.includes('lead-');
  const env = useFeatureAppEnvironment();
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;

  const getLegalItemTitle = (key: string) => legalItemTitles[key];

  const handleInfoLayer = (
    key: 'lssi' | 'tmk' ,
    renderFn: (state: any, close: CloseHandleV2<any, any>) => JSX.Element
  ) => {
    const layer = layerManager.openFocusLayer(
      renderFn,
      { ...infoLayerCopies[key] },
      { size: FocusLayerSizeV2.A, userCloseable: true }
    );

    return layer;
  };

  const renderInfo = (state: { title: string; body: string }, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <Container wrap={'always'} padding={ContainerPadding.static450}>
          <Container
            gutter={ContainerGutter.static200}
            padding={{ bottom: ContainerPadding.static300 }}
            wrap={'always'}
          >
            <Text bold>{state.title}</Text>
            <Text>{state.body}</Text>
          </Container>
          <CTA
            tag='button'
            emphasis='primary'
            onClick={(e) => {
              e.preventDefault();
              close({}, {}, {});
            }}
            size={'small'}
          >
            Entendido
          </CTA>
        </Container>
      </LayerManagerWrapper>
    );
  };

  return (
    <div style={{ paddingTop: '32px' }}>
      {!lssiPermite ?
        <>
          {showTitle && (
            <>
              <BreakpointWrapper max={Breakpoints.b1600}>
                <div style={{ paddingBottom: '32px' }}>
                  <Text appearance={TokenTextAppearance.headline300} bold>
                    Mantente al día
                  </Text>
                </div>
              </BreakpointWrapper>
              <BreakpointWrapper min={Breakpoints.b1600}>
                <div style={{ paddingBottom: '32px' }}>
                  <Text appearance={TokenTextAppearance.headline300} bold>
                    Mantente al día
                  </Text>
                </div>
              </BreakpointWrapper>
            </>
          )}
          <LegalItem
            title={getLegalItemTitle('lssi')}
            onClick={() => handleInfoLayer('lssi', renderInfo)}
            themeColor={themeColor}
            name='lssi'
			checkboxMode={true}
          />
          <LegalItem
            title={getLegalItemTitle('tmk')}
            onClick={() => handleInfoLayer('tmk', renderInfo)}
            themeColor={themeColor}
            name='tmk'
			checkboxMode={true}
          />
        </>
        :
        <>
          {showTitle && !isLead && (
            <div style={{ paddingBottom: '32px' }}>
              <Text appearance={TokenTextAppearance.headline300}>
                <Text bold>¿Listo</Text> para enviar?
              </Text>
            </div>
          )}
          {showTitle && isLead && (
            <div style={{ paddingBottom: '32px' }}>
              <BreakpointWrapper max={Breakpoints.b1600}>
                <Text appearance={TokenTextAppearance.headline300}>
                  Y una <Text bold>última pregunta</Text>
                </Text>
              </BreakpointWrapper>
              <BreakpointWrapper min={Breakpoints.b1600}>
                <Text appearance={TokenTextAppearance.headline300}>
                  Y una <Text bold>última pregunta</Text>
                </Text>
              </BreakpointWrapper>
            </div>
          )}
        </>
      }
    </div>
  );
};
