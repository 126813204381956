import {
	Breakpoints,
	BreakpointWrapper,
	Container,
	ContainerPadding,
	CTA,
	Layout,
	LayoutRowGap,
	Text,
	TextAlignment,
	ThemeProvider,
	TokenTextAppearance,
	TokenTextColor,
} from '@volkswagen-onehub/components-core';
import dayjs from 'dayjs';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {AnteriorCTA} from 'src/components';
import {
	getActualMonth,
	getActualMonthNumber,
	getActualYearNumber,
	getNextActualMonth,
	getPreviousActualMonth,
	getYYYYMMDD,
	useFeatureAppConfig,
	year_MonthNumber_DayNumber,
	useTrackingManager,
} from 'src/feature-app';
import {ArrowLeft, ChevronLeft, ChevronRight} from 'src/icons-core-imports';
import {Availability, Calendar, CalendarSlot, Day, DealersData, Horario, OneFormState, Points} from 'src/types';
import {checkIfEvening, checkIfMidDay, checkIfMorning, getDistanceFromLatLngCenter, getNormalizedDate} from '../NewMap';
import {CloseInDealerCalendar, FranjaHoraria, TabsHorario} from './';
import {
	AllContentWrapper,
	AllDaysWrapper,
	Background,
	CalendarHandlerWrapper,
	Capitalize,
	DayPositionWrapper,
	DayWrapper,
	DealerInfoWrapper,
	DividerC,
	GoBackWrapper,
	GreyText,
	TabsWitdh,
} from './CalandarioStyles';
import {getFirstAndLastDayOfMonth} from 'src/helpers';

export interface SlotsHorarios {
	manana: CalendarSlot[];
	mediodia: CalendarSlot[];
	tarde: CalendarSlot[];
}

interface CalendarioCitaProps {
	selectedPoint: Points;
	paramAvailability: Availability;
	getAvailability: (dealer: DealersData, start: number, end: number) => Promise<Availability>;
	selectedSlot: any;
	setSelectedSlot: React.Dispatch<any>;
	handleDmsMapExit: (slot: CalendarSlot, point: Points) => void;
	handleNoDmsMapExit: (franjaHoraria: string, day: string, point: Points) => void;
	center: google.maps.LatLng;
	horario: Horario;
	handleHideCalendarOnClick: () => void;
	userIsGeoLocated?: boolean;
	/**
	 * Flag para cuando el calendario es un step y no forma parte del mapa, cambian los estilos y es fullscreen;
	 */
	calendarIsStep?: boolean;
}

const checkDmsAvailableInAvailability = (availability: Availability) => availability?.content?.dealer?.dmsAvailable;
const delerHasError = (availability: Availability) => availability?.content?.dealer.hasOwnProperty('error');

export function CalendarioCita(props: CalendarioCitaProps) {
	const {
		selectedPoint,
		paramAvailability,
		getAvailability,
		selectedSlot,
		setSelectedSlot,
		handleDmsMapExit,
		handleNoDmsMapExit,
		center,
		horario,
		handleHideCalendarOnClick,
		userIsGeoLocated,
		calendarIsStep,
	} = props;

	const {formName} = useSelector((state: OneFormState) => state.formData.fields);

	let name, address, city, zipCode, dealer, distanceFromCenter;
	if (selectedPoint.properties?.dealer?.markerInfo?.dealerInfo) {
		name = selectedPoint.properties.dealer.markerInfo.dealerInfo.name;
		address = selectedPoint.properties.dealer.markerInfo.dealerInfo.address;
		city = selectedPoint.properties.dealer.markerInfo.dealerInfo.city;
		zipCode = selectedPoint.properties.dealer.markerInfo.dealerInfo.zipCode;
		dealer = selectedPoint.properties.dealer;
		distanceFromCenter = selectedPoint.properties.distanceFromCenter;
	} else {
		name = '';
		address = '';
		city = '';
		zipCode = '';
		dealer = {};
		distanceFromCenter = 0;
	}
	// const { dealer } = selectedPoint.properties;
	// const { dmsInfo } = dealer;
	// const { distanceFromCenter } = selectedPoint.properties;

	const [preselectedDealerForm, setPreselectedDealerForm] = useState(false);
	const [isCalculadoraFirstStep, setIsCalculadoraFirstStep] = useState(false);
	const config = useFeatureAppConfig();

	useEffect(() => {
		if (formName === 'cita-posventa-calculadora') {
			setIsCalculadoraFirstStep(true);
		}
		if (config.trigger === 'cita-posventa-dealer' || formName === 'cita-posventa-calculadora') {
			setPreselectedDealerForm(true);
		} else {
			setPreselectedDealerForm(false);
		}
	}, []);

	const daysOfTheWeek = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'];
	const [loading, setLoading] = useState<boolean>(false);
	const [month, setMonth] = useState<string>(getActualMonth());
	const [nextMonth, setNextMonth] = useState(getNextActualMonth());
	const [previousMonth, setPreviousMonth] = useState(getPreviousActualMonth());
	const [monthNumber, setMonthNumber] = useState(getActualMonthNumber());
	const [monthOffset, setMonthOffset] = useState(0);
	const [monthDays, setMonthDays] = useState<Day[]>(null);
	const [yearNumber, setYearNumber] = useState(getActualYearNumber());
	const [selectedDay, setSelectedDay] = useState(null);
	const [selectedFranjaHoraria, setSelectedFranjaHoraria] = useState(null);
	const [pastMonthBlocked, setPastMonthBlocked] = useState(false); // En el caso de que el mes actual no tenga fechas disponibles no se debería poder volver atrás.
	const [calendar, setCalendar] = useState<Calendar>(null);
	const [horarios, setHorarios] = useState<SlotsHorarios>(null);
	const tabsRef = useRef<HTMLDivElement>(null);
	const availabilityRef = useRef<Availability>(null);
	const [dmsAvailable, setDmsAvailable] = useState(null);
	const trackingManager = useTrackingManager();

	useEffect(() => {
		trackingManager.trackFormStepLoad(
			{},
			{
				FormStart: false,
			},
			{StepName: 'MapSlot'},
		);
		handleChangeOfMonth(0); // Primera carga del calendario.
	}, []);

	const handleChangeOfMonth = async (newMonthOffset: number): Promise<void> => {
		const {currentMonthNumber, currentMonth, yearNumber, nextMonth} = getDates(newMonthOffset);
		const {firstDayOfMonth, lastDayOfMonth} = getFirstAndLastDayOfMonth(currentMonthNumber, yearNumber);

		const availability =
			newMonthOffset == 0 ? paramAvailability : await getAvailability(dealer, firstDayOfMonth, lastDayOfMonth);

		if (
			checkDmsAvailableInAvailability(availability) &&
			availability.content?.calendar &&
			calendarHasNoProblems(availability) &&
			!delerHasError(availability)
		) {
			setDmsAvailable(true);
		} else if (
			checkDmsAvailableInAvailability(availability) &&
			calendarHasNoProblems(availability) &&
			!availability.content.calendar
		) {
			if (newMonthOffset === 2) {
				setDmsAvailable(false);
			} else {
				setPastMonthBlocked(true);
				handleChangeOfMonth(monthOffset + 1);
			}
			return;
		} else {
			setDmsAvailable(false);
		}
		availabilityRef.current = availability;

		const {updatedMonthDays, selectedDay} = buildCalendar(currentMonthNumber, yearNumber, availability);

		if (selectedDay) {
			setMonthDays(updatedMonthDays);
			setSelectedDay(selectedDay);
			setVariables(newMonthOffset, currentMonth, currentMonthNumber, nextMonth, yearNumber);
		} else {
			setPastMonthBlocked(true);
			handleChangeOfMonth(monthOffset + 1);
		}
	};

	const buildCalendar = (monthNumber: string, yearNumber: string, availability: Availability) => {
		// 1. Crear los días del mes.
		const days = createMonthDays(monthNumber, yearNumber);
		// 2. Con el availability, desactivar todos los días que no son necesarios.
		const updatedDays = getDisabledDays(days, availability);
		// 3. Buscar el primer día activo.
		const firstAvailableDay = getFirstAvailableDay(updatedDays, availability);
		// 3.1 En caso de que no encuentre un día en el mes en curso, devolvemos null para que handleChangeOfMonth se
		// ocupe de cargar el siguiente mes.
		if (!firstAvailableDay) {
			return {updatedMonthDays: null, selectedDay: null};
		}
		// 4. Cambiar el estado del primer dia activo
		const {updatedMonthDays, selectedDay} = handleChangeActiveDay(updatedDays, firstAvailableDay);
		// 5. Carga de los horarios del dia seleccionado
		handleChangeInSelectedDay(firstAvailableDay);

		return {updatedMonthDays, selectedDay};
	};

	/**
	 * Genera los dias del calendario para el mes y año seleccionado.
	 * @param monthNumber
	 * @param yearNumber
	 */
	const createMonthDays = (monthNumber: string, yearNumber: string): Day[] => {
		const firstDayOfMonth = dayjs()
			.year(Number(yearNumber))
			.month(Number(monthNumber) - 1)
			.date(1);

		const daysInMonth = dayjs()
			.year(Number(yearNumber))
			.month(Number(monthNumber) - 1)
			.daysInMonth();

		const firstDayInweek = firstDayOfMonth.format('d'); // The day of the week, with Sunday as 0

		let days: Day[] = [];

		// Se genera una array con espacios vacios para rellenar la primera fila del calendario. Ej: Si el primer día
		// del mes cae en domingo, se genera una array con 6 espacios vacios, donde el 7º día será el domingo.
		if (firstDayInweek === '0') {
			days = new Array(6).fill('');
		} else {
			days = new Array(parseInt(firstDayInweek) - 1).fill('');
		}

		let day = firstDayOfMonth;

		for (let i = 1; i <= daysInMonth; i++) {
			let date = getYYYYMMDD(`${yearNumber}-${monthNumber}-${day.format('D')}`); // YYYY - MM - DD
			const dayNumber = day.format('D'); // 1 - 31
			const dayOfTheWeek = day.format('d'); // 0 - 6
			let newDay: Day = {
				dayNumber,
				dayOfTheWeek,
				date,
				disabled: null,
				active: false,
			};
			days.push(newDay);
			day = day.add(1, 'day');
			newDay = null;
			date = null;
		}
		return days;
	};

	/**
	 * Devuelve los días generados con la propiedad disabled actualizada, según
	 * si el día está disponible o no. Si el dealer tiene dms el día estará disponible
	 * en caso de que también lo esté en el availability. Para el resto de casos se siguen las
	 * normas de la función checkIfDayIsDisabled.
	 *
	 * @param days
	 * @param availability
	 */
	const getDisabledDays = (days: Day[], availability: Availability): Day[] => {
		let updatedDays: Day[];

		// FLUJO CON DMS
		if (availability && availability.content && availability.content.calendar && calendarHasNoProblems(availability)) {
			const availableDates = availability.content.calendar.map((calendarDay) => calendarDay.date);
			updatedDays = days.map((day) => {
				// Days contiene strings vacías.
				if (typeof day === 'object') {
					const foundDate = availableDates.find((date) => date === day.date);
					day.disabled = !foundDate || checkIfDayIsDisabled(day.date) ? true : false;
					return day;
				}
				return day;
			});
		} else {
			// FLUJO SIN DMS
			updatedDays = days.map((day) => {
				// Days contiene strings vacías.
				if (typeof day === 'object') {
					day.disabled = checkIfDayIsDisabled(day.date) ? true : false;
				}
				return day;
			});
		}
		return updatedDays;
	};

	/**
	 *
	 * Las reglas para desactivar un día son:
	 * Si el día ya ha pasado o es el mismo día (no pueden ofrecer cita para el mismo día).
	 * Si es domingo en todos los casos.
	 * Si es sábado y no tiene DMS.
	 *
	 * @param date Se necesita la fecha en formato YYYY-MM-DD
	 */
	const checkIfDayIsDisabled = (date: string): boolean => {
		const actualDay = year_MonthNumber_DayNumber();
		const dayOfTheWeek = dayjs(date).format('d');
		const isPastOrSameDate = dayjs(date).isSameOrBefore(actualDay);
		const disableSaturday = dayOfTheWeek === '6' && !dmsAvailable;
		const disableSunday = dayOfTheWeek === '0';

		if (isPastOrSameDate || disableSaturday || disableSunday) {
			return true;
		}
		return false;
	};

	/**
	 * Función para comprobar si, aunque el calendario retorne una array con slots, estos son recientes.
	 * En el caso de "Binefar" devuelve una array a fecha de 1/1/1970, por lo que comprobamos si las fechas son
	 * anteriores a una fecha ya pasada aleatoria como el 1/1/2021 para eliminar estos casos.
	 * @param availability
	 */
	const calendarHasNoProblems = (availability: Availability): boolean => {
		const msForReference = dayjs('2021-01-01', 'YYYY-MM-DD').locale('es').utc().format('x');

		if (availability && availability.content && availability.content.calendar) {
			const badFormattedDay = availability.content.calendar.find((calendarDay) => {
				const dateInMs = dayjs(calendarDay.date, 'YYYY-MM-DD').locale('es').utc().format('x');
				if (msForReference > dateInMs) {
					return true;
				}
			});
			if (badFormattedDay) {
				return false;
			}
		}
		return true;
	};

	/**
	 * Función para obtener el primer dia activo en el calendario.
	 * En caso de que la concesión disponga de DMS se busca el primer día que tenga slots disponible en cualquiera de los turnos.
	 * En caso de que no encuentre ninguno o no tenga DMS, fija el primer dia que no esté desactivado.
	 *
	 * @param monthDays
	 * @param availability
	 */
	const getFirstAvailableDay = (monthDays: Day[], availability: Availability): Day => {
		let firstDay: Day;

		//Flujo con DMS
		if (availability && availability.content && availability.content.calendar && calendarHasNoProblems(availability)) {
			for (const dayCalendar of availability.content.calendar) {
				const horarios = sortSlotsByHour(dayCalendar);

				if (horario === 'morning' && horarios.manana.length > 0) {
					firstDay = setFirstDay(monthDays, dayCalendar);
					break;
				} else if (horario === 'afternon' && horarios.mediodia.length > 0) {
					firstDay = setFirstDay(monthDays, dayCalendar);
					break;
				} else if (horario === 'evening' && horarios.tarde.length > 0) {
					firstDay = setFirstDay(monthDays, dayCalendar);
					break;
				}
			}
		}
		// Flujo sin DMS
		if (!firstDay) {
			firstDay = monthDays.find((day) => day.disabled === false);
		}

		return firstDay;
	};

	/**
	 * Reparte los slots del calendario en mañana, mediodía y tarde, además de eliminar arrays vacías para evitar posibles errores.
	 *
	 * @param calendar
	 */
	const sortSlotsByHour = (calendar: Calendar): SlotsHorarios => {
		// Se elige un dia aleatorio (1/1/1999) ya que se comparan horas del mismo dia y este es irrelevante.

		const manana = calendar.slots
			.filter((slot: CalendarSlot) => checkIfMorning(getNormalizedDate(slot.hour)))
			.sort((a, b) => a.from - b.from);
		const mediodia = calendar.slots
			.filter((slot: CalendarSlot) => checkIfMidDay(getNormalizedDate(slot.hour)))
			.sort((a, b) => a.from - b.from);
		const tarde = calendar.slots
			.filter((slot: CalendarSlot) => checkIfEvening(getNormalizedDate(slot.hour)))
			.sort((a, b) => a.from - b.from);
		return {manana, mediodia, tarde};
	};

	const setFirstDay = (monthDays: Day[], dayCalendar: Calendar) => {
		const monthDay = monthDays.find((day) => day.date === dayCalendar.date);

		if (monthDay && !monthDay.disabled) {
			return monthDay;
		}
		return null;
	};

	/**
	 * Función que se encarga de cargar los horarios del availability según el dia seleccionado.
	 * @param day
	 * @param isClick es false en la carga del calendario, ya que no hace falta volver a llamar a la función handleChangeActiveDay.
	 */
	const handleChangeInSelectedDay = (day: Day, isClick?: boolean) => {
		if (isClick) {
			const {updatedMonthDays} = handleChangeActiveDay(monthDays, day);
			setMonthDays(updatedMonthDays);
			setSelectedDay(day.date);
		}

		if (!availabilityRef.current || !availabilityRef.current.content || !availabilityRef.current.content.calendar) {
			return;
		}
		let calendar: Calendar = availabilityRef.current.content.calendar.find((calendarDate) => {
			return day.date === calendarDate.date;
		});

		if (calendar) {
			calendar.slots = calendar.slots.sort((a, b) => a.from - b.from);
			const horarios = sortSlotsByHour(calendar);
			const selectedSlot = getSelectedSlot(calendar, horarios);
			setCalendar(calendar);
			setHorarios(horarios);
			setSelectedSlot(selectedSlot);
			if (calendar.slots.length > 1) {
				// selectedCalendar.slots[0].map();
			}
		} else {
			setCalendar(null);
			setHorarios(null);
			setSelectedSlot(null);
		}
	};

	const getSelectedSlot = (selectedCalendar: Calendar, horarios: SlotsHorarios) => {
		if (horario === 'morning') {
			return horarios.manana[0];
		} else if (horario === 'afternon' && horarios.mediodia.length > 0) {
			return horarios.mediodia[0];
		} else if (horario === 'evening' && horarios.tarde.length > 0) {
			return horarios.tarde[0];
		}
		return selectedCalendar.slots[0];
	};

	const handleNextMonthYear = (e: any) => {
		e.preventDefault();
		if (monthOffset === 2) {
			return;
		}
		handleChangeOfMonth(monthOffset + 1);
		// setMonthOffset(monthOffset + 1);
	};

	const handlePreviousMonthYear = (e: any) => {
		e.preventDefault();

		if (monthOffset === 0) {
			return;
		}
		handleChangeOfMonth(monthOffset - 1);
		// setMonthOffset(monthOffset - 1);
	};

	const getDates = (monthOffset: number) => {
		const currentMonth = dayjs().add(monthOffset, 'month').locale('es').format('MMMM');
		const nextMonth = dayjs()
			.add(monthOffset + 1, 'month')
			.locale('es')
			.format('MMMM');
		const currentMonthNumber = dayjs().add(monthOffset, 'month').format('M');
		const yearNumber = dayjs().add(monthOffset, 'month').format('YYYY');

		return {currentMonth, nextMonth, currentMonthNumber, yearNumber};
	};

	useEffect(() => {
		let monthCapitalize = month.charAt(0).toUpperCase() + month.slice(1);
		switch (monthCapitalize) {
			case 'Enero':
				setPreviousMonth('Diciembre');
				break;
			case 'Febrero':
				setPreviousMonth('Enero');
				break;
			case 'Marzo':
				setPreviousMonth('Febrero');
				break;
			case 'Abril':
				setPreviousMonth('Marzo');
				break;
			case 'Mayo':
				setPreviousMonth('Abril');
				break;
			case 'Junio':
				setPreviousMonth('Mayo');
				break;
			case 'Julio':
				setPreviousMonth('Junio');
				break;
			case 'Agosto':
				setPreviousMonth('Julio');
				break;
			case 'Septiembre':
				setPreviousMonth('Agosto');
				break;
			case 'Octubre':
				setPreviousMonth('Septiembre');
				break;
			case 'Noviembre':
				setPreviousMonth('Octubre');
				break;
			case 'Diciembre':
				setPreviousMonth('Noviembre');
				break;
		}
	}, [month]);

	const setVariables = (
		monthOffset: number,
		currentMonth: string,
		currentMonthNumber: string,
		nextMonth: string,
		year: string,
	) => {
		setMonthOffset(monthOffset);
		setMonth(currentMonth);
		setMonthNumber(currentMonthNumber);
		setNextMonth(nextMonth);
		setYearNumber(year);
	};

	const handleSelectedSlot = (slot: CalendarSlot[]) => {
		setSelectedSlot(slot);
	};

	const handleFranjaHoraria = (franjaHoraria: string) => {
		setSelectedFranjaHoraria(franjaHoraria);
	};

	const handleChangeActiveDay = (monthDays: Day[], day: Day): {updatedMonthDays: Day[]; selectedDay: string} => {
		if (day) {
			const updatedMonthDays = monthDays.map((monthDay) => {
				if (monthDay.dayNumber === day.dayNumber && !day.active) {
					monthDay.active = true;
				} else if (monthDay.active && monthDay.dayNumber !== day.dayNumber) {
					monthDay.active = false;
				}
				return monthDay;
			});

			return {updatedMonthDays, selectedDay: day.date};
		} else {
			const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');

			return {updatedMonthDays: monthDays, selectedDay: tomorrow};
		}
	};

	return selectedPoint ? (
		<>
			{!isCalculadoraFirstStep && preselectedDealerForm ? <CloseInDealerCalendar /> : null}
			<Layout
				appearance={{
					[Breakpoints.default]: [{name: 'a', columns: 24}],
					[Breakpoints.b560]: [
						{name: '.', columns: 2},
						{name: 'a', columns: 22},
					],
				}}
			>
				{isCalculadoraFirstStep ? (
					<span
						style={{
							padding: '24px 24px 20px',
							display: 'flex',
							backgroundColor: '#FFFFFF',
						}}
					/>
				) : preselectedDealerForm ? (
					<BreakpointWrapper min={Breakpoints.b560}>
						<ThemeProvider theme="main">
							<div
								style={{
									padding: '24px 24px 20px',
									display: 'flex',
									justifyContent: 'flex-end',
									backgroundColor: '#FFFFFF',
								}}
							>
								<AnteriorCTA />
							</div>
						</ThemeProvider>
					</BreakpointWrapper>
				) : (
					<ThemeProvider theme="main">
						<GoBackWrapper className="goback-wrapper">
							<CTA
								tag="button"
								emphasis="tertiary"
								ariaLabel="aria label"
								icon={<ArrowLeft variant="small" />}
								onClick={(e) => {
									e.preventDefault();
									handleHideCalendarOnClick();
									// Hack para contabilizar en analítica los usuarios que vuelven desde el calendario al mapa.
									trackingManager.trackFormStepLoad(
										{},
										{
											FormStart: false,
										},
										{StepName: 'MapSearch'},
									);
								}}
							>
								<b>Ir al mapa</b>
							</CTA>
						</GoBackWrapper>
					</ThemeProvider>
				)}

				<ThemeProvider theme="main">
					<Background calendarIsStep={calendarIsStep} className="background-white" id="background-white">
						<AllContentWrapper className="all-content-wrapper">
							<div>
								<Layout
									appearance={{
										[Breakpoints.default]: [
											{name: '.', columns: 2},
											{name: 'a', columns: 20},
											{name: '.', columns: 2},
										],
										[Breakpoints.b560]: [
											{name: '.', columns: 3},
											{name: 'a', columns: 18},
											{name: '.', columns: 3},
										],
									}}
								>
									{preselectedDealerForm ? (
										<Container wrap={'always'}>
											<BreakpointWrapper max={Breakpoints.b1600}>
												<Text appearance={TokenTextAppearance.headline300}>
													<Text bold>¿Cuándo </Text>prefieres pasar por el{' '}
													<span style={{whiteSpace: 'nowrap'}}>
														taller?<sup style={{top: 'auto', fontSize: '100%'}}>*</sup>
													</span>
												</Text>
											</BreakpointWrapper>
											<BreakpointWrapper min={Breakpoints.b1600}>
												<Text appearance={TokenTextAppearance.headline300}>
													<Text bold>¿Cuándo </Text>prefieres pasar por el{' '}
													<span style={{whiteSpace: 'nowrap'}}>
														taller?<sup style={{top: 'auto', fontSize: '100%'}}>*</sup>
													</span>
												</Text>
											</BreakpointWrapper>
											{dmsAvailable ? (
												<Container padding={{top: ContainerPadding.dynamic0050}}>
													<Text appearance={TokenTextAppearance.copy200}>
														Selecciona la fecha y la hora de tu visita.
													</Text>
												</Container>
											) : null}
										</Container>
									) : (
										<DealerInfoWrapper className="dealer-info-wrapper">
											<Container
												padding={{bottom: ContainerPadding.static500, top: ContainerPadding.dynamic0250}}
												wrap={'always'}
											>
												<Container horizontalAlign={'space-between'} padding={{bottom: ContainerPadding.static200}}>
													<Text appearance={TokenTextAppearance.headline200} bold textAlign={TextAlignment.start}>
														{name}
													</Text>
													{userIsGeoLocated ? (
														<>
															{getDistanceFromLatLngCenter(center, selectedPoint) === '0.0' ? null : (
																<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.right}>
																	{distanceFromCenter
																		? distanceFromCenter
																		: getDistanceFromLatLngCenter(center, selectedPoint)}
																	km
																</Text>
															)}
														</>
													) : null}
												</Container>
												<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.start}>
													{address}, {zipCode} {city}
												</Text>
											</Container>
											<DividerC />
										</DealerInfoWrapper>
									)}
								</Layout>

								<CalendarHandlerWrapper className="calendar-handler-wrapper">
									<CTA
										tag="button"
										emphasis="tertiary"
										onClick={handlePreviousMonthYear}
										ariaLabel="aria label"
										disabled={monthOffset === 0 || (monthOffset === 1 && pastMonthBlocked) ? true : false}
										icon={<ChevronLeft />}
									/>
									<BreakpointWrapper min={Breakpoints.b960}>
										<Capitalize
											onClick={handlePreviousMonthYear}
											isDisabled={monthOffset === 0 || (monthOffset === 1 && pastMonthBlocked) ? true : false}
										>
											{previousMonth}
										</Capitalize>
									</BreakpointWrapper>
									<Text bold appearance={TokenTextAppearance.copy200}>
										<Capitalize isDisabled={false} hasTitle>
											{month} {yearNumber}
										</Capitalize>
									</Text>
									<BreakpointWrapper min={Breakpoints.b960}>
										<Capitalize onClick={handleNextMonthYear} isDisabled={monthOffset === 2 ? true : false}>
											{nextMonth}
										</Capitalize>
									</BreakpointWrapper>
									<CTA
										tag="button"
										emphasis="tertiary"
										onClick={handleNextMonthYear}
										disabled={monthOffset === 2 ? true : false}
										ariaLabel="aria label"
										icon={<ChevronRight />}
									/>
								</CalendarHandlerWrapper>

								<AllDaysWrapper className="all-days-wrapper">
									<Layout
										appearance={{
											[Breakpoints.default]: [
												{name: '.', columns: 1},
												{name: 'a', columns: 3},
												{name: 'b', columns: 3},
												{name: 'c', columns: 3},
												{name: 'd', columns: 3},
												{name: 'e', columns: 3},
												{name: 'f', columns: 3},
												{name: 'g', columns: 3},
												{name: '.', columns: 2},
											],
											[Breakpoints.b560]: [
												{name: '.', columns: 2},
												{name: 'a', columns: 2},
												{name: '.', columns: 1},
												{name: 'b', columns: 2},
												{name: '.', columns: 1},
												{name: 'c', columns: 2},
												{name: '.', columns: 1},
												{name: 'd', columns: 2},
												{name: '.', columns: 1},
												{name: 'e', columns: 2},
												{name: '.', columns: 1},
												{name: 'f', columns: 2},
												{name: '.', columns: 1},
												{name: 'g', columns: 2},
												{name: '.', columns: 1},
											],
											[Breakpoints.b960]: [
												{name: '.', columns: 1},
												{name: 'a', columns: 3},
												{name: 'b', columns: 3},
												{name: 'c', columns: 3},
												{name: 'd', columns: 3},
												{name: 'e', columns: 3},
												{name: 'f', columns: 3},
												{name: 'g', columns: 3},
												{name: '.', columns: 2},
											],
										}}
										rowGap={LayoutRowGap.static250}
									>
										{daysOfTheWeek.map((day, index) => {
											if (index > 4) {
												return (
													<DayPositionWrapper>
														<DayWrapper key={index} className="day-wrapper">
															<Text bold appearance={TokenTextAppearance.copy200}>
																{day}
															</Text>
														</DayWrapper>
													</DayPositionWrapper>
												);
											} else {
												return (
													<DayPositionWrapper>
														<DayWrapper key={index} className="day-wrapper">
															<Text appearance={TokenTextAppearance.copy200}>
																<GreyText>{day}</GreyText>
															</Text>
														</DayWrapper>
													</DayPositionWrapper>
												);
											}
										})}
										{monthDays && !loading ? (
											monthDays.map((day, i) => {
												return (
													<DayPositionWrapper>
														<DayWrapper
															key={i}
															onClick={(e) => {
																e.preventDefault();

																if (!day.disabled) {
																	if (tabsRef && tabsRef.current) {
																		tabsRef.current.scrollIntoView({behavior: 'smooth'});
																	}
																	// const { updatedMonthDays, selectedDay } = handleChangeActiveDay(monthDays, day);
																	// setMonthDays(updatedMonthDays);
																	// setSelectedDay(selectedDay);
																	handleChangeInSelectedDay(day, true);
																}
															}}
															active={day.active}
															disabled={day.disabled}
															className="day-wrapper"
														>
															<Text appearance={TokenTextAppearance.copy200} color={TokenTextColor.inherit}>
																{day.dayNumber}
															</Text>
														</DayWrapper>
													</DayPositionWrapper>
												);
											})
										) : (
											<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.center}>
												loading...
											</Text>
										)}
									</Layout>
								</AllDaysWrapper>
								<Layout
									appearance={{
										[Breakpoints.default]: [
											{name: '.', columns: 2},
											{name: 'a', columns: 20},
											{name: '.', columns: 2},
										],
										[Breakpoints.b560]: [
											{name: '.', columns: 3},
											{name: 'a', columns: 18},
											{name: '.', columns: 3},
										],
									}}
								>
									<TabsWitdh ref={tabsRef} className="tabs-width">
										<DividerC />
										{dmsAvailable ? (
											<TabsHorario
												handleSelectedSlot={handleSelectedSlot}
												horarios={horarios}
												selectedSlot={selectedSlot}
											/>
										) : (
											<FranjaHoraria
												selectedFranjaHoraria={selectedFranjaHoraria}
												handleFranjaHoraria={handleFranjaHoraria}
												horario={horario}
											/>
										)}
									</TabsWitdh>
								</Layout>
								<Container
									padding={{top: ContainerPadding.static500, bottom: ContainerPadding.static550}}
									horizontalAlign={'center'}
								>
									<CTA
										tag="button"
										emphasis="primary"
										onClick={(e) => {
											e.preventDefault();
											dmsAvailable
												? handleDmsMapExit(selectedSlot, selectedPoint)
												: handleNoDmsMapExit(selectedFranjaHoraria, selectedDay, selectedPoint);
										}}
										ariaLabel="aria label"
									>
										Seleccionar
									</CTA>
								</Container>
							</div>
						</AllContentWrapper>
					</Background>
				</ThemeProvider>
			</Layout>
		</>
	) : null;
}
