import { styled, BreakpointWrapper, Breakpoints, ThemeProvider } from '@volkswagen-onehub/components-core';
import { CloseButton, AnteriorCTA } from 'src/components';
import React from 'react';


const CloseDiv = styled.div`
  padding: 24px 20px 20px;
  color: #001E50;
  background-color: #FFFFFF;
  margin-bottom: -2px;
  display: flex;
  justify-content: space-between;
  @media all and (min-width: 560px) {
    padding: 24px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size-grid002);
    position: fixed;
    top: 0;
    color: #FFFFFF;
    background-color: transparent;
    margin-bottom: 0;
  }
  @media all and (min-width: 960px) {
    width: 80px;
  }
`;


export const CloseInDealerCalendar = () => {

  return (
    <CloseDiv>
      <CloseButton/>
      <BreakpointWrapper max={Breakpoints.b560}>
        <ThemeProvider theme='main'>
          <AnteriorCTA/>
        </ThemeProvider>
      </BreakpointWrapper>
    </CloseDiv>
  );
};
