import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectExtended } from 'src/components';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useSelector, useDispatch } from 'react-redux';
import { OneFormState } from 'src/types';
import { styled } from '@volkswagen-onehub/components-core';

const InputsWrapper = styled.div`
  padding-top: 44px;
  width: 100%;
  @media all and (min-width: 560px) {
    width: 303px;
  }
  @media all and (min-width: 960px) {
    width: 320px;
  }
`;

const motivoOptions = [
  { code: null, name: null },
  {
    code: 'VE',
    name: 'Venta',
  },
  {
    code: 'PV',
    name: 'Posventa',
  },
  {
    code: 'GDP',
    name: 'Gestión de datos personales y privacidad',
  },
  {
    code: 'OT',
    name: 'Otras consultas',
  },
];

export const PantallaMotivoSAC = () => {
  const [motivo, setMotivo] = useState(null);
  const { fields } = useSelector((state: OneFormState) => state.formData);
  const dispatch = useDispatch();
  const { handleNextStep } = useOneFormContext();

  useEffect(() => {
    if (fields.motivoSac) {
      const motivo = getMotivoByCode(fields.motivoSac);
      setMotivo(motivo);
    }
  }, []);

  const getMotivoByName = (name) => {
		return motivoOptions ? motivoOptions.find(obj => obj.name == name) : null;
	}

  const getMotivoByCode = (code) => {
    return motivoOptions ? motivoOptions.find(obj => obj.code == code) : null;
	}

  function handleChangeMotivo(e: any) {
    e.preventDefault();
    if (e.target.value) {
			const motivo = getMotivoByName(e.target.value);
      setMotivo(motivo);
      dispatch({ type: 'UPDATE_FIELDS', payload: { ['motivoSac']: motivo.code } });
      dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { ['motivoSac']: motivo.name } });
      handleNextStep();
		}
  }

  return (
    <>
      <InputsWrapper>
        <SelectExtended
          options={motivoOptions}
          name="motivoSac"
          label="Elige un motivo"
          isRequired
          value={motivo ? motivo.name : ""}
          onClick={() => null}
          setOption={setMotivo}
          handleChange={handleChangeMotivo}
        />
      </InputsWrapper>
    </>
  );
};
