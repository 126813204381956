import React, { useEffect, useState } from 'react';
import { Text, styled } from '@volkswagen-onehub/components-core';
import { useFormContext } from 'react-hook-form';
import { CodigoPostal, InputError, TextInputExtended } from 'src/components';

const ContentWrapper = styled.div`
  margin-top: 4px;
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;
  }
  label {
    margin-top: 40px;
    @media screen and (min-width: 560px) {
      width: 303px;
    }
    @media screen and (min-width: 960px) {
      width: 320px;
      margin-top: 44px;
    }
    @media all and (max-height: 624px) {
      margin-top: 24px;
    }
  }
`;

const WidthWrapper = styled.div`
  width: 100%;
  @media all and (min-width: 560px) {
    width: 303px;
  }
  @media all and (min-width: 960px) {
    width: 320px;
  }
`;

export const EmpresaInfoComponent = () => {
  const { register } = useFormContext();

  // Nombre de la empresa
  const [inputValueName, setInputValueName] = useState('');
  const [pristineName, setPristineName] = useState(true);
  const [errorName, setErrorName] = useState(false);
  const regExpName = /[a-zA-Z]/gi;

  const handleChangeName = (e: any) => {
    setInputValueName(e.target.value);
    setPristineName(false);
  };

  useEffect(() => {
    if (!pristineName && inputValueName.match(regExpName) === null) {
      setErrorName(true);
    } else {
      setErrorName(false);
    }
  }, [inputValueName, pristineName]);

  // CIF
  const [inputValueCIF, setInputValueCIF] = useState('');
  const [pristineCIF, setPristineCIF] = useState(true);
  const [errorCIF, setErrorCIF] = useState(false);
  const regExpCIF = /[A-Z]\d{8}/gi;

  const handleChangeCIF = (e: any) => {
    setInputValueCIF(e.target.value);
    setPristineCIF(false);
  };

  useEffect(() => {
    if (!pristineCIF && inputValueCIF.match(regExpCIF) === null) {
      setErrorCIF(true);
    } else {
      setErrorCIF(false);
    }
  }, [inputValueCIF, pristineCIF]);



  return (
    <>
      <ContentWrapper>
        <WidthWrapper>
          <TextInputExtended
            name="businessName"
            label="Razón Social"
            isFloating
            innerRef={register({
              required: {
                value: true,
                message: 'Requerido',
              },
              pattern: {
                value: regExpName,
                message: 'Solo se aceptan letras',
              },
            })}
            onChange={(e) => handleChangeName(e)}
            required
          />
          {errorName && <InputError className="input-error">Solo se aceptan letras</InputError>}
        </WidthWrapper>
        <WidthWrapper>
          <TextInputExtended
            name="CIF"
            label="CIF"
            isFloating
            innerRef={register({
              required: {
                value: true,
                message: 'Requerido',
              },
              pattern: {
                value: regExpCIF,
                message: 'A00000000',
              },
            })}
            onChange={(e) => handleChangeCIF(e)}
            required
          />
          {errorCIF && <InputError className="input-error">Ejemplo: A00000000</InputError>}
        </WidthWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <WidthWrapper>
          <CodigoPostal />
        </WidthWrapper>

      </ContentWrapper>
    </>
  );
};
