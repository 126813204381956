import React, { useEffect, useState } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { FormController, useOneFormContext } from 'src/feature-app';
import { FormDataGroup, FormInfo, OneFormState, Steps,  } from 'src/types';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { Container, ContainerPadding, Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { MultipleCTASelection, MultipleOptionCTAs, DetalleMotivo, SelectMarcaModelo } from 'src/components';
import { ConfirmacionNoRenovacion, ThankYouNoRenovacion, FakeTriggerCTA } from '.';
import { getCommonFormData } from './FormData';
import { formatHeaders, formDataBuilder } from 'src/forms/format-fields';
import { oneformBFFNdpsSend } from 'src/bff';
import { NoRenovacionFormProps } from './NoRenovacionController';
import { useInitializeNoRenovacionForm } from './use-initialize-no-renovacion-form';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { addRemovedStep, getIndexInSteps, removeStep, resetStepsScreenIndex } from '../helpers/add-remove-steps';
import { CTAsMotivoRenovador, CTAsMotivoRenovadorProblemas, CTAsUsadoNuevo, CTAsLugarCompra } from 'src/feature-app/InputsValues';

export enum NoRenovacionNewCar {
  Introduccion,
  Modelo,
  UsadoNuevo,
  LugarCompra,
  Motivo,
  MasInformacion,
  ProblemasVehiculo,
  Confirmacion,
}

export function NoRenovacionYaTengo({
  dpsData,
  idVehicle,
  idPerson,
  handleError,
  trackFormLoad,
  estado,
}: NoRenovacionFormProps) {
  const { initializeForm } = useOneFormContext();
  const trackingManager = useTrackingManager();
  const store = useStore();
  const dispatch = useDispatch();
  const { formData } = useSelector((state: OneFormState) => state);
  const [loadingPresubmit, setLoadingPresubmit] = useState(false);
  const { selectMotivos, brand } = formData.fields;


  useEffect(() => {
    const sendPresubmit = async () => {
      const result = await sendForm(true);

      if (!result) {
        handleError();
      }
      setLoadingPresubmit(false);
    };

    sendPresubmit();
  }, []);

  const steps: Steps[] = [
    {
      title: (
        <>
          <Text bold>Nos encanta viajar contigo, pero sabemos que la vida da muchas vueltas y tenemos curiosidad…{' '}</Text>
          ¿Qué modelo te ha conquistado?
        </>
      ),
      fields: <OneElementTemplateFullScreen allowOverflowingContent element={<FakeTriggerCTA />} />,
      screenIndex: NoRenovacionNewCar.Introduccion,
      name: 'introduccion',
    },
    {
      title: (
        <>
          <Text bold>
            ¿Qué modelo{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              elegiste?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          allowOverflowingContent
          element={
            <SelectMarcaModelo stepName="Modelo" brandName="brand" modelName="modelo" required={true} formToken="VW_DDB_FORM_NORENUEVA"/>
          }
        />
      ),
      screenIndex: NoRenovacionNewCar.Modelo,
      name: 'Modelo',
      showOverflow: true,
    },
    {
      title: (
        <Text bold>
          ¿Qué tipo de vehículo has{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            escogido?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsUsadoNuevo} name="usadoNuevo" nextOnClick />}
        />
      ),
      screenIndex: NoRenovacionNewCar.UsadoNuevo,
      name: 'usadoNuevo',
    },
    {
      title: (
        <Text bold>
          ¿Dónde has adquirido tu nuevo{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            vehículo?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </Text>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsLugarCompra} name="lugarCompra" nextOnClick />}
        />
      ),
      screenIndex: NoRenovacionNewCar.LugarCompra,
      name: 'lugarCompra',
    },
    {
      title: (
        <>
          <Text bold>¿Por qué motivos </Text>
          no has elegido un{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            Volkswagen?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
          <Container padding={{ top: ContainerPadding.dynamic0020 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Elige un máximo de 3 opciones
            </Text>
          </Container>
        </>
      ),
      alternateTitle: (
        <>
          <Text bold>¿Por qué motivos </Text>
          no has elegido un{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            Volkswagen?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
          <Container padding={{ top: ContainerPadding.dynamic0020 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Elige un máximo de 3 opciones
            </Text>
          </Container>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <MultipleOptionCTAs
              ignoreHandleClick={true}
              ctaValues={CTAsMotivoRenovador}
              maxSelected={3}
              name="selectMotivos"
            />
          }
        />
      ),
      screenIndex: NoRenovacionNewCar.Motivo,
      name: 'selectMotivos',
    },
    {
      title: (
        <>
          <Text bold>¿Por qué motivos </Text>
          tuviste problemas con {' '}
          <span style={{ whiteSpace: 'nowrap' }}>
          tu vehículo anterior?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
          <Container padding={{ top: ContainerPadding.dynamic0020 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Elige un máximo de 3 opciones
            </Text>
          </Container>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <MultipleOptionCTAs
              ignoreHandleClick={true}
              ctaValues={CTAsMotivoRenovadorProblemas}
              maxSelected={3}
              name="selectMotivosProblems"
            />
          }
        />
      ),
      screenIndex: NoRenovacionNewCar.ProblemasVehiculo,
      name: 'selectMotivosProblems',
    },
    {
      title: (
        <>
          <Text bold>
            ¿Nos puedes decir cuáles{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              son?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: <OneElementTemplateFullScreen element={<DetalleMotivo />} />,
      screenIndex: NoRenovacionNewCar.MasInformacion,
      name: 'masInformacion',
    },
    {
      fields: <ConfirmacionNoRenovacion idPerson={idPerson} />,
      screenIndex: NoRenovacionNewCar.Confirmacion,
      name: 'Confirmacion',
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    fields: { formName: 'no_renovacion-ya_tengo', formType: 'otherform', pageCategory: 'Encuesta' }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  }; 

  useEffect(() => {
    if (brand) {
      updateSteps('lugarCompra', brand == '36', 'usadoNuevo');
      updateSteps('selectMotivos', brand != '36', 'usadoNuevo');
      updateSteps('selectMotivosProblems', brand != '36', 'selectMotivos');
      updateSteps('masInformacion', brand != '36', 'selectMotivosProblems');
    };
  }, [brand]);

  useEffect(() => {
    if (selectMotivos){
      if (!(selectMotivos.includes('14') && selectMotivos.includes('13'))) {
        if (selectMotivos.includes('14')) {
          updateSteps('masInformacion', false);
        }else if (selectMotivos.includes('13')) {
          updateSteps('selectMotivosProblems', false);
        }else{
          updateSteps('masInformacion', false);
          updateSteps('selectMotivosProblems', false);
        }
      }
    }
  }, [selectMotivos]);

  const updateSteps = (stepName: string, conditional: boolean, addOnNextStepName?: string ) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
    let newSteps = [...actualSteps];
    let newScreenIndex = screenIndex;

    if (conditional) {

      const stepIndex = getIndexInSteps(newSteps, stepName);

      if (stepIndex === -1) {
        if (addOnNextStepName) {
          newScreenIndex = getIndexInSteps(newSteps, addOnNextStepName);
        }
        newSteps = addRemovedStep(steps, actualSteps, stepName, newScreenIndex);
      }
    } else {
      newSteps = removeStep(newSteps, stepName);
    }

    const newStepsWithIndex = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
  };

  const generateData = (isPresubmit: boolean, data?: Partial<FormDataGroup>) => {
    const { formData }: OneFormState = store.getState();
    const { fields } = formData;

    let motivos = fields.selectMotivos;

    if(fields.selectMotivosProblems){
      motivos = motivos.replace("14", fields.selectMotivosProblems);
    }else{
      motivos = deleteProblema(motivos);
    }

    motivos = orderString(motivos);

    const submitData = !isPresubmit ? {
      'fields[SURVEY_ANSWER_02]': fields.brand,
      'fields[SURVEY_ANSWER_03]': fields.modelo,
      'fields[SURVEY_ANSWER_04]': fields.usadoNuevo,
      'fields[SURVEY_ANSWER_05]': motivos,
      'fields[SURVEY_ANSWER_06]': fields.lugarCompra,
    } : null;

    return {
      ...getCommonFormData(isPresubmit, idPerson, idVehicle, dpsData, fields, data, '1'),
      ...submitData,
    };
  };

  const orderString = (orderMotivos) => {

    if (orderMotivos == null) {
      return null;
    }

    const array = orderMotivos.split(",");
  
    const sortedArray = array.sort((a, b) => {
      return parseInt(a) - parseInt(b);
    });
  
    const orderString = sortedArray.join(",");
  
    return orderString;
  };

  const deleteProblema = (motivosProblema) => {

    if (motivosProblema != null) {
      const array = motivosProblema.split(",");
    
      if (array.length == 1 && (array[0] == "14" || array[0] == "nada")) {
        return null;
      }

      const motivos = array.filter(numero => numero != "14");
    
      return motivos.join(",");
    }
  }

  const sendForm = async (isPresubmit?: boolean): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateData(isPresubmit, NuevoFormData);
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData, isPresubmit);

      if (response.data?.status?.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { firstStep, lastStep, loading } = useInitializeNoRenovacionForm(steps);

  useEffect(() => {
    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps,
      stepsHistory: [0],
      thankYouComponent: <ThankYouNoRenovacion />,
    };

    if (!loading) {
      initializeForm(NuevoFormInfo, NuevoFormData, true);
      trackFormLoad();
    }
  }, [loading]);

  return loading || loadingPresubmit || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
