import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isNull } from 'src/feature-app';
import { OneFormState, Steps } from 'src/types';
import { useWindowWidth} from '@react-hook/window-size';

export const useShowOverflow = (isLayerScreen: boolean, selectedStep?: Steps ) => {
  /**
   *
   * @param stepsToIterate La lista de steps donde hay que buscar el paso previo.
   * @param screenIndexToGet La lista de steps donde hay que buscar el paso previo.
   */
  const windowWidth = useWindowWidth();
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { 
    multiStepScreenIndex, 
    screenIndex, 
    steps,
    multiSteps, notALayer
  } = formInfo;

  const [ showOverflow, setShowOverflow ] = useState(false);


  const handleShowOverflow = (stepsToIterate: Steps[], screenIndexToGet: number) => {
    if (!stepsToIterate || isNull(screenIndexToGet) || screenIndexToGet === -1) {
      setShowOverflow(false);
    }

    const actualStep = stepsToIterate.find((step) => step.screenIndex === screenIndexToGet);

    if(actualStep && actualStep.showOverflow) {
      setShowOverflow(true);
    } else {
      setShowOverflow(false);
    }
  };

  
  useEffect(()=>{
  
    if(windowWidth < 960 && !notALayer || windowWidth < 960 && !isLayerScreen ) {
      setShowOverflow(false);
    } else {

      if(selectedStep && selectedStep.showOverflow) {
        setShowOverflow(true);
      }
  
      if(!steps) {
        setShowOverflow(false);
      } else {
      //console.log(1, screenIndex, steps, multiStepScreenIndex);
        if (multiStepScreenIndex === null) {
          //console.log('22222', multiSteps, screenIndex, multiStepScreenIndex);
          handleShowOverflow(steps, screenIndex);
        } else {
          //console.log('333333', multiSteps, multiStepScreenIndex);
          handleShowOverflow(multiSteps, multiStepScreenIndex);
        } 
      }
    }
    
  }, [steps, multiSteps, selectedStep]);

  return { showOverflow };
};
