import React from 'react';
import {
  Container,
  ContainerGutter,
  CTA,
  styled,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { useIsComerciales } from 'src/feature-app/hooks';

const WhiteLinkWrapper = styled.span`
  a {
    color: #ffffff;
  }
`;

interface legalesFormatoLayerProps {
  noPadding?: boolean;
  showTitle?: boolean;
  linkBasesLegales?: string;
}
const Wrapper = styled.div<legalesFormatoLayerProps>`
  padding-top: ${(props) => (props.noPadding ? null : '72px')};
  padding-bottom: ${(props) => (props.noPadding ? null : '52px')};
`;

export const LegalesFormatoLayer = (props: legalesFormatoLayerProps) => {
  const isComerciales = useIsComerciales();

  return isComerciales ? <LegalesFormatoLayerComerciales {...props} /> : <LegalesFormatoLayerTurismos {...props} />;
};
const LegalesFormatoLayerTurismos = (props: legalesFormatoLayerProps) => {
  return (
    <Wrapper noPadding={props.noPadding}>
      <Container gutter={ContainerGutter.static300} wrap={'always'}>
        {props.showTitle ? (
          <Text bold staticSize appearance={TokenTextAppearance.copy200}>
            Texto legal de Volkswagen
          </Text>
        ) : null}

        <Text staticSize appearance={TokenTextAppearance.copy100}>
          {props.linkBasesLegales ? (
            <>
              Te esponsable del tratamiento, con la finalidad de gestionar tu participación en la Promoción y, en el caso que lo consientas, para los fines comerciales y/o perfilado según lo indicado en el propio consentimiento. Informarte que puedes ejercer los derechos que te confiere la normativa de protección de datos mediante solicitud escrita a{' '}
            </>
          ) : (
            <>
              Te informamos de que tus datos personales serán tratados por Volkswagen Group España Distribución S.A.U., en calidad de responsable del tratamiento, con la finalidad de atender tus consultas y solicitudes, realizar un control de calidad sobre los productos y servicios solicitados, realizar encuestas de opinión y estudios de mercado con fines estadísticos; y, en caso de que nos hayas dado tu consentimiento expreso, para las finalidades descritas en los consentimientos adicionales. Informarte que puedes ejercer los derechos que te confiere la normativa de protección de datos mediante solicitud escrita a{' '}
            </>
          )}
          <WhiteLinkWrapper>
            <CTA tag="a" href="mailto:atencioncliente@volkswagen.es" target="_blank" emphasis="tertiary">
              <b>atencioncliente@volkswagen.es</b>
            </CTA>
          </WhiteLinkWrapper>
          .
        </Text>
        <Text staticSize appearance={TokenTextAppearance.copy100}>
          Puedes consultar la información adicional sobre el tratamiento de tus datos en nuestra{' '}
          <WhiteLinkWrapper>
            <CTA
              tag="a"
              href="https://www.volkswagen.es/es/volkswagen-espana/politica-privacidad.html"
              target="_blank"
              emphasis="tertiary"
            >
              <b>política de privacidad</b>
            </CTA>
          </WhiteLinkWrapper>
          .
        </Text>
        {props.linkBasesLegales ? (
          <Text staticSize appearance={TokenTextAppearance.copy100}>
            Consulta las{' '}
            <WhiteLinkWrapper>
              <CTA
                tag="a"
                href={props.linkBasesLegales}
                target="_blank"
                emphasis="tertiary"
              >
                <b>Bases Legales</b>
              </CTA>
            </WhiteLinkWrapper>{' '}
            de la promoción.
          </Text>
        ) : null}
      </Container>
    </Wrapper>
  );
};
const LegalesFormatoLayerComerciales = (props: legalesFormatoLayerProps) => {
  return (
    <Wrapper noPadding={props.noPadding}>
      <Container gutter={ContainerGutter.static300} wrap={'always'}>
        {props.showTitle ? (
          <Text bold staticSize appearance={TokenTextAppearance.copy200}>
            Texto legal de Volkswagen
          </Text>
        ) : null}

        <Text staticSize appearance={TokenTextAppearance.copy100}>
          Te informamos de que tus datos personales serán tratados por Volkswagen Group España Distribución S.A.U., en calidad de responsable del tratamiento, con la finalidad de poder cursar tu petición y, en caso de que nos hayas dado tu consentimiento expreso, para las finalidades descritas en los consentimientos adicionales. Informarte que puedes ejercer los derechos que te confiere la normativa de protección de datos mediante solicitud escrita a{' '}
          <WhiteLinkWrapper>
            <CTA tag="a" href="mailto:atencioncliente@volkswagen-comerciales.es" target="_blank" emphasis="tertiary">
              <b>atencioncliente@volkswagen-comerciales.es</b>
            </CTA>
          </WhiteLinkWrapper>
          .
        </Text>
        <Text staticSize appearance={TokenTextAppearance.copy100}>
          Puedes consultar la información adicional sobre el tratamiento de tus datos en nuestra{' '}
          <WhiteLinkWrapper>
            <CTA
              tag="a"
              href="https://www.volkswagen-comerciales.es/es/volkswagen-espana/datos-privacidad.html"
              target="_blank"
              emphasis="tertiary"
            >
              <b>política de privacidad</b>
            </CTA>
          </WhiteLinkWrapper>
          .
        </Text>
      </Container>
    </Wrapper>
  );
};

export const LegalesMontmelo = (props: legalesFormatoLayerProps) => {
	return (
		<Wrapper noPadding={props.noPadding}>
			<Container gutter={ContainerGutter.static300} wrap={'always'}>
				{props.showTitle ? (
					<Text bold staticSize appearance={TokenTextAppearance.copy200}>
						Texto legal de Volkswagen
					</Text>
				) : null}

				<Text staticSize appearance={TokenTextAppearance.copy100}>
					Te informamos que el responsable del tratamiento de tus datos es la compañía VOLKSWAGEN GROUP ESPAÑA DISTRIBUCION, S.A.U., con la finalidad de atender tus consultas y solicitudes, realizar un control de calidad sobre los productos y servicios solicitados, realizar encuestas de opinión y estudios de mercado con fines estadísticos y gestionar tu participación gratuita en la Promoción Driving Experience Montmeló comunicada durante el proceso de cita previa.
				</Text>
				<Text staticSize appearance={TokenTextAppearance.copy100}>
					Para más información sobre el tratamiento de los datos pulsa{' '}
					<WhiteLinkWrapper>
						<CTA
							tag="a"
							href="https://www.volkswagen.es/content/dam/onehub_pkw/importers/es/pdf/Bases_legales_Sorteo_Escuela_GTI_Montmelo_2024.pdf"
							target="_blank"
							emphasis="tertiary"
						>
							<b>aquí</b>
						</CTA>
					</WhiteLinkWrapper>
					.
				</Text>
			</Container>
		</Wrapper>
	);
}
