import { FormDataGroup } from 'src/types';

export const PartialDataMasInformacionConfigurador: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Más Información' },
  fields: {
    formFields: {},
    formularioFlag: 'masInformacion',
    formName: 'lead-mas_informacion-configure',
    formType: 'contact',
    pageCategory: 'Más Información',
  },
};
