import React, { useEffect } from 'react';
import { TriggerProps, useFaContent } from 'src/feature-app';
import { StockForm } from 'src/forms/Leads/Stock/StockForm';

export function LeadStockTrigger({ setSelectedJourney }: TriggerProps): JSX.Element {
  const faContent = useFaContent();

  useEffect(() => {
    setSelectedJourney(<StockForm />, true);
  }, []);

  return null;
}
