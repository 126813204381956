import { getDistanceFromLatLngCenter } from './utils';
import { isNull } from 'src/feature-app';
import { Horario, Slot, Points, Center, Slots } from 'src/types';

export const sortPoints = (
  clusters: Points[],
  horario: Horario,
  center: google.maps.LatLng,
  supercluster: supercluster
  // zoom: number,
  // firstLoad: boolean
): { pointsByTime: Points[] | null; pointsByDistance: Points[] | null; newCenter?: Center; newZoom?: number } => {
  // Filtramos los puntos que no son clusters para eliminar las agrupaciones y quedarnos con los dealers unicamente, para posteriormente obtener su distancia la centro del mapa.

  // const distanceForFilter = getDistanceForFilter(zoom);

  // points = points.filter((point: Points) => Number(point.properties.distanceFromCenter) < distanceForFilter);
  let points: Points[] = [...clusters];

  const realClusters = points.filter((point: Points) => point.properties.cluster === true);
  // .sort((a: Points, b: Points) => Number(b.properties.point_count) - Number(a.properties.point_count));

  realClusters.forEach((cluster) => {
    if (supercluster) {
      const leaves: Points[] = supercluster.getLeaves(cluster.id);
      leaves.forEach((dealer) => {
        // Necesitamos esta propiedad para en caso de hover sobre la card, poder cambiar el cluster de color.
        dealer.properties.parent_cluster_id = cluster.id;

        points.push(dealer);
      });
    }
  });

  points = points.filter((point: Points) => point.properties.cluster === false);
  points = [...points]
    // .filter((point: Points) => point.properties.cluster === false)
    .map((point: Points) => {
      const distanceFromCenter = getDistanceFromLatLngCenter(center, point);
      return {
        ...point,
        properties: {
          ...point.properties,
          distanceFromCenter,
        },
      };
    });
  // if (points.length === 0 && firstLoad) {
  //   return { pointsByTime: null, pointsByDistance: null, newCenter: center, newZoom: zoom - 1 };
  // }

  const pointsByTime = sortByTime(points, horario);

  const pointsByDistance = points.sort(
    (a: Points, b: Points) => Number(a.properties.distanceFromCenter) - Number(b.properties.distanceFromCenter)
  );

  return { pointsByTime, pointsByDistance, newCenter: null, newZoom: null };
};

const sortByTime = (points: Points[], horario: Horario): Points[] => {
  const pointsWithDms: Points[] = [];
  const pointsWithoutDms: Points[] = [];

  points.forEach((point: Points) => {
    const { dealer } = point.properties;
    dealer.dmsInfo && dealer.dmsInfo.dmsAvailable ? pointsWithDms.push(point) : pointsWithoutDms.push(point);
  });

  const pointsWithoutDmsSorted = pointsWithoutDms.sort(
    (a: Points, b: Points) => Number(a.properties.distanceFromCenter) - Number(b.properties.distanceFromCenter)
  );

  const pointsWithSlots = pointsWithDms.filter((point: Points) => !isNull(point.properties.dealer.slots));
  // Si el usuario elige cualquier opción que no sea Lo antes posible se le devuelven los slots predeterminados.
  // En caso contrario, se debe elegir el turno más temprano de cada concesión y guardarlo como earliestSlot para poder ordenarlos más adelante.
  if (horario !== 'before') {
    const pointsWithDmsAndAvailableSlots: Points[] = [];
    const pointsWithDmsAndWithoutAvailableSlots: Points[] = [];
    pointsWithSlots.forEach((point) => {
      const { dealer } = point.properties;
      isNull(dealer.slots[horario])
        ? pointsWithDmsAndWithoutAvailableSlots.push(point)
        : pointsWithDmsAndAvailableSlots.push(point);
    });

    pointsWithDmsAndAvailableSlots.sort(
      (a: Points, b: Points) => a.properties.dealer.slots[horario].from - b.properties.dealer.slots[horario].from
    );

    return [...pointsWithDmsAndAvailableSlots, ...pointsWithDmsAndWithoutAvailableSlots, ...pointsWithoutDms];
  } else {
    const pointsWithoutSlots: Points[] = [];
    const pointsWithAtLeastOneSlot: Points[] = [];

    // Separamos los dealers con DMS según si tienen al menos un slot disponible o no.
    pointsWithSlots.forEach((dealer: Points) => {
      if (
        !isNull(dealer.properties.dealer.slots.morning) ||
        !isNull(dealer.properties.dealer.slots.evening) ||
        !isNull(dealer.properties.dealer.slots.afternon)
      ) {
        pointsWithAtLeastOneSlot.push(dealer);
      } else {
        pointsWithoutSlots.push(dealer);
      }
    });

    const pointsWithEarliestSlot = pointsWithAtLeastOneSlot
      .map((point: Points) => {
        const { slots } = point.properties.dealer;
        const slotsFiltered = Object.values(slots)
          .filter((slot: Slot) => !isNull(slot) && !isNull(slot.from))
          .map((slot: Slot) => slot.from);
        point.properties.earliestSlot = Math.min(...slotsFiltered);
        return point;
      })
      .sort((a: Points, b: Points) => a.properties.earliestSlot - b.properties.earliestSlot);
    return [...pointsWithEarliestSlot, ...pointsWithoutSlots, ...pointsWithoutDmsSorted];
  }
};

export const sortPointsByDistance = (
  clusters: Points[],
  center: google.maps.LatLng,
  supercluster: supercluster
): Points[] => {
  let points: Points[] = [...clusters];

  const realClusters = points.filter((point: Points) => point.properties.cluster === true);
  realClusters.forEach((cluster) => {
    if (supercluster) {
      const leaves: Points[] = supercluster.getLeaves(cluster.id);
      leaves.forEach((dealer) => {
        // Necesitamos esta propiedad para en caso de hover sobre la card, poder cambiar el cluster de color.
        dealer.properties.parent_cluster_id = cluster.id;

        points.push(dealer);
      });
    }
  });

  return points
    .filter((point: Points) => point.properties.cluster === false)
    .map((point: Points) => {
      const distanceFromCenter = getDistanceFromLatLngCenter(center, point);
      return {
        ...point,
        properties: {
          ...point.properties,
          distanceFromCenter,
        },
      };
    })
    .sort((a: Points, b: Points) => Number(a.properties.distanceFromCenter) - Number(b.properties.distanceFromCenter));
};

export const getEarliestSlot = (slots: Slots) => {
  if (!slots) {
    return null;
  }
  const filteredSlots = Object.values(slots)
  .filter((slot: Slot) => !isNull(slot) && !isNull(slot.from))
  .map((slot: Slot) => slot.from);
  if (filteredSlots.length === 0) {
    return null;
  }
  return Math.min(...filteredSlots);
};
