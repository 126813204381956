export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_FIELDS = 'UPDATE_FIELDS';

interface UpdateFormAction {
  type: typeof UPDATE_FORM;
  payload: any;
}

interface UpdateFieldsAction {
  type: typeof UPDATE_FIELDS;
  payload: any;
}

export type FormDataActionTypes = UpdateFieldsAction | UpdateFormAction;
