import { IdVehicle} from "src/types";
import { EsOneformCoreServiceV1 } from "src/feature-service/es-oneform-core-feature-service/types";
import { createPincodeEntity } from 'src/forms';
import { useFeatureServices } from "./use-feature-services";

export const useGetIdVehicle = () => {
    const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;
    const dpsData = oneFormService.getDpsData();
    const idVehicle: IdVehicle = createPincodeEntity(dpsData, 'VEHICLE');

    return idVehicle;
}