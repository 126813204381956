export const orderAttributes = [
	{
		name: 'ID',
		value: 'ORDER',
	},
	{
		name: 'attributes',
		value:
			'es.vged.dps.salesforce.rest.entities.SfAttributesRest@59506a4c[type=Order__c,url=/services/data/v53.0/sobjects/Order__c/a0W1n00000IETcDEAX]',
	},
	{
		name: 'Id',
		value: 'a0W1n00000IETcDEAX',
	},
	{
		name: 'OwnerId',
		value: '0050Y000001UE3gQAG',
	},
	{
		name: 'IsDeleted',
		value: 'false',
	},
	{
		name: 'Name',
		value: '36V_146_6599',
	},
	{
		name: 'CreatedDate',
		value: '2020-10-31T08:20:33.000+0000',
	},
	{
		name: 'CreatedById',
		value: '0050Y000001UE3gQAG',
	},
	{
		name: 'LastModifiedDate',
		value: '2021-11-26T09:28:57.000+0000',
	},
	{
		name: 'LastModifiedById',
		value: '0050Y000001UE3gQAG',
	},
	{
		name: 'SystemModstamp',
		value: '2021-11-26T09:28:58.000+0000',
	},
	{
		name: 'LastViewedDate',
		value: 'null',
	},
	{
		name: 'LastReferencedDate',
		value: 'null',
	},
	{
		name: 'CHK_excessive_waiting_Audi__c',
		value: 'false',
	},
	{
		name: 'CHK_excessive_waiting_Audi_theoric__c',
		value: 'false',
	},
	{
		name: 'CHK_excessive_waiting_LCV__c',
		value: 'false',
	},
	{
		name: 'CHK_excessive_waiting_Skoda__c',
		value: 'false',
	},
	{
		name: 'CHK_excessive_waiting_VW__c',
		value: 'false',
	},
	{
		name: 'COD_Carline__c',
		value: '30316',
	},
	{
		name: 'Instalacion_Audi__c',
		value: 'null',
	},
	{
		name: 'Instalacion_LCV__c',
		value: 'null',
	},
	{
		name: 'Instalacion_SK__c',
		value: 'null',
	},
	{
		name: 'COD_comision__c',
		value: 'null',
	},
	{
		name: 'COD_sales_type__c',
		value: 'null',
	},
	{
		name: 'COD_salesgroup__c',
		value: '39237',
	},
	{
		name: 'Customer_change_order__c',
		value: 'false',
	},
	{
		name: 'VipEnviado__c',
		value: 'false',
	},
	{
		name: 'DemoraTipo__c',
		value: 'null',
	},
	{
		name: 'ConfirmacionRegaloEnviado__c',
		value: 'false',
	},
	{
		name: 'EmailFollowUpEnviado__c',
		value: 'false',
	},
	{
		name: 'CHK_contactable_universe__c',
		value: '0',
	},
	{
		name: 'Contactable_universe_vehicle__c',
		value: 'null',
	},
	{
		name: 'DESC_Model__c',
		value: 'null',
	},
	{
		name: 'DESC_Sales_VN_Type__c',
		value: 'null',
	},
	{
		name: 'DESC_TrimLevel__c',
		value: 'null',
	},
	{
		name: 'DESC_car_model_salesgroup__c',
		value: 'Golf Last Edition',
	},
	{
		name: 'DESC_excessive_waiting_Audi__c',
		value: 'null',
	},
	{
		name: 'DESC_model_group__c',
		value: 'null',
	},
	{
		name: 'EmailBienvenidaEnviado__c',
		value: 'false',
	},
	{
		name: 'EmailDemoraEnviado__c',
		value: 'false',
	},
	{
		name: 'EmailEstadoEnviado__c',
		value: 'null',
	},
	{
		name: 'Factory__c',
		value: 'null',
	},
	{
		name: 'Not_lineal_order__c',
		value: 'false',
	},
	{
		name: 'Order_frame_FK__c',
		value: 'WVWZZZAUZLW014456',
	},
	{
		name: 'PKL_order_VN_VO__c',
		value: 'VO',
	},
	{
		name: 'PKL_order_brand__c',
		value: 'Volkswagen',
	},
	{
		name: 'SLI_State__c',
		value: '70',
	},
	{
		name: 'Vehicle_age_monthly_ord__c',
		value: '19',
	},
	{
		name: 'Vehicle_age_yearly_ord__c',
		value: '2',
	},
	{
		name: 'COD_Customer_renting__c',
		value: '0010Y000015jO9yQAE',
	},
	{
		name: 'COD_Customer_type__c',
		value: '8',
	},
	{
		name: 'COD_Driver__c',
		value: '0010Y000015jO9yQAE',
	},
	{
		name: 'COD_Vehicle_climapro__c',
		value: 'a0Y1n00000HU1nzEAD',
	},
	{
		name: 'COD_owner_climapro__c',
		value: '0010Y00001cvNpnQAE',
	},
	{
		name: 'Concesion__c',
		value: '0010Y000014bFZOQA2',
	},
	{
		name: 'Customer_type__c',
		value: 'Particular VO IMA DWA',
	},
	{
		name: 'DAT_Customers_order__c',
		value: '2020-03-05',
	},
	{
		name: 'DAT_Order__c',
		value: '2020-03-05',
	},
	{
		name: 'DAT_delivery__c',
		value: '2020-05-26',
	},
	{
		name: 'DAT_order_state_10__c',
		value: 'null',
	},
	{
		name: 'DAT_order_state_20__c',
		value: 'null',
	},
	{
		name: 'DAT_order_state_30__c',
		value: 'null',
	},
	{
		name: 'DAT_order_state_40__c',
		value: 'null',
	},
	{
		name: 'DAT_order_state_50__c',
		value: 'null',
	},
	{
		name: 'DAT_order_state_60__c',
		value: 'null',
	},
	{
		name: 'DAT_order_state_70__c',
		value: 'null',
	},
	{
		name: 'DAT_order_state_80__c',
		value: 'null',
	},
	{
		name: 'IDClimapro__c',
		value: '36V_146_6599',
	},
	{
		name: 'Instalacion__c',
		value: '0010Y00001IPCkUQAX',
	},
];
