import axios from 'axios';
import { getDataUrl, reportErrorSentry } from 'src/bff';
import { DealersData } from 'src/types';

export const getDealerKvps = async (kvps: string): Promise<DealersData | null> => {
  try {
    const dataUrl = getDataUrl();

    const dealerResponse = await axios.get(`${dataUrl}/getDealer/${kvps}`);

    if (dealerResponse?.data?.dealer) {
      return dealerResponse.data.dealer;
    }

    reportErrorSentry('Could not get dealer');
    console.error('Could not get dealer');
    return null;
  } catch (error) {
    reportErrorSentry(error);
    return null;
  }
};
