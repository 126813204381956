import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { styled, TextInput } from '@volkswagen-onehub/components-core';
import { InputError } from '../NavidadGTI2024Page';
import { empty } from 'rxjs';

interface InputProps {
	name: string;
	label?: string;
	empty?: boolean;
}

export function TextInputField(props: InputProps) {
	const { name, label, empty } = props;
	const dispatch = useDispatch();
	const { register } = useFormContext();
	const [errorValue, setErrorValue] = useState(false);

	const handleChange = (e: any) => {
		const value = e.target.value;

		setErrorValue(false);

		if (/\d/.test(value) || value.trim().length < 3) {
			setErrorValue(true);
		} else {
			dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: value } });
		}
	};

	return (
		<>
			<TextInput
				name={name}
				label={label}
				appearance={'default'}
				required={true}
				onChange={(e) => handleChange(e)}
				type={'text'}
				isFloating={true}
			/>
			{errorValue && <InputError className="input-error">Introduce un valor correcto</InputError>}
			{empty && <InputError className="input-error">Campo obligatorio</InputError>}
		</>
	);
}
