import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { 
  styled, 
  Text, 
  TextAlignment, 
  BreakpointWrapper, 
  Breakpoints,
  Container,
  ContainerPadding,
  CTA,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { useOneFormContext } from 'src/feature-app';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';

const CustomInputRange = styled.input`
    width: 100%;
    height: 30px;
    background: transparent;
    font-size: 1em;
    cursor: pointer;    
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track, &::-webkit-slider-thumb {
        -webkit-appearance: none;
    };
    &::-webkit-slider-runnable-track{
        position: relative;
        width: 100%;
        height: 1px;
        background: transparent;  
    };
    &::-moz-range-track {
        width: 100%;
        height: 1px;
        background: transparent;
    };
    &::-ms-track {
        border: none;
        width: 100%;
        height: 1px;
        background: transparent;
        color: transparent;
    };
    &::-ms-fill-lower {
        display: none;
    };
    &::-webkit-slider-thumb {
        box-sizing: border-box;
        width: 24px;  
        height: 24px;
        border-radius: 50%;
        background: #001e50;
        transform: translateY(-12px);
    };
    &::-moz-range-thumb {
        box-sizing: border-box;
        width: 24px;  
        height: 24px;
        border-radius: 50%;
        background: #001e50;
        transform: translateY(-12px);
    };
    &::-ms-thumb {
        box-sizing: border-box;
        width: 24px;  
        height: 24px;
        border-radius: 50%;
        background: #001e50;
        transform: translateY(-12px);
    };
    &::-webkit-slider-runnable-track:before, &::-webkit-slider-runnable-track:after, & /deep/ #track:before, &/deep/ #track:after {
        position: absolute;
        bottom: 100%;
        color: #2c2c2b;
        font: 700 0.875em / 2 arial, sans-serif;
        background: #001e50;
    };
    &::-webkit-slider-runnable-track:before, & /deep/ #track:before {
        left: 0.8125em;
        word-spacing: 1.4375em;
        content: '1 2 3 4 5 6 7 8 9';
    };
    &::-webkit-slider-runnable-track:after, & /deep/ #track:after {
        right: 0.625em;
        word-spacing: 0.875em;
        content: '10 11 12 13 14 15 16';
    };
    &:focus{
        outline: none;
    }

`;
const Mark = styled.div`
    height: 9px;
    width: 1px;
    background-color: #001e50;
`;
const MarksPosition = styled.div`
    display: flex;
    display:-webkit-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -19.5px;
    z-index: 3;
    margin-left: 7px;
    @media screen and (min-width: 960px) {
        margin-left: 0;
        width: 97%;
    }
`;
const SliderPosition = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 7px;
    @media screen and (min-width: 960px) {
        margin-left: 4px;
    }
`;
const SlideLabelsPosition = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const SliderAndMarksPosition = styled.div`
    width: 96%;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media all and (min-width: 960px) {
        width: 99%;
        padding-right: 0px;
    }
`;
interface CustomSliderProps {
  mobileLabels: Array<string>;
  desktopLabels: Array<string>;
  name: string;
}

export const SlideEncuestas = (props: CustomSliderProps ) => {

  const {name, mobileLabels, desktopLabels} = props;
  const { handleNextStep } = useOneFormContext();
  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();

  const numberOfSteps = 5;
  const sliderMarks= [];
  const [value, setValue] = useState(3);


  const handleInputChange = (event: any) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: `${value}` } });
  }, [value]);

  for(let i = 0; i < 5; i++) {
    sliderMarks.push(<Mark/>);
  }
    
  document.documentElement.classList.add('js');
  
  return (
    <div style={{paddingTop: '44px'}}>
      <SliderAndMarksPosition className = 'slider-and-marks-wrapper'>
        <div className='fake-rail' style={{height: '1px', width: '97%', backgroundColor:'#001e50', transform: 'translateY(5px)'}} />
        <MarksPosition className='marks-position'>
          {sliderMarks ? sliderMarks : null}
        </MarksPosition>  
        <SliderPosition className='slider-position'>
          <CustomInputRange 
            type='range' 
            min='1' 
            max={numberOfSteps} 
            step='1' 
            id="rangeInput" name="rangeInput"
            onChange={(e)=> handleInputChange(e)}
            value={value}
          />
        </SliderPosition>   
      </SliderAndMarksPosition>
      <SlideLabelsPosition className='labels-position'>
        <BreakpointWrapper max={Breakpoints.b960}>
          <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.left}>{mobileLabels[0]}</Text>
          <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.right}>{mobileLabels[1]}</Text>
        </BreakpointWrapper>
        <BreakpointWrapper min={Breakpoints.b960}>
          <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.left}>{desktopLabels[0]}</Text>
          <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.center}>{desktopLabels[1]}</Text>
          <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.center}>{desktopLabels[2]}</Text>
          <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.center}>{desktopLabels[3]}</Text>
          <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.right}>{desktopLabels[4]}</Text>
        </BreakpointWrapper>
        
      </SlideLabelsPosition>

      <Container horizontalAlign={'flex-start'} padding={{ top: ContainerPadding.static500 }}>
        <CTA
          tag="button"
          emphasis="secondary"
          onClick={(e) => {
            e.preventDefault();
            trackingManager.trackFormButtonClick({
              contentId: 'Sin respuesta',
            });
            setValue(3);
            handleNextStep(e);
          }}
          ariaLabel="aria label"
        >
          Sin respuesta
        </CTA>
      </Container>
    </div>
  );
};

