import React from 'react';
import {
  Container,
  Text,
  ContainerGutter,
  TextAlignment,
  styled,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import { CalendarSlot } from 'src/types';

interface hourWrapperProps {
  selected: any;
}

const HourWrapper = styled.div<hourWrapperProps>`
  width: 68px;
  height: 33px;
  border: ${(props) => (props.selected ? '1px solid #001E50' : '1px solid #DFE4E8')};
  background-color: ${(props) => (props.selected ? '#001E50' : null)};
  color: ${(props) => (props.selected ? '#FFFFFF' : '#001E50')};
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface slotsHorarioProps {
  avaiableHours: CalendarSlot[];
  // selectedHourIndex: number;
  handleSelectedSlot: any;
  selectedSlot: CalendarSlot;
}

export const SlotsHorario = (props: slotsHorarioProps) => {
  const { avaiableHours, handleSelectedSlot, selectedSlot } = props;

  const checkIfHourIsSelected = (slot: CalendarSlot) => {
    if (selectedSlot) {
      return slot.from === selectedSlot.from ? true : false;
    }
    return false;
  };

  return (
    <Container gutter={ContainerGutter.static350}>
      {avaiableHours && avaiableHours.length > 0 ? (
        avaiableHours.map((slot: CalendarSlot, i) => {
          return (
            <HourWrapper
              onClick={(e) => {
                e.preventDefault();
                handleSelectedSlot(slot, i);
              }}
              key={slot.from}
              selected={checkIfHourIsSelected(slot)}
            >
              <Text appearance={TokenTextAppearance.label150} color={TokenTextColor.inherit}>
                {slot.hour.slice(0, 5)}
              </Text>
            </HourWrapper>
          );
        })
      ) : (
        <Text appearance={TokenTextAppearance.copy200} color={TokenTextColor.inherit} textAlign={TextAlignment.left}>
          No hay horas disponibles en esta franja.
        </Text>
      )}
    </Container>
  );
};
