import React from 'react';
import { TriggerProps, useInitiateBonosTriggers } from 'src/feature-app';
import { PresupuestoForm } from 'src/forms';
import { FormDataGroup } from 'src/types';

const leadBonoPartialData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Presupuesto' },
  fields: {
    formFields: {},
    formularioFlag: 'presupuesto',
    formName: 'lead-presupuesto-bono',
    formType: 'quote',
    pageCategory: 'Presupuesto',
  },
};

export function LeadBonoTrigger({ setSelectedJourney }: TriggerProps): JSX.Element {
  useInitiateBonosTriggers(
    setSelectedJourney,
    <PresupuestoForm updatedFormData={leadBonoPartialData} />
  );

  return null;
}
