export const getTestVehicle = (isElectrico?: boolean) => {
	return [
		{
			name: 'ID',
			value: 'VEHICLE',
		},
		{
			name: 'Id',
			value: 'a0U1X0000049qKgUAI',
		},
		{
			name: 'Name', // Frame
			value: 'WVWZZZE13PP508774',
		},
		{
			name: 'VGED_ModelGroup__c',
			value: isElectrico ? 'ID.3' : 'GOLF',
		},
		{
			name: 'VGED_RegistrationPlate__c',
			value: '1111-BBB',
		},

		///////////// sin uso

		{
			name: 'attributes',
			value:
				'es.vged.ndpsbackend.salesforce.rest.entities.SfAttributesRest@7d536fed[type=Vehicle__c,url=/services/data/v53.0/sobjects/Vehicle__c/a0U1X0000049qKgUAI]',
		},
		{
			name: 'OwnerId',
			value: '0051i000002usITAAY',
		},
		{
			name: 'IsDeleted',
			value: 'false',
		},
		{
			name: 'DESC_Model__c',
			value: isElectrico ? 'ID.3' : 'GOLF',
		},
		{
			name: 'Registration__c',
			value: 'Example Registration',
		},
		{
			name: 'CurrencyIsoCode',
			value: 'EUR',
		},
		{
			name: 'CreatedDate',
			value: '2023-09-07T10:24:54.000+0000',
		},
		{
			name: 'CreatedById',
			value: '0051i000002usITAAY',
		},
		{
			name: 'LastModifiedDate',
			value: '2024-05-29T09:27:53.000+0000',
		},
		{
			name: 'LastModifiedById',
			value: '0051i000002usITAAY',
		},
		{
			name: 'SystemModstamp',
			value: '2024-05-29T09:27:53.000+0000',
		},
		{
			name: 'LastActivityDate',
			value: 'null',
		},
		{
			name: 'LastViewedDate',
			value: 'null',
		},
		{
			name: 'LastReferencedDate',
			value: 'null',
		},
		{
			name: 'Account__c',
			value: 'null',
		},
		{
			name: 'DeliveryDate__c',
			value: 'null',
		},
		{
			name: 'EngineType__c',
			value: 'null',
		},
		{
			name: 'ExtKey__c',
			value: '44529043',
		},
		{
			name: 'ExteriorColorText__c',
			value: 'null',
		},
		{
			name: 'ExteriorColor__c',
			value: 'C2A1',
		},
		{
			name: 'Lead__c',
			value: 'null',
		},
		{
			name: 'ModelKey__c',
			value: 'E113MJ',
		},
		{
			name: 'ModelYear__c',
			value: '2023',
		},
		{
			name: 'Model__c',
			value: 'null',
		},
		{
			name: 'Opportunity__c',
			value: 'null',
		},
		{
			name: 'ProductionDate__c',
			value: 'null',
		},
		{
			name: 'RCY_BrandList__c',
			value: 'Volkswagen',
		},
		{
			name: 'RCY_BrandLogo__c',
			value: '<img src="/resource/VWLogo" alt="Volkswagen" style="height:25px; width:30px;" border="0"/>',
		},
		{
			name: 'RCY_BrandSearch__c',
			value: 'null',
		},
		{
			name: 'RCY_BrandVehicleModel__c',
			value: 'null',
		},
		{
			name: 'RCY_Brand__c',
			value: 'null',
		},
		{
			name: 'RCY_DisplacementInLiters__c',
			value: 'null',
		},
		{
			name: 'RCY_PowerInHorsePower__c',
			value: '150.0',
		},
		{
			name: 'RCY_PowerInKw__c',
			value: 'null',
		},
		{
			name: 'RCY_TransmissionCode__c',
			value: 'null',
		},
		{
			name: 'RCY_VehicleDetails__c',
			value: 'null',
		},
		{
			name: 'RCY_SEMAGMigrationID__c',
			value: 'null',
		},
		{
			name: 'RCY_HelperGVFTransmissionCode__c',
			value: 'null',
		},
		{
			name: 'RCY_InteriorColorText__c',
			value: 'null',
		},
		{
			name: 'RCY_InteriorColor__c',
			value: 'XX',
		},
		{
			name: 'RCY_RoofColorText__c',
			value: 'null',
		},
		{
			name: 'RCY_RoofColor__c',
			value: 'null',
		},
		{
			name: 'VGED_AssistanceYears__c',
			value: 'null',
		},
		{
			name: 'VGED_AudiConnect__c',
			value: '0',
		},
		{
			name: 'VGED_AudiSport__c',
			value: 'false',
		},
		{
			name: 'VGED_CarModelSalesgroup__c',
			value: 'NO INFORMADO',
		},
		{
			name: 'VGED_CommercialDesignation__c',
			value: 'null',
		},
		{
			name: 'VGED_Electric__c',
			value: isElectrico ? 'false' : 'true',
		},
		{
			name: 'VGED_GearType__c',
			value: 'NO INFORMADO',
		},
		{
			name: 'VGED_HybridElectric__c',
			value: 'false',
		},
		{
			name: 'VGED_HybridGLP__c',
			value: 'false',
		},
		{
			name: 'VGED_IDClimapro__c',
			value: 'null',
		},
		{
			name: 'VGED_InactiveRecord__c',
			value: 'false',
		},
		{
			name: 'VGED_InstalledPRPackages__c',
			value: 'null',
		},
		{
			name: 'VGED_IsModelS__c',
			value: 'false',
		},
		{
			name: 'VGED_KMEstimated__c',
			value: 'null',
		},
		{
			name: 'VGED_LongFullDriveProduct__c',
			value: '1',
		},
		{
			name: 'VGED_LongFullDriveStanding__c',
			value: 'false',
		},
		{
			name: 'VGED_MaintenanceIncluded__c',
			value: '0.0',
		},
		{
			name: 'VGED_MaintenanceType__c',
			value: '0.0',
		},
		{
			name: 'VGED_NextMaintenanceDate__c',
			value: 'null',
		},
		{
			name: 'VGED_OfferLongFullDrive__c',
			value: '0',
		},
		{
			name: 'VGED_OptionalSLIImporter__c',
			value: 'EV3 PJH',
		},
		{
			name: 'VGED_OptionalsSLI__c',
			value: 'AY2 EV3 PJH S3C W2E 4AF 8AV',
		},
		{
			name: 'VGED_RegistrationDate__c',
			value: 'null',
		},
		{
			name: 'VGED_RepairDateEA189__c',
			value: 'null',
		},
		{
			name: 'VGED_Segment__c',
			value: isElectrico ? '30350' : '30316',
		},
		{
			name: 'VGED_TEST__c',
			value: 'false',
		},
		{
			name: 'VGED_TractionType__c',
			value: 'TRACCIÓN TRASERA',
		},
		{
			name: 'VGED_TrimLevelModel__c',
			value: '0',
		},
		{
			name: 'VGED_VehicleWeightSLI__c',
			value: 'null',
		},
		{
			name: 'VGED_LegacySFId__c',
			value: 'null',
		},
		{
			name: 'VGED_Model__c',
			value: '0',
		},
		{
			name: 'VGED_AffectedEA189__c',
			value: 'false',
		},
		{
			name: 'VGED_CancelDateLongDriveContractTerminat__c',
			value: '2034-03-11',
		},
		{
			name: 'VGED_CarNet__c',
			value: 'false',
		},
		{
			name: 'VGED_CarlineCode__c',
			value: isElectrico ? '30350' : '30316',
		},
		{
			name: 'VGED_Commerce__c',
			value: 'null',
		},
		{
			name: 'VGED_CommissionCode__c',
			value: '0028034',
		},
		{
			name: 'VGED_CommissionYear__c',
			value: '2023',
		},
		{
			name: 'VGED_EffectiveDateIncludingMaintenance__c',
			value: 'null',
		},
		{
			name: 'VGED_FactoryPickup__c',
			value: 'null',
		},
		{
			name: 'VGED_GuaranteedKmsCoverage__c',
			value: '0.0',
		},
		{
			name: 'VGED_LastDateOfRepair__c',
			value: 'null',
		},
		{
			name: 'VGED_Life__c',
			value: 'null',
		},
		{
			name: 'VGED_LongDriveContractDischargeDate__c',
			value: '2024-03-11',
		},
		{
			name: 'VGED_LongDriveEffectiveDate__c',
			value: '2034-03-11',
		},
		{
			name: 'VGED_Mileage__c',
			value: 'null',
		},
		{
			name: 'VGED_NextITV__c',
			value: 'null',
		},
		{
			name: 'VGED_NumLdFreeMonths__c',
			value: 'null',
		},
		{
			name: 'VGED_OrderNumber__c',
			value: 'null',
		},
		{
			name: 'VGED_RepairedEA189__c',
			value: 'false',
		},
		{
			name: 'VGED_SalesGroupCode__c',
			value: isElectrico ? '33236' : '39237',
		},
		{
			name: 'VGED_SliModelGroup__c',
			value: 'null',
		},
		{
			name: 'VGED_Transformed__c',
			value: 'false',
		},
		{
			name: 'VGED_UpdateDateKmEstimation__c',
			value: 'null',
		},
		{
			name: 'VGED_VwConnect__c',
			value: 'false',
		},
		{
			name: 'VGED_WarrantyExtension__c',
			value: '0.0',
		},
		{
			name: 'VGED_YearsWarrantyCoverage__c',
			value: 'null',
		},
		{
			name: 'VGED_YearsWarrantyExtension__c',
			value: '0.0',
		},
		{
			name: 'VGED_MDMSyncStatus__c',
			value: 'Synced',
		},
		{
			name: 'VGED_NumOfRetriesMDM__c',
			value: 'null',
		},
		{
			name: 'VGED_TechnicalMdmStatus__c',
			value: 'null',
		},
		{
			name: 'VGED_IsGVF__c',
			value: 'false',
		},
	];
};
