import { Spinner, Text, styled } from '@volkswagen-onehub/components-core';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureServices, useTrackingManager } from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { CarlineBase, DealersData, IdLead, IdOrder, IdPerson } from 'src/types';
import { CEMOneShopForm } from './CEMOneShopForm';
import { GenericErrorLayer } from 'src/components/layers';
import { checkIdLeadHasCarline } from './use-initialize-lem-form';
import { createPincodeEntity } from '../../dps-utils';
import { LoadingEncuestas } from 'src/components';

export interface CEMOneShopFormProps {
  idLead: IdLead;
  idPerson: IdPerson;
  idOrder?: IdOrder;
  dpsData: DpsData;
  handleError: () => void;
  trackFormLoad: () => void;
  showPantallaModelo: boolean;
}

export function CEMOneShopController() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [usado, setUsado] = useState(false);
  const [showPantallaModelo, setShowPantallaModelo] = useState<boolean>(false);
  const [caducado, setCaducado] = useState(false);
  const today = dayjs(new Date());
  const trackingManager = useTrackingManager();
  const dpsDataRef = useRef(null);
  const idLeadRef = useRef(null);
  const idPersonRef = useRef(null);
  const idOrderRef = useRef(null);
  const dispatch = useDispatch();
  const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

  const handleNext = (value: any) => { };
  const handleComplete = () => {
    const dpsData = oneFormService.getDpsData();

    if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
      handleError();
    } else if (dpsData.pincode.pincodeUsed) {
      setUsado(true);
      setLoading(false);
    } else {
      const idLead = createPincodeEntity(dpsData, 'LEAD');
      const idPerson = createPincodeEntity(dpsData, 'PERSON');
      const idOrder = createPincodeEntity(dpsData, 'ORDER');
      const formDate = dayjs(dpsData.env);

      dpsDataRef.current = dpsData;
      idLeadRef.current = idLead;
      idPersonRef.current = idPerson;
      idOrderRef.current = idOrder;

      if (!dpsData.env || today.diff(formDate, 'day') > 5) {
        setCaducado(true);
      }

      setLoading(false);
    }
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  const trackFormLoad = () => {
    if (idLeadRef.current) {
      const customDealer: DealersData = {
        kvps: idLeadRef.current.TXT_DESC_DEALER_CODE__c,
        name: idLeadRef.current.DESC_Dealer_Code__c,
        markerInfo: { dealerInfo: { zipCode: idLeadRef.current.TXT_Postal_Code__c } },
      };
      dispatch({ type: 'SET_SELECTED_DEALER', payload: customDealer });

      if (checkIdLeadHasCarline(idLeadRef.current)) {
        setShowPantallaModelo(true);

        const carline: Partial<CarlineBase> = {
          title: idLeadRef.current.DESC_Model__c,
          code: Number(idLeadRef.current.NUM_COD_INTEREST_CARLINE__c),
        };

        dispatch({ type: 'SET_SELECTED_CARLINE', payload: carline });
      }
    }

    trackingManager.trackFormLoad();
  };

  useEffect(() => {
    const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

    oneFormCoreDpsIsLoaded.subscribe({
      next(value) {
        handleNext(value);
      },
      error(err) {
        console.error(err);
        handleError();
      },
      complete() {
        handleComplete();
      },
    });
  }, []);

  // función para layer de error
  const handleTryAgain = async () => {
    // oneFormService.
    setError(false);
    setLoading(true);
    await oneFormService.retryPincodeLoad();
    handleComplete();
  };

  if (loading) {
    return <LoadingEncuestas />;
  } else if (error) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, algo ha pasado"
          bodycopy="Por alguna razón no hemos podido cargar el cuestionario."
          ctaLayer="Reintentar"
          notShowCTA={true}
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else if (usado) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, ya has respondido a este cuestionario"
          bodycopy="Parece que ya nos has contado cómo fue tu experiencia. ¿Quieres añadir algo más?"
          ctaLayer="Contacta con nosotros"
          ctaType="a"
          urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else if (caducado) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, este formulario ha caducado"
          bodycopy="Parece que el cuestionario ya no está disponible. ¿Quieres contarnos cómo ha sido tu experiencia?"
          ctaLayer="Contacta con nosotros"
          ctaType="a"
          urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
          icon={false}
          notALayer={true}
        />
      </>
    )
  }
  else {
    return (
      <>

        <CEMOneShopForm
          dpsData={dpsDataRef.current}
          idLead={idLeadRef.current}
          idPerson={idPersonRef.current}
          idOrder={idOrderRef.current}
          handleError={handleError}
          trackFormLoad={trackFormLoad}
          showPantallaModelo={showPantallaModelo}
        />

      </>
    );
  }
}
