import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCarlines } from 'src/bff';
import { useGetInstalaciones } from 'src/feature-app';
import { IdLead, Steps } from 'src/types';

const getLastItemOfSteps = (steps: Steps[]) => steps[steps.length - 1];

const propertyHasValue = (property: string) => property !== 'null';

export const checkHasCarlineNoRenovacion = (idLead: IdLead) =>
  idLead &&
  idLead.NUM_COD_INTEREST_CARLINE__c &&
  idLead.DESC_Model__c &&
  propertyHasValue(idLead.NUM_COD_INTEREST_CARLINE__c) &&
  propertyHasValue(idLead.DESC_Model__c);

export function useInitializeNoRenovacionForm(steps: Steps[]) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const installations = useGetInstalaciones('VW_DDB_FORM_NORENUEVA', 'VOLKSWAGEN_SF_INSTALLATION_ALL'); // llama al metodo getInstallations()

  useEffect(() => {
    const requestCarlines = async () => {
      const carlines = await getCarlines();

      dispatch({ type: 'SET_CARLINES', payload: carlines });
      setLoading(false);
    };

    requestCarlines();
  }, []);

  return { firstStep: steps[0], lastStep: getLastItemOfSteps(steps), loading };
}
