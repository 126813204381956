import axios, { AxiosResponse } from 'axios';
import { Color } from 'src';
import { getDataUrlWithoutTenant, reportErrorSentry } from 'src/bff';

interface ColoresRequest {
  colores: Color[];
}

export const getColors = async () => {
  const dataUrl = getDataUrlWithoutTenant();

  try {
    const response: AxiosResponse<ColoresRequest> = await axios.get(`${dataUrl}/getColores`);
    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};
