import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerPadding,
  CTA,
  styled,
  Text,
  TextAlignment,
  Layout,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { LegalesFormatoLayer, LssiTkmQuestions } from 'src/components';
import {
  CloseHandleV2,
  FocusLayerSizeV2,
  LayerHandleV2,
  LayerManagerV2
} from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch, useStore } from 'react-redux';
import {
  LayerManagerWrapper,
  useFeatureAppEnvironment,
  useFeatureServices,
  useOneFormContext,
  useTrackingManager,
} from 'src/feature-app';
import { OneFormState } from 'src/types';
import { KoScreen, LoadingScreen } from 'src/forms/CitaPosventa/NuevaCitaPosventa/ConfirmarCitaLayer';

const GeneralconfirmacionWrapper = styled.div`

`;

const WhiteLinkWrapper = styled.span`
  a {
    font-weight: bold;
  }
`;

function ConfirmacionEurocopa() {
  const dispatch = useDispatch();

  const [lssi, setLssi] = useState<string>(null);
  const [tmk, setTmk] = useState<string>(null);
  const [lssiError, setLssiError] = useState<boolean>(false);
  const [tmkError, setTmkError] = useState<boolean>(false);

  useEffect(() => {
    if (lssi) {
      const values = { lssi: lssi };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setLssiError(false);
    }
    if (tmk) {
      const values = { tmk: tmk };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setTmkError(false);
    }
  }, [lssi, tmk]);

  return (
    <GeneralconfirmacionWrapper>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
          [Breakpoints.b1280]: [
            { name: '.', columns: 5 },
            { name: 'a', columns: 14 },
            { name: '.', columns: 5 },
          ],
          [Breakpoints.b1920]: [
            { name: '.', columns: 6 },
            { name: 'a', columns: 12 },
            { name: '.', columns: 6 },
          ],
          [Breakpoints.b2560]: [
            { name: '.', columns: 7 },
            { name: 'a', columns: 10 },
            { name: '.', columns: 7 },
          ],
        }}
        allowOverflowingContent
      >
        <Text appearance={TokenTextAppearance.headline300}>
          ¡Gracias! Unas{' '}<Text bold>últimas preguntas</Text>{' '} para tenerlo todo claro
        </Text>
        <LssiTkmQuestions
          setTmk={setTmk}
          tmkError={tmkError}
          setLssi={setLssi}
          lssiError={lssiError}
          title={false}
          themeColor='main'
        />
        <Container
          padding={{ bottom: ContainerPadding.static500 }}
          horizontalAlign={'flex-start'}
        >
          <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left} staticSize>
            Enviando este formulario declaras estar informado de la{' '}
            <b>
              <CTA
                tag="a"
                href="https://www.volkswagen.es/es/volkswagen-espana/datos-privacidad.html"
                target="_blank"
                emphasis="tertiary"
              >
                política de privacidad.
              </CTA>
            </b>
          </Text>
        </Container>

        <div
          className="last-navigation-position"
          style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}
        >
          <LastNavigation tmk={tmk} lssi={lssi} setTmkError={setTmkError} setLssiError={setLssiError} />
        </div>
      </Layout>
    </GeneralconfirmacionWrapper>
  );
}

interface LastNavigationProps {
  tmk: string;
  lssi: string;
  setTmkError: React.Dispatch<React.SetStateAction<boolean>>;
  setLssiError: React.Dispatch<React.SetStateAction<boolean>>;
}

function LastNavigation(props: LastNavigationProps) {
  const { tmk, lssi, setTmkError, setLssiError } = props;
  // const dispatch = useDispatch();
  const { handleShowFinalScreen, handleSubmit } = useOneFormContext();
  const { reset } = useFormContext();
  const [layerVisible, setLayerVisible] = useState<boolean>(false);
  const [koLayer, setKoLayer] = useState<LayerHandleV2<any>>(null);
  const [koScreen, setKoScreen] = useState<boolean>(false);
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const dispatch = useDispatch();
  const trackingManager = useTrackingManager();

  //Loading and Ko layers

  const handleKoLayer = () => {
    const layer = layerManager.openFocusLayer(renderKoLayer, {}, { size: FocusLayerSizeV2.A });
    setKoLayer(layer);
  };

  const renderKoLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <KoScreen retrySubmit={retrySubmit} closeLayerCallback={close} hideSecondCTA={true}/>
      </LayerManagerWrapper>
    );
  };

  const handleLoadingScreenLayer = () => {
    const layer = layerManager.openFocusLayer(renderLoadingScreen, {}, { size: FocusLayerSizeV2.A });

    return layer;
  };

  const renderLoadingScreen = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingScreen isleads copy='Enviando tu respuesta'/>
      </LayerManagerWrapper>
    );
  };

  // 1. check de lssi y tmk
  // 2. submit de la cita y activación la pantalla de feedback correspondiente
  // no se hace ninguna validación en este último paso porque esta ya se ha hecho en el edit-guardar de cada campo previamente
  async function handleConfirmarCita(e: any) {
    if (tmk === null && tmk === null) {
      setTmkError(true);
      setLssiError(true);
    } else if (tmk === null) {
      setTmkError(true);
    } else if (lssi === null) {
      setLssiError(true);
    } else {
      trackingManager.trackFormCTAClick({
        contentId: 'Enviar',
      });

      const loadingLayer = handleLoadingScreenLayer();
      setLayerVisible(true);

      const value = await handleSubmit(e);
      loadingLayer.close();

      if (value) {
        handleShowFinalScreen();
      } else {
        handleKoLayer();
      }
    }
  }
  async function retrySubmit(e: any) {
    if (koLayer) {
      koLayer.close();
    }
    setKoScreen(false);
    const loadingLayer = handleLoadingScreenLayer();
    const value = await handleSubmit(e);
    loadingLayer.close();
    if (value) {
      handleShowFinalScreen();
    } else {
      handleKoLayer();
      setKoScreen(true);
    }
  }

  useEffect(() => {
    if (!layerVisible && koScreen) setKoScreen(false);
  }, [layerVisible]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FORM_ENDED', payload: true });
  }, []);

  return (
    <>
      <BreakpointWrapper max={Breakpoints.b560}>
        <CTA
          tag="button"
          type="submit"
          emphasis="primary"
          onClick={(e) => {
            e.preventDefault();
            handleConfirmarCita(e);
          }}
          stretchContent
        >
          Enviar
        </CTA>
      </BreakpointWrapper>
      <BreakpointWrapper min={Breakpoints.b560}>
        <CTA
          tag="button"
          type="submit"
          emphasis="primary"
          onClick={(e) => {
            e.preventDefault();
            handleConfirmarCita(e);
          }}
        >
          Enviar
        </CTA>
      </BreakpointWrapper>
    </>
  );
}


const mapStateToProps = (state: OneFormState) => {
  return {
    formInfo: state.formInfo,
    formData: state.formData,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ConfirmacionEurocopa);

export { ConnectedComponent as ConfirmacionEurocopa };
