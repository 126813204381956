import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDealerKvps } from 'src/bff/api/get-dealer-kvps';

export function useGetDealer() {
  const dispatch = useDispatch();
  const [dealerName, setDealerName] = useState(null);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!window.location) {
      setLoading(false);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const dealer = urlParams.get('dealer');
    if (!dealer) {
      setLoading(false);
      return;
    }
    getDealerKvps(dealer)
      .then((selectedDealer) => {
        if (selectedDealer) {
          setDealerName(selectedDealer.name);
          setSelectedDealer(selectedDealer);
          dispatch({ type: 'SET_SELECTED_DEALER', payload: selectedDealer });
          dispatch({ type: 'SET_URL_DEALER', payload: selectedDealer });
          dispatch({ type: 'UPDATE_SUGGESTION', payload: selectedDealer });
          const { longitude, latitude } = selectedDealer.markerInfo.svcLocation;
          const point = {
            type: 'Feature',
            properties: { cluster: false, concesionId: selectedDealer.id, dealer: selectedDealer },
            geometry: {
              type: 'Point',
              coordinates: [longitude, latitude],
            },
          };
          const points = [point];
          dispatch({ type: 'SET_DEALERS', payload: [selectedDealer] });
          dispatch({ type: 'SET_POINTS', payload: points });
          dispatch({ type: 'DEALERS_OR_INSTALLATIONS_MODE', payload: 'dealers' });
        } else {
          setDealerName(null);
          setSelectedDealer(null);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return { dealerName, selectedDealer, loading };
}
