import { OneFormContent } from 'src';
import { appendJsonKey } from 'src/forms';
import { getFeatures, getMarketingCode, getModelFilter, getOption } from 'src/bff/get-marketing-code';
import {
  DealersInfo,
  FormDataGroup,
  HighlightsReturn,
  LuggageReturn,
  MatchSelectedOptions,
  Offer,
  PassengersTypes,
  RankedPreconfiguration,
  UsageReturn,
} from 'src/types';

const getMatchHash = () => {
  const searchParams = new URLSearchParams(window?.location?.search);
  const hash = searchParams.get('match-app');
  return hash;
};

export const isMatchForm = (formName: string) => formName === 'lead-match' || formName === 'prospect-match';

export const getMatchData = async (formData: FormDataGroup, dealersInfo: DealersInfo, faContent: OneFormContent) => {
  const { fields } = formData;
  const { match } = dealersInfo;

  if (!isMatchForm(fields.formName)) {
    return null;
  } else {
    const { response: marketingCodeResponse } = await getMarketingCode(match.marketingCode);

    const salesgroup = getOption("SALESGROUP",marketingCodeResponse?.content.consolidatedData.options);
    const type = getOption("TYPE",marketingCodeResponse?.content.consolidatedData.options);
    const baseType = getOption("BASETYPE",marketingCodeResponse?.content.consolidatedData.options);
    const equipmentLine = getModelFilter("EquipmentLine",marketingCodeResponse?.content.consolidatedData.meta.modelFilter);

    const matchFields = {
      ...marketingCodeFields,
      // 'fields[COD_INTEREST_CARLINE]': match?.carlineCode,
      // 'fields[DESC_INTEREST_CARLINE]': match?.carlineTitle,
      // 'fields[DESC_FINANCIAL_PRODUCT]': match?.financiacionText?.slice(4),
      // 'fields[DESC_VEH_MARKETING_CODE]': match?.marketingCode,
      // 'fields[DESC_VEH_OFFER_PRICE]': createOfferPrice(match),
      // 'fields[COD_INTEREST_PR]': getFeatures(response?.consolidatedData?.features),
      'fields[CREATIVITY_PERSONALIZATION_DATA]': getMatchHash(),
      'fields[MATCH_AFFINITY]': match?.affinity,
      'fields[MATCH_PRICE]': match?.precio,
      'fields[MATCH_DGT_BADGE]': match?.etiquetaDGT.toUpperCase(),
      'fields[MATCH_USER_JOURNEY]': fields?.argumentatives,
    };

    const matchOptions = getMatchOptions(faContent?.matchOptions);

    return { ...matchFields, ...matchOptions };
  }
};



const getMatchOptions = (options: MatchSelectedOptions) => {
  if (!options) {
    return null;
  }

  return {
    'fields[MATCH_BUDGET]': options.budget,
    'fields[MATCH_COMPANION]': appendJsonKey(getPassengers(options.passengers)),
    'fields[MATCH_TRIP]': appendJsonKey(getUsage(options.usage)),
    'fields[MATCH_BAGGAGE]': appendJsonKey(getLuggage(options.luggage)),
    'fields[MATCH_CAR_CHARACTERISTICS]': appendJsonKey(getHighlights(options.highlights)),
    'fields[MATCH_ENGINE]': options.fuel,
  };
};

const getPassengers = (passengers: PassengersTypes) => {
  if (!Array.isArray(passengers)) {
    return { adult: 0, kid: 0 };
  }
  return { adult: passengers[0], kids: passengers[1] };
};

const getLuggage = (lugagge: number[]): string[] => {
  const lugaggeOptions: LuggageReturn[] = [
    'unaMaleta',
    'escapada',
    'unaSemana',
    'unMes',
    'mascota',
    'bici',
    'surf',
    'snow',
  ];
  const [unaMaleta, escapada, unaSemana, unMes, mascota, bici, surf, snow] = lugagge;
  const options = { unaMaleta, escapada, unaSemana, unMes, mascota, bici, surf, snow };

  return lugaggeOptions.filter((option) => options[option] === 1);
};

const getUsage = (usage: number[]): string[] => {
  const usageOptions: UsageReturn[] = ['urban', 'interurban', 'long', 'allterrain'];
  const [urban, interurban, long, allterrain] = usage;
  const options = { urban, interurban, long, allterrain };

  return usageOptions.filter((option) => options[option] === 1);
};

const getHighlights = (highlights: number[]): string[] => {
  const hihglightsOptions: HighlightsReturn[] = [
    'atrevido',
    'comodo',
    'deportivo',
    'sostenible',
    'elegante',
    'seguro',
    'sonido',
    'tecnologico',
  ];
  const [atrevido, comodo, deportivo, sostenible, elegante, seguro, sonido, tecnologico] = highlights;
  const options = { atrevido, comodo, deportivo, sostenible, elegante, seguro, sonido, tecnologico };

  return hihglightsOptions.filter((option) => options[option] === 1);
};

const createOfferPrice = (match: RankedPreconfiguration) => {
  return `Precio final de la configuración: ${match.precio}Eur`;
};
