import { OneFormContent } from 'src/feature-app';

export const thereIsKeyInFaContent = (faContent: OneFormContent, key: 'carlines' | 'salesgroups') =>
faContent && faContent[key] && faContent[key].length > 0;

export const thereIsCarlinesInFaContent = (faContent: OneFormContent) => thereIsKeyInFaContent(faContent, 'carlines');

export const thereIsSalesgroupsInFaContent = (faContent: OneFormContent) => thereIsKeyInFaContent(faContent, 'salesgroups');

export const isCarlineMode = (faContent: OneFormContent) => faContent?.mode === 'Carline';

export const isSalesGroupMode = (faContent: OneFormContent) => faContent?.mode === 'Salesgroup';

export const isMixedMode = (faContent: OneFormContent) => faContent?.mode === 'Mixed';

export const isConfiguratorMode = (faContent: OneFormContent) => faContent?.mode === 'CarConfiguratorFS';
