import React, { useEffect } from 'react';
import { Text } from '@volkswagen-onehub/components-core';
import { OneElementTemplate } from 'src/feature-app/Screen';
import { Email, PantallaNombreApellido, DetalleMotivo, Telefono, MultipleCTASelection, ContactInfoWrapper } from 'src/components';
import { PantallaMotivoSAC, ConfirmacionSAC, ThankYouSAC } from '.';
import { getCommonFormData } from './FormData';
import { useStore, useDispatch } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import {
  FormController,
  removeWhiteSpace,
  useOneFormContext,
  formatPrefix,
} from 'src/feature-app';
import { formDataBuilder } from '../format-fields';
import { contactoOptionsSAC } from 'src/feature-app/InputsValues/CTAsObjects';

export enum SACFormSteps {
  Motivo,
  MasInformacion,
  NombreApellido,
  EmailTelefono,
  Contacto,
  Confirmacion,
}

export const SACForm = () => {
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const dispatch = useDispatch();

  const steps: Steps[] = [
    {
      title: (
        <>
          <Text bold>¿Cómo</Text> podemos ayudarte?
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <div style={{ marginBottom: '2px' }}>
              <PantallaMotivoSAC />
            </div>
          }
        />
      ),
      screenIndex: SACFormSteps.Motivo,
      name: 'motivoSac',
      outputs: ['motivoSac'],
      outputsText: ['motivoSac'],
    },
    {
      title: (
        <>
          <Text bold>¿Podrías darnos más detalles</Text> sobre tu{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            petición?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: <OneElementTemplate element={<DetalleMotivo hideCTA={true} numberOfMaxLength={1500} required={true} />} />,
      screenIndex: SACFormSteps.MasInformacion,
      name: 'MasInformacion',
      outputs: ['masInformacion'],
      outputsText: ['masInformacion'],
    },
    {
      title: (
        <>
          Para responderte, nos gustaría saber <Text bold>quién eres</Text>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <div style={{ marginBottom: '2px' }}>
              <PantallaNombreApellido />
            </div>
          }
        />
      ),
      screenIndex: SACFormSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      title: (
        <>
          <Text bold>¿En qué teléfono e email </Text>podemos localizarte?
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <ContactInfoWrapper>
              <Telefono paginaConfirmacion={false} required={true} />
              <Email paginaConfirmacion={false} required={true} paddingTop='24px'/>
            </ContactInfoWrapper>
          }
        />
      ),
      screenIndex: SACFormSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      title: (
        <>
          <Text bold>¿Cómo prefieres</Text> que te{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            respondamos?
            <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplate
          element={<MultipleCTASelection ctaValues={contactoOptionsSAC} name="contactoSAC" nextOnClick />}
        />
      ),
      screenIndex: SACFormSteps.Contacto,
      name: 'contactoSAC',
      outputs: ['contactoSAC'],
      outputsText: ['contactoSAC'],
    },
    {
      fields: <ConfirmacionSAC />,
      screenIndex: SACFormSteps.Confirmacion,
      name: 'Confirmacion',
      isLastStep: true,
      hidePreviousStep: true,
    },
  ];

  const SACFormData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_VN_VO: 'VN',
      DESC_GDPR_ADAPTED: 'true',
    },
    fields: { formName: 'sac', formType: 'otherform', pageCategory: 'Atención al cliente' }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData }: OneFormState = store.getState();

    const { fields } = formData;

    return new Promise<any>(async (resolve, reject) => {

      const data = {
        ...getCommonFormData(fields),
        'fields[COMMENTS_PERSON]': fields.masInformacion,
      };
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData);

      if (response && response.data && response.data.content && response.data?.status?.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const SACFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      formType: 'layer',
      formTheme: 'inverted',
      notALayer: false,
      thankYouComponent: <ThankYouSAC />,
    };

    initializeForm(SACFormInfo, SACFormData);
  }, []);

  return <FormController steps={steps} screenType="layer-screen" />;
};
