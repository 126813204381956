import {
  Breakpoints,
  BreakpointWrapper,
  CTA,
  Layout,
  styled,
  Text,
  ThemeProvider,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { InputError } from 'src/components';
import React, { useState } from 'react';
import { TriggerProps, useFaContent } from 'src/feature-app';
import { NavidadForm } from 'src/forms/FormsAdhoc/NavidadForm/NavidadForm';
import { SobreDesktop, SobreMobile, CircleCheck, Sobre2560Desktop } from 'src/forms/FormsAdhoc/NavidadForm/assets';
import { Edit } from 'src/icons-core-imports';

const Content = styled.div`
  overflow: hidden;
  background-color: #001e50;
  position: relative;
  padding-top: 66px;

  @media all and (min-width: 960px) {
    padding-top: 114px;
    padding-bottom: 108px;
  }

  & > div {
    width: 100%;
  }
`;

const BorderSection = styled.div<{ backColor: string }>`
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  background-color: ${(props) => (props.backColor ? props.backColor : '#FFFFFF')};
  min-height: 30px;
  min-width: 44px;
`;

const DisplayBorder = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  width: fit-content;
  gap: 22px;
  transform: translateX(-33px);
`;
const CenterCTA = styled.div`
  text-align: center;
  padding-bottom: 26px;
  @media all and (min-width: 960px) {
    text-align: left;
    padding-top: 32px;
    padding-bottom: 0;
  }
  @media all and (min-width: 1600px) {
    text-align: left;
    padding-top: 54px;
  }
  button {
    background-color: #00deff;
    border: none;
    font-weight: 700;
  }
`;

const SobrePosition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const FakeInputWrapper = styled.div`
  border: 2px solid #00deff;
  padding: 8px;
  padding-right: 12px;
  color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  p {
    font-size: 18px;
    line-height: 32px;
    font-weight: 700;
    margin: 0;
  }
  svg {
    height: 22px;
    width: 22px;
  }
`;

const CheckWrapper = styled.div`
  padding-right: 14px;
  margin-right: 8px;
  height: 28px;
  svg {
    color: #00deff;
  }
`;

//Tamaños tipo y containers

const TipoDos = styled.span`
  font-size: 38px;
  line-height: 42px;
  width: 100%;

  @media all and (min-width: 960px) {
    font-size: 44px;
    line-height: 56px;
  }
`;

const ContainerTitle = styled.div`
  padding-top: 54px;
  padding-bottom: 64px;

  @media all and (min-width: 960px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const InputTitleWrapper = styled.div`
  padding-bottom: 44px;
  @media all and (min-width: 1600px) {
    padding-bottom: 56px;
  }
  @media all and (min-width: 1600px) {
    padding-bottom: 36px;
  }
  @media all and (min-width: 1920px) {
    padding-bottom: 56px;
  }
`;
const BulletLine = styled.div`
  width: 20px;
  min-width: 20px;
  height: 2px;
  background-color: #ffffff;
  margin: 15px 0;
`;
const ListItemWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-left: 20px;
`;

const formatListElement = (value: string) => {
  if (!value || value === '') {
    return null;
  }
  return value;
};

export const NavidadTrigger = (props: TriggerProps) => {
  const { setSelectedJourney, setCTALabel } = props;

  const [listUno, setListUno] = useState(null);
  const [listDos, setListDos] = useState(null);
  const [listTres, setListTres] = useState(null);
  const [error, setError] = useState(false);

  const navidadImgSrc = useFaContent()?.navidadImgSrc;

  const renderForm = () => {
    const wishlist = `Un fin de semana con un ID.4, ${formatListElement(listUno)}, ${formatListElement(
      listDos
    )}, ${formatListElement(listTres)}`;
    setCTALabel('Enviar la carta');
    setSelectedJourney(<NavidadForm updatedFormData={{ fields: { wishlist } }} />);
  };

  return (
    <ThemeProvider theme="inverted">
      <div>
        <Border />
        <Content>
          <BreakpointWrapper max={Breakpoints.b560}>
            <SobrePosition className="sobre-position">
              <SobreMobile />
            </SobrePosition>
          </BreakpointWrapper>
          <BreakpointWrapper min={Breakpoints.b560} max={Breakpoints.b2560}>
            <SobrePosition className="sobre-position">
              <SobreDesktop />
            </SobrePosition>
          </BreakpointWrapper>
          <BreakpointWrapper min={Breakpoints.b2560}>
            <SobrePosition className="sobre-position">
              <Sobre2560Desktop />
            </SobrePosition>
          </BreakpointWrapper>
          <div style={{ position: 'relative', maxWidth: '1920px', margin: '0 auto' }}>
            <Layout
              appearance={{
                [Breakpoints.default]: [
                  { name: '.', columns: 2 },
                  { name: 'a', columns: 20 },
                  { name: '.', columns: 2 },
                  { name: '.', columns: 2 },
                  { name: 'b', columns: 20 },
                  { name: '.', columns: 2 },
                ],
                [Breakpoints.default]: [
                  { name: '.', columns: 3 },
                  { name: 'a', columns: 18 },
                  { name: '.', columns: 3 },
                  { name: '.', columns: 3 },
                  { name: 'b', columns: 18 },
                  { name: '.', columns: 3 },
                ],
                [Breakpoints.b960]: [
                  { name: '.', columns: 2 },
                  { name: 'a', columns: 8 },
                  { name: '.', columns: 2 },
                  { name: 'b', columns: 10 },
                  { name: '.', columns: 2 },
                ],
                [Breakpoints.b1280]: [
                  { name: '.', columns: 3 },
                  { name: 'a', columns: 7 },
                  { name: '.', columns: 2 },
                  { name: 'b', columns: 9 },
                  { name: '.', columns: 3 },
                ],
                [Breakpoints.b1600]: [
                  { name: '.', columns: 3 },
                  { name: 'a', columns: 7 },
                  { name: '.', columns: 2 },
                  { name: 'b', columns: 9 },
                  { name: '.', columns: 3 },
                ],
                [Breakpoints.b1920]: [
                  { name: '.', columns: 4 },
                  { name: 'a', columns: 6 },
                  { name: '.', columns: 3 },
                  { name: 'b', columns: 7 },
                  { name: '.', columns: 4 },
                ],
              }}
            >
              <div>
                <InputTitleWrapper>
                  <Text appearance={TokenTextAppearance.copy200} staticSize>
                    Escribe tu carta y pide lo que más ilusión te haga. <Text bold>¡El ID.4 lo ponemos nosotros! </Text>
                    Escribe tu lista de deseos…
                  </Text>
                </InputTitleWrapper>
                <div style={{ width: '100%' }}>
                  <FakeInputWrapper>
                    <CheckWrapper>
                      <CircleCheck />
                    </CheckWrapper>
                    <p>Un fin de semana con un ID.4</p>
                  </FakeInputWrapper>
                  <Inputs placeholder="Un curso Drive Experience" setList={setListUno} error={error}/>
                  {
                    error ? <InputError maxWidth='100%'>Falta añadir tus deseos, ¿vas a dejarlos?</InputError> : null
                  }
                  <Inputs placeholder="Un ukelele soprano" setList={setListDos} />
                  <Inputs placeholder="Unos guantes con función táctil" setList={setListTres} />
                </div>
              </div>

              <ContainerTitle>
                <div style={{ width: '100%' }}>
                  <div style={{ width: '100%', paddingBottom: '20px' }}>
                    <Text appearance={TokenTextAppearance.headline400}>
                      <TipoDos>Y ahora envíasela </TipoDos>
                      <Text bold>
                        <TipoDos>a los tuyos</TipoDos>
                      </Text>
                    </Text>
                  </div>

                  <BreakpointWrapper max={Breakpoints.b960}>
                    <CenterCTA>
                      <CTA
                        tag="button"
                        onClick={() => {
                          if(listUno === null || listUno ==='') {
                            setError(true);
                          } else {
                            setError(false);
                            renderForm();
                          } 
                        }}
                        stretchContent
                      >
                        <b>Quiero participar</b>
                      </CTA>
                    </CenterCTA>
                  </BreakpointWrapper>

                  <Text appearance={TokenTextAppearance.copy200} staticSize>
                    ¡Nosotros ya sabemos lo que quieres! Ahora díselo a tus seres queridos y participa en un sorteo
                    <sup>1</sup> y gana:
                  </Text>
                  <div style={{ marginTop: '20px' }}>
                    <ListItemWrapper>
                      <BulletLine />
                      <Text appearance={TokenTextAppearance.copy200} staticSize>
                        Una experiencia de fin de semana con un ID.4
                      </Text>
                    </ListItemWrapper>
                    <ListItemWrapper>
                      <BulletLine />
                      <Text appearance={TokenTextAppearance.copy200} staticSize>
                        Una escapada de hotel con esa persona especial
                      </Text>
                    </ListItemWrapper>
                    <ListItemWrapper>
                      <BulletLine />
                      <Text appearance={TokenTextAppearance.copy200} staticSize>
                        Un bono de 100€ de carga para que la energía no pare
                      </Text>
                    </ListItemWrapper>
                    <ListItemWrapper>
                      <BulletLine />
                      <Text appearance={TokenTextAppearance.copy200} staticSize>
                        Y hacer realidad tus deseos con una tarjeta regalo de 300€ en Volkswagen Store
                      </Text>
                    </ListItemWrapper>
                  </div>
                </div>

                <BreakpointWrapper min={Breakpoints.b960}>
                  <CenterCTA>
                    <CTA
                      tag="button"
                      onClick={() => {
                        if(listUno === null || listUno ==='') {
                          setError(true);
                        } else {
                          setError(false);
                          renderForm();
                        } 
                      }}
                    >
                      <b style={{ padding: '0 36px' }}>Quiero participar</b>
                    </CTA>
                  </CenterCTA>
                </BreakpointWrapper>
              </ContainerTitle>
            </Layout>
          </div>
        </Content>
      </div>
    </ThemeProvider>
  );
};

const InputTextWrapper = styled.div<{error:  boolean}>`
  border: ${(props)=> props.error ? '2px solid #e4002c' : '2px solid #ffffff'};
  padding: 8px;
  display: flex;
  width: 100%;
  margin-top: 30px;
  align-items: center;
`;

const InputText = styled.input`
  border: none;
  background-color: transparent;
  all: unset;
  width: 100%;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;

  #placeholder {
    color: rgba(255, 255, 255, 0.46);
  }
`;
const IconWrapper = styled.div`
  padding-right: 14px;
  margin-right: 8px;
  border-right: 1px solid #ffffff;
  height: 28px;
  svg {
    color: #ffffff;
  }
`;
interface InputsProps {
  placeholder: string;
  setList: any;
  error?: boolean;
}

const Inputs = (props: InputsProps) => {

  return (
    <InputTextWrapper className="input-text-wrapper" error={props.error}>
      <IconWrapper>
        <Edit />
      </IconWrapper>
      <InputText 
        type="text" 
        placeholder={props.placeholder} 
        onChange={(e) => props.setList(e.target.value)} 
      />
    </InputTextWrapper>
  );
};
const Border = () => {
  return (
    <div style={{ width: '100vw', overflowX: 'hidden' }}>
      <DisplayBorder className="borde-sobre">
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
        <BorderSection backColor="#178CB9" />
        <BorderSection backColor="#FE2C64" />
      </DisplayBorder>
    </div>
  );
};
