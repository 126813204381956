import React, { useState } from 'react';
import { RadioButton } from '@volkswagen-onehub/components-core';
import { InputButton } from 'src/components/inputs/InputButton';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { OneFormState } from 'src/types';

interface ChecksProps {
  questionName: string;
  avoidNextStep?: boolean;
  answers: string[];
  skippable: boolean;
}

export const Checks = (props: ChecksProps) => {
  const { questionName, answers, skippable} = props;
  const [checkedIndex, setCheckedIndex] = useState(null);
  const dispatch = useDispatch();

  const { handleNextStep } = useOneFormContext();
  const [value, setValue] = useState(null);
  const { register } = useFormContext();

  const handleOnClick = (e: any) => {
    setTimeout(() => {
      dispatch({ type: 'UPDATE_FIELDS', payload: { [questionName]: e.target.value } });
      handleNextStep();
    }, 0);
  };

  return (
    <div style={{ paddingTop: '42px' }}>
      {answers.map((answer, index) => {
        return (
          <div style={{ paddingBottom: '20px', paddingLeft: '12px' }}>
            <RadioButton
              value={answer}
              label={answer}
              name={questionName}
              onClick={(e) => {
                setValue(answer);
                setCheckedIndex(index);
                handleOnClick(e);
              }}
              checked={checkedIndex === index}
            />
          </div>
        )
      })}

      {
        skippable ? (
          <div style={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '12px' }}>
            <InputButton
              tag="button"
              name={questionName}
              label={'No, eso es todo'}
              innerRef={register({})}
              key={10}
              onClick={(e) => {
                setValue("No, eso es todo");
                handleOnClick(e);
              }}
            />
          </div>
        ) : null
      }

      <input
        type="hidden"
        name={questionName}
        value={value}
        ref={register({
          required: {
            value: true,
            message: 'Requerido',
          },
        })}
      />
    </div>
  );
};
