import { FormDataGroup } from 'src/types';

export const partialDataCitaConfigurador: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Cita' },
  fields: {
    formFields: { M_OBSERVATION_BRAND: 'Petición para reservar una cita en el punto de venta.' },
    formularioFlag: 'prospect',
    formName: 'lead-cita-configure',
    formType: 'contact',
    pageCategory: 'Cita',
  },
};
