import { styled } from '@volkswagen-onehub/components-core';

type InputErrorProps = {
  relative?: boolean;
  maxWidth?: string;
};

export const InputError = styled.p<InputErrorProps>`
  position: relative;
  margin-top: 6px;
  background-color: #e4002c;
  color: #FFFFFF;
  /* width: max-content; */
  width: 100%;
  max-width: ${(props) => props.maxWidth ? props.maxWidth : '280px'};
  padding: 4px 8px;
  min-height: 24px;
  border-radius: 4px;
  font-size: 12px;
  text-align: left;
  @media all and (min-width: 560px) {
    max-width: ${(props) => props.maxWidth ? props.maxWidth : '303px'};
  }
  @media all and (min-width: 960px) {
    max-width: ${(props) => props.maxWidth ? props.maxWidth : '320px'};
  }
`;
