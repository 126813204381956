import { useState, useEffect } from 'react';
import { useWindowHeight, useWindowWidth} from '@react-hook/window-size';

export const useDropdowPosition = (identifier: string) => {

  const windowHeight = useWindowHeight();
  const windowWidth = useWindowWidth();

  const [ boundingObject, setBoundingObject ] = useState(null);
  const [ bottom, setBottom ] = useState(null);
  const [ top, setTop ] = useState(null);
  const [ left, setLeft ] = useState(null);
  const [ right, setRight ] = useState(null);
  const [ goUp, setGoUp ] = useState(false);

  //boundingObject contiene varias keys, bottom hace referencia a la distancia entre el bottom del elemento y el top del window

  const getBoundingObject = () => { 
    const {top, right, bottom, left, width, height, x, y} =  document.querySelector(identifier).getBoundingClientRect();
    //la librería de autosuggest no permite poner id, por eso el querySelector
    setBoundingObject({top, right, bottom, left, width, height, x, y});
    setBottom(bottom);
    setTop(top);
    setLeft(left);
    setRight(right);
  };  

  const getAndUpdateState = () => {
    getBoundingObject();
  };

  useEffect(()=>{
    const distanciaDeCambio = windowHeight/4 > 150 ? windowHeight/4 : 150;
    const distanceToBottom = windowHeight - bottom;
    if(windowWidth < 960) {
      setGoUp(false);
    } else if( distanceToBottom < distanciaDeCambio) {
      setGoUp(true);
      //console.log(windowHeight, 'true');
    } else {
      setGoUp(false);
      //console.log(windowHeight, 'false');

    }

  }, [boundingObject]);

  return { boundingObject, bottom, top, left, right, goUp, getAndUpdateState};
};
