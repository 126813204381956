import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { FormController, useOneFormContext, removeWhiteSpace } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { ThankYouBaja, BajaComunicacionesFields } from '.';
import { formDataBuilder, formatHeaders } from '../format-fields';
import { IdPerson } from 'src/types';
import { DpsData  } from 'src/feature-service/es-oneform-core-feature-service/types';

interface BajaComunicacionesProps {
  idPerson: IdPerson;
  dpsData: DpsData;
}

export const BajaComunicacionesForm = (props: BajaComunicacionesProps) => {
  const { initializeForm } = useOneFormContext();
  const { idPerson, dpsData } = props;
  const store = useStore();
  const dispatch = useDispatch();

  const steps: Steps[] = [
    {
      title: <></>, // El title se renderiza en los fields al ser un form de un solo step con un diseño que incluye un stage.
      fields: <OneElementTemplateFullScreen element={<BajaComunicacionesFields idPerson={idPerson}/>} />,
      screenIndex: 0,
      name: 'Form',
    },
  ];

  let NuevoFormData: Partial<FormDataGroup> = {
    headers: {
      DPS_TOKEN: 'VW_DDB_FRM_BAJA_COMUNICACIONES',
      FORM_OBJECTIVE: 'Baja Comunicaciones',
      FORM_NAME: 'vw-oneform-baja-comunicaciones',
    },
    fields: {
      formName: 'baja-comunicaciones',
      formType: 'otherform',
      pageCategory: 'Baja Comunicaciones',
    }// Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();
    const { headers, fields, flags } = formData;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      const data = {
        ...formatHeaders(headers),
        'headers[FORM_REFERER]': url,
        'headers[FORM_DATE_INSERTION]': today,
        'headers[ORIGIN_TRACKING_CODE]': tc,
        'headers[ORIGIN_DEVICE_TYPE]': device,
        'headers[FORM_VERSION]': `vw-oneform-baja-comunicaciones-${process.env.VERSION}`,
        'headers[FK_SYSTEM]': idPerson && idPerson.Id ? idPerson.Id : '',

        'fields[DPS_BRAND]': 'Volkswagen',
        'fields[PERSON_EMAIL]': idPerson && idPerson.PersonEmail ? idPerson.PersonEmail : removeWhiteSpace(fields.email),
        'fields[PERSON_UNSUBSCRIBE_REASON]': fields.motivoBaja,
        'fields[COMMENTS_PERSON]': fields.masInformacion,
        'fields[PERSON_NAME]' : idPerson && idPerson.FirstName ? idPerson.FirstName : fields.name,
        'fields[PERSON_SURNAME_1]' : idPerson && idPerson.LastName ? idPerson.LastName : fields.surname,
        'fields[PERSON_SURNAME_2]' : idPerson && idPerson.MiddleName ? idPerson.MiddleName : fields.secondSurname,
        'fields[PERSON_RGPD_COM_COMERCIALES]': 'NO PERMITE',
        'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',

        'flags[PERSON_RGPD_ADAPTED]': 'true',
        'flags[FLAG_RELACION_MARCA_SIN_IC]': 'true',
      };
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData);

      if (response?.data?.status?.code == 200) {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      formTheme: 'main',
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory,
      notALayer: true,
      formType: 'inPage',
      alingItemsCenter: true,
      thankYouComponent: <ThankYouBaja />,
    };
    initializeForm(NuevoFormInfo, NuevoFormData);
  }, []);

  return !steps ? null : <FormController steps={steps} screenType="full-screen" />;
};
