import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Layout,
  styled,
  Text,
  TextAlignment,
  ThemeProvider,
  ThemeReference,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useFaContent,
} from 'src/feature-app';
import { OneFormState } from 'src/types';
import { TriggerCitaProps } from 'src/triggers';
import { NuevaCitaPosventaFormController } from '../forms/CitaPosventa/NuevaCitaPosventa';
import { RaceTrigger } from './RaceTrigger';

interface contentProps {
  themeColor: ThemeReference;
}

const Content = styled.div<contentProps>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.themeColor === 'inverted' ? props.theme.colors.gradient.radial : '#ffffff' };
  padding: ${(props) => (props.themeColor === 'inverted' ? '44px 0' : '0')};

  @media all and (min-width: 560px) {
    padding: ${(props) => (props.themeColor === 'inverted' ? '68px 0' : '0')};
  }
  @media all and (min-width: 1600px) {
    padding: ${(props) => (props.themeColor === 'inverted' ? '84px 0' : '0')};
  }

  & > div {
    width: 100%;
  }
`;
//background-color: ${(props) => props.theme.colors.background.primary};

const CenterCTA = styled.div`
  text-align: center;
`;
export function CitaPosventaDefaultTrigger({
  setSelectedJourney,
  setCTALabel,
  setSelectedFocusJourney,
  selectedFocusJourney,
  theme,
  getTitle,
  getBodyCopy,
  getCtaLabel,
  getCtaRegister,
}: TriggerCitaProps) {
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { userIsAuthenticated } = formInfo;
  const faContent = useFaContent();
  const dispatch = useDispatch();
  const useLoggedJourney = false; // De momento no es necesario usar todas las funciones del journey logueado/no logueado, tan solo el fill de los datos

  return (
    <>
      <ThemeProvider theme={theme}>
        <Content themeColor={theme}>
          <Layout
            appearance={{
              [Breakpoints.default]: [
                { name: '.', columns: 2 },
                { name: 'a', columns: 20 },
                { name: '.', columns: 2 },
              ],
              [Breakpoints.b960]: [
                { name: '.', columns: 3 },
                { name: 'a', columns: 18 },
                { name: '.', columns: 3 },
              ],
              [Breakpoints.b1280]: [
                { name: '.', columns: 4 },
                { name: 'a', columns: 16 },
                { name: '.', columns: 4 },
              ],
              [Breakpoints.b1600]: [
                { name: '.', columns: 1 },
                { name: 'a', columns: 22 },
                { name: '.', columns: 1 },
              ],
            }}
          >
            <Container wrap={'always'}>
              <Container wrap={'always'} gutter={ContainerGutter.dynamic0100}>
                <Text textAlign={TextAlignment.center} appearance={TokenTextAppearance.headline300}>
                  <div dangerouslySetInnerHTML={getTitle()} />
                </Text>
                {getBodyCopy()}
              </Container>
              <Container
                wrap={'always'}
                gutter={ContainerGutter.static400}
                padding={{ top: ContainerPadding.dynamic0150 }}
              >
                <CenterCTA>
                  <BreakpointWrapper max={Breakpoints.b560}>
                    <CTA
                      tag="button"
                      onClick={() => {
                        dispatch({ type: 'RESET_STATE' });
                        setCTALabel(getCtaLabel());
                        setSelectedJourney(<NuevaCitaPosventaFormController />);
                      }}
                      stretchContent
                    >
                      {getCtaLabel()}
                    </CTA>
                  </BreakpointWrapper>

                  <BreakpointWrapper min={Breakpoints.b560}>
                    <CTA
                      tag="button"
                      onClick={() => {
                        dispatch({ type: 'RESET_STATE' });
                        setCTALabel(getCtaLabel());
                        setSelectedJourney(<NuevaCitaPosventaFormController />);
                      }}
                    >
                      {getCtaLabel()}
                    </CTA>
                  </BreakpointWrapper>
                </CenterCTA>
                {!userIsAuthenticated && useLoggedJourney ? (
                  <Container stretchContent>
                    <Text textAlign={TextAlignment.center}>
                      {getCtaRegister('Regístrate')} o {getCtaRegister('inicia sesión')} para disfrutar de una mejor
                      experiencia.
                    </Text>
                  </Container>
                ) : null}
              </Container>
            </Container>
          </Layout>
        </Content>
      </ThemeProvider>
      {faContent?.race === 'true' ? (
        <RaceTrigger
          selectedFocusJourney={selectedFocusJourney}
          setCTALabel={setCTALabel}
          setSelectedFocusJourney={setSelectedFocusJourney}
        />
      ) : null}
    </>
  );
}
