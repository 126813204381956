export const getTestOrder = [
	{
		name: 'ID',
		value: 'ORDER',
	},
	{
		name: 'Id',
		value: '8011X000003hG8pQAE',
	},
	{
		name: 'VGED_CEMDeliveryDate__c',
		value: '2021-12-24',
	},
	{
		name: 'RCY_ActualDeliveryDate__c',
		value: '2021-12-24',
	},

	///////////// sin uso

	{
		name: 'attributes',
		value:
			'es.vged.ndpsbackend.salesforce.rest.entities.SfAttributesRest@24d13ed5[type=Order,url=/services/data/v53.0/sobjects/Order/8011X000003hG8pQAE]',
	},
	{
		name: 'OwnerId',
		value: '0051X00000FOpojQAD',
	},
	{
		name: 'ContractId',
		value: 'null',
	},
	{
		name: 'AccountId',
		value: '0011i00001dahGZAAY',
	},
	{
		name: 'Pricebook2Id',
		value: 'null',
	},
	{
		name: 'OriginalOrderId',
		value: 'null',
	},
	{
		name: 'OpportunityId',
		value: '0061X00000JQGJbQAP',
	},
	{
		name: 'QuoteId',
		value: '0Q01X000001jdBvSAI',
	},
	{
		name: 'RecordTypeId',
		value: '0121i0000009T64AAE',
	},
	{
		name: 'EffectiveDate',
		value: '2024-04-05',
	},
	{
		name: 'EndDate',
		value: 'null',
	},
	{
		name: 'IsReductionOrder',
		value: 'false',
	},
	{
		name: 'Status',
		value: 'Open',
	},
	{
		name: 'Description',
		value: 'null',
	},
	{
		name: 'CustomerAuthorizedById',
		value: 'null',
	},
	{
		name: 'CustomerAuthorizedDate',
		value: 'null',
	},
	{
		name: 'CompanyAuthorizedById',
		value: 'null',
	},
	{
		name: 'CompanyAuthorizedDate',
		value: 'null',
	},
	{
		name: 'Type',
		value: 'null',
	},
	{
		name: 'BillingAddress',
		value: 'null',
	},
	{
		name: 'ShippingAddress',
		value: 'null',
	},
	{
		name: 'Name',
		value: 'SARSA 14  04/04/2024',
	},
	{
		name: 'PoDate',
		value: 'null',
	},
	{
		name: 'PoNumber',
		value: 'null',
	},
	{
		name: 'OrderReferenceNumber',
		value: 'null',
	},
	{
		name: 'BillToContactId',
		value: 'null',
	},
	{
		name: 'ShipToContactId',
		value: 'null',
	},
	{
		name: 'ActivatedDate',
		value: 'null',
	},
	{
		name: 'ActivatedById',
		value: 'null',
	},
	{
		name: 'StatusCode',
		value: 'Draft',
	},
	{
		name: 'CurrencyIsoCode',
		value: 'EUR',
	},
	{
		name: 'OrderNumber',
		value: '00962800',
	},
	{
		name: 'TotalAmount',
		value: '0.0',
	},
	{
		name: 'CreatedDate',
		value: '2024-04-05T07:46:33.000+0000',
	},
	{
		name: 'CreatedById',
		value: '0051X00000FOpojQAD',
	},
	{
		name: 'LastModifiedDate',
		value: '2024-05-27T15:48:20.000+0000',
	},
	{
		name: 'LastModifiedById',
		value: '0051i000004UwL0AAK',
	},
	{
		name: 'IsDeleted',
		value: 'false',
	},
	{
		name: 'SystemModstamp',
		value: '2024-05-27T15:48:20.000+0000',
	},
	{
		name: 'LastViewedDate',
		value: 'null',
	},
	{
		name: 'LastReferencedDate',
		value: 'null',
	},
	{
		name: 'RCY_ActivationDate__c',
		value: 'null',
	},
	{
		name: 'RCY_BillingEmail__c',
		value: 'null',
	},
	{
		name: 'Asset__c',
		value: 'null',
	},
	{
		name: 'ExtKey__c',
		value: 'null',
	},
	{
		name: 'RCY_Brand__c',
		value: 'null',
	},
	{
		name: 'RCY_CommissionNumber__c',
		value: 'null',
	},
	{
		name: 'RCY_ConfirmationDate__c',
		value: 'null',
	},
	{
		name: 'RCY_ConfirmationStatus__c',
		value: 'null',
	},
	{
		name: 'RCY_Dealer__c',
		value: 'null',
	},
	{
		name: 'RCY_EstimatedDeliveryDate__c',
		value: 'null',
	},
	{
		name: 'RCY_VIN__c',
		value: 'WVGZZZE24RP501114',
	},
	{
		name: 'RCY_BillingFirstName__c',
		value: 'null',
	},
	{
		name: 'RCY_BillingLastName__c',
		value: 'null',
	},
	{
		name: 'RCY_BillingSalutation__c',
		value: 'null',
	},
	{
		name: 'RCY_ContactCommunicationActionName__c',
		value: 'null',
	},
	{
		name: 'RCY_DateClicked__c',
		value: 'null',
	},
	{
		name: 'RCY_DateSent__c',
		value: 'null',
	},
	{
		name: 'RCY_DealerCountry__c',
		value: 'null',
	},
	{
		name: 'RCY_DocumentName__c',
		value: 'null',
	},
	{
		name: 'RCY_DocumentType__c',
		value: '0',
	},
	{
		name: 'RCY_DurationReport__c',
		value: 'null',
	},
	{
		name: 'RCY_ExpirationDate__c',
		value: 'null',
	},
	{
		name: 'RCY_ExtensionDateLatest__c',
		value: 'null',
	},
	{
		name: 'RCY_KVPSID__c',
		value: 'null',
	},
	{
		name: 'RCY_Locale__c',
		value: 'null',
	},
	{
		name: 'RCY_MerchantId__c',
		value: 'null',
	},
	{
		name: 'RCY_NetPrice__c',
		value: 'null',
	},
	{
		name: 'RCY_NotificationContact__c',
		value: 'null',
	},
	{
		name: 'RCY_OrderCreationDate__c',
		value: 'null',
	},
	{
		name: 'RCY_PONumber1__c',
		value: 'null',
	},
	{
		name: 'RCY_Prolongation__c',
		value: 'false',
	},
	{
		name: 'RCY_SEMAGMigrationID__c',
		value: 'null',
	},
	{
		name: 'RCY_ShippingEmail__c',
		value: 'null',
	},
	{
		name: 'RCY_ShippingFirstName__c',
		value: 'null',
	},
	{
		name: 'RCY_ShippingLastName__c',
		value: 'null',
	},
	{
		name: 'RCY_ShippingSalutation__c',
		value: 'null',
	},
	{
		name: 'RCY_TeaserPurchase__c',
		value: 'false',
	},
	{
		name: 'VGED_AudiConnect__c',
		value: 'null',
	},
	{
		name: 'VGED_AudiSport__c',
		value: 'false',
	},
	{
		name: 'VGED_CO2WLTP__c',
		value: 'null',
	},
	{
		name: 'VGED_ProductionDate__c',
		value: 'null',
	},
	{
		name: 'VGED_ClimaproOrderID__c',
		value: '34011000',
	},
	{
		name: 'VGED_CodCarline__c',
		value: 'null',
	},
	{
		name: 'RCY_FAMDealershipSharing__c',
		value: 'null',
	},
	{
		name: 'VGED_CodWLTP2__c',
		value: 'null',
	},
	{
		name: 'VGED_CommunicationModel__c',
		value: 'null',
	},
	{
		name: 'VGED_ContactableUniverse__c',
		value: 'false',
	},
	{
		name: 'VGED_CodCustomerRenting__c',
		value: 'null',
	},
	{
		name: 'VGED_IsStock__c',
		value: 'null',
	},
	{
		name: 'VGED_DelayType__c',
		value: 'null',
	},
	{
		name: 'VGED_DeliveryYear__c',
		value: 'null',
	},
	{
		name: 'VGED_Electric__c',
		value: 'false',
	},
	{
		name: 'VGED_Factory__c',
		value: 'null',
	},
	{
		name: 'VGED_FleetSequence__c',
		value: 'null',
	},
	{
		name: 'VGED_HybridElectric__c',
		value: 'false',
	},
	{
		name: 'VGED_InstalledPrPackages__c',
		value: 'null',
	},
	{
		name: 'VGED_LegacySFId__c',
		value: 'null',
	},
	{
		name: 'VGED_ModelGroup__c',
		value: 'null',
	},
	{
		name: 'VGED_Model__c',
		value: 'null',
	},
	{
		name: 'VGED_OrderSLI__c',
		value: 'null',
	},
	{
		name: 'VGED_OrderState10__c',
		value: 'null',
	},
	{
		name: 'VGED_OrderState20__c',
		value: 'null',
	},
	{
		name: 'VGED_OrderState30__c',
		value: 'null',
	},
	{
		name: 'VGED_OrderState40__c',
		value: 'null',
	},
	{
		name: 'VGED_OrderState50__c',
		value: 'null',
	},
	{
		name: 'VGED_OrderState60__c',
		value: 'null',
	},
	{
		name: 'VGED_OrderState70__c',
		value: 'null',
	},
	{
		name: 'VGED_OrderState80__c',
		value: 'null',
	},
	{
		name: 'VGED_Period__c',
		value: 'null',
	},
	{
		name: 'RCY_RetailDealershipSharing__c',
		value: 'null',
	},
	{
		name: 'VGED_RequestDelayDate__c',
		value: 'null',
	},
	{
		name: 'VGED_SalesConcession__c',
		value: 'null',
	},
	{
		name: 'VGED_SalesTypeSLI__c',
		value: 'null',
	},
	{
		name: 'VGED_SellerCode__c',
		value: 'null',
	},
	{
		name: 'VGED_SliState__c',
		value: 'null',
	},
	{
		name: 'VGED_ConnectType__c',
		value: 'null',
	},
	{
		name: 'VGED_SurveyableOrder__c',
		value: 'false',
	},
	{
		name: 'VGED_Test__c',
		value: 'false',
	},
	{
		name: 'VGED_TypeOrderVNVO__c',
		value: 'null',
	},
	{
		name: 'VGED_Vehicle__c',
		value: 'a0U1X0000049qKgUAI',
	},
	{
		name: 'VGED_WLTPconsumption__c',
		value: 'null',
	},
	{
		name: 'VGED_WarrantyExtension__c',
		value: 'null',
	},
	{
		name: 'VGED_YearsWarrantyExtension__c',
		value: 'null',
	},
	{
		name: 'VGED_CustomerType__c',
		value: 'null',
	},
	{
		name: 'VGED_CodDriver__c',
		value: 'null',
	},
	{
		name: 'VGED_Commerce__c',
		value: 'false',
	},
	{
		name: 'VGED_CustomersOrderDate__c',
		value: 'null',
	},
	{
		name: 'VGED_Life__c',
		value: 'false',
	},
	{
		name: 'VGED_SurveyableDateCEM__c',
		value: 'null',
	},
	{
		name: 'VGED_SurveyablePerson__c',
		value: 'null',
	},
	{
		name: 'VGED_Transformed__c',
		value: 'null',
	},
	{
		name: 'RCY_SalesModel__c',
		value: 'null',
	},
];
