import React, { useEffect, useState } from 'react';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { Container, ContainerPadding, Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { DetalleMotivo, MultipleOptionCTAs, LegalFullScreenSinConsentmientos } from 'src/components';
import { ConfirmacionNoRenovacion, ThankYouNoRenovacion, FakeTriggerCTA } from '.';
import { getCommonFormData } from './FormData';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { FormController, useOneFormContext } from 'src/feature-app';
import { formDataBuilder } from 'src/forms/format-fields';
import { NoRenovacionFormProps } from './NoRenovacionController';
import { useInitializeNoRenovacionForm } from './use-initialize-no-renovacion-form';
import {
  addRemovedStep,
  getIndexInSteps,
  removeStep,
  resetStepsScreenIndex,
  removeMultipleSteps,
} from '../helpers/add-remove-steps';
import { CTAsFormasMovilidad, CTAsRazones, CTAsFormasMovilidadExclusive } from 'src/feature-app/InputsValues';

export enum NoRenovacionNoBuscaSteps {
  Introduccion,
  FormasMovilidad,
  MasInformacion,
  Razones,
  MasInformacionRazones,
  Confirmacion,
}

export function NoRenovacionNoQuiero({
  dpsData,
  idLead,
  idVehicle,
  idPerson,
  trackFormLoad,
  handleError,
  estado,
}: NoRenovacionFormProps) {
  const { initializeForm, handleNextStep } = useOneFormContext();
  const store = useStore();
  const dispatch = useDispatch();
  const { formData } = useSelector((state: OneFormState) => state);
  const [loadingPresubmit, setLoadingPresubmit] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const { motivo, razones, formasMovilidad } = formData.fields;

  const handleSteps = (formasMovilidad: string) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
    let newSteps = [...steps];
    setLoadingForm(true);
    if (formasMovilidad.includes('7')) {
      const aux = formasMovilidad.split(',');
      if (aux.length <= 1) {
        newSteps = removeMultipleSteps(newSteps, ['razones', 'masInformacionRazones']);
        // newSteps = removeStep(newSteps, 'razones');
        // newSteps = removeStep(newSteps, 'masInformacionRazones');
      }
    } else {
      const razones = getIndexInSteps(newSteps, 'razones');
      const masInformacionRazones = getIndexInSteps(newSteps, 'masInformacionRazones');

      if (razones === -1) {
        newSteps = addRemovedStep(steps, actualSteps, 'razones', screenIndex);
      }
      if (masInformacionRazones === -1) {
        newSteps = addRemovedStep(steps, actualSteps, 'masInformacionRazones', screenIndex);
      }
    }

    newSteps = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newSteps });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newSteps[newSteps.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newSteps.length });

    setTimeout(() => {
      handleNextStep();
      setLoadingForm(false);
    }, 0);
  };

  const handleDetalleFormasMovilidad = (motivo: Array<string>) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
    let newSteps = [...actualSteps];

    if (formasMovilidad.includes('8')) {
      const stepIndex = getIndexInSteps(newSteps, 'masInformacion');

      if (stepIndex === -1) {
        newSteps = addRemovedStep(steps, actualSteps, 'masInformacion', screenIndex);
        setTimeout(() => {
          handleNextStep();
        }, 0);
      }
    } else {
      newSteps = removeStep(newSteps, 'masInformacion');
    }

    const newStepsWithIndex = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
  };

  const handleDetalleRazones = (razones: Array<string>) => {
    const { steps: actualSteps, screenIndex } = store.getState().formInfo;
    let newSteps = [...actualSteps];

    if (razones.includes('8')) {
      const stepIndex = getIndexInSteps(newSteps, 'masInformacionRazones');

      if (stepIndex === -1) {
        newSteps = addRemovedStep(steps, actualSteps, 'masInformacionRazones', screenIndex);
        setTimeout(() => {
          handleNextStep();
        }, 0);
      }
    } else {
      newSteps = removeStep(newSteps, 'masInformacionRazones');
    }

    const newStepsWithIndex = resetStepsScreenIndex(newSteps);

    dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
  };

  useEffect(() => {
    const sendPresubmit = async () => {
      const result = await sendForm(true);

      if (!result) {
        handleError();
      }
      setLoadingPresubmit(false);
    };

    sendPresubmit();
  }, []);

  const steps: Steps[] = [
    {
      title: (
        <>
          <Text bold>Nos hubiese encantado seguir siendo tu compañero de viaje. </Text>
          ¿Nos cuentas el porqué de este cambio por si te podemos ayudar en un futuro?
        </>
      ),
      fields: <OneElementTemplateFullScreen allowOverflowingContent element={<FakeTriggerCTA />} />,
      screenIndex: NoRenovacionNoBuscaSteps.Introduccion,
      name: 'introduccion',
    },
    {
      title: (
        <>
          <Text bold>
            ¿Cómo vas a realizar tus desplazamientos de ahora en{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              adelante?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
          <Container padding={{ top: ContainerPadding.dynamic0020 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Puedes elegir más de una opción
            </Text>
          </Container>
        </>
      ),
      alternateTitle: (
        <>
          <Text bold>
            ¿Cómo vas a realizar tus desplazamientos de ahora en{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              adelante?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <MultipleOptionCTAs
              ignoreHandleClick={true}
              ctaValues={CTAsFormasMovilidad}
              minSelected={1}
              name="formasMovilidad"
              exclusiveCTA={true}
              exclusiveCTAValues={CTAsFormasMovilidadExclusive}
            />
          }
        />
      ),
      screenIndex: NoRenovacionNoBuscaSteps.FormasMovilidad,
      name: 'formasMovilidad',
      outputs: ['formasMovilidad'],
      outputsText: ['formasMovilidad'],
    },
    {
      title: (
        <>
          ¿Nos explicas en{' '}
          <Text bold>
            qué medio te vas a{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              desplazar?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: <OneElementTemplateFullScreen element={<DetalleMotivo />} />,
      screenIndex: NoRenovacionNoBuscaSteps.MasInformacion,
      name: 'masInformacion',
      outputs: ['masInformacion'],
      outputsText: ['masInformacion'],
    },
    {
      title: (
        <>
          <Text bold>¿Cuáles han sido las razones </Text>
          para hacer este{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            cambio?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
          <Container padding={{ top: ContainerPadding.dynamic0020 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Puedes elegir más de una opción
            </Text>
          </Container>
        </>
      ),
      alternateTitle: (
        <>
          <Text bold>¿Cuáles han sido las razones </Text>
          para hacer este{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            cambio?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <MultipleOptionCTAs ignoreHandleClick={true} ctaValues={CTAsRazones} minSelected={1} name="razones" />
          }
        />
      ),
      screenIndex: NoRenovacionNoBuscaSteps.Razones,
      name: 'razones',
      outputs: ['razones'],
      outputsText: ['razones'],
    },
    {
      title: (
        <>
          <Text bold>
            ¿Puedes{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              compartirlas?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: <OneElementTemplateFullScreen element={<DetalleMotivo name="masInformacionRazones" />} />,
      screenIndex: NoRenovacionNoBuscaSteps.MasInformacionRazones,
      name: 'masInformacionRazones',
      outputs: ['masInformacionRazones'],
      outputsText: ['masInformacionRazones'],
    },
    {
      fields: <ConfirmacionNoRenovacion idPerson={idPerson} />,
      screenIndex: NoRenovacionNoBuscaSteps.Confirmacion,
      name: 'Confirmacion',
      outputs: ['confirmacion'],
      outputsText: ['confirmacion'],
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    fields: {
      formName: 'no_renovacion-no_quiero',
      formType: 'otherform',
      pageCategory: 'Encuesta',
    }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const generateData = (isPresubmit: boolean, data?: Partial<FormDataGroup>) => {
    const { formData }: OneFormState = store.getState();
    const { fields } = formData;

    let formasMovilidad = orderString(fields.formasMovilidad);
    let razones = orderString(fields.razones);

    const submitData = !isPresubmit ? {
      'fields[SURVEY_ANSWER_07]': formasMovilidad,
      'fields[SURVEY_ANSWER_08]': fields.masInformacion,
      'fields[SURVEY_ANSWER_09]': razones,
      'fields[SURVEY_ANSWER_10]': fields.masInformacionRazones,
    } : null;

    return {
      ...getCommonFormData(isPresubmit, idPerson, idVehicle, dpsData, fields, data, '2'),
      ...submitData,
    };
  };

  const orderString = (orderMotivos) => {

    if (orderMotivos == null) {
      return null;
    }

    const array = orderMotivos.split(",");
  
    const sortedArray = array.sort((a, b) => {
      return parseInt(a) - parseInt(b);
    });
  
    const orderString = sortedArray.join(",");
  
    return orderString;
  };

  const sendForm = async (isPresubmit?: boolean): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateData(isPresubmit, NuevoFormData);
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData, isPresubmit);

      if (response.data?.status?.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { firstStep, lastStep, loading } = useInitializeNoRenovacionForm(steps);

  useEffect(() => {
    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps,
      stepsHistory: [0],
      customLegal: idPerson.LSSI__c === 'PERMITE' ? <LegalFullScreenSinConsentmientos /> : null,
      hideLegal: idPerson.LSSI__c === 'PERMITE' ? true : false,
      thankYouComponent: <ThankYouNoRenovacion />,
    };

    if (!loading) {
      initializeForm(NuevoFormInfo, NuevoFormData, true);
      trackFormLoad();
    }
  }, [loading]);

  useEffect(() => {
    if (formasMovilidad) {
      handleSteps(formasMovilidad);
      handleDetalleFormasMovilidad(formasMovilidad);
    }
  }, [formasMovilidad]);

  useEffect(() => {
    if (razones) handleDetalleRazones(razones);
  }, [razones]);

  return loadingForm || loading || loadingPresubmit || !steps ? null : (
    <FormController steps={steps} screenType="full-screen" />
  );
}
