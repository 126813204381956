import React, { useEffect, useRef, useState } from 'react';
import { useFeatureServices, useFaContent, useTrackingManager } from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { CEMSegundaEntregaForm, CEMSegundaEntregaNoInteres } from 'src/forms/Cem/CEMSegundaEntrega';
import { GenericErrorLayer } from 'src/components/layers';
import { createPincodeEntity } from 'src/forms/dps-utils';
import { getInstallations, getInstallationsEntregaIdeal } from 'src/bff';
import { IdDealer, IdOrder, IdPerson, IdVehicle } from 'src';
import { LoadingEncuestas } from 'src/components';
import { arrayToObject } from 'src/helpers';


export function CEMSegundaEntregaController() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [usado, setUsado] = useState(false);

	const [idPerson, setIdPerson] = useState(null);
	const [idVehicle, setIdVehicle] = useState(null);
	const [idDealer, setIdDealer] = useState(null);
	const [idOrder, setIdOrder] = useState(null);

	const [interes, setInteres] = useState(false);
	const [dealers, setDealers] = useState(null);

	const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

	const handleNext = (value: any) => { };
	const handleComplete = async () => {
		const dpsData = oneFormService.getDpsData();

		if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
			handleError('Pincode not found');
		} else if (dpsData.pincode.pincodeUsed) {
			setUsado(true);
			setLoading(false);
		} else {
			setIdPerson(createPincodeEntity(dpsData, 'PERSON') as IdPerson);
			setIdVehicle(createPincodeEntity(dpsData, 'VEHICLE') as IdVehicle);
			setIdOrder(createPincodeEntity(dpsData, 'ORDER') as IdOrder);
			const idDealer = createPincodeEntity(dpsData, 'DEALER') as IdDealer;
			setIdDealer(idDealer);

			setInteres(dpsData.interes == 'true');

			const installationsRaw = await getInstallations('VW_DDB_FRM_CEM_SEGUNDA_ENTREGA_INVITACION', 'VOLKSWAGEN_SF_LEAD_INSTALLATION'); // Installations
			if (installationsRaw.length > 0) {
				// Todas las instalaciones formato object
				let installations = installationsRaw.map((instal) => arrayToObject(instal.attributes)) as IdDealer[];
				// Filtramos por entrega ideal
				const whiteList = await getInstallationsEntregaIdeal() as any[];
				if (whiteList.length > 0) {
					installations = installations.filter((instal) => whiteList.includes(instal.VGED_DealerCode__c));
				}
				// Obtenemos dealer por parametro
				if (dpsData.dealer) {
					const myInstallations = installations.filter((instal) => instal.VGED_DealerCode__c == dpsData.dealer);
					if (myInstallations.length > 0) {
						const dealer = (myInstallations[0]);
						setIdDealer(dealer);
						// Agrupamos con mismo código de concesión
						installations = installations.filter((instal) => instal.VGED_ConcessionDealerCode__c == dealer.VGED_ConcessionDealerCode__c);
					}
				}
				else {
					// Agrupamos con mismo código de concesión
					installations = installations.filter((instal) => instal.VGED_ConcessionDealerCode__c == idDealer.VGED_ConcessionDealerCode__c);
				}
				setDealers(installations);
			}
			else {
				handleError('Installations not found');
			}

			setLoading(false);
		}
	};

	const handleError = (message?: string) => {
		setError(true);
		setLoading(false);
		if (message) {
			console.error(message);
		}
	};

	useEffect(() => {
		const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

		oneFormCoreDpsIsLoaded.subscribe({
			next(value) {
				handleNext(value);
			},
			error(err) {
				console.error(err);
				handleError();
			},
			complete() {
				handleComplete();
			},
		});
	}, []);

	if (loading) {
		return <LoadingEncuestas />;
	} else if (error) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, algo ha pasado"
					bodycopy="Por alguna razón no hemos podido cargar el cuestionario."
					ctaLayer="Reintentar"
					notShowCTA={true}
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (usado) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, ya has respondido a este cuestionario"
					bodycopy="Parece que ya nos has contado cómo fue tu experiencia. ¿Quieres añadir algo más?"
					ctaLayer="Contacta con nosotros"
					ctaType="a"
					urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
					icon={false}
					notALayer={true}
				/>
			</>
		);
	}
	else {
		return (
			<>
				{interes ? (
					<CEMSegundaEntregaForm
						dealers={dealers}
						idPerson={idPerson}
						idVehicle={idVehicle}
						idDealer={idDealer}
						idOrder={idOrder}
					/>
				) : (
					<CEMSegundaEntregaNoInteres
						handleError={handleError}
						idPerson={idPerson}
						idVehicle={idVehicle}
						idDealer={idDealer}
						idOrder={idOrder}
					/>
				)}
			</>
		);
	}
}
