import { useIsDevStage } from './use-is-dev-stage';
import { useRef } from 'react';
import { useFeatureServices } from 'src/feature-app';

const citaPreviaKey = 'es-posventa-cita_previa';

export function useNavigate() {
  const navigationService = useFeatureServices()['navigation-service'];

  const navigateToCita = (): void => {
    const target = navigationService.navigateTo(citaPreviaKey, {});
    target.push();
  };

  const navigateToThankyouNewsletter = (action: string): void => {
    const resultsPage = `inscripcion-${action}`;

    const target = navigationService.navigateTo(resultsPage, {});

    target.push();
  };

  return {
    navigateToCita,
    navigateToThankyouNewsletter,
  };
}
