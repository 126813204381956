import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { CarlineSelect, Email, PantallaNombreApellido, Telefono, ContactInfoWrapper, MultipleCTASelection, DetalleMotivo } from 'src/components';
import { FormController, useOneFormContext } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { FormDataGroup, FormInfo, IdLead, IdPerson, OneFormState, Steps } from 'src/types';
import { formDataBuilder } from '../format-fields';
import { LEMFormProps } from './LEMController';
import { useInitializeForm, checkIdLeadHasCarline } from './use-initialize-lem-form';
import { ConfirmacionLEM,ThanKYouLEM } from 'src/forms/LEM';

export enum LEMNoContactadoSteps {
  Modelo,
  //NombreApellidos,
  EmailTelefono,
  Horario,
  Comentario,
  Confirmacion
}

export function LEMNoContactadoForm(props: LEMFormProps) {
  const { dpsData, idLead, idPerson, installation, handleError, presubmit, trackFormLoad } = props;
  const [loadingPresubmit, setLoadingPresubmit] = useState(true);
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const dispatch = useDispatch();
  const { dealersInfo }: OneFormState = store.getState();

  const ctasHorarios = [
    {
      label: 'Mañana',
      value: 'Mañana'
    },
    {
      label: 'Tarde',
      value: 'Tarde'
    },
  ];

  const stepsWithModel: Steps[] = [
    {
      title: (
        <>
          <Text bold>¿Por qué modelo </Text>contactaste con tu punto de venta?
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ marginBottom: '2px' }}>
              <CarlineSelect useCarline={true} notALayer={true} />
            </div>
          }
        />
      ),
      screenIndex: LEMNoContactadoSteps.Modelo,
      name: 'Modelo',
    },
    /*{
      title: (
        <>
          Para averiguar qué ha pasado, necesitamos saber <Text bold>quién eres</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ marginBottom: '2px' }}>
              <PantallaNombreApellido notALayer={true} />
            </div>
          }
        />
      ),
      screenIndex: LEMNoContactadoSteps.NombreApellidos,
      name: 'NombreApellidos',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },*/
    {
      title: (
        <>
          ¿Nos podrías confirmar tus <Text bold>datos de contacto?</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <ContactInfoWrapper>
              <Telefono paginaConfirmacion={false} required={true} notALayer={true} />
              <Email paginaConfirmacion={false} required={true} notALayer={true} paddingTop='24px'/>
            </ContactInfoWrapper>
          }
        />
      ),
      screenIndex: LEMNoContactadoSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      title: (
        <>
          <Text bold>¿En qué horario</Text> prefieres ser contactado?
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={ctasHorarios} name="horario" nextOnClick />}
        />
      ),
      screenIndex: LEMNoContactadoSteps.Horario,
      name: 'Horario',
    },
    {
      title: (
        <>
          <Text bold>¿Quieres añadir </Text> algun comentario adicional?
        </>
      ),
      fields: <OneElementTemplateFullScreen element={<DetalleMotivo name="comentario" />} />,
      screenIndex: LEMNoContactadoSteps.Comentario,
      name: 'Comentario',
    },
    {
      fields: <ConfirmacionLEM />,
      screenIndex: LEMNoContactadoSteps.Confirmacion,
      name: 'Confirmacion',
    },
  ];

  const LEMNoContactadoData: Partial<FormDataGroup> = {
    headers: {},
    fields: {
      name: idLead.FirstName,
      surname: idLead.LastName,
      secondSurname: idLead.MiddleName,
      email: idLead.Email,
      cellphone: idLead.MobilePhone,
      formName: 'lem-no_contactado',
      formType: 'otherform',
      pageCategory: 'Más Información',
    }, // Analítica
  };

  useEffect(() => {
    const sendPresubmit = async () => {
      const result = await sendForm(true);

      if (!result) {
        handleError();
      }
      setLoadingPresubmit(false);
    };

    if (presubmit) {
      if(checkIdLeadHasCarline(idLead) && dealersInfo.selectedCarline) {
        sendPresubmit();
      }
      else if(!checkIdLeadHasCarline(idLead)) {
        sendPresubmit();
      }
    } else {
      setLoadingPresubmit(false);
    }
  }, [dealersInfo]);

  const generateData = (data?: Partial<FormDataGroup>) => {
    const { formData, dealersInfo }: OneFormState = store.getState();
    const { fields } = formData;
    const { tc, today, url, device } = getParamsForm();

    return {
      'headers[DPS_TOKEN]': 'VW_DDB_FRM_LEM_ENCUESTA',
      'headers[FK_SYSTEM]': idLead.Id,
      'headers[FORM_DATE_INSERTION]': today,
      'headers[FORM_DETAILS]': 'LEM No Contactado',
      'headers[FORM_NAME]': fields.formName ? `vw-oneform-${fields.formName}` : `vw-oneform-${data.fields.formName}`,
      'headers[FORM_OBJECTIVE]': 'Encuesta',
      'headers[FORM_URL]': url,
      'headers[FORM_VERSION]': fields.formName ? `vw-oneform-${fields.formName}-${process.env.VERSION}` : `vw-oneform-${data.fields.formName}-${process.env.VERSION}`,
      'headers[ORIGIN_DEVICE_TYPE]': device,
      'headers[ORIGIN_TRACKING_CODE]': tc,
      'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,

      'flags[PERSON_RGPD_ADAPTED]': true,
      'flags[FLAG_RELACION_MARCA_SIN_IC]': false,

      'fields[DPS_BRAND]': 'Volkswagen',
      'fields[PERSON_EMAIL]': idLead.Email,
      'fields[PERSON_NAME]': fields.name ? fields.name : idLead.FirstName,
      'fields[PERSON_PHONE_MOBILE]': idLead.MobilePhone,
      'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
      'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
      'fields[PERSON_SURNAME_1]': fields.surname ? fields.surname : idLead.LastName,
      'fields[PERSON_SURNAME_2]': fields.secondSurname ? fields.secondSurname : idLead.MiddleName,
      'fields[SF_LEAD_INSTALLATION_ID]': idLead.Dealership__c,
      'fields[SF_LEAD_INSTALLATION_CODE]': installation ? installation.VGED_DealerCode__c : '',
      'fields[SF_LEAD_INSTALLATION_NAME]': installation ?installation.VGED_CommercialName__c : '',
      'fields[SURVEY_ANSWER_13]': fields.horario,
      'fields[SURVEY_ANSWER_14]': fields.comentario,
      'fields[VEH_INTEREST_NEW_OR_USED]': 'New Car',
      'fields[VEH_INTEREST_ID]': checkIdLeadHasCarline(idLead) && dealersInfo?.selectedCarline ? dealersInfo.selectedCarline.code : fields.carlineCode,
      'fields[VEH_INTEREST_MODEL]': checkIdLeadHasCarline(idLead) && dealersInfo?.selectedCarline ? dealersInfo.selectedCarline.title : fields.modelo

    };
  };

  const generatePresubmitData = () => {
    const data = generateData(LEMNoContactadoData);

    data['headers[FORM_DETAILS]'] = 'LEM Pregrabación';
    data['headers[DPS_TOKEN]'] = 'VW_DDB_FRM_LEM_ENCUESTA_PREGRABACION';

    return data;
  };
;

  const sendForm = (isPresubmit?: boolean): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {

      const data = isPresubmit ? generatePresubmitData() : generateData();
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData, isPresubmit);

      if (response?.data?.status?.code == 200) {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { loading, steps, firstStep, lastStep } = useInitializeForm(stepsWithModel, idLead);

  useEffect(() => {
    const LEMNoContactadoInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps,
      stepsHistory: [0],
      thankYouComponent: <ThanKYouLEM />,
    };
    if (!loading) {
      initializeForm(LEMNoContactadoInfo, LEMNoContactadoData, true);
      trackFormLoad();
    }
  }, [loading]);

  return loading || loadingPresubmit || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
