import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputError, TextInputExtended, SelectExtended } from 'src/components';
import { useOnlyLettersControl } from 'src/components/inputs/use-only-letters-control';
import {
  styled,
} from '@volkswagen-onehub/components-core';
import { OneFormState } from 'src/types';
import { useSelector } from 'react-redux';
interface ContentProps {
  notALayer?: boolean;
}

const ContentWrapper = styled.div<ContentProps>`
  margin-top: 4px;
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;
  }
  label {
    margin-top: 40px;
    @media all and (min-width: 560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid013)' : '303px')};
    }
    @media all and (min-width: 960px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '320px')};
    }
    @media all and (min-width: 1280px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '320px')};
    }
    @media all and (min-width: 1920px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '320px')};
    }
    @media all and (min-width: 2560px) {
      width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '320px')};
    }
  }
`;

const roadOptions = [
  {
    code: 'AUTOP',
    name: 'Autopista',
  },
  {
    code: 'AUTOV',
    name: 'Autovía',
  },
  {
    code: 'AVENI',
    name: 'Avenida',
  },
  {
    code: 'BARIO',
    name: 'Barrio',
  },
  {
    code: 'CALLE',
    name: 'Calle',
  },
  {
    code: 'CAMIN',
    name: 'Camino',
  },
  {
    code: 'CARRT',
    name: 'Carretera',
  },
  {
    code: 'GRANV',
    name: 'Gran vía',
  },
  {
    code: 'PASAJ',
    name: 'Pasaje',
  },
  {
    code: 'PASEO',
    name: 'Paseo',
  },
  {
    code: 'PLAYA',
    name: 'Playa',
  },
  {
    code: 'RAMBL',
    name: 'Rambla',
  },
  {
    code: 'RONDA',
    name: 'Ronda',
  },
  {
    code: 'TRAVE',
    name: 'Travesía',
  },
  {
    code: 'URBAN',
    name: 'Urbanización',
  },
  {
    code: 'VIA',
    name: 'Vía',
  },
];

export const PantallaDireccionPostal = () => {
  const { register } = useFormContext();
  const { fields } = useSelector((state: OneFormState) => state.formData);

  //tipo de vía

  const [tipoVia, setTipoVia] = useState('Autopista');

  useEffect(() => {
    if (fields.tipoVia) {
      setTipoVia(fields.tipoVia);
    }
  }, []);

  function handleChangeTipoVia(event: any) {
    const newTipoVia = event.currentTarget.value;
    setTipoVia(newTipoVia);
  }

  //Nombre de la vía
  const [viaValue, setViaValue] = useState('');
  const [pristineVia, setPristineVia] = useState(true);
  const { error, regExp } = useOnlyLettersControl(pristineVia, viaValue);
  const numberRegExp = /\d|[sS]\/[nN]/gi;
  const handleChangeVia = (e: any) => {
    setViaValue(e.target.value);
    setPristineVia(false);
  };

  //Número

  const [numberValue, setNumberValue] = useState('');
  const [pristineNumber, setPristineNumber] = useState(true);
  const [numberError, setNumberError] = useState(false);
  const regExpNonDigit = /\D/;

  const handleChangeNumber = (e: any) => {
    setNumberValue(e.target.value);
    if (regExpNonDigit.test(e.target.value) || (numberValue.length > 1 && numberValue.length < 8)) {
      setNumberError(true);
    } else if (numberValue === 's/n') {
      setNumberError(true);
    }

    setNumberError(false);

    setPristineNumber(false);
  };

  //Piso y puerta
  const [pisoPuertaValue, setPisoPuertaValue] = useState('');

  const handleChangePisoPuerta = (e: any) => {
    setPisoPuertaValue(e.target.value);
  };

  return (
    <ContentWrapper>
      <SelectExtended
        options={roadOptions}
        name='typeOfRoad'
        label='Tipo de vía'
        isRequired={true}
        value={tipoVia}
        onClick={() => {}}
        setOption={setTipoVia}
        handleChange={handleChangeTipoVia}
        updateFields
      />
      <TextInputExtended
        name='roadName'
        label='Nombre de la vía'
        isFloating
        innerRef={register({
          required: {
            value: true,
            message: 'Requerido',
          },
          pattern: {
            value: regExp,
            message: 'Solo se aceptan letras',
          },
        })}
        onChange={(e) => handleChangeVia(e)}
        required
      />
      {error && <InputError className='input-error'>Solo se aceptan letras</InputError>}
      <TextInputExtended
        name='roadNumber'
        label='Número'
        isFloating
        innerRef={register({
          required: {
            value: true,
            message: 'Requerido',
          },
          // pattern: {
          //   value: numberRegExp,
          //   message: 'Solo se aceptan números',
          // },
        })}
        onChange={(e) => handleChangeNumber(e)}
        required
      />
      {numberError && <InputError className='input-error'>Se aceptan números o la expresión s/n</InputError>}
      <TextInputExtended
        name='roadComplement'
        label='Piso y puerta'
        isFloating
        onChange={(e) => handleChangePisoPuerta(e)}
      />
    </ContentWrapper>
  );
};
