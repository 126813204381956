import React from 'react';
import { ScreenController } from 'src/feature-app';
import { NavidadGTI2024Form } from 'src/forms/FormsAdhoc/NavidadGTI2024/NavidadGTI2024Form';

export function NavidadGTI2024Trigger() {
  return <ScreenController
    selectedJourney={<NavidadGTI2024Form />}
    closeLayerCallback={close}
    handleAreYouSureLayer={() => null}
    notALayer={true}
    formType='inPage'
  />;
}
