import React, { useState, useEffect } from 'react';
import {
  styled,
  Text,
  ThemeProvider,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { CSSTransition } from 'react-transition-group';

const CardsWrapper = styled.div`
  position: relative;
  height: calc((2 *var(--size-grid003)/1.04) + 89px);
  @media screen and (max-width: 780px) {
    height: calc((2 *var(--size-grid003)/1.04) + 140px);
  }
  @media screen and (max-width: 712px) {
    height: 369px;
  }
  @media screen and (max-width: 654px) {
    height: 410px;
  }
  @media screen and (max-width: 654px) {
    height: 388px;
  }
  @media screen and (max-width: 420px) {
    height: 375px;
  }
`;

type CardProps = {
  bgColor: string,
  number: number,
  size?: number,
  position: {
    top?: string,
    right?: string,
    bottom?: string,
    left?: string
  }
}

const Card = styled.article<CardProps>`
  position: absolute;
  width: var(--size-grid003);
  height: calc(var(--size-grid003)/1.04);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  top: ${props => props.position.top ? `${props.position.top}` : 'unset'};
  right: ${props => props.position.right ? `${props.position.right}` : 'unset'};
  bottom: ${props => props.position.bottom ? `${props.position.bottom}` : 'unset'};
  left: ${props => props.position.left ? `${props.position.left}` : 'unset'};
  background: ${props => props.bgColor === 'dark' ? '#001E50' : '#00B0F0'};
  padding: 16px;
  display: none;
  &.card-enter {
    transform: scale(0);
    display: flex;
  }
  &.card-enter-active {
    transform: scale(1.1);
    transition: transform 150ms;
    display: flex;
  }
  &.card-enter-done {
    transform: scale(1);
    transition: transform 150ms;
    display: flex;
  }
  @media screen and (max-width: 1188px) {
    width: max-content;
    max-width: 165px;
    height: 131px;
  }
  @media screen and (max-width: 654px) {
    text-align: left;
  }
  @media screen and (max-width: 420px) {
    max-width: unset;
    width: ${
  props => props.number === 0 ? '120px'
    : props.number === 1 ? '120px'
      : props.number === 2 ? '164px'
        : props.number === 3 ? '144px'
          : '140px'
};
    height: ${
  props => props.number === 0 ? '125px'
    : props.number === 1 ? '121px'
      : props.number === 2 ? '145px'
        : props.number === 3 ? '161px'
          : '140px'
};
  }
`;

const mensajes = [
  {
    bgColor: 'light',
    text: 'Mamá, éste sí que es nuestro año. ¡Te quiero!'
  },
  {
    bgColor: 'dark',
    text: 'Mamá, ¿te ape un plan juntos, solos tú y yo?'
  },
  {
    bgColor: 'light',
    text: '¡Felicidades, Carmen! Prepárate, porque se vienen cositas hihi'
  },
  {
    bgColor: 'dark',
    text: 'Madre no hay más que una, y en eso eres la mejor del mundo <3'
  },
  {
    bgColor: 'dark',
    text: 'Feliz día a ti, mami. ¡Eres mi mayor referente!'},
];

const mensajesPosition = {
  default: [
    { name: 'default'},
    {
      top: '25%',
      right: '22%',
    },
    {
      top: '45%',
      left: '22%',
    },
    {
      top: '10.5%',
      left: '0px',
    },
    {
      top: '0px',
      right: '0px',
    },
    {
      right: '0px',
      bottom: '0px'
    }
  ],
  b1188: [
    { name: 'b1188'},
    {
      top: '25%',
      right: '22%',
    },
    {
      top: '45%',
      left: '7%',
    },
    {
      top: '11.5%',
      left: '0px',
    },
    {
      top: '0px',
      right: '0px',
    },
    {
      right: '0px',
      bottom: '0px'
    }
  ],
  b960: [
    { name: 'b960'},
    {
      top: '25%',
      right: '25%',
    },
    {
      top: '47%',
      left: '10%',
    },
    {
      top: '11.5%',
      left: '0px',
    },
    {
      top: '0px',
      right: '0px',
    },
    {
      right: '0px',
      bottom: '0px'
    }
  ],
  b654: [
    { name: 'b654'},
    {
      top: '25%',
      right: '25%',
    },
    {
      top: '37%',
      left: '30%',
    },
    {
      top: '16.5%',
      left: '0px',
    },
    {
      top: '0px',
      right: '0px',
    },
    {
      right: '0px',
      bottom: '0px'
    }
  ],
  b420: [
    { name: 'b654'},
    {
      top: '25%',
      right: '14%',
    },
    {
      top: '34%',
      left: '24%',
    },
    {
      top: '6.5%',
      left: '0px',
    },
    {
      top: '0px',
      right: '0px',
    },
    {
      right: '0px',
      bottom: '0px'
    }
  ]
};

export function MensajesCards(props: {showMessages: boolean}) {
  const { showMessages } = props;
  const [ showCard1, setShowCard1 ] = useState(false);
  const [ showCard2, setShowCard2 ] = useState(false);
  const [ showCard3, setShowCard3 ] = useState(false);
  const [ showCard4, setShowCard4 ] = useState(false);
  const [ showCard5, setShowCard5 ] = useState(false);

  const [positionsArray, setPositionsArray] = useState(mensajesPosition.default);

  function activateTriggers(num: number) {
    switch(num) {
      case 2:
        setTimeout(() => {
          setShowCard2(true);
          activateTriggers(3);
        }, 450);
        break;
      case 3:
        setTimeout(() => {
          setShowCard3(true);
          activateTriggers(4);
        }, 450);
        break;
      case 4:
        setTimeout(() => {
          setShowCard4(true);
          activateTriggers(5);
        }, 450);
        break;
      case 5:
        setTimeout(() => {
          setShowCard5(true);
        }, 450);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (showMessages) {
      setShowCard1(true);
      activateTriggers(2);
    } else {
      setShowCard1(false);
      setShowCard2(false);
      setShowCard3(false);
      setShowCard4(false);
      setShowCard5(false);
    }
  }, [showMessages]);

  useEffect(() => {
    function changeCardsPosition(bp: string) {
      if (bp === '959' && positionsArray[0].name !== 'b960') {
        setPositionsArray(mensajesPosition.b960);
      } else if (bp === '654' && positionsArray[0].name !== 'b654') {
        setPositionsArray(mensajesPosition.b654);
      } else if (bp === '420' && positionsArray[0].name !== 'b420') {
        setPositionsArray(mensajesPosition.b420);
      } else if (bp === '1188' && positionsArray[0].name !== 'b1188') {
        setPositionsArray(mensajesPosition.b1188);
      } else {
        setPositionsArray(mensajesPosition.default);
      }
    }

    const mediaQueryListObject1188 = window.matchMedia('(max-width: 1188px)');
    try {
      mediaQueryListObject1188.addEventListener('change', () => changeCardsPosition('1188'));
    } catch (e) {
      try {
        mediaQueryListObject1188.addListener(() => changeCardsPosition('1188'));
      }
      catch(e) {
        console.log(e);
      }
    }

    const mediaQueryListObject959 = window.matchMedia('(max-width: 959px)');
    try {
      mediaQueryListObject959.addEventListener('change', () => changeCardsPosition('959'));
    } catch (e) {
      try {
        mediaQueryListObject959.addListener(() => changeCardsPosition('959'));
      }
      catch(e) {
        console.log(e);
      }
    }

    const mediaQueryListObject654 = window.matchMedia('(max-width: 654px)');
    try {
      mediaQueryListObject654.addEventListener('change', () => changeCardsPosition('654'));
    } catch (e) {
      try {
        mediaQueryListObject654.addListener(() => changeCardsPosition('654'));
      }
      catch(e) {
        console.log(e);
      }
    }

    const mediaQueryListObject420 = window.matchMedia('(max-width: 420px)');
    try {
      mediaQueryListObject420.addEventListener('change', () => changeCardsPosition('420'));
    } catch (e) {
      try {
        mediaQueryListObject420.addListener(() => changeCardsPosition('420'));
      }
      catch(e) {
        console.log(e);
      }
    }

    if (window.matchMedia('(max-width:420px)').matches) {
      setPositionsArray(mensajesPosition.b420);
    } else if (window.matchMedia('(max-width:654px)').matches) {
      setPositionsArray(mensajesPosition.b654);
    } else if (window.matchMedia('(max-width:959px)').matches) {
      setPositionsArray(mensajesPosition.b960);
    } else if (window.matchMedia('(max-width:1188px)').matches) {
      setPositionsArray(mensajesPosition.b1188);
    }

    return () => {
      mediaQueryListObject1188.removeEventListener('change', () => changeCardsPosition('1188'));
      mediaQueryListObject959.removeEventListener('change', () => changeCardsPosition('959'));
      mediaQueryListObject654.removeEventListener('change', () => changeCardsPosition('654'));
      mediaQueryListObject420.removeEventListener('change', () => changeCardsPosition('420'));
    };
  }, []);

  return (
    <ThemeProvider theme='inverted'>
      <CardsWrapper>
        {mensajes.map((mensaje, i) => {
          return (
            <CSSTransition
              in={
                i === 0 ? showCard1
                  : i === 1 ? showCard2
                    : i === 2 ? showCard3
                      : i === 3 ? showCard4
                        : showCard5
              }
              timeout={150}
              classNames="card"
            >
              <Card
                number={i}
                bgColor={mensaje.bgColor}
                key={i}
                position={positionsArray[i+1]}
              >
                <Text appearance={TokenTextAppearance.copy200}>{mensaje.text}</Text>
              </Card>
            </CSSTransition>
          );
        })}
      </CardsWrapper>
    </ThemeProvider>
  );
}
