import React, {useEffect, useState} from 'react';
import { LayerScreen, FullScreen } from '.';
interface RenderControllerProps {
  screenType: 'layer-screen' | 'full-screen';
  title: JSX.Element;
  fields: JSX.Element;
  key: number;
  avoidTrackFormStepLoad?: boolean;
  thankYouPage?: boolean;
}

export const RenderController = (props: RenderControllerProps) => {
  const { title, fields, key, avoidTrackFormStepLoad, screenType, thankYouPage } = props;

  return (
    <>
      {screenType ? (
        <>
          {screenType === 'layer-screen' ? (
            <LayerScreen title={title} fields={fields} key={key} avoidTrackFormStepLoad={avoidTrackFormStepLoad} />
          ) : (
            <FullScreen title={title} fields={fields} key={key} avoidTrackFormStepLoad={avoidTrackFormStepLoad} thankYouPage={thankYouPage} />
          )}
        </>
      ) : null}
    </>
  );
};
