import React, { useState, useEffect} from 'react';
import { Text, TextAlignment, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';
import { RatingStars} from 'src/components';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackingManager } from 'src/feature-app/hooks';
import { OneElementTemplateFullScreen } from 'src/feature-app';
import { OneFormState } from 'src/types';
import {LastNavigationFidelizacion} from '.';

const CTAWrapper = styled.div`
  text-align: left;
  margin: -10px;
  margin-top: 32px;
  & > button {
    margin: 12px;
    width: 81px;
  }
`;

const ErrorMessage = styled.div`
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #e4002c;
  color: #ffffff;
`;

function Fields() {
  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const [ selectedValue, setSelectedValue] = useState<string>('');
  const { control, register, setValue } = useFormContext();
  const { fields } = useSelector((state: OneFormState) => state.formData);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (fields.enviarInformacion) setSelectedValue(fields.enviarInformacion);
  }, []);

  return (
    <>
      <RatingStars name="otrosServicios" nextOnClick={true} textGood='mayor importancia' textBad='menor importancia' />
      {
        showError ? (
          <ErrorMessage>
            <Text appearance={TokenTextAppearance.label150} color={TokenTextColor.inherit} textAlign={TextAlignment.left}>
              Necesitamos esta información para seguir
            </Text>
          </ErrorMessage>
        ) : null
      }
      <LastNavigationFidelizacion setShowError={setShowError}/>
    </>
  );
}

export function FidelizacionLastScreenFields() {
  return (
    <OneElementTemplateFullScreen element={<Fields />} />
  );
}
