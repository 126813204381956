import { getOneformBFFCdnServiceConfig } from 'src/feature-app';

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving OneForm BFF CDN service config provider.');
  return null;
};

export const getOneformBFFCdnUrl = (): string | null => {
  const oneformBFFCdn = getOneformBFFCdnServiceConfig();

  if (!oneformBFFCdn) {
    return handleErrorInRetrievingExternalConfig();
  }

  return (testProduction() ? oneformBFFCdn.urlOrigin.replace('pre-', '') : oneformBFFCdn.urlOrigin) + oneformBFFCdn.urlPath;
};

const testProduction = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('test') && urlParams.get('test').includes('PROD');
}