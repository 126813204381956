import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend, getVehiculosModelo } from 'src/bff';
import { FechaCompra, PantallaIntroduccionStars, SelectMarcaModelo, MultipleCTASelection } from 'src/components';
import { FormController, useOneFormContext } from 'src/feature-app';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { formDataBuilder } from 'src/forms/format-fields';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { useInitializeNoCompraForm, NoCompraFormProps, ConfirmacionNoCompra, ThankYouNoCompra, getCommonFormData, RatingSteps } from '.';

export enum NoCompraYaTengoSteps {
  //FechaCompra,
  TipoCompra,
  Modelo,
  Valoracion,
  Plazos,
	Visita,
	Precio,
	Equipamiento,
	Consumo,
	Garantia,
	Financiacion,
  Confirmacion,
}

export function NoCompraYaTengo({ dpsData, idLead, idPerson, handleError, trackFormLoad, estado }: NoCompraFormProps) {
  const { initializeForm, nextStep } = useOneFormContext();
  const trackingManager = useTrackingManager();
  const store = useStore();
  const dispatch = useDispatch();
  const { formData } = useSelector((state: OneFormState) => state);
  const [loadingPresubmit, setLoadingPresubmit] = useState(true);

  const ctasTipoCompra = [
    {
      label: 'He comprado un vehículo nuevo',
      value: '1'
    },
    {
      label: 'He comprado un vehículo de ocasión',
      value: '2'
    },
  ];
  
  /*
  const getModeloInteresOutputs = (modelo: string, list: any) => {
    const aux = list.find((mod: any) => mod.name === 'modelo');
    return [aux.title];
  };

  const { modelo } = formData;

	useEffect(() => {
    if (modelo) updateModelo(modelo);
  }, [modelo]);

  const updateModelo = (modelo: string) => {
    const outputs = getModeloInteresOutputs(modelo, list);

    let newSteps = [...steps];

    const modeloIndex = newSteps.findIndex((step: Steps) => step.name === 'Modelo');
    newSteps[modeloIndex].outputsText = outputs;

    dispatch({ type: 'UPDATE_STEPS', payload: newSteps });
  };*/

  const [list, setList] = useState(null);

  useEffect(() => {
    getVehiculosModelo().then((modelos) => {
      setList(modelos);
    });
  }, []);

  useEffect(() => {
    const sendPresubmit = async () => {
      const result = await sendForm(true);

      if (!result) {
        handleError();
      }
      setLoadingPresubmit(false);
    };

    sendPresubmit();
  }, []);

  const steps: Steps[] = [
    /*{
      title: (
        <>
          Nos encantaría saber más. <Text bold>¿Desde cuándo tienes coche nuevo?</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ marginBottom: '2px' }}>
              <FechaCompra notALayer={true} />
            </div>
          }
        />
      ),
      screenIndex: NoCompraYaTengoSteps.FechaCompra,
      name: 'FechaCompra',
    },*/
    {
      title: (
        <>
          <Text bold>¿Podrías indicarnos si has comprado finalmente</Text> un vehículo nuevo o de ocasión?
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={ctasTipoCompra} name="tipoCompra" nextOnClick />}
        />
      ),
      screenIndex: NoCompraYaTengoSteps.TipoCompra,
      name: 'TipoCompra',
    },
    {
      title: (
        <>
          Por curiosidad,{' '}
          <Text bold>
            ¿qué modelo{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              es?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          allowOverflowingContent
          element={
            <SelectMarcaModelo stepName="Modelo" brandName="marca" modelName="modelo" required={true} formToken="VW_DDB_FRM_ENCUESTA_NO_COMPRA"/>
          }
        />
      ),
      screenIndex: NoCompraYaTengoSteps.Modelo,
      name: 'Modelo',
      showOverflow: true,
    },
    {
      title: (
        <>
          Nos hubiera encantado que fuera un Volkswagen. <Text bold>¿Nos ayudas a mejorar?</Text>
        </>
      ),
      fields: <PantallaIntroduccionStars />,
      screenIndex: NoCompraYaTengoSteps.Valoracion,
      name: 'Valoracion',
    },

    ...RatingSteps('ya_tengo',  NoCompraYaTengoSteps.Valoracion),
    
    {
      fields: <ConfirmacionNoCompra idLead={idLead} />,
      screenIndex: NoCompraYaTengoSteps.Confirmacion,
      name: 'Confirmacion',
    },
  ];

  const NuevoFormData: Partial<FormDataGroup> = {
    headers: {},
		fields: { 
      formName: 'no_compra-ya_tengo', 
      formType: 'otherform', 
      pageCategory: 'Encuesta' 
    }
  };

  const generateData = (isPresubmit: boolean, data: Partial<FormDataGroup>) => {
    const { formData }: OneFormState = store.getState();
    const { fields } = formData; 

		const submitData = !isPresubmit ? {
      //'fields[SURVEY_ANSWER_02]': fields.fecha,
      'fields[SURVEY_ANSWER_02]': fields.tipoCompra,
      'fields[SURVEY_ANSWER_03]': fields.marca,
      'fields[SURVEY_ANSWER_04]': fields.modelo,
      'fields[SURVEY_ANSWER_05]': fields.plazos,
      'fields[SURVEY_ANSWER_06]': fields.visita,
      'fields[SURVEY_ANSWER_08]': fields.precio,
      'fields[SURVEY_ANSWER_09]': fields.equipamiento,
      'fields[SURVEY_ANSWER_10]': fields.consumo,
      'fields[SURVEY_ANSWER_11]': fields.garantia,
      'fields[SURVEY_ANSWER_12]': fields.financiacion,
      'flags[FLAG_SF_INTEGRATION]' : false,
		} : null;

    return {
			...getCommonFormData(isPresubmit, idLead, dpsData, fields, data),
			...submitData,
    };
  };

  const sendForm = async (isPresubmit?: boolean): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateData(isPresubmit, NuevoFormData);
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData, isPresubmit);

      if (response?.data?.status?.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { firstStep, lastStep, loading } = useInitializeNoCompraForm(steps);

  useEffect(() => {
    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps,
      stepsHistory: [0],
      thankYouComponent: <ThankYouNoCompra />,
    };

    if (!loading) {
      initializeForm(NuevoFormInfo, NuevoFormData, true);
      trackFormLoad();
    }
  }, [loading]);

  return loading || loadingPresubmit || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
