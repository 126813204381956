import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  ContainerPadding,
  Layout,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFeatureAppConfig, useTrackingManager, TrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { FormDataFields, FormInfo, OneFormState, Steps } from 'src/types';
import { FacebookSvg, TwitterSvg, WhatsappSvg } from '../assets/Svgs';

interface FieldProps {
  copyBook?: string;
  cliente: string;
}

interface NDPSField {
  name: string;
  value: string;
}

const Anchor = styled.a`
  outline: none;
  color: none;
`;

function Fields() {
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const config = useFeatureAppConfig();

  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
      }}
    >
      <div style={{ textAlign: 'left', paddingBottom: '4px', width: '100%' }}>
        <Container padding={{ top: ContainerPadding.dynamic0100 }} gutter={ContainerGutter.static500} shrinkContent>
          <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
            {formData.fields.cliente === 'true'
              ? 'Tu madre recibirá tu “te quiero” en su bandeja de entrada y, quizá, también un fin de semana contigo al volante del Nuevo ID.4. ¡Mucha suerte! '
              : 'Tu madre está a punto de recibir tu “te quiero” en su bandeja de entrada. ¡Le alegrarás el día! '}
          </Text>
          <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
            ¿Conoces a más conductores/as Volkswagen? Comparte esta página para que ninguna madre se quede sin su “te
            quiero”.
          </Text>
        </Container>
        <Container padding={{ top: ContainerPadding.dynamic0100 }} gutter={ContainerGutter.dynamic0100}>
          <Anchor
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.volkswagen.es%2Fes%2Fdia-de-la-madre.html&quote=Tu%20madre%20y%20tú%20disfrutando%20de%20un%20finde%20en%20un%20parador%20con%20el%20Nuevo%20ID.4.%20Felicítala,%20y%20este%20podrá%20ser%20vuestro%20plan%20para%20el%20Día%20de%20la%20Madre.%20¡Dile%20que%20la%20quieres!"
            target="_blank"
          >
            <FacebookSvg />
          </Anchor>
          <Anchor
            href="https://twitter.com/intent/tweet?url=Tu%20madre%20y%20tú%20disfrutando%20de%20un%20finde%20en%20un%20parador%20con%20el%20Nuevo%20ID.4.%20Felicítala,%20y%20este%20podrá%20ser%20vuestro%20plan%20para%20el%20Día%20de%20la%20Madre.%20¡Dile%20que%20la%20quieres!%20https%3A%2F%2Fwww.volkswagen.es%2Fes%2Fdia-de-la-madre.html"
            target="_blank"
          >
            <TwitterSvg />
          </Anchor>
          <Anchor
            href="whatsapp://send?text=Tu%20madre%20y%20tú%20disfrutando%20de%20un%20finde%20en%20un%20parador%20con%20el%20Nuevo%20ID.4.%20Felicítala,%20y%20este%20podrá%20ser%20vuestro%20plan%20para%20el%20Día%20de%20la%20Madre.%20¡Dile%20que%20la%20quieres!%20https%3A%2F%2Fwww.volkswagen.es%2Fes%2Fdia-de-la-madre.html"
            target="_blank"
          >
            <WhatsappSvg />
          </Anchor>
        </Container>
      </div>
    </Layout>
  );
}

interface ThankYouProps {
  formData: FormDataFields;
  formInfo: FormInfo;
}

function ThankYouDiaDeLaMadre(props: ThankYouProps) {
  const { formData, formInfo } = props;
  const { formResponse } = formInfo;

  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const getTrackingForm = (): TrackingForm => {
    let LeadID;
    if (formResponse && formResponse.content && formResponse.content.data && formResponse.content.data.fields) {
      const leadField: NDPSField = formResponse.content.data.fields.find(
        (field: NDPSField) => field.name === 'COD_LEAD_ID'
      );
      LeadID = leadField ? leadField.value : undefined;
    }
    return {
      LeadID,
      FormFields: undefined,
    };
  };

  useEffect(() => {
    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm()
    );
  }, []);

  const renderTitle = () => {
    return (
      <>
        <BreakpointWrapper max={Breakpoints.b1600}>
          <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
            <Text bold>Seguro que le encanta tu mensaje</Text>
          </Text>
        </BreakpointWrapper>
        <BreakpointWrapper min={Breakpoints.b1600}>
          <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
            <Text bold>Seguro que le encanta tu mensaje</Text>
          </Text>
        </BreakpointWrapper>
      </>
    );
  };

  const screen: Steps = {
    title: <>{renderTitle()}</>,
    fields: <Fields />,
  };
  return (
    <RenderController
      screenType="layer-screen"
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
    />
  );
}

const mapStateToProps = (state: OneFormState) => {
  return {
    formData: state.formData.fields,
    formInfo: state.formInfo,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ThankYouDiaDeLaMadre);

export { ConnectedComponent as ThankYouDiaDeLaMadre };
