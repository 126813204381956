import { checkIfMidDay, checkIfMorning, getNormalizedDate } from 'src/feature-app/NewMap';
import {
  getYYYYMMDDx, 
  hourAndMinute,
} from 'src/feature-app';

export const getFranjaAndDayFrom = (from: number): { day: string; franjaHoraria: 'Mañana' | 'Mediodía' | 'Tarde' } => {
  const day = getYYYYMMDDx(Number(from));
  const hour = hourAndMinute(from);
  const normalizdedDate = getNormalizedDate(hour);
  const franjaHoraria = checkIfMorning(normalizdedDate)
    ? 'Mañana'
    : checkIfMidDay(normalizdedDate)
    ? 'Mediodía'
    : 'Tarde';

  return { day, franjaHoraria };
};
