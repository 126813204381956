import axios, { AxiosResponse } from 'axios';
import { getMolecularUrl, getOneformBFFUrl, getOneformBFFApiKey, reportErrorSentry } from 'src/bff';

export interface PincodeData {
  status: { result: string; timestamp: string; code: number; time: number; };
  params: { pincode: string; token: string };
  content: PincodeResponseOk[] | PincodeResponseKo;
}

export interface PincodeResponseOk {
  attributes: PincodeDataAttributes[];
}
export interface PincodeResponseKo {
  code: string;
  data: string;
  message: string;
  status: string;
}
export interface PincodeDataAttributes {
  name: string;
  value: string;
}

export const getPincode = async (
  pincode: string,
	useBff?: boolean,
  token?: string
): Promise<{ pincodeData: PincodeData; pincodeUsed: boolean; pincodeError: boolean }> => {
  try {
    if (!token) {
      token = getPincodeToken(pincode);

      if (!token) {
        return { pincodeData: null, pincodeUsed: false, pincodeError: true };
      }
    }

		let dataResponse: AxiosResponse<PincodeData>;

		if (useBff) {
			const oneformBFFUrl = getOneformBFFUrl();
			const oneformBFFApiKey = getOneformBFFApiKey();
			dataResponse = await axios.get(`${oneformBFFUrl}/ndps/preload?pincode=${pincode}&token=${token}`, {
				headers: {
					'X-Api-Key': oneformBFFApiKey
				}
			});
		}
		else { 
			const MOLECULAR_URL = getMolecularUrl(); // Legacy
			dataResponse = await axios.get(
				`${MOLECULAR_URL}/request-dps-pincode.php?pincode=${pincode}&token=${token}`
			);
		}

		const pincodeData = dataResponse;

    if (pincodeData && pincodeData.data && 
			(pincodeData.data.status.code === 1 || pincodeData.data.status.code === 200)) {
      return { pincodeData: pincodeData.data, pincodeUsed: false, pincodeError: false };
    } else {
      if (
        pincodeData &&
        pincodeData.data &&
        pincodeData.data.content &&
        (pincodeData.data.content as PincodeResponseKo).message.includes('PINCODE_ALREADY_USED')
      ) {
        return { pincodeData: null, pincodeUsed: true, pincodeError: false };
      }

      return { pincodeData: null, pincodeUsed: false, pincodeError: true };
    }
  } catch (error) {
    console.error(error);
    reportErrorSentry(error);
    return { pincodeData: null, pincodeUsed: false, pincodeError: true };
  }
};

/**
 * El token viene codificado en base64 en el formato PINCODETOKEN.
 * @param pincode
 */
export const getPincodeToken = (pincode: string): string => {
  try {
    const pincodeDecoded = atob(pincode).split(/(?=VW)/g);
    return pincodeDecoded[pincodeDecoded.length - 1];
  } catch (error) {
    reportErrorSentry(error);
    return null;
  }
};
