import { Breakpoints, Layout, LayoutRowGap, Text } from '@volkswagen-onehub/components-core';
import React from 'react';
import { CarlineSelect, PantallaNombreApellido, MultipleCTASelection, RatingStars, PantallaIntroduccionStars, DetalleMotivo, } from 'src/components';
import { Steps } from 'src/types';
import { CTAsSiNo } from 'src/feature-app/InputsValues/CTAsObjects';
import { ChecksEnvio, ctasCheckEnvio } from 'src/forms/Cem/ChecksEnvio';
import { ConfirmacionCem } from 'src/forms/Cem/ConfirmacionCem';
import { FirstStepCEMVentaProducto } from './FirstStepCEMVentaProducto';
import { valueWrongConcesion, valueWrongMarca, valueWrongModelo, valueWrongPersona } from '../helpers';

function OneElementTemplateFullScreen(props: any) {
	const { element, allowOverflowingContent } = props;

	return (
		<Layout
			allowOverflowingContent
			appearance={{
				[Breakpoints.default]: [
					{ name: '.', columns: 2 },
					{ name: 'a', columns: 20 },
					{ name: '.', columns: 2 },
				],
				[Breakpoints.b960]: [
					{ name: '.', columns: 3 },
					{ name: 'a', columns: 18 },
					{ name: '.', columns: 3 },
				],
				[Breakpoints.b1280]: [
					{ name: '.', columns: 5 },
					{ name: 'a', columns: 14 },
					{ name: '.', columns: 5 },
				],
				[Breakpoints.b1920]: [
					{ name: '.', columns: 6 },
					{ name: 'a', columns: 12 },
					{ name: '.', columns: 6 },
				],
				[Breakpoints.b2560]: [
					{ name: '.', columns: 7 },
					{ name: 'a', columns: 10 },
					{ name: '.', columns: 7 },
				],
			}}
			rowGap={LayoutRowGap.static300}
		>
			{element}
		</Layout>
	);
}

enum CemSteps {
	DatosCorrectos,
	DatosIncorrectos,
	Modelo,
	ConfirmacionConcesion,
	Explicacion,

	// begin random
	SatisfaccionDiseno,
	SatisfaccionDisenoMotivo,
	SatisfaccionUso,
	SatisfaccionUsoMotivo,
	SatisfaccionTecnologia,
	SatisfaccionTecnologiaMotivo,
	SatisfaccionCalidad,
	SatisfaccionCalidadMotivo,
	SatisfaccionConduccion,
	SatisfaccionConduccionMotivo,
	// end random
	SatisfaccionElectrico,
	SatisfaccionElectricoMotivo,
	SatisfaccionGeneral,
	SatisfaccionGeneralMotivo,
	SatisfaccionExperiencia,
	SatisfaccionEntrega,

	EnviarConcesion,
	DatosPersonales,
	Confirmacion,
}

export const getStepsVentaProducto = (isElectrico?: boolean) => {

	const ctaDatosIncorrectos = [
		{ label: 'Marca incorrecta', value: valueWrongMarca.toString() },
		{ label: 'Modelo incorrecto', value:  valueWrongModelo.toString() },
		{ label: 'Punto de venta incorrecto', value:  valueWrongConcesion.toString() },
		{ label: 'Persona de contacto incorrecta', value:  valueWrongPersona.toString() },
	];

	const firstStep = {
		fields: <FirstStepCEMVentaProducto />,
		screenIndex: CemSteps.DatosCorrectos,
		name: 'DatosCorrectos',
		alternateTitle: <Text>¿Puedes confirmar que estos datos son correctos?</Text>,
		outputs: ['datosCorrectos'],
		outputsText: ['datosCorrectos'],
		trueOrFalseQuestion: true,
	};

	const confirmacionDatos = {
		title: (
			<>
				Por favor, <Text bold>¿puedes confirmarnos qué dato no es correcto?</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<MultipleCTASelection
						ctaValues={ctaDatosIncorrectos}
						name="datosIncorrectos"
						nextOnClick
					/>}
			/>
		),
		screenIndex: CemSteps.DatosIncorrectos,
		name: 'DatosIncorrectos',
		outputs: ['datosIncorrectos'],
		outputsText: ['datosIncorrectos'],
		ctas: ctaDatosIncorrectos,
	};

	const compraVehiculo = {
		title: <Text bold>¿Has comprado tu nuevo coche en [Concesionario]?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<MultipleCTASelection
						ctaValues={CTAsSiNo}
						name='confirmacionConcesion'
						nextOnClick ctaWidth="81"
					/>}
			/>
		),
		screenIndex: CemSteps.ConfirmacionConcesion,
		name: 'ConfirmacionConcesion',
		outputs: ['confirmacionConcesion'],
		outputsText: ['confirmacionConcesion'],
	};

	const modeloCorrecto = {
		title: <Text bold>¿Cuál es el modelo correcto?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ marginBottom: '2px' }}>
						<CarlineSelect isCEMVenta useCarline />
					</div>
				}
			/>
		),
		screenIndex: CemSteps.Modelo,
		name: 'Modelo',
		outputs: ['modelo'],
		outputsText: ['modelo'],
	};

	const inicio = {
		title: (
			<>
				A continuación, por favor,{' '}
				<Text bold>
					indica tu nivel de satisfacción con aspectos de tu nuevo coche.
				</Text>
			</>
		),
		fields: <PantallaIntroduccionStars bodyCopy="Valora del 1 al 5, a mayor número de estrellas más satisfacción." />,
		screenIndex: CemSteps.Explicacion,
		name: 'Explicacion',
	};

	const preguntaMotivo = <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>;
	const recomendacionMotivo = 'Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan identificarte a ti o a nuestros empleados.';

	const Q1 = {
		title: <Text bold>¿Qué puntuación le das al diseño, tanto interior como exterior?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionDiseno"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionDiseno,
		name: 'SatisfaccionDiseno',
		outputs: ['satisfaccionDiseno'],
		outputsText: ['satisfaccionDiseno'],
		outputsIsStars: true,
	};

	const C1 = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionDisenoMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionDisenoMotivo,
		name: 'SatisfaccionDisenoMotivo',
		outputs: ['satisfaccionDisenoMotivo'],
		outputsText: ['satisfaccionDisenoMotivo'],
	};

	const Q2 = {
		title: <Text bold>¿Te parece cómodo y fácil de conducir?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionUso"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionUso,
		name: 'SatisfaccionUso',
		outputs: ['satisfaccionUso'],
		outputsText: ['satisfaccionUso'],
		outputsIsStars: true,
	};

	const C2 = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionUsoMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionUsoMotivo,
		name: 'SatisfaccionUsoMotivo',
		outputs: ['satisfaccionUsoMotivo'],
		outputsText: ['satisfaccionUsoMotivo'],
	};

	const Q3 = {
		title: <Text bold>¿Cómo valoras el software y funciones de conectividad de tu vehículo?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionTecnologia"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionTecnologia,
		name: 'SatisfaccionTecnologia',
		outputs: ['satisfaccionTecnologia'],
		outputsText: ['satisfaccionTecnologia'],
		outputsIsStars: true,
	};

	const C3 = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionTecnologiaMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionTecnologiaMotivo,
		name: 'SatisfaccionTecnologiaMotivo',
		outputs: ['satisfaccionTecnologiaMotivo'],
		outputsText: ['satisfaccionTecnologiaMotivo'],
	};

	const Q4 = {
		title: <Text bold>¿Cómo de satisfecho estás con la calidad de tu Volkswagen?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionCalidad"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionCalidad,
		name: 'SatisfaccionCalidad',
		outputs: ['satisfaccionCalidad'],
		outputsText: ['satisfaccionCalidad'],
		outputsIsStars: true,
	};

	const C4 = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionCalidadMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionCalidadMotivo,
		name: 'SatisfaccionCalidadMotivo',
		outputs: ['satisfaccionCalidadMotivo'],
		outputsText: ['satisfaccionCalidadMotivo'],
	};

	const Q5 = {
		title: <Text bold>¿Qué puntuación le darías a la experiencia de conducción con tu nuevo coche?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionConduccion"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionConduccion,
		name: 'SatisfaccionConduccion',
		outputs: ['satisfaccionConduccion'],
		outputsText: ['satisfaccionConduccion'],
		outputsIsStars: true,
	};

	const C5 = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionConduccionMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionConduccionMotivo,
		name: 'SatisfaccionConduccionMotivo',
		outputs: ['satisfaccionConduccionMotivo'],
		outputsText: ['satisfaccionConduccionMotivo'],
	};

	const Q6 = {
		title: <Text bold>¿Cómo de satisfecho estás con el proceso de carga en casa y en lugares públicos?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionElectrico"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionElectrico,
		name: 'SatisfaccionElectrico',
		outputs: ['satisfaccionElectrico'],
		outputsText: ['satisfaccionElectrico'],
		outputsIsStars: true,
	};

	const C6 = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionElectricoMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionElectricoMotivo,
		name: 'SatisfaccionElectricoMotivo',
		outputs: ['satisfaccionElectricoMotivo'],
		outputsText: ['satisfaccionElectricoMotivo'],
	};

	const Q7 = {
		title: <Text bold>¿Y cómo de satisfecho estás con tu nuevo Volkswagen en general?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionGeneral"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionGeneral,
		name: 'SatisfaccionGeneral',
		outputs: ['satisfaccionGeneral'],
		outputsText: ['satisfaccionGeneral'],
		outputsIsStars: true,
	};

	const C7 = {
		title: preguntaMotivo,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionGeneralMotivo"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionGeneralMotivo,
		name: 'SatisfaccionGeneralMotivo',
		outputs: ['satisfaccionGeneralMotivo'],
		outputsText: ['satisfaccionGeneralMotivo'],
	};

	const C7Experiencia = {
		title: <Text bold>¿Hay algo más que te gustaría comentarnos sobre tu experiencia con tu Volkswagen?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<DetalleMotivo
						required={true}
						name="satisfaccionExperiencia"
						showRecomendation={recomendacionMotivo}
						numberOfMaxLength={300}
						CTALabel="Sin respuesta"
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionExperiencia,
		name: 'SatisfaccionExperiencia',
		outputs: ['satisfaccionExperiencia'],
		outputsText: ['satisfaccionExperiencia'],
	};

	const Q8 = {
		title: <Text bold>¿Cuál es tu nivel de satisfacción con la ayuda que te hemos dado desde la entrega de tu vehículo?</Text>,
		fields: (
			<OneElementTemplateFullScreen
				element={
					<RatingStars
						name="satisfaccionEntrega"
						nextOnClick={true}
						textGood="satisfecho/a"
						textBad="insatisfecho/a"
						CTASinRespuesta
					/>
				}
			/>
		),
		screenIndex: CemSteps.SatisfaccionEntrega,
		name: 'SatisfaccionEntrega',
		outputs: ['satisfaccionEntrega'],
		outputsText: ['satisfaccionEntrega'],
		outputsIsStars: true,
	};

	const enviarConcesion = {
		title: (
			<>
				Finalmente nos gustaría saber si{' '}
				<Text bold>
					podemos enviar tus respuestas junto con tu nombre
				</Text>
				{' '}al punto de venta [Concesionario] y el bastidor del vehículo, y puedan usarlos para análisis adicionales.
			</>
		),
		fields: <OneElementTemplateFullScreen element={<ChecksEnvio name="enviarConcesion" isProducto />} />,
		screenIndex: CemSteps.EnviarConcesion,
		name: 'EnviarConcesion',
		outputs: ['enviarConcesion'],
		outputsText: ['enviarConcesion'],
		ctas: ctasCheckEnvio(false, true)
	};

	const datosPersonales = {
		title: (
			<>
				Por favor, <Text bold>introduce o comprueba tus datos:</Text>
			</>
		),
		fields: (
			<OneElementTemplateFullScreen
				element={
					<div style={{ paddingBottom: '2px' }}>
						<PantallaNombreApellido />
					</div>
				}
			/>
		),
		screenIndex: CemSteps.DatosPersonales,
		name: 'DatosPersonales',
		outputs: ['name', 'surname', 'secondSurname'],
		outputsText: ['name', 'surname', 'secondSurname'],
	};

	const confirmacion = {
		fields: <ConfirmacionCem />,
		screenIndex: CemSteps.Confirmacion,
		name: 'Confirmacion',
		outputs: ['confirmacion'],
		outputsText: ['confirmacion'],
	};

	const steps: Steps[] = [
		firstStep,
		confirmacionDatos,
		compraVehiculo,
		modeloCorrecto,
		inicio,
		Q1,
		C1,
		Q2,
		C2,
		Q3,
		C3,
		Q4,
		C4,
		Q5,
		C5,
		// position #15
		Q7,
		C7,
		C7Experiencia,
		Q8,
		enviarConcesion,
		datosPersonales,
		confirmacion,
	];

	if (isElectrico) {
		steps.splice(15, 0, Q6, C6); // add electric question and comment
	}

	return steps;
};
