import {
  Container,
  ContainerGutter,
  CTA,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { Alert } from 'src/icons-core-imports';
import { CloseHandleV2 } from '@volkswagen-onehub/layer-manager';
import React from 'react';

const Content = styled.div`
  background-color: #ffffff;
  padding: 44px 31px;
  flex: auto;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 560px) {
    padding: 52px 46px;
  }
  @media screen and (min-width: 960px) {
    padding: var(--size-grid001);
  }
`;

interface LayerComponentProps {
  closeLayerCallback?: CloseHandleV2<any, any>;
}

export function AvailabilityErrorLayer(props: LayerComponentProps) {
  return (
    <Content>
      <AvailabilityError {...props} />
    </Content>
  );
}

interface SlotHorario {
  from: number;
  to: number;
}

function AvailabilityError(props: LayerComponentProps) {
  const { closeLayerCallback } = props;

  return (
    <Container
      gutter={ContainerGutter.dynamic0100}
      wrap={'always'}
      horizontalAlign={'flex-start'}
    >
      <div style={{ textAlign: 'left', color: '#001E50' }}>
        <Alert variant="large" />
      </div>
      <Text appearance={TokenTextAppearance.headline300} bold textAlign={TextAlignment.left}>
        Uy, algo ha pasado
      </Text>
      <Container
        gutter={ContainerGutter.static500}
        wrap={'always'}
        horizontalAlign={'flex-start'}
      >
        <Text textAlign={TextAlignment.left}>
          No hemos podido cargar los datos que nos pides. ¿Lo intentamos de nuevo en un rato?
        </Text>
        <CTA
          tag="button"
          emphasis="primary"
          onClick={(e) => {
            e.preventDefault();
            closeLayerCallback({}, {}, {});
          }}
        >
          Cerrar
        </CTA>
      </Container>
    </Container>
  );
}
