import axios, { AxiosResponse } from 'axios';
import { CarlineBase } from 'src/types';
import { getDataUrl, reportErrorSentry } from 'src/bff';

export const getCarlines = async (): Promise<CarlineBase[]> => {
  try {
    const dataUrl = getDataUrl();
  
    const response: AxiosResponse<{ carlines: CarlineBase[] }> = await axios.get(`${dataUrl}/getCarlines`);
    return response.data.carlines;
    
  } catch (error) {
    reportErrorSentry(error);
    return null;
  }
};
