import { Center, FormInfo, Geometry, Steps } from 'src/types';
import { Suggestion } from '../NewMap';

export const RESET_FORM_INFO = 'RESET_FORM_INFO';
export const SEND_FORM = 'SEND_FORM';
export const SET_FORM_INFO = 'SET_FORM_INFO';
export const SET_FORM_RESPONSE = 'SET_FORM_RESPONSE';
export const HIDE_LEGAL = 'HIDE_LEGAL';
export const SET_CUSTOM_LEGAL = 'SET_CUSTOM_LEGAL';
export const SET_SHOW_CALENDAR_ON_LOAD = 'SET_SHOW_CALENDAR_ON_LOAD';
export const SET_DELETE_CLOSE_AND_PREVIOUS = 'SET_DELETE_CLOSE_AND_PREVIOUS';
export const SET_FORM_SUBMITTED = 'SET_FORM_SUBMITTED';
export const UPDATE_LITERAL_FIELDS_STEPS = 'UPDATE_LITERAL_FIELDS_STEPS';
export const SET_INITIAL_STEPS = 'SET_INITIAL_STEPS';
export const SET_MOLECULAR_SERVICE_CONFIG = 'SET_MOLECULAR_SERVICE_CONFIG';
export const SET_NUMBER_OF_SCREENS = 'SET_NUMBER_OF_SCREENS';
export const SET_SEND_DMS_FAILED = 'SET_SEND_DMS_FAILED';
export const SET_STEPS_HISTORY = 'SET_STEPS_HISTORY';
export const SET_NAVIGATION_MOVING_FORWARD = 'SET_NAVIGATION_MOVING_FORWARD';
export const SHOW_FINAL_SCREEN = 'SHOW_FINAL_SCREEN';
export const SUGGESTION_IS_DEALER = 'SUGGESTION_IS_DEALER';
export const SUGGESTION_IS_ADDRESS = 'SUGGESTION_IS_ADDRESS';
export const SUGGESTION_IS_IN_CANARY_ISLANDS = 'SUGGESTION_IS_IN_CANARY_ISLANDS';
export const TRIGGER_EXIT_ANIMATION = 'TRIGGER_EXIT_ANIMATION';
export const UPDATE_FIRST_MAP_LOAD = 'UPDATE_FIRST_MAP_LOAD';
export const UPDATE_FORM_ENDED = 'UPDATE_FORM_ENDED';
export const UPDATE_FULLSCREEN = 'UPDATE_FULLSCREEN';
export const UPDATE_GENERIC_ERROR = 'UPDATE_GENERIC_ERROR';
export const UPDATE_GEOMETRY = 'UPDATE_GEOMETRY';
export const DISABLE_TRACKING = 'DISABLE_TRACKING';
export const UPDATE_INITIAL_ZOOM = 'UPDATE_INITIAL_ZOOM';
export const UPDATE_LAST_STEP = 'UPDATE_LAST_STEP';
export const UPDATE_MULTISTEP_SCREENINDEX = 'UPDATE_MULTISTEP_SCREENINDEX';
export const UPDATE_MULTISTEPS = 'UPDATE_MULTISTEPS';
export const UPDATE_NEXT_STEP = 'UPDATE_NEXT_STEP';
export const UPDATE_SCREEN = 'UPDATE_SCREEN';
export const UPDATE_SEND_FORM = 'UPDATE_SEND_FORM';
export const UPDATE_STEP = 'UPDATE_STEP';
export const UPDATE_STEPS = 'UPDATE_STEPS';
export const UPDATE_STEPS_HISTORY = 'UPDATE_STEPS_HISTORY';
export const UPDATE_SUGGESTION = 'UPDATE_SUGGESTION';
export const UPDATE_USER_CENTER = 'UPDATE_USER_CENTER';
export const UPDATE_USER_GEOLOCATION = 'UPDATE_USER_GEOLOCATION';
export const UPDATE_WAIT_FOR_NEXT_STEP = 'UPDATE_WAIT_FOR_NEXT_STEP';

interface SetFormInfoAction {
  type: typeof SET_FORM_INFO;
  payload: Partial<FormInfo>;
}
interface SetFormResponseAction {
  type: typeof SET_FORM_RESPONSE;
  payload: any;
}
interface SetFormSubmittedAction {
  type: typeof SET_FORM_SUBMITTED;
  payload: boolean;
}
interface UpdateLiteralFieldsSteps {
  type: typeof UPDATE_LITERAL_FIELDS_STEPS;
  payload: [];
}
interface SetStepsHistoryAction {
  type: typeof SET_STEPS_HISTORY;
  payload: number[];
}
interface SetShowCalendarAction {
  type: typeof SET_SHOW_CALENDAR_ON_LOAD;
  payload: boolean;
}

interface DeleteCloseAndPrevious {
  type: typeof SET_DELETE_CLOSE_AND_PREVIOUS;
  payload: boolean;
}
interface SetCustomLegalAction {
  type: typeof SET_CUSTOM_LEGAL;
  payload: JSX.Element;
}
interface HideLegalAction {
  type: typeof HIDE_LEGAL;
  payload: boolean;
}
interface SetInitialStepsAction {
  type: typeof SET_INITIAL_STEPS;
  payload: Steps[];
}
interface SetNavigationMovingForwardAction {
  type: typeof SET_NAVIGATION_MOVING_FORWARD;
  payload: boolean;
}
interface SetSendDmsFailedAction {
  type: typeof SET_SEND_DMS_FAILED;
  payload: boolean;
}
interface SendFormAction {
  type: typeof SEND_FORM;
  payload: any;
}

interface ResetFormInfoAction {
  type: typeof RESET_FORM_INFO;
}
interface UpdateScreenAction {
  type: typeof UPDATE_SCREEN;
  payload: number;
}

interface UpdateStepsHistoryAction {
  type: typeof UPDATE_STEPS_HISTORY;
  payload: number;
}
interface UpdateInitialZoomAction {
  type: typeof UPDATE_INITIAL_ZOOM;
  payload: number;
}
interface UpdateStepAction {
  type: typeof UPDATE_STEP;
  payload: Steps;
}
interface UpdateLastStepAction {
  type: typeof UPDATE_LAST_STEP;
  payload: Steps;
}
interface UpdateStepsAction {
  type: typeof UPDATE_STEPS;
  payload: Steps[];
}
interface UpdateGenericErrorAction {
  type: typeof UPDATE_GENERIC_ERROR;
  payload: boolean;
}
interface UpdateWaitForNextStepAction {
  type: typeof UPDATE_WAIT_FOR_NEXT_STEP;
  payload: boolean;
}

interface TriggerExitAnimationAction {
  type: typeof TRIGGER_EXIT_ANIMATION;
  payload: boolean;
}

interface UpdateFirstMapLoadAction {
  type: typeof UPDATE_FIRST_MAP_LOAD;
  payload: boolean;
}
interface UpdateNextStepAction {
  type: typeof UPDATE_NEXT_STEP;
  payload: () => number;
}

interface UpdateSendFormAction {
  type: typeof UPDATE_SEND_FORM;
  payload: () => boolean;
}

interface ShowFinalScreenAction {
  type: typeof SHOW_FINAL_SCREEN;
  payload: boolean;
}

interface SetNumberOfScreensAction {
  type: typeof SET_NUMBER_OF_SCREENS;
  payload: number;
}

interface UpdateFullscreenAction {
  type: typeof UPDATE_FULLSCREEN;
  payload: boolean;
}
interface UpdateMultiStepsAction {
  type: typeof UPDATE_MULTISTEPS;
  payload: Steps[];
}
interface UpdateMultiScreenIndexAction {
  type: typeof UPDATE_MULTISTEP_SCREENINDEX;
  payload: number;
}

interface UpdateUserCenterAction {
  type: typeof UPDATE_USER_CENTER;
  payload: Center;
}

interface UpdateSuggestionIsDealer {
  type: typeof SUGGESTION_IS_DEALER;
  payload: boolean;
}
interface UpdateSuggestionIsInCanaryIslands {
  type: typeof SUGGESTION_IS_IN_CANARY_ISLANDS;
  payload: boolean;
}
interface UpdateSuggestionIsAddress {
  type: typeof SUGGESTION_IS_ADDRESS;
  payload: boolean;
}
interface UpdateSuggestion {
  type: typeof UPDATE_SUGGESTION;
  payload: Suggestion;
}
interface UpdateUserGeolocation {
  type: typeof UPDATE_USER_GEOLOCATION;
  payload: Suggestion;
}

interface UpdateFormEnded {
  type: typeof UPDATE_FORM_ENDED;
  payload: boolean;
}

interface UpdateGeometryAction {
  type: typeof UPDATE_GEOMETRY;
  payload: Geometry;
}
interface DisableTrackingAction {
  type: typeof DISABLE_TRACKING;
}

export type FormInfoActionTypes =
  | SetInitialStepsAction
  | ResetFormInfoAction
  | SendFormAction
  | SetCustomLegalAction
  | SetShowCalendarAction
  | DeleteCloseAndPrevious
  | HideLegalAction
  | SetFormInfoAction
  | SetFormResponseAction
  | SetFormSubmittedAction
  | UpdateLiteralFieldsSteps
  | SetNavigationMovingForwardAction
  | SetNumberOfScreensAction
  | SetSendDmsFailedAction
  | SetStepsHistoryAction
  | ShowFinalScreenAction
  | TriggerExitAnimationAction
  | UpdateFirstMapLoadAction
  | UpdateFormEnded
  | UpdateFullscreenAction
  | UpdateGenericErrorAction
  | UpdateGeometryAction
  | UpdateInitialZoomAction
  | UpdateLastStepAction
  | UpdateMultiScreenIndexAction
  | UpdateMultiStepsAction
  | UpdateNextStepAction
  | UpdateScreenAction
  | UpdateSendFormAction
  | UpdateStepAction
  | UpdateStepsAction
  | UpdateStepsAction
  | UpdateStepsHistoryAction
  | UpdateSuggestion
  | UpdateSuggestionIsAddress
  | UpdateSuggestionIsDealer
  | UpdateSuggestionIsInCanaryIslands
  | UpdateUserCenterAction
  | UpdateUserGeolocation
  | DisableTrackingAction
  | UpdateWaitForNextStepAction;
