import React, {useEffect} from 'react';
import {FormController, useOneFormContext} from 'src/feature-app';
import {FormDataGroup, FormInfo, Steps} from 'src/types';
import {ThankYouNuevaCita} from './ThankYouNuevaCita';

interface NuevaCitaPosventaFormProps {
	isCitaPosventaDealer?: boolean;
}

interface FormProps {
	defaultSteps: Steps[];
	sendForm: any;
	firstStep: Steps;
	CitaPosventaData: Partial<FormDataGroup>;
	stepsHistory?: number[];
}

export function NuevaCitaPosventaFormDefault({
	defaultSteps,
	sendForm,
	firstStep,
	CitaPosventaData,
	stepsHistory,
}: FormProps) {
	const {initializeForm, nextScreenIndex} = useOneFormContext();

	useEffect(() => {
		const lastStep = defaultSteps[defaultSteps.length - 1];

		const CitaPosventaInfo: Partial<FormInfo> = {
			formEnded: false,
			formStarted: true,
			sendFormCallback: sendForm,
			fullScreen: firstStep.fullScreen ? true : false,
			lastStep,
			numberOfScreens: defaultSteps.length,
			screenIndex: firstStep.screenIndex,
			step: firstStep,
			notALayer: false,
			formType: 'layer',
			formTheme: 'inverted',
			steps: defaultSteps,
			stepsHistory: stepsHistory ? stepsHistory : [],
			thankYouComponent: <ThankYouNuevaCita />,
		};

		initializeForm(CitaPosventaInfo, CitaPosventaData);
	}, []);

	return <FormController steps={defaultSteps} screenType="layer-screen" />;
}
