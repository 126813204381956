import {
  Breakpoints,
  Container,
  ContainerPadding,
  styled,
  Text,
  Layout,
  BreakpointWrapper,
  CTA,
  ContainerGutter,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useState } from 'react';
import {
  PoliticaPrivacidad,
  LastNavigation,
  LabelInputWidthWrapper,
  Nombre,
  Apellido,
  Telefono,
  Email,
  LegalItem
} from 'src/components';
import { IdPerson } from 'src/types';
import { LayerManagerWrapper, useFeatureAppEnvironment, useFeatureServices } from 'src/feature-app';
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import { useStore } from 'react-redux';

const GridWrapper = styled.div`
  width: 90%;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-gap: 5%;
  height: 180px;
  @media all and (min-width: 1600px) {
    height: 200px;
  }
`;

const GridItem = styled.div <{ row: string }>`
  grid-row: ${props => props.row};
`;

const TextBlue = styled(Text)`
  color: #001e50;
`;

const InputErrorLegales = styled.p`
  position: relative;
  margin-top: 10px;
  background-color: #e4002c;
  color: #ffffff;
  width: max-content;
  max-width: 280px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 10%;
  @media all and (max-width: 1280px) {
    padding-top: 2%;
  }
`;
interface ConfirmacionTestIDProps {
  idPerson: IdPerson;
}

const legalesTestID2023 = 'https://crm.volkswagen.es/email/2023/service/recertificacion_may23/Sorteo%20Test%20Ele%CC%81ctrico.pdf'

const infoLayerCopies = {
  lssi: {
    title: 'Estarás mejor informado.',
    body: 'Si nos das tu consentimiento desde Volkswagen Group España Distribución, S.A. como responsable de la marca Volkswagen podremos enviarte por cualquier medio, ya sea por teléfono, correo electrónico, sms o aplicaciones de mensajería instantánea información comercial lo que puede incluir, a título enunciativo, pero no limitativo, descuentos, novedades, próximos lanzamientos, invitaciones a eventos o a concursos, entre otras comunicaciones comerciales.',
  },
};

export const ConfirmationTestID = (props: ConfirmacionTestIDProps) => {
  const { idPerson } = props;
  const [compartir, setCompartir] = useState<string>(null);
  const [compartirError, setCompartirError] = useState<boolean>(false);
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const env = useFeatureAppEnvironment();
  const store = useStore();
  const handleInfoLayer = (
    key: 'lssi',
    renderFn: (state: any, close: CloseHandleV2<any, any>) => JSX.Element
  ) => {
    const layer = layerManager.openFocusLayer(
      renderFn,
      { ...infoLayerCopies[key] },
      { size: FocusLayerSizeV2.A, userCloseable: true }
    );

    return layer;
  };


  const renderInfo = (state: { title: string; body: string }, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <Container wrap={'always'} padding={ContainerPadding.static450}>
          <Container
            gutter={ContainerGutter.static200}
            padding={{ bottom: ContainerPadding.static300 }}
            wrap={'always'}
          >
            <Text bold>{state.title}</Text>
            <Text>{state.body}</Text>
          </Container>
          <CTA
            tag='button'
            emphasis='primary'
            onClick={(e) => {
              e.preventDefault();
              close({}, {}, {});
            }}
            size={'small'}
          >
            Entendido
          </CTA>
        </Container>
      </LayerManagerWrapper>
    );
  };

  return (
    <>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: 'a', columns: 24 },
          ],
          [Breakpoints.b1280]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ]
        }}
      >
        <Container padding={ContainerPadding.static400}>

          {!idPerson &&
            <Text appearance={TokenTextAppearance.headline300}>
              ¡Genial! Ya lo tenemos casi todo. Ahora solo falta que nos <Text bold>confirmes tus datos para recibir los resultados del test.</Text> ¿Serás un pro de los eléctricos?
            </Text>
          }
          {idPerson &&
            <Text appearance={TokenTextAppearance.headline300}>
              ¡Genial! Aunque el sorteo ya está cerrado, <Text bold>confírmanos tus datos para recibir los resultados del test.</Text> ¿Serás un pro de los eléctricos?
            </Text>
          }

        </Container>
      </Layout >
      <Layout
        allowOverflowingContent
        appearance={{
          [Breakpoints.default]: [
            { name: 'a', columns: 24 },
            { name: 'b', columns: 24 },
          ],
          [Breakpoints.b1280]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 13 },
            { name: '.', columns: 1 },
            { name: 'b', columns: 6 },
            { name: '.', columns: 2 },
          ]
        }}
      >
        <Container padding={{left: ContainerPadding.static400, right: ContainerPadding.static400}}>
          <BreakpointWrapper min={Breakpoints.b1280}>
            <GridWrapper>
              <GridItem row="1">
                <LabelInputWidthWrapper notALayer={true}>
                  <Nombre setFieldValue />
                </LabelInputWidthWrapper>
              </GridItem>
              <GridItem row="1">
                <LabelInputWidthWrapper notALayer={true}>
                  <Apellido setFieldValue name="surname" required={true} label="Primer apellido" />
                </LabelInputWidthWrapper>
              </GridItem>
              <GridItem row="2">
                <Telefono required name="telefono" />
              </GridItem>
              <GridItem row="2">
                <Email setEmailField required />
              </GridItem>
            </GridWrapper>
          </BreakpointWrapper>

          <BreakpointWrapper max={Breakpoints.b1280}>
            <LabelInputWidthWrapper notALayer={true}>
              <Nombre setFieldValue />
            </LabelInputWidthWrapper>
            <LabelInputWidthWrapper notALayer={true}>
              <Apellido setFieldValue name="surname" required={true} label="Primer apellido" />
            </LabelInputWidthWrapper>
            <LabelInputWidthWrapper notALayer={true}>
              <Email name='email' setEmailField required />
            </LabelInputWidthWrapper>
            <div>
              <Telefono required name="telefono" />
            </div>
          </BreakpointWrapper>
        </Container>
      </Layout>

      <Layout
        appearance={{
          [Breakpoints.b1280]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 10 },
            { name: '.', columns: 12 },
          ],
          [Breakpoints.default]: [
            { name: 'a', columns: 24 },
            { name: 'b', columns: 24 },
          ],
        }}>
        <Container padding={{left: ContainerPadding.static400, right: ContainerPadding.static400}}>
          {!idPerson &&
            <LegalItem
              title={'¿Te gustaría también recibir descuentos y novedades de Volkswagen?'}
              onClick={() => handleInfoLayer('lssi', renderInfo)}
              themeColor={'main'}
              name='lssi'
            />
          }
        </Container>
        <Container padding={{left: ContainerPadding.static400, right: ContainerPadding.static400}}>
            <PoliticaPrivacidad />
        </Container>

        {compartirError && <InputErrorLegales>Necesitamos esta información para seguir</InputErrorLegales>}
        <Container padding={{left: ContainerPadding.static400, right: ContainerPadding.static400}}>
          <LastNavigation
            label='Enviar'
            compartir={compartir}
            setCompartirError={setCompartirError}
            loadingCopy="Enviando tu respuesta"
            KoTitle="Uy, algo ha pasado"
            KoBody="Por alguna razón no hemos podido guardar tu respuesta. ¿Lo intentamos de nuevo?"
          />
        </Container>
      </Layout>
      <Layout
        allowOverflowingContent
        appearance={{
          [Breakpoints.b1280]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.default]: [
            { name: 'a', columns: 24 },
          ],
        }}
      >
        <Container padding={ContainerPadding.static400}>
          <BreakpointWrapper min={Breakpoints.b1280}>
            {idPerson &&
              <TextBlue appearance={TokenTextAppearance.copy100}>
                Consulta las bases legales de la promoción{' '}
                <CTA tag="a" href={legalesTestID2023} target="_blank" emphasis="tertiary">
                  <b>aquí</b>
                </CTA>.
              </TextBlue>
            }
          </BreakpointWrapper>
          <BreakpointWrapper max={Breakpoints.b1280}>
            <Container padding={ContainerPadding.static300}>
              {idPerson &&
                <TextBlue appearance={TokenTextAppearance.copy100}>
                  Consulta las bases legales de la promoción{' '}
                  <CTA tag="a" href={legalesTestID2023} target="_blank" emphasis="tertiary">
                    <b>aquí</b>
                  </CTA>.
                </TextBlue>
              }
            </Container>
          </BreakpointWrapper >
        </Container>
      </Layout>
    </>
  );
};
