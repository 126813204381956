import { CTA, styled } from '@volkswagen-onehub/components-core';
import { ArrowUp, Close } from 'src/icons-core-imports';
import { LayerHandleV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { css } from 'styled-components';
import { useLoaded } from 'src/feature-app';
import { TIME_FOR_OPACITY_MS, TIME_FOR_TRANSFORM_MS } from 'src/globals';
import { OneFormState } from 'src/types';
import { useOneFormContext } from '../OneForm';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { useFeatureServices } from '../hooks';
import { removeConfigurationHash } from '../remove-configuration-hash';


{/*
   paddings top prev step wrapper antes de adaptacion a nuevo formato de editorial template with feature
   app in stage que ya setea automáticamente el espacio reservado para la navbar. Se ha retirado también del
   prev-step-controller
   560: padding-top: 110px;
   1920: padding-top: 126px;
*/}
interface wrapperProps {
  fullScreen?: boolean;
  closeInMap?: boolean;
  colorTheme?: string;
  notALayer?: boolean;
}

const Wrapper = styled.div<wrapperProps>`
  position: ${(props) => props.notALayer ? 'absolute' : props.closeInMap ? 'relative' : props.fullScreen ? 'absolute' : 'fixed'};
  top: ${(props) => (props.notALayer ? '84' : '0')};
  right: 0;
  align-self: ${(props) => (props.notALayer ? 'flex-start' : null)};
  padding: ${(props) => (props.notALayer ? '0 var(--size-grid002) 20px' : '20px 20px')};
  display: ${(props) => (props.closeInMap ? 'flex' : props.fullScreen ? 'none' : 'flex')};
  justify-content: ${(props) => (props.notALayer ? 'flex-end' : 'space-between')};
  background-color: ${(props) => (props.notALayer ? 'transparent' : props.colorTheme === 'main' ? '#FFFFFF' : '#001e50')};
  z-index: ${(props) => (props.closeInMap && props.notALayer ? '5' : props.notALayer ? '1' : '2')};
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
  min-height: ${(props) => (props.notALayer ? 'auto' : '72px')};
  margin-bottom: auto;
  @media all and (min-width: 560px) {
    padding: ${(props) => (props.notALayer ? '0 var(--size-grid002) 24px' : '24px 24px')};
    min-height: ${(props) => (props.notALayer ? 'auto' : '80px')};
    ${(props) =>
    props.notALayer &&
    css`
      top: 92;
    `}
  }

  @media screen and (min-width: 960px) {
    background-color: ${(props) => (props.colorTheme === 'main' ? 'transparent' : '#001e50')};
    margin-bottom: unset;
    width: ${(props) => (props.notALayer ? '100%' : props.closeInMap ? '100%' : '960px')};
    padding: ${(props) =>
    props.closeInMap && props.notALayer ? '28px 20px 0' : props.notALayer ? '0 var(--size-grid003) 24px' : '24px 24px'};
  ${(props) =>
    props.notALayer &&
    css`
      top: 102;
    `}
  }
  @media screen and (min-width: 1280px) {
    padding: ${(props) => (props.notALayer ? '0 var(--size-grid005) 24px' : '24px 24px')};
  }
  @media screen and (min-width: 1920px) {
    padding: ${(props) => (props.notALayer ? '0 var(--size-grid006) 24px' : '24px 24px')};
  }
  @media screen and (min-width: 2560px) {
    padding: ${(props) => (props.notALayer ? '0 var(--size-grid007) 24px' : '24px 24px')};
  }

  button {
    background-color: ${(props) => (props.colorTheme === 'main' ? '#FFFFFF' : null)};
  }
	.close-icon {
		color: ${(props) => (props.colorTheme === 'main' ? '#001E50' : '#FFFFFF')};
	}
`;

const CloseDiv = styled.div`
  cursor: pointer;
  &.close-enter {
    opacity: 0;
  }
  &.close-enter-active {
    opacity: 1;
    transition: opacity ${TIME_FOR_OPACITY_MS} ease-in;
  }
  &.close-enter-done {
  }
  &.close-exit {
    transform: translate(0, 0);
    opacity: 1;
  }
  &.close-exit-active {
    transform: translate(0, -100%);
    opacity: 0.01;
    transition: all ${TIME_FOR_TRANSFORM_MS}, opacity ${TIME_FOR_OPACITY_MS} ease-in;
  }
`;

interface CloseComponentProps {
  closeInMap?: boolean;
  closeThankYou?: boolean;
  handleAreYouSureLayer?: () => LayerHandleV2<{}>;
  notALayer?: boolean;
  colorTheme?: string;
  formMin?: boolean;
}
export function CloseComponent(props: CloseComponentProps) {
  const { closeInMap, handleAreYouSureLayer, colorTheme, notALayer, formMin } = props;
  const { formInfo, formData } = useSelector((state: OneFormState) => state);
  const { screenIndex, stepsHistory, multiStepScreenIndex, formSubmitted } = formInfo;
  const [isFirstScreen, setIsFirstScreen] = useState(true);
  const loaded = useLoaded();
  const { handlePreviousStep, isFullScreen, closeLayer } = useOneFormContext();
  const trackingManager = useTrackingManager();
  const layerManager = useFeatureServices()['layer-manager'];
  const dispatch = useDispatch()
  /**
   * En el journey de no compra al llegar al mapa se abre un layer. Se necesita que interactue con el next step, por lo que lo tenemos que cerrar desde aquí
   */
  const handleMapFullScreenLayer = () => {
    const layers = layerManager.getLayers();

    const layer = layers.find(
      (layer) => layer.layer.options.id === 'mapaNoCompra' || layer.layer.options.id === 'mapaNoRenovacion' || layer.layer.options.id === 'mapaLeadInPage'
    );

    if (layer) {
      layer.layer?.close();
    }
  };

  useEffect(() => {
    if (stepsHistory && stepsHistory.length > 0 && !multiStepScreenIndex) {
      stepsHistory[0] === screenIndex ? setIsFirstScreen(true) : setIsFirstScreen(false);
    } else {
      setIsFirstScreen(false);
    }
  }, [screenIndex, multiStepScreenIndex]);

  return (
    <CSSTransition timeout={500} in={loaded} classNames="close">
      <Wrapper
        fullScreen={isFullScreen}
        closeInMap={closeInMap}
        className="close-wrapper"
        colorTheme={colorTheme}
        notALayer={notALayer}
      >
        {notALayer ? null : (
          <CloseDiv
			className="close-icon"
            onClick={(e) => {
              e.preventDefault();
              trackingManager.trackFormCloseClick(
                {
                  contentId: 'Cerrar',
                },
                { FormStart: screenIndex === 0 ? true : false }
              );
              handleMapFullScreenLayer(); // Revisar para optimizar esta llamada y que solo debería ejecutarse si el form es inPage y el step en un layer
              if (!formSubmitted) {
                handleAreYouSureLayer();
              } else {
                closeLayer();
              }
            }}
          >
            <Close />
          </CloseDiv>
        )}

        {isFirstScreen || props.closeThankYou ? null : (
          <CTA
            onClick={(e) => {
              trackingManager.trackFormStepButtonClick({
                contentId: 'Anterior',
              });
              handlePreviousStep(e);
            }}
            tag="button"
            emphasis="secondary"
            size="small"
            icon={<ArrowUp variant="small" />}
          >
            Anterior
          </CTA>
        )}
      </Wrapper>
    </CSSTransition>
  );
}
