import { Breakpoints, BreakpointWrapper, styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useRef, useState } from 'react';
import { Points } from 'src';
import { sortPointsByDistance, useTrackingManager, useFeatureServices } from 'src/feature-app';
import { getDistanceFromLatLngCenter, useMapGeneralController } from 'src/feature-app/NewMap';
import { CTAServicesProps, DealerCardWidget, ViewDesktopWidgetMapa, ViewMobileWidgetMapa } from 'src/widgets';
import { CTAMapaProps } from './CTAMapa';

const ContentWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  overflow: visible;
  height: 85vh;

  @media all and (min-width: 560px) {
    height: 82vh;
  }

  @media all and (min-width: 560px) {
    height: 85vh;
  }
  @media all and (min-width: 960px) {
    height: 60vh;
  }
  @media all and (min-width: 1600px) {
    height: 70vh;
  }
`;

export interface ViewWidgetMapaProps {
  handleSeleccionarOnClick: (point: Points) => void;
  renderPoints(
    points: any,
    carPickupSelected?: boolean,
    replacementCarSelected?: boolean,
    isCarousel?: boolean
  ): JSX.Element[];
  pointsToList: Points[];
  CTAServicesProps: CTAServicesProps;
  CTAMapaProps: CTAMapaProps;
}

interface MapaScreenProps {
  setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MapaScreen = ({ setShowMap }: MapaScreenProps) => {
  const {
    clusters,
    centerToCalculateDistance,
    superClusterRef,
    cardsDistanceRef,
    getNewClusters,
    mapHasMoved,
    resetMap,
  } = useMapGeneralController();
  const trackingManager = useTrackingManager();

  const calculadoraFeatureService = useFeatureServices()['calculator-feature-service'];

  const [pointsToList, setPointsToList] = useState(null);
  const [carPickupSelected, setCarPickupSelected] = useState<boolean>(false);
  const [replacementCarSelected, setReplacementCarSelected] = useState<boolean>(false);

  const handleClickCarPickup = () => {
    if (!carPickupSelected) {
      setCarPickupSelected(true);
    } else {
      setCarPickupSelected(false);
    }
  };

  const handleClickReplacementCar = () => {
    if (!replacementCarSelected) {
      setReplacementCarSelected(true);
    } else {
      setReplacementCarSelected(false);
    }
  };

  const handleSeleccionarOnClick = (point: Points) => {
    if (calculadoraFeatureService) {
      const dealer = point?.properties?.dealer;
      calculadoraFeatureService.setInstallation({ name: dealer.name, code: dealer.kvps });
    } else {
      console.error('calculator-feature-service is not registered');
    }
  };

  useEffect(() => {
    if (clusters && superClusterRef.current) {
      const pointsByDistance = sortPointsByDistance(
        clusters,
        centerToCalculateDistance.current,
        superClusterRef.current
      );
      setPointsToList(pointsByDistance);
    }
  }, [clusters, superClusterRef.current]);

  const handleSearchArea = () => {
    trackingManager.trackFormButtonClick({
      contentId: 'Buscar en esta zona',
    });
    getNewClusters();
    {
      /*if (setTranslateCarousel) {
                    setTranslateCarousel(false);
                  }*/
    }
  };

  const handleSearchAgain = () => {
    setShowMap(false);
    resetMap();
  };

  const renderPoints = (
    points: Points[],
    carPickupSelected?: boolean,
    replacementCarSelected?: boolean,
    isCarousel?: boolean
  ): JSX.Element[] => {
    return points
      ? points.map((point: Points, index: number) => {
          if (!point.properties.cluster) {
            const { dealer } = point.properties;
            const distanceFromCenter = getDistanceFromLatLngCenter(centerToCalculateDistance.current, point);

            return (
              <div
                key={`${point.properties.dealer.kvps}-${index}`}
                className={`${point.properties.dealer.kvps} dealer-card-leads`}
                ref={(el) => {
                  cardsDistanceRef.current[index] = el;
                }}
              >
                <DealerCardWidget
                  isInverted
                  key={`${point.properties.dealer.kvps}-${index}-card`}
                  distanceFromCenter={distanceFromCenter}
                  isCarousel={isCarousel}
                  slots={dealer.slots}
                  dealerInfo={dealer}
                  point={point}
                  carPickupSelected={carPickupSelected}
                  replacementCarSelected={replacementCarSelected}
                  handleSeleccionarOnClick={handleSeleccionarOnClick}
                />
              </div>
            );
          }
          return null;
        })
      : null;
  };
  // De momento la versión mobile no se ve bien ya que están los paddings de la feature app insertados y descuadra el 100vh del content wrapper.
  return (
    <>
      <BreakpointWrapper max={Breakpoints.b960}>
        <ContentWrapper>
          <ViewMobileWidgetMapa
            handleSeleccionarOnClick={handleSeleccionarOnClick}
            renderPoints={renderPoints}
            pointsToList={pointsToList}
            CTAServicesProps={{
              handleClickCarPickup,
              handleClickReplacementCar,
              carPickupSelected,
              replacementCarSelected,
            }}
            CTAMapaProps={{ handleSearchAgain, handleSearchArea, mapHasMoved }}
          />
        </ContentWrapper>
      </BreakpointWrapper>
      <BreakpointWrapper min={Breakpoints.b960}>
        <ContentWrapper>
          <ViewDesktopWidgetMapa
            handleSeleccionarOnClick={handleSeleccionarOnClick}
            renderPoints={renderPoints}
            pointsToList={pointsToList}
            CTAServicesProps={{
              handleClickCarPickup,
              handleClickReplacementCar,
              carPickupSelected,
              replacementCarSelected,
            }}
            CTAMapaProps={{ handleSearchAgain, handleSearchArea, mapHasMoved }}
          />
        </ContentWrapper>
      </BreakpointWrapper>
    </>
  );
};
