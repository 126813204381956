import {
	Breakpoints,
	BreakpointWrapper,
	Container,
	ContainerGutter,
	ContainerPadding,
	CTA,
	Layout,
	styled,
	Text,
	TextAlignment,
	TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import FileDownload from 'js-file-download';
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getEapiAppointmentIcs, getMolecularUrl} from 'src/bff';
import {
	useFeatureAppConfig,
	weekDay_DayNumber_Month,
	weekDay_DayNumber_Month_Hour,
	useTrackingManager,
	TrackingForm,
} from 'src/feature-app';
import {RenderController} from 'src/feature-app/Screen';
import {DealersData, FormDataFields, FormInfo, OneFormState, Steps} from 'src/types';
import {keyframes} from 'styled-components';

const lineKeyframe = keyframes`
  0% {
    transform: rotate(-360deg);
  }
  80% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const AnimatedCheckmark = styled.div`
	animation: ${lineKeyframe} 0.5s ease-in;
`;

interface FieldProps {
	copyBook?: string;
}

interface NDPSField {
	name: string;
	value: string;
}

function Fields(props: FieldProps) {
	const {formData, formInfo} = useSelector((state: OneFormState) => state);
	const {dealer}: {dealer?: DealersData} = formData.fields;
	const {pincode}: {pincode: string} = formInfo.formResponse.content;
	const config = useFeatureAppConfig();

	const handleGuardar = async () => {
		const response = await getEapiAppointmentIcs(pincode);
		FileDownload(response, 'cita.ics');
	};

	return (
		<Layout
			appearance={{
				[Breakpoints.default]: [
					{name: '.', columns: 2},
					{name: 'a', columns: 20},
					{name: '.', columns: 2},
				],
				[Breakpoints.b960]: [
					{name: '.', columns: 3},
					{name: 'a', columns: 18},
					{name: '.', columns: 3},
				],
			}}
		>
			<div style={{textAlign: 'left', paddingBottom: '4px', width: '100%'}}>
				<Container padding={{top: ContainerPadding.dynamic0100}}>
					<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
						{props.copyBook}
					</Text>
				</Container>
				{dealer.dmsInfo.dmsAvailable ? (
					<Container
						padding={{top: ContainerPadding.static500, bottom: ContainerPadding.static400}}
						gutter={ContainerGutter.static350}
						wrap={'always'}
						verticalAlign={'flex-start'}
						horizontalAlign={'flex-start'}
					>
						<CTA
							tag="button"
							emphasis="secondary"
							onClick={(e) => {
								e.preventDefault();
								handleGuardar();
							}}
						>
							Guárdala en tu calendario
						</CTA>
					</Container>
				) : null}
			</div>
		</Layout>
	);
}

interface ThankYouProps {
	formData: FormDataFields;
	formInfo: FormInfo;
}

function ThankYouNuevaCita(props: ThankYouProps) {
	const {formData, formInfo} = props;
	const {formResponse} = formInfo;
	const [fechaHora, setFechaHora] = useState<string>('');
	const [copyBook, setCopyBook] = useState<string>('');

	const trackingManager = useTrackingManager();
	const dispatch = useDispatch();
	const getTrackingForm = (): TrackingForm => {
		let LeadID;
		if (
			formResponse &&
			formResponse.contentNDPS &&
			formResponse.contentNDPS.data &&
			formResponse.contentNDPS.data.fields
		) {
			const leadField: NDPSField = formResponse.contentNDPS.data.fields.find(
				(field: NDPSField) => field.name === 'COD_LEAD_ID',
			);
			LeadID = leadField ? leadField.value : undefined;
		}
		return {
			LeadID,
			FormFields: undefined,
		};
	};

	useEffect(() => {
		// Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
		const screenIndexThankyou = formInfo.numberOfScreens;

		dispatch({type: 'SET_FORM_SUBMITTED', payload: true});
		dispatch({type: 'SET_FORM_INFO', payload: {step: {screenIndex: screenIndexThankyou, name: 'ThankYou'}}});

		const selectedSlot = formData.slot;
		const franjaHoraria = [
			{code: 'MANANA', name: ' por la mañana'},
			{code: 'MEDIODIA', name: ' al mediodía'},
			{code: 'TARDE', name: ' por la tarde'},
		];
		let fromFormatted;

		if (formInfo.sendDmsFailed) {
			const momentSlot = weekDay_DayNumber_Month(selectedSlot.from);
			const formattedSlot =
				momentSlot.split(',')[0] +
				' de ' +
				momentSlot.split(',')[1].charAt(0).toLowerCase() +
				momentSlot.split(',')[1].slice(1);
			const horarioTranslate = franjaHoraria.find((h) => h.code === selectedSlot.dayMoment.code);
			fromFormatted = formattedSlot.replace(',', ' de ').concat(horarioTranslate.name);
			setFechaHora(fromFormatted);
		} else {
			if (formData.dealer.dmsInfo.dmsAvailable) {
				const momentSlot = weekDay_DayNumber_Month_Hour(selectedSlot.from);
				const formattedSlot =
					momentSlot.split(',')[0] +
					' de ' +
					momentSlot.split(',')[1].charAt(0).toLowerCase() +
					momentSlot.split(',')[1].slice(1);
				fromFormatted = formattedSlot.replace('-', ' a las ').concat('h');
				setFechaHora(fromFormatted);
			} else {
				const momentSlot = weekDay_DayNumber_Month(selectedSlot.from);
				const formattedSlot =
					momentSlot.split(',')[0] +
					' de ' +
					momentSlot.split(',')[1].charAt(0).toLowerCase() +
					momentSlot.split(',')[1].slice(1);
				const horarioTranslate = franjaHoraria.find((h) => h.code === selectedSlot.dayMoment.code);
				fromFormatted = formattedSlot.replace(',', ' de ').concat(horarioTranslate.name);
				setFechaHora(fromFormatted);
			}
		}

		trackingManager.trackFormSubmissionSuccessMessageLoad(
			{
				contentId: undefined,
			},
			getTrackingForm(),
		);
	}, []);

	useEffect(() => {
		const copyDms =
			'Tu asesor te contactará pronto para confirmar la cita. Te acabamos de enviar un email con toda la información.';
		const copySinDms =
			'Tu asesor te contactará pronto para confirmar la cita. Te acabamos de enviar un email con toda la información.';

		if (formData.dealer.dmsInfo.dmsAvailable) {
			setCopyBook(copyDms);
		} else {
			setCopyBook(copySinDms);
		}
	}, []);

	const renderTitle = (dmsFailed: boolean) => {
		if (!dmsFailed && formData.dealer.dmsInfo.dmsAvailable) {
			return (
				<>
					<BreakpointWrapper max={Breakpoints.b1600}>
						<Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
							Hemos enviado tu solicitud para <Text bold>el {fechaHora}</Text> en{' '}
							<span style={{textTransform: 'capitalize'}}>{formData.dealer.name}</span>
						</Text>
					</BreakpointWrapper>
					<BreakpointWrapper min={Breakpoints.b1600}>
						<Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
							Hemos enviado tu solicitud para <Text bold>el {fechaHora}</Text> en{' '}
							<span style={{textTransform: 'capitalize'}}>{formData.dealer.name}</span>
						</Text>
					</BreakpointWrapper>
				</>
			);
		} else {
			return (
				<>
					<BreakpointWrapper max={Breakpoints.b1600}>
						<Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
							Hemos enviado tu solicitud para <Text bold>{fechaHora}</Text> en{' '}
							<span style={{textTransform: 'capitalize'}}>{formData.dealer.name}</span>
						</Text>
					</BreakpointWrapper>
					<BreakpointWrapper min={Breakpoints.b1600}>
						<Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
							Hemos enviado tu solicitud para <Text bold> {fechaHora}</Text> en{' '}
							<span style={{textTransform: 'capitalize'}}>{formData.dealer.name}</span>
						</Text>
					</BreakpointWrapper>
				</>
			);
		}
	};

	const screen: Steps = {
		title: <>{renderTitle(formInfo.sendDmsFailed)}</>,
		fields: <Fields copyBook={copyBook} />,
	};
	return (
		<RenderController
			screenType="layer-screen"
			title={screen.title}
			fields={screen.fields}
			key={555}
			avoidTrackFormStepLoad
		/>
	);
}

const mapStateToProps = (state: OneFormState) => {
	return {
		formData: state.formData.fields,
		formInfo: state.formInfo,
	};
};

const ConnectedComponent = connect(mapStateToProps)(ThankYouNuevaCita);

export {ConnectedComponent as ThankYouNuevaCita};
