import React, { useEffect, useState } from 'react';

export function useOnlyLettersControl(pristine: boolean, input: string) {
  const [error, setError] = useState(false);
  // const regExp = /^[ña-zÑA-Z\s]*$/;
  // const regExp = /^[A-Za-zÁÉÍÓÚáéíóúñÑ]+$/g;
  const regExp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\s+]+$/;

  useEffect(() => {
    if (input && !pristine && !regExp.test(input)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [input, pristine]);

  return { error, regExp };
}
