import { IdPerson } from "src/types";
import { EsOneformCoreServiceV1 } from "src/feature-service/es-oneform-core-feature-service/types";
import { createPincodeEntity } from 'src/forms';
import { useFeatureServices } from "./use-feature-services";

export const useGetIdPerson = () => {
    const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;
    const dpsData = oneFormService.getDpsData();
    const idPerson: IdPerson = createPincodeEntity(dpsData, 'PERSON');

    return idPerson;
}