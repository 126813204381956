import { FormDataGroup } from 'src/types';

export const leadsCallCenterPartialData: Partial<FormDataGroup> = {
  fields: {
    formFields: {},
    formularioFlag: 'masInformacion',
    formName: 'lead-call_center',
    formType: 'contact',
    pageCategory: 'Más Información',
  },
};
