import {
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  styled,
  Text,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import React from 'react';

export function LegalMGM({ isFriendForm }: { isFriendForm?: boolean }) {
  return (
    <Container
      gutter={ContainerGutter.static200}
      wrap={'always'}
      padding={isFriendForm ? null : { bottom: ContainerPadding.static200 }}
    >
      <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
          *Promoción solo válida para los 100 primeros pedidos de clientes particulares y autónomos que se adhieran a la presente promoción. El cupón solo puede canjearse con pedidos realizados antes del 28/02/2023. Válido para la compra de vehículos en stock y vehículos a producción. El cupón tendrá un valor de: 1.000€ PVP adicionales para los acabados R-Line, Sport, GTI o R y 500€ PVP adicionales para el resto de acabados. Quedan excluidos vehículos de la promoción los modelos Polo, Tiguan Life y T-Cross Clic2go. El cupón no será acumulable a ofertas ya realizadas por parte del Concesionario ni para pedidos ya realizados, ni cancelados en el último mes. Este cupón no tiene ningún valor en metálico. Solo se aceptará un único cupón por compra y solo se podrá canjear una única vez.
      </Text>
      {isFriendForm ? (
        <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
            **Te recordamos que en caso de que nos proporciones datos relativos a otra persona física deberás, con carácter previo a su inclusión, informarle de los extremos contenidos en la presente cláusula.
        </Text>
      ) : null}
      <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
          Te informamos que el responsable del tratamiento de tus datos es la compañía VOLKSWAGEN GROUP ESPAÑA DISTRIBUCION, S.A.U., con la finalidad de atender tus consultas y solicitudes, realizar un control de calidad sobre los productos y servicios solicitados, realizar encuestas de opinión y estudios de mercado con fines estadísticos. En caso que nos hayas dado tu consentimiento, para las finalidades descritas en los consentimientos adicionales.
      </Text>
      <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
          Te informamos que tienes derecho a retirar tu consentimiento en cualquier momento, así como a oponerte al tratamiento, limitar el mismo, acceder, rectificar, suprimir los datos y ejercer tu derecho a la portabilidad, mediante petición escrita a :{' '}
          <CTA tag="a" href="mailto:atencioncliente@volkswagen.es" target="_blank" emphasis="tertiary">
            <b>atencioncliente@volkswagen.es</b>
          </CTA>
          .
      </Text>
      <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
          Puedes consultar con mayor detalle la información adicional sobre la política de privacidad{' '}
          <CTA
            tag="a"
            href="https://www.volkswagen.es/es/volkswagen-espana/politica-privacidad.html"
            target="_blank"
            emphasis="tertiary"
          >
            <b>aquí</b>
          </CTA>
          .
      </Text>
    </Container>
  );
}
