import {
    CTA
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { Magnifier, Reload } from 'src/icons-core-imports';

export interface CTAMapaProps {
    mapHasMoved: boolean;
    handleSearchArea: () => void
    handleSearchAgain: () => void 
}

export function CTAMapa({ mapHasMoved, handleSearchArea, handleSearchAgain }: CTAMapaProps) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '32px'}}>
      {mapHasMoved ? (
        <CTA
          tag="button"
          emphasis="secondary"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            handleSearchArea();
            e.stopPropagation();
          }}
          ariaLabel="aria label"
          icon={<Reload variant="small" />}
        >
          Buscar en esta zona
        </CTA>
      ) : null}
      <CTA
        tag="button"
        emphasis="secondary"
        size="small"
        onClick={(e) => {
          e.preventDefault();
          handleSearchAgain();
        }}
        ariaLabel="aria label"
        icon={<Magnifier variant="small" />}
      >
        Buscar de nuevo
      </CTA>
    </div>
  );
}
