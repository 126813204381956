import axios from 'axios';
import { getDataUrl, reportErrorSentry } from 'src/bff';
import { CarlineBase, CarlineWithOffers } from 'src/types';

export const getOfertasSUV = async () => {
  const dataUrl = getDataUrl();  
  let dataRes: any;
  let carlinesWithOffers: CarlineWithOffers[];
  await axios.get(`${dataUrl}/getOfertas`)
    .then(res => dataRes = res.data)
    .then(() => {
      carlinesWithOffers = dataRes.carlines.filter((carline: CarlineBase) => {
        const carlineNames = ['t-cross', 'taigo', 't-roc'];
        return carlineNames.includes(carline.name.toLowerCase());
      });
    })
    .catch((error) => {
      reportErrorSentry(error);
      return null;
    });
  return carlinesWithOffers;
};
