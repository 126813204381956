import { Breakpoints, BreakpointWrapper, CTA, styled, ThemeProvider } from '@volkswagen-onehub/components-core';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGoogleApiKey } from 'src/bff';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { Add, ContactDealer, Locate, Reload, Remove } from 'src/icons-core-imports';
import { OneFormState, Points, SelectedTab } from 'src/types';
import { mapStyles } from 'src/feature-app/NewMap';
import { CenterMarker } from 'src/feature-app/NewMap/components/CenterMarker';
import { ClusterMarker, sameClusterId } from 'src/feature-app/NewMap/components/ClusterMarker';
import { DealerMarker, isDealerGrey, isDealerActive, isDealerFavourite, isDealerHovered } from 'src/feature-app/NewMap/components/DealerMarker';
import { useMapGeneralController } from 'src/feature-app/NewMap/MapGeneralController';

const RecalcularWrapper = styled.div<{ isPosventa?: boolean }>`
  position: fixed;
  top: ${(props) => (props.isPosventa ? '96px' : '68px')};
  transform: translateX(-50%);
  left: 50%;
  background-color: #ffffff;
  border-radius: 500px;
  width: fit-content;
  @media all and (min-width: 960px) {
    top: 24px;
    transform: translateX(0);
    left: unset;
    right: 183px;
  }
`;

const GoogleMaps = styled.div`
  & .gm-control-active[aria-label='Ampliar'] {
    display: none !important;
  }

  & .gm-control-active[aria-label='Reducir'] {
    display: none !important;
  }

  & .gmnoprint {
    display: none;
  }
`;

interface MapHeightWrapperProps {
  isTrigger?: boolean;
  isWidget?: boolean;
}

const MapHeigthWrapper = styled.div<MapHeightWrapperProps>`
  width: 100%;
  height: ${(props) => (props.isWidget ? '85vh' : props.isTrigger ? 'var(--size-grid020)' : '100vh')};

  @media all and (min-width: 560px) {
    height: ${(props) => (props.isWidget ? '82vh' : props.isTrigger ? 'var(--size-grid010)' : '100vh')};
  }

  @media all and (min-width: 768px) {
    height: ${(props) => (props.isWidget ? '85vh' : props.isTrigger ? 'var(--size-grid010)' : '100vh')};
  }
  @media all and (min-width: 960px) {
    height: ${(props) => (props.isWidget ? '60vh' : props.isTrigger ? '636px' : '100vh')};
  }
  @media all and (min-width: 1600px) {
    height: ${(props) => (props.isWidget ? '70vh' : props.isTrigger ? '684px' : '100vh')};
  }
`;

interface newMapProps {
  searchIsDealer?: boolean;
  setTranslateCarousel?: any;
  translateCarousel?: boolean;
  preSelectedOrderValue?: SelectedTab;
  isTrigger?: boolean;
  preselectedDealerForm?: boolean;
  replacementCarSelected?: boolean;
  carPickupSelected?: boolean;
  isPosventa?: boolean;
  isWidget?: boolean;
}

export const NewMap = React.memo((props: newMapProps) => {
  const {
    centerRef,
    clusters,
    handleApiLoaded,
    geoLocatedCenterRef,
    getNewClusters,
    handlePointClickAndCarouselMove,
    selectedPoint,
    handleClusterClick,
    mapMaxZoom,
    searchMoreDealers,
    handleMapMovement,
    setSearchIsDealer,
    hoverPoint,
    handleHoverPoint,
    userIsGeoLocated,
    mapHasMoved,
  } = useMapGeneralController();
  const { dealersInfo, formInfo } = useSelector((state: OneFormState) => state);
  const { dealersOrInstallationsMode, favouriteDealer } = dealersInfo;
  const { suggestionIsAddress } = formInfo;
  const {
    searchIsDealer,
    setTranslateCarousel,
    preSelectedOrderValue,
    isTrigger,
    carPickupSelected,
    replacementCarSelected,
    isPosventa,
    isWidget,
  } = props;
  const trackingManager = useTrackingManager();

  const [gelocalizationActive, setGelocalizationActive] = useState<boolean>(false);

  useEffect(() => {
    if (userIsGeoLocated) {
      setGelocalizationActive(true);
    } else {
      setGelocalizationActive(false);
    }
  }, [userIsGeoLocated]);

  return (
    // Important! Always set the container height explicitly
    <GoogleMaps>
      <MapHeigthWrapper isWidget={isWidget} isTrigger={isTrigger}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: getGoogleApiKey(), language: 'es', libraries: ['places'] }}
          onGoogleApiLoaded={({ map, maps }) => {
            handleApiLoaded(map, maps);
          }}
          defaultCenter={{
            lat: 40.58869349456487,
            lng: -3.0047240000000075,
          }}
          yesIWantToUseGoogleMapApiInternals
          onDrag={() => {
            handleMapMovement();
          }}
          defaultZoom={5}
          options={{
            styles: mapStyles,
            clickableIcons: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            maxZoom: mapMaxZoom,
          }}
        >
          {clusters
            ? clusters.map((point: Points) => {
                if (point.geometry) {
                  const [longitude, latitude] = point.geometry.coordinates;
                  return point.properties.cluster ? (
                    <ClusterMarker
                      lat={latitude}
                      lng={longitude}
                      isHover={sameClusterId(hoverPoint, point)}
                      key={point.id}
                      onClick={() => {
                        handleClusterClick(point);
                      }}
                      pointCount={point.properties.point_count}
                    />
                  ) : (
                    <DealerMarker
                      lat={latitude}
                      lng={longitude}
                      key={point.properties.dealer.kvps}
                      isGrey={isDealerGrey(carPickupSelected, replacementCarSelected, point)}
                      isActive={isDealerActive(selectedPoint, point)}
                      isFavouriteDealer={isDealerFavourite(favouriteDealer, point)}
                      isHover={isDealerHovered(hoverPoint, point)}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!searchIsDealer) {
                          handlePointClickAndCarouselMove(point, true, preSelectedOrderValue);
                        }
                      }}
                    />
                  );
                }
                return null;
              })
            : null}
          {(geoLocatedCenterRef.current && gelocalizationActive) || (suggestionIsAddress && centerRef.current) ? (
            <CenterMarker
              lat={
                geoLocatedCenterRef.current
                  ? geoLocatedCenterRef.current.lat()
                  : suggestionIsAddress
                  ? centerRef.current.lat()
                  : null
              }
              lng={
                geoLocatedCenterRef.current
                  ? geoLocatedCenterRef.current.lng()
                  : suggestionIsAddress
                  ? centerRef.current.lng()
                  : null
              }
              key="center-marker"
            />
          ) : null}
        </GoogleMapReact>
        {isTrigger ? (
          <BreakpointWrapper min={Breakpoints.b960}>
            <Controllers suggestionIsAddress={suggestionIsAddress} gelocalizationActive={false} isTrigger />
          </BreakpointWrapper>
        ) : (
          <>
            <BreakpointWrapper min={Breakpoints.b960}>
              <Controllers suggestionIsAddress={suggestionIsAddress} gelocalizationActive={gelocalizationActive} />
            </BreakpointWrapper>
            {clusters && mapHasMoved && !isWidget ? (
              searchIsDealer ? (
                <RecalcularWrapper className="recalcular-wrapper dealer-marker" isPosventa={isPosventa}>
                  <ThemeProvider theme="main">
                    <CTA
                      tag="button"
                      emphasis="secondary"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        setSearchIsDealer(false);

                        trackingManager.trackFormButtonClick({
                          contentId:
                            dealersOrInstallationsMode === 'dealers'
                              ? 'Ver más talleres cerca'
                              : 'Ver más puntos de venta cerca',
                        });
                        searchMoreDealers();
                        if (setTranslateCarousel) {
                          setTranslateCarousel(false);
                        }
                        e.stopPropagation();
                      }}
                      ariaLabel="aria label"
                      icon={<ContactDealer variant="small" />}
                    >
                      {dealersOrInstallationsMode === 'dealers'
                        ? 'Ver más talleres cerca'
                        : 'Ver más puntos de venta cerca'}
                    </CTA>
                  </ThemeProvider>
                </RecalcularWrapper>
              ) : (
                <RecalcularWrapper className="recalcular-wrapper dealer-marker" isPosventa={isPosventa}>
                  <ThemeProvider theme="main">
                    <CTA
                      tag="button"
                      emphasis="secondary"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        trackingManager.trackFormButtonClick({
                          contentId: 'Buscar en esta zona',
                        });
                        getNewClusters();
                        {
                          /*if (setTranslateCarousel) {
                            setTranslateCarousel(false);
                          }*/
                        }
                        e.stopPropagation();
                      }}
                      ariaLabel="aria label"
                      icon={<Reload variant="small" />}
                    >
                      Buscar en esta zona
                    </CTA>
                  </ThemeProvider>
                </RecalcularWrapper>
              )
            ) : null}
          </>
        )}
      </MapHeigthWrapper>
    </GoogleMaps>
  );
});

/* MAP CONTROLLLERS (!el botón de geolocaclización en versión mobile está en la propia vista.
  Estos controladores actualmente solo están visibles a partir de 960px)
*/
const ControllerBackground = styled.div`
  border-radius: 100%;
  background-color: #ffffff;
  height: 44px;
  width: 44px;
`;

interface ControllersWrapperProps {
  isTrigger?: boolean;
}

const ControllersWrapper = styled.div<ControllersWrapperProps>`
  position: ${(props) => (props.isTrigger ? 'absolute' : 'absolute')};
  bottom: ${(props) => (props.isTrigger ? '24px' : '240px')};
  right: ${(props) => (props.isTrigger ? 'calc(var(--size-grid002) + 24px)' : '20px')};

  @media all and (min-width: 960px) {
    bottom: 24px;
    right: ${(props) => (props.isTrigger ? 'calc(var(--size-grid002) + 24px)' : '24px')};
  }
  @media all and (min-width: 1920px) {
    right: ${(props) => (props.isTrigger ? 'calc(var(--size-grid003) + 24px)' : '24px')};
  }
  @media all and (min-width: 2560px) {
    right: ${(props) => (props.isTrigger ? 'calc(var(--size-grid004) + 24px)' : '24px')};
  }
`;

const ZoomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;

  & div {
    margin: 6px 0px;
  }
`;

const IconLess = 'https://cdn.volkswagen.es/repository/app/vw-oneform/assets/icons/ic_less.svg';
const IconMore = 'https://cdn.volkswagen.es/repository/app/vw-oneform/assets/icons/ic_more.svg';

const Controllers = (props: { gelocalizationActive: boolean; suggestionIsAddress: boolean; isTrigger?: boolean }) => {
  const { handleChangeInMapZoom, centerInGeoLocatedCenter, centerRef, centerMap } = useMapGeneralController();

  const { gelocalizationActive, isTrigger, suggestionIsAddress } = props;

  return (
    <ThemeProvider theme="main">
      <ControllersWrapper className="controllers-wrapper-map" isTrigger={isTrigger}>
        {gelocalizationActive || (suggestionIsAddress && centerRef.current) ? (
          <ControllerBackground>
            <CTA
              tag="button"
              emphasis="secondary"
              onClick={(e) => {
                e.preventDefault();
                if (suggestionIsAddress && centerRef.current) {
                  centerMap(centerRef.current);
                } else {
                  centerInGeoLocatedCenter();
                }
              }}
              ariaLabel="aria label"
              icon={<Locate variant="small" />}
            />
          </ControllerBackground>
        ) : null}
        <ZoomWrapper className="zoom-controllers">
          <ControllerBackground>
            <CTA
              tag="button"
              emphasis="secondary"
              onClick={(e) => {
                e.preventDefault();
                handleChangeInMapZoom(true);
              }}
              ariaLabel="aria label"
              icon={<Add variant="small" />}
            />
          </ControllerBackground>
          <ControllerBackground>
            <CTA
              tag="button"
              emphasis="secondary"
              onClick={(e) => {
                e.preventDefault();
                handleChangeInMapZoom(false);
              }}
              ariaLabel="aria label"
              icon={<Remove variant="small" />}
            />
          </ControllerBackground>
        </ZoomWrapper>
      </ControllersWrapper>
    </ThemeProvider>
  );
};
