import { Spinner, Text, styled } from '@volkswagen-onehub/components-core';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureServices, useTrackingManager } from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { IdPerson } from 'src/types';
import { FidelizacionForm } from './FidelizacionForm';
import { GenericErrorLayer } from 'src/components/layers';
import { createPincodeEntity } from '../../dps-utils';

export interface FidelizacionFormProps {
  idPerson: IdPerson;
  dpsData: DpsData;
  handleError: () => void;
  presubmit: boolean;
  trackFormLoad: () => void;
  showPantallaModelo: boolean;
}

export function FidelizacionController() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [usado, setUsado] = useState(false);
  const [presubmit, setPresubmit] = useState(false);
  const [showPantallaModelo, setShowPantallaModelo] = useState<boolean>(false);
  const today = dayjs(new Date());
  const trackingManager = useTrackingManager();
  const dpsDataRef = useRef(null);
  const idPersonRef = useRef(null);
  const dispatch = useDispatch();
  const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

  const handleNext = (value: any) => {};
  const handleComplete = () => {
    const dpsData = oneFormService.getDpsData();

    if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
      handleError();
    } else if (dpsData.pincode.pincodeUsed) {
      setUsado(true);
      setLoading(false);
    } else {
      const idPerson = createPincodeEntity(dpsData, 'PERSON');

      dpsDataRef.current = dpsData;
      idPersonRef.current = idPerson;

      setLoading(false);
    }
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  const trackFormLoad = () => {
    trackingManager.trackFormLoad();
  };

  useEffect(() => {
    const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

    oneFormCoreDpsIsLoaded.subscribe({
      next(value) {
        handleNext(value);
      },
      error(err) {
        console.error(err);
        handleError();
      },
      complete() {
        handleComplete();
      },
    });
  }, []);

  if (loading) {
    return <Loading />;
  } else if (error) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, algo ha pasado"
          bodycopy="Por alguna razón no hemos podido cargar el cuestionario."
          ctaLayer="Reintentar"
          notShowCTA={true}
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else if (usado) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, ya has respondido a este cuestionario"
          bodycopy="Parece que ya nos has contado cómo fue tu experiencia. ¿Quieres añadir algo más?"
          ctaLayer="Contacta con nosotros"
          ctaType="a"
          urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else {
    return (
      <FidelizacionForm
        dpsData={dpsDataRef.current}
        idPerson={idPersonRef.current}
        handleError={handleError}
        presubmit={presubmit}
        trackFormLoad={trackFormLoad}
        showPantallaModelo={showPantallaModelo}
      />
    );
  }
}
interface CenterContentProps {
  height: string;
}
const CenterContent = styled.div<CenterContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? props.height : '100%')};
`;

const Loading = () => {
  const [height, setHeight] = useState('100%');

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    let aux;

    if (viewportWidth < 560) {
      aux = viewportHeight - 152;
    } else if (viewportWidth < 1600) {
      aux = viewportHeight - 252;
    } else {
      aux = viewportHeight - 380;
    }
    setHeight(`${aux}px`);
  }, []);

  return (
    <CenterContent height={height}>
      <Spinner variant="large" />
      <Text>Cargando tus datos</Text>
    </CenterContent>
  );
};
