import dayjs from 'dayjs';
import { Store } from 'redux';
import { getOption, getModelFilter, getWheelsParams } from 'src/bff';
import { emptyConfigurationFromVicci } from 'src/feature-app/tracking-objects';
import {
  TrackingConfiguration,
  TrackingEventInfo,
  TrackingForm,
  TrackingLink,
  TrackingPartner,
  TrackingProcess,
} from 'src/feature-app/tracking-types';
import { DealersData, FormInfo, OneFormState, Slot, Steps } from 'src/types';

export const createTrackingLink = (name?: string): TrackingLink | undefined => {
  return {
    url: window ? window.location.href : undefined,
    name: name ? name : undefined,
  };
};

export const createPartner = (store: Store<OneFormState>, dealerData?: DealersData): TrackingPartner => {
  const { formData, dealersInfo } = store.getState();
  const { dealer } = formData.fields;
  const { selectedDealer } = dealersInfo;

  const dealerFromStore = dealer ? dealer : selectedDealer ? selectedDealer : undefined;

  const dealerToUse = dealerData ? dealerData : dealerFromStore;

  return {
    informationBnr: dealerToUse?.kvps,
    informationDepartment: undefined,
    informationZIP: dealerToUse?.markerInfo?.dealerInfo?.zipCode,
    informationName: dealerToUse?.name,
  };
};

export const createPartnerWithSearch = (store: Store<OneFormState>, dealerData?: DealersData): TrackingPartner => {
  const { formData, formInfo } = store.getState();
  const basePartner = createPartner(store, dealerData);
  const SearchType = formInfo.suggestionIsDealer
    ? 'DealerSuggestion'
    : !formInfo.suggestionIsDealer
    ? 'GooglePlacesSuggestion'
    : undefined;

  return {
    ...basePartner,
    SearchSearchTerm: formData.fields.location,
    SearchType,
    SearchNumberOfResults: undefined,
  };
};

const getStepNumber = (formInfo: FormInfo) => {
  const { step } = formInfo;

  if (!step) return undefined;

  return String(step.screenIndex + 1);
};

const getStepName = (formInfo: FormInfo, processInfo?: TrackingProcess): string | undefined => {
  if (processInfo) return processInfo.StepName;

  const { step, multiStepScreenIndex, multiSteps } = formInfo;

  if (!step) {
    return undefined;
  }
  if (step.multiStep && multiStepScreenIndex >= 0) {
    return multiSteps && multiSteps[multiStepScreenIndex].name ? multiSteps[multiStepScreenIndex].name : undefined;
  } // Devolvemos el nombre del step dentro del multsitep.


  if(formInfo.formSubmitted) return 'ThankYou';

  return step.name ? step.name : undefined;
};

export const createTrackingProcess = (store: Store<OneFormState>, processInfo?: TrackingProcess): TrackingProcess => {
  const { formInfo } = store.getState();

  let StepName = getStepName(formInfo, processInfo);
  let StepNumber = getStepNumber(formInfo);

  return {
    StepName,
    StepNumber,
  };
};

export const getFormAppointments = (store: Store<OneFormState>): TrackingForm => {
  const { formData } = store.getState();
  const { slot }: { slot?: Slot } = formData.fields;

  let Appointments;
  let NumberOfAppointments;

  if (slot) {
    Appointments = [dayjs(slot.from).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')];
    NumberOfAppointments = 1;
  }
  return {
    Appointments,
    NumberOfAppointments,
  };
};

export const getMarketingConsent = (store: Store<OneFormState>): TrackingForm => {
  const { formData } = store.getState();
  const { lssi, tmk } = formData.fields;

  if (!lssi || !tmk) {
    return {
      MarketingConsent: [],
    };
  }

  return {
    MarketingConsent: [
      {
        comunicaciones: lssi === 'PERMITE',
        perfilado: tmk === 'PERMITE',
      },
    ],
  };
};

export const getBaseEventInfo = (store: Store<OneFormState>, brand: 'V' | 'N'): TrackingEventInfo => {
  const { pageCategory } = store.getState().formData.fields;

  const baseEventInfo: TrackingEventInfo = {
    brand: brand === 'N' ? 'commercial' : 'passenger',
    pageCategory,
    sectionName: undefined,
    templateType: 'Feature App Template',
  };
  return baseEventInfo;
};

export const getCompleteConfigurationFromVicci = (vicciData: VicciData): TrackingConfiguration => {
  if (!vicciData) return emptyConfigurationFromVicci;

  const { vehicleConfiguration } = vicciData.consolidatedData;
  const { COD_VEH_WHEELS, DESC_VEH_WHEELS } = getWheelsParams(vicciData.featuresData.vehicleCatalogue.features);
  return {
    ...emptyConfigurationFromVicci,
    ModelId: vehicleConfiguration.modelKey.split('$')[0],
    ModelModelVersion: vehicleConfiguration.modelVersion,
    ModelName: vehicleConfiguration.modelName,
    ModelYear: vehicleConfiguration.modelYear,
    ExteriorColorCode: vehicleConfiguration.exterior?.code,
    ExteriorColorName: vehicleConfiguration.exterior?.name,
    InteriorColorCode: vehicleConfiguration.interior?.code,
    InteriorColorName: vehicleConfiguration.interior?.name,
    WheelsBasicId: COD_VEH_WHEELS,
    WheelsBasicName: DESC_VEH_WHEELS,
  };
};

export const getCarlineConfigurationFromMkc = (marketingCode: any): TrackingConfiguration => {
  if (!marketingCode) return {};

  const salesgroup = getOption("SALESGROUP",marketingCode.content.consolidatedData.options);
  const modelyear = getOption("MODELYEAR",marketingCode.content.consolidatedData.options);
  const type = getOption("TYPE",marketingCode.content.consolidatedData.options);
  const baseType = getOption("BASETYPE",marketingCode.content.consolidatedData.options);

  const equipmentLine = getModelFilter("EquipmentLine",marketingCode.content.consolidatedData.meta.modelFilter);

  return {
    CarlineId: marketingCode.content.consolidatedData.model_code.split("_")[1],
    CarlineName: marketingCode.content.consolidatedData.model_description,
    SalesgroupId: salesgroup.option.code.replace('SALESGROUP:',''),
    SalesgroupName: salesgroup.option.description,
    EquipmentlineName: equipmentLine.modelFilter.text,
    ModelId: baseType.option.code.replace('BASETYPE:',''),
    ModelName: type.option.description,
    ModelYear: modelyear.option.code.replace('MODELYEAR:',''),
  };
};

export const getCarlineConfiguration = (store: Store<OneFormState>): TrackingConfiguration => {
  const { dealersInfo, formData } = store.getState();
  const { selectedCarline, oferta, salesGroup, match } = dealersInfo;
  const { vicciData, matchMarketingCodeData, marketingCodeResponse } = formData.fields;

  if (marketingCodeResponse) {
    return getCarlineConfigurationFromMkc(marketingCodeResponse);
  }

  let configuration: TrackingConfiguration = {};
  if (selectedCarline) {
    configuration = {
      CarlineId: String(selectedCarline.code),
      CarlineName: selectedCarline.title,
    };
  }
  if (oferta) {
    configuration = {
      ...configuration,
      CarlineId: String(oferta.carline.code),
      CarlineName: oferta.carline.title,
    };
  }
  if (salesGroup) {
    configuration = {
      ...configuration,
      SalesgroupId: salesGroup.salesGroupId,
      SalesgroupName: salesGroup.salesGroupName,
    };
  }

  if (match && matchMarketingCodeData) {
    configuration = {
      ...configuration
    };
  }
  return configuration;
};
