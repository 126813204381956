import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Layout,
  styled,
  Text,
  TextAlignment,
  ThemeProvider,
  ThemeReference,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TriggerProps, useTransformToHtml, useFeatureServices } from 'src/feature-app';
import { useFaContent, OneFormContent } from 'src/feature-app/hooks';
import { CitaVentaForm, MasInformacionForm, PresupuestoForm, TestDriveForm, StockForm } from 'src/forms';
import { Calc, Calendar, Info, Interior } from 'src/icons-core-imports';
interface ContentProps {
  centerGradient?: boolean;
  themeColor: ThemeReference;
}

const Content = styled.div<ContentProps>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 100%;
  }

  background: ${(props) => props.themeColor === 'inverted' ? props.theme.colors.gradient.radial : '#ffffff' };

  @media all and (min-width: 960px) {
    background.primary: ${(props) =>
		props.themeColor === 'inverted' 
		?
		props.centerGradient ? props.theme.colors.gradient.radial : props.theme.colors.gradient.right
		:
		'#ffffff'};
  }
`;
const TextWrapper = styled.div`
  padding-bottom: 44px;

  @media all and (min-width: 960px) {
    padding-bottom: 0;
    padding-right: var(--size-grid002);
    width: var(--size-grid012);
  }
  @media all and (min-width: 1280px) {
    padding-right: var(--size-grid002);
    width: var(--size-grid013);
  }
  @media all and (min-width: 1600px) {
    padding-right: var(--size-grid003);
    width: var(--size-grid013);
  }
  @media all and (min-width: 1920px) {
    padding-right: var(--size-grid002);
    width: var(--size-grid013);
  }
  @media all and (min-width: 2560px) {
    padding-right: var(--size-grid002);
    width: var(--size-grid014);
  }
`;
const Box = styled.div`
  margin: 44px 0;
  padding: var(--size-grid001);
  border: ${(props) => (props.theme.colors.background.primary === '#ffffff' ? '2px solid #001e50' : '2px solid #ffffff')};
  width: 100%;
  display: flex;
  flex-direction: column;

  @media all and (min-width: 560px) {
    margin: 68px 0;
    padding: var(--size-grid002);
  }
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding: var(--size-grid001);
  }
  @media all and (min-width: 1600px) {
    margin: 84px 0;
    padding: var(--size-grid001);
  }
`;

const CenterCTA = styled.div`
  text-align: center;
`;

const thereIsOnlyOneForm = (faContent: OneFormContent) => faContent && faContent.forms && faContent.forms.length === 1;

export function LeadTrigger({ setSelectedJourney, setCTALabel }: TriggerProps) {
  const faContent = useFaContent();
  const dispatch = useDispatch();
  const userInteractionService = useFeatureServices()['user-interaction-service'];

  const [firstCta, setFirstCta] = useState(null);
  const [secondCta, setSecondCta] = useState(null);
  const [thirdCta, setThirdCta] = useState(null);
  const [fourthCta, setFourthCta] = useState(null);
  const [fiveCta, setFiveCta] = useState(null);

  const [theme, setTheme] = useState<ThemeReference>('inverted');

  const openTestDriveLayer = () => {
    handleLayerOpen(<TestDriveForm />, 'Pruébalo');
  };
  const openPresupuestoLayer = () => {
    handleLayerOpen(<PresupuestoForm />, 'Pide presupuesto');
  };
  const openMasInformacionLayer = () => {
    handleLayerOpen(<MasInformacionForm />, 'Pide más información');
  };
  const openCitaVentaLayer = () => {
    handleLayerOpen(<CitaVentaForm />, 'Pide cita');
  };
  const openStockLayer = () => {
    handleLayerOpen(<StockForm />, 'Stock');
  };

  useEffect(() => {
    if (faContent?.modo === 'Light') {
      setTheme('main');
    }

    if (faContent?.userInteraction === 'true') {
      userInteractionService.register('es-oneform::lead-test_drive', {
        action: () => {
          openTestDriveLayer();
        },
      });
      userInteractionService.register('es-oneform::lead-presupuesto', {
        action: () => {
          openPresupuestoLayer();
        },
      });
      userInteractionService.register('es-oneform::lead-mas_informacion', {
        action: () => {
          openMasInformacionLayer();
        },
      });
      userInteractionService.register('es-oneform::lead-cita', {
        action: () => {
          openCitaVentaLayer();
        },
      });
      userInteractionService.register('es-oneform::lead-stock', {
        action: () => {
          openStockLayer();
        },
      });
    }
  }, []);

  const renderTestDriveCta = (strechContent?: boolean) => {
    let icon = <Interior variant="small" />;

    if (thereIsOnlyOneForm(faContent)) {
      icon = null;
    }

    return (
      <CTA icon={icon} tag="button" onClick={(e) => openTestDriveLayer()} stretchContent={strechContent}>
        Pruébalo
      </CTA>
    );
  };

  const renderMasInformacionCta = (strechContent?: boolean) => {
    let icon = <Info variant="small" />;

    if (thereIsOnlyOneForm(faContent)) {
      icon = null;
    }

    return (
      <CTA icon={icon} tag="button" onClick={(e) => openMasInformacionLayer()} stretchContent={strechContent}>
        <span style={{ whiteSpace: 'nowrap' }}>Pide más información</span>
      </CTA>
    );
  };

  const renderPidePresupuestoCta = (strechContent?: boolean) => {
    let icon = <Calc variant="small" />;

    if (thereIsOnlyOneForm(faContent)) {
      icon = null;
    }

    return (
      <CTA icon={icon} tag="button" onClick={(e) => openPresupuestoLayer()} stretchContent={strechContent}>
        Pide presupuesto
      </CTA>
    );
  };

  const renderPideCitaCta = (strechContent?: boolean) => {
    let icon = <Calendar variant="small" />;

    if (thereIsOnlyOneForm(faContent)) {
      icon = null;
    }

    return (
      <CTA icon={icon} tag="button" onClick={(e) => openCitaVentaLayer()} stretchContent={strechContent}>
        Pide cita con un asesor
      </CTA>
    );
  };

  const renderStockCta = (strechContent?: boolean) => {
    let icon = <Info variant="small" />;

    if (thereIsOnlyOneForm(faContent)) {
      icon = null;
    }

    return (
      <CTA icon={icon} tag="button" onClick={(e) => openStockLayer()} stretchContent={strechContent}>
        Pide información del stock
      </CTA>
    );
  };

  const handleLayerOpen = (journey: JSX.Element, label: string) => {
    dispatch({ type: 'RESET_STATE' });
    setCTALabel(label);
    setSelectedJourney(journey);
  };

  const handleFaContent = () => {
    const width = window.innerWidth;
    let strechContent = false;

    if (width < 560 && thereIsOnlyOneForm(faContent)) {
      strechContent = true;
    }

    if (faContent?.forms) {
      const renderOrder = faContent.forms.map((form) => {
        switch (form) {
          case 'lead-cita':
            return renderPideCitaCta(strechContent);
          case 'lead-mas_informacion':
            return renderMasInformacionCta(strechContent);
          case 'lead-test_drive':
            return renderTestDriveCta(strechContent);
          case 'lead-presupuesto':
            return renderPidePresupuestoCta(strechContent);
        case 'lead-stock':
            return renderStockCta(strechContent);
          default:
            return null;
        }
      });
      if (renderOrder.length <= 5) {
        if (renderOrder[0]) {
          setFirstCta(renderOrder[0]);
        }
        if (renderOrder[0]) {
          setSecondCta(renderOrder[1]);
        }
        if (renderOrder[2]) {
          setThirdCta(renderOrder[2]);
        }
        if (renderOrder[3]) {
          setFourthCta(renderOrder[3]);
        }
        if (renderOrder[4]) {
          setFiveCta(renderOrder[4]);
        }
      }
    }
  };

  useEffect(() => {
    // getCarlines();
    handleFaContent();
  }, []);

  const alternativeHeadline = '<b>¿Qué te gustaría hacer ahora?</b>';
  const getTriggerHeadline = () =>
    faContent?.headline ? useTransformToHtml(faContent.headline) : useTransformToHtml(alternativeHeadline);

  const alternativeBodyCopy =
    'Estás un paso más cerca de disfrutar del Volkswagen que te gusta. Solo dinos cómo podemos acompañarte para terminar de resolver tus dudas.';
  const getTriggerbodycopy = () =>
    faContent?.bodycopy ? useTransformToHtml(faContent.bodycopy) : useTransformToHtml(alternativeBodyCopy);

  return (
    // Dinamizar el theme a través de la config de la feature-app
    <>
      {thereIsOnlyOneForm(faContent) ? (
        <ThemeProvider theme={theme}>
          <Content centerGradient={true} themeColor={theme}>
            <Layout
              appearance={{
                [Breakpoints.default]: [
                  { name: '.', columns: 2 },
                  { name: 'a', columns: 20 },
                  { name: '.', columns: 2 },
                ],
                [Breakpoints.b960]: [
                  { name: '.', columns: 3 },
                  { name: 'a', columns: 18 },
                  { name: '.', columns: 3 },
                ],
                [Breakpoints.b1280]: [
                  { name: '.', columns: 4 },
                  { name: 'a', columns: 16 },
                  { name: '.', columns: 4 },
                ],
                [Breakpoints.b1600]: [
                  { name: '.', columns: 1 },
                  { name: 'a', columns: 22 },
                  { name: '.', columns: 1 },
                ],
              }}
            >
              <Container wrap={'always'} padding={{ top: ContainerPadding.dynamic0250 }}>
                <Container wrap={'always'} gutter={ContainerGutter.dynamic0100}>
                  <Text textAlign={TextAlignment.center} appearance={TokenTextAppearance.headline300}>
                    <div dangerouslySetInnerHTML={getTriggerHeadline()} />
                  </Text>

                  <Text textAlign={TextAlignment.center} appearance={TokenTextAppearance.copy200}>
                    <div dangerouslySetInnerHTML={getTriggerbodycopy()} />
                  </Text>
                </Container>
                <Container
                  wrap={'always'}
                  gutter={ContainerGutter.static400}
                  padding={{ bottom: ContainerPadding.dynamic0250, top: ContainerPadding.dynamic0150 }}
                >
                  <CenterCTA>
                    <BreakpointWrapper max={Breakpoints.b560}>
                      {firstCta ? firstCta : null}
                      {secondCta ? secondCta : null}
                      {thirdCta ? thirdCta : null}
                      {fourthCta ? fourthCta : null}
                      {fiveCta ? fiveCta : null}
                    </BreakpointWrapper>

                    <BreakpointWrapper min={Breakpoints.b560}>
                      {firstCta ? firstCta : null}
                      {secondCta ? secondCta : null}
                      {thirdCta ? thirdCta : null}
                      {fourthCta ? fourthCta : null}
                      {fiveCta ? fiveCta : null}
                    </BreakpointWrapper>
                  </CenterCTA>
                </Container>
              </Container>
            </Layout>
          </Content>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <Content className="trigger-leads" themeColor={theme}>
            <Layout
              appearance={{
                [Breakpoints.default]: [
                  { name: '.', columns: 1 },
                  { name: 'a', columns: 22 },
                  { name: '.', columns: 1 },
                ],
              }}
            >
              <Box>
                <TextWrapper>
                  <Container wrap={'always'}>
                    <BreakpointWrapper max={Breakpoints.b1280}>
                      <Text appearance={TokenTextAppearance.headline300} bold>
                        {faContent?.headline ? faContent.headline : '¿Qué te gustaría hacer ahora?'}
                      </Text>
                    </BreakpointWrapper>
                    <BreakpointWrapper min={Breakpoints.b1280} max={Breakpoints.b1600}>
                      <Text appearance={TokenTextAppearance.headline400} bold>
                        {faContent?.headline ? faContent.headline : '¿Qué te gustaría hacer ahora?'}
                      </Text>
                    </BreakpointWrapper>
                    <BreakpointWrapper min={Breakpoints.b1600}>
                      <Text appearance={TokenTextAppearance.headline300} bold>
                        {faContent?.headline ? faContent.headline : '¿Qué te gustaría hacer ahora?'}
                      </Text>
                    </BreakpointWrapper>
                    <BreakpointWrapper min={Breakpoints.b560}>
                      <Container padding={{ top: ContainerPadding.dynamic0100 }}>
                        <Text appearance={TokenTextAppearance.copy200}>
                          {faContent?.bodycopy
                            ? faContent.bodycopy
                            : 'Estás un paso más cerca de disfrutar del Volkswagen que te gusta. Solo dinos cómo podemos acompañarte para terminar de resolver tus dudas.'}
                        </Text>
                      </Container>
                    </BreakpointWrapper>
                  </Container>
                </TextWrapper>
                <Container wrap={'always'} gutter={ContainerGutter.static400}>
                  {firstCta ? firstCta : null}
                  {secondCta ? secondCta : null}
                  {thirdCta ? thirdCta : null}
                  {fourthCta ? fourthCta : null}
                  {fiveCta ? fiveCta : null}
                </Container>
              </Box>
            </Layout>
          </Content>
        </ThemeProvider>
      )}
    </>
  );
}
