import React from 'react';
import {
  CTA,
} from '@volkswagen-onehub/components-core';

export type CTAAction = {
  label: string,
  emphasis: string,
}

interface CTAThankYouPageActionProps {
  ctaInfo: CTAAction;
  ctaFunction: any;
}

export const CTAThankYouPageAction = (props: CTAThankYouPageActionProps) => {
  const { ctaInfo, ctaFunction } = props;

  return (
    <CTA
      tag="button"
      emphasis={ctaInfo.emphasis}
      onClick={(e) => {
        e.preventDefault();
        ctaFunction();
      }}
    >
      {ctaInfo.label}
    </CTA>
  );
};
