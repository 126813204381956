import Axios, { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { Text } from '@volkswagen-onehub/components-core';
import { OneElementTemplate } from 'src/feature-app/Screen';
import { ConfirmacionProspects } from './ConfirmacionProspects';
import { Email, PantallaNombreApellido, CarlineSelect, findCarline } from 'src/components';
import { useDispatch, useStore } from 'react-redux';
import { getCarlines, getMolecularUrl } from 'src/bff';
import { isCalculadoraForm } from 'src/forms';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps, MolecularResponse } from 'src/types';
import {
  FormController,
  removeWhiteSpace,
  useFaContent,
  useFeatureAppConfig,
  useFeatureServices,
  useOneFormContext,
} from 'src/feature-app';
import { ThankYouProspects } from '.';
import { formatFields, formatHeaders, formDataBuilder } from '../format-fields';
import { removeStep, resetStepsScreenIndex } from '../helpers/add-remove-steps';
import { getCodInterestCarline, getDescInterestCarline, getMatchData, getCalculadoraData } from '../Leads';

export enum ProspectsSteps {
  Modelo,
  NombreApellido,
  Email,
  Confirmacion,
}

interface ProspectsFormProps {
  customSteps?: Steps[];
  updatedFormData?: Partial<FormDataGroup>;
  updatedFormInfo?: Partial<FormInfo>;
}

export function ProspectsVentaForm({ customSteps, updatedFormData }: ProspectsFormProps) {
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const config = useFeatureAppConfig();
  const dispatch = useDispatch();
  const faContent = useFaContent();

  const steps: Steps[] = customSteps
    ? customSteps
    : [
        {
          title: (
            <>
              <Text bold>¿Qué modelo </Text> te apetece conducir?
            </>
          ),
          fields: (
            <OneElementTemplate
              element={
                <div style={{ marginBottom: '2px' }}>
                  <CarlineSelect useCarline={true} />
                </div>
              }
            />
          ),
          screenIndex: ProspectsSteps.Modelo,
          name: 'Modelo',
          outputs: ['modelo'],
          outputsText: ['modelo'],
        },
        {
          title: (
            <>
              Queremos contártelo todo sobre nuestro próximo modelo, el Nuevo Taigo. Pero antes, nos gustaría saber{' '}
              <Text bold>quién eres</Text>
            </>
          ),
          fields: (
            <OneElementTemplate
              element={
                <div style={{ marginBottom: '2px' }}>
                  <PantallaNombreApellido />
                </div>
              }
            />
          ),
          screenIndex: ProspectsSteps.NombreApellido,
          name: 'NombreApellido',
          outputs: ['name', 'surname', 'secondSurname'],
          outputsText: ['name', 'surname', 'secondSurname'],
        },
        {
          title: (
            <>
              <Text bold>¿A qué email </Text>te enviamos las novedades sobre el Nuevo Taigo?
            </>
          ),
          fields: (
            <OneElementTemplate
              element={
                <div style={{ marginBottom: '2px' }}>
                  <Email paginaConfirmacion={false} required={true} />
                </div>
              }
            />
          ),
          screenIndex: ProspectsSteps.Email,
          name: 'Email',
          outputs: ['email'],
          outputsText: ['email'],
        },
        {
          fields: <ConfirmacionProspects />,
          screenIndex: ProspectsSteps.Confirmacion,
          name: 'Confirmacion',
          isLastStep: true,
          hidePreviousStep: true,
        },
      ];

  const NuevoFormData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_GDPR_ADAPTED: 'true',
      DESC_CAMPAIGNBLOCK: 'B5. VW Launch Prospects',
      DESC_FORMOBJECTIVE: 'Prospect',
      DESC_ID_CAMPAIGN: '7011n000000JrSNAA0',
      DESC_TOKEN: 'VW_DDB_FRM_PROSPECT_LAUNCH',
      ...updatedFormData?.headers,
    },
    fields: {
      formName: 'prospect',
      formType: 'otherform',
      pageCategory: 'Prospect',
      formFields: {
        DESC_LOPD: 'PERMITE',
        DESC_TRANF_DATA_THIRD: 'NO INFORMADO',
      },
      ...updatedFormData?.fields,
    }, // Analítica
  };

  const getFormInfo = (steps: Steps[]): Partial<FormInfo> => {
    const firstStep = steps[0];
    const lastStep = steps[steps.length - 1];
    const stepsHistory = [0];
    const numberOfScreens = steps.length;

    return {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens,
      formTheme: 'inverted',
      formType: 'layer',
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps,
      stepsHistory,
      thankYouComponent: <ThankYouProspects />,
    };
  };

  const getFormDataWithCarline = async () => {
    const carlineCode = faContent?.carlines[0];
    const carlines = await getCarlines();
    const carlineFromFaContent = carlines.find((carline) => carline.code === Number(carlineCode));

    return {
      ...NuevoFormData,
      fields: {
        ...NuevoFormData.fields,
        formFields: {
          ...NuevoFormData.fields.formFields,
          COD_INTEREST_CARLINE: carlineFromFaContent?.code,
          DESC_INTEREST_CARLINE: carlineFromFaContent?.title,
        },
      },
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();

    const { fields, headers } = formData;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, url, device } = getParamsForm();

      const data = {
        ...formatHeaders(headers),
        'headers[DESC_VN_VO]': 'VN',
        'headers[DESC_DEVICETYPE]': device,
        'headers[DESC_URLORIGIN]': url,
        'headers[FECHA_INSERTION_DATE]': today,
        'headers[TC]': tc,
        'headers[COD_FORM_ID]': `vw-oneform-${fields.formName}`,

        'fields[DESC_NAME]': fields.name,
        'fields[DESC_SURNAME_1]': fields.surname,
        'fields[DESC_SURNAME_2]': fields.secondSurname,
        'fields[DESC_EMAIL_ADDRESS]': removeWhiteSpace(fields.email),
        'fields[DESC_FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
        'fields[COD_INTEREST_CARLINE]': getCodInterestCarline(dealersInfo, fields.carlineCode),
        'fields[DESC_INTEREST_CARLINE]': getDescInterestCarline(dealersInfo, fields.modelo),
        ...formatFields(fields.formFields),

        'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
        ...(await getMatchData(formData, dealersInfo, faContent)),
      };

      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formDataInfo = formDataBuilder(dataParsed);

      const MOLECULAR_URL = getMolecularUrl(); // Legacy

      const response: AxiosResponse<MolecularResponse> = await Axios.post(
        `${MOLECULAR_URL}/request-dps-send.php`,
        formDataInfo
      );

      if (response && response.data && response.data.content && response.data.content.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const getFormDataAndInfo = async () => {
    let formData = { ...NuevoFormData };
    let formInfo = getFormInfo(steps);

    if (faContent?.match) {
      let match = faContent.match;
      const carlines = await getCarlines();
      const carline = findCarline(carlines, match.carline.code);
      dispatch({ type: 'SET_SELECTED_CARLINE', payload: carline });
      dispatch({ type: 'SET_CARLINES', payload: carlines });
      dispatch({ type: 'SET_OFERTA', payload: match });

      return { formData, formInfo };
    } else if (faContent?.carlines && faContent?.carlines?.length > 0) {
      formData = await getFormDataWithCarline();
      const newSteps = removeStep(steps, 'Modelo');
      const finalSteps = resetStepsScreenIndex(newSteps);
      formInfo = getFormInfo(finalSteps);
    } else {
      const carlines = await getCarlines();
      dispatch({ type: 'SET_CARLINES', payload: carlines });
    }

    return { formData, formInfo };
  };

  useEffect(() => {
    getFormDataAndInfo().then((response) => {
      const { formData, formInfo } = response;
      initializeForm(formInfo, formData);
    });
  }, []);

  return <FormController steps={steps} screenType="layer-screen" />;
}
