import {
  Breakpoints,
  Container,
  ContainerPadding,
  Layout,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import { FleetServicePrivate, CarPickupService } from 'src/icons-core-imports';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMapGeneralController, getDistanceFromLatLngCenter, usePinState } from 'src/feature-app';
import { Points } from 'src/types';
import { OneFormState } from 'src/types';
import {} from 'src/feature-app';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';

interface BoxCardProps {
  selected?: boolean;
  isCarousel: boolean;
  isHover?: boolean;
  isFirstDesktop?: boolean;
  greyCard?: boolean;
  isInverted?: boolean;
}

const BoxCard = styled.div<BoxCardProps>`
  width: 100%;
  margin-bottom: ${(props) => (props.isCarousel || props.isFirstDesktop ? '0' : '20px')};
  padding: ${(props) => (props.isCarousel ? '12px' : '20px')};
  border: ${(props) =>
    props.selected && props.greyCard && !props.isCarousel
      ? '2px solid #6A767D'
      : props.selected && props.isInverted && !props.isCarousel
      ? '2px solid #001E50'
      : props.greyCard && !props.isCarousel
      ? '1px solid #6A767D'
      : props.isInverted
      ? '1px solid #C2CACF'
      : props.selected && !props.isCarousel
      ? '2px solid #FFFFFF'
      : props.isCarousel
      ? 'none'
      : '1px solid rgba(255,255,255,0.44)'};
  :hover {
    border: ${(props) =>
      props.isCarousel
        ? 'none'
        : props.selected && props.greyCard
        ? '2px solid #6A767D'
        : props.selected && props.isInverted
        ? '2px solid #001E50'
        : props.greyCard
        ? '1px solid #6A767D'
        : props.selected
        ? '2px solid #FFFFFF'
        : '1px solid #4cc7f4'};
  }

  @media all and (min-width: 560px) {
    margin-bottom: ${(props) => (props.isCarousel || props.isFirstDesktop ? '0' : '24px')};
    padding: 20px;
  }
  @media screen and (min-width: 960px) {
    .main {
      border: ${(props) =>
        props.isCarousel
          ? 'none'
          : props.selected
          ? '2px solid #001e50;'
          : props.isHover
          ? '1px solid #0040C5'
          : '1px solid #DFE4E8'};
    }
    .inverted {
      border: ${(props) =>
        props.isCarousel
          ? 'none'
          : props.selected
          ? '2px solid #FFFFFF'
          : props.isHover
          ? '1px solid #4cc7f4'
          : '1px solid rgba(255,255,255,0.44)'};
    }
  }
  @media all and (min-width: 1600px) {
    margin-top: ${(props) => (props.isCarousel || props.isFirstDesktop ? '0' : '28px')};
  }
`;

interface isCarouselProps {
  isCarousel: boolean;
}

const EllipsisTextWrapper = styled.p<{ greyCard?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0px;
  margin-bottom: 0px;
  color: ${(props) => (props.greyCard ? '#6A767D' : '#000000')};
`;
const GreyText = styled.span<{ greyCard?: boolean }>`
  color: ${(props) => (props.greyCard ? '#6A767D' : '#000000')};
`;
const SVGSize = styled.div<{ greyCard: boolean }>`
  padding-left: 16px;
  svg {
    height: 20px;
    width: auto;
    color: ${(props) => (props.greyCard ? '#6A767D' : '#000000')};
  }
`;

const CustomCTA = styled.button<{ greyCard?: boolean }>`
  height: 32px;
  width: max-content;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 22px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.greyCard ? '#6A767D' : '#001E50')};
  color: ${(props) => (props.greyCard ? '#DFE4E8' : '#FFFFFF')};

  :hover {
    background-color: ${(props) => (props.greyCard ? '#6A767D' : '#001E50')};
  }
`;

interface DealerCardProps {
  distanceFromCenter: string;
  handleSeleccionarOnClick?: any;
  handleShowCalendarOnClick?: any;
  // center?: Center,
  isCarousel: boolean;
  dealerInfo: any;
  point: Points;
  slots: any;
  handleShowSlots?: (point: Points) => string | null;
  isCitaPosventa?: boolean;
  isFirstDesktop?: boolean;
  carPickupSelected?: boolean;
  replacementCarSelected?: boolean;
  isInverted?: boolean;
}

export const DealerCardWidget = React.memo((props: DealerCardProps) => {
  const {
    distanceFromCenter,
    isCarousel,
    dealerInfo,
    point,
    isFirstDesktop,
    carPickupSelected,
    replacementCarSelected,
    isInverted,
  } = props;
  const { suggestionIsAddress } = useSelector((state: OneFormState) => state.formInfo);
  const dealerCompletAdress = `${dealerInfo.markerInfo.dealerInfo.address}, ${dealerInfo.markerInfo.dealerInfo.zipCode} ${dealerInfo.markerInfo.dealerInfo.city}`;
  const {
    centerToCalculateDistance,
    handlePointClickAndCarouselMove,
    handleHoverPoint,
    hoverPoint,
    selectedPoint,
    userIsGeoLocated,
    handleClusterClick,
  } = useMapGeneralController();

  const { isActive, isHover } = usePinState(selectedPoint, hoverPoint, point);

  const [greyCard, setGreyCard] = useState<boolean>(false);

  useEffect(() => {
    if (point) {
      if (carPickupSelected && !point.properties.dealer.dmsInfo.pickupCarServiceAvailabe) {
        setGreyCard(true);
      } else if (replacementCarSelected && !point.properties.dealer.dmsInfo.replacementCarServiceAvailable) {
        setGreyCard(true);
      } else {
        setGreyCard(false);
      }
    }
  }, [carPickupSelected, replacementCarSelected, point]);

  return (
    <BoxCard
      selected={isActive}
      isCarousel={isCarousel}
      isHover={isHover}
      isFirstDesktop={isFirstDesktop}
      greyCard={greyCard}
      isInverted={isInverted}
      className="box-card-wrapper"
      onClick={(e) => {
        e.preventDefault();
        if (point.properties.parent_cluster_id) {
          if (window.innerWidth <= 959) {
            handleClusterClick(point, true, true);
          } else {
            handleClusterClick(point, true);
          }
        } else {
          handlePointClickAndCarouselMove(point);
        }
      }}
      onMouseEnter={(e) => {
        if (!isCarousel) {
          e.preventDefault();
          handleHoverPoint(point);
        }
      }}
      onMouseLeave={(e) => {
        if (!isCarousel) {
          e.preventDefault();
          handleHoverPoint(null);
        }
      }}
    >
      <div style={{ width: '100%' }}>
        {isCarousel ? (
          <>
            {/*card para el carousel*/}
            <Container wrap={'always'} stretchContent>
              <div style={{ width: '100%' }}>
                {userIsGeoLocated || suggestionIsAddress ? (
                  <Layout
                    appearance={{
                      [Breakpoints.default]: [
                        { name: 'a', columns: 19 },
                        { name: 'b', columns: 5 },
                      ],
                    }}
                  >
                    <Text bold textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200}>
                      <EllipsisTextWrapper greyCard={greyCard}>
                        {dealerInfo.name ? dealerInfo.name : null}
                      </EllipsisTextWrapper>
                    </Text>

                    <Text textAlign={TextAlignment.right} appearance={TokenTextAppearance.copy100}>
                      <GreyText greyCard={greyCard}>
                        {userIsGeoLocated || suggestionIsAddress
                          ? distanceFromCenter
                            ? `${distanceFromCenter}km`
                            : `${getDistanceFromLatLngCenter(centerToCalculateDistance.current, point)}km`
                          : null}
                      </GreyText>
                    </Text>
                  </Layout>
                ) : (
                  <Text bold textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200}>
                    <EllipsisTextWrapper greyCard={greyCard}>
                      {dealerInfo.name ? dealerInfo.name : null}
                    </EllipsisTextWrapper>
                  </Text>
                )}
              </div>
              <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100}>
                {!point.properties.dealer.dmsInfo || !point.properties.dealer.dmsInfo.dmsAvailable ? (
                  <p style={{ maxHeight: '40px', marginTop: '0', marginBottom: '0' }}>
                    <EllipsisTextWrapper greyCard={greyCard}>{dealerCompletAdress}</EllipsisTextWrapper>
                  </p>
                ) : (
                  <EllipsisTextWrapper greyCard={greyCard}>{dealerCompletAdress}</EllipsisTextWrapper>
                )}
              </Text>
            </Container>
            <>
              {(point?.properties?.dealer?.dmsInfo &&
                point?.properties?.dealer?.dmsInfo?.replacementCarServiceAvailable) ||
              (point?.properties?.dealer?.dmsInfo && point?.properties?.dealer?.dmsInfo?.pickupCarServiceAvailabe) ? (
                <Container
                  padding={{ top: ContainerPadding.static150 }}
                  horizontalAlign={'flexEnd'}
                  verticalAlign={'flexEnd'}
                  stretchContent
                  wrap={'always'}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {point.properties.dealer.dmsInfo.replacementCarServiceAvailable ? (
                      <SVGSize greyCard={greyCard}>
                        <FleetServicePrivate />
                      </SVGSize>
                    ) : null}
                    {point.properties.dealer.dmsInfo.pickupCarServiceAvailabe ? (
                      <SVGSize greyCard={greyCard}>
                        <CarPickupService />
                      </SVGSize>
                    ) : null}
                  </div>
                  <LeadsCTAs {...props} />
                </Container>
              ) : (
                <LeadsCTAs {...props} />
              )}
            </>
          </>
        ) : (
          <>
            {/*card para los tabs, cualquier breakpoint*/}
            <Container wrap={'always'} stretchContent>
              <div style={{ width: '100%', paddingBottom: '4px' }}>
                {userIsGeoLocated || suggestionIsAddress ? (
                  <Layout
                    appearance={{
                      [Breakpoints.default]: [
                        { name: 'a', columns: 18 },
                        { name: 'b', columns: 6 },
                      ],
                    }}
                  >
                    <Text bold textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200} staticSize>
                      <GreyText greyCard={greyCard}>{dealerInfo.name ? dealerInfo.name : null}</GreyText>
                    </Text>
                    <Text textAlign={TextAlignment.right} appearance={TokenTextAppearance.copy200} staticSize>
                      <GreyText greyCard={greyCard}>
                        {userIsGeoLocated || suggestionIsAddress
                          ? distanceFromCenter
                            ? `${distanceFromCenter}km`
                            : `${getDistanceFromLatLngCenter(centerToCalculateDistance.current, point)}km`
                          : null}
                      </GreyText>
                    </Text>
                  </Layout>
                ) : (
                  <Text bold textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200} staticSize>
                    <GreyText greyCard={greyCard}>{dealerInfo.name ? dealerInfo.name : null}</GreyText>
                  </Text>
                )}
              </div>
              <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100} staticSize>
                <p style={{ maxHeight: '48px', height: '100%', marginTop: '0', marginBottom: '0' }}>
                  <GreyText greyCard={greyCard}>{dealerCompletAdress}</GreyText>
                </p>
              </Text>
            </Container>
            <>
              {(point.properties.dealer.dmsInfo && point.properties.dealer.dmsInfo.replacementCarServiceAvailable) ||
              (point.properties.dealer.dmsInfo && point.properties.dealer.dmsInfo.pickupCarServiceAvailabe) ? (
                <Container
                  padding={{ top: ContainerPadding.static200 }}
                  horizontalAlign={'flex-end'}
                  verticalAlign={'flex-end'}
                  stretchContent
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <LeadsCTAs {...props} />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {point.properties.dealer.dmsInfo.replacementCarServiceAvailable ? (
                      <SVGSize greyCard={greyCard}>
                        <FleetServicePrivate />
                      </SVGSize>
                    ) : null}
                    {point.properties.dealer.dmsInfo.pickupCarServiceAvailabe ? (
                      <SVGSize greyCard={greyCard}>
                        <CarPickupService />
                      </SVGSize>
                    ) : null}
                  </div>
                </Container>
              ) : (
                <LeadsCTAs {...props} />
              )}
            </>
          </>
        )}
        {/* <LeadsCTAs {...props} /> */}
      </div>
    </BoxCard>
  );
});

const LeadsCTAs = (props: DealerCardProps) => {
  const { handleSeleccionarOnClick, dealerInfo, point, carPickupSelected, replacementCarSelected } = props;
  const [greyCard, setGreyCard] = useState<boolean>(false);

  const trackingManager = useTrackingManager();

  useEffect(() => {
    if (point) {
      if (carPickupSelected && !point.properties.dealer.dmsInfo.pickupCarServiceAvailabe) {
        setGreyCard(true);
      } else if (replacementCarSelected && !point.properties.dealer.dmsInfo.replacementCarServiceAvailable) {
        setGreyCard(true);
      } else {
        setGreyCard(false);
      }
    }
  }, [carPickupSelected, replacementCarSelected, point]);

  return (
    <Container
      verticalAlign={'flex-end'}
      horizontalAlign={'space-between'}
      padding={{ top: ContainerPadding.dynamic0050 }}
    >
      {/* <CTA
        tag="button"
        emphasis="primary"
        size="small"
        onClick={(e) => {
          e.preventDefault();
          trackingManager.trackFormButtonClick(
            {
              contentId: 'Seleccionar',
            },
            dealerInfo
          );

          handleSeleccionarOnClick(point);
        }}
      >
        Seleccionar
      </CTA> */}
      <CustomCTA
        greyCard={greyCard}
        onClick={(e) => {
          e.preventDefault();
          trackingManager.trackFormButtonClick(
            {
              contentId: 'Seleccionar',
            },
            dealerInfo
          );

          handleSeleccionarOnClick(point);
        }}
      >
        <Text appearance={TokenTextAppearance.label150} staticSize color={TokenTextColor.inherit}>
          Seleccionar
        </Text>
      </CustomCTA>
    </Container>
  );
};
