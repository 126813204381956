import React, { useEffect, useState } from 'react';
import {
  styled,
  Layout,
  Breakpoints,
} from '@volkswagen-onehub/components-core';
import { Points } from 'src/types';
import { useMapGeneralController } from 'src/feature-app';
import { SelectedTab } from 'src/types';

const TabsWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-top: 5px;
  @media all and (min-width: 960px) {
    /* width: 380px; */
    /* Falta ver como se comporta este width: 100% con el scroll */
    width: 100%;
  }

  & > div {
    @media all and (min-width: 960px) {
      height: calc(100vh - 174px);
      overflow-y: auto;
      padding-bottom: 24px;
    }
    @media all and (min-width: 1600px) {
      height: calc(100vh - 200px);
    }
  }
`;

const WidthWrapper = styled.div`
  width: 100vw;
  @media all and (min-width: 960px) {
    width: 100%;
  }
`;

interface SeleccionDealerTabsProps {
  pointsByDistance?: Points[];
  pointsByTime?: Points[];
  isTimeSelected?: boolean;
  setIsTimeSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  renderPoints: (points: Points[], isTime?: boolean) => JSX.Element[];
  setPreSelectedOrderValue?: React.Dispatch<React.SetStateAction<SelectedTab>>;
  preSelectedOrderValue?: string;
  pointsToList: Points[];
}

export function SeleccionDealerTabs(props: SeleccionDealerTabsProps) {
  const {
    pointsByDistance,
    pointsByTime,
    isTimeSelected,
    setIsTimeSelected,
    renderPoints,
    setPreSelectedOrderValue,
    preSelectedOrderValue,
    pointsToList,
  } = props;
  const { clusters } = useMapGeneralController();

  const [defaultIndex, setDefaultIndex] = useState<number>(0);

  useEffect(() => {
    if (preSelectedOrderValue && preSelectedOrderValue === 'horario') {
      setDefaultIndex(1);
    } else {
      setDefaultIndex(0);
    }
  }, [preSelectedOrderValue]);

  return (
    <WidthWrapper className="width-100">
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
        }}
      >
        {renderPoints(pointsToList, true)}

        {/* <Container padding={{ bottom: ContainerPadding.static200 }}>
          <Text appearance={TokenTextAppearance.headline200} bold>
            Ordenar por:
          </Text>
        </Container> */}

        {/* <TabsWrapper id="tabs-dealers-wrapper">
          <Tabs
            idPrefix="Tabs"
            onChange={(index) => {
              setDefaultIndex(index);
              if (index === 0) {
                setPreSelectedOrderValue('ubicacion');
              } else {
                setPreSelectedOrderValue('horario');
              }
            }}
            defaultIndex={defaultIndex}
          >
            {{
              title: 'Ubicación',
              content: renderPoints(pointsToList, false),
              key: 'ubicacion',
            }}
            {{ title: 'Horario', content: renderPoints(pointsToList, true), key: 'horario' }}
          </Tabs>
        </TabsWrapper> */}
      </Layout>
    </WidthWrapper>
  );
}
