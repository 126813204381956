import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { CanalDeContacto, CarlineSelect, PantallaNombreApellido, Email, Telefono, CodigoPostal, ContactInfoWrapper } from 'src/components';
import { OneElementTemplate, OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { MapaLeads, ConfirmacionLeadsNoQuestions } from 'src/forms';
import {
  DetalleMotivo,
  ConsentQuestion
} from 'src/components';
import { FormInfo, Steps } from 'src/types';
import { LeadsFormsProps } from '../LeadGenericoForm';
import { useInitializeLeadForm } from '../use-initialize-lead-form';
import { LeadGenericoController } from '../LeadGenericoController';
import { useIsComerciales } from 'src/feature-app/hooks';
import { leadsMasInformacionDealerPartialData, leadsMasInformacionPartialData } from '.';
import { MapaLeadsInPage, PartialDataMasInformacionConfigurador } from 'src/forms/LeadsInPage';

const getMapaFields = (inPage: boolean) => {
  if (inPage === true) {
    return <MapaLeadsInPage formName="masInformacion" />;
  } else {
    return <MapaLeads formName="masInformacion" />;
  }
};

enum MasInformacionSteps {
  Modelo,
  Mapa,
  NombreApellido,
  CodigoPostal,
  Contacto,
  EmailTelefono,
  Comentarios,
  Confirmacion,
}


export function MasInformacionForm({ updatedFormData, inPage, isConfigurador, isLeadDealer }: LeadsFormsProps) {
  const isComerciales = useIsComerciales();

  const [partialData, setPartialData] = useState(null);

  useEffect(() => {
    if (updatedFormData) {
      setPartialData(updatedFormData);
    } else if (isLeadDealer) {
      setPartialData(leadsMasInformacionDealerPartialData);
    } else {
      setPartialData(leadsMasInformacionPartialData);
    }
  }, []);

  const defaultSteps: Steps[] = [
    {
      title: (
        <>
          <Text bold>¿Qué modelo </Text> te interesa?
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<div style={{ marginBottom: '2px' }}><CarlineSelect useCarline={true} /></div>} />
            :
            <OneElementTemplate element={<div style={{ marginBottom: '2px' }}><CarlineSelect useCarline={true} /></div>} />
          }
        </>
      ),
      screenIndex: MasInformacionSteps.Modelo,
      name: 'Modelo',
      outputs: ['modelo'],
      outputsText: ['modelo'],
    },
    isLeadDealer ? null :
      {
        screenIndex: MasInformacionSteps.Mapa,
        name: 'Mapa',
        multiStep: true,
        component: getMapaFields(inPage),
      },
    {
      title: (
        <>
          Para poder preparar tu visita, necesitamos saber <Text bold>quién eres</Text>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<div style={{ marginBottom: '2px' }}><PantallaNombreApellido isComerciales={isComerciales} /></div>} />
            :
            <OneElementTemplate element={<div style={{ marginBottom: '2px' }}><PantallaNombreApellido isComerciales={isComerciales} /></div>} />
          }
        </>
      ),
      screenIndex: MasInformacionSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
    },
    {
      title: (
        <>
          También nos gustaría conocer <Text bold>tu código postal</Text>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<div style={{ marginBottom: '2px' }}><CodigoPostal /></div>} />
            :
            <OneElementTemplate element={<div style={{ marginBottom: '2px' }}><CodigoPostal /></div>} />
          }
        </>
      ),
      screenIndex: MasInformacionSteps.CodigoPostal,
      name: 'CodigoPostal',
      outputs: ['codigoPostal'],
      outputsText: ['codigoPostal'],
    },
    {
      title: (
        <>
          <Text bold>¿Cómo prefieres </Text>que contactemos contigo?
          <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<CanalDeContacto />} />
            :
            <OneElementTemplate element={<CanalDeContacto />} />
          }
        </>
      ),
      screenIndex: MasInformacionSteps.Contacto,
      name: 'Contacto',
      outputs: ['contacto'],
      outputsText: ['contacto'],
    },
    {
      title: (
        <>
          Para ello necesitaremos <Text bold>tus datos de contacto</Text>
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen
              element={
                <ContactInfoWrapper>
                  <Telefono paginaConfirmacion={false} required={true} />
                  <Email paginaConfirmacion={false} required={true} paddingTop='24px' />
                </ContactInfoWrapper>
              }
            />
            :
            <OneElementTemplate
              element={
                <ContactInfoWrapper>
                  <Telefono paginaConfirmacion={false} required={true} />
                  <Email paginaConfirmacion={false} required={true} paddingTop='24px' />
                </ContactInfoWrapper>
              }
            />
          }
        </>
      ),
      screenIndex: MasInformacionSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      title: (
        <>
          ¿Quieres saber <Text bold>algo en concreto</Text>?
        </>
      ),
      fields: (
        <>
          {inPage ?
            <OneElementTemplateFullScreen element={<DetalleMotivo name="comments"/>} />
            :
            <OneElementTemplate element={<DetalleMotivo name="comments"/>} />
          }
        </>
      ),
      screenIndex: MasInformacionSteps.Comentarios,
      name: 'Comentarios',
      outputs: ['comments'],
      outputsText: ['comments'],
    },
    {
      fields: <ConsentQuestion questionType='lssi' />,
      name: 'lssi',
      outputs: ['lssi'],
    },
    {
      fields: <ConsentQuestion questionType='tmk' />,
      name: 'tmk',
      outputs: ['tmk'],
    },
    {
      fields: <ConfirmacionLeadsNoQuestions />,
      isLastStep: true,
      hidePreviousStep: true,
      name: 'Confirmacion',
    },
  ];
  const filteredSteps = defaultSteps.filter((step) => step !== null);

  const { loading, steps, firstStep, lastStep } = useInitializeLeadForm(filteredSteps, false, inPage);

  const citaVentaInfo: Partial<FormInfo> = {
    numberOfScreens: steps.length,
  };

  return loading ? null : (
    <LeadGenericoController
      steps={steps}
      leadData={isConfigurador ? PartialDataMasInformacionConfigurador : partialData}
      leadInfo={citaVentaInfo}
      firstStep={firstStep}
      lastStep={lastStep}
      inPage={inPage}
      isConfigurador={isConfigurador}
    />
  );
}
