import { appendJsonKey } from 'src/forms';
import { DealersData, FormDataGroup } from 'src/types';

export const isCalculadoraForm = (formName: string) => formName === 'prospect-posventa-calculadora';

export const getCalculadoraData = (formData: FormDataGroup) => {
  const { fields } = formData;
  const dealer: DealersData = fields.dealer;

  if (!isCalculadoraForm(fields.formName)) {
    return null;
  } else {
    const calculadoraFields = {
      'fields[DESC_DEALER_CODE]': dealer?.dmsInfo?.dealerId?.codInstalacion,
      'fields[DESC_DEALER_CODE_KVPS]': dealer?.kvps,
      'fields[DESC_DEALER]': dealer?.name,
      'fields[DESC_DEALER_ADDRESS]': getAddress(dealer),
      'fields[NUM_PHONE_ALTERNATIVE]': dealer?.markerInfo?.dealerInfo?.phone,
      'fields[DESC_AUXFIELD1]': dealer?.markerInfo?.dealerInfo?.mail,
      'fields[DESC_VEH_PLATE]': fields.matricula,
      'fields[DESC_VEH_PLATE_DATE]': fields.anoMatriculacion + ' 00:00:00',
      'fields[DESC_ACTUAL_VEHICLE_MODEL]': fields.modelo,
      'fields[DESC_RATING_MILEAGE]': fields.kilometros,
      'fields[DESC_BUDGET]': appendJsonKey(fields.budget),
      'fields[DESC_AUXFIELD11]': fields.deepLink,
    };

    return { ...calculadoraFields };
  }
};

const getAddress = (dealerData: DealersData) => {
  const { address, zipCode, city, province, country } = dealerData?.markerInfo?.dealerInfo;
  return `${address} ${zipCode} ${city} ${province} ${country}`;
};
