import { styled } from '@volkswagen-onehub/components-core';

export const ContactInfoWrapper = styled.div`
  width: 100%;
  margin-bottom: 2px;
  .email-input-wrapper {
    padding-top: 24px;
  }
  @media all and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    .email-input-wrapper {
      padding-top: 44px;
    }
  }
  @media all and (max-height: 624px) {
    .email-input-wrapper {
      padding-top: 24px;
    }
  }
`; 
