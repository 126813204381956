import { getParamsForm } from 'src/feature-app/get-params-form';
import { getSfAddressRest, sanitizeKvps } from 'src/forms/Cem/helpers';
import { formatToTwoChars } from 'src/helpers';
import { IdDealer, IdOrder, IdPerson, IdVehicle } from 'src/types';

const urlParams = new URLSearchParams(window.location.search);
const pincode = urlParams.get('pincode');
const agency = urlParams.get('agency');
const adviser = urlParams.get('adviser');

const { tc, today, url, device } = getParamsForm();

export const getCommonFormData = (idPerson: IdPerson, idVehicle: IdVehicle, installation: IdDealer, idOrder: IdOrder) => {

	return {
		'headers[FORM_NAME]': 'vw-oneform-cem-segunda_entrega-invitacion',
		'headers[FORM_VERSION]': 'vw-oneform-cem-segunda_entrega-invitacion-' + process.env.VERSION,
		'headers[FORM_URL]': url,
		'headers[FORM_DATE_INSERTION]': today,
		'headers[FORM_DETAILS]': 'CEM Segunda entrega',
		'headers[FORM_OBJECTIVE]': 'Encuesta',
		'headers[ORIGIN_DEVICE_TYPE]': device,
		'headers[ORIGIN_TRACKING_CODE]': tc,
		'headers[PINCODE]': pincode,
		'headers[DPS_TOKEN]': 'VW_DDB_FRM_CEM_SEGUNDA_ENTREGA_INVITACION',
		'headers[FK_SYSTEM]': idOrder.VGED_ClimaproOrderID__c,

		'fields[DPS_BRAND]': 'Volkswagen',
		'fields[SF_LEAD_INSTALLATION_ID]': installation?.Id,
		'fields[SF_LEAD_INSTALLATION_NAME]': installation?.VGED_CommercialName__c,
		'fields[SF_LEAD_INSTALLATION_CODE]': installation?.VGED_DealerCode__c,
		'fields[SF_LEAD_INSTALLATION_CODE_KVPS]': installation?.KVPSCode__c ? sanitizeKvps(installation?.KVPSCode__c) : '',
		'fields[VEH_ACTUAL_FRAME]': idVehicle?.Name,
		'fields[VEH_ACTUAL_PLATE]': idVehicle?.VGED_RegistrationPlate__c,
		'fields[VEH_ACTUAL_MODEL]': idVehicle?.VGED_ModelGroup__c,
		'fields[PERSON_NAME]': idPerson?.FirstName,
		'fields[PERSON_SURNAME_1]': idPerson?.LastName,
		'fields[PERSON_SURNAME_2]': idPerson?.MiddleName,
		'fields[PERSON_PHONE_MOBILE]': idPerson?.PersonMobilePhone,
		'fields[PERSON_EMAIL]': idPerson?.PersonEmail,
		'fields[PERSON_POSTAL_ROAD_NAME]': getSfAddressRest(idPerson?.PersonMailingAddress, 'street'),
		'fields[PERSON_POSTAL_CP]': getSfAddressRest(idPerson?.PersonMailingAddress, 'postalCode'),
		'fields[PERSON_POSTAL_PROVINCE_CODE]': formatToTwoChars(getSfAddressRest(idPerson?.PersonMailingAddress, 'stateCode')),
		'fields[SURVEY_ANSWER_05]': adviser,

		'flags[PERSON_RGPD_ADAPTED]': 'true',
		'flags[FLAG_RELACION_MARCA_SIN_IC]': 'false',
		'flags[FLAG_AGENCY]': agency == 'AGENCIA' ? 'true' : 'false',
	};
};
