import React, {useEffect} from 'react';
import {getDealers} from 'src/bff';
import {TriggerProps, useFaContent, useGetDealers} from 'src/feature-app';
import {FormDataFields} from 'src/types';
import {NuevaCitaPosventaFormController} from 'src/forms/CitaPosventa/NuevaCitaPosventa';

export function CitaPosventaBonosForm({setSelectedJourney}: TriggerProps) {
	const faContent = useFaContent();
	const {loading} = useGetDealers(faContent?.dealer);

	const renderForm = (fields: FormDataFields) => {
		setSelectedJourney(
			<NuevaCitaPosventaFormController
				isCitaPosventaEmbeddedBonos
				updatedFormData={{
					fields,
				}}
			/>,
			true,
		);
	};
	useEffect(() => {
		if (loading) return;

		const fields = {
			motivo: 'OTROS',
			formName: 'cita-posventa-bono',
			masInformacion: faContent?.bono[0].desc,
			deepLink: faContent?.deepLink,
		};
		const kvps = faContent?.dealer;

		getDealers()
			.then(() => {
				renderForm({...fields});
			})
			.catch((err) => {
				renderForm({...fields}); // Hay que ver que hacer en estos casos.
			});
	}, [loading]);

	return <></>;
}
