import { getSentryHub } from 'src/feature-app/sentry';

export const reportErrorSentry = (error: any) => {
  const hub = getSentryHub();
  hub.run((currentHub) => {
    currentHub.withScope((scope) => {
        currentHub.captureException(error);
    });
  });
};
