import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { CarlineSelect, MultipleCTASelection, PantallaIntroduccionStars } from 'src/components';
import { FormController, useOneFormContext } from 'src/feature-app';
import { CTAsSiNo } from 'src/feature-app/InputsValues/CTAsObjects';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { formDataBuilder } from 'src/forms/format-fields';
import { addRemovedStep, getIndexInSteps, removeStep, resetStepsScreenIndex } from 'src/forms/helpers/add-remove-steps';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { useInitializeNoCompraForm, NoCompraFormProps, MapaNoCompra, PantallaOtrosModelos, ConfirmacionNoCompra, ThankYouNoCompra, getCommonFormData, RatingSteps } from '.';
import { getDescInterestCarline } from '../Leads/helpers';

export enum NoCompraNoTengoSteps {
  ContactoConcesionario,
  QuieroVolkswagen,
  QuieroContacto,
  Modelo,
  Valoracion,
	Visita,
	Precio,
	Equipamiento,
	Consumo,
	Garantia,
	Financiacion,
  Mapa,
  Confirmacion,
}

export function NoCompraNoTengo({ dpsData, idLead, idPerson, handleError, trackFormLoad, estado }: NoCompraFormProps) {
  const { initializeForm, handleNextStep } = useOneFormContext();
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { contactoConcesionario, quieroVolkswagen, quieroContacto } = formData.fields;
  const dispatch = useDispatch();
  const [loadingPresubmit, setLoadingPresubmit] = useState(true);
  const store = useStore();

  const ctasContactoConcesionario = [
    {
      label: 'Sí',
      value: 'true'
    },
    {
      label: 'No he tenido contacto con el concesionario',
      value: 'false'
    },
  ];

  const ctasQuieroContacto = [
    {
      label: 'Sí',
      value: 'true'
    },
    {
      label: 'No, ya no tengo interés',
      value: 'false'
    },
  ];


  const steps: Steps[] = [
    {
      title: (
        <>
        Sabiendo que sigues buscando un vehículo que se ajuste a tus necesidades, nos gustaría confirmar contigo algunos datos. <Text bold>¿Has estado en contacto con el concesionario Volkswagen recientemente?</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={ctasContactoConcesionario} name="contactoConcesionario" nextOnClick />}
        />
      ),
      screenIndex: NoCompraNoTengoSteps.ContactoConcesionario,
      name: 'contactoConcesionario',
      trueOrFalseQuestion: true,
    },
    {
      title: (
        <>
          Nos encantaría saber más.{' '}
          <Text bold>
            ¿Aún te interesa conducir un{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              Volkswagen?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsSiNo} name="quieroVolkswagen" ctaWidth="81" nextOnClick />}
        />
      ),
      screenIndex: NoCompraNoTengoSteps.QuieroVolkswagen,
      name: 'QuieroVolkswagen',
      trueOrFalseQuestion: true,
    },
    {
      title: (
        <>
          <Text bold>¿Quieres que un concesionario</Text> contacte contigo para resolver tus{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            dudas?
            <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={ctasQuieroContacto} name="quieroContacto" nextOnClick />}
        />
      ),
      screenIndex: NoCompraNoTengoSteps.QuieroContacto,
      name: 'QuieroContacto',
      showOverflow: true,
    },
    {
      title: (
        <>
          <Text bold>¿Qué modelo </Text>en{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            concreto?
            <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ marginBottom: '2px' }}>
              <CarlineSelect useCarline={true} notALayer={true} nextOnSelect />
            </div>
          }
        />
      ),
      screenIndex: NoCompraNoTengoSteps.Modelo,
      name: 'Modelo',
      showOverflow: true,
    },
		{
      title: (
        <>
          Nos hubiera encantado que fuera un Volkswagen. <Text bold>¿Nos ayudas a mejorar?</Text>
        </>
      ),
      fields: <PantallaIntroduccionStars />,
      screenIndex: NoCompraNoTengoSteps.Valoracion,
      name: 'Valoracion',
    },

    ...RatingSteps('no_tengo',  NoCompraNoTengoSteps.Valoracion),

    {
      screenIndex: NoCompraNoTengoSteps.Mapa,
      name: 'Mapa',
      multiStep: true,
      component: <MapaNoCompra formName="venta" showHorario={false} />,
    },
    {
      fields: <ConfirmacionNoCompra idLead={idLead} />,
      screenIndex: NoCompraNoTengoSteps.Confirmacion,
      name: 'Confirmacion',
    },
  ];

	
	useEffect(() => {
		if (contactoConcesionario) {
			updateSteps('QuieroVolkswagen', contactoConcesionario == 'true');
			updateSteps('QuieroContacto', contactoConcesionario == 'false');
		}
	}, [contactoConcesionario]);

	useEffect(() => {
		if (quieroVolkswagen) {
			updateSteps('Modelo', quieroVolkswagen == 'true');
		}
	}, [quieroVolkswagen]);

	useEffect(() => {
		if (quieroContacto) {
			const steps = ['Modelo', 'Valoracion', 'Gestion', 'Precio', 'Equipamiento', 'Consumo', 'Garantia', 'Financiacion', 'Mapa'];

			steps.reverse().forEach(function(step) {
				updateSteps(step, quieroContacto == 'true');
			});
		}
	}, [quieroContacto]);


	const updateSteps = (stepName: string, conditional: boolean) => {
		const { steps: actualSteps, screenIndex } = store.getState().formInfo;
		let newSteps = [...actualSteps];
		if (conditional) {
			const stepIndex = getIndexInSteps(newSteps, stepName);

			if (stepIndex === -1) {
				newSteps = addRemovedStep(steps, actualSteps, stepName, screenIndex);
			}
		} else {
			newSteps = removeStep(newSteps, stepName);
		}

		const newStepsWithIndex = resetStepsScreenIndex(newSteps);

		dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
		dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
		dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
	};


  useEffect(() => {
    const sendPresubmit = async () => {
      const result = await sendForm(true);

      if (!result) {
        handleError();
      }
      setLoadingPresubmit(false);
    };

    sendPresubmit();
  }, []);

  const NuevoFormData: Partial<FormDataGroup> = {
    headers: {},
    fields: {
      formName: 'no_compra-no_tengo',
      formType: 'otherform',
      pageCategory: 'Encuesta'
    }
  };

  const generateData = (isPresubmit: boolean, data: Partial<FormDataGroup>) => {
    const { formData, dealersInfo }: OneFormState = store.getState();
    const { fields } = formData;
    const { selectedInstallation } = dealersInfo;

    const personData = fields.quieroContacto == 'true' ? {
      'fields[PERSON_NAME]': idLead.FirstName,
      'fields[PERSON_SURNAME_1]': idLead.LastName,
      'fields[PERSON_SURNAME_2]': idLead.MiddleName,
      'fields[PERSON_EMAIL]': idLead.Email,
      'fields[PERSON_PHONE_MOBILE]': idLead.MobilePhone,
    } : null;

    const installationData = selectedInstallation && fields.quieroContacto == 'true' ? {
      'fields[SF_LEAD_INSTALLATION_ID]': selectedInstallation.Id,
      'fields[SF_LEAD_INSTALLATION_CODE]': selectedInstallation.VGED_DealerCode__c,
      'fields[SF_LEAD_INSTALLATION_NAME]': selectedInstallation.VGED_CommercialName__c,
      'fields[SF_LEAD_INSTALLATION_PROVINCE_CODE]': selectedInstallation.ShippingStateCode.toString().padStart(2, '0'),
      'fields[SF_LEAD_RECORDTYPE_ID]': '0121i000000D7NyAAK',
      //'fields[SF_LEAD_RECORDTYPE_NAME]': 'Sales Assistant',
      'fields[SF_LEAD_SALES_INITIAL_ACTION]': 'Direct to Sales Assistant',
      'fields[VEH_INTEREST_ID]': fields.carlineCode,
      'fields[VEH_INTEREST_MODEL]': getDescInterestCarline(dealersInfo, fields.modelo),
    } : null;

    const submitData = !isPresubmit ? {
      ...personData,
      ...installationData,
      'fields[SURVEY_ANSWER_13]': fields.contactoConcesionario,
      'fields[SURVEY_ANSWER_15]': fields.quieroVolkswagen == 'true' ? getDescInterestCarline(dealersInfo, fields.modelo) : null,
      'fields[SURVEY_ANSWER_28]': fields.gestion,
      'fields[SURVEY_ANSWER_30]': fields.precio,
      'fields[SURVEY_ANSWER_31]': fields.equipamiento,
      'fields[SURVEY_ANSWER_32]': fields.consumo,
      'fields[SURVEY_ANSWER_33]': fields.garantia,
      'fields[SURVEY_ANSWER_34]': fields.financiacion,
      'fields[SURVEY_ANSWER_35]': fields.quieroContacto,
			'flags[FLAG_SF_INTEGRATION]' : fields.quieroContacto == 'true',
    } : null;

    return {
      ...getCommonFormData(isPresubmit, idLead, dpsData, fields, data),
      ...submitData,
    };
  };

  const sendForm = async (isPresubmit?: boolean): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateData(isPresubmit, NuevoFormData);
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData, isPresubmit);

      if (response?.data?.status?.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { firstStep, lastStep, loading } = useInitializeNoCompraForm(steps);

  useEffect(() => {
    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps,
      stepsHistory: [0],
      thankYouComponent: <ThankYouNoCompra />,
    };

    if (!loading) {
      initializeForm(NuevoFormInfo, NuevoFormData, true);
      trackFormLoad();
    }
  }, [loading]);

  return loading || loadingPresubmit || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
