import axios, { AxiosResponse } from 'axios';
import { getMolecularCdnUrl, getOneformBFFCdnUrl, getOneformBFFCdnApiKey, reportErrorSentry } from 'src/bff';

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving Volkswagen Vilma service config provider.');
  return null;
};

export const getVilmaAssets = async (frame: string) => {

  const oneformCdn = getOneformBFFCdnUrl();
  const oneformBFFApiKey = getOneformBFFCdnApiKey();

  try {
    const response: AxiosResponse = await axios.get(
      `${oneformCdn}/vilma/assets?frame=${frame}`,
      {
        timeout: 10000,
        headers: {
          'X-Api-Key': oneformBFFApiKey
        }
      }
    );
    return response.data;
  } catch (error) {
    reportErrorSentry(error);
    return null;
  }
}
