export const handleClickCarPickup = (carPickupSelected: boolean, setCarPickupSelected: (value: boolean) => void ) => {
  if(!carPickupSelected) {
    setCarPickupSelected(true);
  } else {
    setCarPickupSelected(false);
  }
};

export const handleClickReplacementCar = (replacementCarSelected: boolean, setReplacementCarSelected: (value: boolean) => void) => {
  if(!replacementCarSelected) {
    setReplacementCarSelected(true);
  } else {
    setReplacementCarSelected(false);
  }
};
