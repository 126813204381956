import React from 'react';
import { 
  Text, 
  styled, 
  Container, 
  ContainerGutter,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { SuggestModelo } from 'src/components';


const Wrapper = styled.div`
  margin-top: 44px;
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
const MarginTop = styled.div`
  margin-top: 44px;
  @media all and (min-width: 960px) {
    margin-top: 0;
  }
`;

export const PantallaOtrosModelos = () => {

  return (
    <Wrapper notALayer={true}>
      <Container gutter={ContainerGutter.static200} wrap={'always'}>
        <div>
          <Text appearance={TokenTextAppearance.copy200} staticSize>Dudo entre este modelo<sup>*</sup>:</Text>
        </div>
        <SuggestModelo notALayer={true} marginTop='0' fieldsName='modeloUno' required={true}/>
      </Container>
      <Container gutter={ContainerGutter.static200} wrap={'always'}>
        <MarginTop>
          <Text appearance={TokenTextAppearance.copy200} staticSize>Y este otro:</Text>
        </MarginTop>
        <SuggestModelo notALayer={true} marginTop='0' fieldsName='modeloDos' required={false}/>
      </Container>
    </Wrapper>
  );
};
