import {
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  styled,
  Text,
  ThemeProvider,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { NuevaCitaPosventaFormController } from 'src/forms/CitaPosventa/NuevaCitaPosventa';

const BlueBackground = styled.div`
  background: radial-gradient(circle at center bottom, #00437A 0%, #001E50 100%);
  color: #ffffff;
  height: 100%;
`;

export const CitaPreviaTile = (props: { setSelectedJourney: React.Dispatch<React.SetStateAction<JSX.Element>> }) => {
  const { setSelectedJourney } = props;
  return (
    <Container>
      <ThemeProvider theme="inverted">
        <BlueBackground>
          <Container>
            <Container>
              <Container
                padding={{
                  top: { 0: ContainerPadding.dynamic0250, 960: ContainerPadding.dynamic0130 },
                  bottom: ContainerPadding.dynamic0130,
                }}
                wrap={"always"}
                gutter={ContainerGutter.dynamic0130}
              >
                <Text appearance={TokenTextAppearance.headline300} color={TokenTextColor.inherit}>
                  ¿Necesitas<Text bold> pasar por el&nbsp;taller?</Text>
                </Text>
                <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.inherit}>
                  Elige el día, la hora y el Servicio Oficial que prefieras para que cuiden de tu Volkswagen.
                </Text>
                <CTA
                  tag={'button'}
                  emphasis="primary"
                  onClick={() => {
                    setSelectedJourney(<NuevaCitaPosventaFormController />);
                  }}
                >
                  Pide tu cita
                </CTA>
              </Container>
            </Container>
          </Container>
        </BlueBackground>
      </ThemeProvider>
    </Container>
  );
};
