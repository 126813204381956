import React, { useState } from 'react';
import { RadioButton } from '@volkswagen-onehub/components-core';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { OneFormState } from 'src/types';

interface ChecksProps {
  questionName: string;
  avoidNextStep?: boolean;
  answers: string[];
  correctAnswer?: string;
}

export const Checks = (props: ChecksProps) => {
  const { questionName, answers, avoidNextStep, correctAnswer } = props;
  const [checkedIndex, setCheckedIndex] = useState(null);
  const dispatch = useDispatch();

  const { handleNextStep } = useOneFormContext();
  const [value, setValue] = useState(null);
  const { register } = useFormContext();
  const { fields } = useSelector((state: OneFormState) => state.formData);
  const currentCorrectAnswers = fields.answers || 0;

  const handleOnClick = (e: any) => {
    if (avoidNextStep) {
      // si no vamos a next step guardaremos el valor en store
      dispatch({ type: 'UPDATE_FIELDS', payload: { [questionName]: e.target.value } });
    } else {
      setTimeout(
        () => {
          if (correctAnswer && correctAnswer === e.target.value) {
            dispatch({ type: 'UPDATE_FIELDS', payload: { ['answers']: currentCorrectAnswers + 1 } });
          }
          handleNextStep();
        }, 0
      );
    }
  };

  return (
    <div style={{ paddingTop: '42px' }}>
      {answers.map((answer, index) => {
        return (
          <div style={{ paddingBottom: '20px', paddingLeft: '12px' }}>
            <RadioButton
              value={answer}
              label={answer}
              name={questionName}
              onClick={(e) => {
                setValue(answer);
                setCheckedIndex(index);
                handleOnClick(e);
              }}
              checked={checkedIndex === index}
            />
          </div>
        )
      })}

      <input
        type="hidden"
        name={questionName}
        value={value}
        ref={register({
          required: {
            value: true,
            message: 'Requerido',
          },
        })}
      />
    </div>
  );
};
