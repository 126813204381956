import { getMolecularServiceConfig } from 'src/feature-app';

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving Molecular service config provider.');
  return null;
};

export const getMolecularUrl = (): string | null => {
  const molecular = getMolecularServiceConfig();

  if (!molecular) {
    return handleErrorInRetrievingExternalConfig();
  }

  return molecular.urlOrigin + molecular.urlPath;
};
