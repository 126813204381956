import axios, { AxiosResponse } from 'axios';
import { getDataUrl, reportErrorSentry } from 'src/bff';
import { DealersData } from 'src/types';

export const getDealers = async (): Promise<DealersData[]> => {
  try {
    const dataUrl = getDataUrl();

    if (!dataUrl) {
      console.error('Error during fetch.');
      return null;
    }
    const dealers: AxiosResponse<{ dealers: DealersData[] }> = await axios.get(`${dataUrl}/getDealers`);
    return dealers.data.dealers;
  } catch (error) {
    reportErrorSentry(error);
    return null;
  }
};
