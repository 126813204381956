import React from 'react';
import { RatingStars, valuesSinRespuesta } from 'src/components';

export const getRatingStarsOutput = (value: string) => {
	const rating = parseInt(value);
	const { valueSinRespuesta, labelSinRespuesta } = valuesSinRespuesta();
	if(valueSinRespuesta == rating){
		return labelSinRespuesta;
	}
	return <RatingStars preSelectedRate={rating} isPrevStep />;
};
