import React, {useEffect} from 'react';
import {getDealerKvps} from 'src/bff';
import {TriggerProps, useFaContent, useGetDealers, VehicleCalculadora} from 'src/feature-app';
import {FormDataFields} from 'src/types';
import {AppointmentVehicle, transformMillage} from 'src/forms';
import {NuevaCitaPosventaFormController} from 'src/forms/CitaPosventa/NuevaCitaPosventa';

const getAppointmentVehicle = (vehicle: VehicleCalculadora): AppointmentVehicle => {
	return {
		brand: 'V',
		frame: vehicle.vin,
		millage: transformMillage(vehicle.mileage),
		modelVersion: vehicle.modelDescr,
		modelYear: 0,
		plate: vehicle.plate.toUpperCase(),
		plateDate: `${vehicle.plateDate} 00:00:00`,
		tmaimg: vehicle.modelKey,
		model: {
			code: vehicle.familyCode,
			key: vehicle.familyCode,
			name: vehicle.familyDesc,
		},
	};
};

//NO SE USA

export function CitaPosventaCalculadoraForm({setSelectedJourney}: TriggerProps) {
	// const faContent = useFaContent();
	// const { loading } = useGetDealers(faContent?.dealer);

	// const renderForm = (fields: FormDataFields) => {
	//   setSelectedJourney(
	//     <NuevaCitaPosventaFormController
	//       isCitaPosventaEmbeddedCalculadora
	//       updatedFormData={{
	//         fields,
	//       }}
	//     />,
	//     true
	//   );
	// };
	// useEffect(() => {
	//   if (loading) return;

	//   const fields = {
	//     motivo: 'MANTENIMIENTO',
	//     formName: 'cita-posventa-calculadora',
	//     matricula: faContent?.vehicle[0]?.plate,
	//     vehicleForCalculadora: getAppointmentVehicle(faContent?.vehicle[0]),
	//     kilometros: transformMillage(faContent?.vehicle[0]?.mileage),
	//     anoMatriculacion: faContent?.vehicle[0]?.plateDate,
	//     modelo: faContent?.vehicle[0]?.modelDescr,
	//     budget: { pvp: faContent?.quotePvp, operations: [...faContent?.quoteOperations] },
	//     deepLink: faContent?.deepLink,
	//   };
	//   const kvps = faContent?.dealer;

	//   getDealerKvps(kvps)
	//   .then((dealer) => {
	//       renderForm({ ...fields, dealer });
	//     })
	//     .catch((err) => {
	//       renderForm({ ...fields, dealer: {} }); // Hay que ver que hacer en estos casos.
	//     });
	// }, [loading]);

	return <></>;
}
