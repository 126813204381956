import { Color } from 'src';
import { getColors } from 'src/bff';

export const getColorInfo = async (colorCode: string): Promise<Color> => {
  if (!colorCode) return null;
  let colorInfo: Color;

  const colors = await getColors();
  const aux = colorCode.split(' ');
  if (colorCode.includes('#')) {
    colorInfo = colors.colores.find((a) => a.hex === colorCode);
  }
  else {
    colorInfo = colors.colores.find((a) => a.code === aux[1]);
  }

  return colorInfo;
};
