import { PincodeResponseOk } from 'src/bff';
import { DpsData } from 'src/feature-service/es-oneform-core-feature-service/types';
import { IdLead, IdPerson, IdOrder, IdVehicle, IdDealer } from 'src/types';

export const createPincodeEntity = (dpsData: DpsData, stringToFind: 'LEAD' | 'PERSON' | 'ORDER' | 'VEHICLE' | 'DEALER') => {
  if (!dpsData.pincode?.pincodeResponse) {
    return null;
  }
  const atts = (dpsData.pincode?.pincodeResponse?.content as PincodeResponseOk[]).find((att: any) =>
    att.attributes.find((att: any) => att.name === 'ID' && att.value === stringToFind)
  );

  if (!atts) {
    return null;
  }

  const id: IdLead | IdPerson | IdOrder | IdVehicle | IdDealer = atts.attributes.reduce((obj: any, att: any) => {
    const key = Object.values(att)[0] as any;
    const value = Object.values(att)[1] as any;
    obj[key] = value;
    return obj;
  }, {});

  return id;
};
