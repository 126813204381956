import { Content } from '@volkswagen-onehub/fa-content-service';
import { useFeatureServices } from 'src/feature-app';
import { DealersData, MatchSelectedOptions, Offer } from 'src/types';

export type EmbeddedFeatureApp =
	| 'es-ofertas'
	| 'es-stock-teaser'
	| 'es-match'
	| 'es-calculadora'
	| 'es-maintenance-plan'
	| 'es-portal'
	| 'es-bono'
	| 'es-my-benefits'
	| 'Configure';

export interface VehiclePortal {
	vehicleClimaproId: number;
	frame: string;
	image: string;
	comercialDenomination: string;
	commercialDenominationSli: string;
	familyModelCode: string;
	familyModelDesc: string;
	tmaimg: string | null;
	license: string | null;
	dealerDate: number;
	licenseDate: number | undefined;
	deliveryDate: number;

	//Maintenance
	climaProKM?: number;
	localKM?: number;
	maintenanceYears?: number;
	maintenanceMonths?: number;
	maintenanceDays?: number;
	maintenanceTotalDays?: number;
	alertNextMaintenance?: boolean;
	maintenance?: any;
	repairOrders?: any;
	editKm?: boolean;

	//Benefits
	salesGroup?: string;
	personVehicleRelation?: boolean;
	flagIsVO?: boolean;
}

interface PortalFields {
	vehicle: VehiclePortal[];
	quotePvp?: string;
	quoteOperations?: {
		id: string; // Identificador único de la operación
		name: string; // Nombre de la operación
		pvp: string | undefined; // Precio de la operación
	}[];
	operation?: {
		id: string; // Identificador único de la operación
		name: string; // Nombre de la operación
	}[];
}

//NO SE USA
export interface VehicleCalculadora {
	familyCode: string;
	familyDesc: string;
	modelDescr: string;
	modelKey: string;
	vin: string;
	mileage: number;
	plate: string;
	plateDate: string;
}

// interface CalculadoraFields {
//   dealer: string; // Código KVPS
//   vehicle: VehicleCalculadora[];
//   quotePvp: string;
//   quoteOperations: {
//     id: string; // Identificador único de la operación
//     name: string; // Nombre de la operación
//     pvp: string; // Precio de la operación
//   }[];
//   deepLink: string; // URL para acceder a calculadora con el presupuesto precargado
// }

type WidgetOptions = 'Mapa';
export interface OneFormContent extends Content, PortalFields {
	'bodycopy-logged'?: string;
	'headline-logged'?: string;
	'action'?: string;
	'argumentatives'?: string;
	'bodycopy'?: string;
	'bono'?: any;
	'carlines'?: number[] | string[];
	'cem'?: 'VENTA' | 'POSVENTA';
	'cta'?: string;
	'dpsFields'?: {
		field: 'headers[DESC_FORM_DETAIL]' | 'headers[DETALLE_TIPO_PETICION_SIM]';
		value: string;
	}[];
	'encuesta'?:
		| 'CEM - VENTA'
		| 'CEM - VENTA PRODUCTO'
		| 'CEM - POSVENTA'
		| 'CEM - ONESHOP'
		| 'CEM - SEGUNDA ENTREGA - INVITACION'
		| 'CEM - SEGUNDA ENTREGA - VALORACION'
		| 'LEM'
		| 'NO COMPRA'
		| 'NO RENOVACIÓN';
	'featureApp'?: EmbeddedFeatureApp;
	'forms'?: LeadForm[];
	'headline'?: string;
	'intro'?: string;
	'isCitaPosventaStageOrDefault'?: 'Default' | 'Stage';
	'lead_type'?: 'test-drive' | 'presupuesto' | 'mas-informacion' | 'cita' | 'stock' | 'call-center';
	'marketingCodeConfig'?: { SalesgroupId: string; SalesgroupName: string };
	'match'?: Offer; // Hay que implementar el type Preconfiguration
	'matchOptions'?: MatchSelectedOptions;
	'mgm'?: 'Bono Cliente' | 'Bono Regalo' | 'Envío Regalo';
	'mgmImgSrc'?: string;
	'mode'?: 'Salesgroup' | 'Carline' | 'CarConfiguratorFS' | 'Mixed';
	'modo'?: 'Dark' | 'Light';
	'motivo'?: string;
	'navidadImgSrc'?: string;
	'observaciones'?: string;
	'oferta'?: Offer;
	'race'?: 'true' | 'false';
	'salesgroups'?: number[];
	'stageCitaImgSrc'?: string;
	'thankYouPage'?: string;
	'triggerCase'?: 'Generico' | 'OneShop';
	'userInteraction'?: 'false' | 'true';
	'widget'?: WidgetOptions;
	'feedback'?: string;
	'idkit'?: string;
	'climapro'?: string;
}

export type LeadForm = 'lead-presupuesto' | 'lead-mas_informacion' | 'lead-test_drive' | 'lead-cita' | 'lead-stock';

export function useFaContent(): OneFormContent | undefined {
	return useFeatureServices()['fa-content-service'].content as OneFormContent;
}
