import { styled } from '@volkswagen-onehub/components-core';
import React from 'react';

const Svg = styled.svg`
  width: 100vw;
  height: auto;
  svg{
    width: 100vw;
  }
`;
const SvgWrapper = styled.div`
`;

export const SobreMobile = () => {
  return (
    <SvgWrapper>
      <Svg width="559px" height="2264px" viewBox="0 0 559 2264" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Custom Preset 2</title>
        <defs>
          <radialGradient cx="50%" cy="0%" fx="50%" fy="0%" r="391.956359%" gradientTransform="translate(0.500000,0.000000),scale(1.000000,0.246873),rotate(90.000000),scale(1.000000,1.019570),translate(-0.500000,-0.000000)" id="radialGradient-1">
            <stop stopColor="#013865" offset="0%"/>
            <stop stopColor="#001E50" offset="100%"/>
          </radialGradient>
          <path d="M1.49066667,0 L559,0 L305.078608,175.597393 C289.266437,186.532179 268.321802,186.480441 252.563847,175.467668 L1.49066667,0 L1.49066667,0 Z" id="path-2"/>
          <filter x="-12.5%" y="-27.6%" width="124.9%" height="166.6%" filterUnits="objectBoundingBox" id="filter-3">
            <feOffset dx="0" dy="16" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation="20.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0667340472 0" type="matrix" in="shadowBlurOuter1"/>
          </filter>
        </defs>
        <g id="Custom-Preset-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group-3" transform="translate(0.000000, 0.000000)">
            <rect id="blue-gradient-100-copy-4" fill="url(#radialGradient-1)" x="0" y="0" width="559" height="2264.32267"/>
            <g id="Path-3-Copy-4">
              <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2"/>
              <use fill="#001E50" fillRule="evenodd" xlinkHref="#path-2"/>
            </g>
          </g>
        </g>
      </Svg>
    </SvgWrapper>
  );
};

export const SobreDesktop = () => {
  return (
    <SvgWrapper>
      <Svg width="2002px" height="959px" viewBox="0 0 2002 959" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Group 2</title>
        <defs>
          <radialGradient cx="50%" cy="0%" fx="50%" fy="0%" r="96.7634199%" gradientTransform="translate(0.500000,0.000000),scale(0.485447,1.000000),rotate(90.000000),scale(1.000000,1.019570),translate(-0.500000,-0.000000)" id="radialGradient-1">
            <stop stopColor="#013865" offset="0%" />
            <stop stopColor="#001E50" offset="100%" />
          </radialGradient>
          <path d="M2.67222222,0 L1921.69743,0 L998.770946,420.047084 C972.326962,432.082407 941.95603,432.021845 915.560254,419.881157 L2.67222222,0 L2.67222222,0 Z" id="path-2" />
          <filter x="-3.6%" y="-12.2%" width="107.2%" height="129.4%" filterUnits="objectBoundingBox" id="filter-3">
            <feOffset dx="0" dy="16" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur stdDeviation="20.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
            <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0667340472 0" type="matrix" in="shadowBlurOuter1" />
          </filter>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Landing-navidad-2-Copy" transform="translate(43.000000, -2999.000000)">
            <g id="Group-2" transform="translate(-4.000000, 3024.000000)">
              <rect id="blue-gradient-100-copy-2" fill="url(#radialGradient-1)" x="0" y="0" width="1924" height="934" />
                <g id="Path-3-Copy-2">
                  <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2" />
                  <use fill="#001E50" fillRule="evenodd" xlinkHref="#path-2" />
                </g>
            </g>
          </g>
        </g>
      </Svg>
    </SvgWrapper>
  );
};
export const Sobre2560Desktop = () => {
  return (
    <SvgWrapper>
      <Svg width="1920px" height="642px" viewBox="0 0 1920 642" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>WEb DDB</title>
        <defs>
          <radialGradient cx="50%" cy="0%" fx="50%" fy="0%" r="96.7634199%" gradientTransform="translate(0.500000,0.000000),scale(0.334375,1.000000),rotate(90.000000),scale(1.000000,1.019570),translate(-0.500000,-0.000000)" id="radialGradient-1">
            <stop stopColor="#013865" offset="0%"/>
            <stop stopColor="#001E50" offset="100%"/>
          </radialGradient>
          <path d="M3,0 L1918,0 L985.638872,293.497924 C965.995308,299.68152 944.921647,299.649571 925.296923,293.40644 L3,0 L3,0 Z" id="path-2"/>
          <filter x="-3.6%" y="-17.7%" width="107.3%" height="144.3%" filterUnits="objectBoundingBox" id="filter-3">
            <feOffset dx="0" dy="16" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation="20.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.0667340472 0" type="matrix" in="shadowBlurOuter1"/>
          </filter>
        </defs>
        <g id="WEb-DDB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group-2-Copy">
            <rect id="blue-gradient-100-copy-2" fill="url(#radialGradient-1)" x="0" y="0" width="1920" height="642"/>
            <g id="Path-3-Copy-2">
                <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2"/>
                <use fill="#001E50" fillRule="evenodd" xlinkHref="#path-2"/>
            </g>
          </g>
        </g>
      </Svg>
    </SvgWrapper>
  );
};

export const CircleCheck = () => {
  return (
    <SvgWrapper>
      <Svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>icon check</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Landing-navidad-2-Copy" transform="translate(-340.000000, -3301.000000)">
            <g id="campos-wishlist-copy-2" transform="translate(320.000000, 3282.000000)">
              <g id="campo-ID4" transform="translate(1.000000, 0.000000)">
                <g id="icon-check" transform="translate(19.000000, 19.000000)">
                  <circle id="Oval" fill="#00DEFF" cx="11" cy="11" r="11"/>
                  <polyline id="Path-2" stroke="#002255" strokeWidth="4" strokeLinecap="round" points="5.46461351 11.5215069 9.45757095 15.2987067 15.5657597 7.96537337"/>
                </g>
              </g>
            </g>
          </g>
        </g>
    </Svg>
    </SvgWrapper>
  );
};
