import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'src/feature-app';

export function useAddressPredictions(input: string, selectedValue: boolean): any[] {

  
  const [predictions, setPredictions] = useState([]);

  const autocomplete = useRef();

  if (!autocomplete.current && window.google) {
    autocomplete.current = new window.google.maps.places.AutocompleteService();
  }

  async function getPlacePredictions(input: string) {
    await autocomplete.current.getPlacePredictions(
      { input, types: ['geocode'], componentRestrictions: { country: 'es' } },
      (predictions) => {
        // El type regions también devuelve como resultado el país, por lo que hay que filtrarlo.
        if (predictions) {
          predictions = predictions.filter((prediction) => !prediction.types.includes('country'));
          setPredictions(predictions.map((prediction) => prediction.description));
        }
      }
    );
  }

  const debouncedGetPlacePredictions = useCallback(debounce(getPlacePredictions, 500), []);

  useEffect(() => {
    if (!selectedValue && input.length > 2) {
      debouncedGetPlacePredictions(input);
    }
  }, [input]);

  return predictions;
}
