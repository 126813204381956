import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, getTrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps } from 'src/types';
import { ThankYouTitle, ThankYouFields } from 'src/components';
interface NDPSField {
  name: string;
  value: string;
}

const ctasNoCompra = [
  {
    emphasis: 'primary',
    url: 'https://www.volkswagen.es/stock/',
    label: 'Ofertas y stock'
  },
  {
    emphasis: 'primary',
    url: 'https://www.volkswagen.es/es/financiacion-seguros.html',
    label: 'Tipos de financiación'
  },
];

export const ThankYouNoCompra = () => {
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { formResponse } = formInfo;

  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();

  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formInfo.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: "ThankYou" } } });

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm(formResponse)
    );
  }, []);

  const screen: Steps = {
    title: (
      <ThankYouTitle notALayer boldCopyFirst='Gracias' regularCopySecond='por tu respuesta' />
    ),
    fields: (
      <ThankYouFields
        notALayer
        copyBook='Conocer tu opinión es la forma que tenemos de seguir mejorando.'
        copyBookSecond='Si buscas más información, aquí podemos ayudarte:'
        CTAFields
        secondCTA
        firstCTA={ctasNoCompra[0]}
        secondCTACopies={ctasNoCompra[1]}
      />
    ),
  };
  return (
    <RenderController
      screenType="full-screen"
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
      thankYouPage={true}
    />
  );
};
