import {
  Container,
  ContainerGutter,
  CTA,
  styled,
  Text,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useIsComerciales } from 'src/feature-app/hooks';
import { OneFormState } from 'src/types';

const PaddingWrapper = styled.div`
  padding-top: 32px;
`;

interface LegalesProps {
  linkBasesLegales?: string;
}
export const Legales = (props: LegalesProps) => {
  const isComerciales = useIsComerciales();
  return isComerciales ? <LegalesComerciales {...props} /> : <LegalesTurismos {...props} />;
};

const LegalesTurismos = (props: LegalesProps) => {
  const { hideLegal, customLegal } = useSelector((state: OneFormState) => state.formInfo);
  return (
    <PaddingWrapper>
      {customLegal ? customLegal : null}
      {hideLegal ? null : (
        <Container gutter={ContainerGutter.static200} wrap={'always'}>
          <Text bold appearance={TokenTextAppearance.copy200} color={TokenTextColor.secondary}>
            Texto legal de Volkswagen
          </Text>
          <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
              Te informamos de que tus datos personales serán tratados por Volkswagen Group España Distribución S.A.U., en calidad de responsable del tratamiento, con la finalidad de atender tus consultas y solicitudes, realizar un control de calidad sobre los productos y servicios solicitados, realizar encuestas de opinión y estudios de mercado con fines estadísticos; y, en caso de que nos hayas dado tu consentimiento expreso, para las finalidades descritas en los consentimientos adicionales. Informarte que puedes ejercer los derechos que te confiere la normativa de protección de datos mediante solicitud escrita a{' '}
              <CTA 
                tag="a" 
                href="mailto:atencioncliente@volkswagen.es" 
                target="_blank" 
                emphasis="tertiary"
              >
                <b>atencioncliente@volkswagen.es</b>
              </CTA>
              .
          </Text>
          <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
            Puedes consultar la información adicional sobre el tratamiento de tus datos en nuestra{' '}
              <CTA
                tag="a"
                href="https://www.volkswagen.es/es/volkswagen-espana/politica-privacidad.html"
                target="_blank"
                emphasis="tertiary"
              >
                <b>política de privacidad</b>
              </CTA>
              .
          </Text>
          {props.linkBasesLegales ? (
          <Text staticSize appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
            Consulta las{' '}
              <CTA
                tag="a"
                href={props.linkBasesLegales}
                target="_blank"
                emphasis="tertiary"
              >
                <b>Bases Legales</b>
              </CTA>{' '}
            de la promoción.
          </Text>
        ) : null}
        </Container>
      )}
    </PaddingWrapper>
  );
};
const LegalesComerciales = (props: LegalesProps) => {
  const { hideLegal } = useSelector((state: OneFormState) => state.formInfo);
  return (
    <PaddingWrapper>
      {hideLegal ? null : (
        <Container gutter={ContainerGutter.static200} wrap={'always'}>
          <Text bold appearance={TokenTextAppearance.copy200} color={TokenTextColor.secondary}>
            Texto legal de Volkswagen
          </Text>
          <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
            Te informamos de que tus datos personales serán tratados por Volkswagen Group España Distribución S.A.U., en calidad de responsable del tratamiento, con la finalidad de poder cursar tu petición y, en caso de que nos hayas dado tu consentimiento expreso, para las finalidades descritas en los consentimientos adicionales. Informarte que puedes ejercer los derechos que te confiere la normativa de protección de datos mediante solicitud escrita a{' '}
              <CTA 
                tag="a" 
                href="mailto:atencioncliente@volkswagen-comerciales.es" 
                target="_blank" 
                emphasis="tertiary"
              >
                <b>atencioncliente@volkswagen-comerciales.es</b>
              </CTA>
              .
          </Text>
          <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
            Puedes consultar la información adicional sobre el tratamiento de tus datos en nuestra{' '}
              <CTA
                tag="a"
                href="https://www.volkswagen-comerciales.es/es/volkswagen-espana/datos-privacidad.html"
                target="_blank"
                emphasis="tertiary"
              >
                <b>política de privacidad</b>
              </CTA>
              .
          </Text>
          {props.linkBasesLegales ? (
          <Text staticSize appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
            Consulta las{' '}
              <CTA
                tag="a"
                href={props.linkBasesLegales}
                target="_blank"
                emphasis="tertiary"
              >
                <b>Bases Legales</b>
              </CTA>{' '}
            de la promoción.
          </Text>
        ) : null}
        </Container>
      )}
    </PaddingWrapper>
  );
};
