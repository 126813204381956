import { DealersInfo } from 'src/types';

export const getDescInterestCarline = (dealersInfo: DealersInfo, modelo: string) => {
  const { selectedCarline } = dealersInfo;

  if (!modelo && !selectedCarline) return '';
  if (modelo && !selectedCarline) {
    return modelo;
  }

  return `${selectedCarline.nuevo ? 'Nuevo ' : ''}${selectedCarline.title}`;
};
