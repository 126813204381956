import React, { useEffect } from 'react';
import { NuevaCitaPosventaFormController } from 'src/forms';
import { TriggerProps, useFaContent } from 'src/feature-app';
import { CitaPosventaCalculadoraForm, CitaPosventaBonosForm, CitaPosventaMaintenanceForm, CitaPosventaPortalForm } from '../forms/CitaPosventa/CitaPosventaEmbedded';
import { CitaPosventaBenfitsForms } from 'src/forms/CitaPosventa/CitaPosventaEmbedded/CitaPosventaBenefitsForm';

export function CitaPosventaEmbeddedTrigger({ setSelectedJourney }: TriggerProps) {
  const faContent = useFaContent();

  switch (faContent.featureApp) {
    case 'es-calculadora':
      return <CitaPosventaCalculadoraForm setSelectedJourney={setSelectedJourney} />;
    case 'es-maintenance-plan':
      return <CitaPosventaMaintenanceForm setSelectedJourney={setSelectedJourney} />;
	case 'es-portal':
      return <CitaPosventaPortalForm setSelectedJourney={setSelectedJourney} />;
    case 'es-bono':
      return <CitaPosventaBonosForm setSelectedJourney={setSelectedJourney} />;
    case 'es-my-benefits':
      return <CitaPosventaBenfitsForms setSelectedJourney={setSelectedJourney} />;
  }
  return null;
}
