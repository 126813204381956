import {
  Breakpoints,
  styled,
  Layout,
  Container,
  ContainerPadding,
  CTA,
  TextAlignment,
  Text,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { LegalesFormatoLayer, LssiTkmQuestions, LastNavigation, PoliticaPrivacidad } from 'src/components';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';


const GeneralconfirmacionWrapper = styled.div`
  padding: 72px 0 44px;
  @media all and (min-width: 960px) {
    padding: 86px 0 0;
  }
`;

const WhiteLinkWrapper = styled.span`
  a {
    color: #ffffff;
    font-weight: bold;
  }
`;

const InputErrorLegales = styled.p`
  position: relative;
  margin-top: 8px;
  margin-bottom: 0;
  background-color: #e4002c;
  color: #ffffff;
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
`;

export const ConfirmacionNavidad = () => {
  const dispatch = useDispatch();

  const [lssi, setLssi] = useState<string>(null);
  const [tmk, setTmk] = useState<string>(null);
  const [lssiError, setLssiError] = useState<boolean>(false);
  const [tmkError, setTmkError] = useState<boolean>(false);

  useEffect(() => {
    if (lssi) {
      const values = { lssi: lssi };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setLssiError(false);
    }
    if (tmk) {
      const values = { tmk: tmk };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setTmkError(false);
    }
  }, [lssi, tmk]);

  return (
    <GeneralconfirmacionWrapper>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
        }}
        allowOverflowingContent
      >
        <LssiTkmQuestions 
          setTmk={setTmk}
          tmkError={tmkError}
          setLssi={setLssi}
          lssiError={lssiError}
          title={true}
        />
        <Container
          padding={{ bottom: ContainerPadding.static500 }}
          horizontalAlign={'flex-start'}
        >
          <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left} staticSize>
          Enviando este formulario, aceptas las{' '}
            <WhiteLinkWrapper >
              <CTA
                tag="a"
                href='https://cdn.volkswagen.es/repository/app/vw-oneform/assets/other/vw_navidad_2021_bbll.pdf'
                target="_blank"
                emphasis="tertiary"
              >
                Bases Legales
              </CTA>
            </WhiteLinkWrapper>{' '}
            de la promoción y declaras haber leído y comprendido la información sobre el{' '}
            <WhiteLinkWrapper >
              <CTA
                tag="a"
                href="https://www.volkswagen.es/es/volkswagen-espana/datos-privacidad.html"
                target="_blank"
                emphasis="tertiary"
              >
                tratamiento de tus datos personales.
              </CTA>
            </WhiteLinkWrapper>
          </Text>
        </Container>
        <LastNavigation 
          tmk={tmk} 
          lssi={lssi} 
          setTmkError={setTmkError} 
          setLssiError={setLssiError} 
          loadingCopy='Enviando tu respuesta'
        />
        <LegalesFormatoLayer noPadding={false} showTitle={true} linkBasesLegales='https://cdn.volkswagen.es/repository/app/vw-oneform/assets/other/vw_navidad_2021_bbll.pdf'/>
      </Layout>
    </GeneralconfirmacionWrapper>
  );
};
