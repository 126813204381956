import { CTA } from '@volkswagen-onehub/components-core';
import { OfertaForm } from 'src/forms';
import { useDispatch } from 'react-redux';
import { TriggerProps } from 'src/feature-app';
import React from 'react';
import { CarlineWithOffers } from 'src/types';

export const CTAOfertaSuv = (props: TriggerProps & { carlineWithOffers: CarlineWithOffers; }) => {
    const dispatch = useDispatch();
    const { setSelectedJourney, carlineWithOffers } = props;
    return (
        <CTA
            tag='button'
            onClick={() => {
                dispatch({ type: 'SET_OFERTA', payload: carlineWithOffers.ofertas[0] });
                setSelectedJourney(<OfertaForm />);
            }}
            emphasis='primary'
        >Pide esta oferta</CTA>
    );
};
