import React from 'react';

//Navidad 2021

export const CTAsDisfrutarID4 = [
  {
    label: 'Será mi primera experiencia eléctrica',
    value: 'PrimeraExperiencia',
  },
  {
    label: 'Comprobar la duración de la batería',
    value: 'DuraciónBateria',
  },
  {
    label: 'Valorar el consumo y la facilidad de carga',
    value: 'ConsumoCarga',
  },
  {
    label: 'Conducir un coche sostenible',
    value: 'Sostenibilidad',
  },
  {
    label: 'Otros',
    value: 'Otros',
  },
];

//LANZAMIENTO TAIGO

export const CTAsTienesVolkswagen = [
  {
    label: 'Sí, lo tengo',
    value: 'Sí, lo tengo',
  },
  {
    label: 'Sí, lo tuve',
    value: 'Sí, lo tuve',
  },
  {
    label: 'No, nunca he tenido un Volkswagen.',
    value: 'No, nunca he tenido un Volkswagen.',
  },
];

export const CTAsDesplazamientoTaigo = [
  {
    label: 'En mi coche',
    value: 'mi coche',
  },
  {
    label: 'En transporte público',
    value: 'transporte público',
  },
  {
    label: 'Con carsharing',
    value: 'carsharing',
  },
  {
    label: 'Otro',
    value: 'otro',
  },
];

export const CTAsMotivacionCompraTaigo = [
  {
    label: 'Su diseño deportivo e innovador',
    value: 'diseño',
  },
  {
    label: 'El alto nivel de equipamiento',
    value: 'equipamiento',
  },
  {
    label: 'Que además de SUV es coupé',
    value: 'suv/coupe',
  },
  {
    label: '¡Tengo otros motivos!',
    value: 'otro',
  },
];

export const CTAsOtroModeloTaigo = [
  {
    label: 'Sí, tengo otros.',
    value: 'true',
  },
  {
    label: 'No, con ninguno.',
    value: 'false',
  },
];
