import { Text } from '@volkswagen-onehub/components-core';
import Axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { CarlineSelect, PantallaNombreApellido, DetalleMotivo, MultipleCTASelection, MultipleOptionCTAs } from 'src/components';
import { FormController, useOneFormContext } from 'src/feature-app';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { formDataBuilder } from 'src/forms/format-fields';
import { addRemovedStep, getIndexInSteps, removeStep, resetStepsScreenIndex } from 'src/forms/helpers/add-remove-steps';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { useInitializeNoCompraForm, NoCompraFormProps, ConfirmacionNoCompra, ThankYouNoCompra, getCommonFormData, MapaNoCompra } from '.';
import { getDescInterestCarline } from '../Leads/helpers';

export enum NoCompraNoQuieroSteps {
  Decision,
  Motivo,
  MotivoDetalle,
  Modelo,
  Mapa,
  DatosPersonales,
  Confirmacion,
}

export function NoCompraNoQuiero({ dpsData, idLead, idPerson, handleError, trackFormLoad, estado }: NoCompraFormProps) {
  const { initializeForm, handleNextStep } = useOneFormContext();
  const store = useStore();
  const dispatch = useDispatch();
  const { formData } = useSelector((state: OneFormState) => state);
  const [loadingPresubmit, setLoadingPresubmit] = useState(true);

  const { decision, motivo } = formData.fields;

  const CTAsDecision = [
    {
      label: 'He decidido aplazar la compra',
      value: 'true',
    },
    {
      label: 'Finalmente he decidido no comprar ningún coche',
      value: 'false',
    },
  ];

  const CTAsMotivoNoQuiero = [
    {
      label: 'Coche compartido (car sharing o moto sharing)',
      value: '1',
    },
    {
      label: 'Transporte público',
      value: '2',
    },
    {
      label: 'Patinete - Moto - Bicicleta',
      value: '3',
    },
    {
      label: 'Más adelante, en coche',
      value: '4',
    },
    {
      label: 'Otros',
      value: '6',
    },
  ];

  const steps: Steps[] = [
    {
      title: (
        <>
          Entendemos que ya no estás interesado en la compra de un Volkswagen.{' '}
          <Text bold>
            ¿Podríamos conocer más detalles respecto a tu{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              decisión?
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsDecision} name="decision" nextOnClick />}
        />
      ),
      screenIndex: NoCompraNoQuieroSteps.Decision,
      name: 'Decision',
    },
    {
      title: (
        <>
          Nos encantaría saber más.{' '}
          <Text bold>
            ¿Cómo vas a moverte de ahora en{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              adelante?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<MultipleCTASelection ctaValues={CTAsMotivoNoQuiero} name="motivo" nextOnClick />}
        />
      ),
      screenIndex: NoCompraNoQuieroSteps.Motivo,
      name: 'Motivo',
    },
    {
      title: (
        <>
          <Text bold>¿Nos lo podrías concretar </Text>un poco más?
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<DetalleMotivo name="motivoDetalle" CTALabel="No, eso es todo" label="Escribe tu motivo aquí" />}
        />
      ),
      screenIndex: NoCompraNoQuieroSteps.MotivoDetalle,
      name: 'MotivoDetalle',
    },
    {
      title: (
        <>
          <Text bold>¿Qué modelo </Text>en{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            concreto?
            <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ marginBottom: '2px' }}>
              <CarlineSelect useCarline={true} notALayer={true} nextOnSelect />
            </div>
          }
        />
      ),
      screenIndex: NoCompraNoQuieroSteps.Modelo,
      name: 'Modelo',
      showOverflow: true,
    },
    {
      screenIndex: NoCompraNoQuieroSteps.Mapa,
      name: 'Mapa',
      multiStep: true,
      component: <MapaNoCompra formName="venta" showHorario={false} />,
    },
    {
      title: (
        <>
          Por favor, <Text bold>introduce o comprueba tus datos:</Text>
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={
            <div style={{ paddingBottom: '2px' }}>
              <PantallaNombreApellido />
            </div>
          }
        />
      ),
      screenIndex: NoCompraNoQuieroSteps.DatosPersonales,
      name: 'DatosPersonales',
    },
    {
      fields: <ConfirmacionNoCompra idLead={idLead} />,
      screenIndex: NoCompraNoQuieroSteps.Confirmacion,
      name: 'Confirmacion',
    },
  ];

  useEffect(() => {
    if (decision) {
      // Si en la updateSteps realiza la accion de añadir step, es necesario añadirlos por el orden inverso de aparición
      updateSteps('DatosPersonales', decision == 'true' );
      updateSteps('Mapa', decision == 'true' );
      updateSteps('Modelo', decision == 'true' );
      updateSteps('MotivoDetalle', decision == 'false' );
      updateSteps('Motivo', decision == 'false' );
    };
  }, [decision]);

  useEffect(() => {
    if (motivo) {
      updateSteps('MotivoDetalle', motivo == '6' );
    };
  }, [motivo]);


  const updateSteps = (stepName: string, conditional: boolean) => {
		const { steps: actualSteps, screenIndex } = store.getState().formInfo;
		let newSteps = [...actualSteps];
		if (conditional) {
			const stepIndex = getIndexInSteps(newSteps, stepName);

			if (stepIndex === -1) {
				newSteps = addRemovedStep(steps, actualSteps, stepName, screenIndex);
			}
		} else {
			newSteps = removeStep(newSteps, stepName);
		}

		const newStepsWithIndex = resetStepsScreenIndex(newSteps);

		dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
		dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
		dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
	};


  useEffect(() => {
    const sendPresubmit = async () => {
      const result = await sendForm(true);

      if (!result) {
        handleError();
      }
      setLoadingPresubmit(false);
    };

    sendPresubmit();
  }, []);

  const NuevoFormData: Partial<FormDataGroup> = {
    headers: {},
    fields: { 
      name: idLead?.FirstName ? idLead.FirstName : (idPerson?.FirstName ? idPerson.FirstName : ''),
      surname: idLead?.LastName ? idLead.LastName : (idPerson?.LastName ? idPerson.LastName : ''),
      secondSurname: idLead?.MiddleName ? idLead.MiddleName : (idPerson?.TXT_2_Surname__c ? idPerson.TXT_2_Surname__c : ''),
      formName: 'no_compra-no_quiero', 
      formType: 'otherform', 
      pageCategory: 'Encuesta' 
    }
  };

  const generateData = (isPresubmit: boolean, data: Partial<FormDataGroup>) => {
    const { formData, dealersInfo }: OneFormState = store.getState();
    const { fields } = formData;
    const { selectedInstallation } = dealersInfo;

    const personData = fields.decision == 'true' ? {
      'fields[PERSON_NAME]': idLead.FirstName,
      'fields[PERSON_SURNAME_1]': idLead.LastName,
      'fields[PERSON_SURNAME_2]': idLead.MiddleName,
      'fields[PERSON_EMAIL]': idLead.Email,
      'fields[PERSON_PHONE_MOBILE]': idLead.MobilePhone,
    } : null;

    const installationData = selectedInstallation && fields.decision == 'true' ? {
      'fields[SF_LEAD_INSTALLATION_ID]': selectedInstallation.Id,
      'fields[SF_LEAD_INSTALLATION_CODE]': selectedInstallation.VGED_DealerCode__c,
      'fields[SF_LEAD_INSTALLATION_NAME]': selectedInstallation.VGED_CommercialName__c,
      'fields[SF_LEAD_INSTALLATION_PROVINCE_CODE]': selectedInstallation.ShippingStateCode.toString().padStart(2, '0'),
      'fields[SF_LEAD_RECORDTYPE_ID]': '0121i000000D7NyAAK',
      //'fields[SF_LEAD_RECORDTYPE_NAME]': 'Sales Assistant',
      'fields[SF_LEAD_SALES_INITIAL_ACTION]': 'Direct to Sales Assistant',
      'fields[VEH_INTEREST_ID]': fields.carlineCode,
      'fields[VEH_INTEREST_MODEL]': getDescInterestCarline(dealersInfo, fields.modelo),
    } : null;

    const submitData = !isPresubmit ? {
      ...personData,
      ...installationData,
      'fields[SURVEY_ANSWER_47]': fields.motivo,
      'fields[SURVEY_ANSWER_49]': fields.motivoDetalle,
      'flags[FLAG_SF_INTEGRATION]' : fields.decision == 'true',
    } : null;

    return {
      ...getCommonFormData(isPresubmit, idLead, dpsData, fields, data),
      ...submitData,
    };
  };

  const sendForm = async (isPresubmit?: boolean): Promise<boolean> => {
    return new Promise<any>(async (resolve, reject) => {
      const data = generateData(isPresubmit, NuevoFormData);
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);
      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData, isPresubmit);

      if (response?.data?.status?.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { firstStep, lastStep, loading } = useInitializeNoCompraForm(steps);

  useEffect(() => {
    const NuevoFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      formType: 'inPage',
      step: firstStep,
      steps,
      stepsHistory: [0],
      thankYouComponent: <ThankYouNoCompra />,
    };

    if (!loading) {
      initializeForm(NuevoFormInfo, NuevoFormData, true);
      trackFormLoad();
    }
  }, [loading]);

  return loading || loadingPresubmit || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}
