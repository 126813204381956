import { useEffect, useState } from 'react';
import { Points } from 'src/types';

export const usePinState = (selectedPoint: Points, hoverPoint: Points, point: Points) => {
  const [isActive, setIsActive] = useState(false);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (selectedPoint) {
      if (isCluster(point)) {
        sameClusterId(selectedPoint, point) ? setIsActive(true) : setIsActive(false);
      } else {
        sameKvps(selectedPoint, point) ? setIsActive(true) : setIsActive(false);
      }
    }

    if (hoverPoint) {
      if (window.innerWidth < 959) {
        // Hover solo se aplica a desktop
        setIsHover(false);
      } else {
        if (isCluster(point)) {
          sameClusterId(hoverPoint, point) ? setIsHover(true) : setIsHover(false);
        } else {
          sameKvps(hoverPoint, point) ? setIsHover(true) : setIsHover(false);
        }
      }
    }
  }, [selectedPoint, hoverPoint]);

  return { isActive, isHover };
};

const isCluster = (point: Points) => point.properties.cluster;

const sameClusterId = (selectedPoint: Points, point: Points) => {
  if (selectedPoint.properties.parent_cluster_id && point.id) {
    return selectedPoint.properties.parent_cluster_id === point.id;
  }
  return false;
};
const sameKvps = (selectedPoint: Points, point: Points) => {
  if (
    selectedPoint.properties &&
    selectedPoint.properties.dealer &&
    point.properties &&
    point.properties.dealer &&
    selectedPoint.properties.dealer.kvps === point.properties.dealer.kvps
  ) {
    return true;
  }
  return false;
};
