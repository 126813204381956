import { FormInfo } from 'src/types';
import { formInfoInitialState } from './';
import { OneFormActionTypes, RESET_STATE } from './types';
import {
  FormInfoActionTypes,
  SET_FORM_INFO,
  SET_FORM_RESPONSE,
  SET_INITIAL_STEPS,
  SET_FORM_SUBMITTED,
  UPDATE_LITERAL_FIELDS_STEPS,
  SET_SHOW_CALENDAR_ON_LOAD,
  SET_DELETE_CLOSE_AND_PREVIOUS,
  SET_NUMBER_OF_SCREENS,
  SET_SEND_DMS_FAILED,
  SET_STEPS_HISTORY,
  SHOW_FINAL_SCREEN,
  SET_NAVIGATION_MOVING_FORWARD,
  SUGGESTION_IS_DEALER,
  SUGGESTION_IS_IN_CANARY_ISLANDS,
  TRIGGER_EXIT_ANIMATION,
  UPDATE_FIRST_MAP_LOAD,
  UPDATE_FORM_ENDED,
  UPDATE_FULLSCREEN,
  UPDATE_GENERIC_ERROR,
  UPDATE_GEOMETRY,
  DISABLE_TRACKING,
  UPDATE_INITIAL_ZOOM,
  UPDATE_LAST_STEP,
  UPDATE_MULTISTEP_SCREENINDEX,
  UPDATE_MULTISTEPS,
  UPDATE_NEXT_STEP,
  UPDATE_SCREEN,
  UPDATE_SEND_FORM,
  UPDATE_STEP,
  UPDATE_STEPS_HISTORY,
  UPDATE_STEPS,
  UPDATE_SUGGESTION,
  SUGGESTION_IS_ADDRESS,
  HIDE_LEGAL,
  SET_CUSTOM_LEGAL,
  UPDATE_USER_CENTER,
  UPDATE_USER_GEOLOCATION,
  UPDATE_WAIT_FOR_NEXT_STEP,
} from './form-info-types';

export function formInfoReducer(state: FormInfo = null, action: OneFormActionTypes | FormInfoActionTypes): any {
  switch (action.type) {
    case RESET_STATE:
      return { ...formInfoInitialState };
    case SET_FORM_INFO:
      return { ...state, ...action.payload };
    case SET_FORM_RESPONSE:
      return { ...state, formResponse: action.payload };
    case HIDE_LEGAL:
      return { ...state, hideLegal: action.payload };
      
    case SET_CUSTOM_LEGAL:
      return { ...state, customLegal: action.payload };

    case SET_FORM_SUBMITTED:
      return { ...state, formSubmitted: action.payload };
    case UPDATE_SCREEN:
      return {
        ...state,
        screenIndex: action.payload,
      };
    case SET_NAVIGATION_MOVING_FORWARD:
      return {
        ...state,
        navigationMovingForward: action.payload,
      };
    case SET_NUMBER_OF_SCREENS:
      return {
        ...state,
        numberOfScreens: action.payload,
      };
    case SET_STEPS_HISTORY:
      return { ...state, stepsHistory: action.payload };
    case UPDATE_STEPS_HISTORY:
      if (!state.stepsHistory || state.stepsHistory.length === 0) {
        return {
          ...state,
        };
      }
      
      const previousSteps = [...state.stepsHistory];
      const newNumber = action.payload;
      const index = previousSteps.indexOf(newNumber);

      if (index === -1) {
        previousSteps.push(newNumber);
        return {
          ...state,
          stepsHistory: previousSteps,
        };
      }
      return {
        ...state,
      };
    case UPDATE_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case UPDATE_LAST_STEP:
      return {
        ...state,
        lastStep: action.payload,
      };
    case UPDATE_STEPS:
      return {
        ...state,
        steps: action.payload,
      };
    case SET_INITIAL_STEPS:
      return {
        ...state,
        initialSteps: action.payload,
      };
    case UPDATE_LITERAL_FIELDS_STEPS:
      return { 
        ...state, 
        stepsLiteralFields: { ...state.stepsLiteralFields, ...action.payload }
      };
    case SET_SHOW_CALENDAR_ON_LOAD:
      return {
        ...state,
        showCalendarOnLoad: action.payload,
      };
    case SET_DELETE_CLOSE_AND_PREVIOUS:
      return {
        ...state,
        deleteCloseAndPrevious: action.payload,
      };
    case TRIGGER_EXIT_ANIMATION:
      return {
        ...state,
        triggerExitAnimation: action.payload,
      };
    case UPDATE_GENERIC_ERROR:
      return {
        ...state,
        genericError: action.payload,
      };
    case SET_SEND_DMS_FAILED:
      return {
        ...state,
        sendDmsFailed: action.payload,
      };
    case UPDATE_NEXT_STEP:
      return {
        ...state,
        nextStepCallback: action.payload,
      };
    case UPDATE_FIRST_MAP_LOAD:
      return {
        ...state,
        firstMapLoad: action.payload,
      };
    case UPDATE_SEND_FORM:
      return {
        ...state,
        sendFormCallback: action.payload,
      };
    case UPDATE_FULLSCREEN:
      return {
        ...state,
        fullScreen: action.payload,
      };
    case SHOW_FINAL_SCREEN:
      return {
        ...state,
        showFinalScreen: action.payload,
      };
    case UPDATE_MULTISTEPS:
      return {
        ...state,
        multiSteps: action.payload,
      };
    case UPDATE_MULTISTEP_SCREENINDEX:
      return {
        ...state,
        multiStepScreenIndex: action.payload,
      };
    case UPDATE_USER_CENTER:
      return {
        ...state,
        userCenter: action.payload,
      };
    case SUGGESTION_IS_DEALER:
      return {
        ...state,
        suggestionIsDealer: action.payload,
      };
    case SUGGESTION_IS_IN_CANARY_ISLANDS:
      return {
        ...state,
        suggestionIsInCanaryIslands: action.payload,
      };
    case SUGGESTION_IS_ADDRESS:
      return {
        ...state,
        suggestionIsAddress: action.payload,
      };
    case UPDATE_SUGGESTION:
      return {
        ...state,
        userSuggestion: action.payload,
      };
    case UPDATE_USER_GEOLOCATION:
      return {
        ...state,
        userGeoLocation: action.payload,
      };
    case UPDATE_WAIT_FOR_NEXT_STEP:
      return {
        ...state,
        waitForNextStep: action.payload,
      };
    case UPDATE_FORM_ENDED:
      return {
        ...state,
        formEnded: action.payload,
      };
    /** deprecated */
    case UPDATE_INITIAL_ZOOM:
      return {
        ...state,
        initialZoom: action.payload,
      };
    case UPDATE_GEOMETRY:
      return {
        ...state,
        geometry: action.payload,
      };
    case DISABLE_TRACKING:
      return {
        ...state,
        trackingIsDisabled: true
      };

    default:
      return state;
  }
}
