import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OneFormState } from "src/types";


export function useGetInstalacion() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const { installations } = useSelector((state: OneFormState) => state.dealersInfo);
  const urlParams = new URLSearchParams(window.location.search);
  const dealer = urlParams.get('dealer');

  useEffect(() => {
    if (!window.location) {
      setLoading(false);
    }
    if (!dealer) {
      setLoading(false);
      return;
    }
    if (installations) {
      let filteredInstallation = installations.find((installation) => {
        return installation.KVPSCode__c == dealer
      });
      if (filteredInstallation === undefined) {
        filteredInstallation = installations.find((installation) => {
          return installation.VGED_DealerCode__c == dealer
        });
      }
      if (filteredInstallation === undefined) {
        filteredInstallation = installations.find((installation) => {
          return installation.VGED_CommercialName__c == dealer;
        });
      }
      if (filteredInstallation === undefined) {
        setLoading(false);
        setSelectedDealer(null);
      }
      else {
        setSelectedDealer(filteredInstallation);
        dispatch({ type: 'SET_SELECTED_INSTALLATION', payload: filteredInstallation });
        dispatch({ type: 'SET_SELECTED_DEALER', payload: filteredInstallation });
        dispatch({ type: 'SET_SELECTED_', payload: filteredInstallation });
        dispatch({ type: 'SET_URL_DEALER', payload: filteredInstallation });
        dispatch({ type: 'UPDATE_SUGGESTION', payload: filteredInstallation });
        dispatch({ type: 'DEALERS_OR_INSTALLATIONS_MODE', payload: 'installations' });
        setLoading(false);
      }

    }

  }, [installations])

  return { selectedDealer, loading }
}
