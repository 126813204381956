import { styled } from '@volkswagen-onehub/components-core';

export const RatingStarWrapper = styled.div<{ isPrevStep?: boolean; textUnderStars?: boolean; pointer?: boolean }>`
  padding-right: ${(props) => (props.isPrevStep ? '20px' : '32px')};
  padding-right: ${(props) => props.textUnderStars && '0px'};
  padding-left: ${(props) => (props.textUnderStars ? '32px' : '0px')};
  padding-top: ${(props) => (props.textUnderStars ? '32px' : '0px')};

  cursor: ${(props) => (props.pointer || !props.isPrevStep ? 'pointer' : null)};

  svg {
    width: 32px;
    height: 32px;

    @media all and (min-width: 560px) {
      width: ${(props) => (props.isPrevStep ? '20px' : '40px')};
      height: ${(props) => (props.isPrevStep ? '20px' : '40px')};
    }
    @media all and (min-width: 1600px) {
      width: ${(props) => (props.isPrevStep ? '20px' : '48px')};
      height: ${(props) => (props.isPrevStep ? '20px' : '48px')};
    }
  }

  @media all and (max-width: 400px) {
    padding-left: ${(props) => (props.textUnderStars ? '7%' : '2vw')};
    padding-right: ${(props) => (props.textUnderStars ? '0px' : '2vw')};

    svg {
      width: ${(props) => (props.isPrevStep ? '10px' : '30px')};
      height: ${(props) => (props.isPrevStep ? '10px' : '30px')};
    }
  }

  &.filled {
    color: #ffd100;
  }

  &.outline {
    color: #6a767d;
  }
`;
