/**
 * @param kmCalculadora
 */
export const transformMillage = (kmCalculadora: number) => {
  try {
    if (kmCalculadora && !Number.isNaN(kmCalculadora)) {
      return Number(kmCalculadora);
    } else {
      return 0;
    } 
  } catch (error) {
    return 0;
  }
};
