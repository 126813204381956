import React from 'react';
import { ScreenController, TriggerProps, useFaContent } from 'src/feature-app';
import { PlanIdealController } from 'src/forms/FormsAdhoc/2023PlanIdeal/PlanIdealController';

export function PlanIdealTrigger(props: TriggerProps) {


  return (
      <ScreenController
        selectedJourney={<PlanIdealController />}
        closeLayerCallback={close}
        handleAreYouSureLayer={() => null}
        notALayer={true}
        formType = 'inPage'
      />
  );
}
