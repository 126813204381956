import dayjs from 'dayjs';
import {
  Container,
  ContainerGutter,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { isNull } from 'src/feature-app';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OneFormState, Steps } from 'src/types';
import { useOneFormContext } from '../OneForm';
import { CSSTransition } from 'react-transition-group';
import { changeValueForLabel, getUpdatedField, getRatingStarsOutput } from './PreviousStepFiles';

type PreviousStepWrapperProps = {
  isSeleccionDealer?: boolean;
  notALayer?: boolean;
};
const PreviousStepWrapper = styled.div<PreviousStepWrapperProps>`
  opacity: ${(props) => (props.notALayer ? '0.4' : '0.35')};
`;

const TextPreviousAnswerWrapper = styled.div<PreviousStepWrapperProps>`
  width: ${(props) => (props.notALayer ? '100%' : '640px')};
  overflow: hidden;
  max-height: 48px;
  opacity: ${(props) => (props.notALayer ? '1' : '0')};
  &.no-animation {
    opacity: 1;
  }
  &.text-enter,
  &.text-enter-active {
    opacity: 0;
  }
  &.text-enter-done {
    opacity: 1;
    transition: opacity 0.3s;
  }
  &.text-exit,
  &.text-exit-active,
  &.text-exit-done {
    opacity: 1;
  }
`;

// Implementar en caso de que sea necesario mostrar el último paso segun lo introducido y no según el screenIndex, por ejemplo al volver desde la pantalla de confirmación a otra.
interface OutputsHistory {
  screenIndex: number;
  step: Steps;
  outputs: any[];
}

export function PreviousStepComponent(props: any) {
  const { formData, formInfo, dealersInfo } = useSelector((state: OneFormState) => state);
  const { steps, stepsHistory, screenIndex, multiStepScreenIndex, multiSteps, triggerExitAnimation, step } = formInfo;

  const [title, setTitle] = useState<JSX.Element>(null);
  const [text, setText] = useState<string | JSX.Element>(null);
  const [hideText, setHideText] = useState<boolean>(false);
  const [textAppear, setTextAppear] = useState<boolean>(false);
  const [outputs, setOutputs] = useState<any[]>(null);
  const [outputsHistory, setOutputsHistory] = useState<OutputsHistory[]>(null);
  const { moveForward, showPreviousStep } = useOneFormContext();

  /**
   *
   * @param stepsToIterate La lista de steps donde hay que buscar el paso previo.
   * @param screenIndexToGet La lista de steps donde hay que buscar el paso previo.
   */
  const getTitleAndOutputs = (stepsToIterate: Steps[], screenIndexToGet: number) => {
    if (!stepsToIterate || isNull(screenIndexToGet) || screenIndexToGet === -1) {
      setTitle(null);
      setText(null);
      return null;
    }
    const previousStep = stepsToIterate.find((step) => step.screenIndex === screenIndexToGet);
    
    if(!previousStep) {
      return null;
    }
    
    const title = previousStep.alternateTitle ? previousStep.alternateTitle : previousStep.title;
    setTitle(title);
    
    const camelCaseName = previousStep.name.charAt(0).toLowerCase() + previousStep.name.slice(1); // convertir a camelCase para intentar obtener el fieldName desde formName: "PrecioMantenimiento" -> "precioMantenimiento"

    // uso de dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS',...
    if (formInfo.stepsLiteralFields?.[previousStep.name]) {
      const text = formInfo.stepsLiteralFields?.[previousStep.name];
      setText(text);
      return null;
    }
    else if (formInfo.stepsLiteralFields?.[camelCaseName]) {
      const text = formInfo.stepsLiteralFields?.[camelCaseName];
      setText(text);
      return null;
    }

    const theValue = formData.fields[camelCaseName];

    // RATING STARS
    if (previousStep.outputsIsStars) {
		setText(getRatingStarsOutput(theValue));
	}
    // OUTPUTS AND OUTPUTSTEXT
    else if (previousStep.outputsText) {
      const updatedOutputsTexts = previousStep.outputsText
        .map((text) => {
          previousStep.outputs.map((output) => {
            if (text === output) {
              text = getUpdatedField(output, formData, dealersInfo, formInfo, previousStep);
            }
          });
          return text;
        })
        .join(' ');

      setText(updatedOutputsTexts);
    }
    else if(typeof theValue != 'undefined') {
      // CTAS
      if (previousStep.ctas) {
        setText(changeValueForLabel(previousStep.ctas, formData.fields, theValue));
      }
      else if(theValue == 'false' || theValue === false) {
        setText('No');
      }
      else if(theValue == 'true' || theValue === true) {
        setText('Sí');
      }
      // TIMESTAMPS
      else if (dayjs(theValue, 'YYYY-MM-DD HH:mm:ss').isValid()) {
        setText(dayjs(theValue, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'));
      }
      else if (dayjs(theValue, 'YYYY-MM-DD').isValid()) {
        setText(dayjs(theValue, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      }
      else if(typeof theValue == 'string'){
        setText(theValue.replace(/\,+/g, ', ')); // prevenir texto junto la coma
      }
    } 
  };

  const handleMultiStep = () => {
    // En la primera pantalla del multistep mostramos el último step antes de entrar en el multistep.
    if (multiStepScreenIndex === 0) {
      getTitleAndOutputs(steps, screenIndex - 1);
    } else {
      getTitleAndOutputs(multiSteps, multiStepScreenIndex - 1);
    }
  };

  const handleStep = () => {
    // En la primera pantalla después de un multistep mostramos el último multistep antes de salir del mismo.
    let previousStep;
    const indexInHistory = stepsHistory.findIndex((history) => history === screenIndex);

    if (indexInHistory === -1) {
      previousStep = steps.find((step) => step.screenIndex === screenIndex - 1);
    } else {
      previousStep = steps.find((step) => step.screenIndex === stepsHistory[indexInHistory - 1]);
    }
    if (previousStep && previousStep.multiStep) {
      getTitleAndOutputs(multiSteps, multiStepScreenIndex);
    } else {
      getTitleAndOutputs(steps, previousStep?.screenIndex);
    }
  };

  useEffect(() => {
    if (isNull(screenIndex) || (screenIndex === 0 && !steps?.[screenIndex]?.multiStep) || !steps?.[screenIndex]) {
      return;
    }
    const step = steps.find((step) => step.screenIndex === screenIndex);
    if (step) {
      step.multiStep ? handleMultiStep() : handleStep();
    } else {
      handleStep();
    }
  }, [screenIndex, multiStepScreenIndex]);

  useEffect(() => {
    if (props.stopAnimation) {
      setHideText(false);
    } else {
      if (!moveForward) {
        setHideText(true);
      } else {
        setHideText(false);
      }
    }
  }, [moveForward]);

  useEffect(() => {
    if (text) setTextAppear(true);
  }, [text]);

  useEffect(() => {
    setHideText(false);
  }, []);

  return showPreviousStep ? (
    <PreviousStepWrapper
      isSeleccionDealer={screenIndex === 2 && multiStepScreenIndex === 1 ? true : false}
      className="prev-step-wrapper"
      notALayer={props.notALayer}
    >
      <Container gutter={ContainerGutter.static200} wrap={'always'}>
        {props.notALayer ? (
          <Text appearance={TokenTextAppearance.headline200} textAlign={TextAlignment.left}>
            {title ? title : null}
          </Text>
        ) : (
          <Text appearance={TokenTextAppearance.headline200} textAlign={TextAlignment.left}>
            {title ? title : null}
          </Text>
        )}
        {props.notALayer ? (
          <TextPreviousAnswerWrapper
            className={props.stopAnimation ? 'no-animation' : 'prev-answer-text-wrapper'}
            notALayer={props.notALayer}
          >
            <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.left}>
              {text && !hideText ? text : null}
            </Text>
          </TextPreviousAnswerWrapper>
        ) : (
          <CSSTransition in={textAppear} timeout={500} classNames="text">
            <TextPreviousAnswerWrapper
              className={props.stopAnimation ? 'no-animation' : 'prev-answer-text-wrapper'}
              notALayer={props.notALayer}
            >
              <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.left}>
                {text && !hideText && !triggerExitAnimation ? text : null}
              </Text>
            </TextPreviousAnswerWrapper>
          </CSSTransition>
        )}
      </Container>
    </PreviousStepWrapper>
  ) : null;
}

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Falta por definir un campo en PreviousStep');
    // También puedes registrar el error en un servicio de reporte de errores
  }

  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier interfaz de repuesto
      return null;
    }

    return this.props.children;
  }
}

export function PreviousStep(props: any) {
  return (
    <ErrorBoundary>
      <PreviousStepComponent {...props} />
    </ErrorBoundary>
  );
}
