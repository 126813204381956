import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { FormController, useOneFormContext, isHighRating, isLowRating, isNoRating } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { formatHeaders, formDataBuilder } from 'src/forms/format-fields';
import { addRemovedStep, getArrayOfStepsNames, getIndexInSteps, removeMultipleStepsAndResetScreenIndex, resetStepsScreenIndex, } from 'src/forms/helpers/add-remove-steps';
import { DpsData } from 'src/feature-service/es-oneform-core-feature-service/types';
import { IdOrder, IdPerson, IdVehicle, IdDealer } from 'src/types';
import { sanitizeKvps, checkUndefinedsInData, formatSinRespuestaValue, getCemDate, getSfAddressRest, stepNameToFieldName, valueWrongConcesion, valueWrongMarca, valueWrongModelo, valueWrongPersona } from 'src/forms/Cem/helpers';
import { formatToTwoChars, strTitlelize } from 'src/helpers';
import { LastQuestionFlow } from 'src/forms/Cem';
import { getStepsVentaProducto, ThankYouCEMVentaProducto } from 'src/forms/Cem/CEMVentaProducto/';


export interface CEMVentaProductoFormProps {
	idOrder?: IdOrder;
	idPerson: IdPerson;
	idVehicle: IdVehicle;
	idDealer?: IdDealer;
	dpsData: DpsData;
	installation?: IdDealer;
}

export function CEMVentaProductoForm(props: CEMVentaProductoFormProps) {
	const { idOrder, idPerson, idVehicle, idDealer, dpsData, installation } = props;
	const { initializeForm, handleNextStep, handleShowFinalScreen, setShowPreviousStep, handleScreenChange } =
		useOneFormContext();
	const { formData } = useSelector((state: OneFormState) => state);
	const store = useStore();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const urlParams = new URLSearchParams(window.location.search);
	const adviser = urlParams.get('adviser');
	const agency = urlParams.get('agency');
	const dev = urlParams.get('dev') !== null;

	const {
		datosCorrectos,
		datosIncorrectos,
		confirmacionConcesion,
		satisfaccionDiseno,
		satisfaccionUso,
		satisfaccionTecnologia,
		satisfaccionCalidad,
		satisfaccionConduccion,
		satisfaccionGeneral,
		satisfaccionElectrico,
		modelo,
		enviarConcesion,
		masPreguntas,
	} = formData.fields;

	const isElectrico = idVehicle.VGED_ModelGroup__c.substring(0, 2) == 'ID';

	const modeloRef = useRef(null); // Necesitamos almacenar el modelo ya que cada vez que el usuario selecciona un modelo se modifican los steps, por lo que necesitamos una referencia para saber que modelo hay que sustituir.

	const NuevoFormData: Partial<FormDataGroup> = {
		headers: {
			FORM_DETAILS: 'CEM Venta Producto',
			FORM_OBJECTIVE: 'Encuesta',
			DPS_TOKEN: 'VW_DDB_FRM_CEM_PRODUCTO_2024',
			FORM_ID: 'Email_B04_C01_CEM_Product',
		},
		fields: {
			formName: 'cem-venta-producto',
			formType: 'otherform',
			pageCategory: 'Encuesta',
			name: idPerson.FirstName,
			surname: idPerson.LastName,
			secondSurname: idPerson.MiddleName,
			installation: installation ? installation.VGED_CommercialName__c : idDealer?.VGED_CommercialName__c ? idDealer.VGED_CommercialName__c : '',
		}, // Analítica
	};

	const sendSurvey = () => {
		const { formData }: OneFormState = store.getState();
		const { fields } = formData;

		return !( // negation
			(fields.datosCorrectos == 'false' && fields.datosIncorrectos == valueWrongMarca) ||
			(fields.datosCorrectos == 'false' && fields.datosIncorrectos == valueWrongPersona) ||
			(fields.datosCorrectos == 'false' && fields.datosIncorrectos == valueWrongConcesion && fields.confirmacionConcesion == 'false')
		);
	};

	const getData = () => {

		const { formData }: OneFormState = store.getState();
		const { fields, headers } = formData;
		const { tc, today, url, device } = getParamsForm();

		const data = {
			...formatHeaders(headers),
			'headers[FORM_NAME]': `vw-oneform-${fields.formName}`,
			'headers[FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
			'headers[FORM_URL]': url,
			'headers[FORM_DATE_INSERTION]': today,
			'headers[ORIGIN_DEVICE_TYPE]': device,
			'headers[ORIGIN_TRACKING_CODE]': tc,
			'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,
			'headers[FK_SYSTEM]': idOrder.VGED_ClimaproOrderID__c,

			'fields[DPS_BRAND]': 'Volkswagen',
			'fields[SF_LEAD_INSTALLATION_ID]': installation.Id,
			'fields[SF_LEAD_INSTALLATION_NAME]': installation.VGED_CommercialName__c,
			'fields[SF_LEAD_INSTALLATION_CODE]': installation.VGED_DealerCode__c,
			'fields[SF_LEAD_INSTALLATION_CODE_KVPS]': sanitizeKvps(installation.KVPSCode__c),
			'fields[VEH_ACTUAL_FRAME]': idVehicle.Name,
			'fields[VEH_ACTUAL_PLATE]': idVehicle.VGED_RegistrationPlate__c,
			'fields[VEH_ACTUAL_MODEL]': idVehicle.VGED_ModelGroup__c ? idVehicle.VGED_ModelGroup__c : dpsData.model ? dpsData.model : null,
			'fields[PERSON_NAME]': fields.name,
			'fields[PERSON_SURNAME_1]': fields.surname,
			'fields[PERSON_SURNAME_2]': fields.secondSurname,
			'fields[PERSON_PHONE_MOBILE]': idPerson.PersonMobilePhone,
			'fields[PERSON_EMAIL]': idPerson.PersonEmail,
			'fields[PERSON_POSTAL_ROAD_NAME]': getSfAddressRest(idPerson?.PersonMailingAddress, 'street'),
			'fields[PERSON_POSTAL_CP]': getSfAddressRest(idPerson.PersonMailingAddress, 'postalCode'),
			'fields[PERSON_POSTAL_LOCATION]': getSfAddressRest(idPerson?.PersonMailingAddress, 'city'),
			'fields[PERSON_POSTAL_PROVINCE_CODE]': formatToTwoChars(getSfAddressRest(idPerson.PersonMailingAddress, 'stateCode')),
			'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
			'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
			
			'fields[SURVEY_ANSWER_01]': fields.datosCorrectos == 'true' ? '1' : '2',
			'fields[SURVEY_ANSWER_02]': fields.datosCorrectos == 'false' ? fields.datosIncorrectos : null,
			'fields[SURVEY_ANSWER_03]': fields.datosCorrectos == 'false' && fields.datosIncorrectos == 2 ? fields.modelo : null,
			'fields[SURVEY_ANSWER_04]': fields.datosCorrectos == 'false' && fields.datosIncorrectos == 3 ? fields.confirmacionConcesion == 'true' ? '1' : '2' : null,
			//conditional send
			'fields[SURVEY_ANSWER_32]': adviser,
			'fields[SURVEY_ANSWER_34]': fields.enviarConcesion,

			'flags[PERSON_RGPD_ADAPTED]': 'true',
			'flags[FLAG_RELACION_MARCA_SIN_IC]': 'false',
		};

		if(sendSurvey()) {
			data['fields[SURVEY_ANSWER_05]'] = formatSinRespuestaValue(fields.satisfaccionDiseno);
			data['fields[SURVEY_ANSWER_06]'] = formatSinRespuestaValue(fields.satisfaccionDisenoMotivo);
			data['fields[SURVEY_ANSWER_07]'] = formatSinRespuestaValue(fields.satisfaccionUso);
			data['fields[SURVEY_ANSWER_08]'] = formatSinRespuestaValue(fields.satisfaccionUsoMotivo);
			data['fields[SURVEY_ANSWER_09]'] = formatSinRespuestaValue(fields.satisfaccionTecnologia);
			data['fields[SURVEY_ANSWER_10]'] = formatSinRespuestaValue(fields.satisfaccionTecnologiaMotivo);
			data['fields[SURVEY_ANSWER_11]'] = formatSinRespuestaValue(fields.satisfaccionCalidad);
			data['fields[SURVEY_ANSWER_12]'] = formatSinRespuestaValue(fields.satisfaccionCalidadMotivo);
			data['fields[SURVEY_ANSWER_13]'] = formatSinRespuestaValue(fields.satisfaccionConduccion);
			data['fields[SURVEY_ANSWER_14]'] = formatSinRespuestaValue(fields.satisfaccionConduccionMotivo);
			data['fields[SURVEY_ANSWER_15]'] = formatSinRespuestaValue(fields.satisfaccionElectrico);
			data['fields[SURVEY_ANSWER_16]'] = formatSinRespuestaValue(fields.satisfaccionElectricoMotivo);
			data['fields[SURVEY_ANSWER_17]'] = formatSinRespuestaValue(fields.satisfaccionGeneral);
			data['fields[SURVEY_ANSWER_18]'] = formatSinRespuestaValue(fields.satisfaccionGeneralMotivo);
			data['fields[SURVEY_ANSWER_19]'] = formatSinRespuestaValue(fields.satisfaccionExperiencia);
			data['fields[SURVEY_ANSWER_20]'] = formatSinRespuestaValue(fields.satisfaccionEntrega);
		}

		if (agency) {
			data['flags[FLAG_AGENCY]'] = agency == 'AGENCIA' ? 'true' : 'false';
		}

		if (idOrder.VGED_CEMDeliveryDate__c && idOrder.VGED_CEMDeliveryDate__c != 'null') {
			data['fields[CEM_DATE]'] = getCemDate(idOrder.VGED_CEMDeliveryDate__c);
		}

		return data;
	};

	useEffect(() => {
		if(dev) {
			console.log(getData());
		}
	}, [formData]);

	const sendForm = (): Promise<boolean> => {
		
		return new Promise<any>(async (resolve, reject) => {

			const dataStringified = JSON.stringify(getData());
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			checkUndefinedsInData(getData(), 'Venta Producto');
			const response = await oneformBFFNdpsSend(formData);

			if (response && response.data?.status?.code == 200) {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				resolve(true);
			} else {
				resolve(false);
			}
		});
	};

	/**
	 * Se extrae la lógica para poder utilizarla por separado sin tener que usar las funciones de loading o updateSteps
	 * ya que hay una pregunta en la que podemos eliminar y añadir un step en el mismo paso.
	 * @param stepsToAdd
	 * @param alternativeSteps
	 * @returns
	 */
	const addingStepsLogic = (stepsToAdd: string[], alternativeSteps?: Steps[]) => {
		const { steps: actualSteps, screenIndex } = store.getState().formInfo;
		const stepsToUse = alternativeSteps ? alternativeSteps : actualSteps;
		// Hay que revertir los steps para que se añadan en el orden correcto
		const indexesInSteps = stepsToAdd.reverse().map((step) => getIndexInSteps(stepsToUse, step));
		let newSteps = [...stepsToUse];

		indexesInSteps.forEach((index, i) => {
			if (index === -1) {
				newSteps = addRemovedStep(getStepsVentaProducto(isElectrico), stepsToUse, stepsToAdd[i], screenIndex);
			}
		});
		const finalSteps = resetStepsScreenIndex(newSteps);
		return finalSteps;
	};

	const addSteps = (stepsToAdd: string[]) => {
		setLoading(true);

		const finalSteps = addingStepsLogic(stepsToAdd);
		updateSteps(finalSteps);
		handleNextStep();

		setLoading(false);
	};

	const removeSteps = (stepsToDelete: string[]) => {
		const { steps } = store.getState().formInfo;
		setLoading(true);
		const finalSteps = removeMultipleStepsAndResetScreenIndex(steps, stepsToDelete);
		updateSteps(finalSteps);
		setLoading(false);
	};

	const updateSteps = (steps: Steps[]) => {
		dispatch({ type: 'UPDATE_STEPS', payload: steps });
		dispatch({ type: 'UPDATE_LAST_STEP', payload: steps[steps.length - 1] });
		dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: steps.length });
	};

	const replaceWithLeadInfo = (string: string) => {
		return string
			.replace('[Modelo]', idVehicle.VGED_ModelGroup__c ? idVehicle.VGED_ModelGroup__c : dpsData.model ? dpsData.model : 'Volkswagen')
			.replace('[Marca]', idVehicle.RCY_BrandList__c)
			.replace('[Concesionario]', strTitlelize(installation ? installation.VGED_CommercialName__c : idDealer?.VGED_CommercialName__c ? idDealer.VGED_CommercialName__c : ''));
	};

	const replaceModelo = (string: string) => {
		if (modeloRef.current) {
			return string.replace(modeloRef.current, modelo);
		} else {
			return string.replace(idVehicle.VGED_ModelGroup__c, modelo);
		}
	};

	const modifyTitleSteps = (steps: Steps[], replaceCallback: (s: string) => string) => {
		return steps.map((step) => {
			if (!step.title) return step;
			if (Array.isArray(step.title.props.children)) {
				step.title.props.children = step.title.props.children.map((child: any) => {
					if (typeof child === 'object') {
						if (typeof child.props.children === 'object') {
							child.props.children.props.children = replaceCallback(child.props.children.props.children);
						} else {
							child.props.children = replaceCallback(child.props.children);
						}
					} else {
						child = replaceCallback(child);
					}
					return child;
				});
			} else if (typeof step.title.props.children === 'object') {
				step.title.props.children.props.children = replaceCallback(step.title.props.children.props.children);
			} else {
				step.title.props.children = replaceCallback(step.title.props.children);
			}
			return step;
		});
	};

	const getOrderStep = (order: number, Q1: number, Q2: number, Q3: number, Q4: number, Q5: number) => {
		if (order === 1) {
			return Q1;
		}
		if (order === 2) {
			return Q2;
		}
		if (order === 3) {
			return Q3;
		}
		if (order === 4) {
			return Q4;
		}
		if (order === 5) {
			return Q5;
		}
		return null;
	};

	const shuffle = (array: number[]): number[] => array.sort(() => Math.random() - 0.5);

	/**
	 * El orden de los primeros rating tiene que ser aleatorio.
	 * @param steps
	 */
	const randomizeSteps = (steps: Steps[]): Steps[] => {
		const Q1 = getIndexInSteps(steps, 'SatisfaccionDiseno');
		const C1 = getIndexInSteps(steps, 'SatisfaccionDisenoMotivo');

		const Q2 = getIndexInSteps(steps, 'SatisfaccionUso');
		const C2 = getIndexInSteps(steps, 'SatisfaccionUsoMotivo');

		const Q3 = getIndexInSteps(steps, 'SatisfaccionTecnologia');
		const C3 = getIndexInSteps(steps, 'SatisfaccionTecnologiaMotivo');

		const Q4 = getIndexInSteps(steps, 'SatisfaccionCalidad');
		const C4 = getIndexInSteps(steps, 'SatisfaccionCalidadMotivo');

		const Q5 = getIndexInSteps(steps, 'SatisfaccionConduccion');
		const C5 = getIndexInSteps(steps, 'SatisfaccionConduccionMotivo');

		let newSteps = [...steps];
		const [random1, random2, random3, random4, random5] = shuffle([1, 2, 3, 4, 5]);
		const randomStep1 = getOrderStep(random1, Q1, Q2, Q3, Q4, Q5);
		const randomStep2 = getOrderStep(random2, Q1, Q2, Q3, Q4, Q5);
		const randomStep3 = getOrderStep(random3, Q1, Q2, Q3, Q4, Q5);
		const randomStep4 = getOrderStep(random4, Q1, Q2, Q3, Q4, Q5);
		const randomStep5 = getOrderStep(random5, Q1, Q2, Q3, Q4, Q5);
		newSteps[Q1] = steps[randomStep1];
		newSteps[C1] = steps[randomStep1 + 1];
		newSteps[Q2] = steps[randomStep2];
		newSteps[C2] = steps[randomStep2 + 1];
		newSteps[Q3] = steps[randomStep3];
		newSteps[C3] = steps[randomStep3 + 1];
		newSteps[Q4] = steps[randomStep4];
		newSteps[C4] = steps[randomStep4 + 1];
		newSteps[Q5] = steps[randomStep5];
		newSteps[C5] = steps[randomStep5 + 1];

		return newSteps;
	};

	const getFormInfo = (steps: Steps[]) => {
		const newSteps = modifyTitleSteps(steps, replaceWithLeadInfo);
		const randomizedSteps = randomizeSteps(newSteps);

		return {
			firstStep: randomizedSteps[0],
			lastStep: randomizedSteps[randomizedSteps.length - 1],
			stepsHistory: [0],
			numberOfScreens: randomizedSteps.length,
			newSteps: randomizedSteps,
		};
	};

	// Lógica para modificar el flujo del formulario
	useEffect(() => {
		if (modelo) {
			const pastSteps = store.getState().formInfo.steps;
			const newSteps = modifyTitleSteps(pastSteps, replaceModelo);
			modeloRef.current = modelo;
			updateSteps(newSteps);
		}
	}, [modelo]);

	useEffect(() => {
		if (datosCorrectos === 'true') {
			const steps = store.getState().formInfo.steps;
			const modelo = getIndexInSteps(steps, 'Modelo');
			// En el caso de elegir que los datos son incorrectos, llegar a confirmación y volver atrás hay que regenerar todos los pasos.
			if (modelo === -1) {
				const initialSteps = store.getState().formInfo.initialSteps;
				const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, ['DatosIncorrectos', 'Modelo', 'ConfirmacionConcesion']);
				updateSteps(finalSteps);
			} else {
				removeSteps(['DatosIncorrectos', 'Modelo', 'ConfirmacionConcesion']);
			}
		} else if (datosCorrectos === 'false') {
			addSteps(['DatosIncorrectos', 'Modelo']);
		}
	}, [datosCorrectos]);

	useEffect(() => {
		if (datosIncorrectos == valueWrongMarca || datosIncorrectos == valueWrongPersona) { 
			const pastSteps = store.getState().formInfo.steps;
			const newsteps = [pastSteps[0], pastSteps[1], pastSteps[pastSteps.length - 1]];
			const finalSteps = resetStepsScreenIndex(newsteps);
			updateSteps(finalSteps);
		}
		if (datosIncorrectos == valueWrongConcesion) { 
			const initialSteps = store.getState().formInfo.initialSteps;
			const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, ['Modelo']);
			updateSteps(finalSteps);
		}
		if (datosIncorrectos === valueWrongModelo) { 
			const initialSteps = store.getState().formInfo.initialSteps;
			const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, ['ConfirmacionConcesion']);
			updateSteps(finalSteps);
		}
	}, [datosIncorrectos]);

	useEffect(() => {
		if (confirmacionConcesion === 'false') {
			const pastSteps = store.getState().formInfo.steps;
			const newsteps = [pastSteps[0], pastSteps[1], pastSteps[2], pastSteps[pastSteps.length - 1]];
			const finalSteps = resetStepsScreenIndex(newsteps);
			updateSteps(finalSteps);
		} else if (confirmacionConcesion === 'true') {
			const initialSteps = store.getState().formInfo.initialSteps;
			const finalSteps = removeMultipleStepsAndResetScreenIndex(initialSteps, ['Modelo']);
			updateSteps(finalSteps);
		}
	}, [confirmacionConcesion]);

	useEffect(() => {
		if (!enviarConcesion) {
			return;
		}
		if (enviarConcesion === '1') {
			addSteps(['DatosPersonales']);
		} else {
			removeSteps(['DatosPersonales']);
		}
	}, [enviarConcesion]);

	useEffect(() => {
		if (!masPreguntas) {
			return;
		}

		if (masPreguntas === 'true') {
			const stepNames = getArrayOfStepsNames(getStepsVentaProducto(isElectrico), 'MasPreguntas', 'EnviarConcesion');
			addSteps(stepNames);
		} else if (masPreguntas === 'false') {
			const { steps } = store.getState().formInfo;
			const stepNames = getArrayOfStepsNames(steps, 'MasPreguntas', 'EnviarConcesion');
			removeSteps(stepNames);
		}
	}, [masPreguntas]);

	/**
	 * Se tiene que de manera consecutiva y no mediante las otras funciones porque si no, se actualizan los steps
	 * dos veces y en el segundo paso (añadir o eliminar el otro step) se hace siempre sobre los steps antiguos y
	 * no funciona de la manera esperada.
	 *
	 * @param stepToRemove
	 * @param stepToAdd
	 */
	const removeAndAddStepAtTheSameTime = (stepToRemove: string, stepToAdd: string) => {
		const { steps } = store.getState().formInfo;
		setLoading(true);
		const removedSteps = removeMultipleStepsAndResetScreenIndex(steps, [stepToRemove]);
		const addedSteps = addingStepsLogic([stepToAdd], removedSteps);
		const finalSteps = resetStepsScreenIndex(addedSteps);
		handleNextStep();
		updateSteps(finalSteps);
		setLoading(false);
	};

	const handleShowMotivo = (nameOfMotivoStep: string, value?: string,) => {
		if (value) {
			if (Number(value) == -1) value = 'Sin respuesta';
			if (isLowRating(value)) {
				addSteps([nameOfMotivoStep]);
			} else if (isHighRating(value) || isNoRating(value)) {
				removeSteps([nameOfMotivoStep]);
				delete formData.fields[stepNameToFieldName(nameOfMotivoStep)];
			}
		}
	};

	useEffect(() => {
		handleShowMotivo('SatisfaccionDisenoMotivo', satisfaccionDiseno);
	}, [satisfaccionDiseno]);

	useEffect(() => {
		handleShowMotivo('SatisfaccionUsoMotivo', satisfaccionUso);
	}, [satisfaccionUso]);

	useEffect(() => {
		handleShowMotivo('SatisfaccionTecnologiaMotivo', satisfaccionTecnologia);
	}, [satisfaccionTecnologia]);

	useEffect(() => {
		handleShowMotivo('SatisfaccionCalidadMotivo', satisfaccionCalidad);
	}, [satisfaccionCalidad]);

	useEffect(() => {
		handleShowMotivo('SatisfaccionConduccionMotivo', satisfaccionConduccion);
	}, [satisfaccionConduccion]);

	useEffect(() => {
		handleShowMotivo('SatisfaccionElectricoMotivo', satisfaccionElectrico);
	}, [satisfaccionElectrico]);

	useEffect(() => {
		const stepName = store.getState().formInfo?.step?.name;

		// Verificamos que se ha contestado SatisfaccionGeneral
		if(stepName == 'SatisfaccionGeneral') {

			const ratings = [satisfaccionDiseno, satisfaccionUso, satisfaccionTecnologia, satisfaccionCalidad, satisfaccionConduccion];
			if (isElectrico) ratings.push(satisfaccionElectrico);

			const { mostrarQlast1, mostrarQlast2 } = LastQuestionFlow(ratings, satisfaccionGeneral);

			if (mostrarQlast1 && mostrarQlast2) {
				addSteps(['SatisfaccionGeneralMotivo', 'SatisfaccionExperiencia']);
			}
			if (!mostrarQlast1 && !mostrarQlast2) {
				removeSteps(['SatisfaccionGeneralMotivo', 'SatisfaccionExperiencia']);
				delete formData.fields.satisfaccionGeneralMotivo;
				delete formData.fields.satisfaccionExperiencia;
			}
			if (!mostrarQlast1 && mostrarQlast2) {
				removeAndAddStepAtTheSameTime('SatisfaccionGeneralMotivo', 'SatisfaccionExperiencia');
				delete formData.fields.satisfaccionGeneralMotivo;
			}
			if (mostrarQlast1 && !mostrarQlast2) {
				removeAndAddStepAtTheSameTime('SatisfaccionExperiencia', 'SatisfaccionGeneralMotivo');
				delete formData.fields.SatisfaccionExperiencia;
			}
		}

	}, [satisfaccionDiseno, satisfaccionUso, satisfaccionTecnologia, satisfaccionCalidad, satisfaccionConduccion, satisfaccionElectrico, satisfaccionGeneral]);


	useEffect(() => {
		if (!installation) return null;

		const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo(getStepsVentaProducto(isElectrico));

		const NuevoFormInfo: Partial<FormInfo> = {
			formStarted: true,
			sendFormCallback: sendForm,
			fullScreen: firstStep.fullScreen ? true : false,
			lastStep,
			numberOfScreens,
			formTheme: 'main',
			notALayer: true,
			formType: 'inPage',
			screenIndex: firstStep.screenIndex,
			step: firstStep,
			steps: newSteps ? newSteps : getStepsVentaProducto(isElectrico),
			stepsHistory,
			thankYouComponent: <ThankYouCEMVentaProducto idPerson={idPerson} />,
			hideLegal: idPerson.LSSI__c === 'PERMITE' ? true : false,
		};

		initializeForm(NuevoFormInfo, NuevoFormData);

	}, [installation]);

	return !loading ? <FormController steps={getStepsVentaProducto(isElectrico)} screenType="full-screen" /> : null;
}
