import React, { useState, useEffect } from 'react';
import {
  Text,
  styled,
  Container,
  ContainerPadding,
  TextAlignment,
  ThemeProvider,
  Breakpoints,
  BreakpointWrapper,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { BackgroundImage, backgroundUrl } from './AcordionHorizontal';
import { keyframes, css } from 'styled-components';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import { CTAOfertaSuv } from './CTAOfertaSuv';
import { TriggerProps } from 'src/feature-app';
import { CarlineWithOffers } from 'src/types';
import { formatNumber } from './MobileCard';

interface LiProps {
  idLi: number;
  shadowSide?: string;
  isVertical?: boolean;
}
const LiWrapper = styled.li<LiProps>`
  flex: 1;
  display: flex;
  position: relative;
  align-items: stretch;
  box-shadow: ${(props) => props.shadowSide === 'interior' ? 'inset -10px 0px 9px -5px rgba(0,0,0,0.1)' : '10px 0px 20px 0px rgba(0, 0, 0, 0.4)'};
  padding: 0 5px;
  overflow: hidden;
  flex-direction: column;
  @media all and (min-width: 1600px){
    padding: 0 20px;
  }
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-in;
  &.active {
    flex: 4;
    cursor: default;
    overflow: hidden;
    padding: 0;
    .section-content {
      opacity: 1;
      min-width: var(--size-grid012);
    }
  }
  .section-title {
    height: fit-content;
    position:absolute;
    margin-top: 130px;
    margin-left: 24px;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
    white-space: nowrap;
  }
  .section-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    padding-top: 100px;
    width: 100%;
    height: ${(props) => props.isVertical ? '50vh' : '100vh'};
    min-height: ${(props) => props.isVertical ? '50vh' : '670px'};
    @media all and (min-width: 1280px){
      padding-top: 140px;
      min-height: ${(props) => props.isVertical ? '50vh' : '720px'};
    }
    @media all and (min-width: 1600px){
      padding-top: 186px;
      min-height: ${(props) => props.isVertical ? '50vh' : '1000px'};;
    }
    @media all and (min-width: 1920px){
      padding-top: 260px;
    }
    @media all and (min-width: 2560px){
      padding-top: 340px;
    }
  }
`;

const Divider = styled.div`
  background-color: #FFFFFF;
  width: 3px;
  height: 84px;
  margin: 0 24px;
  @media all and (min-width: 1600px){
    width: 5px;
    height: 116px;
    margin: 0 32px;
    }
`;

const fadeInImage = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

interface CarImageProps {
  small?: boolean;
  taigo?: boolean;
  height?: number;
  isVertical: boolean;
}

const ImageWrapper = styled.div<CarImageProps>`
  align-self: flex-start;
  position: relative;
  width: 100%;
  height: ${(props) => props.isVertical ? '50vh' : '100vh'};
  min-height:${(props) => props.isVertical ? '50vh' : '670px'};
  display: ${(props) => props.height < 560 && props.small ? 'flex' : null};
  align-items: ${(props) => props.height < 560 && props.small ? 'flex-end' : null};
  @media all and (min-width: 1280px) {
    display: ${(props) => props.height < 720 && props.small ? 'flex' : null};
    align-items: ${(props) => props.height < 720 && props.small ? 'flex-end' : null};
    min-height: ${(props) => props.isVertical ? '50vh' : '720px'};
  }
  @media all and (min-width: 1600px) {
    display: ${(props) => props.height < 1000 && props.small ? 'flex' : null};
    align-items: ${(props) => props.height < 1000 && props.small ? 'flex-end' : null};
    min-height: ${(props) => props.isVertical ? '50vh' : '1000px'};
  }
`;

const CarImage = styled.img<CarImageProps>`
  position: ${(props) => props.small ? 'absolute' : null};
  animation: ${(props) => props.small ? null : css`${fadeInImage} 0.2s ease-in`};
  width: ${(props) => props.small ? 'var(--size-grid006)' : 'var(--size-grid009)'};
  height: auto;
  transform:${(props) => props.taigo && props.small ? 'translateX(-20%) scale(1.2)' : props.taigo ? 'scale(1.2)' : 'translateX(5%)'};
  bottom: ${(props) => props.isVertical && props.small ? '25%' : props.small ? '18%' : null};
  right: ${(props) => props.small ? null : props.taigo ? '-64px' : '-126px'};
  margin-bottom: ${(props) => props.small ? null : '32px'};
  transform-origin: center bottom;
  @media all and (min-width: 1600px) {
    width: ${(props) => props.small ? 'var(--size-grid006)' : 'var(--size-grid009)'};
  }
  @media all and (min-width: 1920px) {
    margin-bottom: ${(props) => props.small ? null : '64px'};
    width: ${(props) => props.small ? '480px' : '720px'};
    transform:${(props) => props.taigo && props.small ? 'translateX(-20%) ' : 'translateX(5%)'};
  }
`;

const CTAanimation = keyframes`
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

const CTAPostion = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: calc( var(--size-grid001) / 2);
  @media all and (min-width: 1600px){
    padding-bottom: 64px;
  }
`;

const CTAWrapper = styled.div`
  animation: ${CTAanimation} 4s ease infinite;
`;

const shadowAnimation = keyframes`
  0%   { opacity: 1; }
  50%  { opacity: 0.5 ; }
  100% { opacity: 1 ; }
`;
const Shadow = styled.div`
  animation: ${shadowAnimation} 4s ease infinite;
  margin: 15px 0 30px;
  height: 6px;
  width: 176px;
  border-radius: 50%;
  filter: blur(8px);
  background-color: #000000;
  @media all and (min-width: 1600px) {
    margin: 15px 0;
  }
`;

const NoMarginWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  div {
    margin-bottom: 0;
  }
`;

const BottomContentWrapper = styled.div<CarImageProps>`
  position: ${(props) => props.height < 560 ? 'relative' : 'absolute'};
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media all and (min-width: 1280px) {
    position: ${(props) => props.height < 720 ? 'relative' : 'absolute'};
  }
  @media all and (min-width: 1600px) {
    position: ${(props) => props.height < 1000 ? 'relative' : 'absolute'};
  }
`;

interface LiSectionProps {
  active: number;
  handleClick: (e: React.MouseEvent, i: number) => void;
  id: number;
  content: any;
  carlineWithOffers: CarlineWithOffers;
}

export const LiSection = (props: TriggerProps & LiSectionProps) => {
  const { handleClick, active, id, content, carlineWithOffers, setSelectedJourney } = props;
  const height = useWindowHeight();
  const width = useWindowWidth();

  const [imageUrl, setImageUrl] = useState<any>(null);
  const [isVertical, setVertical] = useState<boolean>(false);

  useEffect(() => {
    if (width - height <= 100 || height > width) {
      setVertical(true);
    } else {
      setVertical(false);
    }
  }, [width]);

  useEffect(() => {
    console.log(isVertical);
  }, [isVertical]);

  useEffect(() => {
    if (content.carline.title === 'Taigo') {
      setImageUrl('https://assets.volkswagen.com/is/image/volkswagenag/taigo_suvperheroes_v2?Zml0PWNyb3AlMkMxJndpZD0xMDM5JmhlaT01MjAmZm10PXBuZy1hbHBoYSZiZmM9b2ZmJmIzZmE=');
    } else if (content.carline.title === 'T-Cross') {
      setImageUrl('https://assets.volkswagen.com/is/image/volkswagenag/t-cross_suvperheroes_v3?Zml0PWNyb3AlMkMxJndpZD0xMDQyJmhlaT01ODYmZm10PXBuZy1hbHBoYSZiZmM9b2ZmJjVlZWU=');
    } else {
      setImageUrl('https://assets.volkswagen.com/is/image/volkswagenag/t-roc_suvperheroes_v3?Zml0PWNyb3AlMkMxJndpZD0xMDI4JmhlaT01NzgmZm10PXBuZy1hbHBoYSZiZmM9b2ZmJmIwNTU=');
    }
  }, [content]);

  return (
    <LiWrapper onClick={(e) => handleClick(e, id)} className={id === active ? 'active' : 'inactive'} idLi={id} shadowSide={id < active ? 'interior' : 'exterior'} isVertical={isVertical}>
      {
        id !== active ? (
          <>
            <div className="section-title">
              <Text appearance={TokenTextAppearance.headline300} bold>{content.carline.title}</Text>
            </div>
            <ImageWrapper height={height} small taigo={content.carline.title === 'Taigo' ? true : false} isVertical={isVertical}>
              <CarImage src={imageUrl ?? imageUrl} height={height} isVertical={isVertical} small taigo={content.carline.title === 'Taigo' ? true : false} />
            </ImageWrapper>
          </>

        ) : (
          <>
            <BackgroundImage src={backgroundUrl} liSection idLi={id} isVertical={isVertical} />
            <div className='section-content'>

              <BreakpointWrapper max={Breakpoints.b1280}>
                <Container wrap={'never'} verticalAlign={'flex-end'}>
                  <Container wrap={'always'} horizontalAlign={'flex-end'}>
                    <Text appearance={TokenTextAppearance.headline300} bold textAlign={TextAlignment.right}>{content.carline.title}</Text>
                    <Text appearance={TokenTextAppearance.label150} textAlign={TextAlignment.right}>con ACC, Front Assist y</Text>
                    <Text appearance={TokenTextAppearance.label150} textAlign={TextAlignment.right}>detector de peatones</Text>
                  </Container>
                  <Divider className='divider' />
                  <Container wrap={'always'} padding={content.myWay.activo === 1 ? null : { bottom: ContainerPadding.static100 }}>

                    <NoMarginWrapper>
                      <Text appearance={TokenTextAppearance.headline300} bold>{content.cuota}€</Text>
                      <Text appearance={TokenTextAppearance.copy100}>/mes<sup>*</sup></Text>
                    </NoMarginWrapper>
                    <Text appearance={TokenTextAppearance.headline200} bold>{content.financiacionText}</Text>
                    {content.myWay.activo === 1 ?
                      <Text appearance={TokenTextAppearance.label150} textAlign={TextAlignment.left}>Cuota final: {formatNumber(content.cuotaFinal)}€</Text>
                      : null
                    }
                  </Container>
                </Container>
              </BreakpointWrapper>

              <BreakpointWrapper min={Breakpoints.b1280} >
                <Container wrap={'never'} verticalAlign={'flex-end'}>
                  <Container wrap={'always'} horizontalAlign={'flex-end'}>
                    <Text appearance={TokenTextAppearance.headline400} bold textAlign={TextAlignment.right}>{content.carline.title}</Text>
                    <Text appearance={TokenTextAppearance.label200} textAlign={TextAlignment.right}>con ACC, Front Assist y</Text>
                    <Text appearance={TokenTextAppearance.label200} textAlign={TextAlignment.right}>detector de peatones</Text>
                  </Container>
                  <Divider className='divider' />
                  <Container wrap={'always'} padding={content.myWay.activo === 1 ? null : { bottom: ContainerPadding.static100 }}>

                    <NoMarginWrapper>
                      <Text appearance={TokenTextAppearance.headline400} bold>{content.cuota}€</Text>
                      <Text appearance={TokenTextAppearance.copy200}>/mes<sup>*</sup></Text>
                    </NoMarginWrapper>
                    <Text appearance={TokenTextAppearance.headline300} bold>{content.financiacionText}</Text>
                    {content.myWay.activo === 1 ?
                      <Text appearance={TokenTextAppearance.label200} textAlign={TextAlignment.left}>Cuota final: {formatNumber(content.cuotaFinal)}€</Text>
                      : null
                    }
                  </Container>
                </Container>
              </BreakpointWrapper>

              <BottomContentWrapper height={height} isVertical={isVertical} className='bottom-content'>
                <CarImage src={imageUrl ?? imageUrl} isVertical={isVertical} taigo={content.carline.title === 'Taigo' ? true : false} />
                <CTAPostion>
                  <CTAWrapper>
                    <ThemeProvider theme='main'>
                      <CTAOfertaSuv setSelectedJourney={setSelectedJourney} carlineWithOffers={carlineWithOffers} />
                    </ThemeProvider>
                  </CTAWrapper>
                  <Shadow />
                </CTAPostion>
              </BottomContentWrapper>
            </div>
          </>
        )
      }
    </LiWrapper>
  );
};
