import {
  Breakpoints,
  Container,
  ContainerPadding,
  styled,
  Text,
  Layout,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { LastNavigation, LssiTkmQuestions, MultipleCTASelection, PoliticaPrivacidad } from 'src/components';
import { CTAsSiNo } from 'src/feature-app';
import { IdLead, OneFormState } from 'src/types';

const GeneralconfirmacionWrapper = styled.div``;

const InputErrorLegales = styled.p`
  position: relative;
  margin-top: 10px;
  background-color: #e4002c;
  color: #ffffff;
  width: max-content;
  max-width: 280px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
`;
interface ConfirmacionNoCompraProps {
  idLead: IdLead;
}

export const ConfirmacionNoCompra = (props: ConfirmacionNoCompraProps) => {
  const dispatch = useDispatch();
  const { formData } = useSelector((state: OneFormState) => state);
  const [compartir, setCompartir] = useState<string>(null);
  const [compartirError, setCompartirError] = useState<boolean>(false);
  const store = useStore();

  const noTengo = formData.fields.formName == 'no_compra-no_tengo';
  const quieroContacto: boolean = store.getState().formData.fields.quieroContacto == 'true';
  const lssiPermite: boolean = props.idLead?.LSSI__c == 'PERMITE';

  useEffect(() => {
    if (compartir) {
      const values = { compartir: compartir };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setCompartirError(false);
    }
  }, [compartir]);

  useEffect(() => {
    if (lssiPermite) {
      dispatch({ type: 'UPDATE_FIELDS', payload: { lssi: 'NO INFORMADO', tkm: 'NO INFORMADO' } });
    }
  }, [props.idLead]);

  return (
    <GeneralconfirmacionWrapper>
      <Layout
        allowOverflowingContent
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
          [Breakpoints.b1280]: [
            { name: '.', columns: 5 },
            { name: 'a', columns: 14 },
            { name: '.', columns: 5 },
          ],
          [Breakpoints.b1920]: [
            { name: '.', columns: 6 },
            { name: 'a', columns: 12 },
            { name: '.', columns: 6 },
          ],
          [Breakpoints.b2560]: [
            { name: '.', columns: 7 },
            { name: 'a', columns: 10 },
            { name: '.', columns: 7 },
          ],
        }}
      >
        { lssiPermite && (!quieroContacto || !noTengo) ? 
          (
            <div style={{ paddingBottom: '32px' }}>
              <Text appearance={TokenTextAppearance.headline300}>
                <Text bold>¿Listo</Text> para enviar?
              </Text>
            </div>
          ) 
          :
          (
            <Text appearance={TokenTextAppearance.headline300}>
                Y unas <Text bold>últimas preguntas</Text>
            </Text>
          )
        }
        { noTengo && quieroContacto ?
          (
            <Container padding={{ top: ContainerPadding.static400 }} wrap={'always'}>
              <div style={{ width: '100%' }}>
                <Text appearance={TokenTextAppearance.copy200}>
                  ¿Podemos compartir tu respuesta con el{' '}
                  <span style={{ whiteSpace: 'nowrap' }}>
                    punto de venta?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
                  </span>
                </Text>
              </div>
              <MultipleCTASelection ctaValues={CTAsSiNo} marginRight='32' name='compartir' ctaWidth='81' nextOnClick={false} />
              {compartirError ? <InputErrorLegales>Este campo es obligatorio</InputErrorLegales> : null}
            </Container>
          )
          :
          null
        }

        <LssiTkmQuestions showTitle={false} themeColor="main" />
        <PoliticaPrivacidad />

        <LastNavigation
          compartir={compartir}
          setCompartirError={setCompartirError}
          loadingCopy="Enviando tu respuesta"
          KoTitle="Uy, algo ha pasado"
          KoBody="Por alguna razón no hemos podido guardar tu respuesta. ¿Lo intentamos de nuevo?"
        />
      </Layout>
    </GeneralconfirmacionWrapper>
  );
};
