import { Breakpoints, BreakpointWrapper } from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { AvailabilityErrorLayer } from 'src/components';
import {
  LayerManagerWrapper,
  useFeatureAppEnvironment,
  useMapGeneralController,
  sortPoints,
  useOneFormContext,
  useFeatureServices,
  NewDealerCard,
} from 'src/feature-app';
import { LoadingLayer } from 'src/forms/CitaPosventa/NuevaCitaPosventa';
import { Horario, OneFormState, Points } from 'src/types';
import { fetchProvince, fetchProvinceLegacy } from 'src/bff';
import { useIsComerciales } from 'src/feature-app/hooks';
import {
  getDistanceFromLatLngCenter,
  LayerDesktopView,
  LayerMobileView
} from 'src/feature-app/NewMap';

export const JourneyLeadInPageController = () => {
  const { formData } = useSelector((state: OneFormState) => state);
  const { horario }: { horario?: Horario } = formData.fields;
  const { handleNextStep } = useOneFormContext();
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store = useStore();
  const dispatch = useDispatch();
  const env = useFeatureAppEnvironment();
  const [loading, setLoading] = useState(false);
  const pointsByDistanceRef = useRef(null);
  const isComerciales = useIsComerciales();

  const loadingLayer = useRef(null);
  const {
    clusters,
    centerToCalculateDistance,
    superClusterRef,
    cardsDistanceRef,
    cardsTimeRef,
  } = useMapGeneralController();

  const [pointsToList, setPointsToList] = useState(null);

  useEffect(() => {
    if (clusters && superClusterRef.current) {
      const { pointsByDistance } = sortPoints(
        clusters,
        horario,
        centerToCalculateDistance.current,
        superClusterRef.current
      );
      pointsByDistanceRef.current = pointsByDistance;
      setPointsToList(pointsByDistance);
    }
  }, [clusters, superClusterRef.current]);

  const renderPoints = (points: Points[], isTime?: boolean, isCarouselCard?: boolean): JSX.Element[] => {
    // const pointsToRender = isTime ? pointsByTime : pointsByDistance;

    return points.map((point: Points, index: number) => {
      if (!point.properties.cluster) {
        const { dealer } = point.properties;
        const distanceFromCenter = getDistanceFromLatLngCenter(centerToCalculateDistance.current, point);

        return (
          <div
            key={`${point.properties.dealer.kvps}-${index}`}
            className={`${point.properties.dealer.kvps}`}
            ref={(el) => {
              cardsDistanceRef.current[index] = el;
            }}
          >
            <NewDealerCard
              // handleShowSlots={handleShowSlots}
              key={`${point.properties.dealer.kvps}-${index}-card`}
              distanceFromCenter={distanceFromCenter}
              handleSeleccionarOnClick={handleSeleccionarOnClick}
              isCarousel={false}
              slots={dealer.slots}
              dealerInfo={dealer}
              point={point}
            />
          </div>
        );
      }
      return null;
    });
  };

  // Gestión del point seleccionado y del boton de seleccionar (dms)
  const handleSeleccionarOnClick = useCallback((point: Points) => {
    // Añadir lógica para guardar el dealer seleccionado
    dispatch({ type: 'SET_SELECTED_INSTALLATION', payload: point.properties.installation });
    // Necesitamos el nombre de la provincia, y éste no está disponible en la request de instalaciones.
    if(isComerciales) {
      fetchProvince('LCV_DDB_FRM_LEM_REC_LEM').then((response) => {
        const province = response.content.find((att) =>
          att.attributes.find((prov) => prov.value === point.properties.installation.ShippingStateCode)
        );
        const provinceName = province.attributes[1].value;

        dispatch({ type: 'UPDATE_FIELDS', payload: { provinceName } });
      });
    }
    else {
      fetchProvince('VW_DDB_FRM_LEM_REC_LEM').then((response) => {
        const province = response.content.find((att) =>
          att.attributes.find((prov) => prov.value === point.properties.installation.ShippingStateCode)
        );
        const provinceName = province.attributes[1].value;

        dispatch({ type: 'UPDATE_FIELDS', payload: { provinceName } });
      });
    }

    handleNextStep();
  }, []);

  const renderLoadingLayer = () => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingLayer copy='Confirmando tu cita' />
      </LayerManagerWrapper>
    );
  };

  const handleLoadingLayer = () => {
    if (loading) {
      const layer = layerManager.openFocusLayer(renderLoadingLayer, {}, { size: FocusLayerSizeV2.A });
      loadingLayer.current = layer;
    } else {
      if (loadingLayer.current) {
        loadingLayer.current.close();
        loadingLayer.current = null;
      }
    }
  };

  useEffect(() => {
    handleLoadingLayer();
  }, [loading]);

  // availability functions

  return (
    <div className="lead-inPage-MAP-controller">
      <BreakpointWrapper max={Breakpoints.b960}>
        <LayerMobileView
          handleSeleccionarOnClick={handleSeleccionarOnClick}
          renderPoints={renderPoints}
          pointsToList={pointsToList}
          concesionario={true}
          onlySuggest={true}
          isPosventa={false}
        />
      </BreakpointWrapper>

      <BreakpointWrapper min={Breakpoints.b960}>
        <LayerDesktopView
          handleSeleccionarOnClick={handleSeleccionarOnClick}
          renderPoints={renderPoints}
          pointsToList={pointsToList}
          paddingBottomList='32px'
          isPosventa={false}
        />
      </BreakpointWrapper>
    </div>
  );
};
