import { OneFormContent } from 'src/feature-app';
import { FormDataFields, FormDataFlags, FormDataHeaders } from 'src/types';

export const formDataBuilder = (data: any): FormData => {
  const formData = new FormData();

  for (const key in data) {
    if (data[key] === null || data[key] === 'null') {
      formData.append(key, '');
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const formatFormData = (object: object, string: string) => {
  if (typeof object !== 'object' || Object.keys(object).length === 0) {
    return {};
  }
  return Object.entries(object)
    .map((key) => [`${string}[${key[0]}]`, key[1]])
    .reduce((accum: FormDataFields, [k, v]) => {
      accum[k] = v;
      return accum;
    }, {});
};

/**
 * Formato de entrada: {DESC_FORM_VERSION: ''}
 * Formato de salida: {fields[DESC_FORM_VERSION]: ''}
 * @param fields
 */
export const formatFields = (fields: FormDataFields): FormDataFields => {
  return formatFormData(fields, 'fields');
};

/**
 * Formato de entrada: {DESC_FORM_DETAIL: ''}
 * Formato de salida: {headers[DESC_FORM_DETAIL]: ''}
 * @param headers
 */
export const formatHeaders = (headers: FormDataHeaders): FormDataHeaders => {
  return formatFormData(headers, 'headers');
};

/**
 * Formato de entrada: {DESC_FORM_DETAIL: ''}
 * Formato de salida: {flags[DESC_FORM_DETAIL]: ''}
 * @param flags
 */
export const formatFlags = (flags: FormDataFlags): FormDataFlags => {
  return formatFormData(flags, 'flags');
};

export const getDpsFields = (faContent: OneFormContent) => {
  if (faContent && faContent.dpsFields && faContent.dpsFields.length > 0) {
    const headers = faContent.dpsFields.reduce((initialValue, header) => {
      const key = header.field;
      const value = header.value;
      const obj = { [key]: value };
      initialValue = { ...initialValue, ...obj };
      return initialValue;
    }, {});

    return { ...headers };
  }
  return null;
};
