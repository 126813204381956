import React from 'react';
import {
  styled
} from '@volkswagen-onehub/components-core';
import { SuggestModelo, SuggestMarca } from 'src/components';

const ContentWrapper = styled.div`
  margin-top: 4px;
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;
  }
  label {
    margin-top: 40px;
    @media all and (min-width: 560px) {
      width: var(--size-grid013);
    }
    @media all and (min-width: 960px) {
      width: var(--size-grid008);
    }
    @media all and (min-width: 1280px) {
      width: var(--size-grid006);
    }
    @media all and (min-width: 1920px) {
      width: var(--size-grid005);
    }
    @media all and (min-width: 2560px) {
      width: var(--size-grid004);
    }
  }
`;

export const PantallaMarcaModelo = () => {
  return (
    <ContentWrapper>
      <div style={{marginBottom: '2px'}}><SuggestMarca notALayer={true} fieldsName='marca' required/></div>
      <div style={{marginBottom: '2px'}}><SuggestModelo notALayer={true} fieldsName='modelo' required/></div>
    </ContentWrapper>
  );
};
