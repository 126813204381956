import { Container, ContainerPadding, styled, Tabs, Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { SlotsHorarios } from './CalendarioCita';
import { CalendarSlot } from 'src/types';
import { SlotsHorario } from '.';
import { checkIfEvening, checkIfMidDay, checkIfMorning } from '../NewMap';

const TabsWrapper = styled.div`
  ul {
    margin: 5px;
    width: 86vw;

    @media screen and (min-width: 560px) {
      width: 76vw;
    }
    @media screen and (min-width: 960px) {
      width: 640px;
    }
  }
`;

interface TabsHorariosProps {
  handleSelectedSlot: any;
  selectedSlot: CalendarSlot;
  horarios: SlotsHorarios;
}

export function TabsHorario(props: TabsHorariosProps) {
  const { handleSelectedSlot, selectedSlot, horarios } = props;

  const [defaultIndex, setDefaultIndex] = useState(0);

  useEffect(() => {
    if (selectedSlot) {
      const defaultIndex = handleDefaultTabIndex(selectedSlot);
      setDefaultIndex(defaultIndex);
    }
  }, [selectedSlot]);

  const handleDefaultTabIndex = (slot: CalendarSlot) => {
    const slotHour = new Date(`1/1/1999 ${slot.hour}`);

    if (checkIfMorning(slotHour)) {
      return 0;
    } else if (checkIfMidDay(slotHour)) {
      return 1;
    } else if (checkIfEvening(slotHour)) {
      return 2;
    }
    return 0;
  };

  return (
    <Container padding={{ top: ContainerPadding.static500 }}>
        <TabsWrapper className='tabs-wrapper' onClick={(e) => {e.preventDefault()}}>
          <Tabs
            idPrefix="slots-horario"
            defaultIndex={defaultIndex}
            onChange={(index: number) => {
              setDefaultIndex(index);
            }}
          >
            {{
              title: <Text>Mañana</Text>,
              content: (
                <Container padding={{ top: ContainerPadding.static400 }}>
                  <SlotsHorario
                    avaiableHours={horarios ? horarios.manana : null}
                    handleSelectedSlot={handleSelectedSlot}
                    selectedSlot={selectedSlot}
                    // // selectedHourIndex={selectedHourIndex}
                  />
                </Container>
              ),
              key: 'a',
            }}
            {{
              title: <Text>Mediodía</Text>,
              content: (
                <Container padding={{ top: ContainerPadding.static400 }}>
                  <SlotsHorario
                    avaiableHours={horarios ? horarios.mediodia : null}
                    handleSelectedSlot={handleSelectedSlot}
                    selectedSlot={selectedSlot}
                    // // selectedHourIndex={selectedHourIndex}
                  />
                </Container>
              ),
              key: 'b',
            }}
            {{
              title: <Text>Tarde</Text>,
              content: (
                <Container padding={{ top: ContainerPadding.static400 }}>
                  <SlotsHorario
                    avaiableHours={horarios ? horarios.tarde : null}
                    handleSelectedSlot={handleSelectedSlot}
                    selectedSlot={selectedSlot}
                    // // selectedHourIndex={selectedHourIndex}
                  />
                </Container>
              ),
              key: 'c',
            }}
          </Tabs>
        </TabsWrapper>
    </Container>
  );
}
