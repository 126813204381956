import { getVwEventosConfig } from 'src/feature-app';

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving Volkwagen Eventos service config provider.');
  return null;
};

export const getVwEventosUrl = (): string | null => {
  const service = getVwEventosConfig();

  if (!service) {
    return handleErrorInRetrievingExternalConfig();
  }

  return service.urlOrigin;
};

export const getVwEventosEntregaIdealUrl = (): string | null => {
  const service = getVwEventosConfig();

  if (!service) {
    return handleErrorInRetrievingExternalConfig();
  }

  return service.urlOrigin + '/api/volkswagen/entrega-ideal';
};
