import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputError, TextInputExtended } from 'src/components';
import { useOnlyLettersControl } from './use-only-letters-control';
import { useDispatch } from 'react-redux';
import { IdPerson } from 'src/types';

interface NombreProps {
  setFieldValue?: boolean;
  idPerson?: IdPerson;
}

export function Nombre(props: NombreProps) {
  const { setFieldValue } = props;
  const dispatch = useDispatch();
  const { register } = useFormContext();
  const [inputValue, setInputValue] = useState('');
  const [pristine, setPristine] = useState(true);
  const { error, regExp } = useOnlyLettersControl(pristine, inputValue);

  const handleChange = (e: any) => {
    if (setFieldValue && !error) {
      dispatch({ type: 'UPDATE_FIELDS', payload: { ['name']: e.target.value } });
    }

    setInputValue(e.target.value);
    setPristine(false);
  };

  return (
    <div>
      <TextInputExtended
        name="name"
        label="Nombre"
        isFloating
        innerRef={register({
          required: {
            value: true,
            message: 'Requerido',
          },
          pattern: {
            value: regExp,
            message: 'Solo se aceptan letras',
          },
        })}
        onChange={(e) => handleChange(e)}
        required
      />
      {error && <InputError className="input-error">Solo se aceptan letras</InputError>}
    </div>
  );
}
