import React from 'react';
import { JourneyLeadInPageController } from '.';
import { MapGeneralController } from 'src/feature-app';

/**
 * Este componente está creado únicamente para que JourneyNoCompraController tenga acceso a las variables de MapGeneralController.
 */
export const JourneyLeadsInPageWrapper = () => {
  
  return (
    <MapGeneralController>
      <JourneyLeadInPageController />
    </MapGeneralController>
  );
};
