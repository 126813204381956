import React, { useState } from 'react';
import { MultipleCTASelection} from 'src/components';

const fleetOptions = [
  {
    value: '1 - 2',
    label: '1 o 2',
  },
  {
    value: '3 - 10',
    label: 'De 3 a 10',
  },
  {
    value: '11 - 50',
    label: 'De 11 a 50',
  },
];

export const Fleet = () => {

  return (
    <MultipleCTASelection ctaValues={fleetOptions} name="fleet" nextOnClick />
  );
};
