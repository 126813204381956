import {
  Breakpoints,
  Container,
  ContainerPadding,
  CTA,
  Layout,
  styled,
  Text,
  TextAlignment,
  ThemeProvider,
  TokenTextAppearance,
  TokenTextColor
} from '@volkswagen-onehub/components-core';
import { Calendar, FleetServicePrivate, CarPickupService } from 'src/icons-core-imports';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMapGeneralController } from 'src/feature-app';
import { Points } from 'src/types';
import { Horario, OneFormState } from 'src/types';
import { getDistanceFromLatLngCenter } from 'src/feature-app/NewMap';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { usePinState } from 'src/feature-app/NewMap/usePinState';
import { useIsComerciales } from 'src/feature-app';

interface BoxCardProps {
  selected?: boolean;
  isCarousel: boolean;
  isHover?: boolean;
  isFirstDesktop?: boolean;
  greyCard?: boolean;
  iDBuzzExclusive?: boolean;
}

const BoxCard = styled.div<BoxCardProps>`
  width: 100%;
  margin-top: ${(props) => (props.isCarousel || props.isFirstDesktop || props.iDBuzzExclusive ? '0' : '20px')};
  padding: ${(props) => (props.isCarousel ? '12px' : '20px')};
  border: ${(props) =>
    props.selected && props.greyCard && !props.isCarousel
      ? '2px solid #6A767D'
      : props.greyCard && !props.isCarousel
        ? '1px solid #6A767D'
        : props.selected && !props.isCarousel
          ? '2px solid #FFFFFF'
          : props.isCarousel
            ? 'none'
            : '1px solid rgba(255,255,255,0.44)'};
  :hover {
    border: ${(props) => (
    props.isCarousel
      ? 'none'
      : props.selected && props.greyCard
        ? '2px solid #6A767D'
        : props.greyCard
          ? '1px solid #6A767D'
          : props.selected
            ? '2px solid #FFFFFF'
            : '1px solid #4cc7f4')};
  }

  @media all and (min-width: 560px) {
    margin-top: ${(props) => (props.isCarousel || props.isFirstDesktop || props.iDBuzzExclusive ? '0' : '24px')};
    padding: 20px;
  }
  @media screen and (min-width: 960px) {
    .main {
    border: ${(props) =>
    props.isCarousel
      ? 'none'
      : props.selected
        ? '2px solid #001e50;'
        : props.isHover
          ? '1px solid #0040C5'
          : '1px solid #DFE4E8'};
    }
    .inverted {
    border: ${(props) =>
    props.isCarousel
      ? 'none'
      : props.selected
        ? '2px solid #FFFFFF'
        : props.isHover
          ? '1px solid #4cc7f4'
          : '1px solid rgba(255,255,255,0.44)'};
    }
    
  }
  @media all and (min-width: 1600px) {
    margin-top: ${(props) => (props.isCarousel || props.isFirstDesktop || props.iDBuzzExclusive ? '0' : '28px')};
  }
`;

interface isCarouselProps {
  isCarousel: boolean;
}

const CTAVerMasHorasWrapper = styled.div<isCarouselProps>`
  button {
    color: #ffffff;
    font-size: ${(props) => (props.isCarousel ? '12px' : null)};
    svg {
      height: ${(props) => (props.isCarousel ? '10px' : null)};
      width: ${(props) => (props.isCarousel ? '10px' : null)};
    }
  }
`;

const EllipsisTextWrapper = styled.p<{ greyCard?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0px;
  margin-bottom: 0px;
  color: ${(props) => (props.greyCard ? '#6A767D' : '#FFFFFF')};
`;
const GreyText = styled.span<{ greyCard?: boolean }>`
  color: ${(props) => (props.greyCard ? '#6A767D' : '#FFFFFF')};
`;
const SVGSize = styled.div<{ greyCard: boolean }>`
  padding-left: 16px;
  svg {
    height: 20px;
    width: auto;
    color: ${(props) => props.greyCard ? '#6A767D' : '#FFFFFF'};
  }
`;
const CustomCTA = styled.button<{ greyCard?: boolean }>`
  height: 32px;
  width: max-content;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 22px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.greyCard ? '#6A767D' : '#FFFFFF'};
  color: ${(props) => props.greyCard ? '#DFE4E8' : '#001E50'};
  :hover {
    background-color: ${(props) => props.greyCard ? '#6A767D' : 'rgba(255,255,255,0.90)'};
  }
`;
const CustomTerciayCTA = styled.button<{ greyCard?: boolean }>`
  color: ${(props) => props.greyCard ? '#6A767D' : '#FFFFFF'};
  display: flex;
  border: none;
  cursor: pointer;
  background: none;
  align-items: center;
  padding:0 0 2px;
  border-bottom: ${(props) => props.greyCard ? '1px solid #6A767D' : '1px solid #FFFFFF'};
  :hover {
    background-color: ${(props) => props.greyCard ? '#6A767D' : 'rgba(255,255,255,0.90)'};
  }
  svg{
    color: ${(props) => props.greyCard ? '#6A767D' : '#FFFFFF'};
  }
  div {
    color: ${(props) => props.greyCard ? '#6A767D' : '#FFFFFF'};
  }
`;
const ExclusiveIDBuzz = styled.span`
  align-items: center;
  background-color: #DFC526;
  border-radius: 20px;
  color: #001E50;
  display: flex;
  font-size: 10px;
  height: 21px;
  justify-content: center;
  position: relative;
  right: 10px;
  width: 116px;
  top:10.5px;
  @media all and (min-width: 560px) {
    right: 20px;
  }
  @media all and (min-width: 960px) {
    right: 0;
  }
`

interface DealerCardProps {
  distanceFromCenter: string;
  handleSeleccionarOnClick?: any;
  handleShowCalendarOnClick?: any;
  // center?: Center,
  isCarousel: boolean;
  dealerInfo: any;
  point: Points;
  slots: any;
  handleShowSlots?: (point: Points) => string | null;
  isCitaPosventa?: boolean;
  isFirstDesktop?: boolean;
  carPickupSelected?: boolean;
  replacementCarSelected?: boolean;
}

export const NewDealerCard = React.memo((props: DealerCardProps) => {
  const {
    distanceFromCenter,
    isCarousel,
    dealerInfo,
    point,
    handleShowSlots,
    isCitaPosventa,
    isFirstDesktop,
    carPickupSelected,
    replacementCarSelected
  } = props;
  const isComerciales = useIsComerciales();
  const { suggestionIsAddress } = useSelector((state: OneFormState) => state.formInfo);
  const dealerCompletAdress = `${dealerInfo.markerInfo.dealerInfo.address}, ${dealerInfo.markerInfo.dealerInfo.zipCode} ${dealerInfo.markerInfo.dealerInfo.city}`;
  const {
    centerToCalculateDistance,
    handlePointClickAndCarouselMove,
    handleHoverPoint,
    hoverPoint,
    selectedPoint,
    userIsGeoLocated,
    handleClusterClick,
  } = useMapGeneralController();
  const dispatch = useDispatch();
  // Las instalaciones con codInstalacion del array son excluusivas para IDBuzz
  const installationsIDBuzz = [
    '0300W',
    '03910',
    '03394',
    '0311Q',
    '0311X',
    '52VH1',
    '0321C',
    '30559',
    '03200',
    '53V50',
    '0310W',
    '03917',
    '30083'
  ]
  const { isActive, isHover } = usePinState(selectedPoint, hoverPoint, point);
  const [greyCard, setGreyCard] = useState<boolean>(false);
  const codInstalacion = point.properties?.dealer?.codInstalacion;
  const iDBuzzExclusive = installationsIDBuzz.includes(codInstalacion) && isComerciales;

  const handleIDBuzzExclusive = () => {
    const iDBuzzExlusiveAndComerciales = isComerciales && iDBuzzExclusive
    dispatch({ type: 'UPDATE_FIELDS', payload: { idBuzzExclusive: iDBuzzExlusiveAndComerciales } });
  }

  useEffect(() => {
    if (point) {
      if (carPickupSelected && !point.properties.dealer.dmsInfo.pickupCarServiceAvailabe) {
        setGreyCard(true);
      } else if (replacementCarSelected && !point.properties.dealer.dmsInfo.replacementCarServiceAvailable) {
        setGreyCard(true);
      } else {
        setGreyCard(false);
      }
    }
  }, [carPickupSelected, replacementCarSelected, point]);

  return (
    <>
      {iDBuzzExclusive && isComerciales &&
        <Layout allowOverflowingContent appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 0 },
            { name: 'a', columns: 0 },
            { name: '.', columns: 0 },
          ],
          [Breakpoints.b560]: [
            { name: '.', columns: 0 },
            { name: 'a', columns: 0 },
            { name: '.', columns: 0 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 16 },
            { name: 'a', columns: 7 },
            { name: '.', columns: 1 },
          ],
        }}>
          <ExclusiveIDBuzz>Exclusivo para ID.Buzz</ExclusiveIDBuzz>
        </Layout>
      }
      <BoxCard
        selected={isActive}
        isCarousel={isCarousel}
        isHover={isHover}
        isFirstDesktop={isFirstDesktop}
        greyCard={greyCard}
        iDBuzzExclusive={iDBuzzExclusive}
        className='box-card-wrapper'
        onClick={(e) => {
          e.preventDefault();
          if (isComerciales) {
            handleIDBuzzExclusive()
          }
          if (point.properties.parent_cluster_id) {
            if (window.innerWidth <= 959) {
              handleClusterClick(point, true, true);
            } else {
              handleClusterClick(point, true);
            }
          } else {
            handlePointClickAndCarouselMove(point);
          }
        }}
        onMouseEnter={(e) => {
          if (!isCarousel) {
            e.preventDefault();
            handleHoverPoint(point);
          }
        }}
        onMouseLeave={(e) => {
          if (!isCarousel) {
            e.preventDefault();
            handleHoverPoint(null);
          }
        }}
      >
        <div style={{ width: '100%' }}>
          {isCarousel ? (
            <>
              {/*card para el carousel*/}
              <Container wrap={'always'} stretchContent>
                <div style={{ width: '100%' }}>
                  {
                    userIsGeoLocated || suggestionIsAddress ? (
                      <Layout
                        appearance={{
                          [Breakpoints.default]: [
                            { name: 'a', columns: 19 },
                            { name: 'b', columns: 5 },
                          ],
                        }}
                      >
                        <Text bold textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200}>
                          <EllipsisTextWrapper greyCard={greyCard}>{dealerInfo.name ? dealerInfo.name : null}</EllipsisTextWrapper>
                        </Text>

                        <Text textAlign={TextAlignment.right} appearance={TokenTextAppearance.copy100}>
                          <GreyText greyCard={greyCard}>
                            {userIsGeoLocated || suggestionIsAddress
                              ? distanceFromCenter
                                ? `${distanceFromCenter}km`
                                : `${getDistanceFromLatLngCenter(centerToCalculateDistance.current, point)}km`
                              : null}
                          </GreyText>
                        </Text>
                      </Layout>
                    ) : (
                      <Text bold textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200}>
                        <EllipsisTextWrapper greyCard={greyCard}>{dealerInfo.name ? dealerInfo.name : null}</EllipsisTextWrapper>
                      </Text>
                    )
                  }
                </div>
                <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100}>
                  {!point.properties.dealer.dmsInfo || !point.properties.dealer.dmsInfo.dmsAvailable ? (
                    <p style={{ maxHeight: '40px', marginTop: '0', marginBottom: '0' }}>
                      <EllipsisTextWrapper greyCard={greyCard}>{dealerCompletAdress}</EllipsisTextWrapper>
                    </p>
                  ) : (
                    <EllipsisTextWrapper greyCard={greyCard}>{dealerCompletAdress}</EllipsisTextWrapper>
                  )}
                </Text>
              </Container>

              {handleShowSlots && handleShowSlots(point) !== null ? (
                <Container
                  padding={{ top: ContainerPadding.static150 }}
                  horizontalAlign={'space-between'}
                  verticalAlign={'flex-end'}
                >
                  <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200} bold>
                    <GreyText greyCard={greyCard}>
                      {handleShowSlots(point)}
                    </GreyText>
                  </Text>
                  {
                    point.properties.dealer.dmsInfo && point.properties.dealer.dmsInfo.replacementCarServiceAvailable
                      || point.properties.dealer.dmsInfo && point.properties.dealer.dmsInfo.pickupCarServiceAvailabe
                      ? (
                        <div style={{ display: 'flex' }}>
                          {
                            point.properties.dealer.dmsInfo.replacementCarServiceAvailable ? (
                              <SVGSize greyCard={greyCard}>
                                <FleetServicePrivate />
                              </SVGSize>
                            ) : null
                          }
                          {
                            point.properties.dealer.dmsInfo.pickupCarServiceAvailabe ? (
                              <SVGSize greyCard={greyCard}>
                                <CarPickupService />
                              </SVGSize>
                            ) : null
                          }
                        </div>
                      ) : null
                  }
                </Container>
              )
                : null
              }
            </>
          ) : (
            <>
              {/*card para los tabs, cualquier breakpoint*/}
              <Container wrap={'always'} stretchContent>
                <div style={{ width: '100%', paddingBottom: '4px' }}>
                  {
                    userIsGeoLocated || suggestionIsAddress ? (
                      <Layout
                        appearance={{
                          [Breakpoints.default]: [
                            { name: 'a', columns: 18 },
                            { name: 'b', columns: 6 },
                          ],
                        }}
                      >
                        <Text bold textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200} staticSize>
                          <GreyText greyCard={greyCard}>
                            {dealerInfo.name ? dealerInfo.name : null}
                          </GreyText>
                        </Text>
                        <Text textAlign={TextAlignment.right} appearance={TokenTextAppearance.copy200} staticSize>
                          <GreyText greyCard={greyCard}>
                            {userIsGeoLocated || suggestionIsAddress
                              ? distanceFromCenter
                                ? `${distanceFromCenter}km`
                                : `${getDistanceFromLatLngCenter(centerToCalculateDistance.current, point)}km`
                              : null}
                          </GreyText>
                        </Text>
                      </Layout>
                    ) : (
                      <Text bold textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200} staticSize>
                        <GreyText greyCard={greyCard}>
                          {dealerInfo.name ? dealerInfo.name : null}
                        </GreyText>
                      </Text>
                    )
                  }
                </div>
                <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100} staticSize>
                  <p style={{ maxHeight: '48px', height: '100%', marginTop: '0', marginBottom: '0' }}>
                    <GreyText greyCard={greyCard}>
                      {dealerCompletAdress}
                    </GreyText>
                  </p>
                </Text>
              </Container>

              {handleShowSlots && handleShowSlots(point) !== null ? (
                <Container
                  padding={{ top: ContainerPadding.static200 }}
                  horizontalAlign={'space-between'}
                  verticalAlign={'flex-end'}
                >
                  <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.headline200} bold staticSize>
                    <GreyText greyCard={greyCard}>
                      {handleShowSlots(point)}
                    </GreyText>
                  </Text>
                  {
                    point.properties.dealer.dmsInfo ? (
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {
                          point.properties.dealer.dmsInfo.replacementCarServiceAvailable ? (
                            <SVGSize greyCard={greyCard}>
                              <FleetServicePrivate />
                            </SVGSize>
                          ) : null
                        }
                        {
                          point.properties.dealer.dmsInfo.pickupCarServiceAvailabe ? (
                            <SVGSize greyCard={greyCard}>
                              <CarPickupService />
                            </SVGSize>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                </Container>
              )
                : null
              }
            </>
          )}
          {isCitaPosventa ? (<CitaPosventaCTAs {...props} />) : <LeadsCTAs {...props} />}
        </div>
      </BoxCard>
    </>
  );
});

const CitaPosventaCTAs = (props: DealerCardProps) => {
  const {
    carPickupSelected,
    dealerInfo,
    handleSeleccionarOnClick,
    handleShowCalendarOnClick,
    handleShowSlots,
    isCarousel,
    point,
    replacementCarSelected,
  } = props;

  const trackingManager = useTrackingManager();

  const { formData } = useSelector((state: OneFormState) => state);
  const { horario }: { horario?: Horario } = formData.fields;

  const [greyCard, setGreyCard] = useState<boolean>(false);

  useEffect(() => {
    if (point) {
      if (carPickupSelected && !point.properties.dealer.dmsInfo.pickupCarServiceAvailabe) {
        setGreyCard(true);
      } else if (replacementCarSelected && !point.properties.dealer.dmsInfo.replacementCarServiceAvailable) {
        setGreyCard(true);
      } else {
        setGreyCard(false);
      }
    }
  }, [carPickupSelected, replacementCarSelected, point]);
  return !dealerInfo.dmsInfo || !dealerInfo.dmsInfo.dmsAvailable || handleShowSlots && handleShowSlots(point) === null ? (
    <Container
      padding={{ top: ContainerPadding.dynamic0050 }}
      horizontalAlign={'space-between'}
      verticalAlign={'flex-end'}
    >
      <CustomCTA
        greyCard={greyCard}
        onClick={(e) => {
          e.preventDefault();
          trackingManager.trackFormButtonClick(
            {
              contentId: 'Ver horario',
            },
            dealerInfo
          );

          handleShowCalendarOnClick(point);
        }}
      >
        <Text appearance={TokenTextAppearance.label150} staticSize color={TokenTextColor.inherit}>
          Ver calendario
        </Text>
      </CustomCTA>
      {
        point.properties.dealer.dmsInfo ? (
          <div style={{ display: 'flex' }}>
            {
              point.properties.dealer.dmsInfo.replacementCarServiceAvailable ? (
                <SVGSize greyCard={greyCard}>
                  <FleetServicePrivate />
                </SVGSize>
              ) : null
            }
            {
              point.properties.dealer.dmsInfo.pickupCarServiceAvailabe ? (
                <SVGSize greyCard={greyCard}>
                  <CarPickupService />
                </SVGSize>
              ) : null
            }
          </div>
        ) : null
      }
    </Container>
  ) : (
    <Container
      verticalAlign={'flex-end'}
      horizontalAlign={'space-between'}
      padding={{ top: ContainerPadding.dynamic0050 }}
    >
      <CustomCTA
        greyCard={greyCard}
        onClick={(e) => {
          e.preventDefault();
          trackingManager.trackFormButtonClick(
            {
              contentId: 'Seleccionar',
            },
            dealerInfo
          );

          handleSeleccionarOnClick(point);
        }}
      >
        <Text appearance={TokenTextAppearance.label150} staticSize color={TokenTextColor.inherit}>
          Seleccionar
        </Text>
      </CustomCTA>
      <CTAVerMasHorasWrapper isCarousel={isCarousel}>
        <CustomTerciayCTA
          greyCard={greyCard}
          onClick={(e) => {
            e.preventDefault();
            trackingManager.trackFormButtonClick(
              {
                contentId: 'Ver más horas',
              },
              dealerInfo
            );
            handleShowCalendarOnClick(point);
          }}
        >
          <Calendar variant='small' />
          <div style={{ paddingLeft: '4px' }}>
            <Text appearance={TokenTextAppearance.label150} staticSize color={TokenTextColor.inherit}>
              Ver más horas
            </Text>
          </div>
        </CustomTerciayCTA>
      </CTAVerMasHorasWrapper>
    </Container>
  );
};

const LeadsCTAs = (props: DealerCardProps) => {
  const { handleSeleccionarOnClick, dealerInfo, point } = props;

  const trackingManager = useTrackingManager();

  return (
    <ThemeProvider theme='inverted'>
      <Container
        verticalAlign={'flex-end'}
        horizontalAlign={'space-between'}
        padding={{ top: ContainerPadding.dynamic0050 }}
      >
        <CTA
          tag='button'
          emphasis='primary'
          size='small'
          onClick={(e) => {
            e.preventDefault();
            trackingManager.trackFormButtonClick(
              {
                contentId: 'Seleccionar',
              },
              dealerInfo
            );

            handleSeleccionarOnClick(point);
          }}
        >
          Seleccionar
        </CTA>
      </Container>
    </ThemeProvider>
  );
};
