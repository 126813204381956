import { styled } from '@volkswagen-onehub/components-core';
import { keyframes } from 'styled-components';

const lineKeyframe = keyframes`
  from {
    transform: translateY(-40%);
  }
  to {
    transform: translateY(100%);
  }
`;

export const VerticalLine = styled.div`
  height: 35px;
  width: 2px;
  background-color: #FFFFFF;
  animation: ${lineKeyframe} 1.75s infinite;
`;
