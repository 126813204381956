import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, getTrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps } from 'src/types';
import { ThankYouTitle, ThankYouFields } from 'src/components';
import { useGetBrand } from 'src/feature-app/hooks';


export const ThankYouCallMeOferta = () => {
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { formResponse } = formInfo;
  const brand = useGetBrand();

  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();

  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formInfo.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: 'ThankYou' } } });

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm(formResponse)
    );
  }, []);

  const screen: Steps = {
    title: <ThankYouTitle notALayer={false} regularCopyFirst='Te llamaremos' boldCopyFirst='muy pronto' />,
    fields: <ThankYouFields copyBook='' />,
  };
  const comercialesScreen: Steps = {
    title: <ThankYouTitle notALayer={false} boldCopyFirst='¡Muchas gracias por tu interés!' />,
    fields: <ThankYouFields copyBook='En breve te llamaremos desde un número terminado en 904.' />,
  };
  return (
    <RenderController
      screenType="layer-screen"
      title={brand === 'N' ? comercialesScreen.title : screen.title}
      fields={brand === 'N' ? comercialesScreen.fields : screen.fields}
      key={555}
      avoidTrackFormStepLoad
    />
  );
};
