import React, { useEffect, useRef, useState } from 'react';
import { AutosubmitController } from 'src/forms/Autosubmit/AutosubmitController';
import { createPincodeEntity } from '../../dps-utils';
import { EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { GenericErrorLayer } from 'src/components/layers';
import { LoadingEncuestas } from 'src/components';
import { useFeatureServices, useTrackingManager } from 'src/feature-app';
import { PlanIdealForm } from './PlanIdealForm';

export function PlanIdealController() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pincodeAvailable, setPincodeAvailable] = useState(false);
  const trackingManager = useTrackingManager();
  const dpsDataRef = useRef(null);
  const idPersonRef = useRef(null);
  const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

  const handleNext = (value: any) => { };
  const handleComplete = () => {
    const dpsData = oneFormService.getDpsData();

    if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
      setPincodeAvailable(false);
      setLoading(false);
    } else {
      setPincodeAvailable(true);
      const idPerson = createPincodeEntity(dpsData, 'PERSON');

      dpsDataRef.current = dpsData;
      idPersonRef.current = idPerson;

      setLoading(false);
    }
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  const trackFormLoad = () => {
    trackingManager.trackFormLoad();
  };

  useEffect(() => {
    const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

    oneFormCoreDpsIsLoaded.subscribe({
      next(value) {
        handleNext(value);
      },
      error(err) {
        console.error(err);
        handleError();
      },
      complete() {
        handleComplete();
      },
    });
  }, []);

  if (loading) {
    return <LoadingEncuestas />;
  } else if (error) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, algo ha pasado"
          bodycopy="Por alguna razón no hemos podido cargar el cuestionario."
          ctaLayer="Reintentar"
          notShowCTA={true}
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else {
    return (
      <>
        {
          pincodeAvailable ?
            <AutosubmitController action={"newsletter-electricos-adhoc-2023-plan_ideal"} />
            :
            <PlanIdealForm />
        }
      </>
    );
  }
}
