import {
  Breakpoints,
  Container,
  ContainerPadding,
  Layout,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, TrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { FormDataFields, FormInfo, OneFormState, Steps } from 'src/types';

interface FieldProps {
  copyBook?: string;
}

interface NDPSField {
  name: string;
  value: string;
}

function Fields(props: FieldProps) {
  const { formData, formInfo } = useSelector((state: OneFormState) => state);

  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
        [Breakpoints.b1280]: [
          { name: '.', columns: 4 },
          { name: 'a', columns: 16 },
          { name: '.', columns: 4 },
        ],
        [Breakpoints.b1920]: [
          { name: '.', columns: 5 },
          { name: 'a', columns: 14 },
          { name: '.', columns: 5 },
        ],
        [Breakpoints.b2560]: [
          { name: '.', columns: 6 },
          { name: 'a', columns: 12 },
          { name: '.', columns: 6 },
        ],
      }}
    >
      <div style={{ textAlign: 'left', paddingBottom: '4px', width: '100%' }}>
        <Container padding={{ top: ContainerPadding.dynamic0100 }}>
          <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
            Las tendremos en cuenta para seguir mejorando tu experiencia Volkswagen 😊
          </Text>
        </Container>
      </div>
    </Layout>
  );
}

interface ThankYouProps {
  formData: FormDataFields;
  formInfo: FormInfo;
}

function ThankYouFidelizacion(props: ThankYouProps) {
  const { formData, formInfo } = props;
  const { formResponse } = formInfo;

  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();
  const getTrackingForm = (): TrackingForm => {
    let LeadID;
    if (formResponse && formResponse.content && formResponse.content.data && formResponse.content.data.fields) {
      const leadField: NDPSField = formResponse.content.data.fields.find(
        (field: NDPSField) => field.name === 'COD_LEAD_ID'
      );
      LeadID = leadField ? leadField.value : undefined;
    }
    return {
      LeadID,
      FormFields: undefined,
    };
  };

  useEffect(() => {
    // Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
    const screenIndexThankyou = formInfo.numberOfScreens;

    dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
    dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: "ThankYou" } } });

    trackingManager.trackFormSubmissionSuccessMessageLoad(
      {
        contentId: undefined,
      },
      getTrackingForm()
    );
  }, []);

  const screen: Steps = {
    title: (
      <>
        {' '}
        <Text bold>¡Muchas gracias por tu tiempo y tus respuestas!</Text>
      </>
    ),
    fields: <Fields />,
  };
  return (
    <RenderController
      screenType="full-screen"
      title={screen.title}
      fields={screen.fields}
      key={555}
      avoidTrackFormStepLoad
      thankYouPage={true}
    />
  );
}

const mapStateToProps = (state: OneFormState) => {
  return {
    formData: state.formData.fields,
    formInfo: state.formInfo,
  };
};

const ConnectedComponent = connect(mapStateToProps)(ThankYouFidelizacion);

export { ConnectedComponent as ThankYouFidelizacion };
