import {
  Breakpoints,
  Container,
  ContainerPadding,
  Layout,
  styled,
  Text,
  BreakpointWrapper,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { Checkmark } from 'src/icons-core-imports';

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { TIME_FOR_OPACITY_MS, TIME_FOR_TRANSFORM_MS } from 'src/globals';
import { OneFormState } from 'src/types';
import { useTrackingManager, useFeatureAppConfig, useShowOverflow  } from 'src/feature-app/hooks';
import { PreviousStep } from 'src/feature-app/Screen';
import { useOneFormContext } from '../OneForm';
import { css, keyframes } from 'styled-components';

const lineKeyframe = keyframes`
  0% {
    transform: rotate(-90deg); 
  }
  60% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const AnimatedCheckmark = styled.div`
  animation: ${lineKeyframe} 0.3s ease-in;
  svg {
    color: #ffffff;
  }
`;

interface PaddingControllerProps {
  screenIndex: number;
  multiStepScreenIndex: number;
  showPreviousStep?: boolean;
  isDealerCalendar?: boolean;
  showOverflow?: boolean;
}

const ContentCotroller = styled.div<PaddingControllerProps>`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => (!props.showPreviousStep ? '0' : '80px')};
  padding-bottom: ${(props) => (!props.showPreviousStep ? '0' : props.screenIndex === 10 ? '96px' : '128px')};
  min-height: 100vh;
  width: 100%;
  overflow: ${(props) => (props.showOverflow ? 'visible' : 'auto')};
  & input:-internal-autofill-selected {
    background-color: transparent !important;
  }
`;

interface PrevStepWrapperProps extends PaddingControllerProps {
  moveForward: boolean;
  distanceToMove: number;
  showPreviousStep: boolean;
}

const PrevStepWrapper = styled.div<PrevStepWrapperProps>`
  margin-left: ${(props) => (props.showPreviousStep ? '160px' : 'unset')};
  width: ${(props) => (props.showPreviousStep ? '640px' : 'unset')};
  padding-bottom: ${(props) =>
    props.screenIndex === 2 && props.multiStepScreenIndex === 2 ? null : props.showPreviousStep ? '72px' : '0'};
  display: flex;

  @media all and (max-height: 624px) {
    padding-bottom: ${(props) =>
    props.screenIndex === 2 && props.multiStepScreenIndex === 2 ? null : props.showPreviousStep ? '44px' : '0'};
  }

  &.prev-step-exit-active {
    ${(props) =>
    props.moveForward
      ? css`
            transform: translate3d(0, -90%, 0);
            transition: transform ${TIME_FOR_TRANSFORM_MS}, opacity ${TIME_FOR_OPACITY_MS} ease-out;
          `
      : css`
            opacity: 0.5;
            @media screen and (min-width: 960px) {
              transform: translate3d(0, 10vh, 0);
            }
            transition: all 300ms;
          `}
  }
  &.prev-step-exit-done {
    transform: ${(props) =>
    props.moveForward
      ? 'translate3d(0, -90%, 0)'
      : props.distanceToMove
        ? `translate3d(-41px, ${props.distanceToMove}px, 0)`
        : 'translate3d(-41px, 300px, 0)'};
    @media screen and (min-width: 1600px) {
      transform: ${(props) =>
    props.moveForward
      ? 'translate3d(0, -90%, 0)'
      : props.distanceToMove
        ? `translate3d(-41px, calc(${props.distanceToMove}px + 36px), 0)`
        : 'translate3d(-41px, 300px, 0)'};
    }
    ${(props) =>
    !props.moveForward &&
      css`
        width: 709px;
        * {
          font-size: var(--textappearances-headline350-fontsize);
          line-height: var(--textappearances-headline350-lineheight);
          letter-spacing: var(--textappearances-headline350-letterspacing);
          @media screen and (min-width: 1600px) {
            font-size: var(--textappearances-headline300-fontsize);
            line-height: var(--textappearances-headline300-lineheight);
            letter-spacing: var(--textappearances-headline300-letterspacing);
          }
          opacity: 1;
        }
      `}
    transition: ${(props) =>
    props.moveForward
      ? `
        transform ${TIME_FOR_TRANSFORM_MS},
        font-size ${TIME_FOR_TRANSFORM_MS},
        line-height ${TIME_FOR_TRANSFORM_MS},
        letter-spacing ${TIME_FOR_TRANSFORM_MS},
        opacity ${TIME_FOR_OPACITY_MS} ease-out
        `
      : 'all 250ms'}
  }
`;

const ContentWrapper = styled.div<PaddingControllerProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: ${(props) => (props.showOverflow ? 'visible' : 'auto')};
  @media all and (max-height: 573px) and (min-width: 960px) {
    margin-top: ${(props) => (props.screenIndex === 2 && props.multiStepScreenIndex === 1 ? '146px' : null)};
  }
  @media screen and (max-width: 959px) {
    padding-top: ${(props) => (props.isDealerCalendar ? null : props.multiStepScreenIndex === 0 ? '44px' : null)};
  }
`;

const TitleWrapper = styled.div<{ moveForward: boolean; distanceToTop: number }>`
  &.title-enter {
    transform: translate3d(0%, -50%, 0);
    opacity: 0;
    @media screen and (min-width: 960px) {
      transform: ${(props) => (props.moveForward ? 'translate3d(0%, -50%, 0)' : 'unset')};
      opacity: ${(props) => (props.moveForward ? '0' : '1')};
    }
  }
  &.title-enter-done {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: transform ${TIME_FOR_TRANSFORM_MS}, opacity ${TIME_FOR_OPACITY_MS} ease-in;
  }
  &.title-exit {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  &.title-exit-active {
    ${(props) =>
    props.moveForward
      ? css`
            opacity: 0.5;
            @media screen and (min-width: 960px) {
              transform: translate3d(41px, -10vh, 0);
              /* width previous step solo en forward move */
              width: 640px;
              * {
                font-size: var(--textappearances-headline250-fontsize);
                line-height: var(--textappearances-headline250-lineheight);
                letter-spacing: var(--textappearances-headline250-letterspacing);
              }
            }
            @media screen and (min-width: 1600px) {
              transform: translate3d(41px, -15vh, 0);
              /* width previous step solo en forward move */
              width: 640px;
              * {
                font-size: var(--textappearances-headline250-fontsize);
                line-height: var(--textappearances-headline250-lineheight);
                letter-spacing: var(--textappearances-headline250-letterspacing);
              }
            }
            transition: all 350ms;
          `
      : css`
            opacity: 0;
            transform: translate3d(0, 350%, 0);
            transition: all ${TIME_FOR_TRANSFORM_MS};
          `}
  }
  &.title-exit-done {
    opacity: ${(props) => (props.moveForward ? '0.2' : '0')};
    /* mobile */
    transform: ${(props) => (props.moveForward ? 'translate3d(0, -25vh, 0)' : 'translate3d(0, 45vh, 0)')};
    transition: all 300ms;
    /* desktop */
    @media screen and (min-width: 960px) {
      transform: ${(props) =>
    props.moveForward ? `translate3d(41px, -${props.distanceToTop}px, 0)` : 'translate3d(0, 350%, 0)'};
      /* width previous step solo en forward move */
      width: ${(props) => (props.moveForward ? '640px' : 'inherit')};
      ${(props) =>
    props.moveForward &&
        css`
          * {
            font-size: var(--textappearances-headline250-fontsize);
            line-height: var(--textappearances-headline250-lineheight);
            letter-spacing: var(--textappearances-headline250-letterspacing);
          }
        `}
      transition: ${(props) =>
    props.moveForward
      ? 'all 300ms'
      : `
      transform ${TIME_FOR_TRANSFORM_MS},
      font-size ${TIME_FOR_TRANSFORM_MS},
      line-height ${TIME_FOR_TRANSFORM_MS},
      letter-spacing ${TIME_FOR_TRANSFORM_MS},
      opacity ${TIME_FOR_OPACITY_MS} ease-out
    `};
    }
    /* @media screen and (min-width: 1600px) {
    transform: ${(props) =>
    props.moveForward ? `translate3d(41px, calc(-${props.distanceToTop}px - 36px), 0)` : 'translate3d(0, 350%), 0'};
    } */
  }
`;

const FieldsWrapper = styled.div<{ opacity: string }>`
  opacity: ${(props) => props.opacity};
  &.fields-enter {
    transform: translate3d(0, 25%, 0);
    opacity: 0;
  }
  &.fields-enter-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: transform ${TIME_FOR_TRANSFORM_MS}, opacity 300ms ease-in;
  }
  &.fields-exit {
    opacity: 0;
  }
  &.fields-exit-active {
    opacity: 0;
  }
`;

interface ScreenProps {
  title: JSX.Element;
  fields: JSX.Element;
  key: number;
  avoidTrackFormStepLoad?: boolean;
}

export function LayerScreen(props: ScreenProps) {
  const { title, fields, key, avoidTrackFormStepLoad } = props;
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { multiStepScreenIndex, screenIndex, triggerExitAnimation, showFinalScreen, lastStep } = formInfo;
  const trackingManager = useTrackingManager();
  const config = useFeatureAppConfig();
  const {
    distanceToMove,
    setDistanceToMove,
    moveForward,
    nextScreenIndex,
    nextMultiStepScreenIndex,
    showPreviousStep,
  } = useOneFormContext();

  const [showFields, setShowFields] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [opacity, setOpacity] = useState('0');

  //Si se necesita que el overflow sea visible. Selectsno nativos, suggests, etc. Añadir en la definicion del step showOverflow.

  const { showOverflow } = useShowOverflow(true);

  //para el calendario de cita-posventa-dealer

  const [isDealerCalendar, setIsDealerCalendar] = useState(false);

  useEffect(() => {
    if (config.trigger === 'cita-posventa-dealer' && screenIndex === 2) {
      setIsDealerCalendar(true);
    } else {
      setIsDealerCalendar(false);
    }
  }, [screenIndex]);

  // variables para animacion entre steps
  const [distanceToTop, setDistanceToTop] = useState<number>(0);
  const titleRef = useRef<any>(null);
  const contentRef = useRef<any>(null);

  // usar useLayoutEffect para asegurar que los cálculos de distancias se hacen antes de un nuevo render del contenido
  useEffect(() => {
    setTimeout(() => {
      if (titleRef.current && contentRef.current) {
        const titleLength = titleRef.current.innerText.length;
        // titleRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        /*
          extra height en breakpoints grandes y dependiendo de la longitud del título, que afecta a la animación
          desconozco por qué ocurre, pero no depende del viewport height, sino del viewport width
        */
        if (window.matchMedia('(min-width: 1600px)').matches && contentRef) {
          const extraHeight = titleLength < 60 ? (titleLength < 53 ? 8 : 18) : 36;
          setDistanceToTop(titleRef.current.offsetTop + extraHeight);
        } else {
          // console.log(titleRef.current.offsetTop, titleRef.current.getBoundingClientRect().top);
          const extraHeight = titleLength < 53 ? 18 : 0;
          setDistanceToTop(titleRef.current.getBoundingClientRect().top - extraHeight);
        }
        /*
          guardar distancia del título a la parte superior del contenedor padre en un array de distancias
          para saber la distancia que tiene que recorrer el previousStep si la navegación va para atrás
        */
        if ((screenIndex === 0 && nextScreenIndex === null) || screenIndex + 1 > distanceToMove.length) {
          setDistanceToMove((distanceToMove) => [...distanceToMove, titleRef.current.getBoundingClientRect().top]);
        } else if (
          screenIndex + 1 <= distanceToMove.length &&
          nextMultiStepScreenIndex !== 1 &&
          nextMultiStepScreenIndex !== 2
        ) {
          const arrDistances = distanceToMove;
          arrDistances[screenIndex] = titleRef.current.getBoundingClientRect().top;
          setDistanceToMove(arrDistances);
        }
      }
    }, 0);
  }, [titleRef]);

  useLayoutEffect(() => {
    if (triggerExitAnimation) {
      setShowTitle(false);
      setShowFields(false);
    }
  }, [triggerExitAnimation]);

  useEffect(() => {
    // Ni en la primera carga ni en la thank you page se tiene que disparar este evento.
    // El flag isFirstStep solo lo necesitamos en la primera carga, después si que se tiene que disparar el evento.
    setTimeout(() => {
      if (formInfo.step?.isFirstStep || avoidTrackFormStepLoad || (formInfo.stepsHistory?.length === 1 && (!multiStepScreenIndex || multiStepScreenIndex === 0))) {
        if(formInfo.step) formInfo.step.isFirstStep = false;
        return;
      }
      trackingManager.trackFormStepLoad(
        {
          // contentId: step.title
        },
        {
          FormStart: false,
        }
      );
    }, 0);
  }, [title]);

  useEffect(() => {
    if (window.matchMedia('(max-width: 960px)').matches && contentRef) {
      contentRef.current.scrollTo({ top: 0 });
    }
    setShowTitle(true);
  }, []);

  return (
    <ContentCotroller
      className="layer-screen"
      screenIndex={screenIndex}
      multiStepScreenIndex={multiStepScreenIndex}
      showPreviousStep={showPreviousStep}
      ref={contentRef}
      showOverflow = {showOverflow}
    >
      {showFinalScreen || !showPreviousStep ? null : (
        <BreakpointWrapper min={Breakpoints.b960}>
          <CSSTransition timeout={moveForward ? 400 : 200} in={showTitle} classNames="prev-step">
            <PrevStepWrapper
              className="prev-step-controller"
              screenIndex={screenIndex}
              multiStepScreenIndex={multiStepScreenIndex}
              moveForward={moveForward}
              distanceToMove={
                multiStepScreenIndex === 1 ? distanceToMove[screenIndex] : distanceToMove[screenIndex - 1]
              }
              showPreviousStep={showPreviousStep}
            >
              <PreviousStep />
            </PrevStepWrapper>
          </CSSTransition>
        </BreakpointWrapper>
      )}
      <ContentWrapper
        className="content-wrapper"
        screenIndex={screenIndex}
        multiStepScreenIndex={multiStepScreenIndex}
        isDealerCalendar={isDealerCalendar}
        showOverflow = {showOverflow}
      >
        <div style={{ width: '100%' }}>
          <Layout
            allowOverflowingContent
            appearance={{
              [Breakpoints.default]: [
                { name: '.', columns: 2 },
                { name: 'a', columns: 20 },
                { name: '.', columns: 2 },
              ],
              [Breakpoints.b960]: [
                { name: '.', columns: 3 },
                { name: 'a', columns: 18 },
                { name: '.', columns: 3 },
              ],
            }}
          >
            <>
              {showFinalScreen ? (
                <Container padding={{ bottom: ContainerPadding.dynamic0050 }}>
                  <AnimatedCheckmark className="animated-checkmark">
                    <Checkmark variant="default" />
                  </AnimatedCheckmark>
                </Container>
              ) : null}
              <CSSTransition
                timeout={moveForward ? 200 : 400}
                in={showTitle}
                key={key}
                classNames="title"
                onEntered={() => {
                  setTimeout(() => {
                    setShowFields(true);
                  }, 100);
                }}
                onExited={() => {}}
              >
                <TitleWrapper
                  className="text-wrapper"
                  moveForward={moveForward}
                  distanceToTop={distanceToTop}
                  ref={titleRef}
                >
                  <BreakpointWrapper max={Breakpoints.b1600}>
                    <Text appearance={TokenTextAppearance.headline300}>{title}</Text>
                  </BreakpointWrapper>
                  <BreakpointWrapper min={Breakpoints.b1600}>
                    <Text appearance={TokenTextAppearance.headline300}>{title}</Text>
                  </BreakpointWrapper>
                </TitleWrapper>
              </CSSTransition>
            </>
          </Layout>
          <CSSTransition
            timeout={300}
            in={showFields}
            key={key}
            classNames="fields"
            onEntered={() => {
              setOpacity('1');
            }}
            onExited={() => {
              setOpacity('0');
            }}
          >
            <FieldsWrapper opacity={opacity}>{fields}</FieldsWrapper>
          </CSSTransition>
        </div>
      </ContentWrapper>
    </ContentCotroller>
  );
}
