import React,{ useState } from 'react';
import { Switch } from '@volkswagen-onehub/components-core';
interface CustomSwitch {
  setSelected?: any;
  isSelected?: boolean;
  toggle?: any;
}

export const CustomSwitch = (props: CustomSwitch)  => { 
  const { setSelected, isSelected } = props;
  const [toggle, setToggle] = useState(false);

  return (
    <Switch checked={toggle}  onChange={() => {
      setToggle(!toggle);
      setSelected(!isSelected);
    }}/>
  );
};
