import {
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  styled,
} from '@volkswagen-onehub/components-core';
import React, { useRef } from 'react';
import { CarPickupService, FleetServicePrivate } from 'src/icons-core-imports';

const SmallerSVG = styled.div`
  svg {
    height: 12px;
    width: 12px;
  }
  button {
    height: 24px;
    min-height: 24px;
  }
  div {
    font-size: 12px;
  }
`;

export interface CTAServicesProps {
  handleClickCarPickup: () => void;
  handleClickReplacementCar: () => void;
  carPickupSelected: boolean;
  replacementCarSelected: boolean;
}

export function CTAServices({
  handleClickCarPickup,
  carPickupSelected,
  replacementCarSelected,
  handleClickReplacementCar,
}: CTAServicesProps) {


  const sustitucionRef = useRef(null);
  const recogidaRef = useRef(null);

  return (
    <Container
      horizontalAlign={'flex-start'}
      gutter={ContainerGutter.static200}
      padding={{ bottom: { 0: ContainerPadding.static150, 960: ContainerPadding.static200 } }}
      wrap={'never'}
    >
      <SmallerSVG>
        <CTA
          ref={sustitucionRef}
          tag="button"
          emphasis={replacementCarSelected ? 'primary' : 'secondary'}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            handleClickReplacementCar();
            sustitucionRef.current.blur();
          }}
          ariaLabel="aria label"
          icon={<FleetServicePrivate />}
        >
          Coche de sustitución
        </CTA>
      </SmallerSVG>
      <SmallerSVG>
        <CTA
          ref={recogidaRef}
          tag="button"
          emphasis={carPickupSelected ? 'primary' : 'secondary'}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            handleClickCarPickup();
            recogidaRef.current.blur();
          }}
          ariaLabel="aria label"
          icon={<CarPickupService />}
        >
          Servicio de recogida
        </CTA>
      </SmallerSVG>
    </Container>
  );
}
