import { getOneformBFFCdnServiceConfig } from 'src/feature-app';

export interface OneformBFFCdnCustomConfig {
  'X-Api-Key'?: string  
}

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving OneForm BFF CDN service config provider.');
  return null;
};

export const getOneformBFFCdnApiKey = () => {
  const oneformBFF = getOneformBFFCdnServiceConfig();
  const customConfig:OneformBFFCdnCustomConfig = oneformBFF.customConfig;
  
  if (!oneformBFF) {
    return handleErrorInRetrievingExternalConfig();
  }

  return customConfig['X-Api-Key'];
};
