import React from 'react';
import { TriggerProps, useInitiateMatchTriggers } from 'src/feature-app';
import { PresupuestoForm } from 'src/forms';
import { FormDataGroup } from 'src/types';

const leadMatchPartialData: Partial<FormDataGroup> = {
  headers: { FORM_OBJECTIVE: 'Presupuesto' },
  fields: {
    formFields: {},
    formularioFlag: 'presupuesto',
    formName: 'lead-match',
    formType: 'quote',
    pageCategory: 'Presupuesto',
  },
};

export function LeadMatchTrigger({ setSelectedJourney }: TriggerProps): JSX.Element {
  useInitiateMatchTriggers(
    setSelectedJourney,
    <PresupuestoForm isOfertasOrMatch updatedFormData={leadMatchPartialData} />
  );

  return null;
}
