import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputError } from 'src/components';
import { TextInputExtended } from './TextInputExtended';
import { useOnlyLettersControl } from './use-only-letters-control';
import { useDispatch } from 'react-redux';

interface ApellidoProps {
  name?: 'surname' | 'secondSurname';
  label?: string;
  required?: boolean;
  setFieldValue?: boolean;
}

export function Apellido(props: ApellidoProps) {
  const { register, errors } = useFormContext();
  const { name, label, required, setFieldValue } = props;
  const [inputValue, setInputValue] = useState('');
  const [pristine, setPristine] = useState(true);
  const { error, regExp } = useOnlyLettersControl(pristine, inputValue);
  const dispatch = useDispatch();

  const innerRef = required
    ? register({
      required: {
        value: true,
        message: 'Requerido',
      },
      pattern: {
        value: regExp,
        message: 'Solo se aceptan letras',
      },
    })
    : register;

  const handleChange = (e: any) => {
    if (setFieldValue && !error) {
      dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: e.target.value } });
    }
    setInputValue(e.target.value);
    setPristine(false);
  };

  return (
    <div>
      <TextInputExtended
        name={name ? name : 'apellido'}
        label={label ? label : 'Apellido'}
        isFloating
        innerRef={innerRef}
        type="text"
        onChange={(e) => handleChange(e)}
        required={required}
      />
      {error && <InputError className="input-error">Solo se aceptan letras</InputError>}
    </div>
  );
}
