import React from 'react';
import { styled, Text, TextAlignment, TokenTextAppearance } from '@volkswagen-onehub/components-core';

const CustomInputRange = styled.input<{ numberOfSteps: number }>`
  width: 100%;
  height: 24px;
  background: transparent;
  font-size: 1em;
  cursor: pointer;
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  &::-webkit-slider-runnable-track {
    position: relative;
    width: 100%;
    height: 1px;
    background: #00b0f0;
  }
  &::-moz-range-track {
    width: 100%;
    height: 1px;
    background: #00b0f0;
  }
  &::-ms-track {
    border: none;
    width: 100%;
    height: 1px;
    background: #00b0f0;
    color: transparent;
  }
  &::-ms-fill-lower {
    display: none;
  }
  &::-webkit-slider-thumb {
    margin-top: -4px;
    box-sizing: border-box;
    width: calc(100% / ${(props) => props.numberOfSteps});
    height: 8px;
    border-radius: 4px;
    background: #ffffff;
  }
  &::-moz-range-thumb {
    box-sizing: border-box;
    width: calc(100% / ${(props) => props.numberOfSteps});
    height: 8px;
    border-radius: 4px;
    background: #ffffff;
  }
  &::-ms-thumb {
    box-sizing: border-box;
    width: calc(100% / ${(props) => props.numberOfSteps});
    height: 8px;
    border-radius: 4px;
    background: #ffffff;
  }
  &::-webkit-slider-runnable-track:before,
  &::-webkit-slider-runnable-track:after,
  & /deep/ #track:before,
  &/deep/ #track:after {
    position: absolute;
    bottom: 100%;
    color: #2c2c2b;
    font: 700 0.875em / 2 arial, sans-serif;
    background: #ffffff;
  }
  &::-webkit-slider-runnable-track:before,
  & /deep/ #track:before {
    left: 0.8125em;
    word-spacing: 1.4375em;
    content: '1 2 3 4 5 6 7 8 9';
  }
  &::-webkit-slider-runnable-track:after,
  & /deep/ #track:after {
    right: 0.625em;
    word-spacing: 0.875em;
    content: '10 11 12 13 14 15 16';
  }
  &:focus {
    outline: none;
  }
`;
const Mark = styled.div`
  height: 9px;
  width: 1px;
  background-color: #ffffff;
`;
const MarksPosition = styled.div`
  display: flex;
  display: -webkit-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -16.5px;
  z-index: 3;
  margin-left: 7px;
  @media screen and (min-width: 960px) {
    margin-left: 4px;
  }
`;
const SliderPosition = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 7px;
  @media screen and (min-width: 960px) {
    margin-left: 4px;
  }
`;
const SlideLabelsPosition = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SliderAndMarksPosition = styled.div`
  width: 96%;
  padding-right: 20px;
  @media all and (min-width: 960px) {
    width: 97%;
  }
`;
interface CustomSliderProps {
  handleInputChange: any;
  value: number;
  formatNumber?: (num: number | number[]) => string;
  maxValue: number;
  numberOfSteps: number;
}

export const CustomSlider = ({
  value,
  handleInputChange,
  numberOfSteps,
  formatNumber,
  maxValue,
}: CustomSliderProps) => {
  const sliderMarks = [];

  for (let i = 0; i < numberOfSteps + 1; i++) {
    sliderMarks.push(<Mark />);
  }

  document.documentElement.classList.add('js');

  return (
    <>
      <SliderAndMarksPosition className="slider-nad-marks-wrapper">
        <MarksPosition className="marks-position">{sliderMarks ? sliderMarks : null}</MarksPosition>
        <SliderPosition className="slider-position">
          <CustomInputRange
            numberOfSteps={numberOfSteps}
            type="range"
            min="1"
            max={numberOfSteps}
            step="1"
            id="rangeInput"
            name="rangeInput"
            onChange={(e) => handleInputChange(e)}
            value={value}
          />
        </SliderPosition>
      </SliderAndMarksPosition>
      <SlideLabelsPosition className="labels-position">
        <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.left}>
          0km
        </Text>
        <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.right}>
          {'+'}
          {formatNumber(maxValue)}km
        </Text>
      </SlideLabelsPosition>
    </>
  );
};
