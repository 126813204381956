import React, { useEffect } from 'react';
import { TriggerProps, useFaContent } from 'src/feature-app';
import { CallMeOfertaForm } from 'src/forms';

export function CallMeOfertasTrigger({ setSelectedJourney }: TriggerProps): JSX.Element {
  const faContent = useFaContent();
  const getCtaLabel = () => (faContent?.cta ? faContent.cta : 'Llámame');

  useEffect(() => {
    setSelectedJourney(<CallMeOfertaForm />, true);
  }, []);

  return null;

}
