/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import { styled, Select, Text, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';
import { useSelector } from 'react-redux';
import { OneFormState } from 'src/types';
import { useDispatch } from 'react-redux';
import { useOneFormContext, useTrackingManager } from 'src/feature-app';
import { SelectExtendedList } from './CarlineSelect';
import { useFormContext } from 'react-hook-form';

type WrapperProps = {
  disabled?: boolean;
  themeColor?: string;
};

const SelectWrapper = styled.div<WrapperProps>`

  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  & span::before {
   display: none;
  }
  svg {
    fill: ${(props) => (props.themeColor === 'inverted' ? '#ffffff' : '#001E50')};
  }
  option {
    color: #001e50;
    padding-left: 12px;
  }
  }
`;
const SelectOption = styled.option``;

interface SelectProps {
  options: Array<any>;
  name: string;
  label: string;
  value?: any;
  defaultValue?: string;
  isFloating?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  getDealers?: (provincia: string) => void;
  setFormValue?: (selValueIndex: number) => void;
  onClick?: (e?: any) => void;
  setOption?: React.Dispatch<React.SetStateAction<any>>;
  updateFields?: boolean;
  handleChange: any;
  isRequired: boolean;
  innerRef?: any;
}

export const SelectExtended = React.forwardRef((props: SelectProps, ref: React.Ref<any>) => {
  const [selItem, setSelItem] = useState<string | null>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [keyValuePair, setKeyValuePair] = useState();
  const { name, options, setOption, updateFields, value, handleChange, isRequired, label, defaultValue, isFloating, innerRef } = props;
  const wrapperRef = useRef<HTMLHeadingElement>(null);
  const dispatch = useDispatch();
  const trackingManager = useTrackingManager();
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { setValue } = useFormContext();

  const handleClickOutside = (e: any) => {
    if (!wrapperRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  const setItem = (option: any, index: number) => {
    if (!props.disabled) {
      if (typeof option !== 'object') {
        setSelItem(option);
        if (setOption) setOption(option);
      } else {
        const dealerName = option.props.children[0].props.children;
        setSelItem(dealerName);
      }
      if (props.setFormValue) props.setFormValue(index);
      setShowOptions(false);
    }
  };

  useEffect(() => {
    setSelItem(null);
  }, [props.options]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    // función que se llama cuando la componente se desmonta
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef.current]);

  useEffect(() => {
    updateFields
      ? dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: keyValuePair ? keyValuePair : value } })
      : undefined;
  }, [keyValuePair, value]);

  useEffect(() => {
    if (value) {
      setTimeout(() => {
        setSelItem(value);
      }, 0);
    }
  }, [value]);

  return (
  <Text appearance={TokenTextAppearance.copy200} color={TokenTextColor.secondary}>
    <SelectWrapper
      onClick={() => {
        trackingManager.trackFormFieldClick(
          {
            contentId: label,
          },
          {
            FormFieldName: name,
          }
        );
        props.onClick();
      }}
      disabled={props.disabled}
      ref={wrapperRef}
      themeColor={formInfo.formTheme}
    >
      <Select
        label={label}
        isFloating={isFloating}
        value={value}
        name={name}
        onChange={(event) => {
          event.preventDefault();
          handleChange(event);
        }}
        required={isRequired}
        autoFocus={false}
        ref={innerRef}
      >
        {isFloating && <SelectOption value="" disabled>{label}</SelectOption>}
        {options.map((option: any, index: number) => {
          if (typeof option === 'string') {
            return (
              <SelectOption
                onClick={(e) => {
                  e.preventDefault();
                  setItem(option, index);
                }}
                key={index}
                value={option}
              >
                {option}
              </SelectOption>
            );
          } else {
            return (
              <option
                onClick={(e) => {
                  e.preventDefault();
                  setItem(option.name, index);
                  setKeyValuePair(option.code);
                }}
                key={index}
                value={option.name}
              >
                {option.name}
              </option>
            );
          }
        })}
      </Select>
    </SelectWrapper>
  </Text>
  );
});
