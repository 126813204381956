import React from 'react';
import { Text, styled, TextAlignment, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';

const Mark = styled.span`
  display: inline-block;
  position: relative;
  border: 1px solid #001e50;
  width: 24px;
  height: 24px;
  left: 0;
  border-radius: 50%;
  margin-right: 12px;
  vertical-align: middle;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #001e50;
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`;

const RadioButtonWrapper = styled.label`
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  color: #001e50;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    visibility: hidden;
    display: none;
    &:checked + ${Mark} {
      &::after {
        width: 12px;
        height: 12px;
        opacity: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

interface radioButtonProps {
  onClick?: any;
  inputName?: string;
  inputValue?: string;
  checked?: boolean;
}

export const CustomRadioButton = (props: radioButtonProps) => {
  return (
    <RadioButtonWrapper onClick={props.onClick}>
      <input type="radio" name={props.inputName} value={props.inputValue} checked={props.checked} />
      <Mark />
      <Text appearance={TokenTextAppearance.copy200} staticSize color={TokenTextColor.inherit} textAlign={TextAlignment.left}>
        {props.inputValue}
      </Text>
    </RadioButtonWrapper>
  );
};
