import { GooglePlace, Suggestion } from 'src/feature-app';
import { DealersData, isDealerData, Points, Center } from 'src/types';
import getDistance from 'geolib/es/getDistance';
import { Dispatch } from 'react';
import { Geometry, Installation } from 'src/types';
import { store } from '../App';

export const isGooglePlace = (suggestion: Suggestion): suggestion is GooglePlace => {
  if (suggestion) {
    return (suggestion as GooglePlace).isGooglePlace !== undefined;
  }
  return null;
};

const getCanaryBounds = () => {
  const result: google.maps.LatLngBounds = new window.google.maps.LatLngBounds(null);
  const north = 29.463514;
  const east = -13.31543;
  const south = 27.425414;
  const west = -18.391113;
  const ne = new window.google.maps.LatLng(north, east);
  const sw = new window.google.maps.LatLng(south, west);
  result.extend(ne);
  result.extend(sw);

  return result;
};

export const getLocationOfDealer = (suggestion: DealersData): Geometry => {
  const bounds: google.maps.LatLngBounds = new window.google.maps.LatLngBounds(null);
  let lat, lng;
  if (isDealerData(suggestion)) {
    lat = suggestion.markerInfo.svcLocation.latitude;
    lng = suggestion.markerInfo.svcLocation.longitude;
  } else {
    lat = Number((suggestion as Installation).ShippingLatitude);
    lng = Number((suggestion as Installation).ShippingLongitude);
  }
  const point: google.maps.LatLng = new window.google.maps.LatLng(lat, lng);
  bounds.extend(point);
  return {
    lat,
    lng,
    bounds: null,
    viewport: null,
  };
};

export const getLocationOfDealerWithoutLoadInstance = (suggestion: Suggestion, maps: google.maps): Geometry => {
  const bounds: google.maps.LatLngBounds = new maps.LatLngBounds(null);
  let lat, lng;
  if (isDealerData(suggestion)) {
    lat = suggestion.markerInfo.svcLocation.latitude;
    lng = suggestion.markerInfo.svcLocation.longitude;
  } else {
    lat = Number((suggestion as Installation).ShippingLatitude);
    lng = Number((suggestion as Installation).ShippingLongitude);
  }
  const point: google.maps.LatLng = new maps.LatLng(lat, lng);
  bounds.extend(point);
  return {
    lat,
    lng,
  };
};

const getBounds = (latlngBounds: google.maps.LatLngBounds): google.maps.LatLngBounds => {
  const result: google.maps.LatLngBounds = new window.google.maps.LatLngBounds(null);
  const north = latlngBounds.getNorthEast().lat();
  const east = latlngBounds.getNorthEast().lng();
  const south = latlngBounds.getSouthWest().lat();
  const west = latlngBounds.getSouthWest().lng();
  const ne = new window.google.maps.LatLng(north, east);
  const sw = new window.google.maps.LatLng(south, west);
  result.extend(ne);
  result.extend(sw);

  return result;
};

const getGeometryFromGoogleResult = (result: any): Geometry => {
  let viewport = null;
  let bounds = null;
  if (result.geometry) {
    if (result.geometry.viewport) {
      viewport = getBounds(result.geometry.viewport);
    } else if (result.geometry.bounds) {
      bounds = getBounds(result.geometry.bounds);
    }

    const lat = result.geometry.location.lat();
    const lng = result.geometry.location.lng();

    return { lat, lng, bounds, viewport };
  } else {
    return { lat: null, lng: null };
  }
};

interface GeocodeCache {
  suggestion: GooglePlace;
  geometry: Geometry;
  isAddress: boolean;
  isCanaryIslands: boolean;
}

// const cache: GeocodeCache[] = [];

const resultIsAddress = (result: google.maps.GeocoderResult) => {
  const addressTypes = [
    'route',
    'street_address',
    'intersection',
    'neighborhood',
    'premise',
    'subpremise',
    'park',
    'point_of_interest',
    'establishment',
    'tourist_attraction',
  ];
  const resultType = result.types[0];

  return addressTypes.includes(resultType);
};

export const getGeocode = (suggestion: GooglePlace): Promise<Geometry> | Geometry => {
  // Se desactiva el cache porque da error en mobile
  // const cachedResult = cache.find(cachedResult => cachedResult.suggestion.name === suggestion.name);

  // if (cachedResult) {
  //   console.log(cachedResult,'cache');
  //   store.dispatch({ type: 'SUGGESTION_IS_ADDRESS', payload: cachedResult.isAddress });
  //   store.dispatch({ type: 'SUGGESTION_IS_IN_CANARY_ISLANDS', payload: cachedResult.isCanaryIslands });
  //   return new Promise((resolve)=>resolve(cachedResult.geometry));
  // }

  const geocode = new window.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    return geocode.geocode(
      { address: suggestion.name },
      (response: any) => {
        const result: google.maps.GeocoderResult = response[0];
        const geometry = getGeometryFromGoogleResult(result);
        const point: google.maps.LatLng = new window.google.maps.LatLng(geometry.lat, geometry.lng);
        const bounds: google.maps.LatLngBounds = getCanaryBounds();
        let isAddress: boolean;
        let isCanaryIslands: boolean;
        isAddress = resultIsAddress(result);
        isCanaryIslands = bounds.contains(point);

        store.dispatch({ type: 'SUGGESTION_IS_ADDRESS', payload: isAddress });
        store.dispatch({ type: 'SUGGESTION_IS_IN_CANARY_ISLANDS', payload: isCanaryIslands });
        store.dispatch({ type: 'UPDATE_USER_GEOLOCATION', payload: null });

        // cache.push({ suggestion, geometry, isAddress, isCanaryIslands });

        resolve(geometry);
      },
      () => reject('Problems with geocode')
    );
  });
};

interface ReverseGeocodeResponse {
  formatted_address: string;
}

export const getReverseGeocode = (latlng: {
  lat: number;
  lng: number;
}): Promise<{ geometry: Geometry; name: string }> => {
  const geocode = new window.google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    return geocode.geocode(
      { location: latlng },
      (response: ReverseGeocodeResponse[]) => {
        const result = response[0];
        const geometry = getGeometryFromGoogleResult(result);
        let name: string = response.length > 0 ? response[0].formatted_address : 'España'; // Se devuelve el primero porque no hay manera de filtrar el "mejor" resultado.
        resolve({ geometry, name });
      },
      () => reject('Problems with geocode')
    );
  });
};

export const getLocationOfGooglePlace = async (suggestion: GooglePlace): Promise<Geometry> => {
  const geometry = await getGeocode(suggestion);
  return geometry;
};

export const getDistanceFromCenter = (center: Center, point: Points) => {
  const lng = point.geometry.coordinates[0];
  const lat = point.geometry.coordinates[1];
  const distance = getDistance({ lat: center.lat, lng: center.lng }, { lat, lng }) / 1000;
  return distance.toFixed(1);
};

export const getDistanceFromLatLngCenter = (center: google.maps.LatLng, point: Points) => {
  if (!center || !point) {
    return '0';
  }
  const lng = point.geometry.coordinates[0];
  const lat = point.geometry.coordinates[1];
  const distance = getDistance({ lat: center.lat(), lng: center.lng() }, { lat, lng }) / 1000;
  const toFixed = Math.round(distance).toString().length > 2 ? 0 : 1; // Para más de 100km devolvemos el número sin decimal.
  return distance.toFixed(toFixed);
};

export const handleMapVariables = (
  dispatch: Dispatch<any>,
  center: Center,
  zoom: number,
  geometry: Geometry,
  isDealer: boolean,
  suggestion: Suggestion
) => {
  dispatch({ type: 'UPDATE_USER_CENTER', payload: { lat: center.lat, lng: center.lng } }); // Eliminar
  dispatch({ type: 'UPDATE_INITIAL_ZOOM', payload: zoom }); // Eliminar
  dispatch({ type: 'UPDATE_GEOMETRY', payload: geometry });
  dispatch({ type: 'UPDATE_SUGGESTION', payload: suggestion ? suggestion : null });
  dispatch({ type: 'SUGGESTION_IS_DEALER', payload: isDealer });
  dispatch({ type: 'UPDATE_FIELDS', payload: { location: suggestion.name } }); // No se si se usa, de momento lo dejo.
};

export const updateMapVariables = async (dispatch: Dispatch<any>, suggestion: Suggestion, geometryData?: Geometry) => {
  let geometry: Geometry = geometryData ? geometryData : null;
  let isDealer: boolean;
  if (!window.google || !window.google.maps) {
    return;
  }
  if (!geometryData) {
    geometry = isGooglePlace(suggestion) ? await getLocationOfGooglePlace(suggestion) : getLocationOfDealer(suggestion);
  }
  isDealer = !isGooglePlace(suggestion);
  if ((suggestion as GooglePlace).isGeoLocated) {
    dispatch({ type: 'UPDATE_USER_GEOLOCATION', payload: suggestion });
  }
  dispatch({ type: 'UPDATE_GEOMETRY', payload: geometry });
  dispatch({ type: 'UPDATE_SUGGESTION', payload: suggestion ? suggestion : null });
  dispatch({ type: 'SUGGESTION_IS_DEALER', payload: isDealer });
};

export const removeWhiteSpace = (s: string): string => (s ? s.replace(/\s/g, '') : s);
export const formatPrefix = (s: string): string => s ? s : '';
export const formatPrefixOneFormBFF = (s: string): string => s ? s.replace("+","00") : '';

export const checkIfMorning = (slotHour: Date) => {
  const morningHour = getNormalizedDate('12:00:00');
  return slotHour <= morningHour ? true : false;
};

export const checkIfMidDay = (slotHour: Date) => {
  const morningHour = getNormalizedDate('12:00:00');
  const eveningHour = getNormalizedDate('16:00:00');

  return slotHour > morningHour && slotHour <= eveningHour ? true : false;
};

export const checkIfEvening = (slotHour: Date) => {
  const eveningHour = getNormalizedDate('16:00:00');
  return slotHour > eveningHour ? true : false;
};

/**
 * hour en formato 12:00:00
 * @param hour
 */
export const getNormalizedDate = (hour: string) => new Date(`1/1/1999 ${hour}`);
