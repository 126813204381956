import { DealersInfo } from 'src/types';
import { dealersInfo } from './';
import {
  DealersActionTypes,
  DEALERS_OR_INSTALLATIONS_MODE,
  SET_CARLINES,
  SET_CITA_CARLINES,
  SET_DEALERS,
  SET_INSTALLATIONS,
  SET_OFERTA,
  SET_FAVOURITE_DEALER,
  SET_POINTS,
  SET_SALESGROUP,
  SET_SELECTED_CARLINE,
  SET_SELECTED_DEALER,
  SET_SELECTED_INSTALLATION,
  SET_URL_DEALER,
  SET_MATCH,
} from './dealers-types';
import { OneFormActionTypes, RESET_STATE } from './types';

export function dealersReducer(state: DealersInfo = null, action: OneFormActionTypes | DealersActionTypes): any {
  switch (action.type) {
    // case RESET_STATE:
    //   return { ...dealersInfo };
    case SET_DEALERS:
      return {
        ...state,
        dealers: [...action.payload],
      };
    case SET_INSTALLATIONS:
      return {
        ...state,
        installations: [...action.payload],
      };
    case SET_POINTS:
      return {
        ...state,
        points: [...action.payload],
      };
    case SET_CARLINES:
      return {
        ...state,
        carlines: [...action.payload],
      };
    case SET_CITA_CARLINES:
      return {
        ...state,
        citaCarlines: [...action.payload],
      };
    case SET_SELECTED_CARLINE:
      return {
        ...state,
        selectedCarline: action.payload,
      };
    case SET_SELECTED_DEALER:
      return {
        ...state,
        selectedDealer: action.payload,
      };
    case SET_URL_DEALER:
      return {
        ...state,
        urlDealer: action.payload,
      };
    case SET_FAVOURITE_DEALER:
      return {
        ...state,
        favouriteDealer: action.payload,
      };
    case DEALERS_OR_INSTALLATIONS_MODE:
      return {
        ...state,
        dealersOrInstallationsMode: action.payload,
      };
    case SET_SELECTED_INSTALLATION:
      return {
        ...state,
        selectedInstallation: action.payload,
      };
    case SET_OFERTA:
      return {
        ...state,
        oferta: action.payload,
      };
    case SET_MATCH:
      return {
        ...state,
        match: action.payload,
      };
    case SET_SALESGROUP:
      return {
        ...state,
        salesGroup: action.payload,
      };
    default:
      return state;
  }
}
