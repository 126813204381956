import React from 'react';
import { MultipleCTASelection } from 'src/components';
import {
  Container,
  ContainerGutter,
  Text,
  TextAlignment,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { CTAsSiNo } from 'src/feature-app/InputsValues/CTAsObjects';

export const YaTienesVolkswagen = () => {
  return (
    <Container gutter={ContainerGutter.static400} wrap={'always'}>
      <MultipleCTASelection ctaValues={CTAsSiNo} name='cliente' ctaWidth='81' nextOnClick />
      <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left} staticSize>
        <sup >1</sup>{' '}Recuerda que para participar en el sorteo debes formar parte de la familia Volkswagen. 
      </Text>
    </Container>
  );
};
