export const callingCountriesOwn = [
  ['+247'],
  ['+376'],
  ['+971'],
  ['+93'],
  ['+1 268'],
  ['+1 264'],
  ['+355'],
  ['+374'],
  ['+244'],
  ['+672'],
  ['+54'],
  ['+1 684'],
  ['+43'],
  ['+61'],
  ['+297'],
  ['+358'],
  ['+994'],
  ['+387'],
  ['+1 246'],
  ['+880'],
  ['+32'],
  ['+226'],
  ['+359'],
  ['+973'],
  ['+257'],
  ['+229'],
  ['+590'],
  ['+1 441'],
  ['+673'],
  ['+591'],
  ['+599'],
  ['+55'],
  ['+1 242'],
  ['+975'],
  ['+267'],
  ['+375'],
  ['+501'],
  ['+1'],
  ['+61'],
  ['+243'],
  ['+236'],
  ['+242'],
  ['+41'],
  ['+225'],
  ['+682'],
  ['+56'],
  ['+237'],
  ['+86'],
  ['+57'],
  ['+506'],
  ['+53'],
  ['+238'],
  ['+599'],
  ['+61'],
  ['+357'],
  ['+420'],
  ['+49'],
  ['+253'],
  ['+45'],
  ['+1 767'],
  ['+1 809', '+1 829', '+1 849'],
  ['+213'],
  ['+593'],
  ['+372'],
  ['+20'],
  ['+212'],
  ['+291'],
  ['+34'],
  ['+251'],
  ['+388'],
  ['+358'],
  ['+679'],
  ['+500'],
  ['+691'],
  ['+298'],
  ['+33'],
  ['+241'],
  ['+241'],
  ['+44'],
  ['+473'],
  ['+995'],
  ['+594'],
  ['+44'],
  ['+233'],
  ['+350'],
  ['+299'],
  ['+220'],
  ['+224'],
  ['+590'],
  ['+240'],
  ['+30'],
  ['+502'],
  ['+1 671'],
  ['+245'],
  ['+592'],
  ['+852'],
  ['+504'],
  ['+385'],
  ['+509'],
  ['+36'],
  ['+62'],
  ['+353'],
  ['+972'],
  ['+44'],
  ['+91'],
  ['+246'],
  ['+964'],
  ['+98'],
  ['+354'],
  ['+39'],
  ['+44'],
  ['+1 876'],
  ['+962'],
  ['+81'],
  ['+254'],
  ['+996'],
  ['+855'],
  ['+686'],
  ['+269'],
  ['+1 869'],
  ['+850'],
  ['+82'],
  ['+965'],
  ['+1 345'],
  ['+7', '+7 6', '+7 7'],
  ['+856'],
  ['+961'],
  ['+1 758'],
  ['+423'],
  ['+94'],
  ['+231'],
  ['+266'],
  ['+370'],
  ['+352'],
  ['+371'],
  ['+218'],
  ['+212'],
  ['+377'],
  ['+373'],
  ['+382'],
  ['+590'],
  ['+261'],
  ['+692'],
  ['+389'],
  ['+223'],
  ['+95'],
  ['+976'],
  ['+853'],
  ['+1 670'],
  ['+596'],
  ['+222'],
  ['+1 664'],
  ['+356'],
  ['+230'],
  ['+960'],
  ['+265'],
  ['+52'],
  ['+60'],
  ['+258'],
  ['+264'],
  ['+687'],
  ['+227'],
  ['+672'],
  ['+234'],
  ['+505'],
  ['+31'],
  ['+47'],
  ['+977'],
  ['+674'],
  ['+683'],
  ['+64'],
  ['+968'],
  ['+507'],
  ['+51'],
  ['+689'],
  ['+675'],
  ['+63'],
  ['+92'],
  ['+48'],
  ['+508'],
  ['+872'],
  ['+1 787', '+1 939'],
  ['+970'],
  ['+351'],
  ['+680'],
  ['+595'],
  ['+974'],
  ['+262'],
  ['+40'],
  ['+381'],
  ['+7', '+7 3', '+7 4', '+7 8'],
  ['+250'],
  ['+966'],
  ['+677'],
  ['+248'],
  ['+249'],
  ['+46'],
  ['+65'],
  ['+290'],
  ['+386'],
  ['+47'],
  ['+421'],
  ['+232'],
  ['+378'],
  ['+221'],
  ['+252'],
  ['+597'],
  ['+211'],
  ['+239'],
  ['+503'],
  ['+1 721'],
  ['+963'],
  ['+268'],
  ['+290'],
  ['+1 649'],
  ['+235'],
  ['+228'],
  ['+66'],
  ['+992'],
  ['+690'],
  ['+670'],
  ['+993'],
  ['+216'],
  ['+676'],
  ['+90'],
  ['+1 868'],
  ['+688'],
  ['+886'],
  ['+255'],
  ['+380'],
  ['+256'],
  ['+1'],
  ['+1'],
  ['+598'],
  ['+998'],
  ['+379', '+39'],
  ['+1 784'],
  ['+58'],
  ['+1 284'],
  ['+1 340'],
  ['+84'],
  ['+678'],
  ['+681'],
  ['+685'],
  ['+383'],
  ['+967'],
  ['+262'],
  ['+27'],
  ['+260'],
  ['+263'],
];
