import React, { useEffect } from 'react';
import { Text } from '@volkswagen-onehub/components-core';
import { OneElementTemplate } from 'src/feature-app/Screen';
import { DetalleMotivo, MultipleCTASelection } from 'src/components';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { FormController, useOneFormContext, removeWhiteSpace, useFaContent, } from 'src/feature-app';
import { formatHeaders, formDataBuilder } from 'src/forms/format-fields';
import { addRemovedStep, getIndexInSteps, removeStep, resetStepsScreenIndex } from 'src/forms/helpers/add-remove-steps';
import { CTAsSiNo } from 'src/feature-app/InputsValues/CTAsObjects';
import { ThankYouFeedback, ConfirmacionFeedback } from '.';

export enum FeedbackSteps {
	Opinion,
	Mejora,
	HasError,
	Error,
	Confirmacion,
}

interface FeedbackFormProps {
	rating: number;
	hideStars?: any;
}

export function FeedbackForm({ rating, hideStars }: FeedbackFormProps) {
	const { initializeForm, handleNextStep } = useOneFormContext();
	const { formData, formInfo } = useSelector((state: OneFormState) => state);
	const store = useStore();
	const faContent = useFaContent();
	const dispatch = useDispatch();

	useEffect(() => {
		sendForm(true); // Presubmit
	}, []);

	const { hasError } = formData.fields;

	const steps: Steps[] = [
		{
			title: (
				<>
					¿Qué te <Text bold>ha parecido?</Text>
				</>
			),
			fields: <OneElementTemplate element={<DetalleMotivo hideCTA required name="opinion" />} />,
			screenIndex: FeedbackSteps.Opinion,
			name: 'Opinion',
			outputs: ['opinion'],
			outputsText: ['opinion'],
			trueOrFalseQuestion: true,
		},
		{
			title: (
				<>
					¿Hay algo que hayas <Text bold>echado de menos?</Text>
				</>
			),
			fields: <OneElementTemplate element={<DetalleMotivo hideCTA required name="mejora" />} />,
			screenIndex: FeedbackSteps.Mejora,
			name: 'Mejora',
			outputs: ['mejora'],
			outputsText: ['mejora'],
			trueOrFalseQuestion: true,
		},
		{
			title: (
				<>
					¿Has detectado <Text bold>algún error?</Text>
				</>
			),
			fields: (
				<OneElementTemplate
					element={<MultipleCTASelection ctaValues={CTAsSiNo} name="hasError" nextOnClick ctaWidth="81" />}
				/>
			),
			screenIndex: FeedbackSteps.Error,
			name: 'HasError',
			outputs: ['hasError'],
			outputsText: ['hasError'],
			trueOrFalseQuestion: true,
		},
		{
			title: (
				<>
					¿Puedes decirnos <Text bold>cuál?</Text>
				</>
			),
			fields: <OneElementTemplate element={<DetalleMotivo hideCTA required name="error" />} />,
			screenIndex: FeedbackSteps.Error,
			name: 'Error',
			outputs: ['error'],
			outputsText: ['error'],
			trueOrFalseQuestion: true,
		},
		{
			fields: <ConfirmacionFeedback />,
			screenIndex: FeedbackSteps.Confirmacion,
			name: 'Confirmacion',
			outputs: ['confirmacion'],
			outputsText: ['confirmacion'],
		},
	];

	useEffect(() => {
		if (hasError) {
			updateSteps('Error', hasError == 'true');
		}
	}, [hasError]);

	const updateSteps = (stepName: string, conditional: boolean) => {
		const { steps: actualSteps, screenIndex } = store.getState().formInfo;
		let newSteps = [...actualSteps];
		if (conditional) {
			const stepIndex = getIndexInSteps(newSteps, stepName);

			if (stepIndex === -1) {
				newSteps = addRemovedStep(steps, actualSteps, stepName, screenIndex);
			}
		} else {
			newSteps = removeStep(newSteps, stepName);
		}

		const newStepsWithIndex = resetStepsScreenIndex(newSteps);

		dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
		dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
		dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
	};

	const NuevoFormData: Partial<FormDataGroup> = {
		headers: {},
		fields: {
			formName: 'feedback',
			formType: 'otherform',
			pageCategory: 'inPage',
		}, // Analítica
	};

	const getFormInfo = (): {
		firstStep: Steps;
		lastStep: Steps;
		stepsHistory: number[];
		numberOfScreens: number;
		newSteps?: Steps[];
	} => {
		return {
			firstStep: steps[0],
			lastStep: steps[steps.length - 1],
			stepsHistory: [0],
			numberOfScreens: steps.length,
			newSteps: null,
		};
	};

	const sendForm = (isPresubmit?: boolean): Promise<boolean> => {
		const { formData }: OneFormState = store.getState();
		const { fields, headers } = formData;
		const formName = fields.formName ? fields.formName : 'feedback';

		return new Promise<any>(async (resolve, reject) => {
			const { tc, today, url, device } = getParamsForm();

			const data = {
				'headers[DPS_TOKEN]': 'VW_DDB_FRM_FEEDBACK_ONEHUB',
				'headers[FORM_OBJECTIVE]': 'Feedback',
				'headers[FORM_DETAILS]': faContent.feedback,
				'headers[FORM_NAME]': `vw-oneform-${formName}-` + faContent.feedback.toLowerCase(),
				'headers[FORM_URL]': url,
				'headers[FORM_DATE_INSERTION]': today,
				'headers[ORIGIN_DEVICE_TYPE]': device,
				'headers[ORIGIN_TRACKING_CODE]': tc,
				'headers[FK_SYSTEM]': fields.climaproId ? fields.climaproId : 'PENDIENTE',

				...formatHeaders(headers),

				'fields[DPS_BRAND]': 'Volkswagen',
				'fields[PERSON_NAME]': fields.name,
				'fields[PERSON_SURNAME_1]': fields.surname,
				'fields[PERSON_SURNAME_2]': fields.secondSurname,
				'fields[PERSON_EMAIL]': removeWhiteSpace(fields.email),
				'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
				'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
				'fields[SURVEY_ANSWER_01]': rating,
				'fields[SURVEY_ANSWER_02]': fields.opinion,
				'fields[SURVEY_ANSWER_03]': fields.mejora,
				'fields[SURVEY_ANSWER_04]': fields.hasError,
				'fields[SURVEY_ANSWER_05]': fields.error,
				'fields[SURVEY_ANSWER_06]': fields.identitykitId ? fields.identitykitId : 'PENDIENTE',

				'flags[PERSON_RGPD_ADAPTED]': 'true',
			};

			const dataStringified = JSON.stringify(data);
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);

			const response = await oneformBFFNdpsSend(formData);

			if (response?.data?.status?.code == 200) {
				if (!isPresubmit) {
					hideStars();
				}
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				resolve(true);
			} else {
				resolve(false);
			}
		});
	};

	useEffect(() => {
		const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

		const NuevoFormInfo: Partial<FormInfo> = {
			formStarted: true,
			sendFormCallback: sendForm,
			fullScreen: firstStep.fullScreen ? true : false,
			lastStep,
			numberOfScreens,
			formTheme: 'inverted',
			screenIndex: firstStep.screenIndex,
			step: firstStep,
			steps: newSteps ? newSteps : steps,
			stepsHistory,
			thankYouComponent: <ThankYouFeedback />,
		};

		initializeForm(NuevoFormInfo, NuevoFormData);
	}, []);

	return <FormController steps={steps} screenType="layer-screen" />;
}
