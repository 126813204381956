import React, { useState, useEffect } from 'react';
import { RadioButton } from '@volkswagen-onehub/components-core';
import { useDispatch, useSelector } from 'react-redux';
import { OneFormState } from 'src/types';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useFormContext } from 'react-hook-form';
import { ctaEnvioCEM } from 'src/feature-app/InputsValues/CTAsObjects';
import { strTitlelize } from 'src/helpers';

interface ChecksPublicacionProps {
	name: string;
}

export const ChecksPublicacion = (props: ChecksPublicacionProps) => {
	const { name } = props;

	const dispatch = useDispatch();
	const { formData } = useSelector((state: OneFormState) => state);
	const { fields } = formData;
	const [firstCta, secondCta] = ctaEnvioCEM;
	const { installation } = useSelector((state: OneFormState) => state.formData.fields);

	const { handleNextStep } = useOneFormContext();
	const [value, setValue] = useState(fields[name]);
	const { register } = useFormContext();

	const [checkedOne, setCheckedOne] = useState(fields[name] == firstCta.value);
	const [checkedTwo, setCheckedTwo] = useState(fields[name] == secondCta.value);

	const handleOnClick = (e: any) => {
		dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: e.currentTarget.value } });
		setTimeout(() => {
			handleNextStep();
		}, 0);
	};

	return (
		<div style={{ paddingTop: '42px' }}>
			<div style={{ paddingBottom: '20px', paddingLeft: '12px' }}>
				<RadioButton
					value={firstCta.value}
					label={firstCta.label.replace('[Installation]', strTitlelize(installation))}
					name={name}
					onClick={(e) => {
						setValue(firstCta.value);
						setCheckedOne(true);
						setCheckedTwo(false);
						handleOnClick(e);
					}}
					checked={checkedOne}
				/>
			</div>
			<div style={{ paddingLeft: '12px' }}>
				<RadioButton
					value={secondCta.value}
					label={secondCta.label.replace('[Installation]', strTitlelize(installation))}
					name={name}
					onClick={(e) => {
						setValue(secondCta.value);
						setCheckedOne(false);
						setCheckedTwo(true);
						handleOnClick(e);
					}}
					checked={checkedTwo}
				/>
			</div>
			<input
				type="hidden"
				name={name}
				value={value}
				ref={register({
					required: {
						value: true,
						message: 'Requerido',
					},
				})}
			/>
		</div>
	);
};
