import { Close } from 'src/icons-core-imports';
import React from 'react';
import { useSelector } from 'react-redux';
import { OneFormState } from 'src/types';
import { useOneFormContext } from 'src/feature-app';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';


export const CloseButton = () => {
  const { handleAreYouSureLayer } = useOneFormContext();
  const { screenIndex } = useSelector(
    (state: OneFormState) => state.formInfo
  );

  const trackingManager = useTrackingManager();

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        trackingManager.trackFormCloseClick(
          {
            contentId: 'Cerrar',
          },
          { FormStart: screenIndex === 0 ? true : false }
        );
        handleAreYouSureLayer();
      }}
    >
      <Close />
    </div>
  );
};
