import {
  Breakpoints,
  Container,
  ContainerPadding,
  CTA,
  Layout,
  styled,
  Text,
  TextAlignment,
  ThemeProvider,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Suggest, useFeatureAppConfig, useMapGeneralController } from 'src/feature-app';
import { Horario, Installation, isDealerData, OneFormState, SelectedTab } from 'src/types';
import { isGooglePlace, updateMapVariables, Suggestion } from 'src/feature-app/NewMap';
import {
  useTrackingManager,
  useLocateUser,
  useShowOverflow,
  useFeatureAppEnvironment,
  useFeatureServices,
} from 'src/feature-app/hooks';
import LoadScriptInstance from 'src/feature-app/NewMap/Loadscript';
import { renderAvisoIslasCanariasLayer } from 'src/components';
import { getGeocode } from 'src/feature-app/NewMap/utils';
import { GooglePlace } from 'src/feature-app/NewMap/components/suggest';

const FiltrosWrapper = styled.div<{ onlySuggest?: boolean; showOverflow?: boolean }>`
  min-height: 100vh;
  position: relative;
  padding-bottom: 117px;
  padding-top: 56px;
  overflow: ${(props) => (props.showOverflow ? 'visible' : 'auto')};
  .filtros {
    &__header {
      text-align: center;
    }
    &__body {
      flex-grow: 1;
      padding-bottom: ${(props) => (props.onlySuggest ? '4px' : null)};
    }
    &__controls {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 14px 0;
      background: #ffffff;
      border-top: 1px solid #dfe4e8;
    }
  }
`;

const MultipleCTAWrapper = styled.div`
  text-align: left;
  & > button {
    margin: 24px 24px 0 0;
  }
`;

const Divider = styled.div`
  background-color: #dfe4e8;
  width: 100vw;
  height: 1px;
  margin-bottom: 14px;

  @media all and (min-width: 960px) {
    display: none;
    margin-bottom: 0;
  }
`;

//revisar width aButtonsWrapper a partir de 960 en navegadores/dispositivos con barra de scroll.
//Está puesto con valor fijo porqué por algun motivo no pilla bien el 100%

const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media all and (min-width: 960px) {
    width: 400px;
  }
  button {
    div {
      font-size: 14px;
    }
    &:first-of-type {
      margin-right: 32px;
    }
  }
`;

interface FiltrosProps {
  closeFiltros: any;
  showFiltros: boolean;
  setShowFiltros: React.Dispatch<React.SetStateAction<boolean>>;
  setPreSelectedOrderValue?: React.Dispatch<React.SetStateAction<SelectedTab>>;
  preSelectedOrderValue?: SelectedTab;
  onlySuggest?: boolean;
  concesionario?: boolean;
  citaPosventa?: boolean;
}

export const Filtros = (props: FiltrosProps) => {
  return (
    <LoadScriptInstance onMapLoad={() => {}}>
      <FiltrosComponent {...props} />
    </LoadScriptInstance>
  );
};

export interface SelectedSuggestion {
  name: string;
  suggestion: Suggestion;
}

const getSelectedSuggestion = (suggestion: Suggestion) => {
  if (isGooglePlace(suggestion)) {
    return { name: suggestion.name, suggestion };
  } else {
    if (!isDealerData(suggestion)) {
      return {
        name: `${(suggestion as Installation).TXT_V_Installation_Data_Name__c}, ${
          (suggestion as Installation).TXT_V_Address__c
        }`,
        suggestion,
      };
    } else {
      return {
        name: `${suggestion.name}, ${suggestion.markerInfo.dealerInfo.address}`,
        suggestion,
      };
    }
  }
};

export const FiltrosComponent = (props: FiltrosProps) => {
  const {
    closeFiltros,
    showFiltros,
    setShowFiltros,
    setPreSelectedOrderValue,
    preSelectedOrderValue,
    onlySuggest,
    concesionario,
    citaPosventa,
  } = props;
  const { formData, formInfo } = useSelector((state: OneFormState) => state);

  //Si se necesita que el overflow sea visible. Selectsno nativos, suggests, etc. Añadir en la definicion del step showOverflow.

  const { showOverflow } = useShowOverflow(false);

  const [aplicarDisabled, setAplicarDisabled] = useState<boolean>(true);
  const [tabsOrder, setTabsOrder] = useState<SelectedTab>('horario');
  const [currentHorario, setCurrentHorario] = useState<{ label: string; value: string }>({
    label: 'Lo antes posible',
    value: 'before',
  });
  // guardar suggestion seleccionada previo a hacer submit
  // sirve para activar o desactivar el setAplicarDisabled
  const [selectedSuggestion, setSelectedSuggestion] = useState<SelectedSuggestion>(null);
  const { register } = useFormContext();
  const trackingManager = useTrackingManager();
  const filtrosRef = useRef<HTMLDivElement>(null);

  const { userSuggestion: userSuggest, suggestionIsInCanaryIslands } = formInfo;

  const CTAsHorario = [
    { label: 'Lo antes posible', value: 'before' },
    { label: 'Por la mañana', value: 'morning' },
    { label: 'Al mediodía', value: 'afternon' },
    { label: 'Por la tarde', value: 'evening' },
  ];

  const CTAsOrden: { value: SelectedTab; label: string }[] = [
    { value: 'ubicacion', label: 'Ubicación' },
    { value: 'horario', label: 'Horario' },
  ];

  const {
    value,
    setValue,
    icon,
    locationSuccess,
    setLocationSuccess,
    loading,
    locationDisabled,
    isLocating,
    handleLocateFilters,
  } = useLocateUser();
  const dispatch = useDispatch();

  const { moreDealersSearched, setMoreDealersSearched } = useMapGeneralController();
  const { trigger } = useFeatureAppConfig();
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const layerManager = useFeatureServices()['layer-manager'];

  const validateSubmission = () => {
    const { suggestionIsInCanaryIslands } = store.getState().formInfo;

    if (suggestionIsInCanaryIslands) {
      renderAvisoIslasCanariasLayer(store, env, layerManager);
      return;
    }

    if (preSelectedOrderValue && setPreSelectedOrderValue && tabsOrder !== preSelectedOrderValue) {
      setPreSelectedOrderValue(tabsOrder);
    }
    setShowFiltros(false);
  };

  const handleFiltrosSubmit = (selectedSuggestionFromClick?: SelectedSuggestion) => {
    if (selectedSuggestion && !selectedSuggestionFromClick) {
      // if (isGooglePlace(selectedSuggestion.suggestion) && selectedSuggestion.suggestion.isGeoLocated) {
      //   // Parche para que se actualize la ubicación a la geolocalización. El bug viene de que en use-locate-user.tsx
      //   // se detecta el cambio de suggestion en un useeffect y eso dispara la función updateMapVariables, pero en el caso de
      //   // la geolocalización, ésta función se dispara automáticamente al clickar sobre el cta. Si queremos esperar a que los cambios
      //   // se hagan efectivos al clickar "Aplicar filtros" hay que llamarla desde aquí.

      updateMapVariables(dispatch, selectedSuggestion.suggestion).then(() => {
        validateSubmission();
      });
    }
    // Pasamos este valor al seleccionar en la lista desplegable un valor.
    if (selectedSuggestionFromClick) {
      updateMapVariables(dispatch, selectedSuggestionFromClick.suggestion).then(() => {
        validateSubmission();
      });
    }
  };

  useEffect(() => {
    if (!moreDealersSearched) {
      if (userSuggest && showFiltros) {
        if (!isGooglePlace(userSuggest) && isDealerData(userSuggest)) {
          setValue(`${userSuggest?.name}, ${userSuggest?.markerInfo?.dealerInfo?.address}`);
        } else if (!isGooglePlace(userSuggest) && !isDealerData(userSuggest)) {
          setValue(
            `${(userSuggest as Installation)?.TXT_V_Installation_Data_Name__c}, ${
              (userSuggest as Installation)?.TXT_V_Address__c
            }`
          );
        } else {
          setValue(userSuggest?.name);
        }
      }
    } else {
      setValue('');
    }
    if (showFiltros) setAplicarDisabled(true);
  }, [showFiltros]);

  useEffect(() => {
    if (value && !aplicarDisabled) {
      if (value !== selectedSuggestion.name) {
        setAplicarDisabled(true);
      }
    }
  }, [value]);

  useEffect(() => {
    const val = CTAsHorario.find((el: any) => formData.fields.horario === el.value);
    setCurrentHorario(val);
  }, [formData]);

  useEffect(() => {
    if (preSelectedOrderValue) {
      setTabsOrder(preSelectedOrderValue);
    }
  }, [preSelectedOrderValue]);

  useEffect(() => {
    if (moreDealersSearched && locationSuccess) setLocationSuccess(false);
  }, [moreDealersSearched]);

  useEffect(() => {
    // Necesario para que cada vez que se selecciona un resultado de google en filtros se compruebe si
    // pertenece a las islas canarias y dispare el popup antes de que se apliquen los filtros.
    const checkGeocode = async () => {
      const geometry = await getGeocode(selectedSuggestion);
    };
    if (selectedSuggestion?.suggestion?.isGooglePlace) {
      checkGeocode();
    }
  }, [selectedSuggestion]);

  useEffect(() => {
    if (!moreDealersSearched) {
      // cuando se monta la componente, si está geolocalizado forzar el locationSuccess a
      // true porque el boton el hook se resetea al montar de nuevo la componente
      // también porque en mobile la componente Filtros se renderiza cada vez que se abre
      if (!userSuggest) {
        return;
      }
      if ((userSuggest as GooglePlace)?.isGeoLocated) {
        setLocationSuccess(true);
      }
      if (!isGooglePlace(userSuggest) && isDealerData(userSuggest)) {
        setValue(`${userSuggest.name}, ${userSuggest.markerInfo.dealerInfo.address}`);
      } else if (!isGooglePlace(userSuggest) && !isDealerData(userSuggest)) {
        setValue(
          `${(userSuggest as Installation).TXT_V_Installation_Data_Name__c}, ${
            (userSuggest as Installation).TXT_V_Address__c
          }`
        );
      } else {
        setValue(userSuggest.name);
      }
    }
  }, []);

  const onSuggestionSelectedFn = (suggestion: Suggestion) => {
    const selectedSuggestion = getSelectedSuggestion(suggestion);
    setSelectedSuggestion(selectedSuggestion);
    setLocationSuccess(false);
    setAplicarDisabled(false);
    if (moreDealersSearched) setMoreDealersSearched(false);
    // Cierra la parte de filtros
    handleFiltrosSubmit(selectedSuggestion);
  };

  return (
    <ThemeProvider theme="main">
      <FiltrosWrapper
        ref={filtrosRef}
        className="filtros-wrapper filtros-component"
        onlySuggest={onlySuggest}
        showOverflow={showOverflow}
      >
        <Layout
          allowOverflowingContent
          appearance={{
            [Breakpoints.default]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 20 },
              { name: '.', columns: 2 },
            ],
          }}
        >
          {citaPosventa ? null : (
            <div className="filtros__header">
              <Text appearance={TokenTextAppearance.headline200} bold textAlign={TextAlignment.center}>
                Filtros
              </Text>
            </div>
          )}

          {!onlySuggest ? (
            <div className="filtros__body">
              <Container
                wrap={'always'}
                horizontalAlign={'flex-start'}
                padding={{ top: ContainerPadding.static400, bottom: ContainerPadding.static500 }}
              >
                <Text appearance={TokenTextAppearance.headline200} bold>
                  {citaPosventa ? (
                    <>Buscar de nuevo</>
                  ) : (
                    <>Busca tu {!concesionario ? <span>taller</span> : <span>punto de venta</span>}</>
                  )}
                </Text>
                <div style={{ position: 'relative', overflow: 'visible' }}>
                  <Suggest
                    onSuggestionSelectedFn={onSuggestionSelectedFn}
                    value={value}
                    setValue={setValue}
                    isFiltrosSuggest={true}
                    setAplicarDisabled={setAplicarDisabled}
                    isLocating={isLocating}
                    moreDealersSearched={moreDealersSearched}
                    setLocationSuccess={setLocationSuccess}
                  />
                </div>
                <Container
                  wrap={'always'}
                  padding={{ top: ContainerPadding.static350, bottom: ContainerPadding.static500 }}
                >
                  <CTA
                    tag="button"
                    emphasis={locationSuccess ? 'primary' : 'secondary'}
                    icon={icon}
                    onClick={async (e) => {
                      e.preventDefault();
                      if (!locationSuccess) {
                        const { suggestion } = await handleLocateFilters();
                        setSelectedSuggestion({ name: suggestion.name, suggestion });
                        setAplicarDisabled(false);
                        setMoreDealersSearched(false);
                      }
                    }}
                    disabled={loading ? true : false}
                    size="small"
                  >
                    Localiza mi posición actual
                  </CTA>
                  {locationDisabled ? (
                    <div style={{ paddingTop: '24px' }}>
                      <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100}>
                        Tu localización está deshabilitada
                      </Text>
                    </div>
                  ) : null}
                </Container>
                {/* <SortBy
                    setTabsOrder={setTabsOrder}
                    setAplicarDisabled={setAplicarDisabled}
                    preSelectedOrderValue={preSelectedOrderValue}
                    tabsOrder={tabsOrder}
                    CTAsOrden={CTAsOrden}
                  /> */}
              </Container>
              {/* <ChangeHorario
                  setCurrentHorario={setCurrentHorario}
                  setAplicarDisabled={setAplicarDisabled}
                  CTAsHorario={CTAsHorario}
                  currentHorario={currentHorario}
                  horario={formData.fields.horario}
                /> */}
            </div>
          ) : (
            <div className="filtros__body">
              <Container
                wrap={'always'}
                horizontalAlign={'flex-start'}
                padding={{ top: ContainerPadding.static400, bottom: ContainerPadding.static500 }}
              >
                <Text appearance={TokenTextAppearance.headline200} bold>
                  {citaPosventa ? (
                    <>Buscar de nuevo</>
                  ) : (
                    <>Busca tu {!concesionario ? <span>taller</span> : <span>punto de venta</span>}</>
                  )}
                </Text>
                <div style={{ position: 'relative', overflow: 'visible' }}>
                  <Suggest
                    onSuggestionSelectedFn={onSuggestionSelectedFn}
                    value={value}
                    setValue={setValue}
                    isFiltrosSuggest={true}
                    setAplicarDisabled={setAplicarDisabled}
                    setSelectedSuggestion={setSelectedSuggestion}
                    isLocating={isLocating}
                    moreDealersSearched={moreDealersSearched}
                    setMoreDealersSearched={setMoreDealersSearched}
                    setLocationSuccess={setLocationSuccess}
                  />
                </div>
                <Container
                  wrap={'always'}
                  padding={{ top: ContainerPadding.static350, bottom: ContainerPadding.static500 }}
                >
                  <CTA
                    tag="button"
                    emphasis={locationSuccess ? 'primary' : 'secondary'}
                    icon={icon}
                    onClick={async (e) => {
                      e.preventDefault();
                      if (!locationSuccess) {
                        const { suggestion } = await handleLocateFilters();
                        setSelectedSuggestion({ name: suggestion.name, suggestion });
                        setAplicarDisabled(false);
                        setMoreDealersSearched(false);
                      }
                    }}
                    disabled={loading ? true : false}
                    size="small"
                  >
                    Localiza mi posición actual
                  </CTA>
                  {locationDisabled ? (
                    <div style={{ paddingTop: '24px' }}>
                      <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100}>
                        Tu localización está deshabilitada
                      </Text>
                    </div>
                  ) : null}
                </Container>
              </Container>
            </div>
          )}
        </Layout>
        <div className="filtros__controls">
          <Layout
            appearance={{
              [Breakpoints.default]: [
                { name: '.', columns: 2 },
                { name: 'a', columns: 20 },
                { name: '.', columns: 2 },
              ],
            }}
          >
            <ActionButtonsWrapper>
              <CTA
                tag="button"
                emphasis="secondary"
                onClick={() => {
                  closeFiltros();
                  trackingManager.trackFormButtonClick({
                    contentId: 'Cancelar',
                  });
                }}
                ariaLabel="aria label"
              >
                Cancelar
              </CTA>
              <CTA
                tag="button"
                emphasis="primary"
                onClick={(e) => {
                  e.preventDefault();
                  trackingManager.trackFormButtonClick({
                    contentId: 'Aplicar filtros',
                  });
                  setTimeout(() => {
                    handleFiltrosSubmit();
                  }, 1000);
                }}
                ariaLabel="aria label"
                disabled={aplicarDisabled}
              >
                {citaPosventa ? <>Buscar</> : <>Aplicar filtros</>}
              </CTA>
            </ActionButtonsWrapper>
          </Layout>
        </div>
      </FiltrosWrapper>
    </ThemeProvider>
  );
};

interface SortByProps {
  setTabsOrder: React.Dispatch<React.SetStateAction<SelectedTab>>;
  setAplicarDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  preSelectedOrderValue: SelectedTab;
  CTAsOrden: {
    value: SelectedTab;
    label: string;
  }[];
  tabsOrder: SelectedTab;
}

const SortBy = (props: SortByProps) => {
  const { setTabsOrder, setAplicarDisabled, preSelectedOrderValue, tabsOrder, CTAsOrden } = props;
  const trackingManager = useTrackingManager();

  return (
    <>
      <Text appearance={TokenTextAppearance.headline200} bold>
        Ordenar por
      </Text>
      <MultipleCTAWrapper>
        {CTAsOrden.map((el) => {
          return (
            <CTA
              tag="button"
              emphasis={tabsOrder === el.value ? 'primary' : 'secondary'}
              onClick={(e) => {
                e.preventDefault();
                trackingManager.trackFormButtonClick({
                  contentId: el.label,
                });
                setTabsOrder(el.value);
                if (preSelectedOrderValue !== el.value) {
                  setAplicarDisabled(false);
                }
              }}
              size="small"
              key={el.value}
            >
              {el.label}
            </CTA>
          );
        })}
      </MultipleCTAWrapper>
    </>
  );
};

interface ChangeHorarioProps {
  setCurrentHorario: React.Dispatch<React.SetStateAction<{ label: string; value: string }>>;
  setAplicarDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  CTAsHorario: {
    label: string;
    value: string;
  }[];
  currentHorario: { label: string; value: string };
  horario: Horario;
}

const ChangeHorario = (props: ChangeHorarioProps) => {
  const { setCurrentHorario, setAplicarDisabled, CTAsHorario, currentHorario, horario } = props;
  const trackingManager = useTrackingManager();

  return (
    <Container wrap={'always'} horizontalAlign={'flex-start'}>
      <Text appearance={TokenTextAppearance.headline200} bold>
        Selecciona una franja horaria
      </Text>
      <MultipleCTAWrapper>
        {CTAsHorario.map((cta: { label: string; value: string }) => {
          return (
            <CTA
              tag="button"
              emphasis={currentHorario.label === cta.label ? 'primary' : 'secondary'}
              onClick={(e) => {
                e.preventDefault();
                trackingManager.trackFormButtonClick({
                  contentId: cta.label,
                });
                if (cta.label !== currentHorario.label) {
                  setCurrentHorario(cta);
                }
                if (cta.value !== horario) {
                  setAplicarDisabled(false);
                }
              }}
              size="small"
              key={cta.value}
            >
              {cta.label}
            </CTA>
          );
        })}
      </MultipleCTAWrapper>
    </Container>
  );
};
