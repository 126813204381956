import { OneFormState } from 'src';
import { formDataBuilder } from 'src/forms/format-fields';
import { raceSend } from 'src/bff';
import { useEffect, useState } from 'react';
import { cellphoneIsInvalid } from 'src/components';
import { useSelector } from 'react-redux';

export const useRaceFormController = (raceCellphone: string) => {
  const [isPristine, setIsPristine] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const {racePrefix }= useSelector((state: OneFormState) => state.formData.fields);

  useEffect(() => {
    if (
      raceCellphone &&
      !cellphoneIsInvalid(raceCellphone) &&
      /[6|7|8|9][0-9]{8}/gi.test(raceCellphone) &&
      raceCellphone.length === 9
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [raceCellphone]);

  const retry = () => {
    reset();
    sendForm();
  };

  const reset = () => {
    setError(false);
    setIsSent(false);
    setIsSending(false);
    setIsPristine(true);
    setSuccessful(false);
    setDisabledButton(true);
  };

  const sendForm = async (): Promise<any> => {
    setIsSending(true);
    setDisabledButton(true);
    setIsPristine(false);

    const data = {
      id: 'CTC VOLKSWAGEN',
      canal: 'FORM',
      telefono: `${racePrefix}${raceCellphone}`,
    };

    const dataStringified = JSON.stringify(data);
    const dataParsed = JSON.parse(dataStringified);

    const formDataInfo = formDataBuilder(dataParsed);
    const response = await raceSend(formDataInfo);

    setIsSending(false);
    setDisabledButton(false);

    if (response?.data?.status?.result === 'success' && response?.data?.status?.code === 1) {
      setSuccessful(true);
    } else {
      setError(true);
    }
    setIsSent(true);
  };

  return { isSent, isSending, isPristine, disabledButton, error, sendForm, retry, setIsPristine, successful, reset };
};
