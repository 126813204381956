import {
  Breakpoints,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Layout,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { Calendar } from 'src/icons-core-imports';

import React from 'react';
import { useSelector } from 'react-redux';
import { Center, getDistanceFromCenter, Points } from 'src/feature-app';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { Horario, OneFormState } from 'src/types';

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
`;

interface DealerCardProps {
  handleShowSlots?: any;
  point?: Points;
  handleSeleccionarOnClick?: any;
  handleShowCalendarOnClick?: any;
  center: Center;
}

export const DealerCard = (props: DealerCardProps) => {
  const { point, center } = props;
  const { name, address, city, zipCode } = point.properties.dealer.markerInfo.dealerInfo;
  const { distanceFromCenter, dealer } = point.properties;
  const trackingManager = useTrackingManager();
  const { formData } = useSelector((state: OneFormState) => state);
  const { horario }: { horario: Horario } = formData.fields;

  return (
    <>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: 'a', columns: 18 },
            { name: 'b', columns: 6 },
          ],
        }}
      >
        <Container wrap={'always'} stretchContent>
          {props.handleShowSlots(point) === null ? null : (
            <Container padding={{ bottom: ContainerPadding.static300 }}>
              <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.headline200} bold>
                {props.handleShowSlots(point)}
              </Text>
            </Container>
          )}

          <Container wrap={'always'} gutter={ContainerGutter.static100}>
            <Text bold textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200}>
              {name ? name : null}
            </Text>
            <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100}>
              {address}, {zipCode} {city}
            </Text>
          </Container>
        </Container>
        <Container horizontalAlign={'flex-end'}>
          <Text textAlign={TextAlignment.right} appearance={TokenTextAppearance.copy200}>
            {distanceFromCenter ? distanceFromCenter : getDistanceFromCenter(center, point)}km
          </Text>
        </Container>
      </Layout>

      {!point.properties.dealer.dmsInfo || !point.properties.dealer.dmsInfo.dmsAvailable ? (
        <CTAWrapper>
          <CTA
            tag="button"
            emphasis="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              trackingManager.trackFormButtonClick(
                {
                  contentId: 'Seleccionar día y franja horaria',
                },
                dealer
              );
              props.handleShowCalendarOnClick(point);
            }}
          >
            Seleccionar día y franja horaria
          </CTA>
        </CTAWrapper>
      ) : (
        <CTAWrapper>
          <CTA
            tag="button"
            emphasis="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              trackingManager.trackFormButtonClick(
                {
                  contentId: 'Seleccionar',
                },
                dealer
              );
              if (
                (horario !== 'before' && dealer.slots[horario] === null) ||
                (horario === 'before' && !point.properties.hasOwnProperty('earliestSlot'))
              ) {
                props.handleShowCalendarOnClick(point);
              } else {
                props.handleSeleccionarOnClick(point);
              }
            }}
          >
            Seleccionar
          </CTA>
          <CTA
            tag="button"
            emphasis="tertiary"
            size="small"
            icon={<Calendar variant="small" />}
            onClick={(e) => {
              e.preventDefault();
              trackingManager.trackFormButtonClick(
                {
                  contentId: 'Ver más horas',
                },
                dealer
              );
              props.handleShowCalendarOnClick(point);
            }}
          >
            Ver más horas
          </CTA>
        </CTAWrapper>
      )}
    </>
  );
};
