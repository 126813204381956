import { Checkbox, CTA, styled, Text, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useIsComerciales } from 'src/feature-app/hooks';
import { InputError } from 'src/components';
import { OneFormState } from 'src/types';

export const PoliticaPrivacidad = () => {
  const { register, errors, clearError } = useFormContext();
  const isComerciales = useIsComerciales();
  const { formInfo } = useSelector((state: OneFormState) => state);
  return (
    <div style={{ display: 'flex', paddingBottom: '44px', flexDirection: 'column', gap: '12px' }}>
      <div style={{ display: 'flex', gap: '12px' }}>
        <Text appearance={TokenTextAppearance.copy200} color={TokenTextColor.secondary}>
          Enviando este formulario declaras estar informado de la{' '}
            <CTA
              tag="a"
              href={
                isComerciales
                  ? 'https://www.volkswagen-comerciales.es/es/volkswagen-espana/datos-privacidad.html'
                  : 'https://www.volkswagen.es/es/volkswagen-espana/politica-privacidad.html'
              }
              target="_blank"
              emphasis="tertiary"
            >
              política de privacidad.
            </CTA>
        </Text>
      </div>
      {errors && errors.politicaPrivacidad ? (
        <InputError maxWidth="fit-content">Necesitamos esta información para seguir</InputError>
      ) : null}
    </div>
  );
};
