import React, { useState, useEffect } from 'react';
import { BreakpointWrapper, Breakpoints } from '@volkswagen-onehub/components-core';
import { SelectExtended } from '.';
import { getActualYearNumber } from 'src/feature-app';
import { styled } from '@volkswagen-onehub/components-core';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { OneFormState } from 'src/types';
import { meses } from 'src/feature-app/InputsValues/OtherInputTypesObjects';

interface HeaderImageProps {
    imageSmall?: string;
    imageBig?: string;
    imageLogo?: string;
}
const ImageWrapper = styled.div<{ background: string }>`
  div{
    display: flex;
    justify-content: center;
    background-image: ${(props) => `url(${props.background})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30vh;
  }
  @media screen and (max-width: 700px) {
    div{
        height: 40vh;
    }
  }    
`;
const LogoCenter = styled.div`
  .image-front{
    position: relative;
    width: 50vw;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: auto; 
  }
  @media screen and (min-width: 500px) {
    .image-front{
        width: 40vw;
    }
  }
  @media screen and (min-width: 700px) {
    .image-front{
        width: 30vw;
    }
  }
`;


export const HeaderImage = (props: HeaderImageProps) => {
    return (
        <>
            <BreakpointWrapper min={Breakpoints.default} max={Breakpoints.b960}>
                <ImageWrapper background={props.imageSmall}>
                        <LogoCenter>
                            <img className='image-front' src={props.imageLogo} alt="logo" />
                        </LogoCenter>
                </ImageWrapper>
            </BreakpointWrapper>
            <BreakpointWrapper min={Breakpoints.b960}>
                <ImageWrapper background={props.imageBig}>
                    <LogoCenter>
                        <img className='image-front' src={props.imageLogo} alt="logo" />
                    </LogoCenter>
                </ImageWrapper>
            </BreakpointWrapper>
        </>
    );
}