import React from 'react';
import { ScreenController } from 'src/feature-app';
import { ConcursoRodajeController } from 'src/forms';

export function ConcursoRodajeTrigger() {
  return <ScreenController
    selectedJourney={<ConcursoRodajeController />}
    closeLayerCallback={close}
    handleAreYouSureLayer={() => null}
    notALayer={true}
    formType='inPage'
  />;
}
