import {
  Container, ContainerPadding
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { TriggerProps } from 'src/feature-app';
import { DisplayImagenesYCTATrigger, SectionMensajes } from 'src/forms/FormsAdhoc/DiaDeLaMadre/componentsAndViews';

export function DiaDeLaMadreTrigger(props: TriggerProps) {

  const { setSelectedJourney, setCTALabel } = props;

  return (
    <>
      <SectionMensajes />
      <Container padding={{top: ContainerPadding.dynamic0350}}>
        <DisplayImagenesYCTATrigger setSelectedJourney={setSelectedJourney} setCTALabel={setCTALabel}/>
      </Container>
    </>
  );
}
