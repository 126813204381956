import { Salesgroup } from 'src/types';
import { SelectExtendedList } from 'src/components/CarlineSelect';
/**
 * Devuelve los salesgroups con el formato necesario para usar el select.
 * @param carline
 * @returns
 */
 export const formatSalesgroupsSelectExtendedList = (carline: Salesgroup): SelectExtendedList => {
  if (!carline) {
    return null;
  }
  return {
    code: String(carline.code),
    name: carline.name,
    key: carline.name,
  };
};
