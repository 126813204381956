import { BreakpointWrapper, Breakpoints, CTA, Container, ContainerGutter, ContainerPadding, Layout, TextAlignment, TokenTextAppearance, styled } from "@volkswagen-onehub/components-core";
import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect } from "react";
import iconlssi from "./iconlssi.svg"
import icontmk from "./icontmk.svg"
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { useOneFormContext } from "src/feature-app";
import { useWindowHeight } from "@react-hook/window-size";
import { useGetBoundingClientRect } from 'src/feature-app/hooks';
import { OneFormState } from "src/types";

const CTAsWrapper = styled.div`
    padding: var(--size-grid002) 0;
    display: flex;
    justify-content: center;
    gap: var(--size-grid001);
    width: 100%;
    @media (min-width: 960px) {
        padding: var(--size-grid001) 0;
    }
`
const returnLinearGradient = (formType: string) => {
    if (formType === 'layer') {
        return 'linear-gradient(180deg, rgba(7, 29, 77, 0.5) 0%, #071D4D 77.06%);'
    } else {
        return 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) -25.89%, #FFFFFF 50%)'
    }
}

const CTAsWrapperMobile = styled.div<{ navPosition: string, formType: string }>`
    padding:  ${(props) => props.navPosition === 'fixed' ? '45px 0 110px 0' : '50px 0'};
    display: flex;
    justify-content: center;
    gap: var(--size-grid001);
    position: ${(props) => props.navPosition};
    height: 130px;
    right: 0px;
    width: 100%;
    bottom: ${(props) => props.navPosition === 'fixed' ? '31px' : '-27px'};
    background: ${(props) => returnLinearGradient(props.formType)};
`

interface ConsentQuestionProps {
    questionType: 'lssi' | 'tmk';
}

const questions = {
    lssi: {
        title: <>¿Te gustaría recibir <Text bold> descuentos y novedades de Volkswagen?</Text></>,
        subtitle: 'Estarás mejor informado.',
        copy: 'Si nos das tu consentimiento desde Volkswagen Group España Distribución, S.A. como responsable de la marca Volkswagen podremos enviarte por cualquier medio, ya sea por teléfono, correo electrónico, sms o aplicaciones de mensajería instantánea información comercial lo que puede incluir, a título enunciativo, pero no limitativo, descuentos, novedades, próximos lanzamientos, invitaciones a eventos o a concursos, entre otras comunicaciones comerciales.',
        icon: iconlssi,
    },
    tmk: {
        title: <>¿Te gustaría que elaboremos un perfil tuyo para <Text bold> ofrecerte información comercial sobre productos y servicios de nuestras marcas</Text> que creamos que te puedan interesar más según tus preferencias?</>,
        subtitle: '¡Adiós mensajes genéricos!',
        copy: 'Activando esta opción podremos enviarte información y ofertas personalizadas de productos y servicios adecuadas a tu perfil. Para ello nos basaremos en tu comportamiento, preferencias personales y cómo usas nuestros productos y servicios.',
        icon: icontmk,
    }
}


export const ConsentQuestion = (ConsentQuestionProps: ConsentQuestionProps) => {
    const { questionType } = ConsentQuestionProps;
    const [currentValue, setCurrentValue] = React.useState('');
    const { register, setValue, errors } = useFormContext();
    const dispatch = useDispatch();
    const { handleNextStep } = useOneFormContext();
    const [navPosition, setNavPosition] = React.useState('fixed');
    const viewportHeight = useWindowHeight();
    const { formInfo } = useSelector((state: OneFormState) => state);
    const { setShowPreviousStep } = useOneFormContext();

    setShowPreviousStep(false);

    const { bottom: bottomContent, getAndUpdateState: getAndUpdateStateContent } = useGetBoundingClientRect(
        '.content-controller-fullScreen'
    );

    const { top: topNavigation, getAndUpdateState: getAndUpdateStateNavigation } =
        useGetBoundingClientRect('.navigation-wrapper');

    useEffect(() => {
        if (bottomContent === null) {
            setNavPosition('fixed');
        } else if (bottomContent <= viewportHeight - 100) {
            setNavPosition('absolute');
        } else {
            setNavPosition('fixed');
        }
    }, [topNavigation, bottomContent]);

    const handlePosition = () => {
        getAndUpdateStateNavigation();
        getAndUpdateStateContent();
    };

    useEffect(() => {
        document.addEventListener('scroll', handlePosition);
        return () => {
            document.removeEventListener('scroll', handlePosition);
        };

    }, []);

    const renderCTAs = () => {
        return (
            <>
                <CTA
                    tag="button"
                    emphasis="primary"
                    onClick={(e) => {
                        e.preventDefault()
                        checkCurrentValue('aceptar', questionType)
                    }}
                    ariaLabel="aceptar"
                >
                    Aceptar
                </CTA>
                <CTA
                    tag="button"
                    emphasis="secondary"
                    onClick={(e) => {
                        e.preventDefault()
                        checkCurrentValue('omitir', questionType)
                    }}
                    ariaLabel="omitir"
                >
                    Omitir
                </CTA>
                <input
                    type="hidden"
                    name={questionType}
                    value={currentValue}
                    ref={register({
                        required: {
                            value: true,
                            message: 'Requerido',
                        },
                    })}
                />
            </>
        )
    }

    function checkCurrentValue(label: string, name: string) {
        const value = label === 'aceptar' ? 'PERMITE' : 'NO INFORMADO';
        setCurrentValue(label);
        dispatch({ type: 'UPDATE_FIELDS', payload: { [questionType]: value } });

        setValue(name, value);
        handleNextStep();
    }

    return (
        <>
            <Layout
                allowOverflowingContent
                appearance={{
                    [Breakpoints.default]: [
                        { name: '.', columns: 2 },
                        { name: 'a', columns: 20 },
                        { name: '.', columns: 2 },
                    ],
                    [Breakpoints.b1600]: [
                        { name: '.', columns: 3 },
                        { name: 'a', columns: 18 },
                        { name: '.', columns: 3 },
                    ],
                    [Breakpoints.b1920]: [
                        { name: '.', columns: 5 },
                        { name: 'a', columns: 14 },
                        { name: '.', columns: 5 },
                    ],
                }}
            >
                <Container
                    wrap={'always'}
                    horizontalAlign={'center'}
                    gutter={ContainerGutter.static300}
                    padding={{ bottom: ContainerPadding.static515 }}

                >

                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <img src={questions[questionType].icon} alt="icon" />
                    </div>
                    <Text textAlign={TextAlignment.center} appearance={TokenTextAppearance.headline300}>
                        {questions[questionType].title}
                    </Text>
                    <Text textAlign={TextAlignment.center} appearance={TokenTextAppearance.headline200}>
                        <Text bold>{questions[questionType].subtitle}</Text>
                    </Text>
                    <Text textAlign={TextAlignment.center} appearance={TokenTextAppearance.copy100}>
                        {questions[questionType].copy}
                    </Text>
                </Container>
                <BreakpointWrapper min={Breakpoints.b960}>
                    <CTAsWrapper>
                        {renderCTAs()}
                    </CTAsWrapper>
                </BreakpointWrapper>
                <BreakpointWrapper max={Breakpoints.b960}>
                    <CTAsWrapperMobile formType={formInfo.formType} navPosition={navPosition}>
                        {renderCTAs()}
                    </CTAsWrapperMobile>
                </BreakpointWrapper>
            </Layout >
        </>
    )
}
