/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import { styled } from '@volkswagen-onehub/components-core';
import car34 from './car_3-4.png';

const SectionWrapper = styled.div`
  position: relative;
  height: 100%;

  @media all and (min-width: 960px) {
    height: max-content;
    min-height: calc(100vh - 164px);
    width: var(--size-grid012);
    margin-top: 0;
  }
  @media all and (min-width: 1600px) {
    min-height: calc(100vh - 180px);
  }
`;
interface CarlineColorCardProps {
  color: string;
  showColorCard: boolean;
}
const CarlineColorCard = styled.div<CarlineColorCardProps>`
  background-color: ${(props) =>
    !props.showColorCard ? '#FFFFFF' : props.color ? props.color : '#C0C5C6'};
  height: 100%;
  z-index: -2;
  position: absolute;
  top: 0;
  width: 100vw;
  @media all and (min-width: 960px) {
    right: 0;
    width: var(--size-grid009);
    height: max-content;
    min-height: calc(100vh - 164px);
  }
  @media all and (min-width: 1600px) {
    right: 0;
    width: var(--size-grid008);
    min-height: calc(100vh - 180px);
  }
`;
interface ImageWrapperProps {
  noMargins?: boolean;
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  @media all and (min-width: 960px) {
    margin-right: var(--size-grid001);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 40px 0;
  }
  @media all and (min-width: 1600px) {
    padding: 52px 0;
  }
  img {
    width: var(--size-grid024);
    margin: auto;
    margin-top: ${(props) => props.noMargins ? '-120px' : '0'};
    margin-bottom: ${(props) => props.noMargins ? '-52px' : '0'};
    height: auto;
    @media all and (min-width: 560px) {
      width: var(--size-grid024);
    }
    @media all and (min-width: 960px) {
      margin: auto;
      width: var(--size-grid012);
    }
    @media all and (min-width: 1920px) {
      width: var(--size-grid011);
    }
  }
`;

interface ImageProps {
  image: string;
  color: string;
  modelName: string;
  noMargins?: boolean;
}
export const ImageCar = (props: ImageProps) => {
  const { image, color, modelName, noMargins } = props;

  //Ocultar color pastilla en caso de la imagen que llega no sea r-media

  const [showColorCard, setShowColorCard] = useState(true);
  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    if (image) {
      setLoadingImage(false);

      if (image.includes('r-media')) {
        setShowColorCard(true);
      } else {
        setShowColorCard(false);
      }
    } else {
      setShowColorCard(true);
      setLoadingImage(true);
    }
  }, [image]);

  return (
    <SectionWrapper className="section-right-wrapper" >
      <CarlineColorCard
        color={color}
        className="carline-color-background"
        showColorCard={showColorCard}
      />
      <ImageWrapper noMargins>
        {image ? (
          <>
            {loadingImage ? (
              <img src={car34} alt={modelName} />
            ) : (
              <img src={image} alt={modelName} />
            )}
          </>
        ) : null}
      </ImageWrapper>

    </SectionWrapper>
  );
};
