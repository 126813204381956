import React  from 'react';
import { TriggerProps } from 'src/feature-app';
import { EurocopaForm } from 'src/forms/FormsAdhoc/Eurocopa';
import { ScreenController } from 'src/feature-app/Screen';

export function EurocopaTrigger(props: TriggerProps) {

  return (
    <div className="eurocopa-wrapper">
      <ScreenController
        selectedJourney={<EurocopaForm />}
        closeLayerCallback={close}
        handleAreYouSureLayer={() => null}
        notALayer={true}
      />
    </div>
  );
}
