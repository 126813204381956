import { Spinner, Text, styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';

interface CenterContentProps {
  height: string;
}
const CenterContent = styled.div<CenterContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.height ? props.height : '100%')};
`;

export const LoadingEncuestas = () => {
  const [height, setHeight] = useState('100%');

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    let aux;

    if (viewportWidth < 560) {
      aux = viewportHeight - 152;
    } else if (viewportWidth < 1600) {
      aux = viewportHeight - 252;
    } else {
      aux = viewportHeight - 380;
    }
    setHeight(`${aux}px`);
  }, []);

  return (
    <CenterContent height={height}>
      <Spinner variant="large" />
      <Text>Cargando tus datos</Text>
    </CenterContent>
  );
};
