import React from 'react';
import styled from 'styled-components';

const ArrowContainer = styled.div`
	margin-top: -25px;
	margin-bottom: 40px;
	text-align: center;
	width: 100%;
	position: relative;
	z-index: 1;
`;

export const Arrow = () => (
	<ArrowContainer>
		<svg width="58" height="59" viewBox="0 0 58 59" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="29" cy="29.6064" r="28.9399" fill="#E4002C" />
			<path
				d="M30.5571 14.4691C30.5571 13.6087 29.8596 12.9112 28.9993 12.9112C28.1389 12.9112 27.4414 13.6087 27.4414 14.4691L30.5571 14.4691ZM27.8977 45.844C28.5061 46.4524 29.4925 46.4524 30.1008 45.844L40.0149 35.93C40.6232 35.3216 40.6232 34.3352 40.0149 33.7269C39.4065 33.1185 38.4201 33.1185 37.8117 33.7269L28.9993 42.5393L20.1868 33.7269C19.5784 33.1185 18.5921 33.1185 17.9837 33.7269C17.3753 34.3352 17.3753 35.3216 17.9837 35.93L27.8977 45.844ZM27.4414 14.4691L27.4414 44.7425L30.5571 44.7425L30.5571 14.4691L27.4414 14.4691Z"
				fill="#000E26"
			/>
		</svg>
	</ArrowContainer>
);
