import { Text, ThemeProvider, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';
import { Close, ContactDealer, Magnifier, Route } from 'src/icons-core-imports';
import { MagnifierLabel, CloseButton, SuggestionWrapper, StyledWrapper } from '.';
import React, { useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useDispatch, useSelector } from 'react-redux';
import { DealersData } from 'src/types';
import { useAddressPredictions } from 'src/feature-app';
import { DealersOrInstallationsMode, Geometry, Installation, isDealerData, OneFormState } from 'src/types';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { isGooglePlace } from 'src/feature-app/NewMap/utils';
import { useDropdowPosition, useTrackingManager } from 'src/feature-app/hooks';

export interface GooglePlace {
  isGooglePlace: boolean;
  name: string;
  isGeoLocated: boolean;
  skipSearch?: boolean;
  moreDealersSearched?: boolean;
  geometry?: Geometry;
}

export type Suggestion = DealersData | GooglePlace | Installation;

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const getSuggestions = (
  list: DealersData[] | Installation[],
  value: string,
  dealersOrInstallationsMode: DealersOrInstallationsMode
) => {
  if (value.length < 2) return [];
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }
  const regex = new RegExp(escapedValue, 'i');

  if (dealersOrInstallationsMode === 'dealers') {
    return list.filter((dealer: DealersData) => regex.test(dealer.markerInfo.dealerInfo.name));
  } else {
    return list.filter((installation: Installation) => regex.test(installation.VGED_CommercialName__c));
  }
};

const getSuggestionValue = (suggestion: Suggestion) => {
  if (isGooglePlace(suggestion)) {
    return suggestion.name;
  } else {
    if (isDealerData(suggestion)) {
      return suggestion.markerInfo.dealerInfo.name;
    } else {
      return (suggestion as Installation).VGED_CommercialName__c;
    }
  }
};

const renderSuggestion = (suggestion: Suggestion) => {
  if (isGooglePlace(suggestion)) {
    return (
      <SuggestionWrapper>
        <Route /> <span style={{ width: 12 }} />
        <Text appearance={TokenTextAppearance.copy200} color={TokenTextColor.inherit}>
          {suggestion.name}
        </Text>
      </SuggestionWrapper>
    );
  } else {
    let name;
    let address;
    let zipCode;
    let city;
    let province;
    if (isDealerData(suggestion)) {
      name = suggestion.markerInfo.dealerInfo.name;
      address = suggestion.markerInfo.dealerInfo.address;
      zipCode = suggestion.markerInfo.dealerInfo.zipCode;
      city = suggestion.markerInfo.dealerInfo.city;
      province = suggestion.markerInfo.dealerInfo.province;
    } else {
      name = (suggestion as Installation).VGED_CommercialName__c;
      address = (suggestion as Installation).ShippingStreet;
      zipCode = (suggestion as Installation).ShippingPostalCode;
      city = (suggestion as Installation).ShippingCity;
      province = (suggestion as Installation).Province;
    }
    return (
      <SuggestionWrapper>
        <ContactDealer />
        <span style={{ width: 12 }} />
        <div>
          <Text appearance={TokenTextAppearance.copy200} color={TokenTextColor.inherit}>
            {name}
          </Text>
          <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.inherit}>
            {address}
            <br />
            {zipCode} {city}, {province}
          </Text>
        </div>
      </SuggestionWrapper>
    );
  }
};

const getValueFormatted = (suggestion: Suggestion) => {
  if (isGooglePlace(suggestion)) {
    return suggestion.name;
  }
  if (!isGooglePlace(suggestion) && isDealerData(suggestion)) {
    return `${suggestion.name}, ${suggestion.markerInfo.dealerInfo.address}`;
  } else if (!isGooglePlace(suggestion) && !isDealerData(suggestion)) {
    return `${(suggestion as Installation).VGED_CommercialName__c}, ${
      (suggestion as Installation).ShippingStreet
    }`;
  } else {
    return '';
  }
};

interface SuggestProps {
  setSuggestion?: React.Dispatch<React.SetStateAction<Suggestion>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setAplicarDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
  isFiltrosSuggest?: boolean;
  isLocating?: boolean;
  moreDealersSearched?: boolean;
  setLocationSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  formTheme?: string;
  onSuggestionSelectedFn?: (suggestion: Suggestion) => void;
  autoWidth?: boolean;
}

export function Suggest(props: SuggestProps) {
  const {
    setSuggestion,
    setValue,
    value,
    isFiltrosSuggest,
    setAplicarDisabled,
    isLocating,
    moreDealersSearched,
    setLocationSuccess,
    formTheme,
    onSuggestionSelectedFn,
    autoWidth
  } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(false); // Controla que una vez seleccionado un valor en el input, no siga buscando en google maps.
  const predictions = useAddressPredictions(value, selectedValue);
  const [inputPlaceholder, setInputPlaceHolder] = useState<string>('Localidad, código postal o nombre del taller');
  const trackingManager = useTrackingManager();
  const suggestRef = useRef(null);
  const inputRef = useRef(null);

  const { formInfo, dealersInfo } = useSelector((state: OneFormState) => state);
  const { dealers, installations, dealersOrInstallationsMode } = dealersInfo;

  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { setDisableIntroNextStep, setError, handleNextStep } = useOneFormContext();

  useEffect(() => {
    if (isFiltrosSuggest) {
      setDisableIntroNextStep(true);
      // inputRef.current.input.addEventListener('keydown', handleIntroKeyDown)
    }
    return () => {
      if (isFiltrosSuggest) {
        setDisableIntroNextStep(false);
        // inputRef.current.input.removeEventListener('keydown', handleIntroKeyDown)
      }
    };
  }, []);

  const onChange = (e: any, { newValue }: { newValue: string }) => {
    e.stopPropagation();
    if (setSelectedValue) {
      setSelectedValue(false);
    }
    setError(false);
    setValue(newValue);
  };

  //desplegar el dropdown para arriba o para abajo según la posicinón del input en el window
  const { goUp, getAndUpdateState } = useDropdowPosition('.react-autosuggest__container');

  const onClick = () => {
    getAndUpdateState();

    trackingManager.trackFormFieldClick(
      {
        contentId: 'Localidad, código postal o nombre del taller',
      },
      {
        FormFieldName: 'location', // Corregir
      }
    );
  };

  const onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
    const list = dealersOrInstallationsMode === 'dealers' ? dealers : installations;
    const dealerSuggestions = getSuggestions(list, value, dealersOrInstallationsMode);
    setSuggestions(dealerSuggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const [placeHolder, setPlaceHolder] = useState<string>('');

  useEffect(() => {
    if (dealersOrInstallationsMode === 'dealers') {
      setPlaceHolder('Localidad, código postal, dirección, nombre del taller, etc.');
    } else {
      setPlaceHolder('Localidad, código postal o nombre del punto de venta');
    }
  }, []);

  useEffect(() => {
    if (isFiltrosSuggest && moreDealersSearched) {
      setInputPlaceHolder('Zona del mapa seleccionada');
    } else {
      setInputPlaceHolder(placeHolder);
    }
  }, [moreDealersSearched, placeHolder]);

  const inputProps = {
    placeholder: inputPlaceholder,
    value,
    onChange,
    onClick,
    disabled: isLocating ? true : false,
  };

  // REFACTORIZAR
  useEffect(() => {
    if (selectedValue) return;
    const predictionsFormatted: GooglePlace[] = predictions.map((prediction) => {
      return {
        name: prediction,
        isGooglePlace: true,
        isGeoLocated: false,
      };
    });
    setSuggestions([...suggestions, ...predictionsFormatted]);
  }, [predictions]);

  return (
    <StyledWrapper
      isFiltrosSuggest={props.isFiltrosSuggest}
      formTheme={formTheme}
      isLocating={isLocating}
      inputFocus={inputFocus}
      ref={suggestRef}
      onFocus={() => {
        setInputFocus(true);
      }}
      onBlur={() => setInputFocus(false)}
      isFullScreen={formInfo.notALayer ? true : false}
      goUp={goUp}
      autoWidth={autoWidth}
      className="styled-wrapper-suggest"
    >
      <MagnifierLabel
        className="magnifier-label"
        isFiltrosSuggest={props.isFiltrosSuggest}
        isLocating={isLocating}
        formTheme={formTheme}
      >
        <Magnifier />
      </MagnifierLabel>
      <Autosuggest
        ref={inputRef}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(event, { suggestion }) => {
          if (!isGooglePlace(suggestion)) {
            dispatch({ type: 'SUGGESTION_IS_IN_CANARY_ISLANDS', payload: false });
          }
          const value = getValueFormatted(suggestion);
          setValue(value);
          setSelectedValue(true);
          if (onSuggestionSelectedFn) {
            setTimeout(() => {
              onSuggestionSelectedFn(suggestion);
            }, 0);
          }
        }}
        inputProps={inputProps}
      />
      {value !== '' && (
        <ThemeProvider theme={isFiltrosSuggest || formTheme === 'main' ? 'main' : 'inverted'}>
          <CloseButton
            isLocating={isLocating}
            isFiltrosSuggest={isFiltrosSuggest}
            formTheme={formTheme}
            onClick={(e) => {
              e.preventDefault();
              setValue('');
              if (!isFiltrosSuggest && !isLocating) {
                setLocationSuccess(false);
                setSuggestion(null);
                setValue('');
                setSelectedValue(false);
              } else if (isFiltrosSuggest) {
                setAplicarDisabled(true);
              }
            }}
          >
            <Close />
          </CloseButton>
        </ThemeProvider>
      )}
    </StyledWrapper>
  );
}
