import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { TextArea } from '@volkswagen-onehub/components-core';

interface TextareaProps {
	name: string;
	label?: string;
}

export function TextAreaField(props: TextareaProps) {
	const { name, label } = props;
	const dispatch = useDispatch();
	const { register } = useFormContext();

	const handleChange = (e: any) => {
		dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: e.target.value } });
	};

	return (
		<>
			<TextArea name={name} label={label} onChange={(e) => handleChange(e)} rows={3} required={true} maxLength={300} />
		</>
	);
}
