import {
  Text,
  styled,
  Container,
  ContainerGutter,
  CTA,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { OneFormState } from 'src/types';

const PaddingWrapper = styled.div`
  padding-top: 32px;
`;

interface LegalesProps {
  linkBasesLegales?: string;
}
export const LegalFullScreenSinConsentmientos = (props: LegalesProps) => {
  return (
    <PaddingWrapper>
      <Container gutter={ContainerGutter.static200} wrap={'always'}>
        <Text bold appearance={TokenTextAppearance.copy200} color={TokenTextColor.secondary}>
          Texto legal de Volkswagen
        </Text>
        <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
          El responsable de tus datos es Volkswagen Group España Distribución, S.A.U. Vamos a utilizar tus datos para realizar un control de calidad sobre los productos y servicios contratados, mediante la realización de la presente encuesta de satisfacción, y contactarte para resolución de incidencias, reclamaciones o quejas. Tus datos podrán ser comunicados a las empresas del grupo para las finalidades de controlar la calidad de los productos y servicios, así como contactarte para resolución de incidencias, reclamaciones o quejas.
        </Text>
        <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
          En el supuesto de haber consentido, tus datos y las respuestas de la presente encuesta de satisfacción serán comunicadas al Concesionario. Tienes derecho a oponerte al tratamiento, limitar el mismo, acceder, rectificar, suprimir los datos y ejercer tu derecho a portabilidad, mediante el envío de un correo electrónico a:{' '}
            <CTA tag="a" href="mailto:atencioncliente@volkswagen.es" target="_blank" emphasis="tertiary">
              <b>atencioncliente@volkswagen.es</b>
            </CTA>
            .
        </Text>
        <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
            Puedes consultar la información adicional en la{' '}
            <CTA
              tag="a"
              href="https://www.volkswagen.es/es/volkswagen-espana/politica-privacidad.html"
              target="_blank"
              emphasis="tertiary"
            >
              <b>política de privacidad</b>
            </CTA>
            .
        </Text>
        {props.linkBasesLegales ? (
          <Text staticSize appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
              Consulta las{' '}
              <CTA tag="a" href={props.linkBasesLegales} target="_blank" emphasis="tertiary">
                <b>Bases Legales</b>
              </CTA>{' '}
              de la promoción.
          </Text>
        ) : null}
      </Container>
    </PaddingWrapper>
  );
};
