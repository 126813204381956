import { styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { InputError, TextInputExtended } from 'src/components';


const Wrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  label {
    width: 100%;
    @media screen and (min-width: 960px) {
      width: 170px;
    }
  }
`;

interface CodigoPostalProps {
  setFieldValue?: boolean;
}

export const CodigoPostal = ({ setFieldValue = false }: CodigoPostalProps) => {
  const { register } = useFormContext();

  // PostalCode
  const [inputValuePostalCode, setInputValuePostalCode] = useState('');
  const [pristinePostalCode, setPristinePostalCode] = useState(true);
  const [errorPostalCode, setErrorPostalCode] = useState(false);
  const regExpPostalCode = /\d{5}/gi;
  const dispatch = useDispatch();

  const handleChangePostalCode = (e: any) => {
    if (setFieldValue) {
      dispatch({ type: 'UPDATE_FIELDS', payload: { ['postalCode']: e.target.value } });
    }
    setInputValuePostalCode(e.target.value);
    setPristinePostalCode(false);
  };

  useEffect(() => {
    if (!pristinePostalCode && inputValuePostalCode.match(regExpPostalCode) === null) {
      setErrorPostalCode(true);
    } else {
      setErrorPostalCode(false);
    }
  }, [inputValuePostalCode, pristinePostalCode]);

  return (
    <Wrapper>
      <TextInputExtended
        name="postalCode"
        label="Código Postal"
        isFloating
        innerRef={register({
          required: {
            value: true,
            message: 'Requerido',
          },
          pattern: {
            value: regExpPostalCode,
            message: 'Solo se aceptan números',
          },
        })}
        onChange={(e) => handleChangePostalCode(e)}
        required
        maxLength={5}
      />
      {errorPostalCode && <InputError className="input-error">Solo se aceptan números</InputError>}
    </Wrapper>
  );
};
