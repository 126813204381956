import { FeatureAppEnvironment } from '@feature-hub/core';
import { ThemeName, ThemeProvider, styled, IntegratorRoot } from '@volkswagen-onehub/components-core';
import { CloseHandleV2 } from '@volkswagen-onehub/layer-manager';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { FeatureAppEnvironmentContext } from 'src/feature-app/hooks/use-feature-app-environment';
import { OneFormContext, OneFormProvider } from './OneForm';
import { getSentryHub } from './sentry';
import ErrorBoundary from './sentry/ErrorBoundary';

interface LayerManagerWrapperProps {
  children: JSX.Element;
  theme?: ThemeName;
  store: Store;
  env: FeatureAppEnvironment<any, any>;
  useOneFormContext?: any;
  close?: CloseHandleV2<any, any>;
}

export function LayerManagerWrapper({ children, theme, store, env, close }: LayerManagerWrapperProps) {
  const sentryHub = getSentryHub();

  return (
    <ErrorBoundary hub={sentryHub} close={close}>
      <FeatureAppEnvironmentContext.Provider value={env}>
        <IntegratorRoot>
          <ThemeProvider theme={theme ? theme : 'main'}>
            <Provider store={store}>
              <OneFormProvider handleAreYouSureLayer={() => {}} closeLayerCallback={() => {}}>
                {children}
              </OneFormProvider>
            </Provider>
          </ThemeProvider>
        </IntegratorRoot>
      </FeatureAppEnvironmentContext.Provider>
    </ErrorBoundary>
  );
}
