import { getMolecularCdnServiceConfig } from 'src/feature-app';

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving Molecular CDN service config provider.');
  return null;
};

export const getMolecularCdnUrl = (): string | null => {
  const molecularCdn = getMolecularCdnServiceConfig();

  if (!molecularCdn) {
    return handleErrorInRetrievingExternalConfig();
  }

  return (testProduction() ? molecularCdn.urlOrigin.replace('pre-', '') : molecularCdn.urlOrigin) + molecularCdn.urlPath;
};

const testProduction = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('test') && urlParams.get('test').includes('PROD');
}