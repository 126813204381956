import dayjs from 'dayjs';
import {
	Breakpoints,
	Container,
	ContainerPadding,
	Layout,
	Text,
	TextAlignment,
	TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {createEapiAppointment, deleteEapiAppointment, getEapiCarlines, oneformBFFNdpsSend} from 'src/bff';
import {getDealerKvps} from 'src/bff/api/get-dealer-kvps';
import {
	DetalleMotivo,
	Email,
	Kilometros,
	MatriculaYModelo,
	MultipleCTASelection,
	PantallaNombreApellido,
	Telefono,
	SelectExtendedList,
} from 'src/components';
import {
	formatPrefix,
	getDeviceType,
	getTrackingCode,
	removeWhiteSpace,
	useFaContent,
	useOneFormContext,
	useGetWaidLitera,
} from 'src/feature-app';
import {MapaCitaDealer, NewMapaCita} from 'src/forms/CitaPosventa/MapaCita';
import {OneElementTemplate} from 'src/feature-app/Screen';
import {
	resetStepsScreenIndex,
	removeMultipleStepsAndResetScreenIndex,
	removeStep,
	addRemovedStep,
} from 'src/forms/helpers/add-remove-steps';
import {
	FormDataFields,
	DealerInfo,
	DealersData,
	DmsInfo,
	FormDataGroup,
	FormInfo,
	OneFormState,
	Steps,
	CarlineBase,
} from 'src/types';
import {NuevaCitaPosventaFormDefault, NuevaCitaPosventaFormLogged} from '..';
import {CitaFormDataFields} from '../types';
import {ConfirmacionCita} from './ConfirmacionCita';
import {CTAsSiNo, CTAsMotivo, CTAsMotivoDef} from 'src/feature-app/InputsValues/CTAsObjects';
import {getParamsForm} from 'src/feature-app/get-params-form';
import {Store} from 'redux';
import {useIsComerciales, useGetBrand} from 'src/feature-app/hooks';
import {formDataBuilder} from 'src/forms/format-fields';
import {transformDate} from 'src/helpers';

export enum CitaPosventaSteps {
	Motivo,
	MasInformacion,
	Mapa,
	NombreApellido,
	Matricula,
	Kilometros,
	Telefono,
	Email,
	CocheSustitucion,
	CocheAlTaller,
	Confirmacion,
}

interface NuevaCitaPosventaFormProps {
	isCitaPosventaDealer?: boolean;
	isCitaPosventaEmbeddedCalculadora?: boolean;
	isCitaPosventaEmbeddedBonos?: boolean;
	isCitaPosventaEmbeddedBeneficios?: boolean;
	isCitaPosventaEmbeddedMaintenance?: boolean;
	isCitaPosventaEmbeddedPortal?: boolean;
	updatedFormData?: Partial<FormDataGroup>;
	updatedFormInfo?: Partial<FormInfo>;
}

const spliceSteps = (steps: Steps[], step: string, stepName: string) => {
	if (step) {
		const index = steps.findIndex((step) => step.name === stepName);
		steps.splice(index, 1);
		return steps;
	}
	return steps;
};

function updateTitle(detalles: any): any {
	return (
		<>
			<Text bold>¿Quieres contarnos algo más </Text>sobre {detalles ? detalles : 'el motivo de tu visita?'}?
		</>
	);
}

const getFormName = (
	isCitaPosventaDealer: boolean,
	isCitaPosventaEmbeddedCalculadora: boolean,
	isCitaPosventaEmbeddedBonos: boolean,
	isCitaPosventaEmbeddedBeneficios: boolean,
	isCitaPosventaEmbeddedMaintenance: boolean,
) => {
	return isCitaPosventaDealer
		? 'cita-posventa-dealer'
		: isCitaPosventaEmbeddedCalculadora
		? 'cita-posventa-calculadora'
		: isCitaPosventaEmbeddedBonos
		? 'cita-posventa-bono'
		: isCitaPosventaEmbeddedBeneficios
		? 'cita-posventa-beneficios'
		: isCitaPosventaEmbeddedMaintenance
		? 'cita-posventa-maintenance'
		: 'cita-posventa';
};

export const NuevaCitaPosventaFormController = ({
	isCitaPosventaDealer,
	isCitaPosventaEmbeddedCalculadora,
	isCitaPosventaEmbeddedBonos,
	isCitaPosventaEmbeddedBeneficios,
	isCitaPosventaEmbeddedMaintenance,
	isCitaPosventaEmbeddedPortal,
	updatedFormData,
}: NuevaCitaPosventaFormProps) => {
	const {initializeForm, nextScreenIndex, handleNextStep} = useOneFormContext();
	const {formData, formInfo, dealersInfo} = useSelector((state: OneFormState) => state);
	const {motivo} = formData.fields;
	const {selectedDealer} = dealersInfo;
	const {userIsAuthenticated, numberOfScreens} = formInfo;
	const dispatch = useDispatch();
	const store: Store<OneFormState> = useStore();
	const faContent = useFaContent();
	const [loaded, setLoaded] = useState(false);
	const [steps, setSteps] = useState([]);
	const [firstStep, setFirstStep] = useState(null);
	const [stepsHistory, setStepsHistory] = useState(null);
	const isComerciales = useIsComerciales();
	const groupBrand = useGetBrand();
	const waid = useGetWaidLitera();
	const urlParams = new URLSearchParams(window.location.search);
	const wa = urlParams.get('wa');
	const useLoggedJourney = false; // De momento no es necesario usar todas las funciones del journey logueado/no logueado, tan solo el fill de los datos

	const CitaPosventaData: Partial<FormDataGroup> = {
		headers: {
			DESC_BRAND: 'VW',
			DESC_CAMPAIGNBLOCK: '7011n000000Ajm0AAC',
			DESC_FORMOBJECTIVE: 'Call Me Now',
			DESC_ID_CAMPAIGN: '9. VW LEM',
			DESC_TOKEN: 'VW_DDB_FRM_CALL_ME',
			DESC_VN_VO: 'VN',
			DESC_GDPR_ADAPTED: 'true',
			...updatedFormData?.headers,
		},
		fields: {
			formName: getFormName(
				isCitaPosventaDealer,
				isCitaPosventaEmbeddedCalculadora,
				isCitaPosventaEmbeddedBonos,
				isCitaPosventaEmbeddedBeneficios,
				isCitaPosventaEmbeddedMaintenance,
			),
			formType: 'servicerequest',
			pageCategory: 'Cita Posventa',
			...updatedFormData?.fields,
		},
	};
	const [citaPosventaData, setCitaPosventaData] = useState(CitaPosventaData);

	// Lógica común a los formularios independientemente del estado del login.
	const getMotivoTitle = (): JSX.Element => {
		if (!formData.fields || !formData.fields.motivo)
			return (
				<>
					<Text bold>¿Quieres contarnos algo más </Text>sobre el motivo de tu{' '}
					<span style={{whiteSpace: 'nowrap'}}>visita?</span>
				</>
			);
		else {
			const selectedMotivo = CTAsMotivo.find((motivo) => motivo.value === formData.fields.motivo);
			const detalles = selectedMotivo ? selectedMotivo.detalles : 'string';
			return (
				<>
					<Text bold>¿Quieres contarnos algo más </Text>sobre {detalles ? detalles : 'el motivo de tu visita?'}?
				</>
			);
		}
	};

	const updateSteps = (steps: Steps[]) => {
		dispatch({type: 'UPDATE_STEPS', payload: steps});
		dispatch({type: 'UPDATE_LAST_STEP', payload: steps[steps.length - 1]});
		dispatch({type: 'SET_NUMBER_OF_SCREENS', payload: steps.length});
	};

	const isMotivoSoftware = () => {
		const selectedMotivo = CTAsMotivo.find((ctaMotivo) => ctaMotivo.value === motivo);
		return selectedMotivo?.detalles === 'Actualización Software ID';
	};

	useEffect(() => {
		const selectedMotivo = CTAsMotivo.find((ctaMotivo) => ctaMotivo.value === motivo);
		const {steps: actualSteps, screenIndex} = store.getState().formInfo;
		let newSteps = [...steps];

		if (isMotivoSoftware()) {
			newSteps = removeStep(newSteps, 'MasInformacion');
			dispatch({type: 'UPDATE_FIELDS', payload: {masInformacion: 'Actualización Software ID'}});
			updateSteps(newSteps);
		} else if (selectedMotivo && actualSteps.length < defaultSteps.length) {
			updateSteps(newSteps);
		}

		if (selectedMotivo && formInfo?.steps && !isMotivoSoftware()) {
			dispatch({type: 'UPDATE_FIELDS', payload: {masInformacion: ''}});

			const formSteps = formInfo.steps;

			const masInformacionIndex = formSteps.findIndex((step) => step.name === 'MasInformacion');

			if (masInformacionIndex !== -1) {
				formSteps[masInformacionIndex].title = updateTitle(selectedMotivo.detalles);
			}
		}
	}, [motivo]);

	const defaultSteps: Steps[] = [
		{
			title: (
				<>
					<Text bold>¿Por qué motivo</Text> quieres{' '}
					<span style={{whiteSpace: 'nowrap'}}>
						visitarnos?<sup style={{top: 'auto', fontSize: '100%'}}>*</sup>
					</span>
				</>
			),
			alternateTitle: (
				<Text>
					<Text bold>¿Por qué motivo</Text> quieres{' '}
					<span style={{whiteSpace: 'nowrap'}}>
						visitarnos?<sup style={{top: 'auto', fontSize: '100%'}}>*</sup>
					</span>
				</Text>
			),

			fields: (
				<>
					<Layout
						appearance={{
							[Breakpoints.default]: [
								{name: '.', columns: 2},
								{name: 'a', columns: 20},
								{name: '.', columns: 2},
							],
							[Breakpoints.b960]: [
								{name: '.', columns: 3},
								{name: 'a', columns: 18},
								{name: '.', columns: 3},
							],
						}}
					>
						{nextScreenIndex !== null && nextScreenIndex === 1 ? null : (
							<Container padding={{top: ContainerPadding.static200}}>
								<Text appearance={TokenTextAppearance.copy200}>Selecciona la razón principal de tu visita.</Text>
							</Container>
						)}
					</Layout>
					<Layout
						appearance={{
							[Breakpoints.default]: [
								{name: '.', columns: 2},
								{name: 'a', columns: 20},
								{name: '.', columns: 2},
							],
							[Breakpoints.b960]: [
								{name: '.', columns: 3},
								{name: 'a', columns: 18},
								{name: '.', columns: 3},
							],
						}}
					>
						<MultipleCTASelection ctaValues={CTAsMotivo} name="motivo" nextOnClick />
					</Layout>
				</>
			),
			hidePreviousStep: false,
			isFirstStep: true,
			screenIndex: CitaPosventaSteps.Motivo,
			name: 'Motivo',
			outputs: ['motivo'],
			outputsText: ['motivo'],
		},
		{
			title: getMotivoTitle(),
			fields: <OneElementTemplate element={<DetalleMotivo />} />,
			screenIndex: CitaPosventaSteps.MasInformacion,
			name: 'MasInformacion',
			outputs: ['masInformacion'],
			outputsText: ['masInformacion'],
		},
		{
			screenIndex: CitaPosventaSteps.Mapa,
			name: 'Mapa',
			multiStep: true,
			component: isCitaPosventaDealer ? <MapaCitaDealer /> : <NewMapaCita />,
		},
		{
			title: (
				<>
					Para poder preparar tu visita, necesitamos saber <Text bold>quién eres</Text>
				</>
			),
			fields: (
				<OneElementTemplate
					element={
						<div style={{marginBottom: '2px'}}>
							<PantallaNombreApellido />
						</div>
					}
				/>
			),
			screenIndex: CitaPosventaSteps.NombreApellido,
			name: 'NombreApellido',
			outputs: ['name', 'surname', 'secondSurname'],
			outputsText: ['name', 'surname', 'secondSurname'],
		},
		{
			title: (
				<>
					Cuéntanos más sobre tu coche. <Text bold>¿Qué matrícula tiene y qué modelo es?</Text>
				</>
			),
			fields: (
				<OneElementTemplate
					element={
						<div style={{marginBottom: '2px'}}>
							<MatriculaYModelo paginaConfirmacion={false} />
						</div>
					}
				/>
			),
			screenIndex: CitaPosventaSteps.Matricula,
			name: 'Matricula',
			outputs: ['modelo', 'matricula'],
			outputsText: ['modelo', 'con matrícula', 'matricula'],
		},
		{
			title: (
				<>
					Y, ¿cuántos <Text bold>kilómetros?</Text>
					<sup style={{top: 'auto', fontSize: '100%'}}>*</sup>
				</>
			),
			fields: <Kilometros />,
			screenIndex: CitaPosventaSteps.Kilometros,
			name: 'Kilometros',
			outputs: ['kilometros'],
			outputsText: ['kilometros'],
		},
		{
			title: (
				<>
					En caso de necesitar contactar contigo, <Text bold>¿a qué número podemos llamarte?</Text>
				</>
			),
			fields: <OneElementTemplate element={<Telefono paginaConfirmacion={false} required={true} />} />,
			screenIndex: CitaPosventaSteps.Telefono,
			name: 'Telefono',
			outputs: ['cellphone'],
			outputsText: ['cellphone'],
		},

		{
			title: (
				<>
					Y, <Text bold>¿a qué email te enviamos la confirmación y los datos de tu cita?</Text>
				</>
			),
			fields: (
				<OneElementTemplate
					element={
						<div style={{marginBottom: '2px'}}>
							<Email paginaConfirmacion={false} required={true} />
						</div>
					}
				/>
			),
			screenIndex: CitaPosventaSteps.Email,
			name: 'Email',
			outputs: ['email'],
			outputsText: ['email'],
		},
		{
			title: (
				<>
					<Text bold>¿Necesitarás otro coche</Text> mientras el tuyo está en el{' '}
					<span style={{whiteSpace: 'nowrap'}}>
						taller?
						<sup style={{top: 'auto', fontSize: '100%'}}>*</sup>
					</span>
				</>
			),
			fields: (
				<OneElementTemplate
					element={
						<>
							<MultipleCTASelection ctaValues={CTAsSiNo} name="cocheSustitucion" ctaWidth="81" nextOnClick />
							<Container padding={{top: ContainerPadding.static500}}>
								<Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.left} staticSize>
									<Text bold>Coche de sustitución:</Text> este servicio puede tener un coste asociado. El Servicio Oficial contactará contigo para informarte de la disponibilidad y condiciones de este servicio.
								</Text>
							</Container>
						</>
					}
				/>
			),
			screenIndex: CitaPosventaSteps.CocheSustitucion,
			name: 'CocheSustitucion',
			outputs: ['cocheSustitucion'],
			outputsText: ['cocheSustitucion', 'quiero otro coche'],
			trueOrFalseQuestion: true,
		},
		{
			title: (
				<>
					Y para facilitar tu visita,{' '}
					<Text bold>
						¿llevamos tu coche al{' '}
						<span style={{whiteSpace: 'nowrap'}}>
							taller?<sup style={{top: 'auto', fontSize: '100%'}}>*</sup>
						</span>
					</Text>
				</>
			),
			fields: (
				<OneElementTemplate
					element={
						<>
							<MultipleCTASelection ctaValues={CTAsSiNo} name="cocheAlTaller" ctaWidth="81" nextOnClick />
							<Container padding={{top: ContainerPadding.static500}}>
								<Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.left} staticSize>
									<Text bold>Recogida a domicilio:</Text> este servicio puede tener un coste asociado. El Servicio Oficial contactará contigo para informarte de la disponibilidad y condiciones de este servicio.
								</Text>
							</Container>
						</>
					}
				/>
			),
			screenIndex: CitaPosventaSteps.CocheAlTaller,
			name: 'CocheAlTaller',
			outputs: ['cocheAlTaller'],
			outputsText: ['cocheAlTaller', 'quiero servicio de recogida y entrega'],
			trueOrFalseQuestion: true,
		},
		{
			title: <></>,
			fields: <ConfirmacionCita />,
			screenIndex: CitaPosventaSteps.Confirmacion,
			name: 'Confirmacion',
			isLastStep: true,
			hidePreviousStep: true,
		},
	];

	const getLssiTmk = (value: string) => {
		switch (value) {
			case 'PERMITE':
				return 'PERMITIDO';
				break;
			case 'NO PERMITE':
				return 'ROBINSON';
				break;
			default:
				return null;
		}
	};

	const getModelo = (fields: FormDataFields, carlines: CarlineBase[]) => {
		if(fields.idBuzzExclusive && carlines?.length > 0) {
			return carlines.find(carline => carline.name.includes('Buzz'));
		}
		return fields.modeloList
			? fields.modeloList
			: carlines?.length > 0 && fields.modelo
			? carlines.find((carline) => carline.name === fields.modelo)
			: {
					code: null,
					key: null,
					name: null,
			};
	};

	const getInstallationAdress = (markerInfo) => {
		return (
			markerInfo.dealerInfo?.address +
			' ' +
			markerInfo.dealerInfo?.zipCode +
			' ' +
			markerInfo.dealerInfo?.city +
			' ' +
			markerInfo.dealerInfo?.province
		);
	};

	const createAppointmentDealer = (dealer: DealersData) => {
		const {markerInfo, dmsInfo} = dealer;

		return {
			assessors: dmsInfo.assessors ? [...dmsInfo.assessors] : undefined,
			dealerId: dmsInfo.dealerId,
			dmsAvailable: dmsInfo.dmsAvailable ? dmsInfo.dmsAvailable : false,
			email: dmsInfo.email ? dmsInfo.email : markerInfo.dealerInfo.mail,
			name: dmsInfo.name ? dmsInfo.name : markerInfo.dealerInfo.name,
			phone: dmsInfo.phone ? dmsInfo.phone : markerInfo.dealerInfo.phone,
			overhaulingServiceAvialable: dmsInfo.overhaulingServiceAvialable ? dmsInfo.overhaulingServiceAvialable : false,
			pickupCarServiceAvailabe: dmsInfo.pickupCarServiceAvailabe ? dmsInfo.pickupCarServiceAvailabe : false,
			replacementCarServiceAvailable: dmsInfo.replacementCarServiceAvailable
				? dmsInfo.replacementCarServiceAvailable
				: false,
			reference: dmsInfo.reference,
		};
	};

	const sendForm = async (): Promise<boolean> => {
		return new Promise(async (resolve) => {
			const {tc, today, url, device} = getParamsForm();
			const {formData, dealersInfo} = store.getState();

			const {fields} = formData;
			const {citaCarlines} = dealersInfo;
			const {dealer} = fields;
			const {dmsInfo, markerInfo}: {dmsInfo: DmsInfo; markerInfo: DealerInfo} = dealer;
			const modelo = getModelo(fields, citaCarlines);
			const appointmentDealer = createAppointmentDealer(dealer);
			const appointmentDate = dayjs(fields.slot.from).format('DD/MM/YYYY HH:mm:ss');

			const ctaLabel = CTAsMotivo.find((ctaMotivo) => ctaMotivo.value === fields.motivo).label;

			const appointmentNode = {
				appointment: {
					comments: fields.masInformacion,
					customer: {
						contactPhone: `${formatPrefix(fields.prefix)}${fields.cellphone}`,
						customerType: 'F',
						documentNumber: null,
						documentType: null, // Parametrizar [DNI,NIF,NIE] || null
						email: removeWhiteSpace(fields.email),
						gender: 'M', // Parametrizar [M,V]
						name: fields.name?.trim(),
						owner: false,
						surname: fields.surname?.trim(),
						surname2: fields.secondSurname?.trim(),
					},
					vehicle: fields.vehicleForMaintenance
						? fields.vehicleForMaintenance
						: {
								brand: groupBrand,
								frame: null,
								millage: Array.isArray(fields.kilometros) ? fields.kilometros[0] : fields.kilometros,
								modelVersion: 'NO INFORMADO',
								modelYear: 0,
								plate: fields.matricula?.toUpperCase(),
								// countryPlate: fields.countryPlate,
								plateDate: `${fields.anoMatriculacion}-1-1 00:00:00`,
								tmaimg: null,
								model: modelo,
						},
					dealer: appointmentDealer,
					slot: {
						from: fields.slot.from,
						to: fields.slot.to,
						replacementCarServiceAvailable: fields.slot.replacementCarServiceAvailable,
						dayMoment: fields.slot.dayMoment,
						assessorCode: fields.slot.assessorCode,
					},
					assessor: {
						code: fields.assessor.code,
						name: fields.assessor.name,
					},
					intervention: {
						code: fields.motivo, // Parametrizar [NEUMATICOS, OTROS, MANTENIMIENTO, CARROCERIA, REPARACION, ACCESORIOS, EA189, PREITV, ITV]
						name: ctaLabel === 'Actualización Software ID' ? 'Otros' : ctaLabel, // motivo literal excepto si es software tiene que tener el mismo valor que OTROS
					},
					lopd: 'PERMITIDO',
					lssi: getLssiTmk(fields.lssi),
					pickupServiceRequired:
						typeof fields.cocheAlTaller === 'string' ? JSON.parse(fields.cocheAlTaller) : undefined,
					replacementCarServiceRequired:
						typeof fields.cocheSustitucion === 'string' ? JSON.parse(fields.cocheSustitucion) : undefined,
					status: 'P',
					tmk: getLssiTmk(fields.tmk),
					sms: null,
				},
			};

			const surveyNode = null; // Flag para que Eapi no grabe survey
			/*const surveyNode = {
				survey: {
					dealer: {
						dmsInfo: dmsInfo,
						markerInfo: markerInfo,
					},
					deviceType: getDeviceType(),
					formId: `vw-oneform-${fields.formName}`,
					formVersion: `vw-oneform-${fields.formName}-${process.env.VERSION}`,
					tc: getTrackingCode(),
					url,
					urlOrigin: document.referrer || null,
					deepLink: fields.deepLink,
					budget: fields.budget,
					waid,
				},
			};*/

			const eapiData: CitaFormDataFields = {
				group_brand: groupBrand,
				kvps: dealer.kvps,
				cod_instalacion: dealer.codInstalacion,
				...appointmentNode,
				...surveyNode,
			};

			let eapiDataToSend = new FormData();
			for (const key in eapiData) {
				if (typeof eapiData[key] === 'object') {
					eapiDataToSend.append(key, JSON.stringify(eapiData[key]));
				} else {
					eapiDataToSend.append(key, eapiData[key]);
				}
			}

			const eapiResponse = await createEapiAppointment(eapiDataToSend);

			if (eapiResponse && eapiResponse.data?.status?.code == 200) {
				const pincode = eapiResponse.data?.content?.pincode;
				const ndpsData = {
					'headers[DPS_TOKEN]':
						groupBrand == 'N' ? 'LCV_DDB_FRM_SERVICE_APPOINTMENT' : 'VW_DDB_FRM_SERVICE_APPOINTMENT',
					'headers[FK_SYSTEM]':
						groupBrand == 'N' ? 'LCV_DDB_FRM_SERVICE_APPOINTMENT' : 'VW_DDB_FRM_SERVICE_APPOINTMENT',
					'headers[FORM_ID]': wa,
					'headers[FORM_NAME]': `vw-oneform-${fields.formName}`,
					'headers[FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
					'headers[FORM_DATE_INSERTION]': today,
					'headers[FORM_OBJECTIVE]': 'Cita Posventa',
					'headers[ORIGIN_DEVICE_TYPE]': device,
					'headers[ORIGIN_TRACKING_CODE]': tc,

					'fields[DPS_BRAND]': groupBrand == 'N' ? 'Volkswagen CV' : 'Volkswagen',

					'fields[PERSON_NAME]': fields.name,
					'fields[PERSON_SURNAME_1]': fields.surname,
					'fields[PERSON_SURNAME_2]': fields.secondSurname,
					'fields[PERSON_EMAIL]': fields.email,
					'fields[PERSON_PHONE_MOBILE]': fields.cellphone,
					'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
					'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
					'fields[COMMENTS_PERSON]': fields.masInformacion,
					'fields[CREATIVITY_PERSONALIZATION_DATA]': fields.budget,
					//'fields[]': fields.deepLink,

					'fields[SURVEY_ANSWER_01]': pincode,

					'fields[VEH_ACTUAL_MODEL]': appointmentNode.appointment.vehicle.model?.name,
					'fields[VEH_ACTUAL_PLATE]': appointmentNode.appointment.vehicle.plate,
					'fields[VEH_ACTUAL_PLATE_DATE]': transformDate(appointmentNode.appointment.vehicle.plateDate, 'DD/MM/YYYY HH:mm:ss', 'YYYY-M-D HH:mm:ss'),
					'fields[VEH_ACTUAL_MILEAGE]': appointmentNode.appointment.vehicle.millage,
					'fields[VEH_ACTUAL_FRAME]': appointmentNode.appointment.vehicle.frame,

					'fields[APPOINTMENT_TYPE]': 'NUEVA',
					'fields[APPOINTMENT_INSTALLATION_NAME]': appointmentDealer.name,
					'fields[APPOINTMENT_INSTALLATION_CODE]': appointmentDealer.dealerId?.codInstalacion,
					'fields[APPOINTMENT_INSTALLATION_ADDRESS]': getInstallationAdress(markerInfo),
					'fields[APPOINTMENT_INSTALLATION_EMAIL]': appointmentDealer.email,
					'fields[APPOINTMENT_INSTALLATION_PHONE]': appointmentDealer.phone?.replace(/\s+/g, ''),
					'fields[APPOINTMENT_REQUESTED_TYPE]': fields.motivo,
					'fields[APPOINTMENT_DATE]': appointmentDate,
					'fields[PERSON_PREFERRED_SLOT]': fields.slot.dayMoment?.code, // ej: MEDIODIA
					'fields[APPOINTMENT_COMMERCIAL_ADVISER]': fields.assessor.name,

					'flags[APPOINTMENT_FLAG_PICK_UP_SERVICE]':
						typeof fields.cocheAlTaller === 'string' ? JSON.parse(fields.cocheAlTaller) : '',
					'flags[APPOINTMENT_FLAG_SUBSTITUTION_VEHICLE]':
						typeof fields.cocheSustitucion === 'string' ? JSON.parse(fields.cocheSustitucion) : '',
					'flags[APPOINTMENT_FLAG_HAS_DMS]': appointmentDealer.dmsAvailable,
					'flags[PERSON_RGPD_ADAPTED]': 'true',

					'flags[FLAG_RELACION_MARCA_SIN_IC]': 'true',
					'flags[FLAG_IS_MYSTERY_LEAD]': 'false',
				};

				const ndpsDataToSend = formDataBuilder(JSON.parse(JSON.stringify(ndpsData)));
				const ndpsResponse = await oneformBFFNdpsSend(ndpsDataToSend);
				if (ndpsResponse && ndpsResponse.data?.status?.code == 200) {
					dispatch({type: 'SET_FORM_RESPONSE', payload: eapiResponse.data});
					return resolve(true);
				} else {
					const eapiDelete = {
						group_brand: groupBrand,
						pincode: pincode,
					};
					await deleteEapiAppointment(formDataBuilder(eapiDelete));
					resolve(false);
				}
			} else if (!eapiResponse?.data?.content && dmsInfo?.dmsAvailable) {
				dispatch({type: 'SET_SEND_DMS_FAILED', payload: true});
				resolve(false);
			} else {
				resolve(false);
			}
		});
	};

	const repeatKvpsRequest = async (kvps: string) => {
		const newSelectedDealer = await getDealerKvps(kvps);

		if (newSelectedDealer) {
			setCitaPosventaData({...citaPosventaData, fields: {...citaPosventaData.fields, dealer: newSelectedDealer}});
			// CitaPosventaData.fields.dealer = newSelectedDealer;
		}
	};

	const getFinalSteps = (steps: Steps[], motivo: string, observaciones: string) => {
		let newSteps = [...steps];

		newSteps = spliceSteps(newSteps, motivo, 'Motivo');
		newSteps = spliceSteps(newSteps, observaciones, 'MasInformacion');

		const finalSteps = resetStepsScreenIndex(newSteps);

		return finalSteps;
	};

	/**
	 * Dependiendo del journey que siga el usuario se empezará en una pantalla o en otra, pero hay que adaptar stepHistory para que aun así pueda volver hacia atrás.
	 */
	const getFirstStepAndStepsHistory = (): {firstStep: Steps; stepsHistory: number[]; finalSteps: Steps[]} => {
		// Obtención de los parametros de la url
		let selectedMotivo: CTAsMotivoDef = null;
		let motivo: string;
		let observaciones = faContent?.observaciones;

		if (isCitaPosventaEmbeddedCalculadora || isCitaPosventaEmbeddedMaintenance) {
			// En los forms de Calculadora y Mi Manteniemto nos saltamos algunos pasos
			const stepsWithoutMap = removeMultipleStepsAndResetScreenIndex(defaultSteps, [
				'Motivo',
				'MasInformacion',
				'Matricula',
				'Kilometros',
			]);

			return {finalSteps: stepsWithoutMap, stepsHistory: [0], firstStep: stepsWithoutMap[0]};
		}

		if (isCitaPosventaEmbeddedPortal) {
			// En los forms de Calculadora y Mi Manteniemto nos saltamos algunos pasos
			const stepsWithoutMap = removeMultipleStepsAndResetScreenIndex(defaultSteps, [
				'Matricula',
				'Kilometros',
			]);

			return {finalSteps: stepsWithoutMap, stepsHistory: [0], firstStep: stepsWithoutMap[0]};
		}

		if (isCitaPosventaEmbeddedBonos) {
			const stepsWithoutMap = removeMultipleStepsAndResetScreenIndex(defaultSteps, ['Motivo', 'MasInformacion']);

			return {finalSteps: stepsWithoutMap, stepsHistory: [0], firstStep: stepsWithoutMap[0]};
		}

		if (window && window.location) {
			const urlParams = new URLSearchParams(window.location.search);
			const motivo = urlParams.get('motivo');
			const dealer = urlParams.get('dealer');

			if (motivo) {
				selectedMotivo = CTAsMotivo.find((CTAmotivo) => {
					return CTAmotivo.value === motivo;
				});
			}
			if (dealer && selectedDealer) {
				// CitaPosventaData.fields.dealer = selectedDealer;
				setCitaPosventaData({...citaPosventaData, fields: {...citaPosventaData.fields, dealer: selectedDealer}});
			} else if (dealer && !selectedDealer) {
				// En caso de que por algun motivo haya dealer pero no se haya cargado correctamente en el cms repetimos la request
				repeatKvpsRequest(dealer);
			}
		}

		motivo = faContent?.motivo ? faContent.motivo : motivo;
		const finalSteps = getFinalSteps(defaultSteps, motivo, observaciones);

		const defaultFirstStep = finalSteps[CitaPosventaSteps.Motivo];

		let firstStep = finalSteps.find((step: Steps) => {
			if (selectedMotivo && (!faContent || !faContent.motivo)) {
				return step.screenIndex === CitaPosventaSteps.MasInformacion;
			} else {
				return step.screenIndex === defaultFirstStep.screenIndex;
			}
		});

		firstStep = firstStep ? firstStep : defaultFirstStep;
		firstStep.isFirstStep = true;

		let stepsHistory = [0];
		if (firstStep.screenIndex === defaultFirstStep.screenIndex) {
			stepsHistory = [0];
		} else {
			stepsHistory = [0, firstStep.screenIndex];
		}

		const motivoData = faContent?.motivo ? faContent.motivo : selectedMotivo ? selectedMotivo.value : null;
		const masInformacionData = faContent?.observaciones
			? faContent.observaciones
			: isMotivoSoftware()
			? selectedMotivo.detalles
			: null;

		setCitaPosventaData({
			...citaPosventaData,
			fields: {...citaPosventaData.fields, motivo: motivoData, masInformacion: masInformacionData},
		});

		return {firstStep, stepsHistory, finalSteps};
	};

	const handleStepsOfCitaDealer = (steps: Steps[], dealer: DealersData) => {
		let newSteps = [...steps];

		if (isComerciales) {
			return;
		}

		if (!dealer.dmsInfo?.pickupCarServiceAvailabe) {
			newSteps = removeMultipleStepsAndResetScreenIndex(newSteps, ['CocheAlTaller']);
		}
		if (!dealer.dmsInfo?.replacementCarServiceAvailable) {
			newSteps = removeMultipleStepsAndResetScreenIndex(newSteps, ['CocheSustitucion']);
		}

		setSteps(newSteps);
		setFirstStep(newSteps[0]);
		setStepsHistory(stepsHistory);
		setLoaded(true);
	};

	const getSteps = (steps: Steps[]) => {
		if (isCitaPosventaDealer && selectedDealer) {
			handleStepsOfCitaDealer(defaultSteps, selectedDealer);
			return;
		}
		const {firstStep, stepsHistory, finalSteps} = getFirstStepAndStepsHistory();
		setSteps(finalSteps);
		setFirstStep(firstStep);
		setStepsHistory(stepsHistory);
		setLoaded(true);
	};

	useEffect(() => {
		getSteps(defaultSteps);
		getEapiCarlines().then((response) => {
			dispatch({type: 'SET_CITA_CARLINES', payload: response.data?.content});
		});
	}, []);

	return loaded ? (
		userIsAuthenticated && useLoggedJourney ? (
			<NuevaCitaPosventaFormLogged
				defaultSteps={steps}
				sendForm={sendForm}
				firstStep={firstStep}
				CitaPosventaData={citaPosventaData}
			/>
		) : (
			<NuevaCitaPosventaFormDefault
				defaultSteps={steps}
				sendForm={sendForm}
				firstStep={firstStep}
				CitaPosventaData={citaPosventaData}
				stepsHistory={stepsHistory}
			/>
		)
	) : null;
};
