import { Spinner, Text, styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureServices, useTrackingManager } from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { CarlineBase, DealersData, IdLead, IdPerson, IdVehicle } from 'src/types';
import { NoRenovacionYaTengo, NoRenovacionNoTengo, NoRenovacionNoQuiero } from '.';
import { GenericErrorLayer } from 'src/components/layers';
import { checkHasCarlineNoRenovacion } from './use-initialize-no-renovacion-form';
import { createPincodeEntity } from '../dps-utils';
import { LoadingEncuestas } from 'src/components';

export interface NoRenovacionFormProps {
  idLead: IdLead;
  idPerson: IdPerson;
  idVehicle: IdVehicle;
  dpsData: DpsData;
  handleError: () => void;
  trackFormLoad: () => void;
  estado: string;
}

export function NoRenovacionController() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [usado, setUsado] = useState(false);
  const [estado, setEstado] = useState(null);
  const [yaTengo, setYaTengo] = useState(false);
  const [noTengo, setNoTengo] = useState(false);
  const [noQuiero, setNoQuiero] = useState(false);
  const trackingManager = useTrackingManager();
  const dpsDataRef = useRef(null);
  const idLeadRef = useRef(null);
  const idPersonRef = useRef(null);
  const idVehicleRef = useRef(null);
  const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;
  const dispatch = useDispatch();

  const handleNext = (value: any) => { };
  const handleComplete = () => {
    const dpsData = oneFormService.getDpsData();
    const { estado } = dpsData;
    setEstado(estado);

    if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
      handleError();
    } else if (dpsData.pincode.pincodeUsed) {
      setUsado(true);
      setLoading(false);
    } else {
      const idLead = createPincodeEntity(dpsData, 'LEAD');
      const idPerson = createPincodeEntity(dpsData, 'PERSON');
      const idVehicle = createPincodeEntity(dpsData, 'VEHICLE');

      dpsDataRef.current = dpsData;
      idLeadRef.current = idLead;
      idPersonRef.current = idPerson;
      idVehicleRef.current = idVehicle;

      if (estado === 'ya_tengo') {
        setYaTengo(true);
      } else if (estado === 'no_quiero') {
        setNoQuiero(true);
      } else if (estado === 'no_tengo') {
        setNoTengo(true);
      } else {
        handleError();
      }
      setLoading(false);
    }
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  {/* useEffect(() => {
    console.log(error, 'error');
  }, [error]);*/}

  const trackFormLoad = () => {
    if (idLeadRef.current) {
      const customDealer: DealersData = {
        kvps: idLeadRef.current.TXT_DESC_DEALER_CODE__c,
        name: idLeadRef.current.DESC_Dealer_Code__c,
        markerInfo: { dealerInfo: { zipCode: idLeadRef.current.TXT_Postal_Code__c } },
      };
      dispatch({ type: 'SET_SELECTED_DEALER', payload: customDealer });
      if (checkHasCarlineNoRenovacion(idLeadRef.current)) {
        const carline: Partial<CarlineBase> = {
          title: idLeadRef.current.DESC_Model__c,
          code: Number(idLeadRef.current.NUM_COD_INTEREST_CARLINE__c),
        };
        dispatch({ type: 'SET_SELECTED_CARLINE', payload: carline });
      }
    }
    trackingManager.trackFormLoad();
  };

  useEffect(() => {
    const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

    oneFormCoreDpsIsLoaded.subscribe({
      next(value) {
        handleNext(value);
      },
      error(err) {
        console.error(err);
        handleError();
      },
      complete() {
        handleComplete();
      },
    });
  }, []);

  // función para layer de error
  const handleTryAgain = async () => {
    // oneFormService.
    setError(false);
    setLoading(true);
    await oneFormService.retryPincodeLoad();
    handleComplete();
  };

  if (loading) {
    return <LoadingEncuestas/>;
  } else if (error) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, algo ha pasado"
          bodycopy="Por alguna razón no hemos podido cargar este cuestionario."
          ctaLayer="Reintentar"
          notShowCTA={true}
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else if (usado) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, ya has respondido a este cuestionario"
          bodycopy="Parece que ya has compartido tu opinión con nosotros. ¿Quieres añadir algo más?"
          ctaLayer="Contacta con nosotros"
          ctaType="a"
          urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else {
    return (
      <>
        {yaTengo ? (
          <NoRenovacionYaTengo
            dpsData={dpsDataRef.current}
            idLead={idLeadRef.current}
            idVehicle={idVehicleRef.current}
            idPerson={idPersonRef.current}
            handleError={handleError}
            trackFormLoad={trackFormLoad}
            estado={estado}
          />
        ) : noTengo ? (
          <NoRenovacionNoTengo
            dpsData={dpsDataRef.current}
            idLead={idLeadRef.current}
            idVehicle={idVehicleRef.current}
            idPerson={idPersonRef.current}
            handleError={handleError}
            trackFormLoad={trackFormLoad}
            estado={estado}
          />
        ) : noQuiero ? (
          <NoRenovacionNoQuiero
            dpsData={dpsDataRef.current}
            idLead={idLeadRef.current}
            idVehicle={idVehicleRef.current}
            idPerson={idPersonRef.current}
            handleError={handleError}
            trackFormLoad={trackFormLoad}
            estado={estado}
          />
        ) : null}
      </>
    );
  }
}
