import {
  Breakpoints,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Layout,
  ThemeProvider,
} from '@volkswagen-onehub/components-core';
import { ArrowLeft, Magnifier, FleetServicePrivate, CarPickupService } from 'src/icons-core-imports';

import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useFeatureAppConfig } from 'src/feature-app';
import { CloseComponent } from 'src/feature-app/Screen';
import { SelectedTab } from 'src/types';
import { Filtros } from 'src/feature-app/NewMap';
import { Points } from 'src/types';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { NewMap } from 'src/feature-app/NewMap/components/Map';
import { useMapGeneralController } from 'src/feature-app/NewMap/MapGeneralController';
import { 
  MapAndTabsWrapper, 
  FilterButton, 
  FiltrosWrapperLayer, 
  ListWrapper, 
  SmallerSVG, 
  handleClickCarPickup,
  handleClickReplacementCar  
} from '.';

interface ViewCitaPosventaProps {
  handleSeleccionarOnClick: any;
  handleShowSlots?: (point: Points) => string | null;
  renderPoints(points: any, carPickupSelected?: boolean, replacementCarSelected?: boolean, isTime?: boolean ): JSX.Element[];
  renderCalendar?(): JSX.Element;
  showCalendar?: boolean;
  preSelectedOrderValue?: SelectedTab;
  setPreSelectedOrderValue?: React.Dispatch<React.SetStateAction<SelectedTab>>;
  pointsToList: Points[];
  services?: boolean;
  paddingBottomList?: string;
  isPosventa?: boolean;
  concesionario?: boolean;
  onlySuggest?: boolean; 
}

export const LayerDesktopView = (props: ViewCitaPosventaProps) => {
  const {
    renderPoints,
    renderCalendar,
    showCalendar,
    preSelectedOrderValue,
    setPreSelectedOrderValue,
    pointsToList,
    services, paddingBottomList, isPosventa, concesionario, onlySuggest,
  } = props;

  const [showFiltros, setShowFiltros] = useState<boolean>(false);
  const [ preselectedDealerForm, setPreselectedDealerForm ] = useState(false);
  const config = useFeatureAppConfig();
  const sustitucionRef = useRef(null);
  const recogidaRef = useRef(null);

  useEffect(()=>{
    if(config.trigger === 'cita-posventa-dealer') {
      setPreselectedDealerForm(true);
    } else {
      setPreselectedDealerForm(false);
    }
  }, []);

  const { searchIsDealer } = useMapGeneralController();
  const { handleAreYouSureLayer } = useOneFormContext();

  const handleShowFiltros = () => {
    setShowFiltros(!showFiltros);
  };

  //seleccionar los que tiene coche de sustitucion o servicio de recogida

  const [ carPickupSelected, setCarPickupSelected] = useState<boolean>(false);
  const [ replacementCarSelected, setReplacementCarSelected ] = useState<boolean>(false);

  return showCalendar ? (
    renderCalendar()
  ) : (
    <ThemeProvider theme='inverted'>
      <MapAndTabsWrapper className="map-tabs-wrapper" showFilters={showFiltros}>
        <div className="wrapper wrapper--tabs">
          <CloseComponent closeInMap handleAreYouSureLayer={handleAreYouSureLayer} />
          {
            services? (
              <Container 
                horizontalAlign={'flex-start'}
                gutter={ContainerGutter.static200}
                padding={{left: ContainerPadding.static450, bottom: ContainerPadding.static200 }}
              >
                <SmallerSVG>
                  <CTA 
                    ref={sustitucionRef}
                    tag='button' 
                    emphasis={replacementCarSelected ? 'primary':'secondary' }
                    size='small' 
                    onClick={(e) => {
                      e.preventDefault();
                      handleClickReplacementCar(replacementCarSelected, setReplacementCarSelected);
                      sustitucionRef.current.blur();
                    }} 
                    ariaLabel='aria label'
                    icon={<FleetServicePrivate/>}
                  >
                    Coche de sustitución
                  </CTA>
                </SmallerSVG>  
                <SmallerSVG>
                  <CTA 
                    ref={recogidaRef}
                    tag='button' 
                    emphasis={carPickupSelected ? 'primary':'secondary' }
                    size='small' 
                    onClick={(e) => {
                      e.preventDefault();
                      handleClickCarPickup(carPickupSelected, setCarPickupSelected);
                      recogidaRef.current.blur();
                    }} 
                    ariaLabel='aria label'
                    icon={<CarPickupService/>}
                  >
                    Servicio de recogida y entrega
                  </CTA>
                </SmallerSVG>
              </Container>
            ) : null
          }
          <Container
            horizontalAlign={'center'}
            stretchContent={true}
          >
            <div style={{ width: '100%' }}>
              {pointsToList ? (
                <Layout
                  appearance={{
                    [Breakpoints.default]: [
                      { name: '.', columns: 2 },
                      { name: 'a', columns: 20 },
                      { name: '.', columns: 2 },
                    ],
                  }}
                >
                  <ListWrapper className="wrapper--list" paddingBottom={paddingBottomList}>
                    {renderPoints(pointsToList, carPickupSelected, replacementCarSelected)}
                  </ListWrapper>
                </Layout>  
              ) : null}
            </div>
          </Container>
        </div>
        <div className="wrapper wrapper--map">
          <NewMap 
            searchIsDealer={searchIsDealer} 
            preSelectedOrderValue={preSelectedOrderValue} 
            preselectedDealerForm={preselectedDealerForm}
            carPickupSelected={carPickupSelected}
            replacementCarSelected={replacementCarSelected}
            isPosventa={isPosventa}
          />
          {
            preselectedDealerForm ? null : (
              <FilterButton className="filtros-navbar">
                <ThemeProvider theme="main">
                  <CTA
                    tag="button"
                    emphasis="secondary"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      handleShowFiltros();
                    }}
                    ariaLabel="aria label"
                    icon={<Magnifier variant="small" />}
                  >
                    Buscar de nuevo
                  </CTA>
                </ThemeProvider>
              </FilterButton>
            )
          }  
        </div>
        <div className="opacity-layer" onClick={() => setShowFiltros(false)} />
      </MapAndTabsWrapper>

      <CSSTransition in={showFiltros} timeout={300} classNames="filtros">
        <FiltrosWrapperLayer className="filtros-wrapper">
          <ThemeProvider theme="main">
            <div className="back-btn">
              <CTA
                tag="button"
                emphasis="tertiary"
                size="small"
                onClick={(e: any) => {
                  e.preventDefault();
                  handleShowFiltros();
                }}
                ariaLabel="aria label"
                icon={<ArrowLeft />}
              />
            </div>
            <Filtros
              closeFiltros={() => handleShowFiltros()}
              showFiltros={showFiltros}
              setShowFiltros={setShowFiltros}
              setPreSelectedOrderValue={setPreSelectedOrderValue}
              preSelectedOrderValue={preSelectedOrderValue}
              citaPosventa = {isPosventa}
              onlySuggest={true}
              concesionario={true}
            />
          </ThemeProvider>
        </FiltrosWrapperLayer>
      </CSSTransition>
    </ThemeProvider>
  );
};
